import gql from "graphql-tag";
import { UPDATE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  UpdateTenancyAgreementMutation,
  UpdateTenancyAgreementMutationVariables,
} from "./tenancyAgreementClient.generated";

export const TenancyAgreementClientDefinition = {
  [UPDATE]: (params: {
    data: {
      id: string;
      startDate: string;
      fixedTermEndDate?: string;
    };
  }) => ({
    query: gql`
      mutation updateTenancyAgreement($input: UpdateTenancyAgreementInput!) {
        updateTenancyAgreement(input: $input) {
          tenancyAgreement {
            id
            startDate
            fixedTermEndDate
          }
        }
      }
    `,
    variables: <UpdateTenancyAgreementMutationVariables>{
      input: {
        id: params.data.id,
        startDate: params.data.startDate,
        fixedTermEndDate: params.data.fixedTermEndDate ?? null,
      },
    },
    parseResponse(response: GraphQLResponse<UpdateTenancyAgreementMutation>) {
      return {
        data: response.data.updateTenancyAgreement?.tenancyAgreement,
      };
    },
  }),
};

export const tenancyAgreementClient = createClientFromDefinition(
  TenancyAgreementClientDefinition
);
