import React, { useState } from "react";
import {
  ConfirmMutationDialogContent,
  ErrorDialogContent,
  SlideUpDialog,
} from "..";

export const ConfirmActionDialog = ({
  open,
  onClose,
  confirmAction,
  dialogText,
  failureNotification,
}: {
  open: boolean;
  onClose: () => void;
  confirmAction: () => void;
  dialogText: {
    title: string;
    content: string;
  };
  failureNotification: string;
}): React.ReactElement => {
  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <SlideUpDialog open={open} onClose={onClose}>
      {!error ? (
        <ConfirmMutationDialogContent
          title={dialogText.title}
          content={dialogText.content}
          onConfirmClick={confirmAction}
          onCancelClick={onClose}
        />
      ) : (
        <ErrorDialogContent
          title={failureNotification}
          error={error}
          onClose={() => {
            onClose();
            setError(undefined);
          }}
        />
      )}
    </SlideUpDialog>
  );
};
