import { GET_LIST } from "react-admin";
import gql from "graphql-tag";
import { AiloRN, services } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";
import {
  buildPaginatedResponseFromPaginatedList,
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import {
  MigrationAgenciesQuery,
  MigrationAgenciesQueryVariables,
} from "./migrationAgenciesClient.generated";
import { capitaliseFirstLetter } from "../../../common";

export const MigrationAgencyClientDefinition = {
  [GET_LIST]: (params: {
    pagination: PaginationParams;
    sort?: { field?: string; order?: "ASC" | "DESC" };
    filter: {
      organisationId?: string;
    };
  }) => ({
    query: gql`
      query migrationAgencies(
        $cursor: String
        $pageSize: Int
        $sort: LegalEntityWithExternalTrustAccountSortParams!
        $organisationId: AiloRN
      ) {
        migrationLegalEntitiesWithExternalTrustAccount(
          pagination: { pageSize: $pageSize, cursor: $cursor }
          filters: { organisationId: $organisationId }
          sort: $sort
        ) {
          pageInfo {
            hasNext
            nextCursor
            total
          }
          items {
            legalEntityId
            company {
              id
              ailoRN
              registeredEntityName
            }
            disabled
            status
            finishedAt
          }
        }
      }
    `,
    variables: <MigrationAgenciesQueryVariables>{
      ...buildPaginationVariables(params.pagination),
      sort: params.sort?.field
        ? {
            field: capitaliseFirstLetter(params.sort.field),
            direction: params.sort.order === "ASC" ? "Asc" : "Desc",
          }
        : null,
      organisationId: params.filter.organisationId
        ? AiloRN.of(
            services.AuthZ.organisation,
            params.filter.organisationId
          ).toString()
        : undefined,
    },
    parseResponse: (response: GraphQLResponse<MigrationAgenciesQuery>) => {
      return buildPaginatedResponseFromPaginatedList({
        ...response.data.migrationLegalEntitiesWithExternalTrustAccount!,
        items: response.data
          .migrationLegalEntitiesWithExternalTrustAccount!.items.filter(
            isPresent
          )
          .map((legalEntity) => ({
            id: legalEntity.legalEntityId,
            ...legalEntity,
          })),
      });
    },
  }),
};

export const migrationAgenciesClient = createClientFromDefinition(
  MigrationAgencyClientDefinition
);
