import { uuidRegEx } from "../../../../api/common";

export enum ManagementCSVHeader {
  managementId = "management_id",
}

export type ManagementCSVRow = {
  [ManagementCSVHeader.managementId]: string;
};

export function validateManagementCSVRow(row: ManagementCSVRow): void {
  const managementId = row[ManagementCSVHeader.managementId];
  if (!uuidRegEx.test(managementId)) {
    throw new Error(`managementId: ${managementId} is not a valid UUID.`);
  }
}
