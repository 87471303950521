import { FileField, FileInput, required, SimpleForm } from "react-admin";
import React from "react";
import { Create } from "../../../common";

const requiredValidate = [required()];

const VirtualAccountsCreate = (props: Record<string, any>) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <FileInput
          source="file"
          label="CSV file"
          accept="text/csv"
          validate={requiredValidate}
        >
          <FileField source="file" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export { VirtualAccountsCreate };
