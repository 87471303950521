import moment from "moment-timezone";
import { Maybe } from "../../api";
import { getTenancyType, TenancyType } from "./getTenancyType";

interface TenancyInterface {
  id: string;
  startDate?: Maybe<string>;
  endDate?: Maybe<string>;
  voidedAt?: Maybe<string>;
}

export function sortTenancies<T extends TenancyInterface>(tenancies: T[]): T[] {
  return [
    ...tenancies.filter((tenancy) => typeOfTenancy(tenancy) === "Active"),
    ...tenancies.filter((tenancy) => typeOfTenancy(tenancy) === "Ingoing"),
    ...tenancies
      .filter((tenancy) => typeOfTenancy(tenancy) === "Former")
      .sort((tenancy1, tenancy2) => {
        if (!tenancy1.endDate) {
          throw new Error(`Former tenancy without end date: ${tenancy1.id}`);
        }

        if (!tenancy2.endDate) {
          throw new Error(`Former tenancy without end date: ${tenancy2.id}`);
        }

        if (moment(tenancy1.endDate).isAfter(moment(tenancy2.endDate))) {
          return -1;
        }

        if (moment(tenancy1.endDate).isBefore(moment(tenancy2.endDate))) {
          return 1;
        }

        return 0;
      }),
    ...tenancies.filter((tenancy) => typeOfTenancy(tenancy) === "Void"),
  ];
}

function typeOfTenancy<T extends TenancyInterface>(tenancy: T): TenancyType {
  return getTenancyType(tenancy.startDate, tenancy.endDate, !!tenancy.voidedAt);
}
