import { AiloRN } from "@ailo/ailorn";
import React from "react";
import {
  AutocompleteInput,
  regex,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Company } from "../../api";
import { Create, formatCompanyOptionText, ReferenceInput } from "../../common";

const redirect = (basePath: string, id: string, data: Record<string, any>) => {
  return `/${Company}/${AiloRN.from(data.ownerLegalEntity)?.internalId}/show`;
};

const BankAccountBlackListsCreate = (props: Record<string, any>) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          alwaysOn
          label="Legal Entity"
          source="ownerLegalEntity"
          reference="Companies"
          filterToQuery={(searchTerm: any) => ({
            searchTerm,
          })}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText={formatCompanyOptionText}
            fullWidth
            disabled
          />
        </ReferenceInput>
        <TextInput
          label="Bsb"
          source="bsb"
          validate={[
            required(),
            regex(/^\d{6}$/, "BSB number must contain 6 digits only"),
          ]}
        />
        <TextInput
          label="Account Number"
          source="accountNumber"
          validate={[
            required(),
            regex(
              /^\d{6,9}$/,
              "Account no. must contain 6 to 9 digits, please prefix 0 if provided account no. has 5 digits"
            ),
          ]}
        />
        <TextInput
          label="Description"
          source="description"
          defaultValue="Agency Trust Account"
          validate={[required()]}
          disabled
        />
      </SimpleForm>
    </Create>
  );
};

export { BankAccountBlackListsCreate };
