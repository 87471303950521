import { DELETE, GET_ONE } from "react-admin";
import gql from "graphql-tag";
import {
  DeleteCrnInput,
  GetCrnInput,
  UpsertCrnInput,
} from "../../graphql/types.generated";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  DeleteCrnMutation,
  GetCrnQuery,
  UpsertCrnMutation,
} from "./centrepayCrnClient.generated";

export const UPSERT_CRN = "upsert_crn";

export const CentrepayCrnClientDefinition = {
  [GET_ONE]: (params: { input: GetCrnInput }) => ({
    query: gql`
      query getCrn($input: GetCrnInput!) {
        getCrn(input: $input) {
          crn
        }
      }
    `,
    variables: params,
    parseResponse: (response: GraphQLResponse<GetCrnQuery>) => {
      return {
        data: response.data.getCrn?.crn,
      };
    },
  }),

  [UPSERT_CRN]: (params: { input: UpsertCrnInput }) => ({
    query: gql`
      mutation upsertCrn($input: UpsertCrnInput!) {
        upsertCrn(input: $input) {
          legalEntity
          managingEntity
          crn
        }
      }
    `,
    variables: params,
    parseResponse: (response: GraphQLResponse<UpsertCrnMutation>) => {
      return {
        data: response.data.upsertCrn,
      };
    },
  }),
  [DELETE]: (params: { input: DeleteCrnInput }) => ({
    query: gql`
      mutation deleteCrn($input: DeleteCrnInput!) {
        deleteCrn(input: $input)
      }
    `,
    variables: params,
    parseResponse: (response: GraphQLResponse<DeleteCrnMutation>) => {
      return {
        data: response.data,
      };
    },
  }),
};

export const centrepayCrnClient = createClientFromDefinition(
  CentrepayCrnClientDefinition
);
