import { capitalize } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  FunctionField,
  ListProps,
  SelectInput,
  TextField,
} from "react-admin";
import { FeeBlueprint, OperationData } from "../../api";
import {
  List,
  MoneyField,
  OrganisationField,
  OrganisationFilter,
  StatusField,
} from "../../common";
import { allHumanizedTypes, humanize } from "./feeBlueprintTypes";

type FeeBlueprintRecord = OperationData<
  typeof FeeBlueprint,
  "GET_LIST"
>[number];

function Filters(props: Omit<FilterProps, "children">) {
  return (
    <Filter {...props}>
      <OrganisationFilter source="organisationId" alwaysOn />
      <SelectInput
        source="type"
        alwaysOn
        choices={allHumanizedTypes}
        emptyText="All"
      />
      <SelectInput
        source="status"
        alwaysOn
        choices={["active", "archived"].map((status) => ({
          id: status,
          name: capitalize(status),
        }))}
        emptyText="All"
      />
    </Filter>
  );
}

export const FeeBlueprintList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      title="Fee Blueprints"
      bulkActionButtons={false}
      filters={<Filters />}
      filterDefaultValues={{
        status: "active",
      }}
    >
      <Datagrid rowClick="show">
        <OrganisationField source="organisation" sortable={false} />
        <TextField label="Name" source="name" sortable={false} />
        <FunctionField<FeeBlueprintRecord>
          label="Fee type"
          render={(record) => {
            return record?.type ? humanize(record?.type) : null;
          }}
        />
        <FunctionField<FeeBlueprintRecord>
          label="Default Price"
          render={(record) =>
            record!.fixedAmount ? (
              <MoneyField
                label="Default Price"
                source="fixedAmount"
                frequencySource="frequency"
                sortable={false}
              />
            ) : (
              `${record!.oneWeekRentPercentage} weeks of rent`
            )
          }
        />
        <DateField
          label="Created At"
          source="createdAt"
          showTime
          sortable={false}
        />
        <FunctionField<FeeBlueprintRecord>
          label="Status"
          render={(record) => (
            <StatusField
              record={{
                status: record!.archived ? "archived" : "active",
              }}
              source="status"
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};
