import { Button } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import React, { useState } from "react";
import { DateInput, required, TextInput } from "react-admin";
import { Subscription } from "../../api/ResourceName";
import { UPDATE_SUBSCRIPTION_END_DATE } from "../../api/resources/Subscription/subscriptionClient";
import { SimpleFormDialog, useMutation } from "../../common";

export function UpdateSubscriptionButton({
  subscriptionId,
  endsOn,
  style,
}: {
  subscriptionId: string;
  endsOn?: string;
  style?: React.CSSProperties;
}): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Subscription,
    type: UPDATE_SUBSCRIPTION_END_DATE,
  });

  return (
    <div style={style}>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<UpdateIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Update
      </Button>
      {dialogOpen && (
        <SimpleFormDialog
          title="Update Subscription"
          submitLabel="Update"
          cancelLabel="Back"
          initialValues={{ subscriptionId, endsOn }}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          save={(data) => mutate({ payload: { data } })}
        >
          <TextInput
            source="subscriptionId"
            disabled
            fullWidth
            validate={[required()]}
          />
          <DateInput label="End Date" source="endsOn" />
        </SimpleFormDialog>
      )}
    </div>
  );
}
