import { Box } from "@material-ui/core";
import React from "react";
import { Company, OperationData, Person } from "../../../../../api";
import { DetailCard } from "../../../../../common/ui/cards";
import { CompanyBasicInfo } from "../companyBasicInfo";
import { EditConsumerButton } from "./editConsumerButton";
import { PersonBasicInfo } from "./personBasicInfo";
import { EditCompanyButton } from "./editCompanyButton";

type LegalEntity =
  | OperationData<typeof Person, "GET_ONE">
  | OperationData<typeof Company, "GET_ONE">;

export const ConsumerBasicInfoCard = ({
  record,
}: {
  record: LegalEntity;
}): React.ReactElement => {
  return (
    <DetailCard
      title="Basic Information"
      action={
        <Box display="flex">
          {record.__typename === "Person" ? (
            <EditConsumerButton person={record} />
          ) : (
            <EditCompanyButton company={record} />
          )}
        </Box>
      }
      contents={
        record.__typename === "Person" ? (
          <PersonBasicInfo record={record} />
        ) : (
          <CompanyBasicInfo record={record} />
        )
      }
    />
  );
};
