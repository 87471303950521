import { formatLegalEntityName } from "@ailo/domain-helpers";
import { isPresent } from "ts-is-present";
import { ManagementFolioShowRecord } from "./types";

export function getOwnerNames(
  owners: ManagementFolioShowRecord["owners"]
): string[] {
  const ownerNames = (owners ?? [])
    .filter(isPresent)
    .map((ownership) => ownership.owner)
    .filter(isPresent)
    .map((owner) => formatLegalEntityName(owner));
  return ownerNames;
}
