import { TextInput } from "react-admin";
import React from "react";
import { SimpleFormDialog } from "../../common";
import { validateCrn, validateCrnUppercase } from "./validateCrn";

export function UpsertCrnDialog({
  existingCrn,
  show,
  onClose,
  submit,
}: {
  existingCrn: string;
  show: boolean;
  onClose: () => void;
  submit: (crn: string) => Promise<void>;
}): React.ReactElement {
  return (
    <SimpleFormDialog
      title={existingCrn ? "Update Centrepay CRN" : "Create Centrepay CRN"}
      open={show}
      onClose={onClose}
      record={{ crn: existingCrn }}
      save={async ({ crn }: { crn: string }) => {
        await submit(crn);
      }}
    >
      <TextInput source="crn" validate={[validateCrn, validateCrnUppercase]} />
    </SimpleFormDialog>
  );
}
