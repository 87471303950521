import React, { ReactElement } from "react";
import { ManagementListRecord } from "./managementsList";
import { ConsumerChips } from "./ConsumerChips";

function OwnerChips({
  record,
}: {
  record: ManagementListRecord;
}): ReactElement | null {
  const consumers = record.owners?.flatMap((o) => o?.owner) ?? [];
  return <ConsumerChips consumers={consumers} />;
}

export { OwnerChips };
