import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    borderRadius: 20,
  },
}));
export const SimpleFormDialogToolbar = ({
  pristine,
  allowSubmitWhenPristine = false,
  invalid,
  saving,
  submitLabel = "Confirm",
  cancelLabel = "Cancel",
  handleSubmit,
  onCancel,
}: any) => {
  const classes = useStyles();
  return (
    <DialogActions
      style={{
        justifyContent: "space-between",
        padding: 24,
      }}
    >
      <Button
        className={classes.root}
        onClick={onCancel}
        color="secondary"
        variant="outlined"
      >
        {cancelLabel}
      </Button>
      <Button
        disabled={invalid || (pristine && !allowSubmitWhenPristine) || saving}
        className={classes.root}
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
      >
        {submitLabel}
      </Button>
    </DialogActions>
  );
};
