import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import { DateTimeField, IdField } from "../../common";

export function PaymentMethodShow(props: any) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <IdField />
        <TextField source="__typename" label="Type" />
        <DateTimeField source="deletedAt" emptyText="N/A" />
      </SimpleShowLayout>
    </Show>
  );
}
