import { InMemoryCache } from "apollo-cache-inmemory";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { envVars } from "../../../../envVars";

export function buildClient(): ApolloClient<unknown> {
  const cache = new InMemoryCache();
  const token = localStorage.getItem("access_token");

  const link = new HttpLink({
    uri: `${envVars.adminGateway.domain}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return new ApolloClient({
    cache,
    link,
  });
}
