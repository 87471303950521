import { useCallback } from "react";
import { useMutation } from "../../../common/api/useMutation";
import { UpsertCrnInput } from "../../../api/graphql/types.generated";
import { CentrepayCrn } from "../../../api/ResourceName";
import { UPSERT_CRN } from "../../../api/resources/CentrepayCrn/centrepayCrnClient";

export function useUpsertCrn(
  legalEntity: string,
  managingEntity: string
): { upsertCrn: (crn: string) => Promise<void> } {
  const [upsertCrnMutation] = useMutation<{
    input: UpsertCrnInput;
  }>({
    resource: CentrepayCrn,
    type: UPSERT_CRN,
  });

  const setCrn = useCallback(
    async (crn: string) => {
      return upsertCrnMutation({
        payload: {
          input: {
            crn,
            legalEntity,
            managingEntity,
          },
        },
      });
    },
    [upsertCrnMutation, legalEntity, managingEntity]
  );
  return { upsertCrn: setCrn };
}
