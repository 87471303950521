import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import React from "react";

const SelectedManagement = ({ managingEntityName, startDate }: any) => {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Box pr={10}>{managingEntityName}</Box>
      <Grid item>{startDate}</Grid>
    </Grid>
  );
};

export { SelectedManagement };
