import Tooltip from "@material-ui/core/Tooltip";
import ArchiveIcon from "@material-ui/icons/Archive";
import AttachmentIcon from "@material-ui/icons/AttachmentSharp";
import React from "react";
import {
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  FunctionField,
  ListProps,
  TextField,
} from "react-admin";
import { Bill, OperationData } from "../../api";
import {
  BillAgencyStatusInput,
  BulkMutationOneByOneButton,
  List,
  ManagementFilter,
  MoneyField,
  OrganisationFilter,
  PropertyField,
} from "../../common";
import { BillAgencyStatusBadge } from "../../features/Bill/BillAgencyStatusBadge";
import { BillPayeeField } from "../../features/Bill/BillPayeeField";
import { BulkZipBillAttachmentsButton } from "./BulkZipBillAttachmentsButton";

type BillRecord = OperationData<typeof Bill, "GET_LIST">[number];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BulkActionProps = any;

function BulkActionButtons(props: BulkActionProps) {
  return (
    <>
      <BulkMutationOneByOneButton
        type="archive"
        label="Cancel bill(s)"
        icon={<ArchiveIcon />}
        {...props}
      />
      <BulkZipBillAttachmentsButton {...props} />
    </>
  );
}

function Filters(
  props: JSX.IntrinsicAttributes &
    Omit<FilterProps, "children"> & { children?: React.ReactNode }
) {
  return (
    <Filter {...props}>
      <OrganisationFilter
        label="Organisation"
        source="organisationId"
        alwaysOn
      />
      <ManagementFilter label="Management" source="managementId" alwaysOn />
      <BillAgencyStatusInput
        label="Bill Status"
        source="agencyStatus"
        alwaysOn
      />
      <BooleanInput source="showManagementFees" />
      <DateInput source="paymentDateFrom" alwaysOn />
      <DateInput source="paymentDateTo" alwaysOn />
    </Filter>
  );
}

export const BillList = (
  props: Omit<ListProps, "bulkActionButtons" | "filters" | "sort">
): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={<BulkActionButtons />}
      filters={<Filters />}
      sort={{
        field: "DueDate",
        order: "Desc",
      }}
    >
      <Datagrid rowClick="show">
        <PropertyField
          label="Property"
          source="management.property"
          managementIdSource="management.id"
          sortable={false}
        />
        <BillPayeeField label="Payee" sortable={false} source="payee" />
        <TextField
          label="Invoice Number"
          source="invoiceNumber"
          sortable={false}
        />
        <TextField
          label="Organisation"
          source="organisation.name"
          sortable={false}
        />
        <MoneyField source="amount" sortable={false} />
        <DateField source="dueDate" />
        <DateField label="Payment Date" source="liabilityState.paymentDate" />
        <DateField source="createdAt" showTime />
        <FunctionField<BillRecord>
          label="Status"
          render={(record) =>
            record?.agencyStatus ? (
              <BillAgencyStatusBadge agencyStatus={record.agencyStatus} />
            ) : (
              <></>
            )
          }
        />
        <FunctionField<BillRecord>
          label=""
          render={(record) => {
            const attachmentCount = record?.attachments?.length;
            return attachmentCount ? (
              <Tooltip
                title={`${attachmentCount} Attachment(s)`}
                placement="right"
              >
                <AttachmentIcon />
              </Tooltip>
            ) : null;
          }}
        />
      </Datagrid>
    </List>
  );
};
