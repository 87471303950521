import { SelectInput } from "react-admin";
import React from "react";

export function BillAgencyStatusInput(props: any): React.ReactElement | null {
  return (
    <SelectInput
      choices={[
        "Cancelled",
        "Refunded",
        "RefundFailed",
        "AwaitingPayment",
        "Overdue",
        "Due",
        "PaymentSent",
        "Paid",
      ].map((id) => ({
        id,
        name: id,
      }))}
      {...props}
    />
  );
}
