import { List } from "@material-ui/core";
import React from "react";
import { Moment } from "moment-timezone";
import { StatementProgressListItem } from "./StatementProgressListItem";
import { StatementType } from "../../../../api/graphql/types.generated";

export interface StatementProgress {
  id: string;
  statementId?: string;
  startDate: string;
  endDate: string;
  isSuccessful?: boolean;
  fileId?: string;
  fileUrl?: string;
  billsZipFileUrl?: string;
  errorMessage?: string;
  createdAt: Moment;
  dryRunEmail?: string;
  sendEmail?: boolean;
  type?: StatementType;
}

export function StatementProgressesList({
  statementProgresses,
  onRetryGeneratingStatement,
  style,
}: {
  statementProgresses: StatementProgress[];
  onRetryGeneratingStatement?: (progressId: string) => void;
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <List
      style={{
        position: "relative",
        overflow: "auto",
        paddingRight: 25,
        ...style,
      }}
    >
      {statementProgresses.map((statementProgress) => (
        <StatementProgressListItem
          key={statementProgress.id}
          statementProgress={statementProgress}
          onRetryGeneratingStatement={onRetryGeneratingStatement}
        />
      ))}
    </List>
  );
}
