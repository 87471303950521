import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    borderRadius: 20,
  },
}));
export const CloseDialogActions = ({ closeText, onClick }: any) => {
  const classes = useStyles();
  return (
    <DialogActions
      style={{
        padding: 24,
      }}
    >
      <Button
        className={classes.root}
        variant="contained"
        color="secondary"
        onClick={onClick}
      >
        {closeText || "Close"}
      </Button>
    </DialogActions>
  );
};
