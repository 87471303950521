import React from "react";
import { DetailCard } from "../../../../../common/ui/cards";
import { MigrationTaskInfo } from "./MigrationTaskInfo";
import { RefreshMigrationDataButton } from "./RefreshMigrationDataButton";

interface Props {
  legalEntityId: any;
  migrationTask?: any;
  hasActiveExternalTrustAccount: boolean;
}

export const MigrationTaskCard = ({
  legalEntityId,
  migrationTask,
  hasActiveExternalTrustAccount,
}: Props) => {
  if (!migrationTask && !hasActiveExternalTrustAccount) return null;
  return (
    <DetailCard
      title="Data Refresh"
      subheader="Migration"
      contents={
        migrationTask ? (
          <MigrationTaskInfo migrationTask={migrationTask} />
        ) : (
          'No data refresh has been done. Click "START DATA REFRESH" button to trigger one.'
        )
      }
      action={
        hasActiveExternalTrustAccount ? (
          <RefreshMigrationDataButton legalEntityId={legalEntityId} />
        ) : undefined
      }
    />
  );
};
