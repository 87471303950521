import React from "react";
import { Button, DialogActions, DialogContentText } from "@material-ui/core";
import { useMutation, useNotify, useRefresh } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { AiloRN } from "@ailo/ailorn";
import { Colors } from "../../common/ui/colors";
import { CopyToClipboardButton } from "../../common";

const ReverseTransactionDialog = ({ open, onClose, transactionId }: any) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const [reverseTransaction] = useMutation(
    {
      type: "reverse_transaction",
      resource: "Transactions",
      payload: {
        businessTxId: AiloRN.fromString(transactionId).internalId,
      },
    },
    {
      onSuccess: (result) => {
        onClose();
        refresh();
        notify(
          `Transaction ${transactionId} is being reversed. Status: ${result.data}`
        );
      },
      onFailure: (error) => {
        setErrorMessage(error.toString());
        notify(
          `Failed to reverse transaction ${transactionId}. Error: ${error}`,
          "error"
        );
      },
    }
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reverse Transaction (Internal)</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Reverse Transaction will reverse all the entries created within Ailo.
          It will not emit any external direct debit or credit queries, i.e. No
          real world payment transaction will be created, purely Ailo adjustment
          internally. This is mostly used for the scenario of late reversal as
          follows:
        </DialogContentText>
        <DialogContentText>
          1. RENT late reversal, make sure the management fee has been refund
          first before late reversal of RENT. Details:
          https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
        </DialogContentText>
        <DialogContentText>
          2. BILL Hop2 late reversal, make sure it is bill HOP2 BT id, no
          overdraft allowance will be created against Ailo bank wallet.
        </DialogContentText>
        <DialogContentText>
          3. BILL Hop1 late reversal, only if HOP2 is VOID or FAILED. Make sure
          it is bill HOP1 BT id, no overdraft allowance will be created against
          supplier wallet.
        </DialogContentText>
        <DialogContentText>
          4. Auto or manual Wallet withdraw/transfer late reversal, no overdraft
          allowance will be created.
        </DialogContentText>

        <DialogContentText
          style={{
            color: Colors.TEXT.RED,
          }}
        >
          {errorMessage}
          <CopyToClipboardButton value={errorMessage} />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            reverseTransaction();
          }}
          color="primary"
        >
          Reverse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ReverseTransactionField = ({
  transactionId,
  status,
  style,
}: {
  transactionId: string;
  status: string;
  style?: Record<string, any>;
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpenDialog(false);
  }, []);
  const onClick = React.useCallback(() => {
    setOpenDialog(true);
  }, []);
  const reverseEnabled = status === "SUCCESS";
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={onClick}
        disabled={!reverseEnabled}
        style={style}
      >
        Reverse Transaction (Internal)
      </Button>
      <ReverseTransactionDialog
        open={openDialog}
        onClose={closeDialog}
        transactionId={transactionId}
      />
    </div>
  );
};
