import IconEdit from "@material-ui/icons/Edit";
import React, { ReactElement, useState } from "react";
import { Button } from "react-admin";
import { BulkUpdateManagementFeeBlueprintsDialog } from "./BulkUpdateManagementFeeBlueprintsDialog";

export function BulkUpdateManagementFeeBlueprintsButton(): ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={onClick} label="Bulk Update Management Fee Blueprints">
        <IconEdit />
      </Button>
      <BulkUpdateManagementFeeBlueprintsDialog open={open} onClose={onClose} />
    </>
  );
}
