import React, { useCallback } from "react";
import { required } from "react-admin";
import { useFormState, useForm } from "react-final-form"; //eslint-disable-line
import { FeeTaxCategoryInput as BaseFeeTaxCategoryInput } from "../../common";
import { taxCategoryDefaultValues } from "./taxCategoryDefaultValues";

const nonOverWriteableFields = new Set<string>(["name", "type", "chargeType"]);

export const FeeTaxCategoryInput = ({
  autoPopulatedFields,
}: {
  autoPopulatedFields: Set<string>;
}): React.ReactElement => {
  const { batch, change } = useForm();
  const { values } = useFormState();

  const onChange = useCallback(
    (taxCategoryId: keyof typeof taxCategoryDefaultValues) => {
      batch(() => {
        ["name", "type", "chargeType"].forEach((field) => {
          if (
            !values.name ||
            autoPopulatedFields.has(field) ||
            !nonOverWriteableFields.has(field)
          ) {
            change(
              field,
              taxCategoryDefaultValues[taxCategoryId][
                field as "name" | "type" | "chargeType"
              ]
            );
            autoPopulatedFields.add(field);
          }
        });
      });
    },
    [autoPopulatedFields, batch, change, values.name]
  );

  return (
    <BaseFeeTaxCategoryInput
      source="taxCategoryId"
      filter={{
        name: Object.entries(taxCategoryDefaultValues).map(
          ([_key, value]) => value.name
        ),
      }}
      validate={[required()]}
      onChange={onChange}
    />
  );
};
