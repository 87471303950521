import React, { useCallback, useState } from "react";
import {
  TextInput,
  required,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import Alert from "@material-ui/lab/Alert";
import { SimpleFormDialog } from "../../../common";
import { PropertyShowRecord } from "./types";

type Props = {
  property: PropertyShowRecord;
  onDismiss: () => void;
};

export const EditAddressForm = ({
  property,
  onDismiss,
}: Props): React.ReactElement => {
  const [error, setError] = useState("");
  const clearError = useCallback(() => {
    setError("");
  }, []);
  const notify = useNotify();
  const refresh = useRefresh();
  const [updateProperty] = useMutation(
    {
      type: "UPDATE",
      resource: "Properties",
      payload: {},
    },
    {
      onSuccess: () => {
        onDismiss();
        notify(`Property ${property.id} has been updated`);
        refresh();
      },
      onFailure: (errorMessage) => {
        setError(`Fail to update property ${property.id}: ${errorMessage}`);
      },
    }
  );

  const save = useCallback(
    async (updatedProperty): Promise<void> => {
      const { id, address } = updatedProperty;
      await updateProperty({
        payload: {
          updatePropertyInput: {
            id,
            address: { ...address, __typename: undefined },
          },
        },
      });
    },
    [updateProperty]
  );

  const validate = [required()];

  return (
    <SimpleFormDialog
      onClose={() => onDismiss()}
      record={property}
      save={save}
      submitOnEnter={false}
      title="Update Property Address"
      submitLabel="Update Property Address"
    >
      <Alert severity="warning" style={{ marginBottom: 16 }}>
        ONLY use this to fix minor typos. For explanation see{" "}
        <a
          href="https://ailo.atlassian.net/wiki/spaces/AILO/pages/2361852286/Update+property+address"
          target="_blank"
          rel="noreferrer"
        >
          updating a property address
        </a>
        .
      </Alert>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <TextInput
        label="Property Id"
        source="id"
        disabled
        validate={validate}
        fullWidth
        onChange={clearError}
      />
      <TextInput
        label="Unit Street Number"
        source="address.unitStreetNumber"
        validate={validate}
        fullWidth
        onChange={clearError}
      />
      <TextInput
        label="Street Name"
        source="address.streetName"
        validate={validate}
        fullWidth
        onChange={clearError}
      />
      <TextInput
        label="Suburb"
        source="address.suburb"
        fullWidth
        onChange={clearError}
      />
      <TextInput
        label="State"
        source="address.state"
        fullWidth
        onChange={clearError}
      />
      <TextInput
        label="Postcode"
        source="address.postcode"
        fullWidth
        onChange={clearError}
      />
      <TextInput
        label="Country"
        source="address.country"
        validate={validate}
        fullWidth
        onChange={clearError}
      />
    </SimpleFormDialog>
  );
};
