import React, { useState } from "react";
import { Button, DialogContentText } from "@material-ui/core";
import { useMutation, useNotify, useRefresh } from "react-admin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { AiloRN } from "@ailo/ailorn";
import TextField from "@material-ui/core/TextField";
import { CopyToClipboardButton } from "../../common";
import {
  ConfirmCloseDialogActions,
  SlideUpDialog,
} from "../../common/ui/dialogs";

const VoidTransactionDialog = ({ open, onClose, transaction }: any) => {
  const [description, setDescription] = useState("Void transaction");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event: { target: { value: string } }) => {
    setDescription(event.target.value);
  };

  const id = AiloRN.fromString(transaction).internalId;
  const notify = useNotify();
  const refresh = useRefresh();
  const [voidTransaction] = useMutation(
    {
      type: "void_transaction",
      resource: "Transactions",
      payload: {
        id,
        description,
      },
    },
    {
      onSuccess: () => {
        onClose();
        refresh();
        notify(`Transaction ${id} has been voided`);
      },
      onFailure: (error) => {
        setDescription("Void transaction");
        setErrorMessage(error.toString());
        notify(`Failed to void transaction ${id}. Error: ${error}`, "error");
      },
    }
  );
  return (
    <SlideUpDialog open={open} onClose={onClose}>
      <DialogTitle>Void Transaction</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Voiding a transaction will attempt to stop the payment from being
          processed
        </DialogContentText>

        <TextField
          autoFocus
          id="name"
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleChange}
          fullWidth
          style={{
            marginTop: 30,
          }}
        />
        <DialogContentText
          style={{
            marginTop: 30,
          }}
        >
          {errorMessage}
          <CopyToClipboardButton value={errorMessage} />
        </DialogContentText>
      </DialogContent>
      <ConfirmCloseDialogActions
        confirmText="Void"
        onConfirmClick={voidTransaction}
        onCancelClick={onClose}
      />
    </SlideUpDialog>
  );
};

export const VoidTransactionField = ({
  transaction,
  status,
  paymentChannel,
}: {
  transaction: string;
  status: string;
  paymentChannel: string | null | undefined;
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const onClose = () => {
    setOpenDialog(false);
  };

  const onClick = () => {
    setOpenDialog(true);
  };

  const voidingEnabled =
    (status === "NEW" || status === "PENDING" || status === "SUCCESS") &&
    paymentChannel?.toUpperCase() !== "NPP";
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={onClick}
        disabled={!voidingEnabled}
      >
        Void Transaction
      </Button>
      <VoidTransactionDialog
        open={openDialog}
        onClose={onClose}
        transaction={transaction}
      />
    </div>
  );
};
