import { useNotify, useRefresh } from "react-admin";
import React, { useCallback, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useMutation } from "ra-core";
import { Tenancy } from "../../../api";
import { ALTER_TENANCY_START_DATE } from "../../../api/resources/Tenancy/tenanciesClient";
import { TenancyStartDateForm } from "./TenancyStartDateForm";
import { Tenancy as TenancyType } from "../../../api/graphql/types.generated";

export const EditTenancyStartDateFormDialog = ({
  open,
  onClose,
  tenancy,
}: {
  open: boolean;
  onClose: () => void;
  tenancy: TenancyType;
}): React.ReactElement => {
  const [error, setError] = useState("");
  const clearError = useCallback(() => {
    setError("");
  }, []);
  const notify = useNotify();
  const refresh = useRefresh();
  const [alterTenancyStateDate] = useMutation(
    {
      type: ALTER_TENANCY_START_DATE,
      resource: Tenancy,
      payload: {},
    },
    {
      onSuccess: () => {
        onClose();
        notify(`Tenancy ${tenancy.id} start date has been updated`);
        refresh();
      },
      onFailure: (errorReason) => {
        const errorMessage = `Fail to update tenancy ${tenancy.id} start date: ${errorReason}`;
        setError(errorMessage);
      },
    }
  );
  const save = React.useCallback(
    async (updatedTenancy) => {
      const { id, startDate } = updatedTenancy;
      await alterTenancyStateDate({
        payload: {
          tenancyId: id,
          startDate,
        },
      });
    },
    [alterTenancyStateDate]
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit tenancy start date</DialogTitle>

      <DialogContent>
        <TenancyStartDateForm
          entity={tenancy}
          save={save}
          error={error}
          onFormChange={clearError}
        />
      </DialogContent>
    </Dialog>
  );
};
