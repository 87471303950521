import { useState, useCallback, useEffect } from "react";
import { useMutation } from "react-admin";
import { lookup as lookUpMimeType } from "mime-types";
import { uniqBy } from "lodash";
import { convertFileToBuffer } from "../utils";

type FileToUpload = {
  rawFile: File;
  src: string;
  title: string;
};

function getFileStatuses(postFileUploadOperationStatus?: string) {
  return {
    IDLE: "Uploading is not in progress",
    RESERVING: "(1/4) Starting the upload",
    RESERVING_COMPLETE: "(1/4) File entry reserved on server",
    UPLOADING: "(2/4) Uploading file to server",
    UPLOADING_COMPLETE: "(2/4) Uploading complete",
    SERVER_SIDE_VALIDATION: "(3/4) Validating file on server",
    SERVER_SIDE_VALIDATION_COMPLETE: "(3/4) Validation complete",
    CLAIMING: `(4/4) ${
      postFileUploadOperationStatus || "Attaching file to the resource"
    }`,
  };
}

export function useFileUpload({
  onSuccess,
  onError,
  initialFiles = [],
  postFileUploadOperationStatus,
}: {
  onSuccess: (fileGenerated: any[]) => void;
  onError: (error: string) => void;
  initialFiles?: any[];
  postFileUploadOperationStatus?: string;
}): {
  status: string;
  isIdle: boolean;
  generatedFiles: any[];
  startFileUpload: (fileToUpload: FileToUpload) => void;
  deleteFile: (arg0: any) => void;
} {
  const UPLOAD_STATUSES = getFileStatuses(postFileUploadOperationStatus);
  const [status, setStatus] = useState(UPLOAD_STATUSES.IDLE);
  const [processingFile, setProcessingFile] = useState<any>();
  const [generatedFiles, setGeneratedFiles] = useState(initialFiles);
  const [reservedFile, setReservedFile] = useState<any>();
  const onErrorCallback = useCallback(
    (errorMessage) => {
      setStatus(UPLOAD_STATUSES.IDLE);
      onError(errorMessage || "Unexpected error");
    },
    [UPLOAD_STATUSES.IDLE, onError]
  );
  const uploadFile = useCallback(async () => {
    if (!reservedFile) {
      return;
    }

    setStatus(UPLOAD_STATUSES.UPLOADING);
    const body = await convertFileToBuffer(processingFile);

    try {
      const response = await fetch(reservedFile.uploadUrl, {
        method: "PUT",
        body,
        headers: {
          "Content-Type":
            lookUpMimeType(processingFile.title) || "application/octet-stream",
        },
      });

      if (!response.ok) {
        onErrorCallback("Upload to storage yields error");
        return;
      }
    } catch {
      onErrorCallback("Upload to storage yields error");
      return;
    }

    setStatus(UPLOAD_STATUSES.UPLOADING_COMPLETE);
  }, [
    UPLOAD_STATUSES.UPLOADING,
    UPLOAD_STATUSES.UPLOADING_COMPLETE,
    onErrorCallback,
    processingFile,
    reservedFile,
  ]);
  const [startFileUploadMutation] = useMutation(
    {
      resource: "Files",
      type: "start_file_upload",
      payload: {},
    },
    {
      onSuccess({ data }) {
        setReservedFile(data);
        setStatus(UPLOAD_STATUSES.RESERVING_COMPLETE);
      },
      onFailure({ error: fileUploadError }) {
        onErrorCallback(fileUploadError);
      },
    }
  );
  const [validateFileMutation] = useMutation(
    {
      resource: "Files",
      type: "validate_file",
      payload: {},
    },
    {
      onSuccess: ({ data }) => {
        if (data.ok) {
          setStatus(UPLOAD_STATUSES.SERVER_SIDE_VALIDATION_COMPLETE);
        } else {
          onErrorCallback("Failed to validate file");
        }
      },
      onFailure: ({ error: fileUploadError }) => {
        onErrorCallback(fileUploadError);
      },
    }
  );
  useEffect(() => {
    if (UPLOAD_STATUSES.RESERVING_COMPLETE !== status) return;
    uploadFile();
  }, [UPLOAD_STATUSES.RESERVING_COMPLETE, status, uploadFile]);
  useEffect(() => {
    if (status !== UPLOAD_STATUSES.UPLOADING_COMPLETE) return;
    setStatus(UPLOAD_STATUSES.SERVER_SIDE_VALIDATION);
    validateFileMutation({
      payload: {
        fileId: reservedFile.file.id,
      },
    });
  }, [
    UPLOAD_STATUSES.SERVER_SIDE_VALIDATION,
    UPLOAD_STATUSES.UPLOADING_COMPLETE,
    reservedFile,
    status,
    validateFileMutation,
  ]);
  useEffect(() => {
    if (status !== UPLOAD_STATUSES.SERVER_SIDE_VALIDATION_COMPLETE) return;
    setStatus(UPLOAD_STATUSES.IDLE);
    generatedFiles.push({
      id: reservedFile.file.id,
      title: processingFile.title,
      src: processingFile.src,
    });
    setGeneratedFiles(uniqBy(generatedFiles, (file) => file.id));
    onSuccess(generatedFiles);
  }, [
    UPLOAD_STATUSES.IDLE,
    UPLOAD_STATUSES.SERVER_SIDE_VALIDATION_COMPLETE,
    generatedFiles,
    onSuccess,
    processingFile,
    reservedFile,
    status,
  ]);
  const startFileUpload = useCallback(
    (data: FileToUpload) => {
      if (data && !generatedFiles.find((file) => file.src === data.src)) {
        setProcessingFile(data);
        setStatus(UPLOAD_STATUSES.RESERVING);
        startFileUploadMutation({
          payload: {
            fileName: data.title,
          },
        });
      }
    },
    [UPLOAD_STATUSES.RESERVING, generatedFiles, startFileUploadMutation]
  );
  const deleteFile = useCallback(
    (fileToRemove) => {
      const updatedFiles = generatedFiles.filter(
        (file) => file.id !== fileToRemove.id
      );
      setGeneratedFiles(updatedFiles);
      onSuccess(updatedFiles);
    },
    [generatedFiles, onSuccess]
  );
  return {
    status,
    isIdle: status === UPLOAD_STATUSES.IDLE,
    generatedFiles,
    startFileUpload,
    deleteFile,
  };
}
