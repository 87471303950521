import { AiloRN, services } from "@ailo/ailorn";
import { CompactShowLayout, RaBox } from "ra-compact-ui";
import React from "react";
import {
  EditButton,
  FunctionField,
  Show,
  TextField,
  useShowController,
} from "react-admin";
import {
  ActionInitiatorField,
  CopyableTextField,
  DeprecatedIdField,
  DetailCard,
  StatusField,
} from "../../common";
import { WalletCard } from "../../features/Wallet/WalletCard";
import { PaymentMethodsCard } from "../Customer/consumers/components/paymentMethods/paymentMethodsCard";
import { useGetWalletByOwner } from "../Property/show/DepositCard";
import { ArchiveSupplierField } from "./ArchiveSupplierField";

const SupplierShow = (props: Record<string, any>) => {
  const { record, basePath, resource } = useShowController(props);
  const walletOwnerAiloRN = AiloRN.of(
    services.Bill.supplier,
    props.id
  ).toString();

  const { data } = useGetWalletByOwner(walletOwnerAiloRN);
  return (
    <Show {...props} actions={false}>
      <CompactShowLayout layoutComponents={[DetailCard, RaBox]}>
        <RaBox display="flex">
          <DetailCard
            cardStyle={{ flex: 1 }}
            title="Supplier"
            action={
              <EditButton
                basePath={basePath}
                resource={resource}
                record={record}
              />
            }
            marginRight="50px"
          >
            <CopyableTextField label="ID" source="id" />
            <FunctionField
              label="Organisation"
              render={(record: any) =>
                record.organisation && (
                  <DeprecatedIdField
                    id={record.organisation.id}
                    displayName={record.organisation.name}
                    resource="Customers"
                  />
                )
              }
            />
            <TextField label="Display Name" source="name" />
            <TextField
              label="Registered Entity Name"
              source="registeredEntityName"
            />
            <TextField label="ABN" source="abn" />
            <TextField
              label="Email for payment receipts "
              source="emailAddress"
            />
            <TextField
              label="Unit/Street Number"
              source="address.unitStreetNumber"
            />
            <TextField label="Street Name" source="address.streetName" />
            <TextField label="Suburb or Town" source="address.suburb" />
            <TextField label="State" source="address.state" />
            <TextField label="Postcode" source="address.postcode" />
            <FunctionField
              label="KYC Data"
              render={(record: any) => (
                <StatusField
                  record={{
                    kycDataComplete: record.kycDataComplete
                      ? "complete"
                      : "incomplete",
                  }}
                  source="kycDataComplete"
                />
              )}
            />
            <TextField label="Country" source="address.country" />
            <ActionInitiatorField
              label="Created"
              initiatedAtSource="createdAt"
              initiatedBySource="createdBy.ailoRN"
            />
            <ActionInitiatorField
              label="Modified"
              initiatedAtSource="modifiedAt"
              initiatedBySource="modifiedBy.ailoRN"
            />
            <ActionInitiatorField
              label="Archived"
              initiatedAtSource="archivedAt"
              initiatedBySource="archivedBy.ailoRN"
            />
            <FunctionField
              label="Archive"
              render={(record: any) => (
                <ArchiveSupplierField
                  supplier={record}
                  disabled={record.archivedAt !== null}
                />
              )}
            />
          </DetailCard>
          <RaBox style={{ flex: 1 }}>
            {record?.ailoRN && record?.organisation?.ailoRN ? (
              <PaymentMethodsCard
                walletOwnerAiloRN={AiloRN.fromString(record.ailoRN)}
                managingOrganisationAiloRN={AiloRN.fromString(
                  record.organisation.ailoRN
                )}
                includeDeleted={false}
              />
            ) : (
              <></>
            )}
            {data ? (
              <WalletCard
                key={data?.id}
                title="Wallet"
                wallet={data}
                walletOwnerAiloRN={walletOwnerAiloRN}
                showTransactions
              />
            ) : (
              <></>
            )}
          </RaBox>
        </RaBox>
      </CompactShowLayout>
    </Show>
  );
};

export { SupplierShow };
