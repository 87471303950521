import { Money } from "@ailo/money";
import Big from "big.js";
import React, { useCallback } from "react";
import { minValue, number, required } from "react-admin";
import { useForm, useFormState } from "react-final-form"; //eslint-disable-line
import { PercentInput } from "../../common";

const PERCENT_DECIMAL_PLACES = 4;

export function FeePercentInput(): React.ReactElement {
  const { change } = useForm();
  const { values } = useFormState();

  const onChange = useCallback(
    (event) => {
      if (
        values.baseAmountType === "OneWeekRent" &&
        values.baseAmount !== undefined
      ) {
        change(
          "amount",
          Money.fromCents(values.baseAmount.cents).multiply(
            new Big(+event.target.value)
              .round(PERCENT_DECIMAL_PLACES)
              .toNumber()
          )
        );
      }
    },
    [change, values.baseAmount, values.baseAmountType]
  );

  return (
    <PercentInput
      source="percentage"
      label="Weeks of rent"
      format="decimal"
      dp={PERCENT_DECIMAL_PLACES}
      onChange={onChange}
      validate={[required(), number(), minValue(0)]}
      fullWidth
    />
  );
}
