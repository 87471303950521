import React from "react";
import { CREATE, CreateProps } from "react-admin";
import { OperationData } from "../../api";
import { Fee, Property } from "../../api/ResourceName";
import { Create } from "../../common";
import { FeeForm as FeeFormV2 } from "./FeeFormV2";
import { FeeForm as FeeFormV1 } from "./FeeForm";

export const FeeCreate = (props: CreateProps): React.ReactElement => {
  const FeeForm = (props.location?.state as {
    hasManagementFeeBlueprintFeature: boolean;
  }).hasManagementFeeBlueprintFeature
    ? FeeFormV2
    : FeeFormV1;

  return (
    <Create {...props}>
      <FeeForm
        redirect={(
          _path: string,
          _id: string,
          data: NonNullable<OperationData<typeof Fee, typeof CREATE>>
        ) =>
          props.history
            ? props.history.goBack()
            : `/${Property}/${data.management.property.id}/show?management=${data.management.id}`
        }
      />
    </Create>
  );
};
