export class FileNameDeDuplicator {
  private fileNames: string[] = [];

  next(name: string): string {
    return this._next(name, 0);
  }

  private _next(name: string, count: number): string {
    const next = this._format(name, count);
    return this._exists(next) ? this._next(name, count + 1) : this._add(next);
  }

  private _add(next: string) {
    this.fileNames.push(next);
    return next;
  }

  private _exists(next: string) {
    return this.fileNames.includes(next);
  }

  private _format(fileName: string, count: number) {
    const name = getFileName(fileName);
    const ext = getFileExtension(fileName);
    return count === 0 ? fileName : `${name} (${count})${ext}`;
  }
}

function getFileExtension(fileName: string): string {
  const ext = /^.+\.([^.]+)$/.exec(fileName);
  return ext == null ? "" : `.${ext[1]}`;
}

function getFileName(fileName: string): string {
  return fileName.replace(/\.[^./]+$/, "");
}
