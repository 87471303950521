import React, { FunctionComponent } from "react";
import { Badge, BadgeType } from "../../../../common/ui";
import {
  BondDisbursalFailureReason,
  TenancyBondStatus,
} from "../../../../api/graphql/types.generated";

type Props = {
  status: TenancyBondStatus;
  failureReason?: BondDisbursalFailureReason;
};

type BondDisplayStatus = {
  text: string;
  type: BadgeType;
};

const claimRequiredDisplayStatus: BondDisplayStatus = {
  text: "Claim required",
  type: "default",
};

const awaitingRefundDisplayStatus: BondDisplayStatus = {
  text: "Awaiting refund",
  type: "default",
};

const claimCompletedDisplayStatus: BondDisplayStatus = {
  text: "Claim completed",
  type: "success",
};

const claimMismatch: BondDisplayStatus = {
  text: "Claim mismatch",
  type: "critical",
};

const allocationPending: BondDisplayStatus = {
  text: "Allocation pending",
  type: "default",
};

const bondResolved: BondDisplayStatus = {
  text: "Bond resolved",
  type: "success",
};

const undefinedStatus: BondDisplayStatus = {
  text: "Undefined",
  type: "default",
};

export const BondStatusBadge: FunctionComponent<Props> = ({
  status,
  failureReason,
}) => {
  const displayStatus = getBondDisplayStatus(status, failureReason);

  return <Badge {...displayStatus} />;
};

export function getBondDisplayStatus(
  status: TenancyBondStatus,
  failureReason?: BondDisbursalFailureReason
): BondDisplayStatus {
  switch (status) {
    case TenancyBondStatus.Unclaimed:
      return claimRequiredDisplayStatus;
    case TenancyBondStatus.AwaitingFunds:
    case TenancyBondStatus.FundsReceived:
    case TenancyBondStatus.Disbursing:
      return awaitingRefundDisplayStatus;
    case TenancyBondStatus.DisbursementFailed:
      return getStatus(failureReason);
    case TenancyBondStatus.ClaimSuccessful:
      return claimCompletedDisplayStatus;
    case TenancyBondStatus.BondResolved:
      return bondResolved;
    default:
      return undefinedStatus;
  }
}

function getStatus(
  failureReason?: BondDisbursalFailureReason
): BondDisplayStatus {
  if (
    failureReason === "ReceivedLessFundsThanClaimed" ||
    failureReason === "ReceivedMoreFundsThanClaimed"
  ) {
    return claimMismatch;
  }
  return allocationPending;
}
