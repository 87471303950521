import React from "react";
import { Box } from "@material-ui/core";
import { CentrepayPaymentDirectiveType } from "../CentrepayPaymentDirectiveType";
import { LinkField } from "../../../common";
import { Tenancy } from "../../../api";

export function OtherTenancyDirective({
  tenancyDirective,
}: {
  tenancyDirective: CentrepayPaymentDirectiveType;
}): React.ReactElement {
  return (
    <Box style={{ display: "flex" }}>
      <LinkField
        record={{ id: tenancyDirective.tenancy.internalId }}
        source="id"
        sourceResource={Tenancy}
        renderText={() => "Centrepay tenancy"}
      />
    </Box>
  );
}
