import React from "react";
import { Edit } from "../../common";
import { Property } from "../../api/ResourceName";
import { RecurringFeeForm } from "./form";

export const RecurringFeeEdit = (props: any) => {
  return (
    <Edit {...props}>
      <RecurringFeeForm
        redirect={(
          _path: any,
          _id: any,
          data: { propertyId: any; managementId: any }
        ) =>
          `/${Property}/${data.propertyId}/show?management=${data.managementId}`
        }
      />
    </Edit>
  );
};
