import { Alert } from "@material-ui/lab";
import React from "react";
import {
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { LedgerEvent, OperationData } from "../../api";
import { DateTimeField, DynamicAiloRNField, IdField } from "../../common";
import { LedgerEventEntriesList } from "./LedgerEventEntriesList";

type LedgerEventRecord = OperationData<typeof LedgerEvent, "GET_ONE">;

export const LedgerEventShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props} title={`Ledger Event #${props.id}`}>
      <SimpleShowLayout>
        <Alert severity="warning">
          This page is still under development, do not use it if you don't know
          what it is.
        </Alert>
        <IdField label="ID" source="id" />
        <DynamicAiloRNField addLabel source="reference" />
        <TextField source="type" />
        <TextField source="reversalDepth" />
        <TextField source="description" />
        <DateTimeField source="createdAt" />
        <DateTimeField source="occurredAt" />
        <TextField source="domainEventKey" />
        <DynamicAiloRNField
          label="Interaction reference"
          addLabel
          source="interaction.reference"
        />
        <DynamicAiloRNField label="Owing" addLabel source="owing.ailoRN" />
        <DynamicAiloRNField
          label="Business transaction"
          addLabel
          source="businessTransaction.ailoRN"
        />

        <FunctionField<LedgerEventRecord>
          label="Entries"
          render={(record) => {
            if (!record?.id) return null;
            return <LedgerEventEntriesList ledgerEventId={record.id} />;
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};
