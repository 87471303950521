import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

export const DeleteIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="16"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6709 6.66247H15.5022C15.4101 6.66247 15.3354 6.58781 15.3354 6.4957V5.66185C15.3354 4.7408 14.5888 3.99414 13.6677 3.99414H10.3323C9.41127 3.99414 8.66461 4.7408 8.66461 5.66185V6.4957C8.66461 6.53993 8.64704 6.58235 8.61576 6.61363C8.58449 6.6449 8.54207 6.66247 8.49784 6.66247H5.32919C4.96077 6.66247 4.66211 6.96114 4.66211 7.32956C4.66211 7.69798 4.96077 7.99664 5.32919 7.99664H18.6709C19.0393 7.99664 19.3379 7.69798 19.3379 7.32956C19.3379 6.96114 19.0393 6.66247 18.6709 6.66247ZM17.0032 8.99727H6.9969C6.81269 8.99727 6.66336 9.1466 6.66336 9.33081V18.67C6.66336 19.4068 7.26069 20.0041 7.99753 20.0041H16.0025C16.7394 20.0041 17.3367 19.4068 17.3367 18.67V9.33081C17.3367 9.1466 17.1874 8.99727 17.0032 8.99727ZM10.8326 17.6693C10.8326 17.9457 10.6086 18.1697 10.3323 18.1697C10.056 18.1697 9.83201 17.9457 9.83201 17.6693V11.6656C9.83201 11.3893 10.056 11.1653 10.3323 11.1653C10.6086 11.1653 10.8326 11.3893 10.8326 11.6656V17.6693ZM13.6677 18.1697C13.944 18.1697 14.168 17.9457 14.168 17.6693V11.6656C14.168 11.3893 13.944 11.1653 13.6677 11.1653C13.3914 11.1653 13.1674 11.3893 13.1674 11.6656V17.6693C13.1674 17.9457 13.3914 18.1697 13.6677 18.1697ZM9.99878 5.66185V6.4957C9.99878 6.58781 10.0734 6.66247 10.1655 6.66247H13.8345C13.9266 6.66247 14.0013 6.58781 14.0013 6.4957V5.66185C14.0013 5.47764 13.8519 5.32831 13.6677 5.32831H10.3323C10.1481 5.32831 9.99878 5.47764 9.99878 5.66185Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="24" height="24" fill="#EE0B4F" />
      </g>
    </SvgIcon>
  );
};
