import React, { useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  useRedirect,
  useShowController,
} from "react-admin";
import { Company, Person } from "../../api/ResourceName";
import { CopyableTextField } from "../../common";

export const LegalEntityShowRedirect = (props: Record<string, any>) => {
  const { record } = useShowController(props);
  const redirect = useRedirect();
  useEffect(() => {
    if (record?.__typename === "Company") {
      redirect(`/${Company}/${record.id}/show`);
    }

    if (record?.__typename === "Person") {
      redirect(`/${Person}/${record.id}/show`);
    }
  }, [record?.__typename, record?.id, redirect]);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <CopyableTextField label="ID" source="id" />
      </SimpleShowLayout>
    </Show>
  );
};
