import { formatAddress } from "@ailo/domain-helpers";
import { Box, Divider, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { Error, Loading } from "react-admin";
import { Tenancy } from "../../api/ResourceName";
import { CloseButton, DetailCard, EditButton } from "../../common";
import { useQuery } from "../../common/api/useQuery";
import { TenantMoneySettingsDescriptionList } from "./TenantMoneySettingsDescriptionList";

export function TenantMoneySettingsCard({ legalEntityAiloRN, allowEdit }: any) {
  const [edit, setEdit] = useState(false);

  const onEdit = (doEdit: boolean | ((prevState: boolean) => boolean)) => {
    setEdit(doEdit);
  };

  const { data, loading, error } = useQuery({
    type: "get_list_for_tenant",
    resource: Tenancy,
    payload: {
      tenantAilorn: legalEntityAiloRN,
      pagination: {
        perPage: 1000,
      },
    },
  });

  if (error) {
    return <Error error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  const tenancies = data ?? [];

  if (tenancies.length === 0) {
    return null;
  }

  return (
    <DetailCard
      title="Renter Money Management"
      action={
        allowEdit && (
          <Box display="flex">
            {edit && <CloseButton onClick={() => onEdit(false)} />}
            {!edit && <EditButton onClick={() => onEdit(true)} />}
          </Box>
        )
      }
    >
      {tenancies.map((tenancy, index) => {
        const address = tenancy.management?.property.address;
        const liabilityId = tenancy.liability?.id;
        return (
          <Box key={tenancy.id} mt={index === 0 ? 0 : 2}>
            <Box mb={1}>
              <Typography variant="subtitle2">
                {address ? formatAddress(address) : "Unknown address"}
              </Typography>
            </Box>
            {liabilityId ? (
              <TenantMoneySettingsDescriptionList
                tenantAiloRN={legalEntityAiloRN}
                liabilityId={liabilityId}
                edit={edit}
              />
            ) : (
              <Alert severity="error">
                {`Can't show Tenancy Money Settings because Tenancy ${tenancy.id} does not have a
                Liability ID`}
              </Alert>
            )}
            <Divider style={{ marginTop: 12 }} />
          </Box>
        );
      })}
    </DetailCard>
  );
}
