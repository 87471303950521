import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    borderRadius: 20,
  },
}));

/**
 * @deprecated Use `ConfirmMutationDialog`, `SimpleFormDialog` or `window.confirm()` instead.
 */
export const ConfirmCloseDialogActions = ({
  disabled,
  confirmText,
  onConfirmClick,
  cancelText,
  onCancelClick,
}: any) => {
  const classes = useStyles();
  return (
    <DialogActions
      style={{
        justifyContent: "space-between",
        padding: 24,
      }}
    >
      <Button
        className={classes.root}
        onClick={onCancelClick}
        color="secondary"
        variant="outlined"
      >
        {cancelText || "Cancel"}
      </Button>
      <Button
        disabled={disabled}
        className={classes.root}
        variant="contained"
        color="secondary"
        onClick={onConfirmClick}
      >
        {confirmText || "Confirm"}
      </Button>
    </DialogActions>
  );
};
