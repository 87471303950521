import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import {
  BooleanInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  required,
  minValue,
  SelectInput,
} from "react-admin";
import { LegalEntityInput } from "../../common";

export const CreateTenancyCard = ({ style }: any) => {
  return (
    <Card style={style}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Tenancy
        </Typography>
        <Box
          display={{
            xs: "block",
            sm: "flex",
          }}
        >
          <Box
            flex={10}
            mr={{
              xs: 0,
              sm: "0.5em",
            }}
          >
            <LegalEntityInput
              multiple
              label="Tenants"
              source="tenants"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display={{
            xs: "block",
            sm: "flex",
          }}
        >
          <Box
            flex={2}
            mr={{
              xs: 0,
              sm: "0.5em",
            }}
          >
            <DateInput
              label="Tenancy Start Date"
              source="tenancyStartDate"
              validate={[required()]}
            />
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Tenancy Agreement
        </Typography>
        <Box
          display={{
            xs: "block",
            sm: "flex",
          }}
        >
          <Box
            flex={2}
            mr={{
              xs: 0,
              sm: "0.5em",
            }}
          >
            <DateInput
              label="Agreement Start Date"
              source="agreementStartDate"
              validate={[required()]}
            />
          </Box>
          <Box
            flex={8}
            ml={{
              xs: 0,
              sm: "0.5em",
            }}
          >
            <DateInput
              label="Fixed Term End Date"
              source="agreementFixedTermEndDate"
            />
          </Box>
        </Box>

        <BooleanInput source="createRent" label="Setup Rent" defaultValue />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.createRent) {
              return (
                <div>
                  <Typography variant="h6" gutterBottom>
                    Rent
                  </Typography>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={2}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <SelectInput
                        label="Rent Frequency"
                        source="rentPeriod"
                        validate={[required()]}
                        choices={[
                          {
                            id: "monthly",
                            name: "monthly",
                          },
                          {
                            id: "weekly",
                            name: "weekly",
                          },
                          {
                            id: "fortnightly",
                            name: "fortnightly",
                          },
                        ]}
                      />
                    </Box>
                    <Box
                      flex={8}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <NumberInput
                        min="0"
                        label="Rent Amount ($)"
                        source="rentAmount"
                        validate={[required(), minValue(0)]}
                      />
                    </Box>
                    <Box
                      flex={8}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        label="Rent Liability Start Date"
                        source="rentEffectiveDate"
                        validate={[required()]}
                      />
                    </Box>
                  </Box>
                </div>
              );
            }

            return <div />;
          }}
        </FormDataConsumer>
      </CardContent>
    </Card>
  );
};
