import { SelectInput, SelectInputProps } from "react-admin";
import React from "react";

export function FeeStatusInput(
  props: SelectInputProps
): React.ReactElement | null {
  return (
    <SelectInput
      choices={[
        "Due",
        "PaymentPending",
        "Paid",
        "RefundPending",
        "Refunded",
        "Cancelled",
      ].map((id) => ({
        id,
        name: id,
      }))}
      {...props}
    />
  );
}
