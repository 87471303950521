import { REMOVE_LAST_PAGE } from "./types";

export type RemoveLastPageAction = {
  type: string;
  resource: string;
};
export function removeLastPage(resource: string): RemoveLastPageAction {
  return {
    type: REMOVE_LAST_PAGE,
    resource,
  };
}
