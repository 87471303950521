// eslint-disable-next-line no-restricted-imports
import { Create as ReactAdminCreate, useTranslate } from "react-admin";
// eslint-disable-next-line import/no-extraneous-dependencies
import inflection from "inflection";
import React from "react";

export function Create(props: any) {
  const translate = useTranslate();
  const resourceSingularName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  });
  return (
    <ReactAdminCreate
      successMessage={`${resourceSingularName} created`}
      {...props}
    />
  );
}
