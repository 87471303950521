import { LocalDate } from "@ailo/date";
import Box from "@material-ui/core/Box";
import React from "react";
import { Link } from "react-admin";
import { formatMoney } from "../../../../common/ui/deprecated";
import { RightChevronIcon } from "../../../../common/ui/icons/chevronIcon";
import { ListItemView } from "../../../../common/ui/lists";
import { BillAgencyStatusBadge } from "../../../../features/Bill/BillAgencyStatusBadge";
import { BillPayeeField } from "../../../../features/Bill/BillPayeeField";
import { TransactionDate } from "../../../../features/Ledger/components";

function BillListItem({ bill, type }: any) {
  const date =
    type === "Paid" ? bill.liabilityState.paymentDate : bill.issueDate;
  return (
    <Link
      to={`/Bills/${bill.id}/show`}
      style={{
        color: "initial",
      }}
    >
      <ListItemView
        header={
          <BillPayeeField
            record={bill}
            source="payee"
            linkable={false}
            copyable={false}
          />
        }
        leftComponent={<TransactionDate date={date} />}
        rightComponent={
          <>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box textAlign="right" marginBottom="2px">
                {formatMoney(bill.amount)}
              </Box>
              <BillAgencyStatusBadge
                agencyStatus={bill.agencyStatus}
                dueDateHint={LocalDate.from(bill.dueDate).format("D MMM")}
              />
            </Box>
            <RightChevronIcon />
          </>
        }
      />
    </Link>
  );
}

BillListItem.Loading = ListItemView.Loading;
export { BillListItem };
