import React, { ReactNode, useState } from "react";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { AiloRN } from "@ailo/ailorn";
import { Labeled, maxLength, required, TextInput } from "react-admin";
import { useMutation } from "../../../api";
import { TRANSFER_TO_TRUST } from "../../../../api/resources/TrustAccount/trustAccountClient";
import { TrustAccounts } from "../../../../api";
import { ErrorDialog } from "../../dialogs";
import { DialogControlButton } from "../../buttons";
import { SimpleFormDialog } from "../../../views";
import { MoneyField } from "../../../fields";
import { MoneyInput } from "../../../inputs";
import { maxMoney, minMoney } from "../../../validators";
import { Colors } from "../../colors";

export function TransferToTrustDialogControl({
  agency,
  escrowAccount,
  idempotencyKey,
  disabled,
  availableBalance,
  alertComponent,
}: {
  agency: AiloRN;
  escrowAccount: AiloRN;
  idempotencyKey: string;
  disabled: boolean;
  availableBalance: { cents: number };
  alertComponent?: ReactNode;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const icon = getIcon(disabled);

  const [transferFundsToTrust, { error }] = useMutation<{
    amount: { cents: number };
    agency: AiloRN;
    escrowAccount: AiloRN;
    idempotencyKey: string;
    description: string;
  }>(
    {
      type: TRANSFER_TO_TRUST,
      resource: TrustAccounts,
    },
    {
      successMessage: `Funds have been transferred to trust`,
    }
  );

  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;

  const submit = (formData: {
    description: string;
    amount: { cents: number };
  }): void => {
    void transferFundsToTrust({
      payload: {
        ...formData,
        escrowAccount,
        idempotencyKey,
        agency,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Transferring funds to trust failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <div>
      <DialogControlButton onClick={onClick} icon={icon} disabled={disabled}>
        Transfer Funds to Trust
      </DialogControlButton>
      <SimpleFormDialog
        onClose={onClose}
        open={open}
        title="Transfer Funds to Trust Account"
        save={submit}
        style={{ width: 500 }}
      >
        <MoneyField record={{ availableBalance }} source="availableBalance" />
        <Labeled label="How much to transfer?" fullWidth>
          <MoneyInput
            source="amount"
            label="Amount ($)"
            validate={[
              required(),
              minMoney({
                cents: 1,
              }),
              maxMoney(
                availableBalance,
                "The amount cannot exceed the available balance"
              ),
            ]}
          />
        </Labeled>
        <Labeled label="Description" fullWidth>
          <TextInput
            source="description"
            validate={[maxLength(280), required()]}
            multiline
          />
        </Labeled>
        {alertComponent}
      </SimpleFormDialog>
    </div>
  );
}

function getIcon(disabled?: boolean) {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <AccountBalanceWalletOutlinedIcon
      style={{
        color: disabled ? Colors.TEXT.DARK.SECONDARY : Colors.AILO_RED,
        marginRight: 5,
      }}
      variant="outlined"
    />
  );
}
