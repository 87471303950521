import React, { useEffect } from "react";
import { RadioButtonGroupInput } from "react-admin";
import { useFormState, useForm } from "react-final-form"; //eslint-disable-line
import { FeeBlueprintType } from "../../api/graphql/types.generated";
import { allHumanizedTypes } from "./feeBlueprintTypes";

export const FeeTypeInput = ({
  disabled,
}: {
  disabled: boolean;
}): React.ReactElement => {
  const { batch, change } = useForm();
  const { values } = useFormState();

  const { type } = values;

  useEffect(() => {
    if (type !== FeeBlueprintType.RecurringFee) {
      batch(() => {
        change("frequency", undefined);
        change("anniversaryDay", undefined);
        change("anniversaryMonth", undefined);
      });
      if (type !== FeeBlueprintType.OneOffFee) {
        change("oneWeekRentPercentage", undefined);
      }
    }
  }, [type, batch, change]);

  return (
    <RadioButtonGroupInput
      source="type"
      disabled={disabled}
      choices={allHumanizedTypes}
      initialValue={allHumanizedTypes[0].id}
    />
  );
};
