import { AiloRN, services } from "@ailo/ailorn";
import { DialogContentText } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import {
  FormDataConsumer,
  RadioButtonGroupInput,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import { PaymentMethodType } from "../../../api/graphql/types.generated";
import { ErrorDialog } from "../../../common/ui/dialogs";
import { SimpleFormDialog } from "../../../common/views";
import { PaymentMethod, Supplier } from "../../../resourceViews";
import { bankAccountFormFields } from "./bankAccountFormFields";
import { bPayFormFields } from "./bPayFormFields";
import { UpdateBillsCheckbox } from "./UpdateBillsCheckbox";

function useAddPaymentMethod(walletOwnerAiloRN: AiloRN) {
  const notify = useNotify();
  const refresh = useRefresh();
  const typeAndResource = walletOwnerAiloRN.isA(services.Bill.supplier)
    ? { type: "update_payment_method", resource: Supplier }
    : {
        type: "create_payment_method",
        resource: PaymentMethod,
      };
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    typeAndResource,
    {
      onSuccess: () => {
        refresh();
        notify(`Payment method has been added`);
      },
      onFailure: (error) => {
        notify(`Failed to add payment method. Error: ${error}`);
      },
    }
  );
}

export function AddPaymentMethodDialog(props: {
  open: boolean;
  walletOwnerAiloRN: AiloRN;
  managingOrganisationAiloRN?: AiloRN;
  onClose: () => void;
  showUpdateBillsOption?: boolean;
  allowedMethodTypes?: readonly Uncapitalize<PaymentMethodType>[];
}) {
  const {
    open,
    walletOwnerAiloRN,
    managingOrganisationAiloRN,
    onClose,
    showUpdateBillsOption = false,
    allowedMethodTypes = ["bankAccount", "bPay"],
  } = props;
  const [addPaymentMethod, { error }] = useAddPaymentMethod(walletOwnerAiloRN);
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;
  const methodTypeOptions = [
    ...(allowedMethodTypes.includes("bankAccount")
      ? [
          {
            id: "bankAccount",
            name: "Bank Account",
          },
        ]
      : []),
    ...(allowedMethodTypes.includes("bPay")
      ? [
          {
            id: "bPay",
            name: "BPay",
          },
        ]
      : []),
  ];

  const payload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formData: any) => {
      const {
        accountName,
        accountNumber,
        bsb,
        billerCode,
        updateBills,
      } = formData;
      const paymentMethod = billerCode
        ? { bpayInput: { billerCode } }
        : { bankAccountInput: { accountName, accountNumber, bsb } };

      return walletOwnerAiloRN.isA(services.Bill.supplier)
        ? {
            id: walletOwnerAiloRN.internalId,
            paymentMethod,
            replaceExistingDueBillsPaymentMethod: !!updateBills?.includes(
              "checked"
            ),
          }
        : {
            ...formData,
            owner: walletOwnerAiloRN,
            managingOrganisationAiloRN,
          };
    },
    [walletOwnerAiloRN, managingOrganisationAiloRN]
  );

  if (methodTypeOptions.length === 0) {
    return (
      <ErrorDialog
        open={open}
        title="Add Payment Method"
        error="No payment methods can be added"
        onClose={() => {
          onClose?.();
        }}
      />
    );
  }

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Adding payment method failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title="Add Payment Method"
      submitLabel="Add payment method"
      save={async (formData) => {
        await addPaymentMethod({ payload: payload(formData) });
      }}
      onClose={onClose}
    >
      <DialogContentText>Choose payment method</DialogContentText>

      <RadioButtonGroupInput
        source="methodType"
        choices={methodTypeOptions}
        initialValue={methodTypeOptions[0].id}
      />

      <DialogContentText>Enter payment details</DialogContentText>

      <FormDataConsumer>
        {({ formData }) => {
          switch (formData?.methodType) {
            case "bankAccount":
              return bankAccountFormFields;

            case "bPay":
              return bPayFormFields;

            default:
              return <></>;
          }
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => (
          <UpdateBillsCheckbox
            showUpdateBillsOption={showUpdateBillsOption}
            formData={formData}
          />
        )}
      </FormDataConsumer>
    </SimpleFormDialog>
  );
}
