import gql from "graphql-tag";

type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function liabilityCategoriesClient({
  raFetchType,
  params,
  resourceString,
}: Params) {
  return {
    query: gql`
      query ailoLiabilityReport($afterDate: Date, $beforeDate: Date) {
        ailoLiabilityReport(
          filter: { afterDate: $afterDate, beforeDate: $beforeDate }
        ) {
          items {
            id
            amountLiable {
              cents
            }
            category
          }
        }
      }
    `,
    variables: {
      afterDate: params.filter.afterDate,
      beforeDate: params.filter.beforeDate,
    },
    parseResponse: (response: { data: { ailoLiabilityReport: any } }) => {
      const { ailoLiabilityReport } = response.data;
      const { items } = ailoLiabilityReport;
      items.id = ailoLiabilityReport;
      return {
        data: items,
        total: null,
      };
    },
  };
}

export { liabilityCategoriesClient };
