import { AiloRN } from "@ailo/ailorn";
import { Moment } from "moment-timezone";
import { ManagementEndReason } from "./graphql/types.generated";

// TODO `raFetchType` and `params` should use generic type arguments
export type RaDataGraphqlClientArgs = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Bill = {
  id: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type User = {
  id: number;
  auth0Id: string;
  metadata: string;
  onboardedAt?: string;
  onboardingCompletedAt?: string;
  createdAt?: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Wallet = {
  id: string;
  name: string;
  availableBalance: Money;
  totalBalance: Money;
  createdAt: Date;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Person = {
  id: string;
  firstName: string;
  lastName?: string;
  phoneNo?: string;
  emailAddress?: string;
  taxFileNumber?: string;
  user?: User;
  organisation?: OrganisationRef;
  team?: Team;
  kycVerified: boolean;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type LegalEntityInfo = Person | Company;
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Organisation = {
  id: string;
  name: string;
  type: string;
  address: {
    address1: string;
    address2: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
  };
  legalEntities: LegalEntityInfo[];
  activeSubscriptions: Paginated<PlatformSubscription>;
  orgType: "Agency" | "Generic";
  members: Paginated<Person>;
  teams: Team[];
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type LegalEntityRef = {
  id: string;
  ailoRN: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Agency = {
  id: string;
  organisation: OrganisationRef;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Ownership = {
  ownerId: string;
  owner?: LegalEntityInfo;
  startDate: Date;
  sharesOwned: number;
};
type ManagementTerminationReason = "Lost" | "Off-boarded";
type VacatingReason =
  | "Within terms of agreement"
  | "Lease break"
  | "Termination"
  | "Off-boarded";
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Management = {
  id: string;
  createdAt: Date;
  isDraft: boolean;
  property: Property;
  managingEntity: Agency;
  endDate?: Date;
  endReason?: ManagementEndReason;
  terminationReason?: ManagementTerminationReason;
  terminationNotes?: string;
  owners?: Array<Ownership>;
  mostRecentTenancy?: Tenancy;
  allTenancies?: Tenancy[];
  allManagementAgreements?: ManagementAgreement[];
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type ManagementAgreement = {
  startDate?: Moment;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type MigratingManagement = {
  id: string;
  createdAt: Date;
  organisation: OrganisationRef;
  legalEntity: LegalEntityRef;
  trustManagementId: string;
  body: any;
  paidTo: Date;
  modifiedAt: Date;
  modifiedBy: string;
  portfolioName: string;
  ailoManagement?: Management;
  status: string;
  batchRef: string;
  errors: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Tenancy = {
  id: string;
  createdAt: Date;
  isDraft: boolean;
  startDate: Date;
  endDate?: Date | null;
  voidedAt?: Date | null;
  vacatingReason?: VacatingReason | null;
  vacatingNotes?: string | null;
  currentRentSchedule: RentSchedule;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Company = {
  id: string;
  registeredEntityId?: string;
  registeredEntityName?: string;
  address1?: string;
  address2?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  country?: string;
  organisation?: OrganisationRef;
  owner?: Person;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type CompanyWithBankAccountBlackLists = Company & {
  bankAccountBlackLists?: Array<BankAccountBlackListItem>;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type BankAccountBlackListItem = {
  id: string;
  ownerLegalEntity: string;
  bsb: string;
  accountNumber: string;
  description?: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type OrganisationRef = {
  id: string;
  name: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type UserRef = {
  id: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export const BankAccountTypeName = "BankAccount";
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export const CreditCardTypeName = "CreditCard";
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export const BPayTypeName = "BPay";
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export interface CreditCardMethod {
  __typename: typeof CreditCardTypeName;
  id: string;
  type?: string | null;
  expiry?: string | null;
  number?: string | null;
}
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export interface BankAccountMethod {
  __typename: typeof BankAccountTypeName;
  id: string;
  accountName?: string | null;
  accountNumber?: string | null;
  bsb?: string | null;
}
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export interface BPayMethod {
  __typename: typeof BPayTypeName;
  id: string;
  billerCode?: string | null;
}
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type PaymentMethod = CreditCardMethod | BankAccountMethod | BPayMethod;
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type PaymentMethodCompanion = {
  __typename: "PaymentMethodCompanion";
  id: string;
  paymentMethod: PaymentMethod;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type PaymentMethodAiloFees = {
  id: string;
  methodType: string;
  methodCategory?: string | null;
  feeName?: string | null;
  feeBasisPoints?: number | null;
  feeFlatCents?: number | null;
  appliesToId?: string | null;
  filterName?: string | null;
  filterType?: string | null;
  filterEntryEffectiveFromInclusive?: Date | null;
  filterEntryEffectiveToInclusive?: Date | null;
  filterNote?: string | null;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Supplier = {
  id: string;
  ailoRN: string;
  name: string;
  abn: string;
  organisation: OrganisationRef;
  paymentMethods?: PaymentMethodCompanion[];
  createdAt: Date;
  createdBy: UserRef;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type PlatformPlanRef = {
  id: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type PlatformSubscription = {
  id: string;
  plan: PlatformPlanRef;
  startsOn: Date;
  endsOn: Date;
  customer: OrganisationRef;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Address = {
  unitStreetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  country: string;
  postcode: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Property = {
  id: string;
  address: Address;
  managements?: Management[];
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Money = {
  cents: string;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Liability = {
  id: string;
  category: string;
  description: string;
  nextChargeAmount: Money;
  nextChargeDate: Date;
  nextDueAmount: Money;
  nextDueDate: Date;
  overdueAmount: Money;
  paidToDate?: Date | null | undefined;
  effectivePaidToDate?: Date | null | undefined;
  reference: string;
  status?: LiabilityStatus | null | undefined;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type LiabilityStatus = {
  id: string;
  liabilityId: string;
  archivedAt: Date;
  createdAt: Date;
  createdBy: AiloRN;
  payerKycCheckRequired: boolean;
  isArchived: boolean;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type LiabilityAdjustment = {
  amount: Money;
  createdAt: Date;
  description: string;
  effectiveAt: Date;
  liabilityId: string;
  sourceId: string;
  sourceType: string;
};
type RentCategory = "Legacy" | "Support" | "Inherited" | "RentReview";
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Rent = {
  id: string;
  amountInCents: string;
  period: string;
  effectiveDate: Date;
  category: RentCategory;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type RentSchedule = {
  id: string;
  amountInCents: string;
  period: string;
  startDate: Date;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type ManagementSetupResult = {
  management: Management;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type TenancySetupResult = {
  tenancy: Tenancy;
};
type PageInfo = {
  total: number;
  nextCursor: string;
  hasMore: boolean;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Paginated<T> = {
  pageInfo: PageInfo;
  items: T[];
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type Team = {
  id: string;
  name: string;
  organisation: Organisation;
  members: Person[];
  managements: Paginated<Management>;
};
/**
 * @deprecated Use a custom type defined by API client or UI layer.
 */
export type File = {
  id: string;
  url: string;
  thumbnailUrl: string;
  thumbnailStatus: string;
  contentType: string;
  fileName: string;
  fileSize: string;
  createdAt?: Date;
  createdBy?: {
    person: {
      firstName: string;
      lastName: string;
    };
  };
};
