/**
 * NOTE: When adding a new resource name:
 * - for a constant name, use PascalCase singular name (e.g. `User`)
 * - for a constant value, use url friendly plural name (e.g. `users`)
 */

export const AccountOwner = "AccountOwner";
export const Action = "Actions";
export const AutoPayLiability = "AutoPayLiabilities";
export const AutoPayment = "AutoPayments";
export const AutoWithdrawPlan = "AutoWithdrawPlans";
export const BankAccountBlackList = "BankAccountBlackLists";
export const Bill = "Bills";
export const BillTaxCategory = "BillTaxCategories";
export const BondAccount = "BondAccount";
export const CentrepayAccount = "CentrepayAccount";
export const CentrepayFee = "CentrepayFee";
export const CentrepayReport = "CentrepayReport";
export const CentrepayDeduction = "CentrepayDeduction";
export const CentrepayCrn = "CentrepayCrn";
export const CentrepayPaymentDirective = "CentrepayPaymentDirective";
export const TenancyBond = "TenancyBond";
export const Company = "Companies";
export const DeprecatedCompany = "Companies-Generic";
export const Person = "people";
export const Customer = "Customers";
export const Organisation = Customer;
export const Discrepancy = "Discrepancies";
export const ExternalTrustAccount = "ExternalTrustAccount";
export const Fee = "fees";
export const FeeBlueprint = "FeeBlueprint";
export const FeeTaxCategory = "FeeTaxCategories";
export const FeeEvent = "FeeEvent";
export const File = "Files";
export const LedgerEvent = "LedgerEvent";
export const LegalEntity = "LegalEntities";
export const Liability = "Liabilities";
export const LiabilityStatus = "LiabilityStatus";
export const LiabilityAdjustment = "LiabilityAdjustments";
export const LiabilityCategory = "LiabilityCategories";
export const LiabilityPaymentPlan = "LiabilityPaymentPlan";
export const LiabilityPlanChangePreview = "LiabilityPlanChangePreview";
export const Management = "Managements";
export const ManagementAgreement = "ManagementAgreement";
export const ManagementFee = "ManagementFee";
export const ManagementFeeBlueprint = "ManagementFeeBlueprint";
export const ManagementFolio = "Portfolios";
export const MigratingManagement = "migrating-managements";
export const MigrationAgency = "migration-agencies";
export const MigrationTask = "MigrationTask";
export const NegativeWalletBalance = "NegativeWalletBalances";
export const Notifications = "Notifications";
export const NotificationPreference = "NotificationPreferences";
export const OfflinePaymentAccount = "OfflinePaymentAccount";
export const OfflinePaymentProcessingEnabled =
  "OfflinePaymentProcessingEnabled";
export const PaymentMethodAiloFee = "PaymentMethodAiloFees";
export const PaymentMethod = "payment-methods";
export const DeprecatedPerson = "Consumers";
export const Property = "Properties";
export const Reconciliation = "Reconciliations";
export const ReconciliationDetail = "ReconciliationDetails";
export const RecurringFee = "RecurringFees";
export const RecurringOwing = "RecurringOwing";
export const Rent = "Rents";
export const Subscription = "Subscriptions";
export const Supplier = "Suppliers";
export const Team = "Team";
export const Tenancy = "Tenancy";
export const TenancyAgreement = "TenancyAgreement";
export const TenancyDeposits = "TenancyDeposits";
export const Tenantship = "Tenantship";
export const Transaction = "Transactions";
export const TranscoVirtualAccount = "Transco/VirtualAccounts";
export const TranscoVirtualAccountDebits = "Transco/VirtualAccountDebits";
export const TrustAccounts = "TrustAccounts";
export const User = "Users";
export const Wallet = "Wallet";
export const WalletOwnerBalance = "WalletOwnerBalances";
export const WalletEntry = "WalletEntry";
export const Withdrawal = "Withdrawals";
export const WalletTransfer = "WalletTransfer";
export const WalletOverdraftAllowance = "WalletOverdraftAllowance";
export const StatementProgress = "StatementProgress";
export const TrustTransaction = "TrustTransactions";

const resourceNameByApiTypeMap: { [key: string]: string } = {
  Bill,
  BusinessTransaction: Transaction,
  Company,
  Fee,
  LegalEntity,
  LegalEntityCompanion: LegalEntity,
  Liability,
  Management,
  ManagementFolio,
  Organisation: Customer,
  PaymentMethod,
  Person,
  Property,
  Supplier,
  User,
};

const noResourceForApiType = new Set([
  BondAccount,
  CentrepayAccount,
  OfflinePaymentAccount,
  OfflinePaymentProcessingEnabled,
  CentrepayReport,
  CentrepayDeduction,
  TenancyDeposits,
]);

export function getResourceNameForApiType(apiType: string): string | undefined {
  if (noResourceForApiType.has(apiType)) {
    return undefined;
  }

  return resourceNameByApiTypeMap[apiType] ?? apiType;
}

export function getApiTypeForResourceName(resourceName: string): string {
  return (
    Object.keys(resourceNameByApiTypeMap).find(
      (apiType) => resourceNameByApiTypeMap[apiType] === resourceName
    ) ?? resourceName
  );
}
