import { SimpleForm } from "react-admin";
import React from "react";
import { Create } from "../../common";
import { CreateTenancyCard } from "./CreateTenancyCard";

export const TenancyCreate = (props: Record<string, any>) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={() => props.history.goBack()}>
        <CreateTenancyCard />
      </SimpleForm>
    </Create>
  );
};
