import { useQuery } from "react-admin";
import { GetWalletByWalletOwnerAilornQuery } from "../../../../api/resources/Wallet/walletClient.generated";
import { Wallet } from "../../../../api";

export function useGetWalletByOwner(
  ownerAiloRN: string
): {
  data: GetWalletByWalletOwnerAilornQuery["wallet"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  loading: boolean;
} {
  const {
    data,
    loading,
    error,
  }: {
    data?: GetWalletByWalletOwnerAilornQuery["wallet"];
    loading: boolean;
    error?: string;
  } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: ownerAiloRN,
    },
  });

  return {
    data,
    loading,
    error,
  };
}
