import Avatar from "@material-ui/core/Avatar";
import React from "react";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Tooltip } from "@material-ui/core";
import { Colors } from "../colors/colors";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
    backgroundColor: Colors.SMOKE,
  },
}));

const NameAvatarGroup = ({ names, style }: any) => {
  const classes = useStyles();
  return names && names.length > 0 ? (
    <AvatarGroup
      classes={{
        avatar: classes.avatar,
      }}
      max={4}
    >
      {names.map((item: { firstName: any; lastName: any }, index: any) => {
        return (
          <NameAvatar
            {...{
              key: index,
              firstName: item.firstName,
              lastName: item.lastName,
              style,
            }}
          />
        );
      })}
    </AvatarGroup>
  ) : null;
};

const NameAvatar = ({ key, firstName, lastName, style }: any) => {
  const classes = useStyles();
  const fullName = getFullName(firstName, lastName);
  const initial = getFirstCharacter(firstName) + getFirstCharacter(lastName);
  return (
    <Tooltip key={key} title={fullName}>
      <Avatar alt={fullName} className={classes.avatar} style={style}>
        {initial}
      </Avatar>
    </Tooltip>
  );
};

function getFullName(firstName: string, lastName: any) {
  let fullName = firstName || "";
  fullName = fullName + (fullName.length > 0 ? " " : "") + (lastName || "");
  return fullName;
}

function getFirstCharacter(name: string[]) {
  return name ? name[0]?.toUpperCase() || "" : "";
}

export { NameAvatarGroup, NameAvatar };
