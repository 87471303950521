import React from "react";
import { startCase } from "lodash";
import { Badge } from "../../common/ui/Badge";

const feeBadgeType = {
  Cancelled: "default",
  Due: "warning",
  PaymentPending: "warning",
  Paid: "success",
  RefundPending: "critical",
  Refunded: "default",
} as const;

function FeeStatusBadge({
  status,
}: {
  status?: keyof typeof feeBadgeType | null;
}): React.ReactElement {
  return status ? (
    <Badge type={feeBadgeType[status]}>{startCase(status)}</Badge>
  ) : (
    <Badge type="default">Unknown</Badge>
  );
}

export { FeeStatusBadge };
