import { DateRange, LocalDate } from "@ailo/date";
import gql from "graphql-tag";
import { GET_LIST, GET_ONE, UPDATE } from "react-admin";
import { isPresent } from "ts-is-present";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  parseSortParam,
} from "../../common";
import { Property } from "../../graphql/types.generated";
import {
  PropertiesQuery,
  PropertiesQueryVariables,
  PropertyQuery,
  PropertyQueryVariables,
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables,
} from "./propertiesClient.generated";

const propertyIsValid = (property: Pick<Property, "id" | "address">): boolean =>
  [
    property.id,
    property.address,
    property.address.country,
    property.address.streetName,
    property.address.unitStreetNumber,
  ].every(isPresent);

export const PropertyClientDefinition = {
  [GET_LIST]: (params: {
    pagination: { page: string | number | undefined; perPage: any };
    sort:
      | {
          field?: string | null | undefined;
          order?: "ASC" | "DESC" | null | undefined;
        }
      | null
      | undefined;
    filter: { searchTerm: any };
  }) => ({
    query: gql`
      query properties(
        $cursor: String
        $pageSize: Int
        $sort: String
        $searchTerm: String
      ) {
        properties(
          pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
          search: $searchTerm
        ) {
          pageInfo {
            hasMore
            nextCursor
            total
          }
          items {
            id
            address {
              unitStreetNumber
              streetName
              suburb
              state
              country
              postcode
            }
          }
        }
      }
    `,
    variables: <PropertiesQueryVariables>{
      ...buildPaginationVariables(params.pagination),
      sort: parseSortParam(params.sort),
      searchTerm: params.filter.searchTerm,
    },
    parseResponse: (response: GraphQLResponse<PropertiesQuery>) => {
      const { properties } = response.data;
      const { pageInfo, items } = properties!;
      const { total, nextCursor, hasMore } = pageInfo;
      return {
        data: items.filter(propertyIsValid),
        total,
        nextCursor,
        hasMore,
      };
    },
  }),

  [GET_ONE]: (params: { id: any }) => ({
    query: gql`
      query property(
        $propertyId: ID!
        $managementFeeFilter: ManagementFeeFilter
        $managementFeeSort: [FeeSort!]
        $managementFeeCursor: PageCursorWithoutSort
      ) {
        property(propertyId: $propertyId) {
          id
          address {
            unitStreetNumber
            streetName
            suburb
            state
            country
            postcode
          }
          managements {
            id
            ailoRN
            managementFolio {
              id
              ailorn
              owners {
                isPrimary
                owner {
                  ...LegalEntityBasicDetails
                }
              }
            }
            isDraft
            firstPublishedAt
            endDate
            endReason {
              label
              causes {
                label
              }
            }
            endNote
            currentOrNextManagementAgreement {
              id
            }
            currentOrNextManagementFeeSchedule {
              id
              percent
              startDate
            }
            recurringFees {
              ailoRN: id
              startDate
              nextOccurrence {
                date
              }
            }
            fees(
              filter: $managementFeeFilter
              sort: $managementFeeSort
              cursor: $managementFeeCursor
            ) {
              pageInfo {
                hasMore
                nextCursor
                total
              }
              items {
                id
                ailoRN
              }
            }
            mostRecentTenancy {
              ...TenancyDetails
            }
            allTenancies {
              ...TenancyDetails
            }
            managingEntity {
              id
              ailoRN
              registeredEntityName
              organisationId
              organisation {
                id
                name
              }
              owner {
                ...LegalEntityBasicDetails
              }
            }
            allManagementAgreements {
              startDate
              fixedTermEndDate
            }
            team {
              id
              name
            }
            managementFolio {
              id
              ailorn
            }
          }
        }
      }

      fragment TenancyDetails on Tenancy {
        id
        ailoRN
        liability {
          id
          effectivePaidToDate
          paidToDate
          overdueAmount {
            cents
          }
        }
        startDate
        endDate
        voidedAt
        tenants {
          tenantId
          tenancyId
          tenant {
            ...LegalEntityBasicDetails
          }
        }
        deposit {
          id
          ailoRN
          amount {
            cents
          }
          status
          paidAt
          releasedAmount {
            cents
          }
          releasedBy
          releasedAt
          liability {
            id
          }
        }
        currentRent {
          id
          dailyRate
        }
      }

      fragment LegalEntityBasicDetails on LegalEntity {
        __typename
        id
        ailoRN
        ... on Person {
          firstName
          lastName
          emailAddress
          phoneNo
          photo {
            id
            url
            thumbnailUrl
          }
        }
        ... on Company {
          registeredEntityName
        }
      }
    `,
    variables: <PropertyQueryVariables>{
      propertyId: params.id,
      managementFeeSort: ["STATUS_PROGRESS_ASC", "CREATED_AT_ASC"],
      managementFeeCursor: { pageSize: 3 },
    },
    parseResponse: (response: GraphQLResponse<PropertyQuery>) => {
      const { property } = response.data;
      return {
        data: {
          ...property!,
          managements: (property!.managements ?? []).map((management) => ({
            ...management,
            dateRange: management.firstPublishedAt
              ? DateRange.from({
                  start: LocalDate.from(management.firstPublishedAt),
                  end: management.endDate
                    ? LocalDate.from(management.endDate)
                    : undefined,
                })
              : undefined,
          })),
        },
      };
    },
  }),

  [UPDATE]: (params: {
    updatePropertyInput: UpdatePropertyMutationVariables["updatePropertyInput"];
  }) => ({
    query: gql`
      mutation updateProperty($updatePropertyInput: UpdatePropertyInput!) {
        updateProperty(updatePropertyInput: $updatePropertyInput) {
          id
        }
      }
    `,
    variables: <UpdatePropertyMutationVariables>{
      updatePropertyInput: params.updatePropertyInput,
    },
    parseResponse: (response: GraphQLResponse<UpdatePropertyMutation>) => {
      return {
        data: response.data.updateProperty!.id,
      };
    },
  }),
};

export const propertiesClient = createClientFromDefinition(
  PropertyClientDefinition
);
