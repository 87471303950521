import { AiloRN, services } from "@ailo/ailorn";
import { DateRange, LocalDate, RecurringDate } from "@ailo/date";
import gql from "graphql-tag";
import { GET_LIST, GET_ONE } from "react-admin";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
  parseSortParam,
  specifyDefinition,
  specifyOperation,
} from "../../common";
import {
  GetTenantMoneySettingsWithLiabilityIdQuery,
  LegalEntitiesQuery,
  LegalEntityQuery,
} from "./legalEntitiesClient.generated";

const paymentMethodDetailsFragment = gql`
  fragment PaymentMethodDetails on PaymentMethod {
    __typename
    id
    ... on BankAccount {
      accountName
      accountNumber
      bsb
    }
    ... on BPay {
      billerCode
    }
    ... on CreditCard {
      type
      number
    }
  }
`;

const getOne = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      query legalEntity($id: AiloRN!) {
        legalEntity(id: $id) {
          __typename
          id
          ... on Company {
            registeredEntityId
            registeredEntityName
            organisation {
              id
              name
              memberships {
                member {
                  id
                }
                role {
                  id
                  name
                }
              }
            }
            owner {
              id
              firstName
              lastName
            }
          }
          ... on Person {
            firstName
            lastName
            phoneNo
            organisation {
              id
              name
              memberships {
                member {
                  id
                }
                role {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      id: AiloRN.of(services.AuthZ.legalEntity, String(params.id)),
    },
    parseResponse: (response: GraphQLResponse<LegalEntityQuery>) => {
      return {
        data: response.data.legalEntity,
      };
    },
  };
});

const getList = specifyOperation(
  (params: {
    filter: {
      searchTerm?: string;
    };
    sort: {
      field?: string | null | undefined;
      order?: "DESC" | "ASC" | null | undefined;
    };
    pagination: PaginationParams;
  }) => {
    return {
      query: gql`
        query legalEntities(
          $cursor: String
          $pageSize: Int
          $sort: String
          $searchQuery: String
        ) {
          legalEntities(
            pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
            searchQuery: $searchQuery
          ) {
            pageInfo {
              hasMore
              nextCursor
              total
            }
            items {
              id
              ... on Company {
                registeredEntityId
                registeredEntityName
                organisation {
                  id
                  name
                }
                owner {
                  id
                  firstName
                  lastName
                }
              }
              ... on Person {
                firstName
                lastName
                phoneNo
              }
            }
          }
        }
      `,
      variables: {
        ...buildPaginationVariables(params.pagination),
        sort: parseSortParam(params.sort),
        searchQuery: params.filter.searchTerm,
      },
      parseResponse: (response: GraphQLResponse<LegalEntitiesQuery>) => {
        const { total, nextCursor, hasMore } =
          response.data.legalEntities?.pageInfo ?? {};
        return {
          data: response.data.legalEntities?.items,
          total,
          nextCursor,
          hasMore,
        };
      },
    };
  }
);

const getTenantMoneySettings = specifyOperation(
  (params: { tenantAiloRN: string; liabilityId?: string }) => {
    return {
      query: params.liabilityId
        ? gql`
            query getTenantMoneySettingsWithLiabilityId(
              $tenantAiloRN: AiloRN!
              $liabilityId: ID!
            ) {
              liabilityPaymentPlans(
                enabled: true
                payerLegalEntityId: $tenantAiloRN
              ) {
                items {
                  id
                  enabled
                  liabilityId
                  owner
                  details {
                    frequency
                    anniversary
                    startDate
                    endDate
                    amount {
                      cents
                    }
                    paymentMethod {
                      ...PaymentMethodDetails
                    }
                  }
                }
              }

              liability: liabilityById(liabilityId: $liabilityId) {
                id
                autoPaymentDetails {
                  paymentMethod {
                    ...PaymentMethodDetails
                  }
                }
              }
            }
            ${paymentMethodDetailsFragment}
          `
        : gql`
            query getTenantMoneySettings($tenantAiloRN: AiloRN!) {
              liabilityPaymentPlans(
                enabled: true
                payerLegalEntityId: $tenantAiloRN
              ) {
                items {
                  id
                  liabilityId
                  details {
                    frequency
                    anniversary
                    startDate
                    endDate
                    amount {
                      cents
                    }
                    paymentMethod {
                      ...PaymentMethodDetails
                    }
                  }
                }
              }
            }

            ${paymentMethodDetailsFragment}
          `,
      variables: {
        tenantAiloRN: params.tenantAiloRN,
        liabilityId: params.liabilityId,
      },

      parseResponse({
        data,
      }: GraphQLResponse<GetTenantMoneySettingsWithLiabilityIdQuery>) {
        return {
          data: {
            ...data,
            liabilityPaymentPlans: data.liabilityPaymentPlans?.items
              ?.filter((plan) => plan.liabilityId === params.liabilityId)
              .map((plan) => ({
                ...plan,
                ...plan.details,
                dateRange: new DateRange({
                  start: LocalDate.from(plan.details.startDate),
                  end: plan.details.endDate
                    ? LocalDate.from(plan.details.endDate)
                    : undefined,
                }),
                recurringDate: new RecurringDate({
                  frequency: plan.details.frequency,
                  startDate: plan.details.startDate,
                }),
              })),
          },
        };
      },
    };
  }
);

export const LegalEntitiesClientDefinition = specifyDefinition({
  [GET_ONE]: getOne,
  [GET_LIST]: getList,
  get_tenant_money_settings: getTenantMoneySettings,
});

export const legalEntitiesClient = createClientFromDefinition(
  LegalEntitiesClientDefinition
);
