import React from "react";
import { createBrowserHistory } from "history";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { AuthService } from "../../auth";
import { LocalStorage, localStorageKeys } from "../../../common";

type Props = {};
type State = {
  authMessage: string;
};

class LoginCallbackPage extends React.Component<Props, State> {
  _auth: AuthService;

  constructor(props: Props) {
    super(props);
    this._auth = new AuthService();
    this.state = {
      authMessage: "Logging in, please wait...",
    };
  }

  componentDidMount() {
    const history = createBrowserHistory();
    const accessToken = LocalStorage.getItem(localStorageKeys.accessToken);

    if (accessToken && !this._auth.isTokenExpired()) {
      this.setState({
        authMessage: "Login Successful",
      });
    } else {
      this._auth.handleAuthentication().then((result) => {
        if (result.returnTo) {
          window.location.assign(result.returnTo);
        }
        this.setState({
          authMessage: result.isAuthenticated
            ? "Login Successful"
            : `Login Error${result.error ? result.error : "Unkown"}`,
        });
      });
    }

    history.replace("/callback", {});
  }

  render() {
    return (
      <Card
        style={{
          margin: "2em",
        }}
      >
        <CardHeader title="Welcome" />
        <CardContent>{this.state.authMessage}</CardContent>
      </Card>
    );
  }
}

export { LoginCallbackPage as CallbackPage };
