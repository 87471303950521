/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { MoneyInterface } from "@ailo/money";
import csv from "csvtojson";
import { TaxTreatment } from "../../../../api/graphql/types.generated";
import {
  ManagementCSVHeader,
  ManagementCSVRow,
  validateManagementCSVRow,
} from "../common";
import { buildClient } from "../common/buildClient";
import { cancel, create, find, update } from "./clientOperations";

type ManagementFeeBlueprintDetails = {
  fixedAmount?: MoneyInterface;
  oneWeekRentPercentage?: number;
  taxTreatment?: TaxTreatment;
  description?: string;
};

export const updateManagementFeeBlueprintsForManagements = async ({
  csvString,
  feeBlueprintId,
  remove,
  input,
}: {
  csvString: string;
  feeBlueprintId: string;
  remove?: boolean;
  input: ManagementFeeBlueprintDetails;
}): Promise<void> => {
  const client = buildClient();

  let isCSVValid = true;

  await csv()
    .fromString(csvString)
    .subscribe(
      (row: ManagementCSVRow) => {
        try {
          validateManagementCSVRow(row);
        } catch (error) {
          console.error(error);
          isCSVValid = false;
        }
      },
      (err) => console.error(err)
    );

  if (!isCSVValid) {
    throw new Error(
      `The CSV file has invalid data, please fix the errors above.`
    );
  }

  const csvRows: ManagementCSVRow[] = await csv().fromString(csvString);
  const failures: { managementId: string; error: unknown }[] = [];

  for (let index = 0; index < csvRows.length; index++) {
    const managementId = csvRows[index][ManagementCSVHeader.managementId];
    console.group(`Processing management ${managementId}`);

    try {
      console.log("Getting current management fee blueprint");
      // eslint-disable-next-line no-await-in-loop
      const managementFeeBlueprint = await find(
        managementId,
        feeBlueprintId,
        client
      );

      if (!managementFeeBlueprint) {
        if (!remove) {
          validateInput(input);

          console.log("Creating new management fee blueprint");
          await create({
            input: {
              managementId,
              feeBlueprintId,
              ...input,
            },
            client,
          });
        }
      } else if (remove) {
        console.log(
          `Cancelling management fee blueprint ${managementFeeBlueprint.id}`
        );
        await cancel(managementFeeBlueprint.id, client);
      } else {
        console.log("Updating management fee blueprint");
        await update({
          input: {
            id: managementFeeBlueprint.id,
            ...input,
          },
          client,
        });
      }
    } catch (error: unknown) {
      failures.push({
        managementId,
        error: error instanceof Error ? error.message : error,
      });
    }

    console.groupEnd();
  }

  if (failures.length > 0) {
    console.log("Rows that failed to be processed successfully:");
    console.log(failures);
    throw new Error(
      `Management fee blueprint failed to update for ${failures.length} management(s). Please check the console log for more information.`
    );
  }
};

function validateInput(
  input: ManagementFeeBlueprintDetails
): asserts input is {
  taxTreatment: TaxTreatment;
} & ManagementFeeBlueprintDetails {
  if (input.taxTreatment === undefined || input.taxTreatment === null) {
    throw new Error("invalid input");
  }
}
