import React from "react";
import {
  AutocompleteInput,
  GET_MANY,
  required,
  TextInputProps,
} from "react-admin";
import { BillTaxCategory } from "../../..";
import { useQueryWithStore } from "../../../../common";

type CategoryInputProps = Pick<TextInputProps, "style">;

export function CategoryInput(props: CategoryInputProps): React.ReactElement {
  const { data } = useQueryWithStore({
    type: GET_MANY,
    resource: BillTaxCategory,
    payload: {},
  });

  return (
    <AutocompleteInput
      label="Category"
      source="taxCategory"
      validate={[required()]}
      resettable
      choices={data}
      options={{ style: { width: "100%", marginTop: 16, ...props.style } }}
    />
  );
}
