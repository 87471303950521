import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import {
  AppBar as ReactAdminAppBar,
  MenuItemLink,
  UserMenu as ReactAdminUserMenu,
} from "react-admin";
import { User } from "../../api/ResourceName";
import { useCurrentUserAiloRN } from "../../common";
import { envVars } from "../../envVars";

const ConfigurationMenu = React.forwardRef<HTMLLIElement, any>(
  ({ onClick }, ref) => {
    const currentUserAiloRN = useCurrentUserAiloRN();

    if (!currentUserAiloRN) {
      return null;
    }

    return (
      <MenuItemLink
        ref={ref}
        sidebarIsOpen={undefined as any}
        to={`/${User}/${currentUserAiloRN.internalId}/show`}
        primaryText="Your profile"
        leftIcon={<SettingsIcon />}
        onClick={onClick}
      />
    );
  }
);

const UserMenu = (props: any) => (
  <ReactAdminUserMenu {...props}>
    <ConfigurationMenu />
  </ReactAdminUserMenu>
);

export const AppBar = (props: any) => (
  <ReactAdminAppBar
    {...props}
    userMenu={<UserMenu />}
    style={{
      backgroundColor: envVars.appColor,
    }}
  />
);
