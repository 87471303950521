import React from "react";
import { WalletEntry } from "../../api/ResourceName";
import { useInfinitelyPaginatedQuery, SectionListStyle } from "../../common";
import { TransactionsList } from "../Ledger/components/walletOwnerTransactions";
import { getTxDescription } from "../Ledger/utils";

const WalletOwnerTransactionList = ({
  ownerAiloRN,
  relatingToManagementAilorn,
  type = "managementfolio",
  style,
}: {
  ownerAiloRN: string;
  relatingToManagementAilorn?: string;
  type?: "managementfolio";
  style?: SectionListStyle;
}) => {
  const {
    loaded,
    error,
    data,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    type: "GET_LIST_BY_OWNER_ARN",
    resource: WalletEntry,
    payload: {
      filter: {
        ownerAiloRN,
        relatingToManagementAilorn,
      },
    },
  });

  if (!loaded) {
    return <TransactionsList.Loading />;
  }

  if (error) {
    return <TransactionsList.Error />;
  }

  if (!data?.length) {
    return <TransactionsList.Empty />;
  }

  return (
    <TransactionsList
      items={data.map((tx) => ({
        ...tx,
        description: getTxDescription(tx, type),
      }))}
      hasMore={hasMore}
      loadMore={loadMore}
      style={style}
    />
  );
};

export { WalletOwnerTransactionList };
