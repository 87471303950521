/**
 * @deprecated Use `formatAddress` from `@ailo/domain-helpers`
 */
export function formatAddress(data: {
  unitStreetNumber?: string | null | undefined;
  streetName?: string | null | undefined;
  suburb?: string | null | undefined;
  state?: string | null | undefined;
  postcode?: string | null | undefined;
}): string | null | undefined {
  return (
    [
      // @ts-expect-error ts-migrate(2749) FIXME: 'x' refers to a value, but is being used as a type... Remove this comment to see the full error message
      [data.unitStreetNumber, data.streetName].filter((x): x => !!x).join(" "),
      // @ts-expect-error ts-migrate(2749) FIXME: 'x' refers to a value, but is being used as a type... Remove this comment to see the full error message
      [data.suburb, data.state, data.postcode].filter((x): x => !!x).join(" "),
    ]
      .filter((s) => !!s)
      .join("\n") || undefined
  );
}
