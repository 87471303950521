import React, { ReactElement } from "react";
import { PlatformFeature } from "./PlatformFeature";

interface FeatureToggleItem {
  id: string;
  description: string;
  enabled: boolean;
}

const bondFeaturesAndDescriptions = [
  {
    id: "TenancyBondClaim",
    description: "Claim bond in PM app",
  },
  {
    id: "TenancyBonds",
    description: "Manage tenancy bonds in PM app",
  },
  {
    id: "EditTenancyBondClaim",
    description: "Allow tenancy bonds claims to be edited in PM app",
  },
];

function BondFeaturesList({
  featureToggles,
}: {
  featureToggles: PlatformFeature[];
}): ReactElement {
  const bondFeatureToggles: FeatureToggleItem[] = bondFeaturesAndDescriptions.map(
    (toggle) => ({
      ...toggle,
      enabled: getEnabled(featureToggles, toggle.id),
    })
  );

  return (
    <>
      {bondFeatureToggles.map((featureToggle, index) => (
        <FeatureToggle featureToggle={featureToggle} key={index} />
      ))}
    </>
  );
}

function FeatureToggle({
  featureToggle,
}: {
  featureToggle: FeatureToggleItem;
}): ReactElement {
  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>{featureToggle.id}</div>
        <div>{featureToggle.enabled ? "Enabled" : "Disabled"}</div>
      </div>
      <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        {featureToggle.description}
      </div>
    </div>
  );
}

function getEnabled(featureToggles: PlatformFeature[], id: string): boolean {
  return !!featureToggles.find((element) => element.id === id);
}

export { BondFeaturesList };
