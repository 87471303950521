import { FunctionField } from "react-admin";
import { get } from "lodash";
import React from "react";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import { AiloRN } from "@ailo/ailorn";
import { LinkField, MoneyField } from "../../../../common";

export function BondClaimsList({ source, ...props }: any): React.ReactElement {
  return (
    <FunctionField
      {...props}
      label="Claims"
      render={(bondData: any) => {
        const bondClaims = get(bondData, source);
        return bondClaims?.map((bondClaim: any, index: number) => {
          return (
            <BoxedShowLayout record={bondClaim} key={index}>
              <RaBox
                style={{
                  background: index % 2 ? "#f5f5f5" : "white",
                  padding: "10px",
                  borderWidth: 0.4,
                  border: "#c4c4c4",
                  borderBottomStyle: "solid",
                }}
              >
                {AiloRN.from(bondClaim.liabilityAiloRN)?.isA(
                  "propertymanagement:tenancy"
                ) ? (
                  <LinkField
                    label="Rent"
                    source="liabilityAiloRN"
                    sourceResource="Tenancy"
                  />
                ) : (
                  <LinkField
                    label="Bill"
                    source="liabilityAiloRN"
                    sourceResource="Bills"
                  />
                )}
                <MoneyField label="Amount" source="amount" />
              </RaBox>
            </BoxedShowLayout>
          );
        });
      }}
    />
  );
}

BondClaimsList.defaultProps = {
  addLabel: true,
};
