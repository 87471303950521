import React from "react";
import { startCase } from "lodash";
import { Badge } from "../../common/ui/Badge";

const billBadgeType = {
  AwaitingPayment: "default",
  Cancelled: "default",
  Due: "critical",
  PaymentSent: "warning",
  Paid: "success",
  Overdue: "critical",
  Refunded: "default",
  RefundFailed: "critical",
} as const;

function BillAgencyStatusBadge({
  agencyStatus,
  /**
   * If provided and agencyStatus is `AwaitingPayment`, `Due` or `Overdue`,
   * show the due date hint within the badge
   */
  dueDateHint: dueDateHintProp,
}: {
  agencyStatus: keyof typeof billBadgeType;
  dueDateHint?: string;
}) {
  const dueDateHint = dueDateHintProp ? ` · ${dueDateHintProp}` : "";
  const displayStatus = startCase(agencyStatus);
  const badgeText = ["AwaitingPayment", "Due", "Overdue"].includes(agencyStatus)
    ? `${displayStatus}${dueDateHint}`
    : displayStatus;

  return <Badge type={billBadgeType[agencyStatus]}>{badgeText}</Badge>;
}

export { BillAgencyStatusBadge };
