import gql from "graphql-tag";
import { GET_ONE } from "react-admin";
import type { RaDataGraphqlClientArgs, Person } from "../../deprecatedTypes";

export function DeprecatedPersonClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query deprecatedPerson($id: ID!) {
            person(id: $id) {
              id
              firstName
              lastName
              emailAddress
              taxFileNumber
              jobTitle
              phoneNo
              metadata
              user {
                id
                auth0Id
                onboardedAt
                onboardingCompletedAt
                createdAt
              }
              legalFirstName
              legalMiddleName
              preferredName
              phoneNoVerifiedAt
              photo {
                id
                url
                thumbnailUrl
              }
              birthDate
              unitStreetNumber
              streetName
              suburb
              state
              postcode
              wallets {
                id
                name
                totalBalance {
                  cents
                }
                availableBalance {
                  cents
                }
                createdAt
              }
              organisation {
                id
                name
                activeSubscriptions(cursor: {}) {
                  items {
                    id
                    plan {
                      id
                      price {
                        cents
                      }
                    }
                    startsOn
                    endsOn
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: String(params.id),
        },
        parseResponse: (response: {
          data: {
            person: Person;
          };
        }) => {
          return {
            data: response.data.person,
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
