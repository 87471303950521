import { Money } from "@ailo/money";
import React, { useEffect } from "react";
import {
  FormDataConsumer,
  minValue,
  number,
  required,
  TextInput,
  useInput,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { OperationData } from "../../api";
import { ManagementFeeBlueprint } from "../../api/ResourceName";
import {
  maxMoney,
  minMoney,
  MoneyInput,
  PercentInput,
  SimpleForm,
  SimpleFormProps,
} from "../../common";
import { CheckboxWithText } from "../Transaction/components";
import { FeeBlueprintInput } from "./FeeBlueprintInput";

type ManagementFeeBlueprintRecord = OperationData<
  typeof ManagementFeeBlueprint,
  "GET_ONE"
>;

export function ManagementFeeBlueprintForm({
  managementId,
  organisationId,
  name,
  ...props
}: SimpleFormProps<NonNullable<ManagementFeeBlueprintRecord>> & {
  managementId?: string;
  organisationId?: string;
}): React.ReactElement {
  const create = managementId && organisationId;

  return (
    <SimpleForm
      {...props}
      deleteButtonProps={{
        confirmTitle: `Remove ${name} template`,
        confirmContent:
          "Any fees that have already been charged will not be affected. To cancel an existing fee, navigate to Property Management > One Off Fees.",
        label: "Remove",
      }}
    >
      <InnerForm
        {...{
          create,
          managementId: managementId!,
          organisationId: organisationId!,
        }}
      />
    </SimpleForm>
  );
}

function InnerForm({
  create,
  managementId,
  organisationId,
}: {
  create: string | undefined;
  managementId: string;
  organisationId: string;
}) {
  const {
    input: { onChange },
  } = useInput({ source: "managementId" });
  useEffect(() => {
    if (managementId) onChange(managementId);
  }, [managementId, onChange]);
  const {
    input: { value: taxTreatment, onChange: onChangeTaxTreatment },
  } = useInput({
    defaultValue: "inclusive",
    source: "taxTreatment",
  });

  return (
    <>
      {create ? (
        <FeeBlueprintInput
          managementId={managementId}
          organisationId={organisationId}
        />
      ) : null}
      <FormDataConsumer>
        {({ formData }: { formData: ManagementFeeBlueprintRecord }) => {
          const feeBlueprint = formData?.feeBlueprint;
          const fixedAmount = formData?.fixedAmount;

          const amount = fixedAmount
            ? Money.fromCents(fixedAmount.cents || 0)
            : undefined;
          const tax = amount?.divide(taxTreatment === "inclusive" ? 11 : 10);

          const text =
            amount &&
            tax &&
            `GST ${tax.format({
              symbol: true,
              withTrailingZeros: true,
            })}, total ${amount.add(
              taxTreatment === "inclusive" ? Money.fromCents(0) : tax
            )}`;

          return feeBlueprint ? (
            <>
              {feeBlueprint?.fixedAmount ? (
                <MoneyInput
                  source="fixedAmount"
                  label="Price ($)"
                  fullWidth
                  defaultValue={feeBlueprint.fixedAmount}
                  validate={[
                    required(),
                    minMoney({ cents: 0 }),
                    maxMoney({ cents: 1300000 }),
                  ]}
                />
              ) : (
                <PercentInput
                  source="oneWeekRentPercentage"
                  label="Weeks of rent"
                  format="decimal"
                  dp={4}
                  defaultValue={feeBlueprint.oneWeekRentPercentage}
                  validate={[required(), number(), minValue(0)]}
                  fullWidth
                />
              )}
              <CheckboxWithText
                {...{
                  checked: taxTreatment === "inclusive",
                  onChange: (event?: {
                    target?: { checked?: boolean };
                  }): void =>
                    onChangeTaxTreatment(
                      event?.target?.checked ? "inclusive" : "exclusive"
                    ),
                  label: "Includes GST",
                  text,
                }}
              />
              {feeBlueprint?.event && (
                <>
                  <TextInput source="description" style={{ width: "100%" }} />
                  <Alert
                    severity="info"
                    {...{ fullWidth: true }}
                    style={{ marginBottom: 12 }}
                  >
                    {`This fee will be charged ${feeBlueprint.event.longDescription}`}
                  </Alert>
                </>
              )}
            </>
          ) : null;
        }}
      </FormDataConsumer>
    </>
  );
}
