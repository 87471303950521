import React, { ReactElement } from "react";
import { isEmpty } from "lodash";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { OperationData, Person } from "../../../../../api";
import { PersonCompaniesOwnedListItem } from "./personCompaniesOwnedListItem";

interface Props {
  person: OperationData<typeof Person, "GET_ONE">;
}

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    maxHeight: 380,
  },
}));
export function PersonCompaniesOwnedList({ person }: Props): ReactElement {
  const classes = useStyles();

  if (!person.companiesOwned?.items || isEmpty(person.companiesOwned.items)) {
    return <>There are no companies</>;
  }

  return (
    <List className={classes.root}>
      {person.companiesOwned.items.map((company) => (
        <PersonCompaniesOwnedListItem key={company.id} company={company} />
      ))}
    </List>
  );
}
