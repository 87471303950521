import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  FormDataConsumer,
  AutocompleteInput,
  BooleanInput,
} from "react-admin";
import { Divider } from "@material-ui/core";
import { formatPersonName } from "../../common/utils";
import { Create } from "../../common";
import { ReferenceInput } from "../../common";
import { Person } from "../../api/ResourceName";

const requiredValidate = [required()];
type Owner = {
  firstName: string;
  lastName: string;
  id: string;
};
type Organisation = {
  id: string;
  name: string;
};

const ownerRenderer = (owner: Owner) => {
  return `${formatPersonName(owner)} [${owner.id}]`;
};

const organisationRenderer = (org: Organisation) => {
  return `${org.name} [${org.id}]`;
};

const splitByComma = (emailsString?: string): string[] | undefined => {
  return emailsString?.split(",").map((email) => email.trim());
};

const CreateCompany = (props: Record<string, any>) => {
  return (
    <Create
      redirect="list"
      successMessage="Company has been created"
      {...props}
    >
      <SimpleForm>
        <ReferenceInput
          alwaysOn
          label="Owner"
          source="ownerId"
          reference={Person}
          filterToQuery={(searchTerm: string) => ({
            searchTerm,
          })}
        >
          <AutocompleteInput optionText={ownerRenderer} />
        </ReferenceInput>
        <TextInput
          validate={requiredValidate}
          label="Registered Entity Id (ABN)"
          source="registeredEntityId"
        />
        <TextInput
          label="Registered Entity Name"
          source="registeredEntityName"
          validate={requiredValidate}
        />
        <Divider />

        <BooleanInput
          source="createOrganisation"
          label="Create Organisation"
          defaultValue
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.createOrganisation) {
              return (
                <div>
                  <TextInput
                    validate={requiredValidate}
                    label="Trading Name"
                    source="organisation.name"
                  />
                  <SelectInput
                    label="Type"
                    source="organisation.orgType"
                    choices={[
                      {
                        id: "Agency",
                        name: "Agency",
                      },
                      {
                        id: "Generic",
                        name: "Consumer",
                      },
                    ]}
                  />
                </div>
              );
            }

            return (
              <ReferenceInput
                alwaysOn
                label="Existing organisation"
                source="organisationId"
                reference="Customers"
                filterToQuery={(searchTerm: string) => ({
                  searchTerm,
                })}
              >
                <AutocompleteInput
                  optionText={organisationRenderer}
                  options={{
                    InputProps: {
                      // Disable Chrome address autocomplete dropdown
                      // ( https://github.com/marmelab/react-admin/issues/5620 )
                      autoComplete: "nope",
                    },
                  }}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>

        <TextInput label="Primary Contact Email" source="emailAddress" />
        <TextInput
          label="Accounts Email"
          source="accountsEmail"
          helperText="Enter multiple email addresses by inserting a comma in-between"
          parse={splitByComma}
        />
        <TextInput label="Address 1" source="address1" />
        <TextInput label="Address 2" source="address2" />
        <TextInput label="Suburb" source="suburb" />
        <TextInput label="State" source="state" />
        <TextInput label="Postcode" source="postcode" />
        <SelectInput
          validate={requiredValidate}
          defaultValue="Australia"
          label="Country"
          source="country"
          choices={[
            {
              id: "Australia",
              name: "Australia",
            },
            {
              id: "New Zealand",
              name: "New Zealand",
            },
            {
              id: "United States",
              name: "United States",
            },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export { CreateCompany, splitByComma };
