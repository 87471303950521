import React, { useCallback, useState } from "react";
import { ListSubheader, Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FileField, FileInput } from "react-admin";
import { uniq } from "lodash";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useFileUpload } from "./useFileUpload";
import { Colors, RemoveButton } from "../ui";

const useStyles = makeStyles({
  preview: {
    display: "none",
  },
});

const defaultPlaceholder = (
  <Container
    style={{
      height: 60,
      padding: 20,
    }}
  >
    <div
      style={{
        color: Colors.AILO_RED,
        fontWeight: "bolder",
      }}
    >
      Click to upload a file or drag and drop
    </div>
  </Container>
);

export function FileUpload({
  onChange,
  initialFiles,
  placeholder,
}: {
  onChange?: (e: any) => void;
  initialFiles?: any;
  placeholder?: string | React.ReactElement;
}) {
  const classes = useStyles();
  const onSuccess = useCallback(
    (generatedFiles) => {
      onChange?.({
        target: {
          name: "files",
          value: uniq<string>(
            generatedFiles.map((file: { id: string }) => file.id)
          ).map((id: string) => ({
            id,
          })),
        },
      });
    },
    [onChange]
  );
  const [error, setError] = useState();
  const onError = useCallback(
    (errorMessage) => {
      setError(errorMessage);
    },
    [setError]
  );
  const {
    status,
    isIdle,
    generatedFiles,
    startFileUpload,
    deleteFile,
  } = useFileUpload({
    onSuccess,
    onError,
    initialFiles,
  });
  return (
    <>
      {error ? (
        <Grid item xs={12}>
          <Alert severity="error" {...({ fullWidth: true } as any)}>
            <AlertTitle>File Error</AlertTitle>
            {error}
          </Alert>
        </Grid>
      ) : null}
      {generatedFiles.map((file) => (
        <ListSubheader key={file.id}>
          <RemoveButton onClick={() => deleteFile(file)}> </RemoveButton>
          {file.title}
        </ListSubheader>
      ))}
      {!isIdle && <ListSubheader>{status}</ListSubheader>}
      <FileInput
        source="data"
        label=""
        placeholder={placeholder || defaultPlaceholder}
        validate={(file) => {
          startFileUpload(file);
          return undefined;
        }}
      >
        <FileField source="src" title="title" className={classes.preview} />
      </FileInput>
    </>
  );
}
