import { Grid } from "@material-ui/core";
import React from "react";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";
import { StatementsCard } from "../../Property/show/StatementsCard";
import { getPropertyAddresses } from "./getPropertyAddresses";

export function StatementsTab({
  managementFolio,
}: {
  managementFolio: ManagementFolioShowRecord;
}): React.ReactElement {
  const managements = managementFolio.managements.items;
  const propertyAddresses = getPropertyAddresses(managementFolio);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {managements.map((management) => (
          <StatementsCard
            key={management.id}
            managementAiloRN={management.ailorn}
            subheader={propertyAddresses}
          />
        ))}
      </Grid>
    </Grid>
  );
}
