const transactionListRowStyle = (record: any) => ({
  backgroundColor: getBackgroundColour(record),
});

function getBackgroundColour(record: any) {
  if (isReversal(record)) {
    return "rgb(242, 195, 191)";
  }

  if (isCredit(record)) {
    return "rgb(191,202,242)";
  }

  return "rgb(193,242,191)";
}

function isReversal(record: any) {
  return (
    record.transactionType === "late reversal" ||
    record.transactionType === "refund"
  );
}

function isCredit(record: any) {
  return (
    record.paymentType === "direct credit" ||
    record.paymentType === "bpay payable"
  );
}

export { transactionListRowStyle };
