import { formatAddress, formatNames } from "@ailo/domain-helpers";
import { isPresent } from "ts-is-present";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";

export function getPropertyAddresses(
  managementFolio: ManagementFolioShowRecord
): string {
  const managements = managementFolio.managements.items;
  const propertyAddresses = managements
    .map((management) =>
      formatAddress(management.property.address, {
        format: "street, suburb, state, postcode",
      })
    )
    .filter(isPresent);
  return formatNames(propertyAddresses);
}
