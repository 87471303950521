import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import {
  DateField,
  FunctionField,
  Labeled,
  TextField,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import type { Management } from "../../api/deprecatedTypes";
import { ManagementFragment } from "../../api/resources/Management/managementsClient.generated";
import { ClearManagementEndButton } from "./ClearManagementEndButton";
import { ManagementTerminationForm } from "./ManagementTerminationForm";
import { useGetManagementEndReasons } from "./useGetManagementEndReasons";

export const ManagementTerminationDetailsField = ({
  record: management,
}: {
  record?: Management;
}) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const closeDialog = useCallback(() => {
    setDialogOpened(false);
  }, []);
  const openDialog = useCallback(() => {
    setDialogOpened(true);
  }, []);
  if (!management) return null;
  const managementEndDateSet = !!management.endDate;
  const managementEndDateCanBeSet = !management.isDraft && !management.endDate;
  return (
    <Grid container spacing={3} direction="row" alignItems="flex-start">
      <Grid item direction="column">
        <Grid item>
          <Labeled Labeled label="End Date">
            <DateField record={management} source="endDate" emptyText="—" />
          </Labeled>
        </Grid>
        {managementEndDateSet ? (
          <>
            <Grid item>
              <Labeled Labeled label="Management End Reason">
                <TextField record={management} source="endReason.label" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled Labeled label="Management Lost Reason">
                <FunctionField
                  label="Management Lost Reason"
                  record={management}
                  render={(record: any) => {
                    const { endReason } = record as ManagementFragment;

                    if (!endReason?.causes?.length) return "—";

                    return endReason.causes
                      .map(({ label }) => label)
                      .join(", ");
                  }}
                />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled Labeled label="Management End Note">
                <TextField record={management} source="endNote" />
              </Labeled>
            </Grid>
            {management.terminationNotes && (
              <Grid item>
                <Labeled Labeled label="Termination Notes">
                  <TextField
                    record={management}
                    source="terminationNotes"
                    emptyText="—"
                  />
                </Labeled>
              </Grid>
            )}
          </>
        ) : null}
      </Grid>

      <Grid item>
        {managementEndDateCanBeSet ? <EndButton onClick={openDialog} /> : null}
        <EndManagementEndDateFormDialog
          open={dialogOpened}
          onClose={closeDialog}
          management={management}
        />
      </Grid>

      {management.endDate ? (
        <Grid item>
          <ClearManagementEndButton
            managementId={management.id}
            endDate={management.endDate}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

const EndButton = ({ onClick }: { onClick?(): void }) => (
  <Button
    style={{
      marginTop: 8,
    }}
    variant="outlined"
    color="secondary"
    onClick={onClick}
    size="small"
  >
    End Management
  </Button>
);

const EndManagementEndDateFormDialog = ({ open, onClose, management }: any) => {
  const [error, setError] = useState("");
  const clearError = useCallback(() => {
    setError("");
  }, []);
  const notify = useNotify();
  const refresh = useRefresh();
  const [endManagement] = useMutation(
    {
      type: "end_management",
      resource: "Managements",
      payload: {},
    },
    {
      onSuccess: () => {
        onClose();
        notify(`Management ${management.id} ended`);
        refresh();
      },
      onFailure: (error) => {
        const errorMessage = `Fail to end management ${management.id}: ${error}`;
        setError(errorMessage);
      },
    }
  );
  const { data } = useGetManagementEndReasons();

  const save = React.useCallback(
    ({ id, endDate, endReason, endCauses, endNote }) => {
      endManagement({
        payload: {
          managementId: id,
          endDate,
          endReasonCode: endReason,
          endCauses,
          endNote,
        },
      });
    },
    [endManagement]
  );

  if (!data?.managementEndReasons) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>End management</DialogTitle>
      <DialogContent>
        <div
          style={{
            width: 400,
          }}
        >
          <ManagementTerminationForm
            entity={management}
            entityName="Management"
            save={save}
            endReasons={data.managementEndReasons}
            error={error}
            onFormChange={clearError}
            reasonSource="endReason"
            causeSource="endCauses"
            notesSource="endNote"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
