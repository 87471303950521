import Grid from "@material-ui/core/Grid";
import React from "react";
import { AiloRN } from "@ailo/ailorn";
import { ManagementFolioCard } from "../../../../features/ManagementFolio";
import { ManagementOwnersCard } from "../../../../features/Property/ManagementOwnersCard";
import { PropertyAgencyCard } from "../../../../features/Property/PropertyAgencyCard";
import { FeesChargedCard } from "../FeesChargedCard/FeesChargedCard";
import { BillsCard } from "../PropertyBillsCard";
import { PropertyFeesCard } from "../PropertyFeesCard";
import {
  PropertyShowManagement,
  PropertyShowRecord,
  PropertyShowTenancy,
} from "../types";

export function ManagementTab({
  property,
  management,
  tenancy,
}: {
  property: PropertyShowRecord;
  management: PropertyShowManagement;
  tenancy?: PropertyShowTenancy;
}): React.ReactElement {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PropertyAgencyCard
          agency={management.managingEntity}
          teams={management.team ? [management.team] : undefined}
          title="Agency"
          managementReferenceList={[AiloRN.from(management.ailoRN)]}
        />
        <PropertyFeesCard propertyId={property.id} management={management} />
        <FeesChargedCard
          propertyId={property.id}
          management={management}
          tenancy={tenancy}
        />
        {!management.isDraft && (
          <BillsCard
            managementId={management.id}
            payerAilorn={management.ailoRN}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <ManagementOwnersCard ownerships={management.managementFolio.owners} />
        <ManagementFolioCard
          managementFolioId={management.managementFolio.id}
          managementId={management.id}
        />
      </Grid>
    </Grid>
  );
}
