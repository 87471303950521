import gql from "graphql-tag";
import { GET_ONE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { GetCentrepayWalletQuery } from "./centrepayClient.generated";
import { VirtualAccountData } from "../../../common";

export const CentrepayAccountDefinition = {
  [GET_ONE]: (params: { entity: string }) => {
    return {
      query: gql`
        query getCentrepayWallet($entity: AiloRN!) {
          getCentrepayAccountByManagingEntity(managingEntity: $entity) {
            id
            paymentMethodCompanion {
              id
              paymentMethod {
                __typename
                wallet {
                  id
                  totalBalance {
                    cents
                  }
                  availableBalance {
                    cents
                  }
                  owner {
                    reference
                  }
                }
                id
                externalId
                ... on VirtualAccount {
                  accountNumber
                  bsb
                }
              }
            }
          }
        }
      `,
      variables: {
        entity: params.entity,
      },
      parseResponse: (
        response: GraphQLResponse<GetCentrepayWalletQuery>
      ): { data: VirtualAccountData } => {
        return {
          data: {
            id: response.data.getCentrepayAccountByManagingEntity?.id,
            paymentMethodCompanion:
              response.data.getCentrepayAccountByManagingEntity
                ?.paymentMethodCompanion,
          },
        };
      },
    };
  },
};

export const centrepayAccountClient = createClientFromDefinition(
  CentrepayAccountDefinition
);
