import ListSubheader from "@material-ui/core/ListSubheader";
import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box } from "@material-ui/core";
import { Colors } from "../../../../common";
import { ViewAllButton } from "../../../../common";

const useStyles = makeStyles(() => ({
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  subHeader: {
    backgroundColor: Colors.CLOUD,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 12,
  },
}));
export const PaymentMethodList = ({ title, data, listItemComponent }: any) => {
  const classes = useStyles();
  const [viewAll, setViewAll] = useState(false);
  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [itemsToShow, setItemsToShow] = useState([]);
  const MAX_ROWS = 3;
  useEffect((): void => {
    const items = viewAll ? data : data.slice(0, MAX_ROWS);
    setItemsToShow(items);
    setHasMoreRows(data.length > MAX_ROWS);
  }, [data, viewAll]);

  return (
    <>
      <li key={title} className={classes.listSection}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.subHeader}>{title}</ListSubheader>
          {itemsToShow.map((item) => listItemComponent(item))}
        </ul>
      </li>
      {hasMoreRows && (
        <Box display="flex" justifyContent="center" pb={2}>
          <ViewAllButton
            onClick={() => setViewAll(!viewAll)}
            viewAll={viewAll}
          />
        </Box>
      )}
    </>
  );
};
