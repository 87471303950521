interface Address {
  unitStreetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  country: string;
  postcode: string;
}
interface Property {
  id: string;
  address?: Address | null | undefined;
}
/**
 * @deprecated Use helpers from `@ailo/domain-helpers` or create `PropertyInput`
 */
export function formatPropertyOptionText(
  property: Property
): string | null | undefined {
  if (!property.address) {
    return `ID: ${property.id}`;
  }

  return [
    property.address.unitStreetNumber,
    property.address.streetName,
    property.address.suburb,
    property.address.postcode,
    property.address.country,
  ].join(" ");
}
