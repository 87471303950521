import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { DateInput, required, TextInput, UPDATE } from "react-admin";
import { OperationParams } from "../../api";
import { TenancyAgreement } from "../../api/ResourceName";
import { SimpleFormDialog, useMutation } from "../../common";

type UpdateTenancyAgreementInput = OperationParams<
  typeof TenancyAgreement,
  typeof UPDATE
>["data"];

export function EditTenancyAgreementButton({
  tenancyAgreement,
}: {
  tenancyAgreement: {
    id: string;
    startDate?: string | null;
    fixedTermEndDate?: string | null;
  };
}): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: TenancyAgreement,
    type: UPDATE,
  });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Edit
      </Button>
      {dialogOpen && (
        <SimpleFormDialog<UpdateTenancyAgreementInput>
          title="Update Tenancy Agreement"
          submitLabel="Update"
          cancelLabel="Back"
          initialValues={tenancyAgreement}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          save={(data) => mutate({ payload: { data } })}
        >
          <TextInput source="id" disabled fullWidth validate={[required()]} />
          <DateInput source="startDate" fullWidth validate={[required()]} />
          <DateInput source="fixedTermEndDate" fullWidth />
        </SimpleFormDialog>
      )}
    </>
  );
}
