import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { Money } from "@ailo/money";
import { DateTimeWithTimeZone } from "@ailo/date";
import { isPresent } from "ts-is-present";
import {
  CloseButton,
  DATE_TIME_FORMAT,
  DescriptionList,
  DetailCard,
  EditButton,
  LinkField,
} from "../../../../common";
import { DepositStatusBadge } from "./DepositStatusBadge";
import { CancelDepositButton } from "./CancelDeposit";
import { TenancyDepositStatus } from "../../../../api/graphql/types.generated";
import { TransferDepositButton } from "./TransferDeposit";
import { TenancyDepositRecord } from "./TenancyDepositRecord";
import { Liability } from "../../../../api";
import { UpdateDepositButton } from "./UpdateStatus/UpdateDepositButton";
import { useGetWalletByOwner } from "./UseGetWalletByOwner";
import { GetWalletByWalletOwnerAilornQuery } from "../../../../api/resources/Wallet/walletClient.generated";

export const DepositCard = ({
  deposit,
  tenancyAiloRN,
}: {
  deposit: TenancyDepositRecord;
  tenancyAiloRN: string;
}): React.ReactElement => {
  const [edit, setEdit] = useState(false);

  const { data } = useGetWalletByOwner(tenancyAiloRN);

  const {
    ailoRN,
    status,
    amount,
    paidAt,
    releasedAmount,
    releasedAt,
  } = deposit;

  const onEdit = (doEdit: boolean): void => {
    setEdit(doEdit);
  };

  const canCancel = isTenancyDepositCancellable(deposit);
  const canTransfer = isTenancyDepositTransferrable(deposit);
  const canMarkAsReleased = canMarkTenancyDepositAsReleased(deposit, data);

  const canEdit = canCancel || canTransfer || canMarkAsReleased;

  return (
    <DetailCard
      title="Initial Payment"
      action={
        canEdit && (
          <Box display="flex">
            {edit && (
              <>
                {canMarkAsReleased && <UpdateDepositButton deposit={deposit} />}
                {canTransfer && (
                  <TransferDepositButton
                    tenancyAiloRN={tenancyAiloRN}
                    deposit={deposit}
                  />
                )}
                {canCancel && <CancelDepositButton tenancyDepositId={ailoRN} />}
                <CloseButton onClick={() => onEdit(false)} />
              </>
            )}
            {!edit && <EditButton onClick={() => onEdit(true)} />}
          </Box>
        )
      }
    >
      <Box marginBottom={2}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Box>{Money.from(amount).format()}</Box>
          <DepositStatusBadge status={status} />
        </Box>
        <DescriptionList
          descriptionStyle={{
            textAlign: "right",
          }}
          items={[
            {
              name: "ID",
              description: (
                <LinkField
                  record={deposit}
                  linkable={false}
                  renderText={(item) => item.id}
                />
              ),
            },
            {
              name: "Liability",
              description: (
                <LinkField
                  record={deposit.liability}
                  renderText={(item) => item.id}
                  sourceResource={Liability}
                />
              ),
            },
          ]}
        />
      </Box>

      <DescriptionList
        descriptionStyle={{
          textAlign: "right",
        }}
        items={[
          paidAt
            ? {
                name: "Paid by renter",
                description: DateTimeWithTimeZone.from(paidAt).format(
                  DATE_TIME_FORMAT
                ),
              }
            : undefined,
          releasedAt
            ? {
                name: "Transferred to property wallet",
                description: `${Money.from(
                  releasedAmount
                ).format()}, ${DateTimeWithTimeZone.from(releasedAt).format(
                  DATE_TIME_FORMAT
                )}`,
              }
            : undefined,
        ].filter(isPresent)}
      />
    </DetailCard>
  );
};

function isTenancyDepositCancellable(deposit: TenancyDepositRecord): boolean {
  return [
    TenancyDepositStatus.New,
    TenancyDepositStatus.Due,
    TenancyDepositStatus.Failed,
  ].includes(deposit.status);
}

function isTenancyDepositTransferrable(deposit: TenancyDepositRecord): boolean {
  return [TenancyDepositStatus.Paid].includes(deposit.status);
}

function canMarkTenancyDepositAsReleased(
  deposit: TenancyDepositRecord,
  data?: GetWalletByWalletOwnerAilornQuery["wallet"]
): boolean {
  return (
    data?.totalBalance.cents === 0 &&
    [TenancyDepositStatus.Paid].includes(deposit.status)
  );
}
