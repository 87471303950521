import React from "react";
import { Badge } from "../../common";
import { TenancyType } from "./getTenancyType";

export function TenancyTypeBadge({
  tenancyType,
}: {
  tenancyType: TenancyType;
}): React.ReactElement {
  switch (tenancyType) {
    case "Active":
      return <Badge type="success">Current</Badge>;
    case "Ingoing":
      return <Badge type="default">Ingoing</Badge>;
    case "Former":
      return <Badge type="default">Former</Badge>;
    default:
      return <Badge type="default">{tenancyType}</Badge>;
  }
}
