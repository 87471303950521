import { RecurringDate } from "@ailo/date";
import {
  BulkActionProps,
  EditButton,
  ListButton,
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useShowController,
} from "react-admin";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import React from "react";
import {
  ActionInitiatorField,
  CopyableTextField,
  MoneyField,
  MutationButton,
  OrganisationField,
} from "../../common";
import { humanize } from "./feeBlueprintTypes";
import { FeeBlueprint as FeeBlueprintResource, OperationData } from "../../api";
import { FeeBlueprint } from "../../api/graphql/types.generated";
import { useFeeBlueprintAnalytics } from "./useFeeBlueprintAnalytics";

type FeeBlueprintRecord = OperationData<typeof FeeBlueprintResource, "GET_ONE">;

const Actions = ({
  basePath,
  data: record,
  resource,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
BulkActionProps & { data?: any }) => {
  const { onFeeBlueprintMutated } = useFeeBlueprintAnalytics();

  if (!record) return null;

  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
      <EditButton basePath={basePath} record={record} />
      {!record.archived && (
        <MutationButton
          {...{
            resource: resource!,
            record,
          }}
          type="archive"
          icon={<ArchiveIcon />}
          confirmationAlert="Are you sure you want to archive this fee blueprint?"
          onSuccess={({ data }: { data: FeeBlueprint }) => {
            onFeeBlueprintMutated("Fee Blueprint Archived", data);
          }}
        />
      )}
      {record.archived && (
        <MutationButton
          {...{
            resource: resource!,
            record,
          }}
          type="unarchive"
          icon={<UnarchiveIcon />}
          onSuccess={({ data }: { data: FeeBlueprint }) => {
            onFeeBlueprintMutated("Fee Blueprint Restored", data);
          }}
        />
      )}
    </TopToolbar>
  );
};

export const FeeBlueprintShow = (props: ShowProps): React.ReactElement => {
  const { record: feeBlueprint } = useShowController<FeeBlueprintRecord>(props);

  return (
    <Show actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <CopyableTextField label="ID" source="id" />
        <OrganisationField source="organisation" />
        <TextField source="name" />
        <FunctionField<FeeBlueprintRecord>
          label="Fee type"
          render={(record) => (record?.type ? humanize(record?.type) : null)}
        />
        <TextField label="Tax category" source="taxCategory.name" />
        {feeBlueprint?.frequency && (
          <FunctionField
            label="Frequency"
            source="frequency"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={(record: any) =>
              record.frequency ? new RecurringDate(record).format() : null
            }
          />
        )}
        {feeBlueprint?.event && (
          <FunctionField<FeeBlueprintRecord>
            label="Fee trigger"
            source="event"
            render={() => feeBlueprint.event?.longDescription}
          />
        )}
        {feeBlueprint?.fixedAmount && (
          <MoneyField
            label="Default Price (incl. tax)"
            source="fixedAmount"
            frequencySource="frequency"
          />
        )}
        {feeBlueprint?.oneWeekRentPercentage && (
          <FunctionField<FeeBlueprintRecord>
            label="Default Price (incl. tax)"
            source="oneWeekRentPercentage"
            render={(record) =>
              `${record?.oneWeekRentPercentage} week(s) of rent`
            }
          />
        )}
        <ActionInitiatorField
          label="Created"
          initiatedAtSource="createdAt"
          initiatedBySource="createdBy.ailoRN"
        />
        <ActionInitiatorField
          label="Modified"
          initiatedBySource="modifiedBy.ailoRN"
        />
        <ActionInitiatorField
          label="Archived"
          initiatedAtSource="archivedAt"
          initiatedBySource="archivedBy.ailoRN"
        />
      </SimpleShowLayout>
    </Show>
  );
};
