import { envVars } from "../../envVars";

export type ImpersonatableApp = "consumer-app" | "agency-app";

export function impersonateUser({
  app,
  userAuth0Id,
}: {
  app: ImpersonatableApp;
  userAuth0Id: string;
}): void {
  if (
    !window.confirm(
      `Impersonating a user will trigger a notification and all your actions will be recorded. Proceed?\n\nP.S. Remember that you have to be logged in the app using your admin account. Only then the impersonation will work. If the app asks you to log in, use your admin account details. If you end up being logged in as a different user, sign out and click impersonate button again.`
    )
  ) {
    return;
  }

  window.open(getImpersonateUrl(app, userAuth0Id), "_blank", "incognito=true");
}

export function getImpersonateUrl(
  app: ImpersonatableApp,
  userAuth0Id: string
): string {
  const appUrl =
    app === "consumer-app"
      ? envVars.auth0.consumerAppUrl
      : app === "agency-app"
      ? envVars.auth0.agencyAppUrl
      : undefined;

  if (!appUrl) {
    throw new TypeError(`Unknown app: ${app}`);
  }

  const impersonateUrl = `${appUrl}?loginAs=${userAuth0Id}`;
  return impersonateUrl;
}
