export function getTaxCategoryName(taxCategoryId: string): string {
  switch (taxCategoryId) {
    case "ADMINISTRATION_FEES":
      return "Administration Fee";
    case "ADVERTISING_FEES":
      return "Advertising Fee";
    case "AIR_CONDITIONING":
      return "Air Conditioning";
    case "APPLIANCES":
      return "Appliances";
    case "BANK_CHARGES":
      return "Bank Charges";
    case "BLINDS_AND_CURTAINS":
      return "Blinds and Curtains";
    case "CAPITAL_EXPENDITURES":
      return "Capital Expenditure";
    case "CLEANING":
      return "Cleaning";
    case "COMPENSATION_TO_TENANT":
      return "Compensation To Tenant";
    case "COUNCIL_RATES":
      return "Council Rates";
    case "ELECTRICAL_REPAIRS":
      return "Electrical Repair";
    case "ELECTRICITY_RATES":
      return "Electricity Rates";
    case "ANNUAL_FEES":
      return "Annual Fee";
    case "FIRE_SAFETY_PROTECTION_EQUIPMENT":
      return "Fire Safety Protection Equipment";
    case "FLOORING_AND_CARPETS":
      return "Flooring & Carpets";
    case "GARDENING":
      return "Gardening";
    case "GENERAL_REPAIRS":
      return "General Repairs";
    case "INSPECTION_FEES":
      return "Inspection Fee";
    case "INSURANCE_AND_COMPLIANCE":
      return "Insurance & Compliance";
    case "LAND_TAX":
      return "Land Tax";
    case "LEASE_RENEWAL_FEES":
      return "Lease Renewal Fee";
    case "LETTING_FEES":
      return "Letting Fee";
    case "LOCKS_AND_ACCESS_CARDS":
      return "Keys, Locks & Access Card";
    case "MANAGEMENT_FEES":
      return "Management Fee";
    case "OTHER_EXPENSES":
      return "Other Expenses";
    case "PAINTING":
      return "Painting";
    case "PEST_CONTROL":
      return "Pest Control";
    case "PLUMBING":
      return "Plumbing";
    case "POOL_SERVICE_AND_MAINTENANCE":
      return "Pool Service & Maintenance";
    case "RENT":
      return "Rent";
    case "STRATA_LEVIES":
      return "Strata Levy";
    case "TRIBUNAL_FEES":
      return "Tribunal Fee";
    case "WATER_RATES":
      return "Water Rates";
    case "WINDOWS_AND_DOORS":
      return "Windows & Doors";
    case "LEASE_BREAK_FEES":
      return "Lease Break Fees";
    case "LEASE_PREPARATION_FEES":
      return "Lease Preparation Fees";
    case "SALES_ADVERTISING_FEES":
      return "Sales Advertising Fees";
    case "ADVERTISING_SIGN":
      return "Advertising Sign";
    case "ENTRY_INSPECTION_FEES":
      return "Entry Inspection Fees";
    case "EXIT_INSPECTION_FEES":
      return "Exit Inspection Fees";
    case "PHOTOGRAPHY_FEES":
      return "Photography Fees";
    case "BREAK_MANAGEMENT_FEE":
      return "Break Management Fee";
    case "GAS_RATES":
      return "Gas Rates";
    case "EMERGENCY_SERVICES_LEVY":
      return "Emergency Services Levy";
    case "CHANGE_OF_TENANT":
      return "Change of Tenant";
    case "RESCHEDULED_INSPECTION":
      return "Rescheduled Inspection";
    case "SMOKE_ALARMS_COMPLIANCE":
      return "Smoke Alarms Compliance";
    case "SEPTIC_SERVICE":
      return "Septic Service";
    case "INVOICE_FEES":
      return "Invoice Fees";
    case "PROJECT_MANAGEMENT_FEES":
      return "Project Management Fees";
    case "INSURANCE_CLAIM_FEES":
      return "Insurance Claim Fees";
    case "TITLE_SEARCH_FEES":
      return "Title Search Fees";
    case "PHONE_AND_INTERNET":
      return "Phone & Internet";
    default:
      return taxCategoryId;
  }
}
