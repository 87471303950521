import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { ClassNameMap } from "@material-ui/styles";
import moment from "moment-timezone";
import React, { ReactElement } from "react";
import {
  ArrayInput,
  BooleanInput,
  DateField,
  DateInput,
  EditProps,
  FormDataConsumer,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin";
import { MigratingManagement, OperationData } from "../../api";
import { Edit, SimpleForm } from "../../common";
import { RemoveTenancyButton } from "./removeTenancy";
import {
  HealthCheckType,
  MigratingManagementHealthCheckCardProps,
  MigratingManagementsHealthCheckCard,
} from "./migratingManagementsHealthCheckCard";
import {
  isManagementFeeBlueprintEnabled,
  TriggerBasedFeesFormList,
} from "./triggerBasedFeesFormList";

export type MigrationIssue = {
  field?: string;
  value?: string;
  description: string;
};

type MigratingManagementRecord = OperationData<
  typeof MigratingManagement,
  "GET_ONE"
>;

const requiredValidate = [required()];

const minValue = (
  min: number,
  message = "Anniversary Day should be [1, 28] for Monthly and Yearly, [1,7] for Weekly. Anniversary Month should be [1,12]."
) => (value: number) => (value && value < min ? message : undefined);

const maxValue = (
  max: number,
  message = "Anniversary Day should be [1, 28] for Monthly and Yearly, [1,7] for Weekly. Anniversary Month should be [1,12]."
) => (value: number) => (value && value > max ? message : undefined);

const validateAnniversaryDay = [
  required(),
  number(),
  minValue(1),
  maxValue(28),
];
const validateAnniversaryMonth = [number(), minValue(1), maxValue(12)];
const validateFixedAmount = [
  required(),
  number(),
  minValue(0, "value should be >= 0"),
];

function MigratingManagementTitle({
  record,
}: {
  record?: MigratingManagementRecord;
}): React.ReactElement | null {
  const { body } = record!;

  if (body.management && body.management.property && body.management.property) {
    const { property } = body.management;
    return (
      <>
        {[
          property.unitStreetNumber,
          property.streetName,
          property.suburb,
          property.postcode,
          property.country,
        ].join(" ")}
      </>
    );
  }

  return null;
}

export const useStyles = makeStyles({
  error: {
    "& p": {
      color: "red",
      fontWeight: "bold",
      margin: 0,
    },
    "& div:before": {
      borderBottomColor: "#f44336",
    },
  },
  valid: {
    color: "black",
  },
});

const ErrorsField = ({ record }: { record?: MigratingManagementRecord }) => {
  if (!record?.errors) return null;

  const cards = [];

  const potentialIssues: MigratingManagementHealthCheckCardProps[] = [
    {
      issues: (record.errors as unknown) as MigrationIssue[],
      type: HealthCheckType.ISSUE,
    },
    {
      issues: (record.postApprovalTasks as unknown) as MigrationIssue[],
      type: HealthCheckType.POST_APPROVAL_TASK,
    },
  ];

  for (const { issues, type } of potentialIssues) {
    if ((issues || []).length >= 1) {
      cards.push(
        <MigratingManagementsHealthCheckCard
          key="health-check-card"
          issues={issues}
          type={type}
        />
      );
    }
  }

  if (cards.length === 0) {
    cards.push(
      <Alert
        key="no-health-issues"
        severity="success"
        style={{ marginBottom: 10 }}
      >
        <AlertTitle>No health issues!</AlertTitle>
        <div>This management looks ready to go!</div>
      </Alert>
    );
  }

  if (record.migrateAsVacant) {
    cards.push(
      <Alert
        key="migrating-as-vacant"
        severity="warning"
        style={{ marginBottom: 10 }}
      >
        <AlertTitle>Migrating as vacant</AlertTitle>
        <div>
          The PM has marked this management to be automatically migrated as
          vacant
        </div>
      </Alert>
    );
  }

  return <>{cards}</>;
};

const FailedMigrationField = ({
  record,
}: {
  record?: MigratingManagementRecord;
}) => {
  if (!record?.migrationFailureReason) return null;
  return (
    <Alert severity="error">
      <AlertTitle>
        {record.statusChangedAt
          ? `This management failed to migrate (${moment(
              record.statusChangedAt
            ).format("YYYY-MM-DD HH:mm:ss")})`
          : "This management failed to migrate"}
      </AlertTitle>
      <ul>
        <li>{record.migrationFailureReason}</li>
      </ul>
    </Alert>
  );
};

export const ErrorStyle = (
  record: MigratingManagementRecord,
  source: string,
  classes: ClassNameMap<"error" | "valid">
): string | undefined => {
  const errors = (record?.errors || []).filter(
    (err: { field: string }) => `body.${err.field}` === source
  );
  return errors.length > 0 ? classes.error : undefined;
};

export const HelperText = (
  record: MigratingManagementRecord,
  source: string
): string | Record<string, never> => {
  const errors = (record?.errors || []).filter(
    (err: { field: string }) => `body.${err.field}` === source
  );
  return errors.length > 0
    ? errors.map((err: MigrationIssue) => err.description).join(", ")
    : {};
};

const getCountryChoices = (record: MigratingManagementRecord) => {
  const countryChoices = [
    {
      id: "Australia",
      name: "Australia",
    },
    {
      id: "New Zealand",
      name: "New Zealand",
    },
    {
      id: "USA",
      name: "USA",
    },
    {
      id: "UK",
      name: "UK",
    },
  ];
  const currentCountry = record?.body.management.property.country;
  const supportedCountries = countryChoices.map(({ id }) => id);

  if (currentCountry && !supportedCountries.includes(currentCountry)) {
    return [
      ...countryChoices,
      {
        id: currentCountry,
        name: currentCountry,
      },
    ];
  }

  return countryChoices;
};

export const MigratingManagementsEdit = (props: EditProps): ReactElement => {
  const styles = {
    width: "100%",
  };
  const classes = useStyles();
  return (
    <Edit title={<MigratingManagementTitle />} {...props}>
      <SimpleForm toolbarOptions={{ deleteButton: false }}>
        <TextField label="Id" source="id" />
        <TextField label="Status" source="status" />
        <DateField
          label="Last healthcheck"
          source="healthcheckRunAt"
          showTime
        />
        <FailedMigrationField />
        <ErrorsField />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Card style={styles}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Property Address
                  </Typography>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={2}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <TextInput
                        style={styles}
                        label="unit/street number"
                        source="body.management.property.unitStreetNumber"
                        helperText={HelperText(
                          formData,
                          "body.management.property.unitStreetNumber"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.property.unitStreetNumber",
                          classes
                        )}
                        fullWidth
                      />
                    </Box>
                    <Box
                      flex={8}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <TextInput
                        label="street name"
                        source="body.management.property.streetName"
                        helperText={HelperText(
                          formData,
                          "body.management.property.streetName"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.property.streetName",
                          classes
                        )}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={1}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <TextInput
                        label="suburb"
                        source="body.management.property.suburb"
                        helperText={HelperText(
                          formData,
                          "body.management.property.suburb"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.property.suburb",
                          classes
                        )}
                        fullWidth
                      />
                    </Box>
                    <Box
                      flex={2}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <TextInput
                        label="state"
                        source="body.management.property.state"
                        helperText={HelperText(
                          formData,
                          "body.management.property.state"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.property.state",
                          classes
                        )}
                        fullWidth
                      />
                    </Box>
                    <Box
                      flex={2}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <TextInput
                        label="postcode"
                        source="body.management.property.postcode"
                        helperText={HelperText(
                          formData,
                          "body.management.property.postcode"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.property.postcode",
                          classes
                        )}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <SelectInput
                      label="country"
                      source="body.management.property.country"
                      helperText={HelperText(
                        formData,
                        "body.management.property.country"
                      )}
                      className={
                        ErrorStyle(
                          formData,
                          "body.management.property.country",
                          classes
                        ) as string
                      }
                      choices={getCountryChoices(formData)}
                    />
                  </Box>
                </CardContent>
              </Card>
            );
          }}
        </FormDataConsumer>

        <Box mt="1em" />

        <Card style={styles}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Owners
            </Typography>
            <ArrayInput
              style={styles}
              label="owners"
              source="body.management.owners"
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ formData, getSource }) => {
                    return (
                      <div>
                        <Box
                          style={styles}
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                        >
                          <Box
                            flex={2}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="legal first name"
                              source={getSource!("firstName")}
                              helperText={HelperText(
                                formData,
                                getSource!("firstName")
                              )}
                              className={ErrorStyle(
                                formData,
                                getSource!("firstName"),
                                classes
                              )}
                            />
                          </Box>
                          <Box
                            flex={2}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="legal last name"
                              source={getSource!("lastName")}
                              helperText={HelperText(
                                formData,
                                getSource!("lastName")
                              )}
                              className={ErrorStyle(
                                formData,
                                getSource!("lastName"),
                                classes
                              )}
                            />
                          </Box>
                          <Box
                            flex={2}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="email"
                              source={getSource!("email")}
                              helperText={HelperText(
                                formData,
                                getSource!("email")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("email"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>
                          <Box
                            flex={2}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="phone"
                              source={getSource!("phone")}
                              helperText={HelperText(
                                formData,
                                getSource!("phone")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("phone"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>
                          <Box
                            flex={1}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <NumberInput
                              fullWidth
                              label="shares owned"
                              source={getSource!("sharesOwned")}
                              helperText={HelperText(
                                formData,
                                getSource!("sharesOwned")
                              )}
                              className={ErrorStyle(
                                formData,
                                getSource!("sharesOwned"),
                                classes
                              )}
                            />
                          </Box>
                          <Box
                            flex={2}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <BooleanInput
                              fullWidth
                              label="migrating customer"
                              source={getSource!("migratingCustomer")}
                              defaultValue
                            />
                          </Box>
                        </Box>
                        <Box
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                          style={styles}
                        >
                          <Box
                            flex={2}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="company name"
                              source={getSource!("companyName")}
                              helperText={HelperText(
                                formData,
                                getSource!("companyName")
                              )}
                              className={ErrorStyle(
                                formData,
                                getSource!("companyName"),
                                classes
                              )}
                            />
                          </Box>
                          <Box
                            flex={1}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="ABN/ACN"
                              source={getSource!("ABN")}
                              helperText={HelperText(
                                formData,
                                getSource!("ABN")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("ABN"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>
                        </Box>

                        <Box
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                          style={styles}
                        >
                          <Box
                            flex={2}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <BooleanInput
                              fullWidth
                              label="passive"
                              source={getSource!("passive")}
                              defaultValue={false}
                            />
                          </Box>
                        </Box>
                        <Box
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                          style={styles}
                        >
                          <Box
                            flex={2}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <DateInput
                              label="next disbursement"
                              source={getSource!(
                                "withdrawalSettings.nextWithdrawal"
                              )}
                              helperText={HelperText(
                                formData,
                                getSource!("withdrawalSettings.nextWithdrawal")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!(
                                    "withdrawalSettings.nextWithdrawal"
                                  ),
                                  classes
                                ) as string
                              }
                            />
                          </Box>
                          <Box
                            flex={2}
                            ml={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <SelectInput
                              label="Withdrawal frequency"
                              source={getSource!(
                                "withdrawalSettings.frequency"
                              )}
                              choices={[
                                {
                                  id: "daily",
                                  name: "daily",
                                },
                                {
                                  id: "weekly",
                                  name: "weekly",
                                },
                                {
                                  id: "fortnightly",
                                  name: "fortnightly",
                                },
                                {
                                  id: "twicemonthly",
                                  name: "twice monthly",
                                },
                                {
                                  id: "monthly",
                                  name: "monthly",
                                },
                                {
                                  id: "manual",
                                  name: "manual",
                                },
                              ]}
                              helperText={HelperText(
                                formData,
                                getSource!("withdrawalSettings.frequency")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("withdrawalSettings.frequency"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>
                          <Box
                            flex={8}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          />
                        </Box>
                        <ArrayInput
                          style={styles}
                          label="bank accounts"
                          source={getSource!("withdrawalSettings.bankAccounts")}
                        >
                          <SimpleFormIterator>
                            <FormDataConsumer>
                              {({
                                formData: formDataInner,
                                getSource: getSourceInner,
                              }) => (
                                <div>
                                  <Box
                                    style={styles}
                                    display={{
                                      xs: "block",
                                      sm: "flex",
                                    }}
                                  >
                                    <Box
                                      flex={1}
                                      mr={{
                                        xs: 0,
                                        sm: "0.5em",
                                      }}
                                    >
                                      <TextInput
                                        fullWidth
                                        label="BSB"
                                        source={getSourceInner!("bsb")}
                                        helperText={HelperText(
                                          formDataInner,
                                          getSourceInner!("bsb")
                                        )}
                                        className={ErrorStyle(
                                          formDataInner,
                                          getSourceInner!("bsb"),
                                          classes
                                        )}
                                      />
                                    </Box>
                                    <Box
                                      flex={2}
                                      ml={{
                                        xs: 0,
                                        sm: "0.5em",
                                      }}
                                    >
                                      <TextInput
                                        fullWidth
                                        label="account number"
                                        source={getSourceInner!(
                                          "accountNumber"
                                        )}
                                        helperText={HelperText(
                                          formDataInner,
                                          getSourceInner!("accountNumber")
                                        )}
                                        className={
                                          ErrorStyle(
                                            formDataInner,
                                            getSourceInner!("accountNumber"),
                                            classes
                                          ) as string
                                        }
                                      />
                                    </Box>
                                    <Box
                                      flex={2}
                                      ml={{
                                        xs: 0,
                                        sm: "0.5em",
                                      }}
                                    >
                                      <TextInput
                                        fullWidth
                                        label="account name"
                                        source={getSourceInner!("accountName")}
                                        helperText={HelperText(
                                          formDataInner,
                                          getSourceInner!("accountName")
                                        )}
                                        className={ErrorStyle(
                                          formDataInner,
                                          getSourceInner!("accountName"),
                                          classes
                                        )}
                                      />
                                    </Box>
                                    <Box
                                      flex={1}
                                      mr={{
                                        xs: 0,
                                        sm: "0.5em",
                                      }}
                                    >
                                      <TextInput
                                        fullWidth
                                        label="percentage"
                                        source={getSourceInner!("percentage")}
                                        helperText={HelperText(
                                          formDataInner,
                                          getSourceInner!("percentage")
                                        )}
                                        className={
                                          ErrorStyle(
                                            formDataInner,
                                            getSourceInner!("percentage"),
                                            classes
                                          ) as string
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </div>
                              )}
                            </FormDataConsumer>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </div>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </CardContent>
        </Card>

        <Box mt="1em" />

        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Card style={styles}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Management Fee
                  </Typography>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                    style={styles}
                  >
                    <Box
                      flex={2}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <NumberInput
                        label="Management Fee %"
                        source="body.fees.management.value"
                        helperText={HelperText(
                          formData,
                          "body.fees.management.value"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.fees.management.value",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={2}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <SelectInput
                        label="Fee type"
                        source="body.fees.management.type"
                        choices={[
                          {
                            id: "percent",
                            name: "percent",
                          },
                          {
                            id: "fixed",
                            name: "fixed",
                          },
                        ]}
                        helperText={HelperText(
                          formData,
                          "body.fees.management.type"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.fees.management.type",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={8}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    />
                  </Box>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                    style={styles}
                  >
                    <Box
                      flex={1}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        label="Management start date"
                        source="body.management.startDate"
                        helperText={HelperText(
                          formData,
                          "body.management.startDate"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.startDate",
                          classes
                        )}
                      />
                    </Box>

                    <Box
                      flex={3}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        fullWidth
                        label="Management agreement expiry date"
                        source="body.management.agreementEndDate"
                        helperText={HelperText(
                          formData,
                          "body.management.agreementEndDate"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.agreementEndDate",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={10}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            );
          }}
        </FormDataConsumer>

        <Box mt="1em" />

        <Card style={styles}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Time Based Recurring Fee
            </Typography>
            <ArrayInput
              style={styles}
              label="weekly, monthly and yearly recurring agency fees (including trigger based statement fees)"
              source="body.fees.recurringFees.data"
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ formData, getSource, scopedFormData }) => {
                    return (
                      <div>
                        <Box
                          style={styles}
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                        >
                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              label="fee name"
                              source={getSource!("templateName")}
                              helperText={HelperText(
                                formData,
                                getSource!("templateName")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("templateName"),
                                  classes
                                ) as string
                              }
                              validate={requiredValidate}
                            />
                          </Box>
                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              label="fee value"
                              source={getSource!("value")}
                              helperText={HelperText(
                                formData,
                                getSource!("value")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("value"),
                                  classes
                                ) as string
                              }
                              validate={validateFixedAmount}
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <SelectInput
                              disabled
                              label="fee type"
                              source={getSource!("type")}
                              choices={[
                                {
                                  id: "Fixed",
                                  name: "Fixed",
                                },
                              ]}
                              defaultValue="Fixed"
                              helperText={HelperText(
                                formData,
                                getSource!("type")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("type"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <BooleanInput
                              label="tax inclusive"
                              source={getSource!("taxInclusive")}
                              defaultValue
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              label="template default value"
                              source={getSource!("templateDefaultValue")}
                              helperText={HelperText(
                                formData,
                                getSource!("templateDefaultValue")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("templateDefaultValue"),
                                  classes
                                ) as string
                              }
                              defaultValue={0}
                              validate={validateFixedAmount}
                            />
                          </Box>
                        </Box>

                        <Box
                          style={styles}
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                        >
                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <SelectInput
                              fullWidth
                              label="frequency"
                              source={getSource!("feeTemplateFrequency")}
                              choices={[
                                {
                                  id: "Weekly",
                                  name: "Weekly",
                                },
                                {
                                  id: "Monthly",
                                  name: "Monthly",
                                },
                                {
                                  id: "Yearly",
                                  name: "Yearly",
                                },
                              ]}
                              defaultValue="Monthly"
                              helperText={HelperText(
                                formData,
                                getSource!("feeTemplateFrequency")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("feeTemplateFrequency"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <NumberInput
                              fullWidth
                              label="anniversary day"
                              source={getSource!("anniversaryDay")}
                              helperText={HelperText(
                                formData,
                                getSource!("anniversaryDay")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("anniversaryDay"),
                                  classes
                                ) as string
                              }
                              validate={validateAnniversaryDay}
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <NumberInput
                              fullWidth
                              label="anniversary month"
                              source={getSource!("anniversaryMonth")}
                              helperText={HelperText(
                                formData,
                                getSource!("anniversaryMonth")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("anniversaryMonth"),
                                  classes
                                ) as string
                              }
                              validate={validateAnniversaryMonth}
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <SelectInput
                              fullWidth
                              label="tax category"
                              source={getSource!("feeTaxCategory")}
                              choices={[
                                {
                                  id: "ADMINISTRATION_FEES",
                                  name: "ADMINISTRATION_FEES",
                                },
                                {
                                  id: "MANAGEMENT_FEES",
                                  name: "MANAGEMENT_FEES",
                                },
                              ]}
                              defaultValue="ADMINISTRATION_FEES"
                              helperText={HelperText(
                                formData,
                                getSource!("feeTaxCategory")
                              )}
                              className={
                                ErrorStyle(
                                  formData,
                                  getSource!("feeTaxCategory"),
                                  classes
                                ) as string
                              }
                            />
                          </Box>

                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              disabled
                              label="external source id"
                              source={getSource!("sourceId")}
                              helperText={HelperText(
                                formData,
                                getSource!("sourceId")
                              )}
                              className={ErrorStyle(
                                formData,
                                getSource!("sourceId"),
                                classes
                              )}
                            />
                          </Box>
                        </Box>

                        <Box
                          style={styles}
                          display={{
                            xs: "block",
                            sm: "flex",
                          }}
                        >
                          <Box
                            flex={1}
                            mr={{
                              xs: 0,
                              sm: "0.5em",
                            }}
                          >
                            <TextInput
                              fullWidth
                              disabled
                              label="external fee template id"
                              source={getSource!("feeTemplateId")}
                              helperText={HelperText(
                                formData,
                                getSource!("feeTemplateId")
                              )}
                              className={ErrorStyle(
                                formData,
                                getSource!("feeTemplateId"),
                                classes
                              )}
                            />
                          </Box>
                        </Box>
                        {scopedFormData && scopedFormData.convertedFrom && (
                          <Card style={{ ...styles, marginBottom: 10 }}>
                            <CardContent>
                              <Typography variant="subtitle1" gutterBottom>
                                Converted from trigger based fee:
                              </Typography>
                              <Box
                                style={styles}
                                display={{
                                  xs: "block",
                                  sm: "flex",
                                }}
                              >
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="trigger type"
                                    source={getSource!(
                                      "convertedFrom.triggerType"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.triggerType")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.triggerType"),
                                      classes
                                    )}
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="fee name"
                                    source={getSource!("convertedFrom.feeName")}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.feeName")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.feeName"),
                                      classes
                                    )}
                                  />
                                </Box>

                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="fee value"
                                    source={getSource!("convertedFrom.value")}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.value")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.value"),
                                      classes
                                    )}
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="fee type"
                                    source={getSource!(
                                      "convertedFrom.valueType"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.valueType")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.valueType"),
                                      classes
                                    )}
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="charge type"
                                    source={getSource!(
                                      "convertedFrom.chargeType"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.chargeType")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.chargeType"),
                                      classes
                                    )}
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="owner disburse frequency"
                                    source={getSource!(
                                      "convertedFrom.ownerDisburseFrequency"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!(
                                        "convertedFrom.ownerDisburseFrequency"
                                      )
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!(
                                        "convertedFrom.ownerDisburseFrequency"
                                      ),
                                      classes
                                    )}
                                  />
                                </Box>
                              </Box>
                              <Box
                                style={styles}
                                display={{
                                  xs: "block",
                                  sm: "flex",
                                }}
                              >
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="properties count"
                                    source={getSource!(
                                      "convertedFrom.propertiesCount"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!(
                                        "convertedFrom.propertiesCount"
                                      )
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!(
                                        "convertedFrom.propertiesCount"
                                      ),
                                      classes
                                    )}
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="external source id"
                                    source={getSource!(
                                      "convertedFrom.sourceId"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.sourceId")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.sourceId"),
                                      classes
                                    )}
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  mr={{
                                    xs: 0,
                                    sm: "0.5em",
                                  }}
                                >
                                  <TextInput
                                    fullWidth
                                    disabled
                                    label="fee template id"
                                    source={getSource!(
                                      "convertedFrom.feeTemplateId"
                                    )}
                                    helperText={HelperText(
                                      formData,
                                      getSource!("convertedFrom.feeTemplateId")
                                    )}
                                    className={ErrorStyle(
                                      formData,
                                      getSource!("convertedFrom.feeTemplateId"),
                                      classes
                                    )}
                                  />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </CardContent>
        </Card>

        <Box mt="1em" />

        <FormDataConsumer>
          {({ formData }) => {
            if (
              isManagementFeeBlueprintEnabled(
                formData.organisation.availableFeatures
              )
            ) {
              return (
                <Card style={styles}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Manual Fee Templates
                    </Typography>
                    <TriggerBasedFeesFormList
                      label="First rent receipt fees"
                      source="body.fees.triggerBasedFees.firstRentReceipt"
                    />
                    <TriggerBasedFeesFormList
                      label="Manual fees"
                      source="body.fees.triggerBasedFees.manual"
                    />
                    <TriggerBasedFeesFormList
                      label="Inspection fees"
                      source="body.fees.triggerBasedFees.inspection"
                    />
                    <TriggerBasedFeesFormList
                      label="Bill created fees"
                      source="body.fees.triggerBasedFees.billCreated"
                    />
                    <TriggerBasedFeesFormList
                      label="Invoice receipt fees"
                      source="body.fees.triggerBasedFees.invoiceReceipt"
                    />
                  </CardContent>
                </Card>
              );
            }
            return null;
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => {
            return formData.migrateAsVacant ? null : (
              <Card style={styles}>
                <CardContent>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 12,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Tenancy
                    </Typography>
                    <RemoveTenancyButton id={formData.id} />
                  </Box>
                  <Box
                    style={styles}
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={2}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        label="start date"
                        source="body.management.tenancy.startDate"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.startDate"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.startDate",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={1}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        label="move out date"
                        source="body.management.tenancy.moveOutDate"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.moveOutDate"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.moveOutDate",
                          classes
                        )}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={1}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        label="agreement start date"
                        source="body.management.tenancy.agreementStartDate"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.agreementStartDate"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.agreementStartDate",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={1}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <DateInput
                        label="agreement fixed term end date"
                        source="body.management.tenancy.agreementFixedTermEndDate"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.agreementFixedTermEndDate"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.agreementFixedTermEndDate",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={10}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    />
                  </Box>
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Bond
                  </Typography>
                  <Box
                    style={styles}
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={3}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <TextInput
                        label="Bond Reference"
                        source="body.management.tenancy.bond.bondReference"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.bond.bondReference"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.bond.bondReference",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={2}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <NumberInput
                        label="Bond amount ($)"
                        source="body.management.tenancy.bond.bondAmount"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.bond.bondAmount"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.bond.bondAmount",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={10}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    />
                  </Box>
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Rent
                  </Typography>
                  <Box
                    style={styles}
                    display={{
                      xs: "block",
                      sm: "flex",
                    }}
                  >
                    <Box
                      flex={1}
                      mr={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <SelectInput
                        label="rent frequency"
                        source="body.management.tenancy.rent.frequency"
                        choices={[
                          {
                            id: "monthly",
                            name: "monthly",
                          },
                          {
                            id: "weekly",
                            name: "weekly",
                          },
                          {
                            id: "fortnightly",
                            name: "fortnightly",
                          },
                        ]}
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.rent.frequency"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.rent.frequency",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={2}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    >
                      <NumberInput
                        label="Rent amount ($)"
                        source="body.management.tenancy.rent.amount"
                        helperText={HelperText(
                          formData,
                          "body.management.tenancy.rent.amount"
                        )}
                        className={ErrorStyle(
                          formData,
                          "body.management.tenancy.rent.amount",
                          classes
                        )}
                      />
                    </Box>
                    <Box
                      flex={5}
                      ml={{
                        xs: 0,
                        sm: "0.5em",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    Next Rent
                  </Typography>
                  <Box
                    flex={1}
                    mr={{
                      xs: 0,
                      sm: "0.5em",
                    }}
                  >
                    <NumberInput
                      label="next rent amount ($)"
                      source="body.management.tenancy.rentReview.nextRentAmount"
                      helperText={HelperText(
                        formData,
                        "body.management.tenancy.rentReview.nextRentAmount"
                      )}
                      className={
                        ErrorStyle(
                          formData,
                          "body.management.tenancy.rentReview.nextRentAmount",
                          classes
                        ) as string
                      }
                    />

                    <DateInput
                      label="next effective date"
                      source="body.management.tenancy.rentReview.nextRentEffectiveDate"
                      helperText={HelperText(
                        formData,
                        "body.management.tenancy.rentReview.nextRentEffectiveDate"
                      )}
                      className={ErrorStyle(
                        formData,
                        "body.management.tenancy.rentReview.nextRentEffectiveDate",
                        classes
                      )}
                      style={{
                        marginLeft: 15,
                        marginRight: 15,
                      }}
                    />

                    <DateInput
                      label="next review date"
                      source="body.management.tenancy.rentReview.nextReviewDate"
                      helperText={HelperText(
                        formData,
                        "body.management.tenancy.rentReview.nextReviewDate"
                      )}
                      className={
                        ErrorStyle(
                          formData,
                          "body.management.tenancy.rentReview.nextReviewDate",
                          classes
                        ) as string
                      }
                    />
                  </Box>
                </CardContent>
              </Card>
            );
          }}
        </FormDataConsumer>

        <Box mt="1em" />

        <FormDataConsumer>
          {({ formData: parentFormData }) => {
            return parentFormData.migrateAsVacant ? null : (
              <Card style={styles}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Tenants
                  </Typography>
                  <ArrayInput
                    label="tenants"
                    source="body.management.tenancy.tenants"
                  >
                    <SimpleFormIterator>
                      <FormDataConsumer>
                        {({ getSource, formData }) => (
                          <div>
                            <Box
                              style={styles}
                              display={{
                                xs: "block",
                                sm: "flex",
                              }}
                            >
                              <Box
                                flex={1}
                                mr={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <TextInput
                                  fullWidth
                                  label="legal first name"
                                  source={getSource!("firstName")}
                                  helperText={HelperText(
                                    formData,
                                    getSource!("firstName")
                                  )}
                                  className={
                                    ErrorStyle(
                                      formData,
                                      getSource!("lastName"),
                                      classes
                                    ) as string
                                  }
                                />
                              </Box>
                              <Box
                                flex={2}
                                ml={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <TextInput
                                  fullWidth
                                  label="legal last name"
                                  source={getSource!("lastName")}
                                  helperText={HelperText(
                                    formData,
                                    getSource!("lastName")
                                  )}
                                  className={
                                    ErrorStyle(
                                      formData,
                                      getSource!("lastName"),
                                      classes
                                    ) as string
                                  }
                                />
                              </Box>
                              <Box
                                flex={2}
                                ml={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <TextInput
                                  fullWidth
                                  label="email"
                                  source={getSource!("email")}
                                  helperText={HelperText(
                                    formData,
                                    getSource!("email")
                                  )}
                                  className={ErrorStyle(
                                    formData,
                                    getSource!("email"),
                                    classes
                                  )}
                                />
                              </Box>
                              <Box
                                flex={2}
                                ml={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <TextInput
                                  fullWidth
                                  label="phone"
                                  source={getSource!("phone")}
                                  helperText={HelperText(
                                    formData,
                                    getSource!("phone")
                                  )}
                                  className={
                                    ErrorStyle(
                                      formData,
                                      getSource!("phone"),
                                      classes
                                    ) as string
                                  }
                                />
                              </Box>
                              <Box
                                flex={2}
                                ml={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <BooleanInput
                                  fullWidth
                                  label="migrating customer"
                                  source={getSource!("migratingCustomer")}
                                  defaultValue
                                />
                              </Box>
                            </Box>
                            <Box
                              display={{
                                xs: "block",
                                sm: "flex",
                              }}
                              style={styles}
                            >
                              <Box
                                flex={2}
                                mr={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <TextInput
                                  fullWidth
                                  label="company name"
                                  source={getSource!("companyName")}
                                  helperText={
                                    HelperText(
                                      formData,
                                      getSource!("companyName")
                                    ) as string
                                  }
                                  className={
                                    ErrorStyle(
                                      formData,
                                      getSource!("companyName"),
                                      classes
                                    ) as string
                                  }
                                />
                              </Box>
                              <Box
                                flex={1}
                                ml={{
                                  xs: 0,
                                  sm: "0.5em",
                                }}
                              >
                                <TextInput
                                  fullWidth
                                  label="ABN/ACN"
                                  source={getSource!("ABN")}
                                  helperText={HelperText(
                                    formData,
                                    getSource!("ABN")
                                  )}
                                  className={
                                    ErrorStyle(
                                      formData,
                                      getSource!("ABN"),
                                      classes
                                    ) as string
                                  }
                                />
                              </Box>
                            </Box>
                          </div>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </CardContent>
              </Card>
            );
          }}
        </FormDataConsumer>

        <Box mt="1em" />

        <DateInput disabled source="paidTo" />
        <NumberInput
          disabled
          label="Partial Paid (cents)"
          source="partialPaidCents"
        />
        <TextInput
          label="Portfolio name"
          source="body.management.portfolioName"
        />
      </SimpleForm>
    </Edit>
  );
};
