import React, { useState } from "react";
import { TransferButton } from "./TransferButton";
import { WriteOffDialog } from "./WriteOffDialog";

export function WriteOffButton({
  walletId,
}: {
  walletId: string;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <TransferButton onClick={onClick}>Write Off</TransferButton>
      <WriteOffDialog open={open} onClose={onClose} walletId={walletId} />
    </div>
  );
}
