import { AiloRN } from "@ailo/ailorn";
import { CentrepayPaymentDirectiveType } from "./CentrepayPaymentDirectiveType";

export function getOtherTenancyDirective(
  paymentDirectives: CentrepayPaymentDirectiveType[],
  tenancy: string
): CentrepayPaymentDirectiveType | undefined {
  return paymentDirectives.find(
    (directive) => !directive.tenancy.equals(AiloRN.from(tenancy))
  );
}
