import { CREATE, GET_LIST } from "react-admin";
import { transcoRestClient } from "../client/transcoRestClient";

const virtualAccountsClient = (
  client: typeof transcoRestClient,
  raFetchType: string,
  params: Record<string, any>
) => {
  switch (raFetchType) {
    case GET_LIST:
      return Promise.resolve({
        data: [],
        total: 1,
      });

    case CREATE:
      return client
        .upload("admin/virtual_accounts", params as any)
        .then(({ data }) => {
          if (
            data.response.rowsExisting.length ||
            data.response.rowsNotCreated.length
          ) {
            alert("Not all rows were processed successfully");
            console.warn(data.response);
          }

          return {
            data,
          };
        });

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
};

export { virtualAccountsClient };
