import { Box, Divider } from "@material-ui/core";
import React, { ComponentProps, useState } from "react";
import { CloseButton, DetailCard, EditButton } from "../../common";
import { AddAutoWithdrawPlanButton } from "../Wallet";
import { AutoPayBillItem } from "./AutoPayBillItem";
import { AutoWithdrawPlanDisabledItem } from "./AutoWithdrawPlanDisabledItem";
import { AutoWithdrawPlanItem } from "./AutoWithdrawPlanItem";
import { SwitchContainer } from "./SwitchContainer";

/**
 * Should only be used for Management Folio or Agency wallets
 * since only these can set up auto payment (auto withdraw & auto pay bill)
 */
export function AutoPaymentSettingsCard({
  walletOwnerReference,
  legalEntityAilorn,
  title = "Wallet Auto Payment Settings",
  allowEdit,
  autoWithdrawPlans = [],
  addAutoWithdrawPlanSettings,
  autoPayLiabilityStatus,
}: {
  walletOwnerReference: string;
  /**
   * The legal entity allowed to manage the auto payment settings for this wallet.
   *
   * For a Management Folio Wallet, `walletOwnerReference` would be the Management Folio ailorn
   * while `legalEntityAilorn` would be the Folio's primary owner's LE ailorn.
   *
   * For an Agency Wallet, `walletOwnerReference` and `legalEntityAilorn` are the same thing.
   *
   * No other types of wallets can set up auto withdraw/auto pay bills atm
   */
  legalEntityAilorn: string;
  title?: string;
  allowEdit: boolean;
  autoWithdrawPlans?: Array<
    ComponentProps<typeof AutoWithdrawPlanItem>["autoWithdrawPlan"]
  >;
  addAutoWithdrawPlanSettings?: {
    walletId: string;
    walletName?: string;
    /**
     * Transaction description shown on customer's banking transaction history
     *
     * For a Management Folio wallet, this should be the management's property address but
     * this may need to change once a management folio can contain more than one management
     */
    transactionDescription?: string;
  };
  autoPayLiabilityStatus?: ComponentProps<
    typeof AutoPayBillItem
  >["autoPayLiabilityStatus"];
}): React.ReactElement {
  const [edit, setEdit] = useState(false);
  const hasAutoWithdrawPlan = !!autoWithdrawPlans.length;

  return (
    <DetailCard
      title={title}
      action={
        allowEdit && (
          <SwitchContainer display="flex">
            {edit ? (
              <CloseButton onClick={() => setEdit(false)} />
            ) : (
              <EditButton onClick={() => setEdit(true)} />
            )}
          </SwitchContainer>
        )
      }
    >
      {hasAutoWithdrawPlan ? (
        <Box mb={2}>
          {autoWithdrawPlans.map((plan) => {
            return (
              <AutoWithdrawPlanItem
                key={plan.id}
                {...{
                  edit,
                  autoWithdrawPlan: plan,
                  walletOwnerReference,
                }}
              />
            );
          })}
        </Box>
      ) : addAutoWithdrawPlanSettings ? (
        <>
          <AddAutoWithdrawPlanButton
            {...{
              legalEntityAiloRN: legalEntityAilorn,
              ...addAutoWithdrawPlanSettings,
            }}
          />
          <Divider />
        </>
      ) : (
        <AutoWithdrawPlanDisabledItem />
      )}

      {autoPayLiabilityStatus && (
        <Box mb={2} mt={2}>
          <AutoPayBillItem
            {...{
              edit,
              autoPayLiabilityStatus,
              legalEntityAiloRN: legalEntityAilorn,
            }}
          />
        </Box>
      )}
    </DetailCard>
  );
}
