import gql from "graphql-tag";
import { GET_ONE } from "react-admin";
import {
  createClientFromDefinition,
  GraphQLResponse,
  specifyDefinition,
  specifyOperation,
} from "../..";
import {
  GetWalletAutoPaymentSettingsQuery,
  GetWalletAutoPaymentSettingsQueryVariables,
  GetWalletByWalletOwnerAilornQuery,
  GetWalletByWalletOwnerAilornQueryVariables,
  GetWalletQuery,
  GetWalletQueryVariables,
} from "./walletClient.generated";

export const GET_ONE_BY_WALLET_OWNER_AILORN = "get_one_by_wallet_owner_ailorn";
export const GET_WALLET_AUTO_PAYMENT_SETTINGS =
  "get_wallet_auto_payment_settings";

const paymentMethodDetailsFragment = gql`
  fragment PaymentMethodDetails on PaymentMethod {
    __typename
    id
    ... on BankAccount {
      accountName
      accountNumber
      bsb
    }
    ... on BPay {
      billerCode
    }
    ... on CreditCard {
      type
      number
    }
  }
`;

const getOne = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      query getWallet($walletId: ID!) {
        wallet: walletById(walletId: $walletId) {
          id
          name
          totalBalance {
            cents
          }
          availableBalance {
            cents
          }
          createdAt
          owner {
            id
            reference
          }
        }
      }
    `,
    variables: <GetWalletQueryVariables>{
      walletId: params.id,
    },
    parseResponse: (response: GraphQLResponse<GetWalletQuery>) => {
      return {
        data: response.data.wallet,
      };
    },
  };
});

const getOneByWalletAilorn = specifyOperation(
  (params: { walletOwnerAiloRN: string }) => {
    return {
      query: gql`
        query getWalletByWalletOwnerAilorn($walletOwnerAiloRN: AiloRN!) {
          wallet: walletByWalletOwnerReference(
            walletOwnerReference: $walletOwnerAiloRN
          ) {
            id
            name
            availableBalance {
              cents
            }
            totalBalance {
              cents
            }
            idempotencyKey
          }
        }
      `,
      variables: <GetWalletByWalletOwnerAilornQueryVariables>{
        walletOwnerAiloRN: params.walletOwnerAiloRN,
      },
      parseResponse: (
        response: GraphQLResponse<GetWalletByWalletOwnerAilornQuery>
      ) => {
        return {
          data: response.data.wallet,
        };
      },
    };
  }
);

const getWalletAutoPaymentSettings = specifyOperation(
  (params: { walletOwnerAilorn: string }) => {
    return {
      query: gql`
        query getWalletAutoPaymentSettings($walletOwnerAilorn: AiloRN!) {
          wallet: walletByWalletOwnerReference(
            walletOwnerReference: $walletOwnerAilorn
          ) {
            id
            autoWithdrawPlans(enabled: true, disableOwnerFilter: true) {
              items {
                id
                enabled
                owner
                details {
                  frequency
                  anniversary
                  anniversaryDaysOfMonth
                  startDate
                  endDate
                  nextFireTime
                  setAsideAmount {
                    cents
                  }
                  paymentMethodDestinations {
                    paymentMethod {
                      ...PaymentMethodDetails
                    }
                    share
                  }
                  paymentMethod {
                    ...PaymentMethodDetails
                  }
                  isLastDayOfTheMonth
                }
              }
            }

            autoPayLiabilityStatus {
              enabled
              payerId
              maximumPaymentAmount {
                cents
              }
              paymentMethod {
                ...PaymentMethodDetails
              }
            }
          }
        }
        ${paymentMethodDetailsFragment}
      `,
      variables: <GetWalletAutoPaymentSettingsQueryVariables>{
        walletOwnerAilorn: params.walletOwnerAilorn,
      },
      parseResponse: (
        response: GraphQLResponse<GetWalletAutoPaymentSettingsQuery>
      ) => {
        return {
          data: response.data.wallet,
        };
      },
    };
  }
);

export const WalletClientDefinition = specifyDefinition({
  [GET_ONE]: getOne,
  [GET_ONE_BY_WALLET_OWNER_AILORN]: getOneByWalletAilorn,
  [GET_WALLET_AUTO_PAYMENT_SETTINGS]: getWalletAutoPaymentSettings,
});

export const walletClient = createClientFromDefinition(WalletClientDefinition);
