/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import {
  DateTimeInput,
  FormDataConsumer,
  Labeled,
  required,
  useMutation,
  UseMutationValue,
  useNotify,
  useRefresh,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { Money, MoneyInterface } from "@ailo/money";
import moment from "moment-timezone";
import {
  ErrorDialog,
  maxMoney,
  minMoney,
  MoneyInput,
  SimpleFormDialog,
} from "../../common";
import { WalletOverdraftAllowance } from "../../api";
import { envVars } from "../../envVars";

type FormData = {
  walletId: number;
  amount: MoneyInterface;
  startAt: Date;
  endAt: Date;
};

const useCreateOverdraftAllowance = ({
  onSuccess,
}: {
  onSuccess?(): void;
}): UseMutationValue => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    {
      type: "create_overdraft_allowance",
      resource: WalletOverdraftAllowance,
    },
    {
      onSuccess: ({ data }) => {
        const error = data?.error;
        if (error) {
          notify(`Failed to create overdraft allowance. Error: ${error}`);
        } else {
          refresh();
          notify(`Overdraft allowance has been created`);
          onSuccess?.();
        }
      },
      onFailure: (error) => {
        notify(`Failed to create overdraft allowance. Error: ${error}`);
      },
    }
  );
};

type Props = { open: boolean; onClose: () => void; walletId: string };

function Warning() {
  return (
    <Alert severity="warning" style={{ marginBottom: 16 }}>
      <div>Only do this if you know what you&lsquo;re doing!</div>
      <p>
        Please ensure necessary steps are followed to recover the funds after
        the overdraft ends.
      </p>
      <p>
        Please read: &nbsp;
        <a href="https://ailo.atlassian.net/wiki/spaces/TS/pages/2170028530/How+to+Add+an+Overdraft+Allowance">
          confluence docs
        </a>
      </p>
    </Alert>
  );
}

function isValidDate(date: Date) {
  return date && date.toString() !== "Invalid Date";
}

export const CreateOverdraftAllowanceDialog = ({
  open,
  onClose,
  walletId,
}: Props): React.ReactElement => {
  const [createOverdraftAllowance, { error }] = useCreateOverdraftAllowance({
    onSuccess: onClose,
  });
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;

  const submit = async (formData: {
    walletId: string;
    amount: MoneyInterface;
    startAt: Date;
    endAt: Date;
  }) => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `Are you sure you would like to create overdraft allowance on wallet ${walletId} for amount ${Money.fromCents(
          formData.amount.cents
        ).toString()}?`
      )
    ) {
      return;
    }
    await createOverdraftAllowance({
      payload: {
        walletId,
        amountInCents: formData.amount.cents,
        startAt: formData.startAt,
        endAt: formData.endAt,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Creating overdraft allowance failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        walletId,
        startAt: moment().toDate(),
        endAt: moment().add(1, "hours").toDate(),
      }}
      title="Create Overdraft Allowance"
      open={open}
      validate={(values: FormData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: any = {};

        if (!values.walletId) {
          errors.error = "No wallet";
        }
        if (!values.amount || values.amount.cents <= 0) {
          errors.error = "Invalid amount";
        }
        if (!isValidDate(values.startAt)) {
          errors.error = "Invalid start at";
        }
        if (!isValidDate(values.startAt)) {
          errors.error = "Invalid end at";
        }

        return errors;
      }}
      submitLabel="Create"
      save={submit}
      onClose={onClose}
    >
      <FormDataConsumer>
        {() => {
          return (
            <>
              <Warning />
              <Labeled label="How much? (in $)" fullWidth>
                <MoneyInput
                  source="amount"
                  validate={[
                    required(),
                    minMoney({ cents: 1 }),
                    maxMoney({
                      cents: envVars.overdraftAllowanceMaxAmountCents || 300000,
                    }),
                  ]}
                />
              </Labeled>
              <Labeled label="Start At" fullWidth>
                <DateTimeInput source="startAt" validate={[required()]} />
              </Labeled>
              <Labeled label="End At" fullWidth>
                <DateTimeInput source="endAt" validate={[required()]} />
              </Labeled>
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleFormDialog>
  );
};
