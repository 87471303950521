import { CardContent, Grid } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import React from "react";
import { Button, Labeled, Link, TextField } from "react-admin";
import { AccountOwner, ManagementFolio } from "../../../api";
import { Colors, DetailCard, IdField, opacify } from "../../../common";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";
import { ManagementFolioWalletSection } from "./ManagementFolioWalletSection";

export const ManagementFolioTitleCard = ({
  managementFolio,
  ownerNames,
}: {
  managementFolio: ManagementFolioShowRecord;
  ownerNames: string;
}): React.ReactElement => {
  return (
    <DetailCard avatar={<FolderIcon />} title={`Portfolio: ${ownerNames}`}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              borderRightWidth: 1,
              borderRightStyle: "solid" as const,
              borderColor: opacify(Colors.SPACE_BLACK, 0.1),
              paddingLeft: 69,
              paddingRight: 69,
            }}
          >
            <Labeled label="Portfolio ID">
              <IdField
                record={managementFolio}
                source="ailorn"
                sourceResource={ManagementFolio}
              />
            </Labeled>

            <Labeled label="Agency">
              <TextField
                record={managementFolio}
                source="managingEntity.registeredEntityName"
              />
            </Labeled>

            <Button
              component={Link}
              to={`/${AccountOwner}/${managementFolio.ailorn}/show`}
              label="Account Owner View"
              style={{ margin: 14 }}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 69,
              paddingRight: 69,
            }}
          >
            <ManagementFolioWalletSection managementFolio={managementFolio} />
          </Grid>
        </Grid>
      </CardContent>
    </DetailCard>
  );
};
