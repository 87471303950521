// TODO Use new List
// eslint-disable-next-line no-restricted-imports
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { List } from "../../../common/views/List";

const VirtualAccountDebitsList = (props: Record<string, any>) => {
  return (
    <List
      {...props}
      title="Virtual Accounts"
      bulkActionButtons={false}
      pagination={false}
    >
      <Card>
        <CardContent>Coming soon...</CardContent>
      </Card>
    </List>
  );
};

export { VirtualAccountDebitsList };
