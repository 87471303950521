import Box from "@material-ui/core/Box";
import SupervisorIcon from "@material-ui/icons/SupervisorAccount";
import React from "react";
import { Button, Link } from "react-admin";
import { formatLegalEntityName } from "@ailo/domain-helpers";
import {
  getImpersonateUrl,
  impersonateUser,
} from "../../../../features/User/impersonateUser";
import { DeprecatedPerson, TrustAccounts } from "../../../../api";
import { Default } from "../../../../common/ui/Badge/default";
import { Success } from "../../../../common/ui/Badge/success";
import {
  DetailCard,
  GoButton,
  LegalEntityAvatar,
  IdField,
} from "../../../../common";
import { InvitePersonButton } from "../../../../features/User";

export const CustomerTitleCard = (props: { record: any }) => {
  const { record } = props;
  const name = formatLegalEntityName(record);
  const isPerson = record.__typename === "Person";
  const isCompany = record.__typename === "Company";
  const { id } = record;
  return (
    <DetailCard
      avatar={
        <LegalEntityAvatar
          legalEntity={record}
          style={{
            width: 70,
            height: 70,
            fontSize: "1rem",
          }}
        />
      }
      title={
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          style={{
            gap: 8,
          }}
        >
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
          >
            <Box display="flex" alignItems="center" style={{}}>
              <Box fontWeight={600}>{name}</Box>
              {isPerson && (
                <Box pl={2}>
                  {getIsPersonActive(record) ? (
                    <Success>Active</Success>
                  ) : (
                    <Default>Inactive</Default>
                  )}
                </Box>
              )}
              {isCompany && (
                <Box pl={2}>
                  {isCompanyAnAgency(record) ? (
                    <Success>Agency</Success>
                  ) : (
                    <Default>Consumer</Default>
                  )}
                </Box>
              )}
            </Box>

            <Box
              style={{
                fontSize: 14,
                color: "grey",
              }}
            >
              <IdField record={record} source="ailoRN" />
            </Box>
          </Box>
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            style={{
              gap: 8,
            }}
          >
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
              style={{
                gap: 8,
              }}
            >
              {isPerson && (
                <GoButton
                  variant="outlined"
                  resource={DeprecatedPerson}
                  id={id}
                  label="Old Person View"
                />
              )}
              {isPerson && (
                <>
                  <InvitePersonButton person={record} />
                  <Button
                    color="primary"
                    variant="outlined"
                    component={Link}
                    to={{
                      pathname: "/Subscriptions/create",
                      state: {
                        record: {
                          customer: record.organisation.id,
                          startsOn: new Date(),
                        },
                      },
                    }}
                    label="Subscribe to Plan"
                  />
                </>
              )}
              {isCompany && (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    component={Link}
                    to={{
                      pathname: `/${TrustAccounts}/create`,
                      state: {
                        record: {
                          ownerLegalEntity: record.id,
                        },
                      },
                    }}
                    label="add trust account"
                    style={{
                      marginLeft: 8,
                    }}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    component={Link}
                    to={{
                      pathname: "/Subscriptions/create",
                      state: {
                        record: {
                          customer: record.organisation.id,
                          startsOn: new Date(),
                        },
                      },
                    }}
                    label="Subscribe to Plan"
                    style={{
                      marginLeft: 8,
                    }}
                  />
                </>
              )}
            </Box>
            {isPerson && record.user && (
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-end"
                style={{
                  gap: 8,
                }}
              >
                <Button
                  href={getImpersonateUrl("consumer-app", record.user.auth0Id)}
                  variant="outlined"
                  color="secondary"
                  label="Impersonate in consumer-app"
                  icon={<SupervisorIcon />}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    impersonateUser({
                      app: "consumer-app",
                      userAuth0Id: record.user.auth0Id,
                    });
                  }}
                />
                <Button
                  href={getImpersonateUrl("agency-app", record.user.auth0Id)}
                  variant="outlined"
                  color="secondary"
                  label="Impersonate in agency-app"
                  icon={<SupervisorIcon />}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    impersonateUser({
                      app: "agency-app",
                      userAuth0Id: record.user.auth0Id,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};

function getIsPersonActive(person: {
  organisation: { activeSubscriptions: { items: string | any[] } };
  signUpCompleted: any;
  eulaSignedAt: any;
}) {
  const hasActiveSubscription =
    person.organisation.activeSubscriptions.items.length > 0;
  return (
    (!!person.signUpCompleted || !!person.eulaSignedAt) && hasActiveSubscription
  );
}
function isCompanyAnAgency(company: { organisation: { orgType: string } }) {
  return company.organisation.orgType === "Agency";
}
