import gql from "graphql-tag";
import { GET_ONE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { GetBondWalletQuery } from "./bondAccountClient.generated";
import { VirtualAccountData } from "../../../common";

export const BondAccountDefinition = {
  [GET_ONE]: (params: { entity: string }) => {
    return {
      query: gql`
        query getBondWallet($entity: AiloRN!) {
          getTenancyBondAccountsByManagingEntity(managingEntity: $entity) {
            id
            paymentMethodCompanion {
              id
              paymentMethod {
                __typename
                wallet {
                  id
                  totalBalance {
                    cents
                  }
                  availableBalance {
                    cents
                  }
                  owner {
                    reference
                  }
                }
                id
                externalId
                ... on VirtualAccount {
                  accountNumber
                  bsb
                }
              }
            }
          }
        }
      `,
      variables: {
        entity: params.entity,
      },
      parseResponse: (
        response: GraphQLResponse<GetBondWalletQuery>
      ): GetTenancyBondAccountsByManagingEntityData => {
        return { data: response.data.getTenancyBondAccountsByManagingEntity };
      },
    };
  },
};

interface GetTenancyBondAccountsByManagingEntityData {
  data: VirtualAccountData[];
}

export const bondAccountClient = createClientFromDefinition(
  BondAccountDefinition
);
