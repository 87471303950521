import React from "react";
import { AutocompleteInput, required, useInput } from "react-admin";
import { formatAddress } from "@ailo/domain-helpers";
import { ReferenceInput } from "../../../../common";
import { Property as PropertyType } from "../../../../api/graphql/types.generated";
import { Property } from "../../../../api/ResourceName";

export function PropertyInput(): React.ReactElement {
  const {
    input: { onChange },
  } = useInput({ source: "property" });
  return (
    <ReferenceInput
      alwaysOn
      label="Enter property address"
      source="property.id"
      reference={Property}
      filterToQuery={(searchTerm: string) => ({ searchTerm })}
      validate={[required()]}
    >
      <AutocompleteInput
        onSelect={(property?: PropertyType) => onChange(property)}
        optionText={(property?: PropertyType) =>
          property &&
          formatAddress(property.address, { format: "street, suburb" })
        }
        shouldRenderSuggestions={(val: string | undefined) => {
          return !!val && val.trim().length > 0;
        }}
        limitChoicesToValue
        options={{
          style: { width: "100%" },
          InputProps: {
            type: "search", // needed to stop 1password from trying to auto fill this field
          },
        }}
        resettable
      />
    </ReferenceInput>
  );
}
