import Grid from "@material-ui/core/Grid";
import React from "react";
import { AutoPaymentSettingsCardContainer } from "../../../features/AutoPaymentSettings";
import { WalletTransactionHistoryCard } from "../../../features/Wallet/WalletTransactionHistoryCard";
import { LegalEntityWalletCards } from "../../Customer/consumers/components/wallets/LegalEntityWalletCards";
import {
  isAgency,
  isCompany,
  LegalEntityShowRecord,
} from "./LegalEntityShowRecord";

export function WalletTab({
  record,
}: {
  record: LegalEntityShowRecord;
}): React.ReactElement {
  const isAgencyWallet = isCompany(record) && isAgency(record);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <LegalEntityWalletCards
          legalEntityAiloRN={record.ailoRN}
          wallets={record.wallets}
          entityType={record?.__typename}
          isAgency={isAgencyWallet}
        />

        {isAgencyWallet && (
          <AutoPaymentSettingsCardContainer
            walletOwnerReference={record.ailoRN}
            legalEntityAilorn={record.ailoRN}
            walletType="Agency"
          />
        )}
      </Grid>

      <Grid item xs={6}>
        <WalletTransactionHistoryCard walletOwnerReference={record.ailoRN} />
      </Grid>
    </Grid>
  );
}
