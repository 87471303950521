import React from "react";
import { Dialog } from "@material-ui/core";
import { ErrorDialogContent } from "./ErrorDialogContent";

export function ErrorDialog({ open = true, title, error, onClose }: any) {
  return (
    <Dialog open={open} onClose={onClose}>
      <ErrorDialogContent
        {...{
          title,
          error,
          onClose,
        }}
      />
    </Dialog>
  );
}
