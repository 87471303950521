import { LocalDate } from "@ailo/date";
import {
  BooleanInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import React from "react";
import { ManagementInput } from "./ManagementInput";
import { useSendStatement } from "./useSendOwnershipStatement";
import { EarlierStatementsAlert } from "./EarlierStatementsAlert";
import { HowToSendStatementsAlert } from "./HowToSendStatementsAlert";
import {
  SimpleFormDialog,
  datesLessThanOrEqualTo,
  isDate,
  maxDateToday,
  minDate,
} from "../../../../common";

import { StatementType } from "../../../../api/graphql/types.generated";

const TIME_ZONE = "Australia/Sydney";
const MIN_OWNERSHIP_STATEMENT_START_DATE = LocalDate.from("2021-07-02");

export const SendStatementDialog = ({
  management,
  open,
  onClose,
  statementTypes,
}: {
  management: ManagementInput;
  open: boolean;
  onClose: () => void;
  statementTypes: StatementType[];
}): React.ReactElement => {
  const { send } = useSendStatement({
    onSuccess: onClose,
  });

  const endDate = earlierOfYesterdayAnd(management.endDate);
  const startDate = endDate.setDayOfMonth(1).toString();

  return (
    <SimpleFormDialog
      initialValues={{
        managementId: management.id,
        startDate: startDate.toString(),
        endDate: endDate.toString(),
        skipValidation: false,
        sendTo: "dryRunEmail",
      }}
      title="Send Ownership Statement"
      open={open}
      save={send}
      onClose={onClose}
    >
      <HowToSendStatementsAlert />
      <TextInput source="managementId" disabled />
      <SelectInput
        label="Statement Type"
        source="statementType"
        choices={statementTypes.map((statementType) => ({
          id: statementType.toString(),
          name: statementType.toString(),
        }))}
        fullWidth
      />

      <FormDataConsumer>
        {({ formData }) => (
          <TextInput
            source="startDate"
            validate={
              formData.statementType === StatementType.Management
                ? [
                    required(),
                    minDate(MIN_OWNERSHIP_STATEMENT_START_DATE, "Start date"),
                  ]
                : [required()]
            }
          />
        )}
      </FormDataConsumer>

      <TextInput
        source="endDate"
        validate={[
          required(),
          maxDateToday(TIME_ZONE, "End date"),
          datesLessThanOrEqualTo("startDate", "endDate"),
        ]}
      />
      <EarlierStatementsAlert
        minStartDate={MIN_OWNERSHIP_STATEMENT_START_DATE}
      />
      <RadioButtonGroupInput
        source="sendTo"
        choices={[
          {
            id: "dryRunEmail",
            name: "Custom email",
          },
          {
            id: "investors",
            name: "Investors",
          },
        ]}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.sendTo === "dryRunEmail" && (
            <>
              <TextInput
                source="dryRunEmail"
                validate={[required()]}
                style={{ width: 256 }}
              />
              <BooleanInput source="skipValidation" defaultValue={false} />
            </>
          )
        }
      </FormDataConsumer>
    </SimpleFormDialog>
  );
};

function earlierOfYesterdayAnd(date: string | null | undefined) {
  const yesterday = LocalDate.today(TIME_ZONE).subtract(1, "day");
  return date && isDate(date)
    ? LocalDate.min(yesterday, LocalDate.from(date))
    : yesterday;
}
