import React, {
  Component,
  ComponentType,
  ReactNode,
  ReactElement,
} from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

export interface ErrorBoundaryRenderFallbackProps {
  error: Error;
}

interface ErrorBoundaryState {
  error?: Error;
}

export type ErrorBoundaryProps = {
  fallbackComponent?: ComponentType<ErrorBoundaryRenderFallbackProps>;
};

export function ErrorBoundaryDefaultRenderFallback(): ReactElement {
  return (
    <Card style={{ margin: 10 }}>
      <CardContent>
        <Typography>
          A technical error occurred. This content couldn't be loaded
        </Typography>
      </CardContent>
    </Card>
  );
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  render(): ReactNode {
    const { error } = this.state;
    const { children, fallbackComponent } = this.props;

    if (error) {
      const Fallback = fallbackComponent ?? ErrorBoundaryDefaultRenderFallback;
      return <Fallback error={error} />;
    }

    return children;
  }
}

export function withErrorBoundary<T>(
  MainComponent: ComponentType<T>,
  options?: ErrorBoundaryProps
): ComponentType<T> {
  function WrappedComponent(props: T): ReactElement {
    return (
      <ErrorBoundary {...options}>
        <MainComponent {...props} />
      </ErrorBoundary>
    );
  }

  WrappedComponent.displayName = `WithErrorBoundary(${
    MainComponent.displayName || MainComponent.name || "Component"
  })`;

  return WrappedComponent;
}
