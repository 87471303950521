import React from "react";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box } from "@material-ui/core";
import { AiloPaymentMethodFeesListItem } from "./ailoPaymentMethodFeesListItem";
import { Colors, opacify } from "../../../../../common/ui/colors";

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    maxHeight: 380,
  },
  box: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    paddingLeft: 10,
    marginLeft: 10,
    marginBottom: 20,
    backgroundColor: opacify(Colors.SMOKE, 0.1),
  },
}));
export const AiloPaymentMethodFeesList = ({ mergedFeesList, note }: any) => {
  const classes = useStyles();
  return (
    <>
      <List className={classes.root}>
        {mergedFeesList.map((item: any) => (
          <AiloPaymentMethodFeesListItem key={item.type} item={item} />
        ))}
      </List>
      {note && (
        <Box className={classes.box}>
          {" "}
          {
            <p>
              <b>Internal Note:</b> {note}
            </p>
          }{" "}
        </Box>
      )}
    </>
  );
};
