import React, { ReactElement } from "react";
import { formatPersonName } from "../../common";
import { ManagementListRecord } from "./managementsList";
import { ConsumerChip } from "./ConsumerChip";

type Owner = NonNullable<
  NonNullable<ManagementListRecord["owners"]>[number]
>["owner"];
type Tenant = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<ManagementListRecord["allTenancies"]>[number]
    >["tenants"]
  >[number]
>["tenant"];
type Consumer = Owner | Tenant;

function getConsumerName(consumer: Consumer): string | null {
  if (consumer?.__typename === "Person") return formatPersonName(consumer);
  if (consumer?.__typename === "Company") return consumer.registeredEntityName;
  return null;
}

function isConsumerInvited(consumer: Consumer): boolean {
  if (consumer?.__typename === "Person") {
    return Boolean(consumer.user?.onboardedAt);
  }
  if (consumer?.__typename === "Company") {
    return Boolean(consumer.owner.user?.onboardedAt);
  }
  return false;
}

function ConsumerChips({
  consumers,
}: {
  consumers: Consumer[];
}): ReactElement | null {
  const consumerChips = consumers.reduce((acc, consumer) => {
    if (!consumer) return acc;

    const name = getConsumerName(consumer);
    if (name === null) return acc;

    const isInvited = isConsumerInvited(consumer);
    const label = `${name}: ${isInvited ? "Invited" : "Pending"}`;

    return [
      ...acc,
      <ConsumerChip key={consumer.id} isInvited={isInvited} label={label} />,
    ];
  }, [] as ReactElement[]);

  return <>{consumerChips}</>;
}

export { ConsumerChips };
