// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient } from "apollo-client";
import { MoneyInterface } from "@ailo/money";
import gql from "graphql-tag";
import { TaxTreatment } from "../../../../../api/graphql/types.generated";
import {
  CreateManagementFeeBlueprintMutation,
  CreateManagementFeeBlueprintMutationVariables,
} from "./create.generated";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function create({
  input,
  client,
}: {
  input: {
    managementId: string;
    feeBlueprintId: string;
    taxTreatment: TaxTreatment;
    fixedAmount?: MoneyInterface;
    oneWeekRentPercentage?: number;
    description?: string;
  };
  client: ApolloClient<unknown>;
}) {
  const mutation = gql`
    mutation createManagementFeeBlueprint(
      $input: CreateManagementFeeBlueprintInput!
    ) {
      blueprint: createManagementFeeBlueprint(input: $input) {
        id
        ailorn
      }
    }
  `;

  const result = await client.mutate<
    CreateManagementFeeBlueprintMutation,
    CreateManagementFeeBlueprintMutationVariables
  >({
    mutation,
    variables: {
      input,
    },
  });
  return result;
}
