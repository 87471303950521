import React from "react";
import { Switch } from "@material-ui/core";
import { ConfirmMutationDialog } from "../dialogs";

export function MutationSwitch(props: {
  checked: any;
  mutationParams: any;
  confirmation: any;
  successNotification: any;
  failureNotification: any;
  onSuccess?: (response: any) => void;
  shouldRefresh?: boolean;
  disabled?: boolean;
}) {
  const {
    checked,
    mutationParams,
    confirmation,
    successNotification,
    failureNotification,
    onSuccess,
    shouldRefresh = true,
    disabled,
  } = props;
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Switch
        checked={checked}
        disabled={typeof disabled === "undefined" ? !checked : disabled}
        onChange={onClick}
      />
      <ConfirmMutationDialog
        open={open}
        shouldRefresh={shouldRefresh}
        onClose={onClose}
        onSuccess={onSuccess}
        mutationParams={mutationParams}
        confirmation={confirmation}
        successNotification={successNotification}
        failureNotification={failureNotification}
      />
    </>
  );
}
