import React from "react";
import { DateField } from "react-admin";
import { Grid } from "@material-ui/core";
import { EditButton } from "./EditButton";
import { EditTenancyStartDateFormDialog } from "./EditTenancyStartDateFormDialog";
import { Tenancy } from "../../../api/graphql/types.generated";

export const TenancyStartDate = ({
  tenancy,
}: {
  tenancy: Tenancy;
}): React.ReactElement => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpenDialog(false);
  }, []);
  const onEdit = React.useCallback(() => {
    setOpenDialog(true);
  }, []);

  return (
    <Grid container direction="column">
      <Grid container spacing={3} direction="row">
        <Grid item direction="column">
          <Grid item>Start date:</Grid>
          <Grid item>
            <DateField
              label="Start date"
              record={tenancy}
              source="startDate"
              emptyText="—"
            />
          </Grid>
        </Grid>
        <Grid item>
          <EditButton onClick={onEdit} />
          <EditTenancyStartDateFormDialog
            open={openDialog}
            onClose={closeDialog}
            tenancy={tenancy}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
