import gql from "graphql-tag";
import { NegativeWalletBalance } from "../../../ResourceName";

type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function walletBalancesClient({ raFetchType, params, resourceString }: Params) {
  return {
    query: gql`
      query walletBalanceReport(
        $negativeOnly: String
        $afterDate: Date
        $beforeDate: Date
      ) {
        walletBalanceReport(
          filter: {
            search: [{ key: "negativeOnly", value: $negativeOnly }]
            dateRange: { afterDate: $afterDate, beforeDate: $beforeDate }
          }
        ) {
          items {
            id
            reference
            balance {
              cents
            }
            name
          }
        }
      }
    `,
    variables: {
      negativeOnly: resourceString === NegativeWalletBalance ? "true" : "false",
      afterDate: params.filter.afterDate,
      beforeDate: params.filter.beforeDate,
    },
    parseResponse: (response: { data: { walletBalanceReport: any } }) => {
      const { walletBalanceReport } = response.data;
      const { items } = walletBalanceReport;
      items.id = walletBalanceReport;
      // filter out ailo bank wallet
      const filteredItems = items.filter(
        (item: { reference: string }) =>
          !item.reference.startsWith("ailo:ledgers:system")
      );
      return {
        data: filteredItems,
        total: null,
      };
    },
  };
}

export { walletBalancesClient };
