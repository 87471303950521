import moment from "moment-timezone";
import React from "react";
import {
  Datagrid,
  DateInput,
  Filter,
  TextField,
  SelectInput,
  ListProps,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  DateTimeField,
  List,
  PropertyField,
  ReferenceInput,
} from "../../common";

const useStyles = makeStyles(() => ({
  filterInput: {
    marginTop: 20,
    margin: 10,
  },
}));
const TrustTransactionsList = (props: ListProps): React.ReactElement => {
  const classes = useStyles();
  const afterDateDefaultFilter = moment()
    .subtract(1, "days")
    .format(moment.HTML5_FMT.DATE);
  return (
    <List
      {...props}
      perPage={50}
      title="Transactions"
      bulkActionButtons={false}
      sort={{
        field: "auditNumber",
        order: "DESC",
      }}
      filters={
        <Filter {...props}>
          <FormDataConsumer alwaysOn>
            {({ formData }) => (
              <>
                <DateInput
                  label="After"
                  source="afterDate"
                  alwaysOn
                  className={classes.filterInput}
                />
                <DateInput
                  label="Before"
                  source="beforeDate"
                  alwaysOn
                  className={classes.filterInput}
                />
                <BooleanInput
                  label="Ailo"
                  source="gtTransactions"
                  alwaysOn
                  className={classes.filterInput}
                />
                {formData.gtTransactions || (
                  <ReferenceInput
                    source="moneyHolder"
                    reference="TrustAccounts"
                    className={classes.filterInput}
                    required
                    alwaysOn
                  >
                    <SelectInput
                      optionText="ownerLegalEntity.registeredEntityName"
                      optionValue="id"
                    />
                  </ReferenceInput>
                )}
              </>
            )}
          </FormDataConsumer>
        </Filter>
      }
      filterDefaultValues={{
        afterDate: afterDateDefaultFilter,
      }}
    >
      <Datagrid>
        <TextField source="auditNumber" label="Audit Number" />
        <TextField source="type" label="Type" sortable={false} />
        <TextField
          source="referenceNumber"
          label="Reference Number"
          sortable={false}
        />
        <PropertyField
          source="management.property"
          managementIdSource="management.id"
          sortable={false}
        />
        <TextField source="description" label="Description" sortable={false} />
        <DateTimeField source="createdAt" sortable={false} />
      </Datagrid>
    </List>
  );
};

export { TrustTransactionsList };
