/* eslint-disable @typescript-eslint/no-explicit-any */
import currency from "currency.js";
import moment from "moment-timezone";
import { RentCategory } from "../graphql/types.generated";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformRentForRequest(setupObject: Record<string, any>) {
  if (!setupObject) {
    return {};
  }
  const {
    amount,
    effectiveDate,
    period,
    setsChargeDate,
    tenancyId,
  } = setupObject;
  return {
    input: {
      tenancyId,
      rents: [
        {
          effectiveDate: effectiveDate
            ? moment(effectiveDate).format("YYYY-MM-DD")
            : null,
          amountInCents: String(currency(amount).intValue),
          period,
          category: RentCategory.Support,
          setsChargeDate,
        },
      ],
    },
  };
}
