import moment from "moment-timezone";

export function groupTransactionsByMonth(
  transactions: any[],
  groupBy = "createdAt"
) {
  return transactions
    .reduce((groups, transaction) => {
      const lastGroup = groups[groups.length - 1];

      if (
        groups.length === 0 ||
        !monthAndYearMatches(lastGroup[0][groupBy], transaction[groupBy])
      ) {
        groups.push([]);
      }

      groups[groups.length - 1].push(transaction);
      return groups;
    }, [])
    .map((group: { [x: string]: string }[]) => ({
      title: formatDate(group[0][groupBy]),
      data: group,
    }));
}

function monthAndYearMatches(dateA: string, dateB: string): boolean {
  return (
    moment(dateA).isSame(dateB, "month") && moment(dateA).isSame(dateB, "year")
  );
}

function formatDate(date: string): string {
  const format = moment().isSame(date, "year") ? "MMMM" : "MMMM YYYY";
  return moment(date).format(format);
}
