/**
 * @deprecated Use helpers from `@ailo/domain-helpers` or create `CompanyInput`
 */
export function formatCompanyOptionText(company: {
  registeredEntityName: string;
  organisation: {
    id: string;
    name: string;
  };
}): string {
  return `${company.registeredEntityName} (trading as ${company.organisation.name})`;
}
