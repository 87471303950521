import React, { useCallback, useMemo, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { sortBy } from "lodash";
import { useNotify, useRefresh } from "react-admin";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SyncIcon from "@material-ui/icons/Sync";
import { Button } from "@material-ui/core";
import { DetailCard } from "../../../../../common/ui/cards";
import { Badge } from "../../../../../common/ui/Badge";
import { AddNewButton, useMutation } from "../../../../../common";
import { AddExternalTrustAccountDialog } from "./AddExternalTrustAccountDialog";
import { ExternalTrustAccount } from "../../../../../api";
import {
  DISABLE,
  ENABLE,
} from "../../../../../api/resources/ExternalTrustAccount/ExternaltrustAccountOperations";
import { ConfirmationDialog } from "../../../../Management/ConfirmationDialog";

const useStyles = makeStyles(() => ({
  root: {
    "overflow-x": "auto",
  },
}));
export const ExternalTrustAccountsCard = ({
  externalTrustAccounts,
  legalEntityId,
  organisationId,
  organisationName,
}: any) => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const action = useMemo(
    () => (
      <>
        <AddNewButton onClick={onClick}>Add</AddNewButton>
        <AddExternalTrustAccountDialog
          open={open}
          onClose={onClose}
          legalEntityId={legalEntityId}
          organisationId={organisationId}
          organisationName={organisationName}
        />
      </>
    ),
    [legalEntityId, organisationId, organisationName, open]
  );

  return (
    <DetailCard
      title="External Trust Accounts"
      subheader="Migration"
      contents={
        <ExternalTrustAccountTable
          externalTrustAccounts={externalTrustAccounts}
        />
      }
      action={action}
    />
  );
};

const ExternalTrustAccountTable = ({ externalTrustAccounts }: any) => {
  const classes = useStyles();
  const sortedExternalTrustAccounts = sortBy(externalTrustAccounts, [
    "disabled",
    "id",
  ]);
  return (
    <div className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Secret ARN</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedExternalTrustAccounts.map((externalTrustAccount) => (
            <ExternalTrustAccountRow
              key={externalTrustAccount.id}
              {...externalTrustAccount}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const ExternalTrustAccountRow = ({
  id,
  secretArn,
  disabled,
}: {
  id: number;
  secretArn: string;
  disabled: boolean;
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableDialogOpened, setDisableDialogOpened] = useState(false);
  const [enableDialogOpened, setEnableDialogOpened] = useState(false);

  const [
    disableExternalTrustAccount,
    { loading: disableExternalTrustAccountLoading },
  ] = useMutation(
    {
      type: DISABLE,
      resource: ExternalTrustAccount,
    },
    {
      successMessage: "External trust account disabled",
      onSuccess: () => {
        refresh();
      },
      onFailure: (error) =>
        notify(
          `Unable to disable external trust account: ${error.message}`,
          "error"
        ),
    }
  );

  const [
    enableExternalTrustAccount,
    { loading: enableExternalTrustAccountLoading },
  ] = useMutation(
    {
      type: ENABLE,
      resource: ExternalTrustAccount,
    },
    {
      successMessage: "External trust account enabled",
      onSuccess: () => {
        refresh();
      },
      onFailure: (error) =>
        notify(
          `Unable to enable external trust account: ${error.message}`,
          "error"
        ),
    }
  );

  const openDisableConfirmationDialog = useCallback(() => {
    setDisableDialogOpened(true);
  }, []);
  const cancelDisable = useCallback(() => {
    setDisableDialogOpened(false);
  }, []);
  const confirmDisable = useCallback(async () => {
    setDisableDialogOpened(false);
    await disableExternalTrustAccount({
      payload: {
        details: {
          externalTrustAccountId: id,
        },
      },
    });
  }, [disableExternalTrustAccount, id]);

  const openEnableConfirmationDialog = useCallback(() => {
    setEnableDialogOpened(true);
  }, []);
  const cancelEnable = useCallback(() => {
    setEnableDialogOpened(false);
  }, []);
  const confirmEnable = useCallback(async () => {
    setEnableDialogOpened(false);
    await enableExternalTrustAccount({
      payload: {
        details: {
          externalTrustAccountId: id,
        },
      },
    });
  }, [enableExternalTrustAccount, id]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell style={{ maxWidth: 250, overflowWrap: "break-word" }}>
        {secretArn}
      </TableCell>
      <TableCell align="right">
        {disabled ? (
          <Badge type="critical" text="Disabled" />
        ) : (
          <Badge type="success" text="Active" />
        )}
      </TableCell>
      <TableCell>
        {disabled ? (
          <>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={openEnableConfirmationDialog}
              disabled={enableExternalTrustAccountLoading}
              startIcon={<SyncIcon />}
            >
              Enable
            </Button>
            <ConfirmationDialog
              open={enableDialogOpened}
              onClose={cancelEnable}
              onDisagree={cancelEnable}
              onAgree={confirmEnable}
              title="Are you sure?"
              description={`External trust account ${id} will be enabled.`}
            />
          </>
        ) : (
          <>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={openDisableConfirmationDialog}
              disabled={disableExternalTrustAccountLoading}
              startIcon={<DeleteIcon />}
            >
              Disable
            </Button>
            <ConfirmationDialog
              open={disableDialogOpened}
              onClose={cancelDisable}
              onDisagree={cancelDisable}
              onAgree={confirmDisable}
              title="Are you sure?"
              description={`External trust account ${id} will be disabled.`}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
