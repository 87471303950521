import React from "react";
import {
  BooleanInput,
  CreateProps,
  DateTimeInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { v4 as uuid } from "uuid";
import { Liability } from "../../api";
import { Create } from "../../common";

const redirect = (
  _basePath: unknown,
  _id: unknown,
  data: Record<string, unknown>
) => {
  return `/${Liability}/${data?.liabilityId}/show`;
};

const LiabilityAdjustmentCreate = (props: CreateProps) => {
  const idempotencyKey = uuid();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          label="Liability"
          source="liabilityId"
          validate={[required()]}
        />
        <TextInput
          disabled
          label="Idempotency Key"
          source="idempotencyKey"
          initialValue={idempotencyKey}
          helperText="System generated key to prevent duplicate transaction"
          validate={[required()]}
        />
        <SelectInput
          label="Adjustment Type"
          validate={[required()]}
          source="adjustmentType"
          choices={[
            {
              id: "CREDIT",
              name: "Credit (entry with negative amount)",
            },
            {
              id: "DEBIT",
              name: "Debit (entry with positive amount)",
            },
          ]}
        />
        <NumberInput
          label="Amount ($)"
          source="amount"
          validate={[required()]}
        />
        <DateTimeInput
          source="effectiveAt"
          validate={[required()]}
          helperText="Note: Selecting date in past may affect the history and already generated statements."
        />
        <TextInput
          source="description"
          validate={[required()]}
          helperText="The description may be visible to end users in the future."
        />
        <BooleanInput
          source="descriptionIsCustom"
          label="Show description to users"
        />
      </SimpleForm>
    </Create>
  );
};

export { LiabilityAdjustmentCreate };
