import React from "react";
import { useMutation, useNotify, useRefresh } from "react-admin";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import type { Supplier } from "../../api/deprecatedTypes";

const ArchiveSupplierButton = ({ onClick, disabled }: any) => (
  <Button
    variant="outlined"
    color="primary"
    onClick={onClick}
    disabled={disabled}
  >
    Archive Supplier
  </Button>
);

const ArchiveSupplierConfirmationDialog = ({
  open,
  onClose,
  supplier,
}: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [archiveSupplier] = useMutation(
    {
      type: "archive_supplier",
      resource: "Suppliers",
      payload: {
        id: supplier.id,
        data: {
          isActive: true,
        },
      },
    },
    {
      onSuccess: (data) => {
        console.log("success:", data);
        onClose();
        refresh();
        notify(`Supplier ${supplier.id} has been archived`);
      },
      onFailure: (error) => {
        onClose();
        notify(
          `Failed to archive supplier ${supplier.id}. Error: ${error}`,
          "error"
        );
      },
    }
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Archive Supplier</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Archiving a supplier will prevent agencies from creating <b>new</b>{" "}
          bills with the supplier.
        </DialogContentText>
        <DialogContentText>
          Existing bills will not be affected.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={archiveSupplier} color="primary" autoFocus>
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ArchiveSupplierField = ({
  supplier,
  disabled,
}: {
  supplier: Supplier;
  disabled: boolean;
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpenDialog(false);
  }, []);
  const onEdit = React.useCallback(() => {
    setOpenDialog(true);
  }, []);
  return (
    <div>
      <ArchiveSupplierButton onClick={onEdit} disabled={disabled} />
      <ArchiveSupplierConfirmationDialog
        open={openDialog}
        onClose={closeDialog}
        supplier={supplier}
      />
    </div>
  );
};
