import React from "react";
import { PersonDetailCard } from "./PersonDetailCard";
import { CompanyDetailCard } from "./CompanyDetailCard";

export const LegalEntityDetailCard = ({
  legalEntity,
  resource,
  status,
  additionalInfo,
  preferOrganisationName,
}: any) => {
  return legalEntity.__typename === "Person" ? (
    <PersonDetailCard
      person={legalEntity}
      resource={resource}
      status={status}
      additionalInfo={additionalInfo}
    />
  ) : legalEntity.__typename === "Company" ? (
    <CompanyDetailCard
      company={legalEntity}
      resource={resource}
      status={status}
      additionalInfo={additionalInfo}
      preferOrganisationName={preferOrganisationName}
    />
  ) : null;
};
