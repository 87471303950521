import React from "react";
import { Box, Link } from "@material-ui/core";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import { Button } from "react-admin";
import { ListItem } from "../../../../common";
import { CopiableId } from "./CopiableId";
import { StatementCreateStatus } from "./StatementCreateStatus";
import { StatementProgress } from "./StatementProgressesList";
import { StatementType } from "../../../../api/graphql/types.generated";

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function statementTypeToDisplayName(statementType?: StatementType) {
  switch (statementType) {
    case StatementType.Management:
      return "Ownership Statement";
    case StatementType.TaxSummary:
      return "Tax Summary";
    case StatementType.TenancyLedger:
      return "Tenancy Ledger";
    case StatementType.TransferSummary:
      return "Transfer Summary";
    default:
      return "Statement";
  }
}

export function StatementProgressListItem({
  statementProgress: {
    id,
    statementId,
    fileId,
    fileUrl,
    billsZipFileUrl,
    startDate,
    endDate,
    /** when isSuccessful == null, it's actually unknown status, not fail */
    isSuccessful,
    errorMessage,
    createdAt,
    dryRunEmail,
    type,
  },
  onRetryGeneratingStatement,
}: {
  statementProgress: StatementProgress;
  onRetryGeneratingStatement?: (progressId: string) => void;
}): React.ReactElement {
  return (
    <ListItem
      key={id}
      style={{ marginTop: 16 }}
      title={
        <Box display="flex" fontSize={14}>
          {`${startDate} to ${endDate} ${statementTypeToDisplayName(type)}`}
          <Box pl={2} ml="auto">
            <StatementCreateStatus
              isSuccessful={isSuccessful}
              errorMessage={errorMessage}
            />
          </Box>
        </Box>
      }
    >
      <Box style={{ paddingBottom: 16 }}>
        <CopiableId label={`Progress ID: ${id}`} copyValue={id} />
        {statementId && (
          <CopiableId
            label={`Statement ID: ${statementId}`}
            copyValue={statementId}
          />
        )}
        {fileId && (
          <CopiableId label={`File ID: ${fileId}`} copyValue={fileId} />
        )}

        <Box mt={1} fontSize={14} color="rgba(0, 0, 0, 0.87)">
          Time: {createdAt.format("YYYY-MM-DD hh:mm a")} ({localTimezone})
        </Box>

        <Box mt={1} fontSize={14} color="rgba(0, 0, 0, 0.87)">
          Sent to: {dryRunEmail ?? "Investors"}
        </Box>

        {isSuccessful && (
          <>
            <Box mt={1} fontSize={12} color="rgba(0, 0, 0, 0.87)">
              {fileUrl && (
                <Link
                  href={fileUrl}
                  target="_blank"
                  style={{ paddingLeft: 0, paddingRight: 20 }}
                >
                  <Button label="Download" style={{ paddingLeft: 0 }}>
                    <ArchiveOutlinedIcon />
                  </Button>
                </Link>
              )}
              {billsZipFileUrl && (
                <Link href={billsZipFileUrl} target="_blank">
                  <Button label="Bills in Zip">
                    <ReceiptOutlinedIcon />
                  </Button>
                </Link>
              )}
            </Box>
          </>
        )}

        {isSuccessful === false && (
          <>
            <Box mt={1} fontSize={14}>
              Error type:
            </Box>
            <Box mt={1} fontSize={14} style={{ wordBreak: "break-all" }}>
              {errorMessage ?? "Unknown error"}
            </Box>
            <Button
              label="Retry"
              variant="outlined"
              style={{ paddingLeft: 13, paddingRight: 20, marginTop: 12 }}
              onClick={() => onRetryGeneratingStatement?.(id)}
            />
          </>
        )}
      </Box>
    </ListItem>
  );
}
