// TODO Use new List
// eslint-disable-next-line no-restricted-imports
import { BooleanInput, DateInput, Filter, List } from "react-admin";
import React from "react";
import moment from "moment-timezone";
import { DiscrepanciesGrid } from "./discrepanciesGrid";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <DateInput label="After" source="afterDate" alwaysOn />
    <DateInput label="Before" source="beforeDate" alwaysOn />
    <BooleanInput label="Show all transactions" source="showAll" alwaysOn />
  </Filter>
);

const DiscrepanciesList = (props: any) => {
  const afterDateDefaultFilter = moment()
    .subtract(1, "days")
    .format(moment.HTML5_FMT.DATE);

  const beforeDateDefaultFilter = moment().format(moment.HTML5_FMT.DATE);

  return (
    <List
      {...props}
      title="Discrepancies"
      filters={<SearchFilter />}
      filterDefaultValues={{
        afterDate: afterDateDefaultFilter,
        beforeDate: beforeDateDefaultFilter,
        showAll: false,
      }}
      bulkActionButtons={false}
      pagination={false}
    >
      <DiscrepanciesGrid {...props} />
    </List>
  );
};

export { DiscrepanciesList };
