import { Money, MoneyInterface } from "@ailo/money";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import React from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  FunctionFieldProps,
  Show,
  ShowProps,
  TextField,
  TopToolbar,
  useShowController,
} from "react-admin";
import { AiloRN, services } from "@ailo/ailorn";
import { OperationData } from "../../api";
import { Bill, Liability } from "../../api/ResourceName";
import { LinkField, PersonLinkField, StatusField } from "../../common";
import {
  CopyableTextField,
  DateTimeField,
  LegalEntityField,
  ManagementField,
  MoneyField,
  PropertyField,
} from "../../common/fields";
import { LegalEntityLink } from "../../common/fields/deprecated/LegalEntityLink";
import { Badge } from "../../common/ui/Badge";
import { BillAgencyStatusBadge } from "../../features/Bill/BillAgencyStatusBadge";
import { BillPayeeField } from "../../features/Bill/BillPayeeField";
import { LiabilityEntriesGrid } from "../../features/Ledger/LiabilityEntriesGrid";
import { CancelBillButton } from "./CancelBillButton";
import { BusinessTransaction } from "../../api/graphql/types.generated";

type BillRecord = OperationData<typeof Bill, "GET_ONE">;

export const BillShow = (props: ShowProps): React.ReactElement => {
  const { record } = useShowController<BillRecord>(props);
  const paymentMethodType =
    record?.paymentReference?.supplierPaymentMethodCompanion?.paymentMethod
      ?.__typename;
  const internal = !!record?.internal;
  return (
    <Show actions={<Actions />} {...props} title="Bill">
      <BoxedShowLayout>
        <RaBox display="flex">
          <RaBox marginRight="50px">
            <CopyableTextField label="ID" source="id" />
            <MoneyField source="amount" />
            <TextField label="Invoice No." source="invoiceNumber" />
            <FunctionField<BillRecord>
              label="Agency Status"
              render={(billRecord) => (
                <BillAgencyStatusBadge
                  agencyStatus={billRecord!.agencyStatus}
                />
              )}
            />
            <TextField label="Status" source="status" />
            <TextField
              label="Payment Status"
              source="liabilityState.paymentStatus"
            />
            <TextField
              label="Reason for cancelling"
              source="organisationArchiveReason"
            />
            <DateField label="Issue Date" source="issueDate" />
            <DateField label="Due Date" source="dueDate" />
            <DateField label="Payment Date" source="liabilityState.paidAt" />
            <DateTimeField label="Created in Ailo" source="createdAt" />
            <FunctionField<BillRecord>
              label="Created By"
              render={(billRecord) => {
                return billRecord!.createdByUser ? (
                  <PersonLinkField record={billRecord!.createdByUser.person} />
                ) : (
                  billRecord!.createdBy
                );
              }}
            />
            <TextField label="Category" source="taxCategory.name" />
            <TextField label="Description" source="description" />
            <LineItems label="Line Items" addLabel />
            <Attachments label="Attachments" addLabel />
            <BooleanField
              source="taxAutoCalculated"
              label={
                'Tax automatically calculated ("GST included" checkbox was selected)'
              }
            />
            <BooleanField
              source="applyManagementFee"
              label="Charge management fee to investor once bill is paid"
            />
          </RaBox>
          <RaBox>
            <PropertyField
              source="management.property"
              managementIdSource="management.id"
            />
            <ManagementField label="Management" source="management" />
            <LegalEntityField
              label="Managing Entity"
              source="management.managingEntity"
            />
            <DateField
              label="Management started"
              source="management.firstPublishedAt"
            />
            <FunctionField<BillRecord>
              label="Payer(s)"
              render={(billRecord) => {
                const payerType = billRecord!.payer?.__typename;
                const legalEntities =
                  billRecord!.payer?.__typename === "Tenancy"
                    ? billRecord!.payer?.tenants?.map(
                        (tenantship) => tenantship?.tenant
                      )
                    : billRecord!.payer?.__typename === "Management"
                    ? billRecord!.payer?.owners?.map(
                        (ownership) => ownership?.owner
                      )
                    : undefined;
                return (
                  <>
                    <LinkField record={record} source="payer" />
                    {legalEntities?.map((legalEntity) => (
                      <div
                        key={legalEntity?.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingLeft: 8,
                          paddingTop: 8,
                        }}
                      >
                        <LegalEntityLink legalEntity={legalEntity} />
                        <Badge
                          style={{
                            marginLeft: "4px",
                          }}
                          type={payerType === "Tenancy" ? "tenant" : "investor"}
                          text={payerType === "Tenancy" ? "Renter" : "Investor"}
                        />
                      </div>
                    ))}
                  </>
                );
              }}
            />
            <BillPayeeField label="Payee" sortable={false} source="payee" />

            {!internal ? (
              <TextField
                label="Payment Type"
                source="paymentReference.supplierPaymentMethodCompanion.paymentMethod.__typename"
              />
            ) : (
              <FunctionField<BillRecord>
                label="Payment Type"
                render={(bill) =>
                  bill?.payee?.__typename === "Supplier" && (
                    <div>Internal Bill</div>
                  )
                }
              />
            )}

            {internal ? (
              <FunctionField<BillRecord>
                label="Payment destination"
                render={(bill) =>
                  bill?.payee?.__typename === "Supplier" && (
                    <div>Supplier's Primary Ailo Wallet</div>
                  )
                }
              />
            ) : (
              <></>
            )}
            {!internal && paymentMethodType === "BPay" ? (
              <TextField
                label="Biller Code"
                source="paymentReference.supplierPaymentMethodCompanion.paymentMethod.billerCode"
              />
            ) : (
              <></>
            )}
            {paymentMethodType === "BPay" ? (
              <TextField
                label="Customer Reference"
                source="paymentReference.crn"
              />
            ) : (
              <></>
            )}

            {!internal && paymentMethodType === "BankAccount" ? (
              <TextField
                label="BSB"
                source="paymentReference.supplierPaymentMethodCompanion.paymentMethod.bsb"
              />
            ) : (
              <></>
            )}
            {!internal && paymentMethodType === "BankAccount" ? (
              <TextField
                label="Account Number"
                source="paymentReference.supplierPaymentMethodCompanion.paymentMethod.accountNumber"
              />
            ) : (
              <></>
            )}

            <LinkField
              label="Liability"
              source="liability"
              sourceResource={Liability}
            />
            <ArrayField
              source="liability.linkedChildBusinessTransactions"
              label="Linked Business Transactions (HOP2)"
            >
              <Datagrid>
                <FunctionField<BusinessTransaction>
                  render={(renderProps) => {
                    if (!renderProps?.id) {
                      return <></>;
                    }
                    return (
                      <LinkField
                        label="transaction"
                        record={{
                          __typename: "BusinessTransaction",
                          // TODO pass ID instead, once transaction view is fixed (so it accepts ID instead of ARN as url param)
                          id: AiloRN.of(
                            services.Ledger.businessTransaction,
                            renderProps?.id
                          ).toString(),
                        }}
                        includeApiTypeInLinkText={false}
                        renderText={() => renderProps.id}
                      />
                    );
                  }}
                />
                <StatusField source="status" />
                <DateField label="Created At" source="createdAt" showTime />
              </Datagrid>
            </ArrayField>
          </RaBox>
        </RaBox>

        <FunctionField<BillRecord>
          label="Liability Entries"
          render={(billRecord) => {
            if (!billRecord?.liability) {
              return null;
            }
            return (
              <LiabilityEntriesGrid liabilityId={billRecord.liability.id} />
            );
          }}
        />
      </BoxedShowLayout>
    </Show>
  );
};

function LineItems({
  data: record,
}: { data?: BillRecord } & Omit<FunctionFieldProps, "render">) {
  return (
    <FunctionField<BillRecord>
      record={record}
      render={(data) => {
        const includedTaxAmount = (data?.lineItems?.items ?? [])
          .filter((item: { isTax: boolean }) => !item.isTax)
          .map((item: { taxAmount: Money | MoneyInterface }) =>
            Money.from(item.taxAmount)
          )
          .reduce(
            (a: { add: (arg0: Money) => Money }, b: Money) => a.add(b),
            Money.zero()
          );
        return (
          <>
            {data!.lineItems?.items.map((lineItem) => {
              return (
                <div key={lineItem.ailoRN}>
                  <TextField
                    label="Description"
                    record={lineItem}
                    source="description"
                    addLabel
                  />
                  {": "}
                  <MoneyField record={lineItem} source="taxInclusiveAmount" />
                </div>
              );
            })}
            {!!includedTaxAmount?.cents && (
              <div>(GST Included: {includedTaxAmount.format()})</div>
            )}
          </>
        );
      }}
    />
  );
}

function Attachments({
  data: record,
}: { data?: BillRecord } & Omit<FunctionFieldProps, "render">) {
  return (
    <FunctionField<BillRecord>
      record={record}
      render={(data) =>
        data ? (
          <>
            {data.attachments?.map((attachment) => {
              return (
                <div key={attachment.id}>
                  {attachment.file?.url && attachment.file?.fileName && (
                    <a href={attachment.file?.url}>
                      {attachment.file?.fileName}
                    </a>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )
      }
    />
  );
}

function Actions({ data: record }: { data?: BillRecord }) {
  if (!record) return null;
  return (
    <TopToolbar>
      <CancelBillButton
        billId={record?.id}
        disabled={!record?.archivableByOrganisation}
      />
    </TopToolbar>
  );
}
