import { formatPaymentMethodName, PaymentMethod } from "@ailo/domain-helpers";
import Big from "big.js";
import React from "react";
import { truncateToDecimalPlaces } from "../../common";

export function PaymentMethodDestinations({
  paymentMethodDestinations,
}: {
  paymentMethodDestinations: {
    share: number;
    paymentMethod: PaymentMethod;
  }[];
}): React.ReactElement {
  const totalShares = paymentMethodDestinations?.reduce(
    (sum, pd) => sum + pd.share,
    0
  );
  return (
    <div>
      {paymentMethodDestinations?.map((paymentDestination, key) => (
        <PaymentDestination
          paymentDestination={paymentDestination}
          totalShares={totalShares}
          key={key}
        />
      )) || null}
    </div>
  );
}

function PaymentDestination({
  paymentDestination,
  totalShares,
}: {
  paymentDestination: {
    share: number;
    paymentMethod: PaymentMethod;
  };
  totalShares: number;
}): React.ReactElement {
  return (
    <div style={{ marginTop: 8 }}>
      <div style={{ fontWeight: "bold" }}>
        {formatPaymentMethodName(
          {
            ...paymentDestination.paymentMethod,
            __typename: "BankAccount",
          },
          { type: "consumer-name" }
        )}{" "}
        {truncateToDecimalPlaces(2)(
          Big(paymentDestination.share).div(totalShares).mul(100)
        )}
        %
      </div>
      <div style={{ fontSize: 14 }}>
        {formatPaymentMethodName({
          ...paymentDestination.paymentMethod,
          __typename: "BankAccount",
        })
          .replace("Bank Account", "")
          .replace("Account number", "Acc")}
      </div>
    </div>
  );
}
