import React from "react";
import { Create } from "../../common";
import { SupplierForm } from "./SupplierForm";

const SupplierCreate = (props: Record<string, any>): React.ReactElement => {
  return (
    <Create redirect="list" {...props}>
      <SupplierForm redirect="show" />
    </Create>
  );
};

export { SupplierCreate };
