import { LocalDate, RecurringDate } from "@ailo/date";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import {
  DateInput,
  Error,
  FormDataConsumer,
  Loading,
  maxLength,
  required,
  TextInput,
  useGetOne,
  useInput,
} from "react-admin";
import { isPresent } from "ts-is-present";
import { FeeBlueprintType } from "../../api/graphql/types.generated";
import { FeeBlueprint } from "../../api/ResourceName";
import {
  FeeBlueprintInput,
  minDate,
  MoneyInput,
  OrganisationInput,
  SimpleForm,
} from "../../common";

function PrefillWithBlueprint({ blueprintId }: any) {
  const { data: blueprint, loading, error } = useGetOne(
    FeeBlueprint,
    blueprintId
  );
  const {
    input: { onChange: changeFrequency },
  } = useInput({
    source: "frequency",
  });
  const {
    input: { onChange: changeTaxInclusiveAmount },
  } = useInput({
    source: "taxInclusiveAmount",
  });
  const {
    input: { onChange: changeStartDate },
  } = useInput({
    source: "startDate",
  });
  useEffect(() => {
    if (!blueprint?.frequency) {
      return;
    }

    changeFrequency(blueprint.frequency); // We should rely on changeFrequency,
    // but it isn't memoized (while it should be)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprint?.frequency]);
  useEffect(() => {
    if (!blueprint?.fixedAmount) {
      return;
    }

    changeTaxInclusiveAmount(blueprint.fixedAmount); // We should rely on changeTaxInclusiveAmount,
    // but it isn't memoized (while it should be)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprint?.fixedAmount]);
  useEffect(() => {
    if (!blueprint?.frequency) {
      return;
    }

    const recurringDate = new RecurringDate({
      frequency: blueprint.frequency,
      anniversaryDay: blueprint.anniversaryDay,
      anniversaryMonth: blueprint.anniversaryMonth,
    });
    changeStartDate(
      recurringDate.getNextOccurrence(LocalDate.yesterday()).toString()
    ); // We should rely on changeStartDate
    // but it isn't memoized (while it should be)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blueprint?.anniversaryDay,
    blueprint?.anniversaryMonth,
    blueprint?.frequency,
  ]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return null;
}

export function RecurringFeeForm(props: any) {
  const create = !props.record.id;
  const { organisationId } = props.record;
  return (
    <SimpleForm
      toolbarOptions={{
        saveLabel: create ? "Apply New Fee" : "Save",
        deleteButton: false,
      }}
      {...props}
    >
      {create && (
        <FormDataConsumer>
          {({ formData: { blueprintId } }) =>
            blueprintId && <PrefillWithBlueprint blueprintId={blueprintId} />
          }
        </FormDataConsumer>
      )}

      <OrganisationInput
        source="organisationId"
        disabled
        validate={[required()]}
      />
      <TextInput
        source="managementAgreementId"
        disabled
        validate={[required()]}
      />
      <FeeBlueprintInput
        source="blueprintId"
        disabled={!create}
        filter={
          create
            ? {
                organisationId,
                type: FeeBlueprintType.RecurringFee,
                status: "active",
              }
            : undefined
        }
        validate={[required()]}
      />

      <TextInput disabled source="frequency" />

      <DateInput
        source="startDate"
        validate={[
          required(),
          !create ? minDate(LocalDate.tomorrow()) : undefined,
        ].filter(isPresent)}
      />

      <MoneyInput
        source="taxInclusiveAmount"
        label="Price (incl. tax) ($)"
        validate={[required()]}
      />

      <TextInput
        label="Description for the payer (optional)"
        source="description"
        validate={[maxLength(60)]}
        fullWidth
      />

      {!create && (
        <Alert severity="info" {...({ fullWidth: true } as any)}>
          This will replace all future fee occurrences. Past occurrences will
          stay as they are.
        </Alert>
      )}
    </SimpleForm>
  );
}
