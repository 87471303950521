import React, { ReactElement } from "react";
import { useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { Wallet } from "../../../api";
import { VirtualAccountCardItem, VirtualAccountData } from "../../../common";
import { SendCentrepayOnboardingNotificationDialogControl } from "./SendCentrepayOnboardingNotificationDialogControl";
import { CentrepayFeeSwitchContainer } from "./CentrepayFeeSwitchContainer";

interface Params {
  data: VirtualAccountData;
  agency: AiloRN;
}

function CentrepayWalletCardItem({
  data,
  agency,
}: Params): ReactElement | null {
  const walletOwnerRef =
    data.paymentMethodCompanion?.paymentMethod.wallet.owner.reference;

  const {
    data: wallet,
  }: {
    data?: {
      id: string;
      availableBalance: { cents: number };
      outstandingBalance: { cents: number };
      idempotencyKey: string;
    };
  } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: walletOwnerRef,
    },
  });

  if (!data || !walletOwnerRef || !wallet) {
    return null;
  }
  const escrowAccount = AiloRN.from(walletOwnerRef);

  return (
    <VirtualAccountCardItem
      agency={agency}
      walletOwnerRef={walletOwnerRef}
      wallet={wallet}
      data={data}
      title="Centrepay Wallet"
      transferToTrustDescriptionGuide="State the reason for the transfer to trust"
      additionalActions={
        <>
          <SendCentrepayOnboardingNotificationDialogControl
            agency={AiloRN.from(agency)}
          />
        </>
      }
      footerActions={
        <CentrepayFeeSwitchContainer escrowAccount={escrowAccount} />
      }
    />
  );
}

export { CentrepayWalletCardItem };
