import { CREATE } from "ra-core";
import React, { useState } from "react";
import {
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  PasswordInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { ExternalTrustAccount } from "../../../../../api";
import {
  ErrorDialog,
  SimpleFormDialog,
  useMutation,
} from "../../../../../common";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RARecord = Record<string, any>;

function useAddExternalTrustAccount() {
  return useMutation(
    {
      type: CREATE,
      resource: ExternalTrustAccount,
    },
    {
      successMessage: "External trust account has been added",
    }
  );
}

export function AddExternalTrustAccountDialog(props: {
  open: boolean;
  organisationId: string;
  legalEntityId: string;
  organisationName: string;
  onClose: () => void;
}): React.ReactElement {
  const {
    open,
    organisationId,
    legalEntityId,
    organisationName,
    onClose,
  } = props;
  const [addExternalTrustAccount, { error }] = useAddExternalTrustAccount();
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Adding external trust account failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title="Add External Trust Account"
      submitLabel="Add"
      save={(formData: RARecord) =>
        addExternalTrustAccount({
          payload: {
            ...formData,
            legalEntityId,
            organisationId,
            organisationName,
          },
        })
      }
      onClose={onClose}
    >
      <BooleanInput
        source="createSecret"
        label="Create Secret"
        defaultValue={false}
      />

      <FormDataConsumer>
        {({ formData }: RARecord) => {
          if (formData?.createSecret) {
            return (
              <>
                <SelectInput
                  source="systemType"
                  label="System type"
                  defaultValue="PropertyMe"
                  choices={["PropertyMe"].map((id) => ({
                    id,
                    name: id,
                  }))}
                  unselectable="on"
                  fullWidth
                />
                <TextInput source="username" label="Email address" fullWidth />
                <PasswordInput source="password" label="Password" fullWidth />
                <TextInput
                  source="mfa"
                  label="Multi-factor authentication (MFA)"
                  fullWidth
                />
              </>
            );
          }
          return <TextInput source="secretArn" label="Secret ARN" fullWidth />;
        }}
      </FormDataConsumer>

      <NumberInput
        source="priority"
        label="Data ingestion priority"
        helperText="Lower number means higher priority (e.g. 1 has higher priority than 2). If unsure, leave this empty."
        fullWidth
      />
    </SimpleFormDialog>
  );
}
