import { formatPersonOptionText } from "./formatPersonOptionText";
import { formatCompanyOptionText } from "./formatCompanyOptionText";

type PersonLegalEntity = {
  __typename: "Person";
  firstName: string;
  lastName: string | null | undefined;
  phoneNo: string | null | undefined;
};
type CompanyLegalEntity = {
  __typename: "Company";
  registeredEntityName: string;
  organisation: {
    id: string;
    name: string;
  };
};
type LegalEntity = PersonLegalEntity | CompanyLegalEntity;

/**
 * @deprecated Use helpers from `@ailo/domain-helpers` or use `LegalEntityInput`
 */
export function formatLegalEntityOptionText(legalEntity: LegalEntity) {
  return legalEntity.__typename === "Person"
    ? formatPersonOptionText(legalEntity)
    : formatCompanyOptionText(legalEntity);
}
