import { Link } from "react-admin";
import React from "react";
import { AiloRN } from "@ailo/ailorn/build";

type Props = {
  ailoRN?: string;
  internalId?: string;
  resource: string;
  value?: string;
  label?: string;
  children?: any;
};
/**
 * @deprecated Use LinkField
 */
export const DeprecatedLinkField = (props: Props) => {
  const id = props.internalId
    ? props.internalId
    : props.ailoRN && AiloRN.isAiloRNString(props.ailoRN)
    ? AiloRN.from(props.ailoRN)?.internalId
    : props.ailoRN;
  const text = props.value || id;
  return id ? (
    <Link to={`/${props.resource}/${id}/show`}>
      {props.children ? props.children(text) : text}
    </Link>
  ) : null;
};
