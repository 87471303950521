import { AiloRN } from "@ailo/ailorn/build";
import React, { ReactElement } from "react";
import { CREATE } from "react-admin";
import { Box } from "@material-ui/core";
import { MutationSwitch } from "../../../common";
import { CentrepayFee } from "../../../api";

const enablingProperties = {
  enabled: true,
  title: "Enable Centrepay Fees",
  content:
    "Centrepay fees will be applied to payments and generate a corresponding rent credit.",
  successNotification: "Centrepay fees have been enabled",
  failureNotification: "Centrepay fees update failed",
};
const disablingProperties = {
  enabled: false,
  title: "Disable Centrepay Fees",
  content: "Centrepay fees will not be applied to payments.",
  successNotification: "Centrepay fees have been disabled",
  failureNotification: "Centrepay fees update failed",
};

export function CentrepayFeeSwitch({
  escrowAccount,
  checked,
}: {
  escrowAccount: AiloRN;
  checked: boolean;
}): ReactElement {
  const confirmationProperties = checked
    ? disablingProperties
    : enablingProperties;

  return (
    <MutationSwitch
      checked={checked}
      mutationParams={{
        type: CREATE,
        resource: CentrepayFee,
        payload: {
          input: {
            escrowAccount: escrowAccount.toString(),
            enabled: confirmationProperties.enabled,
          },
        },
      }}
      confirmation={{
        title: confirmationProperties.title,
        content: (
          <>
            <Box>{confirmationProperties.content}</Box>
            <Box paddingTop="2rem">Are you sure you want to proceed?</Box>
          </>
        ),
      }}
      successNotification={confirmationProperties.successNotification}
      failureNotification={confirmationProperties.failureNotification}
      disabled={false}
    />
  );
}
