import gql from "graphql-tag";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { PaginationParams } from "../../graphql/types.generated";
import { GeneralLedgerPaginatedEntriesFragment } from "../GeneralLedgerEntry";
import {
  LedgerEventQuery,
  LedgerEventQueryVariables,
  ListEventEntriesQuery,
  ListEventEntriesQueryVariables,
} from "./ledgerEventClient.generated";

export const LedgerEventClientDefinition = {
  GET_ONE: (params: { id: string }) => ({
    query: gql`
      query ledgerEvent($id: ID!) {
        ledgerEvent(id: $id) {
          id
          reference
          description
          createdAt
          occurredAt
          domainEventKey
          interaction {
            reference
          }
          owing {
            ailoRN
          }
          businessTransaction {
            ailoRN
          }
          type
          reversalDepth
        }
      }
    `,
    variables: <LedgerEventQueryVariables>{
      id: params.id,
    },
    parseResponse: (response: GraphQLResponse<LedgerEventQuery>) => ({
      data: response.data.ledgerEvent!,
    }),
  }),
  LIST_EVENT_ENTRIES: (params: {
    cursor?: PaginationParams;
    ledgerEventId: string;
  }) => ({
    query: gql`
      query listEventEntries($pagination: PaginationParams!, $id: ID!) {
        ledgerEvent(id: $id) {
          id
          entries(pagination: $pagination) {
            ...GeneralLedgerPaginatedEntries
          }
        }
      }

      ${GeneralLedgerPaginatedEntriesFragment}
    `,
    variables: <ListEventEntriesQueryVariables>{
      pagination: params.cursor,
      id: params.ledgerEventId,
    },
    parseResponse: (response: GraphQLResponse<ListEventEntriesQuery>) => ({
      data: response.data.ledgerEvent!.entries!,
    }),
  }),
};

export const ledgerEventClient = createClientFromDefinition(
  LedgerEventClientDefinition
);
