import gql from "graphql-tag";
import { GET_LIST, GET_MANY } from "react-admin";
import {
  createClientFromDefinition,
  specifyDefinition,
  specifyOperation,
  GraphQLResponse,
} from "../../common";
import { GetTaxCategoriesQuery } from "./billTaxCategoryClient.generated";

const getMany = specifyOperation((params: { filter?: { name: string } }) => ({
  query: gql`
    query getTaxCategories {
      categories: taxCategories {
        id
        name
      }
    }
  `,
  variables: { filter: params?.filter },
  parseResponse(response: GraphQLResponse<GetTaxCategoriesQuery>) {
    let { categories } = response.data;

    if (params.filter?.name) {
      categories = categories.filter((c: { name: string }) =>
        params.filter?.name.includes(c.name)
      );
    }

    return {
      data: categories,
      total: categories.length,
    };
  },
}));

export const billTaxCategoryDefinition = specifyDefinition({
  [GET_MANY]: getMany,
  [GET_LIST]: getMany,
});

export const billTaxCategoryClient = createClientFromDefinition(
  billTaxCategoryDefinition
);
