import { TextInput } from "react-admin";
import React, { useState } from "react";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";
import { useGenerateTenancyLedgerStatement } from "./useGenerateTenancyLedgerStatement";
import { SimpleFormDialog } from "../../../../common/views/SimpleFormDialog";
import { TenancyInput } from "./TenancyInput";
import { DownloadStatementFileWithInterval } from "./DownloadStatementFileWithInterval";
import { getTenancyType } from "../../../../features/Tenancy";
import { Colors } from "../../../../common";

export const GenerateTenancyLedgerStatementDialog = ({
  tenancy,
  open,
  onClose,
}: {
  tenancy: TenancyInput;
  open: boolean;
  onClose: () => void;
}): React.ReactElement => {
  const [statementProgressId, setStatementProgressId] = useState("");
  const { send } = useGenerateTenancyLedgerStatement({
    onSuccess: setStatementProgressId,
  });

  const onSuccess = () => {
    setStatementProgressId("");
    onClose();
  };

  const currentDate = moment().format(moment.HTML5_FMT.DATE);
  const startDate = tenancy.startDate ? tenancy.startDate : currentDate;
  const endDate = tenancy.endDate ? tenancy.endDate : currentDate;

  const tenancyType = getTenancyType(tenancy?.startDate, tenancy?.endDate);
  const tenancyColor =
    tenancyType === "Active"
      ? Colors.STATUS.SUCCESS
      : tenancyType === "Ingoing"
      ? Colors.STATUS.INFO
      : Colors.STATUS.ERROR;

  return (
    <SimpleFormDialog
      initialValues={{
        tenancyId: tenancy.id,
        startDate,
        endDate,
      }}
      title="Generate Tenancy Ledger"
      open={open}
      save={send}
      onClose={onSuccess}
      submitLabel={statementProgressId ? "Retry" : "Confirm"}
      style={{ width: 350 }}
    >
      <Box pr={10} style={{ color: tenancyColor }}>
        {tenancyType}
      </Box>
      <TextInput source="tenancyId" disabled />
      <TextInput source="startDate" disabled />
      <TextInput source="endDate" disabled />

      {statementProgressId && (
        <>
          <Alert
            severity="info"
            style={{
              marginBottom: 16,
              width: 224,
            }}
          >
            System is generating Tenancy Ledger. It can take about{" "}
            <b>30 seconds</b> before it is ready and the browser will download
            it automatically.
          </Alert>
          <DownloadStatementFileWithInterval
            statementProgressId={statementProgressId}
            interval={1000}
            onSuccess={onSuccess}
          />
        </>
      )}
    </SimpleFormDialog>
  );
};
