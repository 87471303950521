import React, { useState, useCallback } from "react";
import { useRefresh } from "react-admin";
import { Button, DialogContentText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Money } from "@ailo/money";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useMutation } from "../../common";
import { RentFrequency } from "../../api/graphql/types.generated";

interface Props {
  record: {
    id: string;
    amountInCents: string;
    period: RentFrequency;
    effectiveDate: Date;
  };
}

export function DeleteRentButton({ record }: Props): React.ReactElement {
  const refresh = useRefresh();
  const [dialogOpened, setDialogOpened] = useState(false);

  const [deleteRent, { loading }] = useMutation(
    {
      type: "delete",
      resource: "Rents",
    },
    {
      onSuccess: () => {
        refresh();
      },
    }
  );
  const openConfirmationDialog = useCallback(() => {
    setDialogOpened(true);
  }, []);
  const cancelDeleteRent = useCallback(() => {
    setDialogOpened(false);
  }, []);
  const confirmDeleteRent = useCallback(async () => {
    setDialogOpened(false);
    await deleteRent({ payload: { rentId: record.id } });
  }, [deleteRent, record]);
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={openConfirmationDialog}
        disabled={loading}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <ConfirmationDialog
        open={dialogOpened}
        onClose={cancelDeleteRent}
        onDisagree={cancelDeleteRent}
        onAgree={confirmDeleteRent}
        title="Are you sure?"
        description={
          <>
            <DialogContentText>{`Rent for ${Money.fromCents(
              record.amountInCents
            ).format()} ${record.period} starting on ${
              record.effectiveDate
            } will be deleted. This may result in pro-rata schedules being created. Click below for examples.`}</DialogContentText>
            <a
              href="https://ailo.atlassian.net/wiki/spaces/AILO/pages/2656469106/Deleting+Rents"
              target="_blank"
              rel="noreferrer"
            >
              Rent Deletion Scenarios
            </a>
          </>
        }
      />
    </>
  );
}
