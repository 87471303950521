import { DateRange, LocalDate, LocalDateFormat } from "@ailo/date";
import { Maybe } from "../../../../../api";
import { getTenancyType } from "../../../../../features/Tenancy";

const dateFormat = "MMM YYYY" as LocalDateFormat;

interface TenancyInterface {
  id: string;
  startDate?: Maybe<string>;
  endDate?: Maybe<string>;
  voidedAt?: Maybe<string>;
}

function getTenancyDescription<T extends TenancyInterface>(
  tenancy: T | null
): string | undefined {
  if (tenancy === null) {
    return undefined;
  }
  const type = getTenancyType(
    tenancy?.startDate,
    tenancy?.endDate,
    !!tenancy?.voidedAt
  );

  switch (type) {
    case "Active":
      return "Active tenancy";
    case "Ingoing":
      return "Ingoing tenancy";
    case "Former":
      return `Former tenancy (${new DateRange({
        start: tenancy?.startDate
          ? new LocalDate(tenancy.startDate)
          : undefined,
        end: tenancy?.endDate ? new LocalDate(tenancy.endDate) : undefined,
      }).format({ dateFormat })})`;
    case "Void":
      return `Void tenancy (${
        tenancy.voidedAt
          ? LocalDate.from(tenancy.voidedAt).format(dateFormat)
          : undefined
      })`;
    default:
      return "";
  }
}

export { getTenancyDescription };
