import CircularProgress from "@material-ui/core/CircularProgress";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import React, { useCallback } from "react";
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  TextField,
  TextInput,
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import type { Address as PropertyAddress } from "../../api/deprecatedTypes";
import { LinkField, List, ReferenceInput } from "../../common";
import {
  InviteOwnersButton,
  InviteTenantsButton,
  useBulkInviteOwners,
  useBulkInviteTenants,
} from "../../features/User";
import { DraftField } from "./DraftField";
import { ManagementEndDateField } from "./ManagementEndDateField";
import { ManagementsQuery } from "../../api/resources/Management/managementsClient.generated";
import { OwnerChips } from "./OwnerChips";
import { TenantChips } from "./TenantChips";

export type ManagementListRecord = NonNullable<
  NonNullable<ManagementsQuery["managements"]>["items"][number]
>;

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      source="organisationId"
      reference="Customers"
      filterToQuery={(searchTerm: string) => ({
        searchTerm,
      })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput autoFocus label="Search" source="searchTerm" alwaysOn />
  </Filter>
);

const Address = ({ address }: { address: PropertyAddress }) => {
  return [
    address.unitStreetNumber,
    address.streetName,
    address.suburb,
    address.postcode,
    address.state,
    address.country,
  ].join(" ");
};

const ManagementsBulkActionButtons = ({ selectedIds }: Record<string, any>) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const [publishManagement, { loading }] = useMutation(
    {
      type: "publish_many",
      resource: "Managements",
      payload: {
        ids: selectedIds,
      },
    },
    {
      onSuccess: ({ data }) => {
        notify("Managements published");
        unselectAll("Managements");
        refresh();
      },
      onFailure: (error) =>
        notify(`Unable to publish managements: ${error.message}`, "error"),
    }
  );
  return (
    <>
      <BulkInviteTenantsButton managementIds={selectedIds} />
      <BulkInviteOwnersButton managementIds={selectedIds} />
      <Button onClick={publishManagement} disabled={loading} label="Publish">
        <PlayCircleOutlineIcon />
      </Button>
    </>
  );
};

function BulkInviteOwnersButton({
  managementIds,
}: {
  managementIds: Array<string>;
}) {
  const [bulkInviteOwners, { loading }] = useBulkInviteOwners();
  const onClick = useCallback(() => bulkInviteOwners(managementIds), [
    bulkInviteOwners,
    managementIds,
  ]);
  if (loading) return <CircularProgress size={25} />;
  return <InviteOwnersButton onClick={onClick} />;
}

function BulkInviteTenantsButton({
  managementIds,
}: {
  managementIds: Array<string>;
}) {
  const [bulkInviteTenants, { loading }] = useBulkInviteTenants();
  const onClick = useCallback(() => bulkInviteTenants(managementIds), [
    bulkInviteTenants,
    managementIds,
  ]);
  if (loading) return <CircularProgress size={25} />;
  return <InviteTenantsButton onClick={onClick} />;
}

const ManagementsList = (props: Record<string, any>) => {
  return (
    <List
      {...props}
      title="Managements"
      sort={{
        field: "createdAt",
        order: "DESC",
      }}
      bulkActionButtons={<ManagementsBulkActionButtons />}
      filters={<SearchFilter />}
    >
      <Datagrid rowClick="show">
        <FunctionField
          {...props}
          label="Managed By"
          render={(record: any) => {
            return (
              <LinkField
                label="AGENCY"
                record={record}
                source="managingEntity"
                renderText={() =>
                  record?.managingEntity?.organisation.name || ""
                }
                sourceResource="Company"
                to={`/Companies/${record?.managingEntity?.id}/show`}
              />
            );
          }}
        />
        <FunctionField
          label="Address"
          sortBy="address"
          render={(record: any) => {
            return record.property ? (
              // @ts-expect-error ts-migrate(2786) FIXME: 'Address' cannot be used as a JSX component.
              <Address address={record.property.address} />
            ) : (
              <span>—</span>
            );
          }}
        />
        <FunctionField
          label="Status"
          sortBy="isDraft"
          render={(record: any) => <DraftField record={record} />}
        />
        <FunctionField<ManagementListRecord>
          label="Owners"
          render={(record) => {
            return record ? <OwnerChips record={record} /> : null;
          }}
          sortable={false}
        />
        <FunctionField<ManagementListRecord>
          label="Tenants"
          render={(record) => {
            return record ? <TenantChips record={record} /> : null;
          }}
          sortable={false}
        />
        <DateField label="Created At" source="createdAt" showTime />
        <FunctionField
          label="End Date"
          sortBy="endDate"
          render={(record: any) => <ManagementEndDateField record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export { ManagementsList };
