import React from "react";
import { AutocompleteInput, required, regex, TextInput } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import {
  Create,
  formatCompanyOptionText,
  ReferenceInput,
  SimpleForm,
} from "../../common";
import { Company } from "../../api";

const redirect = (basePath: string, id: string, data: Record<string, any>) => {
  return `/${Company}/${AiloRN.fromString(data.agency).internalId}/show`;
};

const TrustAccountsCreate = (props: Record<string, any>) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          alwaysOn
          label="Legal Entity"
          source="ownerLegalEntity"
          reference="Companies"
          filterToQuery={(searchTerm: any) => ({
            searchTerm,
          })}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText={formatCompanyOptionText}
            fullWidth
            disabled
          />
        </ReferenceInput>
        <TextInput
          label="Bsb"
          source="bsb"
          validate={[
            required(),
            regex(/^\d{6}$/, "BSB number must contain 6 digits only"),
          ]}
        />
        <TextInput
          label="Account Number"
          source="accountNumber"
          validate={[
            required(),
            regex(
              /^\d{6,9}$/,
              "Account no. must contain 6 to 9 digits, please prefix 0 if provided account no. has 5 digits"
            ),
          ]}
        />
        <TextInput
          label="Account Name"
          source="accountName"
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export { TrustAccountsCreate };
