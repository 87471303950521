import { AiloRN } from "@ailo/ailorn";
import { formatPaymentMethodName } from "@ailo/domain-helpers";
import { Money } from "@ailo/money";
import React from "react";
import { OperationData } from "../../api/DataProviderDefinition";
import { CopyToClipboardButton, DescriptionList } from "../../common";
import { MutationSwitch } from "../../common/ui/buttons/MutationSwitch";
import { isTruthy } from "../../common/utils/typeHelpers/isTruthy";
import { AutoPayLiability, ManagementFolio } from "../../resourceViews";
import { StatusDisabled, StatusEnabled } from "./Status";
import { SubHeading } from "./SubHeading";
import { SwitchContainer } from "./SwitchContainer";

type AutoPayBillRecord = NonNullable<
  NonNullable<
    NonNullable<OperationData<typeof ManagementFolio, "GET_ONE">>["wallet"]
  >["autoPayLiabilityStatus"]
>;

export function AutoPayBillItem({
  edit,
  autoPayLiabilityStatus,
  legalEntityAiloRN,
}: {
  edit: boolean;
  autoPayLiabilityStatus: AutoPayBillRecord;
  legalEntityAiloRN: string;
}): React.ReactElement {
  const walletOwnerReference = AiloRN.from(autoPayLiabilityStatus.payerId);

  return (
    <DescriptionList
      descriptionStyle={{
        textAlign: "right",
      }}
      items={[
        {
          name: <SubHeading variant="subtitle2">Auto Bill Payments</SubHeading>,
          description: (
            <SwitchContainer>
              {!edit ? (
                autoPayLiabilityStatus.enabled ? (
                  StatusEnabled
                ) : (
                  StatusDisabled
                )
              ) : (
                <MutationSwitch
                  checked={autoPayLiabilityStatus.enabled}
                  mutationParams={{
                    type: "cancel_auto_pay_liability_status",
                    resource: AutoPayLiability,
                    payload: {
                      payerLegalEntityId: legalEntityAiloRN,
                      payerId: autoPayLiabilityStatus.payerId,
                    },
                  }}
                  confirmation={{
                    title: "Remove Auto Bill Payments",
                    content:
                      "Are you sure you want to remove automatic bill payments?",
                  }}
                  successNotification="Auto Bill Payments have been cancelled"
                  failureNotification="Remove auto bill payments failed"
                />
              )}
            </SwitchContainer>
          ),
        },
        {
          name: "Pay from",
          description: walletOwnerReference.isA("authz:legalentity") ? (
            <>
              Personal Wallet ({walletOwnerReference.toString()})
              <CopyToClipboardButton value={walletOwnerReference.toString()} />
            </>
          ) : walletOwnerReference.isA("propertymanagement:managementfolio") ? (
            "Management Folio Wallet"
          ) : (
            <>
              Unknown Wallet Type ({walletOwnerReference.toString()})
              <CopyToClipboardButton value={walletOwnerReference.toString()} />
            </>
          ),
        },
        autoPayLiabilityStatus.enabled &&
          !!autoPayLiabilityStatus.paymentMethod && {
            name: "Payment method",
            description: formatPaymentMethodName(
              autoPayLiabilityStatus.paymentMethod
            ),
          },
        autoPayLiabilityStatus.enabled && {
          name: "Bill limit",
          description: !autoPayLiabilityStatus.maximumPaymentAmount
            ? "-"
            : `Up to ${Money.from(
                autoPayLiabilityStatus.maximumPaymentAmount
              )}`,
        },
        autoPayLiabilityStatus.enabled && {
          name: "Bill payments",
          description:
            "Bills will be paid automatically when funds are available",
        },
      ].filter(isTruthy)}
    />
  );
}
