import { CardHeader, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  headerTitle: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  cardHeaderRoot: {
    flex: 1,
  },
}));
export function DetailCardHeader({ avatar, title, subheader, action }: any) {
  const classes = useStyles();
  return (
    <CardHeader
      classes={{
        title: classes.headerTitle,
        root: classes.cardHeaderRoot,
      }}
      title={title}
      subheader={subheader}
      avatar={avatar || null}
      action={action}
    />
  );
}
