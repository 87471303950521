import { Box, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import React from "react";
import { AiloRN } from "@ailo/ailorn";
import { PropertyQuery } from "../../api/resources/Property/propertiesClient.generated";
import { LegalEntityField } from "../../common";
import { DetailCard } from "../../common/ui/cards";
import { LegalEntityDetailCard } from "../LegalEntity";
import { PropertyChangeTeamButton } from "./PropertyChangeTeamButton";

export const PropertyAgencyCard = ({
  agency,
  teams: teamsProp,
  title,
  managementReferenceList,
}: {
  agency?: NonNullable<
    PropertyQuery["property"]
  >["managements"][number]["managingEntity"];
  teams?: { name: string }[] | null;
  title?: string;
  managementReferenceList: AiloRN[];
}): React.ReactElement => {
  const teams = teamsProp ?? [];

  if (!agency) {
    return (
      <DetailCard
        title={title}
        contents={<Alert severity="error">Could not find agency</Alert>}
      />
    );
  }

  return (
    <DetailCard
      title={title}
      contents={
        <Grid container direction="column" spacing={0}>
          <LegalEntityDetailCard
            legalEntity={agency}
            preferOrganisationName
            additionalInfo={
              <>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginRight: 6 }}
                  >
                    Agency Owner:
                  </Typography>
                  <LegalEntityField
                    record={{ owner: agency.owner }}
                    source="owner"
                    copyable
                  />
                </Box>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginRight: 6 }}
                  >
                    {teams.length > 1 ? "Teams:" : "Team:"}
                  </Typography>
                  {teams.length === 0 ? (
                    <Typography variant="body2">Unassigned</Typography>
                  ) : (
                    teams.map((team, index) => (
                      <Grid item key={index}>
                        <Typography variant="body2">{team.name}</Typography>
                      </Grid>
                    ))
                  )}
                  <PropertyChangeTeamButton
                    managingEntityOrganisationId={agency.organisationId}
                    managementReferenceList={managementReferenceList}
                  />
                </Box>
              </>
            }
          />
        </Grid>
      }
    />
  );
};
