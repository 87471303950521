import gql from "graphql-tag";
import { AiloRN } from "@ailo/ailorn/build";
import { services } from "@ailo/ailorn";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

const SET_PREFERENCE = "set_preference";
const GET_BY_USER = "get_user_preferences";

function notificationPreferencesClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_BY_USER:
      return {
        query: gql`
          query userPreferencesByUserIdAndType($userId: AiloRN!) {
            userPreferencesByUserIdAndType(userId: $userId) {
              userId
              communicationType
              channel
              enabled
            }
          }
        `,
        variables: {
          userId: AiloRN.of(services.AuthZ.user, params.userId),
        },
        parseResponse: (response: {
          data: { userPreferencesByUserIdAndType: any };
        }) => {
          const { userPreferencesByUserIdAndType } = response.data;
          return {
            data: userPreferencesByUserIdAndType,
          };
        },
      };

    case SET_PREFERENCE:
      return {
        query: gql`
          mutation createOrUpdateUserPreferenceV2(
            $userId: AiloRN!
            $communicationType: CommunicationType!
            $channel: Channel
            $enabled: Boolean!
          ) {
            createOrUpdateUserPreferenceV2(
              preference: {
                userId: $userId
                communicationType: $communicationType
                channel: $channel
                enabled: $enabled
              }
            ) {
              userId
              communicationType
              channel
              enabled
            }
          }
        `,
        variables: {
          userId: AiloRN.of(services.AuthZ.user, params.userId),
          communicationType: params.communicationType,
          channel: params.channel,
          enabled: params.enabled,
        },
        parseResponse: (response: {
          data: { createOrUpdateUserPreferenceV2: any };
        }) => ({
          data: response.data.createOrUpdateUserPreferenceV2,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { notificationPreferencesClient };
