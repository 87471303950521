import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment-timezone";
import { Colors } from "../../../common";

function TransactionDate({ date }: { date: moment.MomentInput }) {
  return (
    <Box minWidth="40px" marginRight="16px">
      <Box
        textAlign="center"
        fontWeight="fontWeightMedium"
        color={Colors.TEXT.DARK.SECONDARY}
      >
        {moment(date).format("DD")}
      </Box>
      <Box textAlign="center" color={Colors.TEXT.DARK.SECONDARY}>
        {moment(date).format("MMM")}
      </Box>
    </Box>
  );
}

export { TransactionDate };
