import React from "react";
import { BaseBadge } from "./baseBadge";
import { Colors, opacify } from "../colors";

/**
 * @deprecated Use <Badge  /> instead.
 */
export function Default({ style, children }: any) {
  return (
    <BaseBadge
      color={Colors.TEXT.DARK.PRIMARY}
      style={{ ...style, bgcolor: opacify(Colors.SPACE_BLACK, 0.075) }}
    >
      {children}
    </BaseBadge>
  );
}
