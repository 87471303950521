import React from "react";
import { useRecordContext } from "react-admin";
import { RecurringOwing } from "../../../api";
import { useInfinitelyPaginatedQuery } from "../../../common";
import { ChargeCycleList } from "./ChargeCycleList";

export function ChargeCycles(): React.ReactElement {
  const { id } = useRecordContext();
  const {
    loaded,
    error,
    data,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    type: "LIST_CHARGE_CYCLES",
    resource: RecurringOwing,
    payload: {
      recurringOwingId: id.toString(),
    },
    pageSize: 100,
  });

  if (!loaded) return <ChargeCycleList.Loading />;
  if (error) return <ChargeCycleList.Error />;
  if (!data?.length) return <ChargeCycleList.Empty />;

  return <ChargeCycleList hasMore={hasMore} loadMore={loadMore} items={data} />;
}
