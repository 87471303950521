import React from "react";
// eslint-disable-next-line no-restricted-imports
import { useMutation, useNotify, useRefresh, DateField } from "react-admin";
import { Button, Grid } from "@material-ui/core";
import { LocalDate } from "@ailo/date";
import type { Tenancy } from "../../api/deprecatedTypes";

const VoidIngoingTenancyButton = ({
  record,
}: {
  record: Tenancy;
}): React.ReactElement => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [voidTenancy, { loading }] = useMutation(
    {
      type: "void_tenancy",
      resource: "Tenancy",
      payload: {
        tenancyId: record.id,
      },
    },
    {
      onSuccess: () => {
        notify("Tenancy voided");
        refresh();
      },
      onFailure: (error) =>
        notify(`Unable to void tenancy: ${error.message}`, "error"),
    }
  );
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={voidTenancy}
      disabled={loading}
    >
      Void Ingoing Tenancy
    </Button>
  );
};

export const TenancyVoidedAtField = ({
  tenancy,
}: {
  tenancy: Tenancy;
}): React.ReactElement => {
  return (
    <Grid container spacing={3} direction="row">
      <Grid item direction="column">
        <Grid item>Voided at:</Grid>
        <Grid item>
          <DateField source="voidedAt" record={tenancy} emptyText="—" />
        </Grid>
      </Grid>
      {LocalDate.from(tenancy.startDate).isAfter(LocalDate.today()) &&
        tenancy.voidedAt == null && (
          <>
            <Grid item>
              <VoidIngoingTenancyButton record={tenancy} />
            </Grid>
          </>
        )}
    </Grid>
  );
};
