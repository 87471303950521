import gql from "graphql-tag";
import { CREATE, GET_ONE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  GetCentrepayFeeSettingQuery,
  UpsertCentrepayFeeOverrideMutation,
} from "./centrepayFeeClient.generated";

export const CentrepayFeeDefinition = {
  [GET_ONE]: (params: { escrowAccount: string }) => {
    return {
      query: gql`
        query getCentrepayFeeSetting($escrowAccount: AiloRN!) {
          getCentrepayFeeSetting(escrowAccount: $escrowAccount) {
            ailoRN
            enabled
          }
        }
      `,
      variables: {
        escrowAccount: params.escrowAccount,
      },
      parseResponse: (
        response: GraphQLResponse<GetCentrepayFeeSettingQuery>
      ): { data: boolean } => {
        return {
          data: response.data.getCentrepayFeeSetting.enabled,
        };
      },
    };
  },
  [CREATE]: (params: { escrowAccount: string; enabled: boolean }) => {
    return {
      query: gql`
        mutation upsertCentrepayFeeOverride(
          $input: CentrepayFeeOverrideInput!
        ) {
          upsertCentrepayFeeOverride(input: $input) {
            ailoRN
            enabled
          }
        }
      `,
      variables: params,
      parseResponse: (
        response: GraphQLResponse<UpsertCentrepayFeeOverrideMutation>
      ): { data: boolean } => {
        return {
          data: response.data.upsertCentrepayFeeOverride.enabled,
        };
      },
    };
  },
};

export const centrepayFeeClient = createClientFromDefinition(
  CentrepayFeeDefinition
);
