import {
  AutocompleteInput,
  DateInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import React from "react";
import type { Organisation } from "../../api/deprecatedTypes";
import { Create } from "../../common";
import { ReferenceInput } from "../../common";

const requiredValidate = [required()];

const customerRenderer = (org: Organisation) => {
  return org ? `${org.name} [${org.id}]` : "";
};

const redirect = (basePath: string, id: string, data: Record<string, any>) => {
  return `/Customers/${data.customer.id}/show`;
};

const SubscriptionCreate = (props: Record<string, any>) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          alwaysOn
          label="Customer"
          source="customer"
          reference="Customers"
          filterToQuery={(searchTerm: string) => ({
            searchTerm,
          })}
        >
          <AutocompleteInput optionText={customerRenderer} fullWidth />
        </ReferenceInput>
        <SelectInput
          label="Plans"
          validate={requiredValidate}
          source="plan"
          choices={[
            {
              id: "pilot-investor",
              name: "pilot-investor",
            },
            {
              id: "testing-investor",
              name: "testing-investor",
            },
            {
              id: "pilot-tenant",
              name: "pilot-tenant",
            },
            {
              id: "testing-tenant",
              name: "testing-tenant",
            },
            {
              id: "migrating-tenant",
              name: "migrating-tenant",
            },
            {
              id: "pilot-agency",
              name: "pilot-agency",
            },
            {
              id: "testing-agency",
              name: "testing-agency",
            },
          ]}
        />
        <DateInput source="startsOn" validate={requiredValidate} />
        <DateInput source="endsOn" />
      </SimpleForm>
    </Create>
  );
};

export { SubscriptionCreate };
