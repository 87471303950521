import { DateTimeWithTimeZone } from "@ailo/date";
import React from "react";
import {
  Colors,
  CopyableTextField,
  DATE_TIME_FORMAT,
  LinkField,
  ListItemView,
} from "../../../../common";
import { PaymentMethodBadges } from "./paymentMethodBadges";

export const BPayListItem = ({
  id,
  billerCode,
  isDefaultIn,
  isDefaultOut,
  isDeleted,
  isHidden,
  wallet,
}: any) => {
  const deletedStyle = {
    color: Colors.TEXT.DARK.SECONDARY,
  };
  return (
    <ListItemView
      header={billerCode}
      subHeader={
        <>
          <CopyableTextField
            source="id"
            record={{
              id,
            }}
          />
          Added on{" "}
          {DateTimeWithTimeZone.from(wallet.createdAt).format(DATE_TIME_FORMAT)}
          <br />
          <LinkField record={wallet} />
        </>
      }
      rightComponent={
        <PaymentMethodBadges
          isDefaultIn={isDefaultIn}
          isDefaultOut={isDefaultOut}
          isDeleted={isDeleted}
          isHidden={isHidden}
        />
      }
      style={isDeleted ? deletedStyle : null}
    />
  );
};
