import gql from "graphql-tag";
import { GET_LIST } from "react-admin";
import moment from "moment-timezone";
import { AiloRN } from "@ailo/ailorn";
import {
  buildPaginatedResponseFromPaginatedList,
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import {
  TransactionsQuery,
  TransactionsQueryVariables,
} from "./transactionsClient.generated";
import {
  ListTransactionsSortParams,
  SortDirection,
  TransactionsSortField,
} from "../../graphql/types.generated";

type Params = {
  pagination: PaginationParams;
  filter: {
    afterDate?: Date;
    beforeDate?: Date;
    moneyHolder?: string;
    gtTransactions: boolean;
  };
  sort: {
    field: string;
    order: string;
  };
};
export const TrustTransactionsClientDefinition = {
  [GET_LIST]: (params: Params) => ({
    query: gql`
      query transactions(
        $paginationParams: PaginationParams!
        $afterDate: DateTime
        $beforeDate: DateTime
        $moneyHolder: AiloRN!
        $sortParams: [ListTransactionsSortParams!]!
      ) {
        transactions(
          paginationParams: $paginationParams
          filter: {
            moneyHolder: $moneyHolder
            createdAfter: $afterDate
            createdBefore: $beforeDate
          }
          sort: $sortParams
        ) {
          pageInfo {
            total
            hasNext
          }
          items {
            id
            auditNumber
            type
            referenceNumber
            createdAt
            description
            allocations {
              amount {
                cents
              }
              description
            }
            management {
              id
              property {
                id
                address {
                  unitStreetNumber
                  streetName
                  suburb
                }
              }
            }
          }
        }
      }
    `,
    variables: <TransactionsQueryVariables>{
      paginationParams: buildPaginationVariables(params.pagination),
      afterDate: params.filter.afterDate
        ? moment(params.filter.afterDate).toISOString()
        : null,
      beforeDate: params.filter.beforeDate
        ? moment(params.filter.beforeDate).toISOString()
        : null,
      moneyHolder: buildMoneyHolder(params.filter),
      sortParams: buildSortVariables(params),
    },
    parseResponse: (response: GraphQLResponse<TransactionsQuery>) => {
      return buildPaginatedResponseFromPaginatedList(
        response.data.transactions!
      );
    },
  }),
};

function buildSortVariables(params: Params): ListTransactionsSortParams {
  return {
    field: TransactionsSortField.AuditNumber,
    direction:
      params.sort?.order === "ASC" ? SortDirection.Asc : SortDirection.Desc,
  };
}

function buildMoneyHolder(filter: {
  afterDate?: Date;
  beforeDate?: Date;
  moneyHolder?: string;
  gtTransactions: boolean;
}) {
  if (filter.gtTransactions || !filter.moneyHolder) {
    return "ailo:generalledger:accountowner:ailo";
  }
  return AiloRN.of("trust-account:trust-account", filter.moneyHolder);
}

export const trustTransactionsClient = createClientFromDefinition(
  TrustTransactionsClientDefinition
);
