import Analytics from "analytics-node";
import { envVars } from "../../envVars";

const Segment = new Analytics(envVars.segment.apiKey, envVars.segment.config);
type ResetPasswordType = {
  userId: string;
  organisationName: string;
  organisationId: string;
  userCreatedAt: string;
  plans: Array<string>;
};
const analytics = {
  resetPassword: (user: ResetPasswordType) => {
    const userId = _userAilorn(user.userId);

    Segment.identify({
      userId,
      traits: {
        platform_role: plansToRoles(user.plans || []),
        // always array — this data
        // is used in the how to email that goes two hours after the welcome email,
        // so wuld be great to automate
        // Might as well include user generic org, but it's nice to have
        created: user.userCreatedAt, // when user is created, datetime object.
        // Can be sent earlier, when user is created
      },
    });
    Segment.track({
      userId,
      event: "Welcome Email Sent",
    });
  },
};

function _userAilorn(id: string) {
  return `ailo:authz:user:${id}`;
}

function plansToRoles(plans: any[]) {
  return plans.map((plan: string) => {
    if (plan.match(/investor/)) {
      return "investor";
    }

    if (plan.match(/tenant/)) {
      return "renter";
    }

    if (plan.match(/agency/)) {
      return "agency_team";
    }

    console.warn(`Unknown plan: ${plan}`);
    return undefined;
  });
}

export { analytics };
