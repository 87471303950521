/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Select from "@material-ui/core/Select";
import React, { useCallback, useMemo, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment-timezone";
import { ManagementSwitcherItem } from "./managementSwitchItem";
import { Colors, opacify } from "../../ui/colors";
import { SelectedManagement } from "../../../resourceViews/Property/components/selectedManagement";
import { formatDate } from "../../utils/formatDate";

const styles = makeStyles(() => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 20,
    paddingLeft: 20,
    height: 40,
    backgroundColor: opacify(Colors.SMOKE, 0.1),
  },
  menuPaper: {
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: opacify(Colors.SPACE_BLACK, 0.1),
  },
}));

const ManagementSwitcher = ({
  onChange,
  value,
  managements,
  disabled,
}: {
  onChange: any;
  value: any;
  managements?: any[];
  disabled?: boolean;
}) => {
  const classes = styles();
  const sortedManangementsDropDownOptions = useMemo(
    () =>
      sortManagements(managements ?? []).map((management) => ({
        value: management.id,
        label: "",
      })),
    [managements]
  );
  const setMenuIsOpen = useState(false)[1];
  const renderMenuItem = useCallback(
    (item) => {
      const management = managements?.find(
        (management) => management.id === item.value
      );
      return management ? (
        <MenuItem
          style={{
            padding: 0,
          }}
          value={management.id}
          key={management.id}
        >
          <ManagementSwitcherItem management={management} />
        </MenuItem>
      ) : null;
    },
    [managements]
  );

  const renderSelectedValue = (value: any) => {
    const management = managements?.find(
      (management) => management.id === value
    );
    const managingEntityName = management?.managingEntity?.registeredEntityName;
    const firstManagementAgreement =
      (management?.allManagementAgreements?.length ?? 0) > 0
        ? management?.allManagementAgreements?.[0]
        : undefined;
    const startDate = firstManagementAgreement
      ? formatDate(firstManagementAgreement?.startDate)
      : "";
    return management ? (
      <SelectedManagement
        managingEntityName={managingEntityName}
        startDate={startDate}
      />
    ) : null;
  };

  const changeManagement = (event: { target: { value: any } }) => {
    onChange(event.target.value);
  };

  return (
    <Select
      value={value || ""}
      disabled={!!disabled}
      fullWidth
      className={classes.root}
      renderValue={renderSelectedValue}
      onOpen={() => setMenuIsOpen(true)}
      onClose={() => setMenuIsOpen(false)}
      onChange={changeManagement}
      MenuProps={{
        classes: {
          paper: classes.menuPaper,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
    >
      {sortedManangementsDropDownOptions.map(renderMenuItem)}
    </Select>
  );
};

function sortManagements(managements: any[]) {
  return [
    ...managements.sort(
      (
        management1: { allManagementAgreements: any[] },
        management2: { allManagementAgreements: any[] }
      ) => {
        const management1Start = management1.allManagementAgreements.reduce(
          getEarliestStartDate,
          null
        );
        const management2Start = management2.allManagementAgreements.reduce(
          getEarliestStartDate,
          null
        );
        if (!management1Start && !management2Start) return 0;
        if (!management2Start) return -1;

        if (
          management2Start &&
          moment(management1Start).isAfter(moment(management2Start))
        ) {
          return -1;
        }

        if (
          management2Start &&
          moment(management1Start).isBefore(moment(management2Start))
        ) {
          return 1;
        }

        return 0;
      }
    ),
  ];
}

function getEarliestStartDate(
  agreement1: { startDate: moment.MomentInput },
  agreement2: { startDate: moment.MomentInput }
) {
  return moment(agreement1?.startDate).isBefore(moment(agreement2?.startDate))
    ? agreement1?.startDate
    : agreement2?.startDate;
}

ManagementSwitcher.Empty = ({ placeHolder }: { placeHolder: string }) => {
  const classes = styles();

  return (
    <Select
      displayEmpty
      renderValue={() => placeHolder ?? "Management"}
      disabled
      fullWidth
      value=""
      style={{ height: 40 }}
      MenuProps={{
        classes: {
          paper: classes.menuPaper,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
    />
  );
};

export { ManagementSwitcher };
