import React, { useState, useCallback } from "react";
import {
  DateField,
  TextField,
  FunctionField,
  useMutation,
  useNotify,
  useRefresh,
  useQuery,
  Loading,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { AiloRN, services } from "@ailo/ailorn/build";
import { Money } from "@ailo/money";
import EditIcon from "@material-ui/icons/Edit";
import type { Tenancy } from "../../api/deprecatedTypes";
import { TenancyTerminationForm } from "./TenancyTerminationForm";
import { formatTerminationReason } from "../../common/utils/formatTerminationReason";
import { Wallet } from "../../api";
import { GetWalletByWalletOwnerAilornQuery } from "../../api/resources/Wallet/walletClient.generated";
import { WarningContainer } from "../../common/ui/containers/WarningContainer";

export const TenancyEndDateField = ({ tenancy }: { tenancy: Tenancy }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpenDialog(false);
  }, []);
  const onEdit = React.useCallback(() => {
    setOpenDialog(true);
  }, []);
  return (
    <Grid container direction="column">
      <Grid container spacing={3} direction="row">
        <Grid item direction="column">
          <Grid item>End date:</Grid>
          <Grid item>
            <DateField
              label="End date"
              record={tenancy}
              source="endDate"
              emptyText="—"
            />
          </Grid>
        </Grid>
        <Grid item>
          <EditButton onClick={onEdit} />
          <EditTenancyEndDateFormDialog
            open={openDialog}
            onClose={closeDialog}
            tenancy={tenancy}
          />
        </Grid>
      </Grid>
      {tenancy.endDate ? (
        <Grid
          container
          spacing={3}
          direction="row"
          style={{
            marginTop: 10,
          }}
        >
          <Grid item direction="column">
            <Grid item {...({ pt: 20 } as any)}>
              Vacating reason:
            </Grid>
            <Grid item>
              <FunctionField
                label="Vacating reason"
                record={tenancy}
                render={(record: any) =>
                  record ? formatTerminationReason(record.vacatingReason) : "—"
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {tenancy.endDate ? (
        <Grid
          container
          spacing={3}
          direction="row"
          style={{
            marginTop: 10,
          }}
        >
          <Grid item direction="column">
            <Grid item {...({ pt: 20 } as any)}>
              Vacating notes:
            </Grid>
            <Grid item>
              <TextField
                label="Vacating notes"
                record={tenancy}
                source="vacatingNotes"
                emptyText="—"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

const EditButton = ({ onClick }: any) => (
  <Button
    variant="outlined"
    color="primary"
    startIcon={<EditIcon />}
    onClick={onClick}
  >
    Edit
  </Button>
);

function getWarningText(
  data?: GetWalletByWalletOwnerAilornQuery["wallet"]
): string | undefined {
  if (data?.totalBalance && data.totalBalance.cents > 0) {
    const totalBalance = Money.fromCents(data.totalBalance.cents);
    const availableBalance = Money.fromCents(data.availableBalance.cents || 0);

    let balanceText: string;
    if (availableBalance.cents < totalBalance.cents) {
      balanceText = `The tenancy wallet has a pending balance of ${totalBalance.format()} which must be transferred or refunded once cleared.`;
    } else {
      balanceText = `The tenancy wallet has a balance of ${availableBalance.format()} which must be transferred or refunded.`;
    }

    return `${balanceText} Please contact the agency for further instructions.`;
  }

  return undefined;
}

const EditTenancyEndDateFormDialog = ({ open, onClose, tenancy }: any) => {
  const {
    data,
    loading,
  }: {
    data?: GetWalletByWalletOwnerAilornQuery["wallet"];
    loading: boolean;
  } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: AiloRN.of(
        services.PropertyManagement.tenancy,
        tenancy.id
      ),
    },
  });
  const [error, setError] = useState("");
  const clearError = useCallback(() => {
    setError("");
  }, []);
  const notify = useNotify();
  const refresh = useRefresh();
  const [updateEndOfTenancy] = useMutation(
    {
      type: "update_end_of_tenancy",
      resource: "Managements",
      payload: {},
    },
    {
      onSuccess: () => {
        onClose();
        notify(`Tenancy ${tenancy.id} end date has been updated`);
        refresh();
      },
      onFailure: (error) => {
        const errorMessage = `Fail to update tenancy ${tenancy.id} end date: ${error}`;
        setError(errorMessage);
      },
    }
  );
  const save = React.useCallback(
    (updatedTenancy) => {
      const { id, endDate, vacatingReason, vacatingNotes } = updatedTenancy;
      updateEndOfTenancy({
        payload: {
          tenancyId: id,
          endDate,
          reason: vacatingReason,
          notes: vacatingNotes,
        },
      });
    },
    [updateEndOfTenancy]
  );
  if (loading) {
    return <Loading />;
  }

  const warningText = getWarningText(data);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit tenancy end date</DialogTitle>
      {!!warningText && (
        <WarningContainer content={warningText} style={{ margin: "0 42px" }} />
      )}

      <DialogContent>
        <TenancyTerminationForm
          entity={tenancy}
          entityName="Tenancy"
          save={save}
          terminationReasons={[
            "",
            "WithinTermsOfAgreement",
            "LeaseBreak",
            "Termination",
            "OffBoarded",
            "LostManagement",
          ]}
          error={error}
          onFormChange={clearError}
          resetEnabled
          notesWarning="Notes are visible to the agency in PM app"
          reasonSource="vacatingReason"
          notesSource="vacatingNotes"
          allowPushingEndDateBack={false}
        />
      </DialogContent>
    </Dialog>
  );
};
