import React from "react";
import { Box } from "@material-ui/core";
import { TenantsCentrepayInfoProps } from "../TenantsCentrepayInfoProps";
import { useAddCentrepayDirective } from "../mutations/useAddCentrepayDirective";
import { useAddCentrepayPaymentDirectiveDialog } from "./dialogs/useAddCentrepayPaymentDirectiveDialog";
import { EditIcon } from "../../../common";

export function MissingTenancyDirective({
  tenancy,
  legalEntity,
  managingEntity,
}: TenantsCentrepayInfoProps): React.ReactElement {
  const addCentrepayDirective = useAddCentrepayDirective(
    tenancy,
    legalEntity,
    managingEntity
  );

  const {
    showAddDirectiveDialog,
    confirmAddDirectiveDialog,
  } = useAddCentrepayPaymentDirectiveDialog(addCentrepayDirective);

  return (
    <Box style={{ display: "flex" }}>
      Add centrepay directive:
      <Box
        onClick={showAddDirectiveDialog}
        style={{ cursor: "pointer", marginLeft: 10 }}
      >
        <EditIcon style={{ height: 20 }} />
      </Box>
      {confirmAddDirectiveDialog}
    </Box>
  );
}
