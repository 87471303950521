import React from "react";
import { Datagrid, DateInput, Filter, SelectInput } from "react-admin";
import moment from "moment-timezone";
import {
  LinkField,
  List,
  MoneyField,
  UppercaseTextField,
} from "../../../common";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <SelectInput
      label="Category"
      source="category"
      choices={[
        {
          id: "management",
          name: "MANAGEMENT",
        },
        {
          id: "management folio",
          name: "MANAGEMENT FOLIO",
        },
        {
          id: "agency",
          name: "AGENCY",
        },
        {
          id: "tenancy",
          name: "TENANCY",
        },
        {
          id: "misc",
          name: "MISC",
        },
        {
          id: "investor",
          name: "INVESTOR",
        },
        {
          id: "tenant",
          name: "TENANT",
        },
        {
          id: "rentvestor",
          name: "RENTVESTOR",
        },
        {
          id: "centrepay",
          name: "CENTREPAY",
        },
        {
          id: "bond",
          name: "BOND",
        },
        {
          id: "offline payment",
          name: "OFFLINE PAYMENT",
        },
        {
          id: "ailo trading",
          name: "AILO TRADING",
        },
        {
          id: "ailo bank",
          name: "AILO BANK",
        },
      ]}
      alwaysOn
    />
    <DateInput label="Banking Date Before" source="beforeDate" alwaysOn />
  </Filter>
);

const WalletOwnerBalancesList = (props: any) => {
  const beforeDateDefaultFilter = moment()
    .startOf("month")
    .subtract(1, "days")
    .format(moment.HTML5_FMT.DATE);

  return (
    <List
      {...props}
      title="Wallet Owner Balances"
      bulkActionButtons={false}
      filters={<SearchFilter />}
      filterDefaultValues={{
        beforeDate: beforeDateDefaultFilter,
      }}
    >
      <Datagrid>
        <UppercaseTextField label="Category" source="category" />
        <LinkField label="Reference" source="id" />
        <MoneyField
          label="Total Balance ($)"
          textAlign="right"
          source="totalBalance"
        />
      </Datagrid>
    </List>
  );
};

export { WalletOwnerBalancesList };
