import React from "react";
import { Button } from "@material-ui/core";
/**
 * @deprecated Use <Button /> directly
 */
export const ViewAllButton = ({
  viewAll,
  onClick,
}: {
  viewAll?: boolean;
  onClick?(): void;
}) => {
  const text = viewAll ? " View less" : "View all";
  return (
    <Button
      color="secondary"
      style={{
        textTransform: "none",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
