import { uniqBy } from "lodash";
import { isPresent } from "ts-is-present";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";

export function getTeams(
  managementFolio: ManagementFolioShowRecord
): { id: string; name: string }[] {
  const managements = managementFolio.managements.items;
  const teams = managements
    .map((management) => management.team)
    .filter(isPresent);

  return uniqBy(teams, "id");
}
