import {
  Button,
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import React from "react";

/**
 * @example
 * ```js
  function BulkActionButtons(props: any): React.ReactElement | null {
    return (
      <>
        <BulkMutationOneByOneButton type="archive" {...props} />
        <BulkMutationOneByOneButton type="unarchive" {...props} />
      </>
    );
  };

  export const BillList = (props: Object) => {
    return (
      <List bulkActionButtons={<BulkActionButtons />} {...props}>...</List>
    );
  };
 * ```
 */
export function BulkMutationOneByOneButton({
  type,
  label = type,
  resource,
  selectedIds,
  icon,
}: any) {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);
  const [mutate] = useMutation();

  async function commit() {
    // eslint-disable-next-line no-undef
    const results = await Promise.all(
      selectedIds.map((id: any) => {
        // eslint-disable-next-line no-undef
        return new Promise((resolve) => {
          mutate(
            {
              type,
              resource,
              payload: {
                data: {
                  id,
                },
              },
            },
            {
              onSuccess(response) {
                resolve({
                  id,
                  response,
                });
              },

              onFailure(error) {
                resolve({
                  id,
                  error,
                });
              },
            }
          );
        });
      })
    );
    const errorResults = results.filter((r: any) => r.error);
    const successResults = results.filter((r: any) => !r.error);

    if (successResults.length === 0) {
      notify(
        `${label} failed on all ${
          results.length
        } ${resource} records.\n${errorResults
          .map((r: any) => r.error)
          .join("\n")}`
      );
      return;
    }

    if (errorResults.length > 0) {
      notify(
        `${label} succeeded on ${
          successResults.length
        } ${resource} records, but failed on ${
          errorResults.length
        } records: ${errorResults
          .map((r: any) => r.id)
          .join(" , ")}.\n${errorResults.map((r: any) => r.error).join("\n")}`
      );
      return;
    }

    unselectAll();
    refresh();
    notify(`${label} succeeded on ${results.length} ${resource} records.`);
  }

  return (
    <Button
      label={label}
      disabled={!selectedIds || selectedIds.length === 0}
      onClick={commit}
    >
      {icon}
    </Button>
  );
}
