/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import {
  formatAddress,
  formatRentPaymentSchedule,
} from "../../../common/utils";
import { ListItemView } from "../../../common/ui/lists";
import { PropertyTypeAside } from "./PropertyTypeAside";

const ConsumerPropertiesListItem = ({
  item,
  subHeader,
  push: pushProp,
}: any) => {
  const { property, rent } = item;
  const formattedAddress = formatAddress(property.address);
  const formattedRentSchedule = rent
    ? `Rent amount: ${formatRentPaymentSchedule(rent)}`
    : null;
  return (
    <ListItemView
      header={formattedAddress}
      subHeader={subHeader ?? formattedRentSchedule}
      rightComponent={<PropertyTypeAside item={item} />}
      onClick={() => {
        pushProp(`/Properties/${item.property.id}/show`);
      }}
    />
  );
};

const ConnectedConsumerPropertiesListItem = connect(null, {
  push,
})(ConsumerPropertiesListItem);
export { ConnectedConsumerPropertiesListItem as ConsumerPropertiesListItem };
