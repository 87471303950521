import React from "react";
import { push } from "react-router-redux";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-admin";
import { ListItemView, RightChevronIcon } from "../../../../../common";
import { OperationData, Person } from "../../../../../api";

const connector = connect(null, { pushRoute: push });
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps {
  company: NonNullable<
    OperationData<typeof Person, "GET_ONE">["companiesOwned"]
  >["items"][number];
}

export const PersonCompaniesOwnedListItem = connector(
  ({ company, pushRoute }: Props) => {
    const linkTo = `/Companies/${company.id}/show`;

    return (
      <ListItemView
        header={company.registeredEntityName}
        rightComponent={
          <Link to={linkTo}>
            <RightChevronIcon />
          </Link>
        }
        onClick={() => {
          pushRoute(linkTo);
        }}
      />
    );
  }
);
