import gql from "graphql-tag";
import { CREATE, GET_LIST, GET_MANY, GET_ONE } from "react-admin";
import { capitaliseFirstLetter } from "../../../common/ui/deprecated";
import {
  buildPaginatedResponseFromPaginatedList,
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import { LocalDateRangeCondition } from "../../graphql/types.generated";
import {
  ArchiveBillMutation,
  ArchiveBillMutationVariables,
  BillQuery,
  BillQueryVariables,
  BillsByIdsQuery,
  BillsByIdsQueryVariables,
  BillsQuery,
  BillsQueryVariables,
  CreateBillMutation,
  CreateBillMutationVariables,
} from "./billClient.generated";

const LegalEntityFragment = gql`
  fragment LegalEntityFragment on LegalEntity {
    __typename
    id
    ... on Person {
      firstName
      lastName
      phoneNo
      emailAddress
    }
    ... on Company {
      registeredEntityName
    }
  }
`;

const BillPayeeFragment = gql`
  fragment BillPayeeFragment on BillPayee {
    __typename
    ... on Supplier {
      ailoRN
      name
    }
    ... on Management {
      ailoRN
      property {
        id
        address {
          unitStreetNumber
          streetName
          suburb
          state
          country
          postcode
        }
      }
    }
    ... on LegalEntityCompanion {
      ailoRN: ailoRNV2
      legalEntity {
        ...LegalEntityFragment
      }
    }
  }
  ${LegalEntityFragment}
`;

const ListBillFragment = gql`
  fragment ListBill on Bill {
    id
    organisationAiloRN: relatingToOrganisation
    managementAiloRN: relatingToManagement
    issueDate: issueDateV2
    dueDate: dueDateV2
    organisation {
      id
      ailoRN
      name
    }
    management {
      id
      ailoRN
      property {
        id
        address {
          unitStreetNumber
          streetName
        }
      }
    }
    amount {
      cents
    }
    liabilityState {
      paymentStatus
      paymentDate
    }
    payee {
      ...BillPayeeFragment
    }
    invoiceNumber
    status
    agencyStatus
    createdAt
    organisationArchiveReason
  }
  ${BillPayeeFragment}
`;

export type BillClientCreateInput = {
  data: CreateBillMutationVariables["billDetails"];
};

export const BillClientDefinition = {
  [CREATE]: (params: BillClientCreateInput) => ({
    query: gql`
      mutation createBill($billDetails: BillInput!) {
        createBill(billDetails: $billDetails) {
          ailoRN
          id
          organisationAiloRN: relatingToOrganisation
          managementAiloRN: relatingToManagement
          issueDate: issueDateV2
          dueDate: dueDateV2
          organisation {
            id
            ailoRN
            name
          }
          management {
            id
            ailoRN
            property {
              id
              address {
                unitStreetNumber
                streetName
              }
            }
          }
          amount {
            cents
          }
          payee {
            ...BillPayeeFragment
          }
          status
          agencyStatus
          invoiceNumber
          createdAt
          organisationArchiveReason
        }
      }
      ${BillPayeeFragment}
    `,
    variables: {
      billDetails: {
        ...params.data,
      },
    },
    parseResponse(response: GraphQLResponse<CreateBillMutation>) {
      return {
        data: {
          ...response.data.createBill,
        },
      };
    },
  }),
  [GET_LIST]: (params: {
    filter: {
      searchTerm?: string;
      organisationId?: string;
      managementId?: string;
      status?: string | string[];
      paid?: boolean;
      paymentStatus?: string;
      agencyStatus?: string;
      taxCategoryIdNotIn?: string[];
      showManagementFees?: boolean;
      payerId?: string[];
      paymentDateFrom?: string;
      paymentDateTo?: string;
      dueDate?: LocalDateRangeCondition;
    };
    sort?: {
      field?: string;
      order?: "ASC" | "DESC";
    };
    pagination: PaginationParams;
  }) => {
    return {
      query: gql`
        query bills(
          $pageSize: Int
          $cursor: String
          $organisationAiloRNs: [AiloRN!]
          $managementAiloRNs: [AiloRN!]
          $paid: Boolean
          $status: [BillStatus!]
          $paymentStatus: [BillPaymentStatus!]
          $agencyStatus: [BillAgencyStatus!]
          $taxCategoryIdNotIn: [String!]
          $payerId: [AiloRN!]
          $paymentDate: LocalDateRangeCondition
          $dueDate: LocalDateRangeCondition
          $sort: BillSortParams
        ) {
          bills(
            cursor: { pageSize: $pageSize, cursor: $cursor }
            organisationId: $organisationAiloRNs
            paid: $paid
            status: $status
            paymentStatus: $paymentStatus
            agencyStatus: $agencyStatus
            taxCategoryIdNotIn: $taxCategoryIdNotIn
            management: $managementAiloRNs
            payerId: $payerId
            paymentDate: $paymentDate
            dueDate: $dueDate
            sort: $sort
          ) {
            pageInfo {
              total
              nextCursor
              hasNext
            }
            items {
              ...ListBill
              attachments {
                id
              }
            }
          }
        }
        ${ListBillFragment}
      `,
      variables: <BillsQueryVariables>{
        ...buildPaginationVariables(params.pagination),
        name: params.filter.searchTerm,
        organisationAiloRNs: params.filter.organisationId
          ? [`ailo:authz:organisation:${params.filter.organisationId}`]
          : null,
        managementAiloRNs: params.filter.managementId
          ? [`ailo:propertymanagement:management:${params.filter.managementId}`]
          : null,
        paid: params.filter.paid,
        status: params.filter.status
          ? Array.isArray(params.filter.status)
            ? params.filter.status
            : [params.filter.status]
          : undefined,
        paymentStatus: params.filter.paymentStatus
          ? [params.filter.paymentStatus]
          : undefined,
        agencyStatus: params.filter.agencyStatus
          ? [params.filter.agencyStatus]
          : undefined,
        taxCategoryIdNotIn:
          params.filter.taxCategoryIdNotIn ??
          (!params.filter.showManagementFees ? ["MANAGEMENT_FEES"] : undefined),
        payerId: params.filter.payerId,
        paymentDate: {
          gte: params?.filter?.paymentDateFrom,
          lte: params?.filter?.paymentDateTo,
        },
        dueDate: params?.filter?.dueDate,
        sort: {
          field: params.sort?.field
            ? capitaliseFirstLetter(params.sort?.field)
            : "DueDate",
          direction: params.sort?.order === "ASC" ? "Asc" : "Desc",
        },
      },

      parseResponse(response: GraphQLResponse<BillsQuery>) {
        return buildPaginatedResponseFromPaginatedList(response.data.bills!);
      },
    };
  },
  [GET_MANY]: (params: { ids: string[]; pagination: PaginationParams }) => {
    return {
      query: gql`
        query billsByIds($ids: [AiloRN!], $pageSize: Int, $cursor: String) {
          bills(id: $ids, cursor: { pageSize: $pageSize, cursor: $cursor }) {
            pageInfo {
              total
              nextCursor
              hasNext
            }
            items {
              ...ListBill
              attachments {
                id
                file {
                  id
                  fileName
                  url
                  thumbnailUrl
                }
              }
            }
          }
        }
        ${ListBillFragment}
      `,
      variables: <BillsByIdsQueryVariables>{
        ids: params.ids,
        ...buildPaginationVariables(params.pagination),
      },
      parseResponse(response: GraphQLResponse<BillsByIdsQuery>) {
        return buildPaginatedResponseFromPaginatedList(response.data.bills!);
      },
    };
  },
  archive: (params: {
    data: {
      id: string;
      reason?: string;
    };
  }) => {
    return {
      query: gql`
        mutation archiveBill($id: ID!, $reason: String) {
          bill: archiveBill(billId: $id, reason: $reason) {
            ...ListBill
          }
        }
        ${ListBillFragment}
      `,
      variables: <ArchiveBillMutationVariables>{
        id: params.data.id,
        reason: params.data.reason,
      },

      parseResponse(response: GraphQLResponse<ArchiveBillMutation>) {
        return {
          data: response.data.bill,
        };
      },
    };
  },
  [GET_ONE]: (params: { id: string }) => {
    return {
      query: gql`
        query bill($id: ID!) {
          bill: billById(billId: $id) {
            id
            amount {
              cents
            }
            invoiceNumber
            description
            status
            agencyStatus
            createdAt
            createdBy
            createdByUser {
              id
              person {
                id
                firstName
                lastName
              }
            }
            dueDate: dueDateV2
            issueDate: issueDateV2
            payer {
              __typename
              ... on Management {
                ailoRN
                owners {
                  owner {
                    ...LegalEntityFragment
                  }
                }
              }
              ... on Tenancy {
                id
                ailoRN
                tenants {
                  tenant {
                    ...LegalEntityFragment
                  }
                }
              }
              ... on LegalEntityCompanion {
                ailoRN: ailoRNV2
                legalEntity {
                  ...LegalEntityFragment
                }
              }
            }
            managementAiloRN: relatingToManagement
            organisationArchiveReason
            archivableByOrganisation
            liability {
              id
              linkedChildBusinessTransactions {
                id
                status
                createdAt
              }
            }
            management {
              id
              ailoRN
              managingEntity {
                id
                ailoRN
                registeredEntityName
              }
              firstPublishedAt
              property {
                id
                address {
                  unitStreetNumber
                  streetName
                  suburb
                  state
                  country
                  postcode
                }
              }
            }
            payee {
              ...BillPayeeFragment
            }
            paymentReference {
              id
              supplierPaymentMethodReference
              crn
              supplierPaymentMethodCompanion {
                id
                paymentMethod {
                  id
                  ... on CreditCard {
                    __typename
                  }
                  ... on BankAccount {
                    bsb
                    accountNumber
                    __typename
                  }
                  ... on BPay {
                    __typename
                    billerCode
                  }
                }
              }
            }
            liabilityState {
              paymentStatus
              paidAt
              dueAmount {
                cents
              }
            }
            taxCategory {
              id
              name
            }
            internal
            taxAutoCalculated
            lineItems {
              pageInfo {
                hasMore
                nextCursor
                total
              }
              items {
                id
                ailoRN
                description
                isTax
                taxInclusiveAmount {
                  cents
                }
                taxAmount {
                  cents
                }
              }
            }
            attachments {
              id
              file {
                id
                fileName
                url
                thumbnailUrl
              }
            }
            applyManagementFee
            liability {
              id
            }
          }
        }
        ${LegalEntityFragment}
        ${BillPayeeFragment}
      `,
      variables: <BillQueryVariables>{
        id: params.id,
      },
      errorPolicy: "all",

      parseResponse(response: GraphQLResponse<BillQuery>) {
        return {
          data: response.data.bill,
        };
      },
    };
  },
};

export const billClient = createClientFromDefinition(BillClientDefinition);
