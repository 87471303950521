import gql from "graphql-tag";
import { CREATE, GET_LIST } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { VirtualAccountData } from "../../../common";
import {
  CreateOfflinePaymentAccountMutation,
  GetOfflinePaymentAccountsQuery,
} from "./offlinePaymentAccountClient.generated";

export const OfflinePaymentAccountDefinition = {
  [GET_LIST]: (params: { entity: string }) => {
    return {
      query: gql`
        query getOfflinePaymentAccounts($entity: AiloRN!) {
          getOfflinePaymentAccountsByManagingEntity(managingEntity: $entity) {
            id
            paymentMethodCompanion {
              id
              paymentMethod {
                __typename
                wallet {
                  id
                  totalBalance {
                    cents
                  }
                  availableBalance {
                    cents
                  }
                  owner {
                    reference
                  }
                }
                id
                externalId
                ... on VirtualAccount {
                  accountNumber
                  bsb
                }
              }
            }
          }
        }
      `,
      variables: {
        entity: params.entity,
      },
      parseResponse: (
        response: GraphQLResponse<GetOfflinePaymentAccountsQuery>
      ): GetOfflinePaymentAccountsByManagingEntityData => {
        return {
          data: response.data.getOfflinePaymentAccountsByManagingEntity,
        };
      },
    };
  },
  [CREATE]: (params: { entity: string }) => {
    return {
      query: gql`
        mutation createOfflinePaymentAccount($entity: AiloRN!) {
          createOfflinePaymentAccount(managingEntity: $entity) {
            id
          }
        }
      `,
      variables: {
        entity: params.entity,
      },
      parseResponse: (
        response: GraphQLResponse<CreateOfflinePaymentAccountMutation>
      ): { data: VirtualAccountData } => {
        return {
          data: response.data.createOfflinePaymentAccount,
        };
      },
    };
  },
};

interface GetOfflinePaymentAccountsByManagingEntityData {
  data: VirtualAccountData[];
}

export const offlinePaymentAccountClient = createClientFromDefinition(
  OfflinePaymentAccountDefinition
);
