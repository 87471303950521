import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, Typography } from "@material-ui/core";
import React from "react";
import { ConfirmCloseDialogActions } from "../ConfirmCloseDialogActions";

export const ConfirmMutationDialogContent = ({
  title,
  content,
  onConfirmClick,
  onCancelClick,
}: any) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography paragraph>{content}</Typography>
      </DialogContent>
      <ConfirmCloseDialogActions
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
    </>
  );
};
