import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import {
  Datagrid,
  Filter,
  FunctionField,
  regex,
  TextField,
  TextInput,
} from "react-admin";
import { ManagementFolio, OperationData } from "../../api";
import { LegalEntityField, LinkField, MoneyField } from "../../common/fields";
import { CompanyFilter, LegalEntityFilter } from "../../common/filters";
import { List } from "../../common/views/List";
import { ExpensesDueField } from "./ExpensesDueField";

type ManagementFolioListRecord = NonNullable<
  OperationData<typeof ManagementFolio, "GET_LIST">
>[number];

const validateAilornOrUUID = regex(
  /^(ailo:propertymanagement:managementfolio:)?[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ABab][\da-f]{3}-[\da-f]{12}$/,
  "Must be a valid UUID or Ailorn"
);

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <TextInput
      autoFocus
      label="Portfolio ID"
      source="idOrAilorn"
      alwaysOn
      validate={validateAilornOrUUID}
    />
    <CompanyFilter label="Agency name" source="managingEntityId" alwaysOn />
    <LegalEntityFilter label="Owner" source="ownerId" alwaysOn />
  </Filter>
);

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
});

const ManagementFolioList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      perPage={25}
      title="Portfolios"
      bulkActionButtons={false}
      filters={<SearchFilter />}
    >
      <Datagrid rowClick="show">
        <FunctionField<ManagementFolioListRecord>
          {...props}
          label="PORTFOLIO ID"
          render={(record) => {
            return (
              <LinkField
                label="PORTFOLIO ID"
                record={record}
                source="id"
                sourceResource={ManagementFolio}
                to={`/${ManagementFolio}/${record?.id}/show`}
              />
            );
          }}
        />
        <FunctionField<ManagementFolioListRecord>
          {...props}
          label="AGENCY"
          render={(record) => {
            return (
              <LinkField
                label="AGENCY"
                record={record}
                source="managingEntity"
                renderText={() =>
                  record?.managingEntity?.organisation.name || ""
                }
                sourceResource="Company"
                to={`/Companies/${record?.managingEntity?.id}/show`}
              />
            );
          }}
        />
        <FunctionField<ManagementFolioListRecord>
          {...props}
          label="OWNERS"
          render={(record) => {
            return record?.owners ? (
              <span className={classes.main}>
                {record?.owners.map((o, index) => (
                  <LegalEntityField
                    key={index}
                    record={{ owner: o.owner }}
                    source="owner"
                    copyable
                  />
                ))}
              </span>
            ) : null;
          }}
        />
        <TextField label="PROPERTIES" source="managements.pageInfo.total" />
        <MoneyField
          label="AVAILABLE BALANCE"
          source="wallet.availableBalance"
          emptyText="N/A"
        />
        <FunctionField<ManagementFolioListRecord>
          {...props}
          label="EXPENSES DUE"
          render={(record) => {
            if (!record) return <Skeleton width={60} />;

            const managementAilorns = record.managements.items.map(
              (management) => management.ailorn
            );
            return record ? (
              <ExpensesDueField
                billPayerAilorns={[record.ailorn, ...managementAilorns]}
                dueFeeAmount={record?.wallet?.owner.dueFeeAmount}
              />
            ) : (
              <Skeleton width={60} />
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export { ManagementFolioList };
