import React from "react";
import {
  FormDataConsumer,
  required,
  TextInput,
  TextInputProps,
} from "react-admin";

type DescriptionInputProps = Pick<TextInputProps, "style">;

const MAX_CHARS = 60;
const source = "description" as const;

export function DescriptionInput(
  props: DescriptionInputProps
): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <TextInput
          label="Description for the payer"
          source={source}
          helperText={characterCountMessage(formData[source])}
          validate={[characterLimitValidation, required()]}
          {...props}
        />
      )}
    </FormDataConsumer>
  );
}

function characterLimitValidation(value?: string): string | undefined {
  return value && value.length > MAX_CHARS
    ? characterCountMessage(value)
    : undefined;
}

function characterCountMessage(value?: string): string {
  return `Character limit: ${value?.length || 0}/${MAX_CHARS}`;
}
