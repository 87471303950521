import currency from "currency.js";
import React from "react";

/**
 * @deprecated Use `MoneyField` instead.
 */
export const AmountField = ({
  record,
  value,
  adornment,
}: {
  record?: { cents: string | number } | null;
  value?: number;
  adornment?: string;
}) => {
  const cents =
    record?.cents !== undefined
      ? record.cents
      : (record as any)?.amountInCents !== undefined
      ? (record as any).amountInCents
      : value;
  if (cents === undefined && !adornment) {
    return null;
  }
  return (
    <span>
      {currency(cents).divide(100).format()}
      {adornment && ` ${adornment}`}
    </span>
  );
};
