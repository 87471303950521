import React from "react";
import { EditProps, useNotify, useRedirect } from "react-admin";
import { FeeBlueprint } from "../../api/graphql/types.generated";
import { Edit } from "../../common";
import { useFeeBlueprintAnalytics } from "./useFeeBlueprintAnalytics";
import { FeeBlueprintForm } from "./form";

export const FeeBlueprintEdit = (props: EditProps): React.ReactElement => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { onFeeBlueprintMutated } = useFeeBlueprintAnalytics();

  return (
    <Edit
      {...props}
      onSuccess={({ data }: { data: FeeBlueprint }) => {
        redirect(`/FeeBlueprint/${data.id}/show`);
        notify("Fee blueprint updated");
        onFeeBlueprintMutated("Fee Blueprint Edited", data);
      }}
    >
      <FeeBlueprintForm
        toolbarOptions={{
          deleteButton: false,
        }}
      />
    </Edit>
  );
};
