import type { Reducer } from "redux";
import type { PaginationStateType } from "../states";
import type {
  UpdateLatestPageAction,
  RemoveLastPageAction,
  ResetPaginationResourceAction,
} from "../actions";
import {
  UPDATE_LATEST_PAGE,
  REMOVE_LAST_PAGE,
  RESET_PAGINATION_RESOURCE,
} from "../actions";
import { PaginationState } from "../states";

export type PaginationActionType =
  | UpdateLatestPageAction
  | RemoveLastPageAction
  | ResetPaginationResourceAction;
const initialState: PaginationStateType = new PaginationState(
  PaginationState.defaultState
).current();

const paginationReducer: Reducer<PaginationStateType, PaginationActionType> = (
  state: PaginationStateType = initialState,
  action: PaginationActionType
) => {
  const paginationState = () => new PaginationState(state);

  switch (action.type) {
    case UPDATE_LATEST_PAGE: {
      const typedAction: UpdateLatestPageAction = action as any;
      return paginationState()
        .updateLatestResourcePage(typedAction.resource, typedAction.payload)
        .current();
    }

    case REMOVE_LAST_PAGE: {
      const typedAction: RemoveLastPageAction = action as any;
      return paginationState()
        .removeLastPageInResource(typedAction.resource)
        .current();
    }

    case RESET_PAGINATION_RESOURCE: {
      const typedAction: ResetPaginationResourceAction = action as any;
      return paginationState().resetResource(typedAction.resource).current();
    }

    default:
      return state;
  }
};

export { paginationReducer };
