import { formatNames } from "@ailo/domain-helpers";
import { Tab, Tabs } from "@material-ui/core";
import { parse, stringify } from "query-string";
import React, { useMemo } from "react";
import { ShowProps, TitleForRecord, useShowController } from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import {
  getOwnerNames,
  ManagementFolioShowRecord,
} from "../../../features/ManagementFolio";
import { GeneralTab } from "./GeneralTab";
import { ManagementFolioTitleCard } from "./ManagementFolioTitleCard";
import { StatementsTab } from "./StatementsTab";
import { TransactionsTab } from "./TransactionsTab";

const TABS = [
  { label: "General", value: "General" },
  { label: "Transactions", value: "Transactions" },
  { label: "Statements", value: "Statements" },
] as const;

export const ManagementFolioShow = (
  props: ShowProps
): React.ReactElement | null => {
  const {
    loaded,
    loading,
    record: managementFolio,
  } = useShowController<ManagementFolioShowRecord>(props);
  const history = useHistory();
  const location = useLocation();

  const { tab: tabSearchParam } = useMemo(() => parse(location.search), [
    location.search,
  ]);
  const tabValue =
    TABS.find((tab) => tab.value === tabSearchParam)?.value ?? "General";

  if (!loaded || loading || !managementFolio) {
    return null;
  }

  const ownerNames = formatNames(getOwnerNames(managementFolio.owners));

  return (
    <>
      <TitleForRecord
        record={managementFolio}
        defaultTitle={`Portfolio: ${ownerNames}`}
      />
      <ManagementFolioTitleCard
        managementFolio={managementFolio}
        ownerNames={ownerNames}
      />

      <Tabs
        value={tabValue}
        onChange={(_event, newTabValue) => {
          history.push({
            search: stringify({
              tab: newTabValue,
            }),
          });
        }}
      >
        {TABS.map((tab, index) => (
          <Tab label={tab.label} value={tab.value} key={index} />
        ))}
      </Tabs>

      {tabValue === "General" ? (
        <GeneralTab managementFolio={managementFolio} />
      ) : tabValue === "Transactions" ? (
        <TransactionsTab managementFolio={managementFolio} />
      ) : tabValue === "Statements" ? (
        <StatementsTab managementFolio={managementFolio} />
      ) : null}
    </>
  );
};
