import React, { ReactElement } from "react";
import { isPresent } from "ts-is-present";
import { sortTenancies } from "../../features/Tenancy";
import { ManagementListRecord } from "./managementsList";
import { ConsumerChips } from "./ConsumerChips";

function TenantChips({
  record,
}: {
  record: ManagementListRecord;
}): ReactElement | null {
  const { allTenancies } = record;
  if (!allTenancies) return null;

  const sortedTenancies = sortTenancies(allTenancies.filter(isPresent));
  if (sortedTenancies.length === 0) return null;

  const shownTenancy = sortedTenancies[0];
  if (!shownTenancy) return null;

  const consumers = shownTenancy.tenants?.flatMap((t) => t?.tenant) ?? [];
  return <ConsumerChips consumers={consumers} />;
}

export { TenantChips };
