import { DateTimeWithTimeZone } from "@ailo/date";
import Box from "@material-ui/core/Box";
import React from "react";
import {
  Colors,
  CopyableTextField,
  DATE_TIME_FORMAT,
  LinkField,
  ListItemView,
} from "../../../../common";
import { PaymentMethodBadges } from "./paymentMethodBadges";

export const OnceOffBankAccountListItem = ({
  id,
  bsb,
  maskedAccountNumber,
  isDefaultIn,
  isDefaultOut,
  isDeleted,
  isHidden,
  wallet,
}: any) => {
  const deletedStyle = {
    color: Colors.TEXT.DARK.SECONDARY,
  };
  const accountNumber = `${bsb}  ${maskedAccountNumber}`;
  return (
    <ListItemView
      header="One off transfer"
      subHeader={
        <>
          <Box
            style={{
              whiteSpace: "pre",
            }}
            component="span"
          >
            {accountNumber}
          </Box>
          <CopyableTextField
            source="id"
            record={{
              id,
            }}
          />
          Added on{" "}
          {DateTimeWithTimeZone.from(wallet.createdAt).format(DATE_TIME_FORMAT)}
          <br />
          <LinkField record={wallet} />
        </>
      }
      rightComponent={
        <PaymentMethodBadges
          isDefaultIn={isDefaultIn}
          isDefaultOut={isDefaultOut}
          isDeleted={isDeleted}
          isHidden={isHidden}
        />
      }
      style={isDeleted ? deletedStyle : null}
    />
  );
};
