import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { stringify } from "query-string";
import { GET_ONE } from "ra-core";
import React from "react";
import { Button, Link } from "react-admin";
import { isPresent } from "ts-is-present";
import { ManagementFolio } from "../../../api";
import { DescriptionList, LinkField, MoneyField } from "../../../common";
import { useQuery } from "../../../common/api/useQuery";
import { DetailCard } from "../../../common/ui/cards";
import { DetailCardListSubheader } from "../../../common/ui/cards/DetailCard/DetailCardListSubHeader";
import { getPrimaryOwnerAilorn } from "../../../resourceViews/ManagementFolio/ManagementFolioShow/getPrimaryOwner";
import {
  AutoPayBillItem,
  AutoWithdrawPlanDisabledItem,
  AutoWithdrawPlanItem,
} from "../../AutoPaymentSettings";
import { getOwnerNames } from "../getOwnerNames";
import { ManagementFolioPropertiesList } from "../ManagementFolioPropertiesCard/ManagementFolioPropertiesList";

export function ManagementFolioCard({
  managementFolioId,
  managementId,
}: {
  managementFolioId: string;
  /**
   * If set, will only show transactions for the given management.
   */
  managementId?: string;
}): React.ReactElement {
  const { data: managementFolio, loading, error } = useQuery({
    type: GET_ONE,
    resource: ManagementFolio,
    payload: {
      id: managementFolioId,
    },
  });

  if (error) {
    return (
      <DetailCard
        title={`Portfolio: ${managementFolioId}`}
        contents={`Failed to load management folio ${managementFolioId}`}
      />
    );
  }

  const viewFolioButton = (
    <Button
      component={Link}
      to={{
        pathname: `/${ManagementFolio}/${managementFolioId}/show`,
      }}
      label="View"
    />
  );

  if (loading || !managementFolio) {
    return (
      <DetailCard
        title={`Portfolio: ${managementFolioId}`}
        action={viewFolioButton}
        contents={<Skeleton variant="text" width={160} />}
      />
    );
  }

  const { wallet } = managementFolio;
  const ownerNames = getOwnerNames(managementFolio.owners);
  const managements = managementFolio.managements.items ?? [];
  const autoWithdrawPlans = wallet?.autoWithdrawPlans?.items ?? [];
  const primaryOwnerAilorn = getPrimaryOwnerAilorn(managementFolio);

  const transactionsLink = `/${ManagementFolio}/${
    managementFolio.id
  }/show?${stringify({
    tab: "Transactions",
    ...(isPresent(managementId) && { management: managementId }),
  })}`;

  return (
    <DetailCard
      title={`Portfolio: ${ownerNames}`}
      action={viewFolioButton}
      contents={
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <DescriptionList
            items={[
              {
                name: "Portfolio ID",
                description: (
                  <LinkField
                    record={managementFolio}
                    source="ailorn"
                    sourceResource={ManagementFolio}
                    linkable={false}
                  />
                ),
              },
              wallet?.availableBalance && {
                name: "Available Wallet Balance",
                description: (
                  <Box>
                    <MoneyField record={wallet} source="availableBalance" />
                    <Link to={transactionsLink} style={{ marginLeft: 10 }}>
                      View Transactions
                    </Link>
                  </Box>
                ),
              },
            ].filter(isPresent)}
          />

          <DetailCardListSubheader>Properties</DetailCardListSubheader>
          <ManagementFolioPropertiesList managements={managements} />

          <DetailCardListSubheader>Wallet Settings</DetailCardListSubheader>
          {autoWithdrawPlans.length > 0 ? (
            autoWithdrawPlans.map((plan) => (
              <AutoWithdrawPlanItem
                key={plan.id}
                edit={false}
                autoWithdrawPlan={plan}
                walletOwnerReference={managementFolio.ailorn}
              />
            ))
          ) : (
            <AutoWithdrawPlanDisabledItem />
          )}

          {wallet?.autoPayLiabilityStatus &&
            (primaryOwnerAilorn ? (
              <AutoPayBillItem
                edit={false}
                autoPayLiabilityStatus={wallet?.autoPayLiabilityStatus}
                legalEntityAiloRN={primaryOwnerAilorn.toString()}
              />
            ) : (
              <Box style={{ marginTop: 10 }}>Could not find primary owner</Box>
            ))}
        </Grid>
      }
    />
  );
}
