import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  number,
  SelectInput,
  DateInput,
  NumberInput,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  regex,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatCompanyOptionText,
  formatLegalEntityOptionText,
  formatPropertyOptionText,
} from "../../common/utils";
import { Create } from "../../common";
import { LegalEntityInput, ReferenceInput } from "../../common";
import { CreateTenancyCard } from "../Tenancy/CreateTenancyCard";

const styles = {
  width: "100%",
  first_name: {
    display: "inline-block",
  },
  last_name: {
    display: "inline-block",
    marginLeft: 32,
  },
  email: {
    width: 544,
  },
  address: {
    maxWidth: 544,
  },
  zipcode: {
    display: "inline-block",
  },
  city: {
    display: "inline-block",
    marginLeft: 32,
  },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: {
    display: "inline-block",
  },
  confirm_password: {
    display: "inline-block",
    marginLeft: 32,
  },
};

const minValue = (min: number, message = "Value too small") => (value: any) =>
  value && value < min ? message : undefined;

const maxValue = (max: number, message = "Value too large") => (value: any) =>
  value && value > max ? message : undefined;

const validatePercent = [required(), number(), minValue(1), maxValue(100)];

const ManagementCreate = (props: Record<string, any>) => {
  return (
    <Create
      redirect="show"
      successMessage="Management has been created"
      {...props}
    >
      <SimpleForm style={styles}>
        <Card style={styles}>
          <CardContent>
            <BooleanInput
              source="createProperty"
              label="Create Property"
              defaultValue={false}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.createProperty) {
                  return (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Property Address
                      </Typography>
                      <Box
                        display={{
                          xs: "block",
                          sm: "flex",
                        }}
                      >
                        <Box
                          flex={2}
                          mr={{
                            xs: 0,
                            sm: "0.5em",
                          }}
                        >
                          <TextInput
                            style={styles}
                            label="unit/street number"
                            source="property.unitStreetNumber"
                            fullWidth
                          />
                        </Box>
                        <Box
                          flex={8}
                          ml={{
                            xs: 0,
                            sm: "0.5em",
                          }}
                        >
                          <TextInput
                            label="street name"
                            source="property.streetName"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box
                        display={{
                          xs: "block",
                          sm: "flex",
                        }}
                      >
                        <Box
                          flex={1}
                          mr={{
                            xs: 0,
                            sm: "0.5em",
                          }}
                        >
                          <TextInput
                            label="suburb"
                            source="property.suburb"
                            fullWidth
                          />
                        </Box>
                        <Box
                          flex={2}
                          ml={{
                            xs: 0,
                            sm: "0.5em",
                          }}
                        >
                          <TextInput
                            label="state"
                            source="property.state"
                            fullWidth
                          />
                        </Box>
                        <Box
                          flex={2}
                          ml={{
                            xs: 0,
                            sm: "0.5em",
                          }}
                        >
                          <TextInput
                            label="postcode"
                            source="property.postcode"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box
                        display={{
                          xs: "block",
                          sm: "flex",
                        }}
                      >
                        <SelectInput
                          label="country"
                          source="property.country"
                          choices={[
                            {
                              id: "Australia",
                              name: "Australia",
                            },
                            {
                              id: "New Zealand",
                              name: "New Zealand",
                            },
                            {
                              id: "USA",
                              name: "USA",
                            },
                            {
                              id: "UK",
                              name: "UK",
                            },
                          ]}
                        />
                      </Box>
                    </div>
                  );
                }

                return (
                  <div>
                    <Typography variant="h6" gutterBottom>
                      Existing Property
                    </Typography>
                    <ReferenceInput
                      alwaysOn
                      label="Address"
                      source="propertyId"
                      reference="Properties"
                      filterToQuery={(searchTerm: string) => ({
                        searchTerm,
                      })}
                    >
                      <AutocompleteInput
                        optionText={formatPropertyOptionText}
                        fullWidth
                      />
                    </ReferenceInput>
                  </div>
                );
              }}
            </FormDataConsumer>
          </CardContent>
        </Card>

        <Box mt="1em" />

        <Card style={styles}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Management
            </Typography>
            <Box
              display={{
                xs: "block",
                sm: "flex",
              }}
              style={styles}
            >
              <Box
                flex={1}
                ml={{
                  xs: 0,
                  sm: "0.5em",
                }}
              >
                <NumberInput
                  label="Management Fee %"
                  source="commissionPercent"
                  validate={validatePercent}
                />
              </Box>
              <Box
                flex={2}
                mr={{
                  xs: 0,
                  sm: "0.5em",
                }}
              >
                <DateInput
                  label="Management Start Date"
                  source="managementStartDate"
                  validate={[required()]}
                />
              </Box>
            </Box>
            <Box
              flex={1}
              ml={{
                xs: 0,
                sm: "0.5em",
              }}
            >
              <Box
                flex={1}
                ml={{
                  xs: 0,
                  sm: "0.5em",
                }}
              >
                <ReferenceInput
                  alwaysOn
                  label="Managed By"
                  source="managingEntityId"
                  reference="Companies"
                  fullWidth
                  validate={[required()]}
                  filterToQuery={(searchTerm: string) => ({
                    searchTerm,
                  })}
                >
                  <AutocompleteInput optionText={formatCompanyOptionText} />
                </ReferenceInput>
              </Box>
            </Box>
            <Box
              flex={1}
              ml={{
                xs: 0,
                sm: "0.5em",
              }}
            >
              <OwnersForm />
            </Box>
          </CardContent>
        </Card>

        <Box mt="1em" />
        <BooleanInput
          source="createTenancy"
          label="Create Tenancy"
          defaultValue
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.createTenancy) {
              return <CreateTenancyCard style={styles} />;
            }

            return null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const OwnersForm = () => {
  const ownerFormStyles = useOwnerFormStyles();
  return (
    <ArrayInput
      label="Owners"
      source="owners"
      defaultValue={[
        {
          person: undefined,
          shares: 1,
        },
      ]}
    >
      <SimpleFormIterator classes={ownerFormStyles}>
        <LegalEntityInput
          label="Legal Entity"
          source="id"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source="shares"
          defaultValue={0}
          label="Shares owned"
          validate={validateSharesOwned}
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const validateSharesOwned = [
  required(),
  number(),
  minValue(0),
  regex(/^\d+$/, "Must be a whole number"),
];
const useOwnerFormStyles = makeStyles({
  form: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    "& .ra-input:nth-of-type(1)": {
      flex: 2,
      display: "flex",
      marginRight: "0.5em",
      marginLeft: "0.5em",
    },
  },
  index: {
    display: "none",
  },
});
export { ManagementCreate };
