import { formatMoney } from "../ui/deprecated";

/**
 * @deprecated Use `RecurringDate` from `@ailo/date`
 */
export function formatRentPaymentSchedule(params: {
  amountInCents: number;
  period: any;
}): string {
  const rentAmount = formatMoney({
    cents: params.amountInCents,
  });
  const rentFrequency = formatRentPeriod(params.period);
  return `${rentAmount} ${rentFrequency}`;
}

const formatRentPeriod = (
  rentFrequency: keyof typeof RentFrequency
): string => {
  switch (rentFrequency) {
    case RentFrequency.Daily:
      return "/day";

    case RentFrequency.Weekly:
      return "/wk";

    case RentFrequency.Fortnightly:
      return "/fn";

    case RentFrequency.Monthly:
      return "/mo";

    default:
      return "";
  }
};

const RentFrequency = {
  Daily: "daily",
  Weekly: "weekly",
  Fortnightly: "fortnightly",
  Monthly: "monthly",
};
