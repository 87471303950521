import React from "react";
import { IconButton } from "@material-ui/core";
import { EditIcon } from "../../icons/editIcon";

/**
 * @deprecated Use <Button /> directly
 */
const EditButton = ({ onClick }: any) => (
  <IconButton aria-label="Edit" onClick={onClick}>
    <EditIcon />
  </IconButton>
);

export { EditButton };
