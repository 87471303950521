import React from "react";
import { Error, Loading } from "react-admin";
import { GET_WALLET_AUTO_PAYMENT_SETTINGS } from "../../api/resources/Wallet";
import { useQuery } from "../../common/api/useQuery";
import { Wallet } from "../../resourceViews";
import { AutoPaymentSettingsCard } from "./AutoPaymentSettingsCard";

/**
 * Should only be used for Management Folio or Agency wallets
 * since only these can set up auto payment (auto withdraw & auto pay bill)
 */
export function AutoPaymentSettingsCardContainer({
  walletOwnerReference,
  legalEntityAilorn,
  title,
  addAutoWithdrawPlanSettings,
  walletType,
}: {
  walletOwnerReference: string;
  /**
   * @see {@link AutoPaymentSettingsCard}
   */
  legalEntityAilorn: string;
  title?: string;
  walletType: "ManagementFolio" | "Agency";
  addAutoWithdrawPlanSettings?: {
    walletName?: string;
    transactionDescription?: string;
  };
}): React.ReactElement {
  const { data: wallet, loading, error } = useQuery({
    type: GET_WALLET_AUTO_PAYMENT_SETTINGS,
    resource: Wallet,
    payload: {
      walletOwnerAilorn: walletOwnerReference,
    },
  });

  if (loading || !wallet) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <AutoPaymentSettingsCard
      title={title}
      legalEntityAilorn={legalEntityAilorn}
      walletOwnerReference={walletOwnerReference}
      allowEdit
      autoWithdrawPlans={wallet.autoWithdrawPlans?.items ?? []}
      addAutoWithdrawPlanSettings={{
        walletId: wallet.id,
        ...addAutoWithdrawPlanSettings,
      }}
      // Agencies can't set up auto pay bill
      autoPayLiabilityStatus={
        walletType === "Agency"
          ? undefined
          : wallet?.autoPayLiabilityStatus ?? undefined
      }
    />
  );
}
