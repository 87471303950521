import React from "react";
import { DetailCard } from "../../common/ui/cards";
import { WalletOwnerTransactionList } from "../WalletEntry";

export const WalletTransactionHistoryCard = ({
  walletOwnerReference,
}: {
  walletOwnerReference: string;
}): React.ReactElement => {
  return (
    <DetailCard
      title="Transaction History"
      contents={
        <WalletOwnerTransactionList ownerAiloRN={walletOwnerReference} />
      }
    />
  );
};
