import React from "react";
import { AddNewButton } from "../../../common/ui/buttons";
import { AddAutoWithdrawPlanDialog } from "./AddAutoWithdrawPlanDialog";

type Props = {
  legalEntityAiloRN: string;
  walletId: string;
  /**
   * Helper text shown on the form to identify the type of wallet.
   *
   * E.g. Folio Wallet, Personal Wallet.
   *
   * Not provided as a parameter in GQL mutation
   */
  walletName?: string;
  transactionDescription?: string;
};

const AddAutoWithdrawPlanButton = ({
  legalEntityAiloRN,
  walletId,
  walletName,
  transactionDescription,
}: Props): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AddNewButton onClick={onClick}>Add Auto Transfer Plan</AddNewButton>
      <AddAutoWithdrawPlanDialog
        open={open}
        onClose={onClose}
        legalEntityAiloRN={legalEntityAiloRN}
        walletId={walletId}
        walletName={walletName}
        transactionDescription={transactionDescription}
      />
    </>
  );
};

export { AddAutoWithdrawPlanButton };
