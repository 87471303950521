import React from "react";
import { Error, GET_LIST, Loading, SelectInput } from "react-admin";
import { FeeEvent as FeeEventResource } from "../../api";
import { useQuery } from "../../common/api/useQuery";

export const FeeEventInput = (): React.ReactElement | null => {
  const { data, loading, error } = useQuery({
    resource: FeeEventResource,
    type: GET_LIST,
    payload: {},
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (data == null || data.length === 0) {
    return null;
  }

  const choices = data.map((event) => ({
    id: event.type,
    name: event.longDescription,
  }));

  return (
    <SelectInput
      label="Event type"
      source="event"
      choices={choices}
      initialValue={choices[0].id}
      disabled={choices.length === 1}
      fullWidth
    />
  );
};
