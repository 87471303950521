import { AiloRN, services } from "@ailo/ailorn";
import gql from "graphql-tag";
import { CREATE, GET_LIST, GET_ONE, UPDATE } from "react-admin";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
  parseSortParam,
  removeWhitespace,
} from "../../common";
import { Maybe, OrganisationType } from "../../graphql/types.generated";
import {
  AbrCompanyLookupQuery,
  AbrCompanyLookupQueryVariables,
  CompaniesQuery,
  CompaniesQueryVariables,
  CompanyQuery,
  CompanyQueryVariables,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  UpdateCompanyProfileDetailsMutation,
  UpdateCompanyProfileDetailsMutationVariables,
} from "./companiesClient.generated";

const BaseCompanyFragment = gql`
  fragment BaseCompanyFragment on Company {
    __typename
    id
    ailoRN
    registeredEntityId
    registeredEntityName
    emailAddress
    accountsEmail
    address1
    address2
    suburb
    state
    postcode
    country
    timezone
    eulaSignedAt
    eulaSignedBy {
      firstName
    }
    wallets {
      id
      name
      totalBalance {
        cents
      }
      availableBalance {
        cents
      }
      createdAt
      idempotencyKey
    }
    organisation {
      id
      name
      orgType
      legalEntities {
        id
        ... on Person {
          firstName
          lastName
        }
        ... on Company {
          registeredEntityName
        }
      }
      memberships {
        organisation {
          id
          legalEntities {
            id
          }
        }
        member {
          id
          firstName
          lastName
        }
        role {
          id
          name
        }
      }
      activeSubscriptions(cursor: {}) {
        pageInfo {
          total
          hasMore
          nextCursor
        }
        items {
          id
          plan {
            id
            price {
              cents
            }
          }
          startsOn
          endsOn
        }
      }
      availableFeatures {
        id
        description
      }
    }
    owner {
      id
      firstName
      lastName
    }
    externalTrustAccounts {
      id
      secretArn
      disabled
    }
    migrationTask {
      status
      startedBy {
        ... on User {
          id
          ailoRN
          person {
            __typename
            id
            firstName
            lastName
          }
        }
        ... on System {
          ailoRN
        }
      }
      startedAt
      finishedAt
      lastError
    }
  }
`;

export const CompanyClientDefinition = {
  [GET_LIST]: (params: {
    pagination: PaginationParams;
    sort:
      | { field?: string | null; order?: "ASC" | "DESC" | null }
      | null
      | undefined;
    filter: { searchTerm?: string; orgType?: OrganisationType };
  }) => ({
    query: gql`
      query companies(
        $cursor: String
        $pageSize: Int
        $sort: String
        $searchTerm: String
        $orgType: OrganisationType
      ) {
        companies(
          pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
          search: $searchTerm
          orgType: $orgType
        ) {
          pageInfo {
            hasMore
            nextCursor
            total
          }
          items {
            id
            registeredEntityId
            registeredEntityName
            address1
            address2
            suburb
            state
            postcode
            country
            eulaSignedAt
            eulaSignedBy {
              firstName
            }
            organisation {
              id
              name
              orgType
            }
            owner {
              id
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: <CompaniesQueryVariables>{
      ...buildPaginationVariables(params.pagination),
      sort: parseSortParam(params.sort),
      searchTerm: params.filter.searchTerm,
      orgType: params.filter.orgType,
    },
    parseResponse: (response: GraphQLResponse<CompaniesQuery>) => {
      const { companies } = response.data;
      const { pageInfo, items } = companies!;
      const { total, nextCursor, hasMore } = pageInfo;
      return {
        data: items,
        total,
        nextCursor,
        hasMore,
      };
    },
  }),

  [GET_ONE]: (params: { id: any }) => ({
    query: gql`
      query company($id: ID!, $legalEntityAiloRN: AiloRN!) {
        company(id: $id) {
          ...BaseCompanyFragment
        }

        listBankAccountBlacklistByOwner(owner: $legalEntityAiloRN) {
          id
          ownerLegalEntity
          bsb
          accountNumber
          description
        }
      }
      ${BaseCompanyFragment}
    `,
    variables: <CompanyQueryVariables>{
      id: String(params.id),
      legalEntityAiloRN: AiloRN.of(
        services.AuthZ.legalEntity,
        String(params.id)
      ).toString(),
    },
    parseResponse: (response: GraphQLResponse<CompanyQuery>) => {
      const data = {
        ...response.data.company!,
        ...{
          bankAccountBlackLists: response.data.listBankAccountBlacklistByOwner!,
        },
      };
      return {
        data,
      };
    },
  }),

  [CREATE]: (params: { data: Record<string, any> }) => ({
    query: gql`
      mutation createCompany(
        $ownerId: String!
        $registeredEntityId: String!
        $registeredEntityName: String!
        $emailAddress: String
        $accountsEmail: [String!]
        $address1: String
        $address2: String
        $suburb: String
        $state: String
        $postcode: String
        $country: String
        $organisationId: ID
        $organisation: OrganisationServiceInput
      ) {
        createCompany(
          companyDetails: {
            ownerId: $ownerId
            registeredEntityId: $registeredEntityId
            registeredEntityName: $registeredEntityName
            emailAddress: $emailAddress
            accountsEmail: $accountsEmail
            address1: $address1
            address2: $address2
            suburb: $suburb
            state: $state
            postcode: $postcode
            country: $country
            organisationId: $organisationId
          }
          organisationDetails: $organisation
        ) {
          id
          registeredEntityId
          registeredEntityName
          emailAddress
          accountsEmail
          address1
          address2
          suburb
          state
          postcode
          country
          organisation {
            id
            name
          }
          owner {
            id
            firstName
            lastName
          }
        }
      }
    `,
    variables: {
      ...(<CreateCompanyMutationVariables>removeWhitespace(params.data)),
      accountsEmail: params.data.accountsEmail?.filter(
        (e: string) => e.length > 0
      ),
    },
    parseResponse: (response: GraphQLResponse<CreateCompanyMutation>) => ({
      data: response.data.createCompany,
    }),
  }),

  [UPDATE]: (params: {
    id: string | number;
    registeredEntityId?: Maybe<string>;
    registeredEntityName?: Maybe<string>;
    address1?: Maybe<string>;
    address2?: Maybe<string>;
    emailAddress?: Maybe<string>;
    accountsEmail?: Maybe<string[]>;
    suburb?: Maybe<string>;
    state?: Maybe<string>;
    postcode?: Maybe<string>;
    country?: Maybe<string>;
    timezone?: Maybe<string>;
  }) => ({
    query: gql`
      mutation updateCompanyProfileDetails(
        $input: UpdateCompanyProfileDetailsInput!
      ) {
        updateCompanyProfileDetails(input: $input) {
          ...BaseCompanyFragment
        }
      }
      ${BaseCompanyFragment}
    `,
    variables: <UpdateCompanyProfileDetailsMutationVariables>{
      input: {
        id: AiloRN.of(services.AuthZ.legalEntity, params.id).toString(),
        registeredEntityId: params.registeredEntityId,
        registeredEntityName: params.registeredEntityName,
        emailAddress: params.emailAddress,
        accountsEmail: params.accountsEmail?.filter((e) => e.length > 0),
        address1: params.address1,
        address2: params.address2,
        suburb: params.suburb,
        state: params.state,
        postcode: params.postcode,
        country: params.country,
        timezone: params.timezone,
      },
    },
    parseResponse: (
      response: GraphQLResponse<UpdateCompanyProfileDetailsMutation>
    ) => {
      return {
        data: response.data.updateCompanyProfileDetails,
      };
    },
  }),

  abr_lookup: (params: { abnOrAcn: string }) => ({
    query: gql`
      query abrCompanyLookup($abnOrAcn: String!) {
        abrCompanyLookup(abnOrAcn: $abnOrAcn) {
          registeredEntityName
          abn
          acn
          addressState
          addressPostcode
          __typename
        }
      }
    `,
    variables: <AbrCompanyLookupQueryVariables>{
      abnOrAcn: params.abnOrAcn,
    },
    parseResponse: (response: GraphQLResponse<AbrCompanyLookupQuery>) => {
      return {
        data: response.data.abrCompanyLookup,
      };
    },
  }),
};

export const companiesClient = createClientFromDefinition(
  CompanyClientDefinition
);
