import React from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  Filter,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { List } from "../../common";
import {
  LegalEntitiesField,
  OrganisationActiveSubscriptionsField,
} from "../../common";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <TextInput label="Search" source="searchTerm" alwaysOn autoFocus />
    <SelectInput
      label="Type"
      source="orgType"
      choices={[
        {
          id: "Agency",
          name: "Agency",
        },
        {
          id: "Generic",
          name: "Consumer",
        },
      ]}
      alwaysOn
    />
  </Filter>
);

const CustomerList = (props: any) => {
  return (
    <List
      {...props}
      title="Customers"
      bulkActionButtons={false}
      filters={<SearchFilter />}
    >
      <Datagrid rowClick="show">
        <TextField label="Name" source="name" />
        <ChipField label="Type" source="orgType" />
        <DateField label="Created" source="createdAt" showTime />
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; }' is not assignable to typ... Remove this comment to see the full error message */}
        <LegalEntitiesField label="Legal Entities" />
        <OrganisationActiveSubscriptionsField label="Subscriptions" />
      </Datagrid>
    </List>
  );
};

export { CustomerList };
