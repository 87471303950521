import React from "react";
import { ManagementInput } from "../inputs";

export function ManagementFilter(props: any) {
  return (
    <ManagementInput
      allowEmpty
      emptyText="Property Address"
      {...props}
      options={{
        InputProps: {
          // Disable Chrome address autocomplete dropdown
          // ( https://github.com/marmelab/react-admin/issues/5620#issuecomment-773773732 )
          autoComplete: "off",
        },
      }}
    />
  );
}
