import { Tab, Tabs, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Info } from "@material-ui/icons";
import { parse, stringify } from "query-string";
import React, { useCallback, useMemo } from "react";
import {
  Labeled,
  ShowProps,
  TitleForRecord,
  useShowController,
} from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { isPresent } from "ts-is-present";
import { Colors } from "../../../common";
import { sortTenancies } from "../../../features/Tenancy";
import { ManagementSwitcher, TenancySwitcher } from "../components";
import { GenerateTenancyLedgerStatementButton } from "./GenerateTenancyLedgerStatementButton/GenerateTenancyLedgerStatementButton";
import { PropertyShowRecord } from "./types";
import { PropertyTitleCard } from "./PropertyTitleCard";
import { SendStatementButton } from "./SendStatementButton/SendStatementButton";
import { ManagementTab, TenancyTab } from "./tabs";

const TABS = [
  { label: "Management", value: "Management" },
  { label: "Tenancy", value: "Tenancy" },
] as const;

export function PropertiesShow(props: ShowProps): React.ReactElement | null {
  const {
    defaultTitle,
    loaded,
    loading,
    record: property,
  } = useShowController<PropertyShowRecord>(props);
  const history = useHistory();
  const location = useLocation();
  const {
    management: selectedManagementId,
    tenancy: selectedTenancyId,
    tab: searchQueryTab,
  } = useMemo(() => parse(location.search), [location.search]);

  const selectedTabValue =
    TABS.find((tab) => tab.value === searchQueryTab)?.value ?? TABS[0].value;

  const handleManagementSwitcherChange = useCallback(
    (nextManagementId) => {
      history.push({
        search: nextManagementId
          ? `?${stringify({
              tab: selectedTabValue,
              management: nextManagementId,
            })}`
          : undefined,
      });
    },
    [history, selectedTabValue]
  );

  const handleTenancySwitcherChange = useCallback(
    (nextTenancyId) => {
      history.push({
        search: nextTenancyId
          ? `?${stringify({
              tab: selectedTabValue,
              management: selectedManagementId,
              tenancy: nextTenancyId,
            })}`
          : undefined,
      });
    },
    [history, selectedManagementId, selectedTabValue]
  );

  const handleTabChange = useCallback(
    (nextTab) => {
      history.push({
        search: stringify({
          tab: nextTab,
          ...(selectedManagementId && { management: selectedManagementId }),
          ...(selectedTenancyId && { tenancy: selectedTenancyId }),
        }),
      });
    },
    [history, selectedManagementId, selectedTenancyId]
  );

  if (!loaded || !!loading || !property) {
    return null;
  }

  const management =
    property.managements.find((m) => m.id === selectedManagementId) ||
    property.managements[property.managements.length - 1];
  const defaultTenancy = management.allTenancies
    ? sortTenancies(management.allTenancies.filter(isPresent))[0]
    : undefined;
  const tenancy =
    management.allTenancies?.find((m) => m?.id === selectedTenancyId) ||
    defaultTenancy;

  const moreThanOneTenancy =
    management.allTenancies && management.allTenancies.length > 1;
  const moreThanOneManagement = property.managements.length > 1;

  return (
    <>
      <TitleForRecord record={property} defaultTitle={defaultTitle} />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          grid: "8px",
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <span style={{ marginRight: 16 }}>
          {tenancy && (
            <GenerateTenancyLedgerStatementButton tenancy={tenancy} />
          )}
        </span>
        {management && <SendStatementButton management={management} />}
      </div>

      <Grid container spacing={2}>
        <Grid container item xs={12}>
          {!!property.managements?.length && (
            <Grid item xs={6}>
              <Labeled label="Management">
                <ManagementSwitcher
                  value={management.id}
                  managements={property.managements}
                  onChange={handleManagementSwitcherChange}
                />
              </Labeled>
            </Grid>
          )}
          {!!management.allTenancies?.length && (
            <Grid item xs={6}>
              <Labeled label="Tenancy">
                <TenancySwitcher
                  value={tenancy?.id}
                  tenancies={management.allTenancies}
                  onChange={handleTenancySwitcherChange}
                />
              </Labeled>
            </Grid>
          )}
        </Grid>

        {(moreThanOneManagement || moreThanOneTenancy) && (
          <Grid item xs={12}>
            <div
              style={{
                border: `2px solid ${Colors.STATUS.INFO}`,
                backgroundColor: "#fff",
                borderRadius: "25px",
                padding: "9px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
            >
              <Info style={{ fill: Colors.STATUS.INFO, marginRight: 7 }} />
              <Typography>
                Please ensure that the correct&nbsp;
                {moreThanOneManagement && "management "}
                {moreThanOneManagement && moreThanOneTenancy && "and/or "}
                {moreThanOneTenancy && "tenancy "}
                is selected before making changes.
              </Typography>
            </div>
          </Grid>
        )}

        <Grid item xs={12}>
          <PropertyTitleCard
            property={property}
            management={management}
            tenancy={tenancy}
          />
        </Grid>

        <Grid item xs={12}>
          <Tabs
            value={selectedTabValue}
            onChange={(_event, newTabValue) => handleTabChange(newTabValue)}
          >
            {TABS.map((tab, index) => (
              <Tab label={tab.label} value={tab.value} key={index} />
            ))}
          </Tabs>
        </Grid>

        {selectedTabValue === "Management" ? (
          <ManagementTab
            property={property}
            management={management}
            tenancy={tenancy}
          />
        ) : selectedTabValue === "Tenancy" ? (
          <TenancyTab management={management} tenancy={tenancy} />
        ) : null}
      </Grid>
    </>
  );
}
