import currency from "currency.js";

/**
 * @deprecated Use `Money` from `@ailo/money` instead.
 */
export function formatMoney(
  amount: {
    cents: number;
  },
  options: {
    withSign?: boolean;
    withSeparator?: boolean;
  } = {
    withSign: true,
    withSeparator: false,
  }
): string {
  const plusMinusSign = amount.cents < 0 ? "-" : "";
  const currencySymbol = options.withSign ? "$" : "";
  const separator = options.withSeparator ? "," : "";
  return (
    plusMinusSign +
    currency(Math.abs(amount.cents), {
      symbol: currencySymbol,
      separator,
    })
      .divide(100)
      .format()
  );
}
