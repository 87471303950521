import { MutationOptions as RAMutationOptions } from "ra-core/esm/dataProvider/useMutation";
import { useCallback } from "react";
import {
  MutationMode,
  // eslint-disable-next-line no-restricted-imports
  useMutation as useRAMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  OperationData,
  OperationParams,
  OperationType,
  TypedResourceName,
} from "../../api";

type MutationQuery<
  TResourceName extends string = string,
  TOperationType extends string | number | symbol = string
> = {
  resource: TResourceName;
  type: TOperationType;
};

export type MutationOptions<TData> = Omit<
  RAMutationOptions,
  "onSuccess" | "onFailure" | "mutationMode" | "undoable"
> & {
  /**
   * @default true
   */
  refreshOnSuccess?: boolean;

  /**
   * @default false
   */
  muteNotifications?: boolean;

  /**
   * @default pessimistic
   */
  mutationMode?: MutationMode;

  successMessage?: string;

  onSuccess?(response: { data: TData }): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFailure?(error?: any): void;
};

export type UseMutationValue<TPayload, TData> = [
  (
    query?: { payload: TPayload },
    options?: Partial<MutationOptions<TData>>
  ) => void | Promise<void>,
  {
    data?: TData;
    total?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    loading: boolean;
    loaded: boolean;
  }
];

/**
 * Same as [react-admin's `useMutation`](https://marmelab.com/react-admin/Actions.html#usemutation-hook),
 * but:
 * - has type definitions for mutation payload and response data,
 * - shows error notification on failure,
 * - shows success notification on success,
 * - refreshes data on success.
 *
 * @example
 * ```
 * const [transferFunds, { error }] = useMutation<TransferFundsMutationVariables, TransferFundsMutation>(
 *   {
 *     type: "withdraw_funds",
 *     resource: "Withdrawals",
 *   },
 *   {
 *     successMessage: `Funds have been withdrawn`,
 *   }
 * );
 *
 * const submit = (formData: any) => {
 *   transferFunds({
 *     payload: formData,
 *   });
 * };
 * ```
 */
export function useMutation<
  TResourceName extends TypedResourceName,
  TOperationType extends OperationType<TResourceName>
>(
  query: MutationQuery<TResourceName, TOperationType>,
  options?: MutationOptions<OperationData<TResourceName, TOperationType>>
): UseMutationValue<
  OperationParams<TResourceName, TOperationType>,
  OperationData<TResourceName, TOperationType>
>;

export function useMutation<TPayload = unknown, TData = unknown>(
  query: MutationQuery,
  options?: MutationOptions<TData>
): UseMutationValue<TPayload, TData>;
export function useMutation<TPayload = unknown, TData = unknown>(
  query: {
    resource: string;
    type: string;
  },
  options: MutationOptions<TData> = {}
): UseMutationValue<TPayload, TData> {
  const notify = useNotify();
  const refresh = useRefresh();

  const [raMutate, state] = useRAMutation();

  const mutate = useCallback(
    (
      callTimeQuery?: { payload: TPayload },
      callTimeOptions?: Partial<MutationOptions<TData>>
    ) => {
      const raQuery = { payload: {}, ...query, ...callTimeQuery };
      const mergedOptions = {
        ...options,
        ...callTimeOptions,
      };
      const mute = !!mergedOptions.muteNotifications;
      const raOptions: RAMutationOptions = {
        ...mergedOptions,
        mutationMode: mergedOptions?.mutationMode ?? "pessimistic",

        onSuccess(response: { data: TData }) {
          const error =
            response.data && "error" in response.data
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (response.data as any).error
              : undefined;
          if (error) {
            if (!mute) {
              notify(`Operation ${query.type} has failed. Error: ${error}`);
            }
            mergedOptions?.onFailure?.(error);
            return;
          }

          if (mergedOptions?.refreshOnSuccess ?? true) {
            refresh();
          }

          if (!mute) {
            notify(
              mergedOptions?.successMessage ??
                `Operation ${query.type} has succeeded`
            );
          }
          mergedOptions?.onSuccess?.(response);
        },

        onFailure(error) {
          if (!mute) {
            notify(`Operation ${query.type} has failed. Error: ${error}`);
          }
          mergedOptions?.onFailure?.(error);
        },
      };

      return raMutate(raQuery, raOptions);
    },
    // Doing `JSON.stringify({ query, options })` because react-admin does the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [raMutate, notify, JSON.stringify({ options, query }), refresh]
  );

  return [mutate, state];
}
