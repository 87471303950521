import {
  Button as ButtonInput,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField as TextFieldInput,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import currency from "currency.js";
import { keyBy, values } from "lodash";
import React, { useCallback, useState } from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
  useShowController,
} from "react-admin";
import type { Management, Ownership } from "../../api/deprecatedTypes";
import { TenancyAgreement } from "../../api/graphql/types.generated";
import { Property } from "../../api/ResourceName";
import {
  AiloRNField,
  AmountField,
  CopyableTextField,
  DeprecatedIdField,
  IdField,
  LinkField,
} from "../../common";
import { CompanyLinkField } from "../../common/fields/deprecated/CompanyLinkField";
import { PersonLinkField } from "../../common/fields/deprecated/PersonLinkField";
import { nestedCardStyle } from "../../common/ui/cards";
import { formatPersonName } from "../../common/utils";
import { InvitePersonButton } from "../../features/User";
import { DeleteRentButton } from "./DeleteRentButton";
import { EditTenancyAgreementButton } from "./EditTenancyAgreementButton";
import { ManagementStatusField } from "./ManagementStatusField";
import { ManagementTerminationDetailsField } from "./ManagementTerminationDetailsField";
import PlanChangePreviewDialog from "./planChangePreviewDialog";
import { TenancyEndDateField } from "./TenancyEndDateField";
import { TenancyVoidedAtField } from "./TenancyVoidedAtField";
import { TenancyStatusField } from "./TenancyStatusField";
import { DeleteTenantshipButton } from "./DeleteTenantshipButton";
import { TenancyStartDate } from "./TenancyStartDate";
import { EditManagementAgreementButton } from "./EditManagementAgreementButton";

const ManagementTitle = ({ record }: { record: Management }) => {
  if (record.property && record.property.address) {
    const { address } = record.property;
    return [
      "Management / ",
      address.unitStreetNumber,
      address.streetName,
      address.suburb,
      address.state,
      address.postcode,
      address.country,
    ].join(" ");
  }

  return null;
};

const useStyles = makeStyles({
  heading: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: "bold",
  },
  singleLine: {
    whiteSpace: "nowrap",
  },
});

const ConsumerInviteField = ({ record }: Record<string, any>) => {
  const parsedMetadata = JSON.parse(record.metadata);
  return (
    <span>
      {[formatPersonName(parsedMetadata), parsedMetadata.email].join(" ")}
    </span>
  );
};

const PublishButton = ({ record }: { record: Management }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [publishManagement, { loading }] = useMutation(
    {
      type: "publish",
      resource: "Managements",
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: ({ data }) => {
        redirect(`/Managements/${data}/show`);
        notify("Management published");
        refresh();
      },
      onFailure: (error) =>
        notify(`Unable to publish management: ${error.message}`, "error"),
    }
  );
  return (
    <Button
      color="primary"
      variant="outlined"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ color: "primary"; variant: "outlined"; wid... Remove this comment to see the full error message
      width={6}
      onClick={publishManagement}
      disabled={loading}
      label="Publish"
    />
  );
};

const ManagementShowActions = ({ data }: { data: Management }) => {
  return data ? (
    <TopToolbar>
      <Button
        component={Link}
        to={`/${Property}/${data.property.id}/show?management=${data.id}`}
        label="Property view"
        style={{
          marginRight: 8,
        }}
      />
      <PublishButton record={data} />
    </TopToolbar>
  ) : null;
};

const InviteOwnerButton = ({ record }: any) => {
  if (!record.owner) return null;
  const owner = record.owner.owner || record.owner; // company owner or owner of a property

  return <InvitePersonButton person={owner} />;
};

const InviteTenantButton = ({ record }: any) => {
  if (!record.tenant) return null;
  const tenant = record.tenant.owner || record.tenant; // company owner or a tenant

  return <InvitePersonButton person={tenant} />;
};

const ManagementShow = (props: any) => {
  const classes = useStyles();
  const {
    loaded: managementLoaded,
    record: managementRecord,
  } = useShowController(props);
  return (
    <Show
      {...props}
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'record' is missing in type '{}' but requ... Remove this comment to see the full error message
      title={<ManagementTitle />}
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'data' is missing in type '{}' but requir... Remove this comment to see the full error message
      actions={<ManagementShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="Management">
          <CopyableTextField source="id" />
          <ManagementStatusField />
          <DateField
            label="First Published At"
            source="firstPublishedAt"
            showTime
          />
          <DateField label="Migrated At" source="migratedAt" showTime />
          <DateField label="Created" source="createdAt" showTime />
          <ManagementTerminationDetailsField />
          <FunctionField
            label="Managed By"
            render={({ managingEntity }: any) => {
              return managingEntity ? (
                <CompanyLinkField record={managingEntity} />
              ) : null;
            }}
          />
          <Divider />
          <Typography variant="body2" className={classes.heading}>
            Current Management Agreement
          </Typography>
          <CopyableTextField
            label="Id"
            source="currentManagementAgreement.id"
          />
          <DateField
            label="Start Date"
            source="currentManagementAgreement.startDate"
          />
          <DateField
            label="Fixed term end date (WA/SA/NT)"
            source="currentManagementAgreement.fixedTermEndDate"
          />
          <Divider />
          <ArrayField source="allManagementAgreements">
            <Datagrid>
              <CopyableTextField label="Id" source="id" />
              <DateField label="Created" source="createdAt" showTime />
              <DateField label="Start Date" source="startDate" />
              <DateField
                label="Fixed term end date (WA/SA/NT)"
                source="fixedTermEndDate"
              />
              <FunctionField
                render={(source: any) => {
                  return (
                    <EditManagementAgreementButton
                      managementAgreement={source}
                    />
                  );
                }}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Property">
          <FunctionField
            label="Id"
            render={(record: any) => (
              <DeprecatedIdField
                id={record.property.id}
                resource="Properties"
                copyable
                size="small"
              />
            )}
          />
          <TextField
            label="unit, street number"
            source="property.address.unitStreetNumber"
          />
          <TextField label="street name" source="property.address.streetName" />
          <TextField label="suburb" source="property.address.suburb" />
          <TextField label="state" source="property.address.state" />
          <TextField label="postcode" source="property.address.postcode" />
          <TextField label="country" source="property.address.country" />
        </Tab>
        <Tab label="Owners">
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'Record | undefined' is not assignable to typ... Remove this comment to see the full error message */}
          <OwnersTabContent management={managementRecord} />
        </Tab>
        <Tab label="Tenancy">
          <ArrayField source="allTenancies">
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ managementId: any; }' is not assignable to... Remove this comment to see the full error message */}
            <TenancyGrid managementId={props.id} />
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const OwnersTabContent = ({
  record,
  management,
}: {
  record: Ownership;
  management: Management;
}) => {
  const [
    showChangeOwnerSharesDialog,
    setShowChangeOwnerSharesDialog,
  ] = useState(false);
  // @ts-expect-error ts-migrate(2551) FIXME: Property 'owners' does not exist on type 'Ownershi... Remove this comment to see the full error message
  return !record.owners ? null : (
    <>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type 'Ownership' but r... Remove this comment to see the full error message */}
      <ArrayField source="owners" record={record}>
        <Datagrid>
          <FunctionField
            label="Owner"
            render={({ owner, consumerInvite }: any) => {
              if (owner && owner.__typename === "Company") {
                return <CompanyLinkField record={owner} />;
              }

              if (owner && owner.__typename === "Person") {
                return <PersonLinkField record={owner} />;
              }

              if (consumerInvite) {
                return <ConsumerInviteField record={consumerInvite} />;
              }
            }}
          />
          <DateField label="Start Date" source="startDate" />
          <DateField label="End Date" source="endDate" />
          <FunctionField
            label="Owner Shares"
            render={(record: any) => (
              <>
                {record.sharesOwned}{" "}
                <ChangeOwnerSharesInput
                  onClick={() => setShowChangeOwnerSharesDialog(true)}
                />
              </>
            )}
          />
          <FunctionField
            label=""
            render={(record: any) =>
              !record.isDraft ? <InviteOwnerButton record={record} /> : null
            }
          />
        </Datagrid>
      </ArrayField>
      {showChangeOwnerSharesDialog && (
        <ChangeOwnerSharesDialog
          management={management}
          onDismiss={() => setShowChangeOwnerSharesDialog(false)}
        />
      )}
    </>
  );
};

function ChangeOwnerSharesInput({ onClick }: { onClick: () => void }) {
  return (
    <Tooltip title="Edit Owner Shares">
      <IconButton color="primary" onClick={onClick}>
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

function ChangeOwnerSharesDialog({
  management,
  onDismiss,
}: {
  management: Management;
  onDismiss: () => void;
}) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [ownerSharesMap, setOwnerSharesMap] = useState(
    keyBy(
      management.owners?.map(({ ownerId, sharesOwned }) => ({
        ownerId,
        sharesOwned,
      })),
      "ownerId"
    )
  );
  const ownerShareChanged = useCallback(
    (ownerId: string, inputValue: string) => {
      let sharesOwned = Number.parseInt(inputValue);

      if (Number.isNaN(sharesOwned) || sharesOwned <= 0) {
        sharesOwned = 0;
      }

      setOwnerSharesMap({
        ...ownerSharesMap,
        [ownerId]: {
          ownerId,
          sharesOwned,
        },
      });
    },
    [ownerSharesMap]
  );
  const [mutate, { loading }] = useMutation(
    {
      resource: "Managements",
      type: "update_owner_shares",
      payload: {},
    },
    {
      onSuccess: () => {
        notify("Owner shares updated");
        refresh();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  const save = useCallback(() => {
    mutate({
      payload: {
        id: management.id,
        ownerShares: values(ownerSharesMap),
      },
    });
    onDismiss();
  }, [management.id, mutate, onDismiss, ownerSharesMap]);
  return (
    <Dialog
      open
      onClose={() => onDismiss()}
      style={{
        minWidth: 500,
      }}
    >
      <DialogTitle>Update Owner Shares</DialogTitle>
      <Table
        style={{
          padding: 5,
        }}
      >
        <TableBody>
          {management.owners?.map((o) => (
            <TableRow key={o.ownerId}>
              {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Company | Person | undefined' is... Remove this comment to see the full error message */}
              <TableCell>{formatPersonName(o.owner)}</TableCell>
              <TableCell
                style={{
                  width: "40%",
                }}
              >
                <TextFieldInput
                  value={ownerSharesMap[o.ownerId].sharesOwned}
                  onChange={(event) =>
                    ownerShareChanged(o.ownerId, event.target.value)
                  }
                  onClick={(event) => (event.target as any).select()}
                  size="small"
                  type="number"
                  variant="outlined"
                  required
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ButtonInput
        style={{
          padding: 15,
        }}
        color="primary"
        startIcon={<SaveIcon />}
        onClick={save}
      >
        Save
      </ButtonInput>
    </Dialog>
  );
}

const TenancyGrid = ({
  ids,
  data,
  record,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'managementId' does not exist on type '{ ... Remove this comment to see the full error message
  managementId,
  ...rest
}: {
  ids: string[];
  data: any;
  record: Record<any, any>;
}) => {
  const [
    changeOfPlanPreviewDialogDetails,
    setChangeOfPlanPreviewDialogDetails,
  ] = React.useState<any>(false);
  const classes = useStyles();
  return (
    <div>
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: "/Tenancy/create",
          state: {
            record: {
              managementId,
            },
          },
        }}
        label="Create New Tenancy"
      />
      {ids.map((id) => {
        const tenants = data[id].tenants
          .map(({ tenant, consumerInvite }: any) => {
            if (tenant) {
              return tenant.registeredEntityId
                ? tenant.registeredEntityName
                : formatPersonName(tenant);
            }

            if (consumerInvite) {
              return consumerInvite.id;
            }

            return undefined;
          })
          .join(",");
        const { startDate } = data[id];
        return (
          <Card key={id} style={nestedCardStyle}>
            <CardHeader
              title={
                <span>
                  Tenanted by {tenants} from {startDate}
                </span>
              }
            />
            <CardContent>
              <span>
                Id:
                <br />
                <CopyableTextField record={data[id]} source="id" />
              </span>
            </CardContent>
            <CardContent>
              <TenancyStatusField tenancy={data[id]} />
            </CardContent>
            <CardContent>
              <TenancyStartDate tenancy={data[id]} />
            </CardContent>
            <CardContent>
              <TenancyEndDateField tenancy={data[id]} />
            </CardContent>
            <CardContent>
              <TenancyVoidedAtField tenancy={data[id]} />
            </CardContent>
            <CardContent>
              <span>
                Created at:
                <br />
                <DateField record={data[id]} source="createdAt" showTime />
              </span>
            </CardContent>
            <CardContent>
              <Grid container>
                <Grid item xs>
                  <span>
                    Current Rent:
                    <br />
                    <FunctionField
                      record={data[id]}
                      render={(record: any) => {
                        return record && record.currentRent ? (
                          <span>
                            <AmountField record={record.currentRent} />{" "}
                            {record.currentRent.period}
                          </span>
                        ) : (
                          "—"
                        );
                      }}
                    />
                  </span>
                </Grid>
                <Grid item xs>
                  <span>
                    Current Rent Schedule:
                    <br />
                    <FunctionField
                      record={data[id]}
                      render={(record: any) => {
                        return record && record.currentRentSchedule ? (
                          <span>
                            <AmountField record={record.currentRentSchedule} />{" "}
                            {record.currentRentSchedule.period}
                          </span>
                        ) : (
                          "—"
                        );
                      }}
                    />
                  </span>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <span>
                Tenants:
                <Button
                  color="primary"
                  component={Link}
                  to={{
                    pathname: "/Tenantship/create",
                    state: {
                      record: {
                        tenancyId: data[id].id,
                        startDate: data[id].startDate,
                      },
                    },
                  }}
                  label="Add Tenant"
                />
                <br />
                <ArrayField
                  record={data[id]}
                  label="Current Tenants"
                  source="tenants"
                >
                  <Datagrid
                    style={{
                      width: 700,
                    }}
                  >
                    <FunctionField
                      label="Tenant"
                      render={({ tenant, consumerInvite }: any) => {
                        if (tenant && tenant.registeredEntityId) {
                          return <CompanyLinkField record={tenant} />;
                        }

                        if (tenant && tenant.firstName) {
                          return <PersonLinkField record={tenant} />;
                        }

                        if (consumerInvite) {
                          return (
                            <ConsumerInviteField record={consumerInvite} />
                          );
                        }
                      }}
                    />
                    {!data[id].isDraft ? <InviteTenantButton /> : null}
                    <DeleteTenantshipButton record={data[id]} />
                  </Datagrid>
                </ArrayField>
              </span>
            </CardContent>
            <CardContent>
              <span>
                Liability:
                <br />
                <LinkField
                  record={data[id]}
                  source="liability"
                  sourceResource="Liabilities"
                />
              </span>
            </CardContent>
            <CardContent>
              <span>
                Paid To Date:
                <br />
                <FunctionField
                  record={data[id]}
                  render={(record: any) => {
                    return record && record.liability ? (
                      <DateField
                        record={record.liability}
                        source="paidToDate"
                      />
                    ) : null;
                  }}
                />
              </span>
              <br />
              <span>
                Effective Paid To Date:
                <br />
                <FunctionField
                  record={data[id]}
                  render={(record: any) => {
                    return record && record.liability ? (
                      <DateField
                        record={record.liability}
                        source="effectivePaidToDate"
                      />
                    ) : null;
                  }}
                />
              </span>
            </CardContent>
            <CardContent>
              <span>
                Overdue amount($):
                <br />
                <FunctionField
                  record={data[id]}
                  label="Overdue Amount ($)"
                  render={(record: any) => {
                    return (
                      record &&
                      record.liability && (
                        <span>
                          {currency(record.liability.overdueAmount.cents)
                            .divide(100)
                            .format()}
                        </span>
                      )
                    );
                  }}
                />
              </span>
            </CardContent>
            <CardContent>
              <span>
                Tenancy Agreements:
                <br />
                <ArrayField
                  record={data[id]}
                  label="Tenancy Agreements"
                  source="tenancyAgreements.items"
                >
                  <Datagrid
                    classes={{
                      headerCell: classes.singleLine,
                    }}
                  >
                    <IdField source="id" />
                    <DateField source="startDate" />
                    <DateField source="fixedTermEndDate" />
                    <FunctionField<TenancyAgreement>
                      render={(tenancyAgreement) =>
                        tenancyAgreement && (
                          <EditTenancyAgreementButton
                            tenancyAgreement={tenancyAgreement}
                          />
                        )
                      }
                    />
                  </Datagrid>
                </ArrayField>
              </span>
            </CardContent>
            <CardContent>
              <span>
                Rents:
                <br />
                <ArrayField
                  record={data[id]}
                  label="Rents"
                  source="rents.items"
                >
                  <Datagrid
                    classes={{
                      headerCell: classes.singleLine,
                    }}
                  >
                    <AiloRNField source="id" showId />
                    <FunctionField
                      label="Amount ($)"
                      render={(record: any) => {
                        return <AmountField record={record} />;
                      }}
                    />
                    <TextField source="period" />
                    <DateField source="effectiveDate" />
                    <TextField source="category" />
                    <ArrayField source="rentSchedules">
                      <Datagrid
                        classes={{
                          headerCell: classes.singleLine,
                        }}
                      >
                        <AiloRNField source="id" showId />
                        <FunctionField
                          label="Amount ($)"
                          render={(record: any) => {
                            return <AmountField record={record} />;
                          }}
                        />
                        <TextField source="period" />
                        <DateField source="startDate" />
                        <TextField source="proRata" />
                      </Datagrid>
                    </ArrayField>
                    <DeleteRentButton record={data[id]} />
                  </Datagrid>
                </ArrayField>
              </span>
            </CardContent>
            <CardContent>
              <Button
                color="primary"
                component={Link}
                to={{
                  pathname: "/Rents/create",
                  state: {
                    record: {
                      managementId,
                      tenancyId: data[id].id,
                      effectiveDate: new Date(),
                      setsChargeDate: true,
                    },
                  },
                }}
                label="Add Rent"
              />
              <FunctionField
                record={data[id]}
                render={(record: any) => {
                  return (
                    data[id].liability && (
                      <Button
                        color="primary"
                        onClick={() =>
                          setChangeOfPlanPreviewDialogDetails({
                            isOpened: true,
                            record,
                            liabilityId: data[id].liability.id,
                          })
                        }
                        label="Preview Adjustment"
                      />
                    )
                  );
                }}
              />
            </CardContent>
            <CardActions
              style={{
                textAlign: "right",
              }}
            >
              <PlanChangePreviewDialog
                isOpened={changeOfPlanPreviewDialogDetails.isOpened}
                onClose={() =>
                  setChangeOfPlanPreviewDialogDetails({
                    isOpened: false,
                  })
                }
                record={changeOfPlanPreviewDialogDetails.record}
                liabilityId={changeOfPlanPreviewDialogDetails.liabilityId}
              />
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
};

TenancyGrid.defaultProps = {
  data: {},
  ids: [],
};

export { ManagementShow };
