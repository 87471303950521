const ignoredWarnings: (string | RegExp | (string | RegExp)[])[] = [
  "Warning: Missing translation for key:",
  'Warning: Please use `require("history").createBrowserHistory` instead of `require("history/createBrowserHistory")`. Support for the latter will be removed in the next major release.',
  "Warning: React.createFactory() is deprecated and will be removed in a future major release. Consider using JSX or use React.createElement() directly instead.",
  "Warning: Failed prop type: Invalid prop `component` supplied to `ForwardRef(ButtonBase)`. Expected an element type that can hold a ref. Did you accidentally provide a plain function component instead? For more information see https://material-ui.com/r/caveat-with-refs-guide",
  "Warning: Failed prop type: Invalid prop `page` of type `string` supplied to `ListView`, expected `number`",
  [
    "Warning: React does not recognize the `%s` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `%s` instead. If you accidentally passed it from a parent component, remove it from the DOM element.%s",
    "successMessage",
  ],
  "<Create successMessage> prop is deprecated, use the onSuccess prop instead.",
  "<Edit successMessage> prop is deprecated, use the onSuccess prop instead.",
  "dumb resource not found",
  [
    `Warning: Function components cannot be given refs. Attempts to access this ref will fail.`,
    "Check the render method of `ForwardRef(ButtonBase)`.",
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    undefined,
  ],
];

function wrapConsoleLog(method: "log" | "warn" | "error") {
  const originalFn = console[method];

  console[method] = function log(...args: any[]): void {
    const shouldIgnore = ignoredWarnings.some((patternOrPatterns) => {
      const patternsByArgIndex = Array.isArray(patternOrPatterns)
        ? patternOrPatterns
        : [patternOrPatterns];
      const matchedArgs = args.filter((arg, index) => {
        const pattern = patternsByArgIndex[index];

        if (pattern === undefined) {
          return true;
        }

        const message = arg instanceof Error ? arg.message : arg;

        if (typeof message !== "string") {
          return false;
        }

        return typeof pattern === "string"
          ? message.includes(pattern)
          : message.match(pattern);
      });
      return matchedArgs.length === args.length;
    });

    if (shouldIgnore) {
      return;
    }

    originalFn.apply(console, args);
  };
}

wrapConsoleLog("log");
wrapConsoleLog("warn");
wrapConsoleLog("error");

export {};
