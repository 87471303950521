import Box from "@material-ui/core/Box";
import React from "react";
import moment from "moment-timezone";
import { WaivedFeeChip } from "./waivedFeeChip";
import { ActiveFeeChip } from "./activeFeeChip";

export const FeeStatusAside = ({ item }: any) => {
  const { filterName, filterEntryEffectiveToInclusive } = item;
  return (
    <Box display="flex" alignItems="center">
      {filterName.includes("Waive") ? (
        <WaivedFeeChip
          label={`Fee Waived ${
            filterEntryEffectiveToInclusive
              ? `until ${moment(filterEntryEffectiveToInclusive)?.format(
                  "DD MMM YYYY"
                )}`
              : "indefinitely"
          }`}
        />
      ) : (
        <ActiveFeeChip label="Active" />
      )}
    </Box>
  );
};
