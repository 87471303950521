// Copy of https://raw.githubusercontent.com/marmelab/react-admin/1647909c77ba003be4a8c4d548fb6b3f66eb2e25/packages/ra-core/src/controller/useListController.ts ,
// but with our own `useListParams`.

/* eslint-disable no-shadow */

/* eslint-disable import/no-extraneous-dependencies */
import inflection from "inflection";
import get from "lodash/get";
import {
  CRUD_GET_LIST,
  defaultExporter,
  Exporter,
  useCheckMinimumRequiredProps,
  useGetList,
  useNotify,
  useRecordSelection,
  useResourceContext,
  useTranslate,
} from "ra-core";
import { SORT_ASC } from "ra-core/esm/reducer/admin/resource/list/queryReducer";
import { isValidElement, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useListParams } from "./useListParams";

const defaultSort = {
  field: "id",
  order: SORT_ASC,
};
const defaultData = {};

/**
 * Prepare data for the List view
 *
 * @param {Object} props The props passed to the List component.
 *
 * @return {Object} controllerProps Fetched and computed data for the List view
 *
 * @example
 *
 * import { useListController } from 'react-admin';
 * import ListView from './ListView';
 *
 * const MyList = props => {
 *     const controllerProps = useListController(props);
 *     return <ListView {...controllerProps} {...props} />;
 * }
 */
const useListController = (props: {
  basePath: any;
  exporter?: Exporter | undefined;
  filterDefaultValues: any;
  hasCreate: any;
  sort?: { field: string; order: string } | undefined;
  perPage?: 10 | undefined;
  filter: any;
  debounce?: 500 | undefined;
  syncWithLocation: any;
}) => {
  useCheckMinimumRequiredProps("List", ["basePath"], props);
  const {
    basePath,
    exporter = defaultExporter,
    filterDefaultValues,
    hasCreate,
    sort = defaultSort,
    perPage = 10,
    filter,
    debounce = 500,
    syncWithLocation,
  } = props;
  const resource = useResourceContext(props as any);

  if (!resource) {
    throw new Error(
      `<List> was called outside of a ResourceContext and without a resource prop. You must set the resource prop.`
    );
  }

  if (filter && isValidElement(filter)) {
    throw new Error(
      "<List> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer."
    );
  }

  const translate = useTranslate();
  const notify = useNotify();
  const [query, queryModifiers] = useListParams({
    resource,
    filterDefaultValues,
    sort,
    perPage,
    debounce,
    syncWithLocation,
  });
  const [selectedIds, selectionModifiers] = useRecordSelection(resource);

  /**
   * We want the list of ids to be always available for optimistic rendering,
   * and therefore we need a custom action (CRUD_GET_LIST) that will be used.
   */
  const { ids, total, error, loading, loaded } = useGetList(
    resource,
    {
      page: query.page,
      perPage: query.perPage,
    },
    {
      field: query.sort,
      order: query.order,
    },
    { ...query.filter, ...filter },
    {
      action: CRUD_GET_LIST,
      onFailure: (error: any) =>
        notify(
          typeof error === "string"
            ? error
            : error.message || "ra.notification.http_error",
          "warning",
          {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          }
        ),
    }
  );
  const data = useSelector<any>((state) =>
    get(state.admin.resources, [resource, "data"], defaultData)
  );
  // When the user changes the page/sort/filter, this controller runs the
  // useGetList hook again. While the result of this new call is loading,
  // the ids and total are empty. To avoid rendering an empty list at that
  // moment, we override the ids and total with the latest loaded ones.
  const defaultIds: any = useSelector<any>((state) =>
    get(state.admin.resources, [resource, "list", "ids"], [])
  );
  const defaultTotal = useSelector<any>((state): number =>
    get(state.admin.resources, [resource, "list", "total"])
  );
  // Since the total can be empty during the loading phase
  // We need to override that total with the latest loaded one
  // This way, the useEffect bellow won't reset the page to 1
  const finalTotal: any = typeof total === "undefined" ? defaultTotal : total;
  const finalIds: any = typeof total === "undefined" ? defaultIds : ids;
  const totalPages = useMemo(() => {
    return Math.ceil(finalTotal / query.perPage) || 1;
  }, [query.perPage, finalTotal]);
  useEffect(() => {
    if (
      query.page <= 0 ||
      (!loading && query.page > 1 && (finalIds || []).length === 0)
    ) {
      // Query for a page that doesn't exist, set page to 1
      queryModifiers.setPage(1);
    } else if (!loading && query.page > totalPages) {
      // Query for a page out of bounds, set page to the last existing page
      // It occurs when deleting the last element of the last page
      queryModifiers.setPage(totalPages);
    }
  }, [
    loading,
    query.page,
    finalIds,
    queryModifiers,
    total,
    totalPages,
    defaultIds,
  ]);
  const currentSort = useMemo(
    () => ({
      field: query.sort,
      order: query.order,
    }),
    [query.sort, query.order]
  );
  const resourceName = translate(`resources.${resource}.name`, {
    smart_count: 2,
    _: inflection.humanize(inflection.pluralize(resource)),
  });
  const defaultTitle = translate("ra.page.list", {
    name: resourceName,
  });
  return {
    basePath,
    currentSort,
    data,
    defaultTitle,
    displayedFilters: query.displayedFilters,
    error,
    exporter,
    filter,
    filterValues: query.filterValues,
    hasCreate,
    hideFilter: queryModifiers.hideFilter,
    ids: finalIds,
    loaded: loaded || defaultIds.length > 0,
    loading,
    onSelect: selectionModifiers.select,
    onToggleItem: selectionModifiers.toggle,
    onUnselectItems: selectionModifiers.clearSelection,
    page: query.page,
    perPage: query.perPage,
    resource,
    selectedIds,
    setFilters: queryModifiers.setFilters,
    setPage: queryModifiers.setPage,
    setPerPage: queryModifiers.setPerPage,
    setSort: queryModifiers.setSort,
    showFilter: queryModifiers.showFilter,
    total: finalTotal,
  };
};

export const injectedProps = [
  "basePath",
  "currentSort",
  "data",
  "defaultTitle",
  "displayedFilters",
  "error",
  "exporter",
  "filterValues",
  "hasCreate",
  "hideFilter",
  "ids",
  "loading",
  "loaded",
  "onSelect",
  "onToggleItem",
  "onUnselectItems",
  "page",
  "perPage",
  "refresh",
  "resource",
  "selectedIds",
  "setFilters",
  "setPage",
  "setPerPage",
  "setSort",
  "showFilter",
  "total",
  "totalPages",
  "version",
];

/**
 * Select the props injected by the useListController hook
 * to be passed to the List children need
 * This is an implementation of pick()
 */
export const getListControllerProps = (props: { [x: string]: any }) =>
  injectedProps.reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

/**
 * Select the props not injected by the useListController hook
 * to be used inside the List children to sanitize props injected by List
 * This is an implementation of omit()
 */
export const sanitizeListRestProps = (props: { [x: string]: any }) =>
  Object.keys(props)
    .filter((propName) => !injectedProps.includes(propName))
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});
export { useListController };
