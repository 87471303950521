import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import React from "react";
import { Colors, opacify } from "../../../../../common/ui/colors";

const useStyles = makeStyles({
  verified: {
    fontSize: "0.75em",
    color: Colors.TENANT_BLUE,
    backgroundColor: opacify(Colors.TENANT_BLUE, 0.2),
  },
  pending: {
    fontSize: "0.75em",
    color: Colors.STATUS.WARNING,
    backgroundColor: opacify(Colors.STATUS.WARNING, 0.2),
  },
});
export function KycVerifiedChip({
  status,
}: {
  status: boolean;
}): React.ReactElement {
  const classes = useStyles();
  return status ? (
    <Chip
      label="Verified"
      size="small"
      classes={{
        root: classes.verified,
      }}
    />
  ) : (
    <Chip
      label="Pending"
      size="small"
      classes={{
        root: classes.pending,
      }}
    />
  );
}
