import React, { useEffect } from "react";
import { RadioButtonGroupInput, RadioButtonGroupInputProps } from "react-admin";
import { useFormState, useForm } from "react-final-form"; //eslint-disable-line
import { FeeBlueprintChargeType } from "../../api/graphql/types.generated";

type FeeChargeTypeInputProps = Omit<
  RadioButtonGroupInputProps,
  "key" | "source" | "choices" | "initialValue"
>;

const chargeTypes = [
  {
    id: "FixedAmount",
    name: "Flat Fee",
  },
  {
    id: "OneWeekRentPercentage",
    name: "Based on rent",
  },
];

export const FeeChargeTypeInput = (
  props: FeeChargeTypeInputProps
): React.ReactElement => {
  const { change } = useForm();
  const { values } = useFormState();
  const { chargeType } = values;

  useEffect(() => {
    if (chargeType !== FeeBlueprintChargeType.FixedAmount) {
      change("fixedAmount", undefined);
    }
    if (chargeType !== FeeBlueprintChargeType.OneWeekRentPercentage) {
      change("oneWeekRentPercentage", undefined);
    }
  }, [chargeType, change]);

  return (
    <RadioButtonGroupInput
      key={chargeType}
      source="chargeType"
      choices={chargeTypes}
      initialValue={chargeTypes[0].id}
      {...props}
    />
  );
};
