import React from "react";
import { DetailCard } from "../../common/ui/cards";
import { Ownership, OwnershipsList } from "./OwnershipsList";

export const ManagementOwnersCard = ({
  ownerships,
}: {
  ownerships: Array<Ownership | null> | null | undefined;
}): React.ReactElement | null => {
  if (!ownerships) return null;
  return (
    <DetailCard
      title="Owners"
      contents={<OwnershipsList ownerships={ownerships} />}
    />
  );
};
