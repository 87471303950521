import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AuthService } from "../../auth";

class LoginPage extends React.Component<RouteComponentProps> {
  componentDidMount() {
    const auth = new AuthService();
    auth.login();
  }

  render() {
    return (
      <Card
        style={{
          margin: "2em",
        }}
      >
        <CardHeader title="Login" />
        <CardContent>Redirecting to Auth0...</CardContent>
      </Card>
    );
  }
}

export { LoginPage };
