import React from "react";
import { TextInput, required, number, minValue, maxValue } from "react-admin";
import Alert from "@material-ui/lab/Alert";
import { Address, formatAddress } from "@ailo/domain-helpers";
import { Edit, SimpleForm } from "../../common";
import { Property } from "../../api";
import { PercentInput } from "../../common";

export const ManagementFeeEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm
        toolbarOptions={{
          deleteButton: false,
        }}
        redirect={(
          _path: any,
          _id: any,
          data: { management: { property: { id: any }; id: any } }
        ) =>
          `/${Property}/${data.management.property.id}/show?management=${data.management.id}`
        }
      >
        <TextInput
          label="Organisation"
          source="management.managingEntity.organisation.name"
          disabled
        />
        <TextInput
          label="Property Address"
          source="management.property.address"
          format={(address: Address) =>
            address
              ? formatAddress(address, {
                  format: "street, suburb",
                })
              : ""
          }
          disabled
        />
        <TextInput
          label="Management Agreement Id"
          source="managementAgreement.id"
          disabled
        />
        <PercentInput
          label="Management Fee %"
          source="percent"
          format="percentage"
          validate={[
            required(),
            number(),
            minValue(0, "Must be 0% or more"),
            maxValue(1, "Must be 100% or less"),
          ]}
        />
        <Alert severity="info">
          This fee will apply to the next rent payment. If a payment is pending
          at the time of this change, the old fee will apply
        </Alert>
      </SimpleForm>
    </Edit>
  );
};
