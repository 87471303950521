import React from "react";
import { Box } from "@material-ui/core";
import { PaymentMethodBadges } from "./paymentMethodBadges";
import { RemovePaymentMethod } from "./removePaymentMethod";

export const PaymentMethodAction = ({
  paymentMethodId,
  isAutoPay,
  isAutoWithdraw,
  isDefaultIn,
  isDefaultOut,
  isDeleted,
  isHidden,
  edit,
}: any) => {
  return (
    <Box display="flex">
      <PaymentMethodBadges
        isAutoPay={isAutoPay}
        isAutoWithdraw={isAutoWithdraw}
        isDefaultIn={isDefaultIn}
        isDefaultOut={isDefaultOut}
        isDeleted={isDeleted}
        isHidden={isHidden}
      />
      {edit && !isDeleted && (
        <RemovePaymentMethod
          paymentMethodId={paymentMethodId}
          isAutoPay={isAutoPay}
          isAutoWithdraw={isAutoWithdraw}
        />
      )}
    </Box>
  );
};
