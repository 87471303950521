import { Alert } from "@material-ui/lab";
import React from "react";
import {
  FunctionField,
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useShowController,
} from "react-admin";
import {
  AmountField,
  CopyableTextField,
  DateTimeField,
  LinkField,
  useHasAuthScope,
} from "../../common";
import { WalletOwnerTransactionList } from "../../features/WalletEntry";
import { AiloBankCreditButton } from "./AiloBankCreditButton";
import { DirectWalletTransferButton } from "./DirectWalletTransferButton";
import { WriteOffButton } from "./WriteOffButton";
import { CreateOverdraftAllowanceButton } from "./CreateOverdraftAllowanceButton";

function Actions({ data: record }: { data: Wallet }) {
  const canWritePayments = useHasAuthScope("admin:payments:write");

  if (!canWritePayments || !record) {
    return null;
  }
  return (
    <TopToolbar>
      <DirectWalletTransferButton
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ color: string; label: string; liability: a... Remove this comment to see the full error message
        color="primary"
        label="Direct Wallet Transfer"
        fromWalletId={record.id}
      />
      <AiloBankCreditButton walletId={record.id} />
      <CreateOverdraftAllowanceButton walletId={record.id} />
      <WriteOffButton walletId={record.id} />
    </TopToolbar>
  );
}
type Wallet = {
  id: string;
  name: string;
  availableBalance: number;
  totalBalance: number;
};

export const WalletShow = (props: Record<string, any>) => {
  const { record } = useShowController(props);
  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'data' is missing in type '{}' but requir... Remove this comment to see the full error message
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <CopyableTextField label="ID" source="id" />
        <TextField label="Name" source="name" />
        <LinkField source="owner.reference" label="Owner" />
        <DateTimeField source="createdAt" />
        <FunctionField
          label="Available Balance ($)"
          render={(record: any) => {
            return <AmountField record={record.availableBalance} />;
          }}
        />
        <FunctionField
          label="Total Balance ($)"
          render={(record: any) => {
            return <AmountField record={record.totalBalance} />;
          }}
        />

        {record?.owner.reference && (
          <Labeled label="Entries" fullWidth>
            <>
              <Alert severity="warning" style={{ marginBottom: 8 }}>
                This list shows all entries that belong to this wallet owner.
                Which means it might be not just this wallet's transactions, but
                also transactions of their other wallets. Sorry, we'll fix it{" "}
                <a
                  href="https://ailo.atlassian.net/browse/META-418"
                  target="_blank"
                  rel="noreferrer"
                >
                  soon
                </a>
                .
              </Alert>
              <WalletOwnerTransactionList
                ownerAiloRN={record.owner.reference}
                style={{ maxHeight: 500 }}
              />
            </>
          </Labeled>
        )}
      </SimpleShowLayout>
    </Show>
  );
};
