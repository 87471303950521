import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import React from "react";
import { Colors, opacify } from "../colors";

type Style = Pick<
  React.CSSProperties,
  "margin" | "marginTop" | "marginBottom" | "marginLeft" | "marginRight"
>;

export function ListItem({
  title,
  children,
  style,
}: {
  title: any;
  children: React.ReactNode;
  style?: Style;
}) {
  return (
    <Card elevation={0} style={style}>
      <CardHeader
        style={{
          alignItems: "flex-start",
          padding: 0,
          paddingTop: 8,
          paddingBottom: 8,
          borderBottom: `1px solid ${opacify(Colors.SPACE_BLACK, 0.1)}`,
        }}
        title={title}
        subheader={children}
      />
    </Card>
  );
}
