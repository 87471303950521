import React from "react";
import { Button } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconPeople from "@material-ui/icons/People";
import IconFace from "@material-ui/icons/Face";
import { useOnboardUsers } from "./useOnboardUsers";

type Props = {
  person: {
    user: {
      id: number;
      onboardedAt?: string;
    };
  };
  style?: {
    marginLeft: number;
  };
};
export function InvitePersonButton({ person, style }: Props) {
  const [onboardUsers, { loading }] = useOnboardUsers();
  if (loading) return <CircularProgress size={25} />;
  const { user } = person;
  if (!user) return null;
  const label = user.onboardedAt ? "Resend Invite" : "Invite";
  return (
    <Button
      variant="outlined"
      label={label}
      onClick={() => onboardUsers([user.id])}
      style={style}
    />
  );
}
export function InviteOwnersButton({ onClick }: { onClick?(): void }) {
  return (
    <Button onClick={onClick} label="INVITE OWNERS">
      <IconPeople />
    </Button>
  );
}
export function InviteTenantsButton({ onClick }: { onClick?(): void }) {
  return (
    <Button onClick={onClick} label="INVITE TENANTS">
      <IconFace />
    </Button>
  );
}
