import { Container } from "@material-ui/core";
import React from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  FunctionField,
  TextField,
} from "react-admin";
import { LedgerEvent } from "../../api";
import { GeneralLedgerPaginatedEntriesFragment } from "../../api/resources/GeneralLedgerEntry/GeneralLedgerPaginatedEntriesFragment.generated";
import {
  DateTimeField,
  DynamicAiloRNField,
  IdField,
  LinkField,
  LoadingList,
  MoneyField,
} from "../../common";
import { determineAccountOwnerCategory } from "./determineAccountOwnerCategory";

type EntryRecord = NonNullable<
  GeneralLedgerPaginatedEntriesFragment["items"][number]
>;

const GeneralLedgerEntryList = ({
  items,
  hasMore,
  loadMore,
}: {
  items?: GeneralLedgerPaginatedEntriesFragment["items"];
  hasMore?: boolean;
  loadMore?(): void;
}): React.ReactElement => {
  return (
    <>
      <ArrayField source="items" record={{ id: "", items }}>
        <Datagrid>
          <IdField source="id" />
          <DateTimeField source="createdAt" />
          <TextField source="account.book" label="Book" />
          <TextField source="account.subtype" label="Account Subtype" />
          <DynamicAiloRNField
            source="account.accountOwner.reference"
            label="Account Owner Reference"
          />
          <FunctionField<EntryRecord>
            render={(record) =>
              determineAccountOwnerCategory(
                record?.account.accountOwner.reference
              )
            }
            label="Account Owner Category"
          />
          <DynamicAiloRNField
            source="account.reference"
            label="Account Reference"
          />
          <MoneyField source="amount" />
          <TextField source="userFacingDescription" />
          <LinkField
            label="Ledger Event"
            source="ledgerEvent.id"
            sourceResource={LedgerEvent}
          />
        </Datagrid>
      </ArrayField>
      {hasMore && (
        <Button
          label="Load more"
          onClick={loadMore}
          variant="contained"
          fullWidth
        />
      )}
    </>
  );
};

GeneralLedgerEntryList.Loading = LoadingList;
GeneralLedgerEntryList.Empty = () => (
  <Container {...{ align: "center" }}>No entries yet</Container>
);
GeneralLedgerEntryList.Error = () => (
  <Container {...{ align: "center" }}>
    There was an error displaying entries
  </Container>
);

export { GeneralLedgerEntryList };
