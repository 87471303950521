import React from "react";
import Box from "@material-ui/core/Box";
import { Link } from "react-admin";
import { formatMoney } from "../../../../common/ui/deprecated";
import { Badge, RightChevronIcon } from "../../../../common";

const TransactionDetails = ({ transaction }: any) => {
  const amount = transaction.amount.cents;
  const status = getTransactionStatus(transaction);
  return (
    <>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Box justify="flex-end">
        <Box textAlign="right" marginBottom="2px">
          {formatMoney({
            cents: amount,
          })}
        </Box>
        {
          ({
            Pending: isNotWithdrawal(transaction) ? (
              <Badge.Default>{status}</Badge.Default>
            ) : null,
            Failed: <Badge.Critical>{status}</Badge.Critical>,
            Reversal: <Badge.Critical>{status}</Badge.Critical>,
            Unknown: null,
          } as any)[status]
        }
      </Box>
      <Link to={`/Transactions/${transaction.businessTransaction}/show`}>
        <RightChevronIcon />
      </Link>
    </>
  );
};

const Status = {
  Pending: "Pending",
  Failed: "Failed",
  Reversal: "Reversal",
  Unknown: "Unknown",
};

function getTransactionStatus(transaction: {
  status: string;
  isReversal: any;
}): string {
  if (transaction.status === "PENDING") return Status.Pending;

  if (transaction.status === "FAIL" || transaction.status === "CLEANED") {
    return Status.Failed;
  }

  if (transaction.isReversal) return Status.Reversal;
  return Status.Unknown;
}

function isNotWithdrawal(transaction: {
  liability: { category: any };
  isReversal: any;
  amount: { cents: number };
}): boolean {
  const liabilityCategory = String(transaction.liability?.category);
  return (
    transaction.isReversal ||
    transaction.amount.cents > 0 ||
    ["BILL", "RENT"].includes(liabilityCategory)
  );
}

export { TransactionDetails };
