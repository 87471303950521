import moment from "moment-timezone";
import React from "react";
import { isPresent } from "ts-is-present";
import { ConsumerPropertiesListItem } from "../../Property";
import { ManagementFolioToManagement } from "./ManagementFolioToManagement";

export function ManagementFolioToManagementListItem({
  managementFolioToManagement,
}: {
  managementFolioToManagement: ManagementFolioToManagement;
}): React.ReactElement {
  const {
    management,
    startDatetime,
    endDatetime,
  } = managementFolioToManagement;

  const property = {
    id: management.property.id,
    type: "Investor",
    property: management.property,
  };
  const startDate = moment
    .tz(startDatetime, "Australia/Sydney")
    .format("D MMM YYYY");
  const endDate = endDatetime
    ? moment.tz(endDatetime, "Australia/Sydney").format("D MMM YYYY")
    : undefined;
  const dateRange = [startDate, endDate].filter(isPresent).join(" — ");

  return <ConsumerPropertiesListItem item={property} subHeader={dateRange} />;
}
