import { AiloRN } from "@ailo/ailorn";
import React from "react";
import { FunctionField } from "react-admin";
import { CopyToClipboardButton } from "../../ui/buttons/CopyToClipboardButton";

/**
 * @deprecated Use LinkField directly.
 */
const AiloRNField = ({ record, source, label, showId }: any) => {
  const value = record[source];
  const displayValue = value && showId ? AiloRN.from(value)?.internalId : value;
  return (
    <FunctionField
      record={record}
      label={label}
      render={() => (
        <span title={value}>
          {displayValue}
          <CopyToClipboardButton value={displayValue} />
        </span>
      )}
    />
  );
};

export { AiloRNField };
