import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Tab,
  TextField,
} from "react-admin";
import { AmountField } from "../../common";
import { UpdateSubscriptionButton } from "./UpdateSubscriptionButton";

function renderSubscriptionsTab() {
  return (
    <Tab label="Subscriptions">
      <ArrayField source="activeSubscriptions.items">
        <Datagrid>
          <FunctionField label="Active" render={() => "true"} />
          <TextField label="Id" source="id" />
          <TextField label="Plan" source="plan.id" />
          <DateField label="Starts On" source="startsOn" />
          <DateField label="Ends On" source="endsOn" />
          <FunctionField
            label="Amount ($)"
            render={(record: any) => {
              return <AmountField record={record.plan.price} />;
            }}
          />
          <TextField label="Price" source="plan.price.cents" />
          <FunctionField
            label="Update"
            render={(record: any) => (
              <UpdateSubscriptionButton
                subscriptionId={record.id}
                endsOn={record.endsOn}
              />
            )}
          />
        </Datagrid>
      </ArrayField>
      <ArrayField source="pastSubscriptions.items">
        <Datagrid>
          <FunctionField label="Active" render={() => "false"} />
          <TextField label="Id" source="id" />
          <TextField label="Plan" source="plan.id" />
          <DateField label="Starts On" source="startsOn" />
          <DateField label="Ends On" source="endsOn" />
          <FunctionField
            label="Amount ($)"
            render={(record: any) => {
              return <AmountField record={record.plan.price} />;
            }}
          />
          <TextField label="Price" source="plan.price.cents" />
          <FunctionField
            label="Update"
            render={(record: any) => (
              <UpdateSubscriptionButton
                subscriptionId={record.id}
                endsOn={record.endsOn}
              />
            )}
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}

export { renderSubscriptionsTab };
