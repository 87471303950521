import {
  formatPaymentMethodName,
  PaymentMethod as PaymentMethodType,
} from "@ailo/domain-helpers";
import { GET_LIST } from "ra-core";
import { useMemo } from "react";
import { PaymentMethod } from "../../..";
import { GetPaymentMethodsByOwnerQuery } from "../../../../api/resources/PaymentMethod/paymentMethodsClient.generated";
import { useQuery } from "../../../../common/api/useQuery";
import { PayeeOption } from "../InputComponents/PaymentOptionHooks/useGetPayeeOptions";

const tenancyWalletImplemented = false;

export type PaymentMethodOption = {
  name: string;
  id: string;
} & (
  | { type: "internal"; supplierAilorn: string }
  | { type: "BPay"; paymentMethodId: string; billerCode: string }
  | {
      type: "BankAccount";
      paymentMethodId: string;
      bsb: string;
      accountNumber: string;
    }
);

type PaymentMethodData = NonNullable<
  NonNullable<GetPaymentMethodsByOwnerQuery["paymentMethodsByOwner"]>["items"]
>;

export function useGetPaymentMethodOptions(
  payee?: PayeeOption["value"],
  internal?: boolean
): PaymentMethodOption[] {
  const { data: paymentMethodData, loaded, loading } = useQuery<
    typeof PaymentMethod,
    typeof GET_LIST,
    PaymentMethodData
  >(
    {
      type: GET_LIST,
      resource: PaymentMethod,
      payload: {
        filter: {
          owner: payee?.ailorn,
        },
      },
    },
    {
      enabled: !!(payee && payee.ailorn && payee.type === "supplier"),
    }
  );

  return useMemo((): PaymentMethodOption[] => {
    const paymentMethod = paymentMethodFromData(paymentMethodData);

    if (!loaded || loading) return [];

    switch (payee?.type) {
      case "supplier":
        return paymentMethod
          ? [
              {
                name: internal
                  ? "Supplier's Ailo Wallet"
                  : formatPaymentMethodName(paymentMethod, {
                      type: "account-details",
                    })
                      .replaceAll("\n", " ")
                      .replace("Account number", "Acc"),
                id: payee.ailorn,
                paymentMethodId: paymentMethod.id,
                ...(paymentMethod.__typename === "BPay"
                  ? {
                      type: paymentMethod.__typename,
                      billerCode: paymentMethod.billerCode!,
                    }
                  : {
                      type: paymentMethod.__typename,
                      bsb: paymentMethod.bsb!,
                      accountNumber: paymentMethod.accountNumber!,
                    }),
              },
            ]
          : [];
      case "agency":
        return [
          {
            name: "Agency Wallet",
            id: payee.ailorn,
            type: "internal",
            supplierAilorn: payee.ailorn,
          },
        ];
      case "management":
        return [
          {
            name: "Management Wallet",
            id: payee.ailorn,
            type: "internal",
            supplierAilorn: payee.ailorn,
          },
        ];
      case "tenancy":
        return [
          ...payee.tenants.map(
            ({ ailorn, name }): PaymentMethodOption => ({
              name: `Personal wallet for ${name}`,
              id: ailorn,
              type: "internal",
              supplierAilorn: ailorn,
            })
          ),
        ].concat(
          tenancyWalletImplemented
            ? {
                name: "Tenancy Wallet",
                id: payee.ailorn,
                type: "internal",
                supplierAilorn: payee.ailorn,
              }
            : []
        );
      default:
        return [];
    }
  }, [loaded, loading, paymentMethodData, payee, internal]);
}

function paymentMethodFromData(paymentMethodData?: PaymentMethodData) {
  const paymentMethod = paymentMethodData?.[0];
  const processedPaymentMethod: PaymentMethodType | undefined =
    paymentMethod?.__typename === "BPay"
      ? {
          __typename: "BPay",
          billerCode: paymentMethod.billerCode,
          id: paymentMethod.ailorn,
        }
      : paymentMethod?.__typename === "BankAccount"
      ? {
          __typename: "BankAccount",
          bsb: paymentMethod.bsb,
          accountNumber: paymentMethod.accountNumber,
          id: paymentMethod.ailorn,
        }
      : undefined;
  return processedPaymentMethod;
}
