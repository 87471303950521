import React, { useState } from "react";
import { Button } from "react-admin";
import { useHasAuthScope } from "../../../../common/auth/useHasAuthScope";
import { SendStatementDialog } from "./SendStatementDialog";
import { ManagementInput } from "./ManagementInput";
import { StatementType } from "../../../../api/graphql/types.generated";

export function SendStatementButton({
  management,
  ...props
}: {
  management: ManagementInput;
}): React.ReactElement | null {
  const [dialogOpen, setDialogOpen] = useState(false);
  const canReadPayments = useHasAuthScope("admin:payments:read");

  if (!canReadPayments) {
    return null;
  }

  return (
    <>
      <SendStatementDialog
        management={management}
        statementTypes={[StatementType.Management, StatementType.TaxSummary]}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Button
        label="Send Statement"
        variant="outlined"
        {...props}
        onClick={() => setDialogOpen(true)}
      />
    </>
  );
}
