import { formatPersonName } from "@ailo/domain-helpers";
import React from "react";
import { Person } from "../../../api/ResourceName";
import { DeprecatedLinkField } from "./DeprecatedLinkField";

/**
 * @deprecated Use `LegalEntityField` instead.
 */
const PersonLinkField = ({
  record,
  resource = Person,
  children,
}: {
  record: any;
  resource?: string | null | undefined;
  children?: React.ReactNode;
}) => {
  const name = formatPersonName(record);
  const { id } = record;
  return (
    record && (
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      <DeprecatedLinkField resource={resource} value={name} internalId={id}>
        {children}
      </DeprecatedLinkField>
    )
  );
};

export { PersonLinkField };
