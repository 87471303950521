import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const EditPaymentMethodAiloFeeWaiverForm = ({ onChange, values }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            aria-label="Enable Bank Account Fee Waivers"
            control={
              <Checkbox
                checked={!!values.bankAccountEnabled}
                onChange={onChange}
                name="bankAccountEnabled"
              />
            }
            label="Bank Account"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <TextField
              id="bankAccountStartDate"
              label="Waiver Start Date"
              style={{
                margin: 8,
              }}
              type="date"
              name="bankAccountStart"
              defaultValue={values.bankAccountStart}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
            />
            <TextField
              id="bankAccountEndDate"
              label="Waiver End Date"
              style={{
                margin: 8,
              }}
              type="date"
              name="bankAccountEnd"
              defaultValue={values.bankAccountEnd}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            aria-label="Enable Credit Card Fee Waivers"
            control={
              <Checkbox
                checked={values.creditCardEnabled}
                onChange={onChange}
                name="creditCardEnabled"
              />
            }
            label="Credit Card"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <TextField
              id="creditCardStartDate"
              label="Waiver Start Date"
              style={{
                margin: 8,
              }}
              type="date"
              name="creditCardStart"
              defaultValue={values.creditCardStart}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
            />
            <TextField
              id="creditCardEndDate"
              label="Waiver End Date"
              style={{
                margin: 8,
              }}
              type="date"
              name="creditCardEnd"
              defaultValue={values.creditCardEnd}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            aria-label="Enable Debit Card Fee Waivers"
            control={
              <Checkbox
                checked={values.debitCardEnabled}
                onChange={onChange}
                name="debitCardEnabled"
              />
            }
            label="Debit Card"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <TextField
              id="debitCardStartDate"
              label="Waiver Start Date"
              style={{
                margin: 8,
              }}
              type="date"
              name="debitCardStart"
              defaultValue={values.debitCardStart}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
            />
            <TextField
              id="debitCardEndDate"
              label="Waiver End Date"
              style={{
                margin: 8,
              }}
              type="date"
              name="debitCardEnd"
              defaultValue={values.debitCardEnd}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TextField
        id="waiverNote"
        label="Notes"
        name="notes"
        multiline
        fullWidth
        style={{
          margin: 8,
        }}
        defaultValue={values.notes}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export { EditPaymentMethodAiloFeeWaiverForm };
