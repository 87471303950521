import { UPDATE } from "ra-core";
import React from "react";
import { DateInput, TextInput } from "react-admin";
import { OperationData, Person } from "../../../../../api";
import {
  ErrorDialog,
  SimpleFormDialog,
  useMutation,
} from "../../../../../common";

const useEditPerson = ({ onSuccess }: { onSuccess?(): void }) => {
  return useMutation(
    {
      type: UPDATE,
      resource: Person,
    },
    {
      onSuccess: ({ data: _data }) => onSuccess?.(),
      successMessage: "Person has been updated",
    }
  );
};

type PersonRecord = OperationData<typeof Person, "GET_ONE">;

export const EditPersonDialog = (props: {
  open: boolean | undefined;
  onClose(): void;
  person: PersonRecord;
}): React.ReactElement => {
  const { open, onClose, person } = props;
  const [editPerson, { error }] = useEditPerson({
    onSuccess: onClose,
  });

  const submit = (formData: { reason: string }) => {
    return editPerson({
      payload: {
        ...person,
        ...formData,
      },
    });
  };

  if (error) {
    return (
      <ErrorDialog
        open={open}
        title="Editing person failed"
        error={error}
        onClose={onClose}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        ...person,
      }}
      title="Edit person"
      submitLabel="Update"
      cancelLabel="Cancel"
      open={open}
      onClose={onClose}
      save={submit}
      allowSubmitWhenPristine
    >
      <TextInput label="Legal First Name" source="legalFirstName" />
      <TextInput label="Legal Middle Name" source="legalMiddleName" />
      <TextInput label="Preferred Name" source="preferredName" />
      <TextInput label="Last name" source="lastName" />
      <TextInput label="Unit/Street Number" source="unitStreetNumber" />
      <TextInput label="Street Name" source="streetName" />
      <TextInput label="Suburb" source="suburb" />
      <TextInput label="State" source="state" />
      <TextInput label="Postcode" source="postcode" />
      <TextInput label="Country" source="country" />
      <DateInput
        source="birthDate"
        name="birthDate"
        label="Date of birth"
        variant="outlined"
        fullWidth
      />
    </SimpleFormDialog>
  );
};
