import React from "react";
import { BaseBadge } from "./baseBadge";
import { Colors, opacify } from "../colors";

/**
 * @deprecated Use <Badge type="success" /> instead.
 */
export function Success({ style, children }: any) {
  return (
    <BaseBadge
      color={Colors.TEXT.GREEN}
      style={{ ...style, bgcolor: opacify(Colors.STATUS.SUCCESS, 0.2) }}
    >
      {children}
    </BaseBadge>
  );
}
