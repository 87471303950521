import { Divider } from "@material-ui/core";
import React from "react";
import { DescriptionList } from "../../common";
import { StatusDisabled } from "./Status";
import { SubHeading } from "./SubHeading";

export function AutoWithdrawPlanDisabledItem(): React.ReactElement {
  return (
    <>
      <DescriptionList
        descriptionStyle={{
          textAlign: "right",
        }}
        items={[
          {
            name: <SubHeading variant="subtitle2">Auto Transfer</SubHeading>,
            description: StatusDisabled,
          },
        ]}
      />
      <Divider style={{ marginTop: 12 }} />
    </>
  );
}
