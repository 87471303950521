import { get } from "lodash";
import React, { ReactElement } from "react";
import { FunctionField } from "react-admin";
import { PublicFieldProps } from "./utils/react-admin-types";

export function PercentageField(props: PublicFieldProps): ReactElement {
  return (
    <FunctionField
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={(record: any, source: any) => {
        const value = get(record, source);
        if (!value) {
          return null;
        }
        if (typeof value !== "number") {
          return value;
        }

        return value.toLocaleString("en-GB", {
          style: "percent",
          maximumFractionDigits: 3,
        });
      }}
    />
  );
}

PercentageField.defaultProps = {
  addLabel: true,
};
