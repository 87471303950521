import { MoneyInterface } from "@ailo/money";
import gql from "graphql-tag";
import { GET_ONE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { BidirectionalPageCursor } from "../../graphql/types.generated";
import {
  GetLiabilityEntriesQuery,
  GetLiabilityEntriesQueryVariables,
  LiabilityQuery,
  LiabilityQueryVariables,
  PayLiabilityFromWalletMutation,
  PayLiabilityFromWalletMutationVariables,
} from "./liabilitiesClient.generated";

export const LiabilityClientDefinition = {
  [GET_ONE]: (params: { id: string }) => ({
    query: gql`
      query liability($id: ID!) {
        liability: liabilityById(liabilityId: $id) {
          id
          category
          description
          nextChargeAmount {
            cents
          }
          nextChargeDate
          nextDueAmount {
            cents
          }
          nextDueDate
          overdueAmount {
            cents
          }
          effectivePaidToDate
          paidToDate
          reference
          status {
            id
            liabilityId
            archivedAt
            createdAt
            createdBy
            payerKycCheckRequired
            isArchived
          }
          autoPaymentDetails {
            paymentMethod {
              id
            }
          }
          paymentKey
          owing {
            id
          }
        }
      }
    `,
    variables: <LiabilityQueryVariables>{
      id: String(params.id),
    },
    parseResponse({ data }: GraphQLResponse<LiabilityQuery>) {
      return {
        data: data.liability!,
      };
    },
  }),

  get_entries(params: { id: string; cursor?: BidirectionalPageCursor }) {
    return {
      query: gql`
        query getLiabilityEntries($id: ID!, $cursor: BidirectionalPageCursor) {
          liability: liabilityById(liabilityId: $id) {
            id
            entries(cursor: $cursor) {
              pageInfo {
                total
                hasNext
                nextCursor
              }
              items {
                __typename
                id
                amount {
                  cents
                }
                createdAt
                createdBy {
                  __typename
                  ... on User {
                    id
                    ailoRN
                  }
                  ... on System {
                    ailoRN
                  }
                }
                effectiveAt
                entryType
                liability {
                  category
                  taxCategory
                }
                status
                ... on AdjustmentLiabilityEntry {
                  description
                }
                ... on PlanBasedLiabilityEntry {
                  frequency
                  cycleStartDate
                  cycleEndDate
                  paidToDate
                }
                ... on PaymentLiabilityEntry {
                  businessTransaction {
                    id
                    description
                  }
                }
              }
            }
          }
        }
      `,
      variables: <GetLiabilityEntriesQueryVariables>{
        id: params.id,
        cursor: params.cursor,
      },
      parseResponse(response: GraphQLResponse<GetLiabilityEntriesQuery>) {
        const entries = response.data.liability!.entries!;
        const items = entries.items.map((entry) => ({
          ...entry,
          effectiveAtDate: new Date(entry.effectiveAt),
        }));
        return {
          data: {
            items,
            pageInfo: entries.pageInfo,
          },
        };
      },
    };
  },

  pay_liability_from_wallet: (params: {
    data: {
      amount: MoneyInterface;
      idempotentKey: string;
      liabilityAiloRN: string;
      walletAiloRN: string;
    };
  }) => ({
    query: gql`
      mutation payLiabilityFromWallet(
        $amount: MoneyInput!
        $idempotentKey: GeneratedKey!
        $liabilityAiloRN: AiloRN!
        $walletAiloRN: AiloRN!
      ) {
        responseStatus: payLiabilityFromWallet(
          amount: $amount
          idempotentKey: $idempotentKey
          liability: $liabilityAiloRN
          wallet: $walletAiloRN
        ) {
          status
          error
        }
      }
    `,
    variables: <PayLiabilityFromWalletMutationVariables>params.data,

    parseResponse({
      data: { responseStatus },
    }: GraphQLResponse<PayLiabilityFromWalletMutation>) {
      if (responseStatus?.status !== "SUCCESS" && !responseStatus?.error) {
        return {
          data: {
            ...responseStatus,
            error: "API returned status that is not equal to SUCCESS",
          },
        };
      }

      return {
        data: responseStatus,
      };
    },
  }),
};

export const liabilitiesClient = createClientFromDefinition(
  LiabilityClientDefinition
);
