import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

const START_FILE_UPLOAD = "start_file_upload";
const VALIDATE_FILE = "validate_file";
export function filesClient({ raFetchType, params }: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case VALIDATE_FILE: {
      return {
        query: gql`
          mutation validateFile($fileId: AiloRN!) {
            validateFile(fileId: $fileId) {
              ok
              errorCode
            }
          }
        `,
        variables: {
          fileId: params.fileId,
        },
        parseResponse: (response: { data: { validateFile: any } }) => ({
          data: response.data.validateFile,
        }),
      };
    }

    case START_FILE_UPLOAD: {
      return {
        query: gql`
          mutation startFileUpload($fileName: String!) {
            startFileUpload(fileName: $fileName) {
              uploadUrl
              file {
                id
              }
            }
          }
        `,
        variables: {
          fileName: params.fileName,
        },
        parseResponse: (response: { data: { startFileUpload: any } }) => ({
          data: response.data.startFileUpload,
        }),
      };
    }

    default:
      throw new Error(`${raFetchType} is an unknown fetch type`);
  }
}
