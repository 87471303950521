import Container from "@material-ui/core/Container";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useCallback } from "react";
import {
  OnFailure,
  OnSuccess,
  RedirectionSideEffect,
  // eslint-disable-next-line no-restricted-imports
  SimpleForm,
  SimpleFormProps,
} from "react-admin";
import { SlideUpDialog } from "../../ui/dialogs";
import { SimpleFormDialogToolbar } from "./SimpleFormDialogToolbar";

/**
 * Similar to `SimpleForm`, but mounts the form in a `Dialog`.
 *
 * Just as in `SimpleForm`, children will be cloned and wrapped with `Labeled`,
 * if only they have `label` and `addLabel` provided.
 *
 * Other `SimpleForm` props like `validate` `initialValues` will be passed along to `SimpleForm`.
 *
 * @example
```
export const TransferFundsDialog = ({
  open,
  onClose,
}) => {
  const [transferFunds] = useTransferFunds();

  const submit = (formData) => {
    transferFunds({
      payload: {
        fromWalletId: formData.walletId,
        amountInCents: formData.amount.cents,
        toPaymentMethodId: formData.toPaymentMethodId,
      },
    });
  };

  return (
    <SimpleFormDialog
      title="Transfer funds"
      open={open}
      submitLabel="Transfer funds"
      save={submit}
      onClose={onClose}
    >
      <WalletInput label="Wallet" source="walletId" />
      <MoneyInput
        source="amount"
        validate={[
          required(),
          minMoney({ cents: 1 }),
        ]}
      />
      <BankAccountPaymentMethodOfOwnerInput label="Transfer to" source="toPaymentMethodId" />
    </SimpleFormDialog>
  );
};
```
 */
export function SimpleFormDialog<TRecord = any>({
  open = true,
  title,
  submitLabel,
  cancelLabel,
  allowSubmitWhenPristine = true,
  onClose,
  children,
  disableBackdropClick,
  ...props
}: Omit<SimpleFormProps, "component" | "toolbar" | "save" | "onSubmit"> & {
  open?: boolean;
  title?: string | null | undefined;
  submitLabel?: string | null | undefined;
  cancelLabel?: string | null | undefined;
  allowSubmitWhenPristine?: boolean;
  save?: (
    data: TRecord,
    redirectTo: RedirectionSideEffect,
    options?: {
      onSuccess?: OnSuccess;
      onFailure?: OnFailure;
    }
  ) => void;
  onClose: () => void;
  children: React.ReactNode;
}) {
  const Wrapper = useCallback(
    ({ children: wrapperChildren }: any) => (
      <>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <Container
            maxWidth="xs"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {wrapperChildren}
          </Container>
        </DialogContent>
      </>
    ),
    [title]
  );
  return (
    <SlideUpDialog
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
    >
      <SimpleForm
        component={Wrapper}
        toolbar={
          <SimpleFormDialogToolbar
            {...{
              submitLabel,
              cancelLabel,
              allowSubmitWhenPristine,
              onCancel: onClose,
            }}
          />
        }
        {...props}
      >
        {children}
      </SimpleForm>
    </SlideUpDialog>
  );
}
