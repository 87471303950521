import { GET_LIST, GET_MANY, GET_ONE } from "react-admin";
import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { RaDataGraphqlClientResult } from "../../common";

export function feeTaxCategoryClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs): RaDataGraphqlClientResult {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query feeTaxCategory {
            categories: feeTaxCategories {
              id
              name
            }
          }
        `,

        parseResponse(response: {
          data: { categories: Array<{ id: string; name: string }> };
        }) {
          const { categories } = response.data;
          return {
            data: categories.find((c: { id: string }) => c.id === params.id),
          };
        },
      };

    case GET_LIST:
    case GET_MANY:
      return {
        query: gql`
          query feeTaxCategories {
            categories: feeTaxCategories {
              id
              name
            }
          }
        `,

        parseResponse(response: {
          data: { categories: Array<{ id: string; name: string }> };
        }) {
          let { categories } = response.data;

          if (params.filter?.name) {
            categories = categories.filter((c: { name: string }) =>
              params.filter?.name.includes(c.name)
            );
          }

          return {
            data: categories,
            total: categories.length,
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
