import React, { Component } from "react";
import type { ComponentType } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  showNotification as showNotificationAction,
  Button,
} from "react-admin";
import { envVars } from "../../envVars";
import { analytics } from "../../common/utils/analytics";

class PasswordResetButton extends Component<{
  showNotification: any;
  clientId: string;
  email: string;
  metadata: Record<string, any>;
  label: string;
  button?: ComponentType<{
    onClick: (...args: Array<any>) => any;
  }>;
}> {
  handleClick = ({ clientId, email, showNotification, metadata }: any) => {
    axios
      .post(
        `https://${envVars.auth0.domain}/dbconnections/change_password`,
        {
          client_id: clientId,
          email,
          connection: "Username-Password-Authentication",
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(() => {
        showNotification(`Success! Password reset email has been triggered`);
      })
      .catch((error) => {
        console.log(error);
        showNotification(`Failed. ${error}`, "error");
      });
    analytics.resetPassword(metadata);
  };

  render() {
    const { clientId, email, showNotification, metadata, ...rest } = this.props;
    return (
      <Button
        {...(rest as any)}
        onClick={() =>
          this.handleClick({
            clientId,
            email,
            showNotification,
            metadata,
          })
        }
      />
    );
  }
}

const ConnectedPasswordResetButton = connect(null, {
  showNotification: showNotificationAction,
})(PasswordResetButton);

type Props = {
  email: string;
  metadata: Record<string, any>;
};

const GTConsumerPasswordResetButton = (props: Props) => (
  <ConnectedPasswordResetButton
    label="Reset Consumer Password"
    clientId={envVars.auth0.ailoConsumerClientId}
    {...props}
  />
);

const GTAgencyPasswordResetButton = (props: Props) => (
  <ConnectedPasswordResetButton
    label="Reset Agency Password"
    clientId={envVars.auth0.ailoAgencyClientId}
    {...props}
  />
);

export { GTConsumerPasswordResetButton, GTAgencyPasswordResetButton };
