import { AiloRN, services } from "@ailo/ailorn/build";
import gql from "graphql-tag";
import { UPDATE } from "ra-core";
import { CREATE, GET_LIST, GET_ONE } from "react-admin";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
  parseSortParam,
  removeWhitespace,
  transformUserForRequest,
} from "../../common";
import { Maybe } from "../../graphql/types.generated";
import {
  CreatePersonMutation,
  CreatePersonMutationVariables,
  CreateUserForPersonMutation,
  CreateUserForPersonMutationVariables,
  PeopleQuery,
  PeopleQueryVariables,
  PersonQuery,
  PersonQueryVariables,
  UpdatePersonProfileDetailsMutation,
  UpdatePersonProfileDetailsMutationVariables,
  UpdateUserEmailAddressMutation,
  UpdateUserEmailAddressMutationVariables,
} from "./PersonClient.generated";

const BasePersonFragment = gql`
  fragment BasePersonFragment on Person {
    __typename
    id
    ailoRN
    firstName
    lastName
    emailAddress
    taxFileNumber
    jobTitle
    phoneNo
    metadata
    photo {
      id
      url
      thumbnailUrl
    }
    user {
      id
      ailoRN
      auth0Id
      onboardedAt
      onboardingCompletedAt
      createdAt
    }
    organisation {
      id
      name
      availableFeatures {
        id
      }
      activeSubscriptions(cursor: {}) {
        pageInfo {
          total
          hasMore
          nextCursor
        }
        items {
          id
          plan {
            id
            price {
              cents
            }
          }
          startsOn
          endsOn
        }
      }
    }
  }
`;

const ExtendedPersonFragment = gql`
  fragment ExtendedPersonFragment on Person {
    kycVerified
    legalFirstName
    legalMiddleName
    preferredName
    phoneNoVerifiedAt
    eulaSignedAt
    signUpCompleted
    birthDate
    unitStreetNumber
    streetName
    suburb
    state
    postcode
    country
    wallets {
      id
      name
      totalBalance {
        cents
      }
      availableBalance {
        cents
      }
      createdAt
      idempotencyKey
    }
    user {
      onboardingTasks {
        onboardingTask {
          id
          description
        }
        completedAt
      }
    }
    companiesOwned {
      items {
        id
        ailoRN
        registeredEntityName
      }
    }
  }
`;

export const PersonClientDefinition = {
  [GET_LIST]: (params: {
    pagination: PaginationParams;
    sort?: { field?: string | null; order?: "ASC" | "DESC" | null } | null;
    filter: { searchTerm?: string; domainType?: string };
  }) => ({
    query: gql`
      query people(
        $cursor: String
        $pageSize: Int
        $sort: String
        $searchTerm: String
        $domainType: DomainType
      ) {
        people(
          pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
          search: $searchTerm
          domainType: $domainType
        ) {
          pageInfo {
            hasMore
            nextCursor
            total
          }
          items {
            ...BasePersonFragment
          }
        }
      }
      ${BasePersonFragment}
    `,
    variables: <PeopleQueryVariables>{
      ...buildPaginationVariables(params.pagination),
      sort: parseSortParam(params.sort),
      searchTerm: params.filter.searchTerm,
      domainType: params.filter.domainType,
    },
    parseResponse: (response: GraphQLResponse<PeopleQuery>) => {
      const { people } = response.data;
      const { pageInfo, items } = people!;
      const { total, nextCursor, hasMore } = pageInfo;
      return {
        data: items,
        total,
        nextCursor,
        hasMore,
      };
    },
  }),

  [GET_ONE]: (params: { id: string }) => ({
    query: gql`
      query person($id: ID!) {
        person(id: $id) {
          ...BasePersonFragment
          ...ExtendedPersonFragment
        }
      }
      ${BasePersonFragment}
      ${ExtendedPersonFragment}
    `,
    variables: <PersonQueryVariables>{
      id: String(params.id),
    },
    parseResponse: (response: GraphQLResponse<PersonQuery>) => {
      return {
        data: response.data.person!,
      };
    },
  }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [CREATE]: (params: { data: Record<string, any> }) => ({
    query: gql`
      mutation createPerson(
        $firstName: String
        $lastName: String
        $emailAddress: String
        $phoneNo: String
        $jobTitle: String
        $taxFileNumber: String
        $metadata: String
      ) {
        createPerson(
          personDetails: {
            firstName: $firstName
            lastName: $lastName
            emailAddress: $emailAddress
            phoneNo: $phoneNo
            jobTitle: $jobTitle
            taxFileNumber: $taxFileNumber
            metadata: $metadata
          }
        ) {
          ...BasePersonFragment
        }
      }
      ${BasePersonFragment}
    `,
    variables: <CreatePersonMutationVariables>(
      removeWhitespace(transformUserForRequest(params.data))
    ),
    parseResponse: (response: GraphQLResponse<CreatePersonMutation>) => ({
      data: response.data.createPerson,
    }),
  }),

  [UPDATE]: (params: {
    id: string | number;
    legalFirstName?: Maybe<string>;
    preferredName?: Maybe<string>;
    lastName?: Maybe<string>;
    birthDate?: Maybe<string>;
    unitStreetNumber?: Maybe<string>;
    streetName?: Maybe<string>;
    suburb?: Maybe<string>;
    state?: Maybe<string>;
    postcode?: Maybe<string>;
    country?: Maybe<string>;
  }) => ({
    query: gql`
      mutation updatePersonProfileDetails(
        $input: UpdatePersonProfileDetailsInput!
      ) {
        updatePersonProfileDetails(input: $input) {
          ...BasePersonFragment
          ...ExtendedPersonFragment
        }
      }

      ${BasePersonFragment}
      ${ExtendedPersonFragment}
    `,
    variables: <UpdatePersonProfileDetailsMutationVariables>{
      input: {
        id: AiloRN.of(services.AuthZ.legalEntity, params.id).toString(),
        legalFirstName: params.legalFirstName,
        preferredName: params.preferredName,
        lastName: params.lastName,
        birthDate: params.birthDate,
        unitStreetNumber: params.unitStreetNumber,
        streetName: params.streetName,
        suburb: params.suburb,
        state: params.state,
        postcode: params.postcode,
        country: params.country,
      },
    },
    parseResponse: (
      response: GraphQLResponse<UpdatePersonProfileDetailsMutation>
    ) => {
      return {
        data: response.data.updatePersonProfileDetails,
      };
    },
  }),

  create_user: (params: { id: string; email?: string }) => ({
    query: gql`
      mutation createUserForPerson($id: ID!, $email: String) {
        person: createUserForPerson(personId: $id, email: $email) {
          ...BasePersonFragment
          ...ExtendedPersonFragment
        }
      }

      ${BasePersonFragment}
      ${ExtendedPersonFragment}
    `,
    variables: <CreateUserForPersonMutationVariables>{
      id: params.id,
      email: params.email,
    },
    parseResponse(response: GraphQLResponse<CreateUserForPersonMutation>) {
      return {
        data: response.data.person,
      };
    },
  }),
  update_email: (params: {
    id: string;
    email: string;
    sendEmailChangeWarningEmail?: boolean;
  }) => ({
    query: gql`
      mutation updateUserEmailAddress(
        $id: AiloRN!
        $email: String!
        $sendEmailChangeWarningEmail: Boolean
      ) {
        user: updateUserEmailAddress(
          userId: $id
          emailAddress: $email
          sendEmailChangeWarningEmail: $sendEmailChangeWarningEmail
        ) {
          person {
            ...BasePersonFragment
            ...ExtendedPersonFragment
          }
        }
      }

      ${BasePersonFragment}
      ${ExtendedPersonFragment}
    `,
    variables: <UpdateUserEmailAddressMutationVariables>{
      id: AiloRN.of(services.AuthZ.user, params.id).toString(),
      email: params.email,
      sendEmailChangeWarningEmail: params.sendEmailChangeWarningEmail,
    },
    parseResponse(response: GraphQLResponse<UpdateUserEmailAddressMutation>) {
      return {
        data: response.data.user?.person,
      };
    },
  }),
};

export const PersonClient = createClientFromDefinition(PersonClientDefinition);
