import { Container, styled } from "@material-ui/core";
import React from "react";
import { Button, GET_ONE, Link, useGetOne } from "react-admin";
import { OperationData } from "../../../../api";
import { PlatformFeatureId } from "../../../../api/graphql/types.generated";
import {
  Organisation,
  Fee,
  ManagementFeeBlueprint,
  Property,
} from "../../../../api/ResourceName";
import { OrganisationQuery } from "../../../../api/resources/Organisation/organisationClient.generated";
import { DetailCard } from "../../../../common/ui/cards";
import { FeesChargedList } from "./FeesChargedList";

type PropertyRecord = OperationData<typeof Property, typeof GET_ONE>;
const Row = styled(Container)({ flexDirection: "row" });

export const FeesChargedCard = ({
  propertyId,
  management,
  tenancy,
}: {
  propertyId: string;
  management: PropertyRecord["managements"][number];
  tenancy?: NonNullable<
    PropertyRecord["managements"][number]["allTenancies"]
  >[number];
}): React.ReactElement => {
  const organisation = useGetOne<
    NonNullable<OrganisationQuery["organisation"]>
  >(Organisation, management.managingEntity?.organisationId || "");

  const availableFeatures = (organisation.data?.availableFeatures ?? []).map(
    (val) => val.id
  );

  const hasManagementFeeBlueprintFeature = availableFeatures.includes(
    PlatformFeatureId.ManagementFeeBlueprint
  );

  return (
    <DetailCard
      title="Fees charged"
      contents={
        <>
          <FeesChargedList fees={management.fees?.items} />
          {management.fees?.pageInfo.hasMore && (
            <Button
              component={Link}
              to={{
                pathname: `/${Fee}`,
                search: `?filter={"managementId": "${management.id}"}`,
              }}
              label="View All"
              fullWidth
              style={{ marginTop: 22 }}
            />
          )}
        </>
      }
      action={
        <Row style={{ paddingRight: 0 }}>
          {!!management.managingEntity?.organisationId && !!management.id && (
            <Button
              component={Link}
              to={{
                pathname: `/${Fee}/create`,
                state: {
                  hasManagementFeeBlueprintFeature,
                  record: {
                    organisationId: management.managingEntity.organisationId,
                    propertyId,
                    managementId: management.id,
                    dailyRate: tenancy?.currentRent?.dailyRate,
                  },
                },
              }}
              label="Charge Fee"
            />
          )}
          {!!management.managingEntity?.organisationId &&
            !!management.id &&
            hasManagementFeeBlueprintFeature && (
              <Button
                component={Link}
                to={{
                  pathname: `/${ManagementFeeBlueprint}`,
                  state: {
                    managementId: management.id,
                    organisationId: management.managingEntity.organisationId,
                  },
                }}
                label="View Fee Schedule"
              />
            )}
        </Row>
      }
    />
  );
};
