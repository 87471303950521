import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import HomeIcon from "@material-ui/icons/Home";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import HouseIcon from "@material-ui/icons/House";
import MoneyIcon from "@material-ui/icons/Money";
import FolderIcon from "@material-ui/icons/Folder";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import ScriptIcon from "@material-ui/icons/Adb";
import PublishIcon from "@material-ui/icons/Publish";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TitleIcon from "@material-ui/icons/Title";
import { ReduxState } from "ra-core";
import React, { FC, useState } from "react";
import { DashboardMenuItem, MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import {
  Fee,
  LiabilityPaymentPlan,
  ManagementFolio,
  MigratingManagement,
  MigrationAgency,
  Person,
} from "../../api";
import SubMenu from "./SubMenu";

type MenuName =
  | "menuMigration"
  | "menuManagements"
  | "menuCustomers"
  | "menuReports"
  | "menuTransco"
  | "menuTrust";
interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}
export type ThemeName = "light" | "dark";
export interface AppState extends ReduxState {
  theme: ThemeName;
}

export const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuMigration: false,
    menuManagements: false,
    menuCustomers: false,
    menuReports: false,
    menuTransco: false,
    menuTrust: false,
  });
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      {" "}
      <DashboardMenuItem sidebarIsOpen={open} onClick={onMenuClick} />
      <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="Customers"
        icon={<BusinessCenterIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${Person}?page=1`}
          primaryText="People"
          leftIcon={<PeopleOutlineOutlinedIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/Companies?page=1"
          primaryText="Companies"
          leftIcon={<BusinessIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/Customers?page=1"
          primaryText="Customers"
          leftIcon={<BusinessCenterIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to={`/${ManagementFolio}?page=1`}
        primaryText="Portfolios"
        leftIcon={<FolderIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/Properties?page=1"
        primaryText="Properties"
        leftIcon={<HouseIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuManagements")}
        isOpen={state.menuManagements}
        sidebarIsOpen={open}
        name="Property Management"
        icon={<HomeIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/Managements?page=1"
          primaryText="Managements"
          leftIcon={<HomeWorkIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/Bills?page=1"
          primaryText="Bills"
          leftIcon={<ReceiptIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/Suppliers?page=1&filter={"status":"active"}`}
          primaryText="Suppliers"
          leftIcon={<BuildIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${Fee}?page=1`}
          primaryText="One Off Fees"
          leftIcon={<ReceiptIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/FeeBlueprint?page=1"
          primaryText="Fee Blueprints"
          leftIcon={<ReceiptIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuMigration")}
        isOpen={state.menuMigration}
        sidebarIsOpen={open}
        name="Migration"
        icon={<PublishIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/${MigratingManagement}?page=1`}
          primaryText="Migrating Managements"
          leftIcon={<PublishIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${MigrationAgency}?page=1`}
          primaryText="Agencies"
          leftIcon={<BusinessIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuReports")}
        isOpen={state.menuReports}
        sidebarIsOpen={open}
        name="Finances"
        icon={<AccountBalanceIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/Transactions"
          primaryText="Transactions"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/Reconciliations"
          primaryText="Reconciliations"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/ReconciliationDetails"
          primaryText="Reconciliation Details"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/LiabilityCategories"
          primaryText="Liabilities"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/WalletOwnerBalances"
          primaryText="Wallet Owner Balances"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/NegativeWalletBalances"
          primaryText="Negative Wallet Balances"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/Discrepancies"
          primaryText="Discrepancies"
          leftIcon={<AttachMoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${LiabilityPaymentPlan}`}
          primaryText="Liability Payment Plans"
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuTransco")}
        isOpen={state.menuTransco}
        sidebarIsOpen={open}
        name="Transco"
        icon={<TitleIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/Transco/VirtualAccounts"
          primaryText="Virtual Accounts"
          leftIcon={<MoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/Transco/VirtualAccountDebits"
          primaryText="Virtual Account Debits"
          leftIcon={<MoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuTrust")}
        isOpen={state.menuTrust}
        sidebarIsOpen={open}
        name="Trust Accounting"
        icon={<AttachMoneyIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/TrustTransactions/show"
          primaryText="Transactions"
          leftIcon={<MoneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/scripts"
        primaryText="Scripts"
        leftIcon={<ScriptIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
    </div>
  );
};
