import { AutocompleteInput } from "react-admin";
import React from "react";
import { Customer } from "../../api/ResourceName";
import { ReferenceInput } from "./ReferenceInput";

export function OrganisationInput({
  source,
  allowEmpty = false,
  emptyText,
  disabled,
  options,
  ...props
}: any) {
  return (
    <ReferenceInput
      reference={Customer}
      source={source}
      filterToQuery={(searchTerm: string) => ({
        searchTerm,
      })}
      allowEmpty={allowEmpty}
      {...props}
    >
      <AutocompleteInput
        optionText="name"
        allowEmpty={allowEmpty}
        emptyText={emptyText}
        options={{
          disabled,
          ...options,
          InputProps: {
            // Disable Chrome address autocomplete dropdown
            // ( https://github.com/marmelab/react-admin/issues/5620#issuecomment-773773732 )
            autoComplete: "no",
            type: "search", // needed to stop 1password from trying to auto fill this field
            ...options?.InputProps,
          },
        }}
      />
    </ReferenceInput>
  );
}
