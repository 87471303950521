import React from "react";
import { Error, Labeled, required, useQuery } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { Money } from "@ailo/money";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {
  Colors,
  ErrorDialog,
  maxMoney,
  minMoney,
  MoneyInput,
  SimpleFormDialog,
  useMutation,
} from "../../../../../common";
import { TenancyDeposits, Wallet } from "../../../../../api";
import { TenancyDepositRecord } from "../TenancyDepositRecord";

const useTransferDeposit = ({ onSuccess }: { onSuccess?(): void }) => {
  return useMutation(
    {
      type: "transfer_tenancy_deposit",
      resource: TenancyDeposits,
    },
    {
      onSuccess: ({ data: _data }) => onSuccess?.(),
      successMessage: "Transfer initial payment to property wallet submitted",
    }
  );
};

export const TransferDepositDialog = ({
  deposit,
  tenancyAiloRN,
  open,
  onClose,
}: {
  deposit: TenancyDepositRecord;
  tenancyAiloRN: string;
  open: boolean | undefined;
  onClose: () => void;
}): React.ReactElement => {
  const [transferDeposit, { error }] = useTransferDeposit({
    onSuccess: onClose,
  });

  const submit = (formData: { amount: number }) => {
    return transferDeposit({
      payload: {
        tenancyDepositId: deposit.ailoRN,
        ...formData,
      },
    });
  };

  const { data: tenancyWallet, loading, error: walletError } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: tenancyAiloRN,
    },
  });

  if (loading) {
    return <></>;
  }

  if (walletError) {
    return <Error error={walletError} />;
  }

  if (error) {
    return (
      <ErrorDialog
        open={open}
        title="Transfer initial payment failed"
        error={error}
        onClose={onClose}
      />
    );
  }

  const { availableBalance } = tenancyWallet;

  return (
    <SimpleFormDialog
      initialValues={{
        amount:
          availableBalance.cents < deposit.amount.cents
            ? availableBalance
            : deposit.amount,
      }}
      title="Transfer initial payment to property wallet as rent"
      submitLabel="Transfer"
      cancelLabel="Cancel"
      open={open}
      onClose={onClose}
      save={submit}
      allowSubmitWhenPristine
      style={{ width: 500 }}
    >
      <Labeled label="Transfer from" fullWidth>
        <Box>
          <Typography>Tenancy Wallet</Typography>
          <Typography
            style={{
              color: Colors.TEXT.DARK.SECONDARY,
              fontSize: "0.8125rem",
            }}
          >
            {`Available balance: ${Money.from(availableBalance).format()}`}
          </Typography>
        </Box>
      </Labeled>
      <Divider
        style={{
          marginTop: 12,
        }}
      />
      <Labeled label="How much?" fullWidth>
        <MoneyInput
          source="amount"
          label="amount ($)"
          validate={[
            required(),
            minMoney({
              cents: 1,
            }),
            maxMoney(availableBalance),
          ]}
        />
      </Labeled>
    </SimpleFormDialog>
  );
};
