import gql from "graphql-tag";
import { GET_LIST } from "ra-core";
import {
  createClientFromDefinition,
  GraphQLResponse,
  specifyDefinition,
} from "../../common";
import { FeeEventsQuery } from "./feeEventClient.generated";

export const FeeEventClientDefinition = specifyDefinition({
  [GET_LIST]: () => ({
    query: gql`
      query feeEvents {
        events: feeEvents {
          type
          shortDescription
          longDescription
        }
      }
    `,
    parseResponse: (response: GraphQLResponse<FeeEventsQuery>) => ({
      data: response.data.events,
    }),
  }),
});

export const feeEventClient = createClientFromDefinition(
  FeeEventClientDefinition
);
