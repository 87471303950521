const env = { ...(window as any).windowEnvVars, ...process.env };
const envVars = {
  clientName: env.APOLLO_CLIENT_NAME || "ailo-admin",
  ailoApiEnv: env.REACT_APP_ENV !== "local" ? env.REACT_APP_ENV : "dev",
  appColor:
    env.REACT_APP_ENV !== "local" ? env.REACT_APP_COLOR : "rgb(11, 91, 238)",
  segment: {
    apiKey: env.REACT_APP_SEGMENT_API_KEY,
    config: {
      flushAt: env.REACT_APP_SEGMENT_QUEUE_SIZE || 20,
      flushInterval: env.REACT_APP_SEGMENT_FLUSH_INTERVAL || 10000,
    },
  },
  auth0: {
    url: env.REACT_APP_AUTH0_URL || "https://manage.auth0.com",
    domain: env.REACT_APP_AUTH0_DOMAIN,
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    ailoConsumerClientId: env.REACT_APP_AILO_CONSUMER_CLIENT_ID,
    ailoAgencyClientId: env.REACT_APP_AILO_AGENCY_CLIENT_ID,
    callbackUrl: `${window.location.origin}/${
      env.REACT_APP_AUTH0_CALLBACK_URL ?? "callback"
    }`,
    audience: env.REACT_APP_AUTH0_AUDIENCE,
    consumerAppUrl: env.REACT_APP_CONSUMER_APP_URL,
    agencyAppUrl: env.REACT_APP_AGENCY_APP_URL,
  },
  adminGateway: {
    domain: env.REACT_APP_ADMIN_GATEWAY_DOMAIN ?? `/admin/graphql`,
  },
  transco: {
    domain: env.REACT_APP_ADMIN_TRANSCO_DOMAIN,
  },
  overdraftAllowanceMaxAmountCents:
    env.REACT_APP_OVERDRAFT_ALLOWANCE_MAX_AMOUNT_CENTS || 300000,
  organizationRoleIds: {
    agency: {
      admin: {
        legalEntity: env.REACT_APP_AGENCY_ROLE_LE_ADMIN,
        organization: env.REACT_APP_AGENCY_ROLE_ORG_ADMIN,
      },
      member: {
        legalEntity: env.REACT_APP_AGENCY_ROLE_LE_MEMBER,
        organization: env.REACT_APP_AGENCY_ROLE_ORG_MEMBER,
      },
    },
  },
};
export { envVars };
