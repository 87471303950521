import { makeStyles } from "@material-ui/core/styles";
import { Button, Link, useListContext } from "react-admin";
import { stringify } from "query-string";
import React, { ReactElement } from "react";

const useStyles = makeStyles({
  link: {
    minWidth: "auto",
  },
});

export const LinkToReconciliationDetails = ({
  record,
  source,
}: Record<string, any>): ReactElement => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/ReconciliationDetails/list",
        search: stringify({
          filter: JSON.stringify({
            categoryId: `${record.id}`,
            afterDate: filterValues.afterDate,
            beforeDate: filterValues.beforeDate,
          }),
        }),
      }}
      label={record[source]}
      className={classes.link}
    />
  );
};
