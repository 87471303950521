import React from "react";
import { NumberInput, NumberInputProps } from "react-admin";
import Big, { BigSource } from "big.js";

type PercentInputProps = {
  /**
   * If the value is 12% and format is:
   * - percentage, display `12`
   * - decimal, display `0.12`
   *
   * @default decimal
   */
  format?: "percentage" | "decimal";
  dp?: number;
  range?: [min: number, max: number];
} & NumberInputProps;

export function PercentInput({
  addLabel: _addLabel,
  format = "decimal",
  dp = 2,
  range,
  ...props
}: PercentInputProps) {
  return (
    <NumberInput
      step={0.01}
      format={(value: BigSource | null | undefined) => {
        if (value === null || value === undefined || value === "") {
          return null;
        }

        const percent =
          format === "percentage"
            ? new Big(value).mul(100).toNumber()
            : new Big(value).toNumber();
        return percent;
      }}
      parse={(input: BigSource | null | undefined) => {
        if (input === undefined || input === null || input === "") {
          return input;
        }

        const inputBig = range
          ? putInRange(...range.map((x: number): Big => Big(x)))(Big(input))
          : Big(input);

        const percentNumber =
          format === "percentage"
            ? inputBig.round(dp, 0).div(100).toNumber()
            : inputBig.round(dp, 0).toNumber();

        return percentNumber;
      }}
      {...props}
    />
  );
}

function putInRange(min: Big = Big(0), max: Big = Big(100)) {
  return (x: Big): Big => {
    if (max.lt(min)) throw new Error("min can't be bigger than max");
    if (x.lt(min)) return min;
    if (x.gt(max)) return max;
    return x;
  };
}
