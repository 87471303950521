import React from "react";
import { NameAvatar } from "./nameAvatarGroup";
import { CompanyAvatar } from "./companyAvatar";
import { ImageAvatar } from "./imageAvatar";
import { formatPersonName } from "../../utils";

export const LegalEntityAvatar = ({ legalEntity, style }: any) => {
  return legalEntity.__typename === "Person" ? (
    legalEntity.photo?.url ? (
      <ImageAvatar
        source={legalEntity.photo.thumbnailUrl || legalEntity.photo.url}
        name={formatPersonName(legalEntity)}
        style={style}
      />
    ) : (
      <NameAvatar
        firstName={legalEntity.firstName}
        lastName={legalEntity.lastName}
        style={style}
      />
    )
  ) : (
    <CompanyAvatar name={legalEntity.registeredEntityName} style={style} />
  );
};
