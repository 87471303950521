import { AiloRN } from "@ailo/ailorn";
import { Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import React, { useState } from "react";
import { CREATE, required, TextInput } from "react-admin";
import { OperationParams } from "../../api";
import { Team } from "../../api/ResourceName";
import { SimpleFormDialog, useMutation } from "../../common";

type CreateTeamInput = OperationParams<typeof Team, typeof CREATE>["data"];

export function CreateTeamButton({
  organisationAilorn,
  style,
}: {
  organisationAilorn: AiloRN;
  style?: React.CSSProperties;
}): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Team,
    type: CREATE,
  });

  return (
    <div style={style}>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<CreateIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Create
      </Button>
      {dialogOpen && (
        <SimpleFormDialog<CreateTeamInput>
          title="Create Team"
          submitLabel="Create"
          cancelLabel="Back"
          initialValues={{ organisationAilorn }}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          save={(data) => mutate({ payload: { data } })}
        >
          <TextInput
            source="organisationAilorn"
            disabled
            fullWidth
            validate={[required()]}
          />
          <TextInput source="name" fullWidth validate={[required()]} />
        </SimpleFormDialog>
      )}
    </div>
  );
}
