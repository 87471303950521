import { useQuery } from "react-admin";
import { useMemo } from "react";
import { ManagementEndReasonsQuery } from "../../api/resources/Management/managementsClient.generated";
import { Management } from "../../api";

type GetManagementEndReasons = {
  data?: ManagementEndReasonsQuery;
  error?: unknown;
  loading: boolean;
};

export function useGetManagementEndReasons(): GetManagementEndReasons {
  const { data, loading, error }: GetManagementEndReasons = useQuery({
    type: "get_management_end_reasons",
    resource: Management,
    payload: {},
  });

  const factory = () => ({
    data,
    loading,
    error,
  });

  return useMemo<GetManagementEndReasons>(factory, [data, loading, error]);
}
