import React from "react";
import { Datagrid, TextField } from "react-admin";

import moment from "moment-timezone";
import { DateFilter, List, MoneyField } from "../../../common";

import { LinkToReconciliationDetails } from "./linkToReconciliationDetails";

const rowStyle = (record: { transactionType: string }) => ({
  backgroundColor: isReversalOrRefund(record) ? "rgb(242, 195, 191)" : "white",
});

const ReconciliationsList = (props: any): React.ReactElement => {
  const afterDateDefaultFilter = moment()
    .subtract(1, "days")
    .format(moment.HTML5_FMT.DATE);

  const beforeDateDefaultFilter = moment().format(moment.HTML5_FMT.DATE);

  return (
    <List
      {...props}
      title="Bank Level Reconciliations"
      bulkActionButtons={false}
      filters={<DateFilter label="Banking Date" />}
      filterDefaultValues={{
        afterDate: afterDateDefaultFilter,
        beforeDate: beforeDateDefaultFilter,
      }}
      pagination={false}
    >
      <Datagrid rowStyle={rowStyle as any}>
        <LinkToReconciliationDetails source="paymentType" />
        <LinkToReconciliationDetails source="transactionType" />
        <TextField label="Payment Type" source="paymentType" />
        <TextField label="Transaction Type" source="transactionType" />
        <MoneyField label="Total Amount ($)" source="total" showAbsolute />
        <MoneyField label="Fee Amount ($)" source="fees" showAbsolute />
        <TextField label="Count" source="count" textAlign="right" />
      </Datagrid>
    </List>
  );
};

function isReversalOrRefund(record: { transactionType: string }) {
  return (
    record.transactionType === "refund" ||
    record.transactionType === "manual adjustment" ||
    record.transactionType === "early reversal" ||
    record.transactionType === "late reversal" ||
    record.transactionType === "write-off"
  );
}

export { ReconciliationsList };
