import { envVars } from "../../envVars";

export async function updateFavicon(): Promise<void> {
  const favicon = await drawColoredFavicon();

  if (favicon) {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    link.href = favicon;
  }
}

async function drawColoredFavicon(): Promise<string | undefined> {
  const icon = await loadAiloIcon();
  const size = 256;

  const cv = document.createElement("canvas");
  cv.width = size;
  cv.height = size;

  const ctx = cv.getContext("2d");
  if (!ctx) return undefined;

  ctx.fillStyle = envVars.appColor;
  ctx.fillRect(0, 0, size, size);
  ctx.drawImage(icon, 0, 0);

  return cv.toDataURL();
}

function loadAiloIcon(): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve) => {
    const icon = new Image();

    icon.addEventListener("load", () => {
      resolve(icon);
    });

    icon.src = "/images/ailoWhiteA.png";
  });
}
