import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { Labeled } from "react-admin";
import { Wallet } from "../../../api";
import { AmountField, LinkField } from "../../../common";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";
import { TransferFundsButton } from "../../../features/Wallet/TransferFundsButton";
import { getPrimaryOwnerAilorn } from "./getPrimaryOwner";
import { getPropertyAddresses } from "./getPropertyAddresses";

export const ManagementFolioWalletSection = ({
  managementFolio,
}: {
  managementFolio: ManagementFolioShowRecord;
}): React.ReactElement => {
  const primaryOwnerAilorn = getPrimaryOwnerAilorn(managementFolio);
  const propertyAddresses = getPropertyAddresses(managementFolio);
  const managements = managementFolio.managements.items;
  const hasPublishedManagement = managements.some(
    (management) => !management.isDraft
  );

  const { wallet } = managementFolio;

  if (!wallet) {
    return hasPublishedManagement ? (
      <Alert severity="error">
        Could not find wallet even though Management Folio contains published
        managements
      </Alert>
    ) : (
      <Alert severity="info">
        Management Folio only contains draft managements and therefore has no
        wallet
      </Alert>
    );
  }

  return (
    <>
      <Labeled label="Wallet ID">
        <LinkField sourceResource={Wallet} record={wallet} />
      </Labeled>

      <Grid style={{ display: "flex" }}>
        <Labeled label="Available Balance ($)">
          <AmountField record={wallet.availableBalance} />
        </Labeled>

        {primaryOwnerAilorn && (
          <TransferFundsButton
            legalEntityId={primaryOwnerAilorn.toString()}
            wallets={[wallet]}
            transactionDescription={propertyAddresses}
          />
        )}
      </Grid>

      <Labeled label="Total Balance($)">
        <AmountField record={wallet.totalBalance} />
      </Labeled>
    </>
  );
};
