import { MoneyInterface } from "@ailo/money";
import { Alert, Skeleton } from "@material-ui/lab";
import moment from "moment-timezone";
import { GET_LIST } from "ra-core";
import React from "react";
import { Bill } from "..";
import { MoneyField } from "../../common";
import { useQueryWithStore } from "../../common/api";

export function ExpensesDueField({
  billPayerAilorns,
  dueFeeAmount,
}: {
  billPayerAilorns: string[];
  dueFeeAmount?: MoneyInterface;
}): React.ReactElement {
  const { loading, error, data: bills } = useQueryWithStore({
    type: GET_LIST,
    resource: Bill,
    payload: {
      pagination: {
        page: 1,
        perPage: 10000,
      },
      filter: {
        payerId: billPayerAilorns,
        paid: false,
        status: ["APPROVED"],
        taxCategoryIdNotIn: ["MANAGEMENT_FEES"],
        dueDate: {
          // Should ideally use managementFolio.timezone
          lt: moment
            .tz("Australia/Sydney")
            .add(30, "days")
            .format("YYYY-MM-DD"),
        },
      },
    },
  });

  if (loading) return <Skeleton width={60} />;

  if (error) return <Alert severity="error">Error</Alert>;

  const dueBillAmountCents = (bills ?? [])
    .map((bill) => bill.amount.cents)
    .filter((cents) => cents > 0)
    .reduce((sum, current) => sum + current, 0);

  const expensesDue = {
    cents: (dueFeeAmount?.cents ?? 0) + dueBillAmountCents,
  };
  return <MoneyField record={{ expensesDue }} source="expensesDue" />;
}
