import React from "react";
import { LinkField } from "./LinkField";
import { Management } from "../../api/ResourceName";

export function ManagementField({ record, source }: any) {
  return (
    <LinkField
      record={record}
      source={`${source}.id`}
      sourceResource={Management}
    />
  );
}
ManagementField.defaultProps = {
  addLabel: true,
  label: "Management",
};
