/* eslint-disable @typescript-eslint/no-explicit-any */
import { uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { useQueryWithStore } from "react-admin";
import {
  OperationData,
  OperationParams,
  OperationType,
  TypedResourceName,
} from "../../api";

type Item = { id: string };

type PageInfo =
  | {
      total: number;
      hasNext: boolean;
      nextCursor?: string | null;
    }
  | {
      total: number;
      hasMore: boolean;
      nextCursor?: string | null;
    };

type PaginatedList<TItem> = {
  pageInfo: PageInfo;
  items: Array<TItem & Item>;
};

type PaginatedQueryPayload = {
  cursor?: {
    pageSize?: number | null;
    cursor?: string | null;
  };
};

type UseInfinitelyPaginatedQueryOptions<
  TResourceName extends string,
  TOperationType extends string | number | symbol,
  TPayload extends PaginatedQueryPayload
> = {
  resource: TResourceName;
  type: TOperationType;
  payload?: TPayload;
  pageSize?: number;
};

export type InfinitelyPaginatedQueryResult<TItem> = {
  loaded: boolean;
  error: any;
  data?: TItem[];
  loadMore?(): void;
  hasMore?: boolean;
  totalItemsCount?: number;
};

export function useInfinitelyPaginatedQuery<
  TResourceName extends TypedResourceName,
  TOperationType extends OperationType<TResourceName>
>(
  options: UseInfinitelyPaginatedQueryOptions<
    TResourceName,
    TOperationType,
    OperationParams<TResourceName, TOperationType, PaginatedQueryPayload>
  >
): OperationData<TResourceName, TOperationType> extends PaginatedList<any>
  ? InfinitelyPaginatedQueryResult<
      OperationData<TResourceName, TOperationType>["items"][number]
    >
  : never;

export function useInfinitelyPaginatedQuery<
  TData extends PaginatedList<TItem> = {
    pageInfo: PageInfo;
    items: Array<any>;
  },
  TPayload extends PaginatedQueryPayload = any,
  TItem = TData extends {
    items: Array<infer TTItem>;
  }
    ? TTItem extends Item
      ? TTItem
      : unknown
    : unknown
>(
  options: UseInfinitelyPaginatedQueryOptions<string, string, TPayload>
): InfinitelyPaginatedQueryResult<TItem>;

export function useInfinitelyPaginatedQuery<
  TData extends PaginatedList<TItem> = {
    pageInfo: PageInfo;
    items: Array<any>;
  },
  TPayload extends PaginatedQueryPayload = any,
  TItem = TData extends {
    items: Array<infer TTItem>;
  }
    ? TTItem extends Item
      ? TTItem
      : unknown
    : unknown
>({
  type,
  resource,
  payload,
  pageSize = 50,
}: UseInfinitelyPaginatedQueryOptions<
  string,
  string,
  TPayload
>): InfinitelyPaginatedQueryResult<TItem> {
  const [prevItems, setPrevItems] = useState<TItem[]>([]);
  const [cursor, setCursor] = useState<string>();
  const { loaded, error, data } = <
    {
      loaded: boolean;
      error: any;
      data?: TData;
    }
  >useQueryWithStore({
    type,
    resource,
    payload: <TPayload>{
      ...payload,
      cursor: {
        pageSize,
        cursor,
      },
    },
  });
  const allItemsLoaded = prevItems.length > 0 || loaded;
  const allItems = useMemo(
    () =>
      allItemsLoaded
        ? uniqBy(
            [...prevItems, ...(data?.items ?? [])],
            (item) => ((item as unknown) as Item).id
          )
        : undefined,
    [data?.items, allItemsLoaded, prevItems]
  );

  const loadMore = useCallback(() => {
    if (!data?.pageInfo.nextCursor) {
      return;
    }

    setPrevItems((existingPrevItems) => [...existingPrevItems, ...data.items]);
    setCursor(data.pageInfo.nextCursor);
  }, [data?.items, data?.pageInfo.nextCursor]);
  return {
    loaded: allItemsLoaded,
    error,
    data: allItems,
    loadMore,
    hasMore:
      data?.pageInfo &&
      ("hasNext" in data.pageInfo
        ? data.pageInfo.hasNext
        : data.pageInfo.hasMore),
    totalItemsCount: data?.pageInfo.total,
  };
}
