import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from "react-admin";
import gql from "graphql-tag";
import { AiloRN, services } from "@ailo/ailorn";
import { parseSortParam } from "../../common";
import type {
  RaDataGraphqlClientArgs,
  Paginated,
  Supplier,
} from "../../deprecatedTypes";
import { removeWhitespace, transformSupplierForRequest } from "../../common";
import { buildPaginationVariables } from "../../common";
import { GetSuppliersQuery } from "./supplierClient.generated";
import { MutationFindOrCreateInternalSupplierArgs } from "../../graphql/types.generated";

const ARCHIVE_SUPPLIER = "archive_supplier";
const UPDATE_PAYMENT_METHOD = "update_payment_method";
export const FIND_OR_CREATE_INTERNAL_SUPPLIER =
  "find_or_create_internal_supplier";

// TODO: use this fragment when we upgrade to Apollo Client 3.0+ and can
// provide `possibleTypes` as an option to the client
// https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
// const ActionInitiatorFragment = gql`
//   fragment ActionInitiator on ActionInitiator {
//     __typename
//     ... on User {
//       id
//       ailoRN
//     }
//     ... on System {
//       ailoRN
//     }
//   }
// `;
function supplierClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_MANY:
      return {
        query: gql`
          query getSuppliers(
            $conditions: SuppliersQueryInput!
            $suppliersCursor: PageCursor!
          ) {
            suppliers(cursor: $suppliersCursor, conditions: $conditions) {
              pageInfo {
                total
                hasMore
                nextCursor
              }
              items {
                name
                ailoRN
              }
            }
          }
        `,
        variables: {
          conditions: {
            organisationReference: AiloRN.of(
              services.AuthZ.organisation,
              params.organisationId
            ).toString(),
          },
          suppliersCursor: {
            pageSize: 1000,
          },
        },
        parseResponse: (response: { data: GetSuppliersQuery }) => {
          const { suppliers } = response.data ?? {};
          const { pageInfo, items } = suppliers ?? {};
          const { total, nextCursor, hasMore } = pageInfo ?? {};
          return {
            data: items,
            total,
            nextCursor,
            hasMore,
          };
        },
      };

    case GET_LIST:
      return {
        query: gql`
          query suppliers(
            $pageSize: Int
            $cursor: String
            $sort: String
            $conditions: SuppliersQueryInput
          ) {
            suppliers(
              cursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
              conditions: $conditions
            ) {
              pageInfo {
                hasMore
                nextCursor
                total
              }
              items {
                id
                name
                abn
                organisation {
                  id
                  name
                }
                kycDataComplete
                createdAt
                createdBy: createdByV2 {
                  __typename
                  ... on User {
                    id
                    ailoRN
                  }
                  ... on System {
                    ailoRN
                  }
                }
                archivedAt
              }
            }
          }
        `,
        variables: {
          ...buildPaginationVariables(params.pagination),
          sort: parseSortParam(params.sort),
          conditions: {
            nameLike: params.filter.searchTerm,
            organisationReference: params.filter.organisationId
              ? `ailo:authz:organisation:${params.filter.organisationId}`
              : null,
            abnLike: params.filter.abnLike || undefined,
            archived:
              params.filter.status === "archived"
                ? true
                : params.filter.status === "active"
                ? false
                : undefined,
            kycDataComplete:
              params.filter.kycDataComplete === "complete"
                ? true
                : params.filter.kycDataComplete === "incomplete"
                ? false
                : undefined,
          },
        },
        parseResponse: (response: {
          data: {
            suppliers: Paginated<Supplier>;
          };
        }) => {
          const { suppliers } = response.data;
          const { pageInfo, items } = suppliers;
          const { total, nextCursor, hasMore } = pageInfo;
          return {
            data: items,
            total,
            nextCursor,
            hasMore,
          };
        },
      };

    case GET_ONE:
      return {
        query: gql`
          query supplier($id: ID!) {
            supplier(id: $id) {
              id
              ailoRN
              registeredEntityName
              name
              abn
              emailAddress
              address {
                unitStreetNumber
                streetName
                suburb
                postcode
                state
                country
              }
              organisation {
                id
                ailoRN
                name
              }
              kycDataComplete
              createdAt
              createdBy: createdByV2 {
                __typename
                ... on User {
                  id
                  ailoRN
                }
                ... on System {
                  ailoRN
                }
              }
              modifiedAt
              modifiedBy {
                __typename
                ... on User {
                  id
                  ailoRN
                }
                ... on System {
                  ailoRN
                }
              }
              archivedAt
              archivedBy: archivedByV2 {
                __typename
                ... on User {
                  id
                  ailoRN
                }
                ... on System {
                  ailoRN
                }
              }
            }
          }
        `,
        variables: {
          id: String(params.id),
        },
        parseResponse: (response: any) => {
          return {
            data: {
              ...response.data.supplier.address,
              ...response.data.supplier,
            },
          };
        },
      };

    case FIND_OR_CREATE_INTERNAL_SUPPLIER:
      return {
        query: gql`
          mutation findOrCreateInternalSupplier(
            $supplierDetails: InternalSupplierInput!
          ) {
            findOrCreateInternalSupplier(supplierDetails: $supplierDetails) {
              ailoRN
            }
          }
        `,
        variables: <MutationFindOrCreateInternalSupplierArgs>{
          supplierDetails: params,
        },
        parseResponse: ({ data }: { data: any }) => ({ data }),
      };

    case CREATE:
      return {
        query: gql`
          mutation createSupplier(
            $organisation: AiloRN!
            $abn: String
            $name: String!
            $registeredEntityName: String
            $address: SupplierAddressInput!
            $emailAddress: String
          ) {
            createSupplier(
              input: {
                organisationAiloRN: $organisation
                abn: $abn
                name: $name
                registeredEntityName: $registeredEntityName
                address: $address
                emailAddress: $emailAddress
              }
            ) {
              id
            }
          }
        `,
        variables: removeWhitespace(transformSupplierForRequest(params.data)),
        parseResponse: (response: {
          data: {
            createSupplier: Supplier;
          };
        }) => ({
          data: response.data.createSupplier,
        }),
      };

    case UPDATE_PAYMENT_METHOD:
      return {
        query: gql`
          mutation updateSupplierPaymentMethod(
            $id: ID!
            $paymentMethod: SupplierPaymentMethodInput
            $replaceExistingDueBillsPaymentMethod: Boolean
          ) {
            updateSupplier(
              input: {
                id: $id
                paymentMethod: $paymentMethod
                replaceExistingDueBillsPaymentMethod: $replaceExistingDueBillsPaymentMethod
              }
            ) {
              id
            }
          }
        `,
        variables: {
          id: params.id,
          paymentMethod: params.paymentMethod,
          replaceExistingDueBillsPaymentMethod:
            params.replaceExistingDueBillsPaymentMethod,
        },
      };

    case ARCHIVE_SUPPLIER:
      return {
        query: gql`
          mutation archiveSupplier($supplierId: ID!) {
            archiveSupplier(supplierId: $supplierId) {
              id
              archivedAt
              archivedBy: archivedByV2 {
                __typename
                ... on User {
                  id
                  ailoRN
                }
                ... on System {
                  ailoRN
                }
              }
            }
          }
        `,
        variables: {
          supplierId: String(params.id),
        },
        parseResponse: (response: { data: { archiveSupplier: any } }) => ({
          data: response.data.archiveSupplier,
        }),
      };
    case UPDATE: {
      const {
        abn,
        address,
        id,
        registeredEntityName,
        name,
        emailAddress,
      } = removeWhitespace(transformSupplierForRequest(params.data));
      const input = {
        abn,
        address,
        id,
        registeredEntityName,
        name,
        emailAddress,
      };

      return {
        query: gql`
          mutation updateSupplier($input: UpdateSupplierInput!) {
            updateSupplier(input: $input) {
              id
            }
          }
        `,
        variables: {
          input,
        },
        parseResponse: (response: any) => ({
          data: response.data.updateSupplier,
        }),
      };
    }
    case DELETE:
    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { supplierClient };
