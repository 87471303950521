import React from "react";
import { AutocompleteInput, required, useInput } from "react-admin";
import { ReferenceInput } from "../../common";
import { FeeBlueprint as FeeBlueprintData } from "../../api/graphql/types.generated";
import { FeeBlueprint } from "../../api/ResourceName";

type FeeBlueprintInputProps = {
  managementId?: string;
  organisationId: string;
};

export function FeeBlueprintInput({
  managementId,
  organisationId,
}: FeeBlueprintInputProps): React.ReactElement {
  const {
    input: { onChange },
  } = useInput({ source: "feeBlueprint" });
  const {
    input: { onChange: onChangeFixedAmount },
  } = useInput({ source: "fixedAmount" });
  const {
    input: { onChange: onChangeOneWeekRentPercentage },
  } = useInput({ source: "oneWeekRentPercentage" });
  return (
    <ReferenceInput
      alwaysOn
      label="fee blueprint"
      source="feeBlueprintId"
      reference={FeeBlueprint}
      filterToQuery={() => ({
        notAppliedToManagementId: managementId,
        organisationId,
        status: "active",
      })}
      validate={[required()]}
    >
      <AutocompleteInput
        onSelect={(feeBlueprint?: FeeBlueprintData) => {
          onChange(feeBlueprint);
          onChangeFixedAmount(feeBlueprint?.fixedAmount);
          onChangeOneWeekRentPercentage(feeBlueprint?.oneWeekRentPercentage);
        }}
        options={{
          style: { width: "100%" },
          InputProps: {
            type: "search", // needed to stop 1password from trying to auto fill this field
          },
        }}
        resettable
      />
    </ReferenceInput>
  );
}
