import IconEdit from "@material-ui/icons/Edit";
import React, { ReactElement, useState } from "react";
import { Button } from "react-admin";
import { BulkUploadAutoWithdrawPlanDialog } from "./BulkUpdateAutoWithdrawPlanDialog";

export function BulkUpdateAutoWithdrawPlanButton(): ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={onClick}
        label="Bulk Update Management Auto Withdraw Plans"
      >
        <IconEdit />
      </Button>
      <BulkUploadAutoWithdrawPlanDialog open={open} onClose={onClose} />
    </>
  );
}
