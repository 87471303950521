import { GET_ONE, useQuery } from "react-admin";
import { CentrepayCrn } from "../../../api/ResourceName";

export function useFetchExistingCrn(
  legalEntity: string,
  managingEntity: string
): string {
  const { data: existingCrn } = useQuery({
    type: GET_ONE,
    resource: CentrepayCrn,
    payload: {
      input: {
        legalEntity,
        managingEntity,
      },
    },
  });
  return existingCrn;
}
