import { CREATE, DELETE, GET_ONE } from "react-admin";
import currency from "currency.js";
import gql from "graphql-tag";
import { AiloRN } from "@ailo/ailorn";
import type { RaDataGraphqlClientArgs, Rent } from "../../deprecatedTypes";
import { removeWhitespace, transformRentForRequest } from "../../common";

function rentsClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query rent($id: AiloRN!) {
            rent(id: $id) {
              id
              amountInCents
              period
              effectiveDate
              category
            }
          }
        `,
        variables: {
          id: params.id,
        },
        parseResponse: (response: {
          data: {
            rent: Rent;
          };
        }) => ({
          data: {
            ...response.data.rent,
            amountInCents: currency(response.data.rent.amountInCents).divide(
              100
            ),
          },
        }),
      };
    case CREATE:
      return {
        query: gql`
          mutation createRent($input: CreateRentsInput!) {
            createRents(input: $input) {
              rents {
                id
                amountInCents
                period
                effectiveDate
                category
              }
            }
          }
        `,
        variables: removeWhitespace(transformRentForRequest(params.data)),
        parseResponse: (response: {
          data: {
            createRents: {
              rents: Rent[];
            };
          };
        }) => {
          return {
            data: response.data.createRents.rents[0],
          };
        },
      };
    case DELETE:
      return {
        query: gql`
          mutation deleteRent($input: DeleteRentInput!) {
            deleteRent(input: $input) {
              rentId
            }
          }
        `,
        variables: {
          input: { rentId: AiloRN.fromString(params.rentId).internalId },
        },
        parseResponse: (response: {
          data: {
            deleteRent: {
              rentId: string;
            };
          };
        }) => {
          return {
            data: response.data.deleteRent,
          };
        },
      };
    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { rentsClient };
