import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

type Props = {
  open: boolean;
  title: string;
  description: string | React.ReactElement;
  onClose: () => void;
  onAgree: () => void;
  onDisagree: () => void;
};
export const ConfirmationDialog = ({
  open,
  title,
  description,
  onClose,
  onAgree,
  onDisagree,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} color="secondary">
          Cancel
        </Button>
        <Button onClick={onAgree} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
