import { List, Map } from "immutable";

export const getCrudGetListParams = (
  state: Record<string, any>,
  resource: string
) => {
  const { filter, order, sort, perPage, page } = state.admin.resources[
    resource
  ].list.params;
  const customPagination = state.pagination.get(resource, List()).last();
  return {
    pagination: Map({
      perPage,
      page,
    })
      .merge(customPagination)
      .toJS(),
    sort: {
      field: sort,
      order,
    },
    filter,
  };
};
