import { AiloRN } from "@ailo/ailorn";
import { DateRange, LocalDate, RecurringDate } from "@ailo/date";
import { Divider } from "@material-ui/core";
import React from "react";
import { OperationData } from "../../api";
import {
  CopyToClipboardButton,
  DescriptionList,
  formatMonthlyFrequency,
} from "../../common";
import { MutationSwitch } from "../../common/ui/buttons/MutationSwitch";
import { isTruthy } from "../../common/utils/typeHelpers/isTruthy";
import { AutoWithdrawPlan, ManagementFolio } from "../../resourceViews";
import { PaymentMethodDestinations } from "../LegalEntity/PaymentMethodDestinations";
import { StatusDisabled, StatusEnabled } from "./Status";
import { SubHeading } from "./SubHeading";
import { SwitchContainer } from "./SwitchContainer";

type AutoWithdrawPlanRecord = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<OperationData<typeof ManagementFolio, "GET_ONE">>["wallet"]
    >["autoWithdrawPlans"]
  >["items"]
>[number];

export function AutoWithdrawPlanItem({
  edit,
  walletOwnerReference,
  autoWithdrawPlan,
}: {
  edit: boolean;
  walletOwnerReference: string;
  autoWithdrawPlan: AutoWithdrawPlanRecord;
}): React.ReactElement {
  const dateRange = new DateRange({
    start: LocalDate.from(autoWithdrawPlan.details.startDate),
    end: autoWithdrawPlan.details.endDate
      ? LocalDate.from(autoWithdrawPlan.details.endDate)
      : undefined,
  });

  const walletOwnerAilorn = AiloRN.from(walletOwnerReference);

  return (
    <>
      <DescriptionList
        descriptionStyle={{
          textAlign: "right",
        }}
        items={[
          {
            name: <SubHeading variant="subtitle2">Auto Transfer</SubHeading>,
            description: (
              <SwitchContainer>
                {!edit ? (
                  autoWithdrawPlan.enabled ? (
                    StatusEnabled
                  ) : (
                    StatusDisabled
                  )
                ) : (
                  <MutationSwitch
                    checked={autoWithdrawPlan.enabled}
                    mutationParams={
                      autoWithdrawPlan.enabled && {
                        type: "cancel_auto_withdraw_plan",
                        resource: AutoWithdrawPlan,
                        payload: {
                          autoWithdrawPlanId: autoWithdrawPlan.id,
                        },
                      }
                    }
                    confirmation={{
                      title: "Remove Auto Transfer",
                      content:
                        "Are you sure you want to remove this auto transfer?",
                    }}
                    successNotification="Auto Transfer has been removed"
                    failureNotification="Remove auto transfer failed"
                  />
                )}
              </SwitchContainer>
            ),
          },

          autoWithdrawPlan && {
            name: "Transfer from",
            description: walletOwnerAilorn.isA("authz:legalentity") ? (
              <>
                Personal Wallet ({autoWithdrawPlan.owner})
                <CopyToClipboardButton value={autoWithdrawPlan.owner} />
              </>
            ) : walletOwnerAilorn.isA("propertymanagement:managementfolio") ? (
              "Management Folio Wallet"
            ) : (
              <>
                Unknown Wallet Type ({autoWithdrawPlan.owner})
                <CopyToClipboardButton value={autoWithdrawPlan.owner} />
              </>
            ),
          },

          autoWithdrawPlan && {
            name: "Payment destinations",
            description: (
              <PaymentMethodDestinations
                paymentMethodDestinations={
                  autoWithdrawPlan.details.paymentMethodDestinations
                }
              />
            ),
          },
          autoWithdrawPlan && {
            name: "Frequency",
            description:
              autoWithdrawPlan.details.frequency === "monthly"
                ? formatMonthlyFrequency(
                    autoWithdrawPlan.details.anniversaryDaysOfMonth ?? [],
                    autoWithdrawPlan.details.isLastDayOfTheMonth ?? false
                  )
                : new RecurringDate({
                    frequency: autoWithdrawPlan.details.frequency,
                    startDate: autoWithdrawPlan.details.startDate,
                  }).format({ type: "F-ly on D" }),
          },
          autoWithdrawPlan && {
            name: "Next payment",
            description: LocalDate.from(
              autoWithdrawPlan.details.nextFireTime
            ).format("DD MMM YYYY"),
          },
          dateRange.end && {
            name: "End date",
            description: dateRange.end?.format(),
          },
          autoWithdrawPlan.owner && {
            name: "Owner",
            description: (
              <>
                {autoWithdrawPlan.owner}
                <CopyToClipboardButton value={autoWithdrawPlan.owner} />
              </>
            ),
          },
        ].filter(isTruthy)}
      />
      <Divider style={{ marginTop: 12 }} />
    </>
  );
}
