import Grid from "@material-ui/core/Grid";
import React from "react";
import {
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { Liability } from "../../api";
import { IdField, LinkField, MoneyField } from "../../common";
import { RefundTransactionField } from "./refundTransactionField";
import { ReverseTransactionField } from "./reverseTransactionField";
import { VoidTransactionField } from "./voidTransactionField";

const TransactionShow = (props: Record<string, any>) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <IdField source="id" />
        <TextField source="type" />
        <MoneyField source="transactionAmount" />
        <MoneyField source="requestedAmount" />
        <MoneyField label="Fee" source="feeAmount" />
        <TextField label="Current Status" source="transactionStatus.status" />
        <LinkField source="payer" />
        <DateField source="createdAt" showTime />
        <DateField
          label="Cleared at"
          source="transactionStatus.clearedAt"
          showTime
        />
        <TextField source="paymentChannel" defaultValue="N/A" />
        <TextField
          label="Payment Method Type"
          source="paymentMethod.__typename"
          defaultValue="N/A"
        />
        <FunctionField
          label="Payment Method"
          render={(record: any) => {
            switch (record.paymentMethod?.__typename) {
              case "BankAccount":
                const details = `Num: ${record.paymentMethod?.accountNumber} BSB: ${record.paymentMethod?.bsb}`;
                return <span>{details}</span>;

              case "CreditCard":
                return (
                  <span>
                    {`${
                      record.paymentMethod?.type
                    } Num: ${record.paymentMethod?.number.toLocaleLowerCase()}`}
                  </span>
                );

              case "BPay":
                return <span>{record.paymentMethod?.billerCode}</span>;

              default:
                return <span>N/A</span>;
            }
          }}
        />
        <LinkField source="liability" sourceResource={Liability} />
        <LinkField label="Liability Reference" source="liability.reference" />
        <TextField
          label="Liability Category"
          source="liability.category"
          defaultValue="N/A"
        />
        <TextField
          label="Liability Tax Category"
          source="liability.taxCategory"
          defaultValue="N/A"
        />

        <FunctionField
          label="Actions"
          render={(record: any) => (
            <Grid container direction="row">
              <VoidTransactionField
                transaction={record.id}
                status={record?.transactionStatus?.status}
                paymentChannel={record?.paymentChannel}
              />
              <RefundTransactionField
                transactionId={record.id}
                status={record?.transactionStatus?.status}
                style={{
                  marginLeft: 8,
                }}
              />
              <ReverseTransactionField
                transactionId={record.id}
                status={record?.transactionStatus?.status}
                style={{
                  marginLeft: 8,
                }}
              />
            </Grid>
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export { TransactionShow };
