import React from "react";
import { OrganisationInput } from "../inputs";

export function OrganisationFilter(props: any) {
  return (
    <OrganisationInput
      allowEmpty
      emptyText="All organisations"
      {...props}
      options={{
        InputProps: {
          // Disable Chrome address autocomplete dropdown
          // ( https://github.com/marmelab/react-admin/issues/5620#issuecomment-773773732 )
          autoComplete: "off",
        },
      }}
    />
  );
}
