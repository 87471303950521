import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

export const CANCEL_AUTO_PAYMENTS = "cancel_auto_payments";
export function autoPaymentsClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CANCEL_AUTO_PAYMENTS:
      return {
        query: gql`
          mutation cancelAutoPay($liabilityId: ID!) {
            cancelAutoPay(cancelAutoPayInput: { liabilityId: $liabilityId }) {
              status
            }
          }
        `,
        variables: {
          liabilityId: params.liabilityId,
        },
        parseResponse: (response: { data: { cancelAutoPay: any } }) => ({
          data: response.data.cancelAutoPay,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
