import gql from "graphql-tag";
import { CREATE, GET_ONE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  GetOfflinePaymentProcessingEnabledQuery,
  UpsertOfflinePaymentProcessingEnabledMutation,
} from "./offlinePaymentProcessingEnabledClient.generated";

export const OfflinePaymentProcessingEnabledDefinition = {
  [GET_ONE]: (params: { tenancy: string }) => {
    return {
      query: gql`
        query getOfflinePaymentProcessingEnabled($tenancy: AiloRN!) {
          getOfflineProcessingEnabledForTenancy(tenancy: $tenancy) {
            tenancy
            enabled
          }
        }
      `,
      variables: {
        tenancy: params.tenancy,
      },
      parseResponse: (
        response: GraphQLResponse<GetOfflinePaymentProcessingEnabledQuery>
      ): { data: boolean } => {
        return {
          data: response.data.getOfflineProcessingEnabledForTenancy.enabled,
        };
      },
    };
  },
  [CREATE]: (params: { tenancy: string; enabled: boolean }) => {
    return {
      query: gql`
        mutation upsertOfflinePaymentProcessingEnabled(
          $input: OfflinePaymentProcessingEnabledInput!
        ) {
          upsertOfflineProcessingEnabledForTenancy(input: $input) {
            tenancy
            enabled
          }
        }
      `,
      variables: params,
      parseResponse: (
        response: GraphQLResponse<UpsertOfflinePaymentProcessingEnabledMutation>
      ): { data: boolean } => {
        return {
          data: response.data.upsertOfflineProcessingEnabledForTenancy.enabled,
        };
      },
    };
  },
};

export const offlinePaymentProcessingEnabledClient = createClientFromDefinition(
  OfflinePaymentProcessingEnabledDefinition
);
