import { AiloRN, services } from "@ailo/ailorn";
import { GET_LIST, GET_MANY, GET_ONE } from "react-admin";
import gql from "graphql-tag";
import { parseSortParam, buildPaginationVariables } from "../../common";
import type {
  RaDataGraphqlClientArgs,
  Organisation,
  Paginated,
  Team,
} from "../../deprecatedTypes";

const MOVE_PEOPLE_TO_TEAM = "move_people_to_team";

const MOVE_MANAGEMENTS_TO_TEAM = "move_managements_to_team";

const ADD_MEMBER = "add_member";

const REMOVE_MEMBER = "remove_member";

function organisationClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_LIST:
      return {
        query: gql`
          query organisations(
            $cursor: String
            $pageSize: Int
            $sort: String
            $searchTerm: String
            $orgType: OrganisationType
          ) {
            organisations(
              pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
              search: $searchTerm
              orgType: $orgType
            ) {
              pageInfo {
                hasMore
                nextCursor
                total
              }
              items {
                ...Organisation
              }
            }
          }
          ${organisationFragment}
        `,
        variables: {
          ...buildPaginationVariables(params.pagination),
          sort: parseSortParam(params.sort),
          searchTerm: params.filter.searchTerm,
          orgType: params.filter.orgType,
        },
        parseResponse: (response: {
          data: {
            organisations: Paginated<Organisation>;
          };
        }) => {
          const { organisations } = response.data;
          const { pageInfo, items } = organisations;
          const { total, nextCursor, hasMore } = pageInfo;
          return {
            data: items,
            total,
            nextCursor,
            hasMore,
          };
        },
      };

    case GET_ONE:
      return {
        query: gql`
          query organisation($id: ID!) {
            organisation(id: $id) {
              ...OrganisationFull
            }
          }
          ${organisationFullFragment}
        `,
        variables: {
          id: String(params.id),
        },
        parseResponse: (response: {
          data: {
            organisation: Organisation;
          };
        }) => {
          return {
            data: response.data.organisation,
          };
        },
      };

    case GET_MANY:
      if (params.ids.length === 1) {
        return {
          query: gql`
            query getOneOrganisation($id: ID!) {
              organisation(id: $id) {
                ...OrganisationFull
              }
            }
            ${organisationFullFragment}
          `,
          variables: {
            id: String(params.ids[0]),
          },
          parseResponse: (response: {
            data: {
              organisation: Organisation;
            };
          }) => {
            return {
              data: [response.data.organisation],
            };
          },
        };
      }

      throw new Error(`${raFetchType} is not yet implemented`);

    case MOVE_PEOPLE_TO_TEAM:
      return {
        query: gql`
          mutation movePeopleToTeam($memberIds: [AiloRN!]!, $teamId: AiloRN!) {
            movePeopleToTeam(memberIds: $memberIds, teamId: $teamId) {
              id
              name
            }
          }
        `,
        variables: {
          memberIds: params.memberIds.map((id: string | number) =>
            AiloRN.of(services.AuthZ.legalEntity, id)
          ),
          teamId: params.teamId,
        },
        parseResponse: (response: {
          data: {
            movePeopleToTeam: Team;
          };
        }) => ({
          data: response.data.movePeopleToTeam.id,
        }),
      };

    case MOVE_MANAGEMENTS_TO_TEAM:
      return {
        query: gql`
          mutation moveManagementsToTeam(
            $managementIds: [AiloRN!]!
            $teamId: AiloRN!
          ) {
            moveManagementsToTeam(
              managementIds: $managementIds
              teamId: $teamId
            ) {
              id
              name
            }
          }
        `,
        variables: {
          managementIds: params.managementIds,
          teamId: params.teamId,
        },
      };

    case REMOVE_MEMBER:
      return {
        query: gql`
          mutation removeMemberFromOrganisation(
            $input: RemoveMemberFromOrganisationInput!
          ) {
            removeMemberFromOrganisation(input: $input)
          }
        `,
        variables: {
          input: params.input,
        },
      };

    case ADD_MEMBER:
      return {
        query: gql`
          mutation addMemberToCompany(
            $personId: String!
            $organisationId: String!
            $orgRoleId: String!
            $legalEntityId: String!
            $leRoleId: String!
          ) {
            addMemberToOrganisation(
              input: {
                personId: $personId
                organisationId: $organisationId
                roleId: $orgRoleId
              }
            ) {
              role {
                id
              }
            }
            addMemberToLegalEntity(
              input: {
                memberPersonId: $personId
                legalEntityId: $legalEntityId
                roleId: $leRoleId
              }
            ) {
              role {
                id
              }
            }
          }
        `,
        variables: {
          ...params,
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

const organisationMembershipsFragment = gql`
  fragment OrganisationMemberships on Organisation {
    memberships {
      organisation {
        id
        legalEntities {
          id
        }
      }
      member {
        id
        firstName
        lastName
        team(organisationId: $id) {
          id
          name
        }
      }
      role {
        id
        name
      }
    }
  }
`;
const organisationTeamsFragment = gql`
  fragment OrganisationTeams on Organisation {
    teams {
      id
      name
      managements(pageCursor: { pageSize: 0 }) {
        pageInfo {
          total
        }
      }
      activeManagements: managements(
        pageCursor: { pageSize: 0 }
        filter: { name: Active }
      ) {
        pageInfo {
          total
        }
      }
    }
  }
`;
const organisationFragment = gql`
  fragment Organisation on Organisation {
    id
    ailorn: ailoRN
    name
    orgType
    createdAt
    legalEntities {
      id
      ... on Company {
        registeredEntityId
        registeredEntityName
      }
      ... on Person {
        firstName
        lastName
      }
    }
    activeSubscriptions(cursor: { pageSize: 100 }) {
      pageInfo {
        total
        hasMore
        nextCursor
      }
      items {
        id
        plan {
          id
          price {
            cents
          }
        }
        startsOn
        endsOn
      }
    }
    ...OrganisationTeams
  }
  ${organisationTeamsFragment}
`;
const organisationFullFragment = gql`
  fragment OrganisationFull on Organisation {
    id
    ailorn: ailoRN
    name
    orgType
    createdAt
    legalEntities {
      id
      ... on Company {
        registeredEntityId
        registeredEntityName
      }
      ... on Person {
        firstName
        lastName
      }
    }
    availableFeatures {
      id
    }
    activeSubscriptions(cursor: {}) {
      pageInfo {
        hasMore
        nextCursor
        total
      }
      items {
        id
        plan {
          id
          price {
            cents
          }
        }
        startsOn
        endsOn
      }
    }
    pastSubscriptions(cursor: {}) {
      pageInfo {
        hasMore
        nextCursor
        total
      }
      items {
        id
        plan {
          id
          price {
            cents
          }
        }
        startsOn
        endsOn
      }
    }
    ...OrganisationMemberships
    ...OrganisationTeams
  }
  ${organisationMembershipsFragment}
  ${organisationTeamsFragment}
`;
export { organisationClient };
