import React from "react";
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";

/** 
 This loading indicator will only show 
 when the load is taking longer than 500ms 
 */
export function DelayedLinearProgress({ loading }: { loading: boolean }) {
  return (
    <Fade
      in={loading}
      style={{
        transition: "50ms",
        transitionDelay: loading ? "500ms" : "0ms",
      }}
      unmountOnExit
    >
      <LinearProgress />
    </Fade>
  );
}
