import gql from "graphql-tag";
import { flatMap, uniqBy } from "lodash";
import { CREATE, GET_LIST, GET_MANY, GET_ONE } from "react-admin";
import {
  buildPaginationVariables,
  bulkActionVars,
  createClientFromDefinition,
  GraphQLResponse,
  parseSortParam,
  removeWhitespace,
  transformManagementForRequest,
} from "../../common";
import type {
  Management,
  ManagementSetupResult,
  Paginated,
} from "../../deprecatedTypes";
import {
  ClearManagementEndMutation,
  ClearManagementEndMutationVariables,
  EndManagementV2Mutation,
  EndManagementV2MutationVariables,
  ManagementEndReasonsQuery,
  ManagementQuery,
  ManagementsForOwnerQuery,
} from "./managementsClient.generated";

const gqlignore = gql;

const PUBLISH = "publish";
const PUBLISH_MANY = "publish_many";
const GET_MANY_OWNERS_FOR_INVITE = "get_many_owners_for_invite";
const GET_MANY_TENANTS_FOR_INVITE = "get_many_tenants_for_invite";
const UPDATE_END_OF_TENANCY = "update_end_of_tenancy";
const END_MANAGEMENT = "end_management";
export const CLEAR_MANAGEMENT_END = "clear_management_end";
const GET_MANAGEMENT_END_REASONS = "get_management_end_reasons";
const GET_LIST_FOR_OWNER = "get_list_for_owner";
const DELETE_DRAFT = "delete_draft";
const GET_ALL_OWNERS = "get_all_owners";
const GET_ALL_TENANTS = "get_all_tenants";
const UPDATE_OWNER_SHARES = "update_owner_shares";
const SEND_STATEMENT = "send_statement";
const GENERATE_TENANCY_LEDGER_STATEMENT = "generate_tenancy_ledger_statement";
const GET_STATEMENT_URL = "get_statement_url";

const ManagementsClientDefinition = {
  [GET_LIST]: (params: any) => ({
    query: gql`
      query managements(
        $cursor: String
        $pageSize: Int
        $sort: String
        $organisationId: ID
        $searchTerm: String
        $withManagingEntity: Boolean!
      ) {
        managements(
          pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
          organisationId: $organisationId
          search: $searchTerm
        ) {
          pageInfo {
            hasMore
            nextCursor
            total
          }
          items {
            id
            createdAt
            isDraft
            endDate
            endReason {
              label
              causes {
                code
                label
              }
            }
            terminationReason
            property {
              id
              address {
                unitStreetNumber
                streetName
                suburb
                state
                country
                postcode
              }
            }
            owners {
              ownerId
              owner {
                id
                ... on Company {
                  registeredEntityId
                  registeredEntityName
                  owner {
                    id
                    user {
                      id
                      onboardedAt
                    }
                  }
                }
                ... on Person {
                  firstName
                  lastName
                  user {
                    id
                    onboardedAt
                  }
                }
              }
            }
            allTenancies {
              id
              startDate
              endDate
              voidedAt
              tenants {
                tenantId
                tenancyId
                tenant {
                  id
                  ... on Company {
                    registeredEntityId
                    registeredEntityName
                    owner {
                      id
                      user {
                        id
                        onboardedAt
                      }
                    }
                  }
                  ... on Person {
                    firstName
                    lastName
                    user {
                      id
                      onboardedAt
                    }
                  }
                }
              }
            }
            # We don't need the managing entity for \`ManagementInput\`
            # Also, some managements reference an entity that no longer exists in the authz-service
            # which causes GQL to return an error causing the entire input to break
            # https://ailo.atlassian.net/browse/BUG-313
            managingEntity @include(if: $withManagingEntity) {
              id
              registeredEntityId
              registeredEntityName
              organisation {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      ...buildPaginationVariables(params.pagination),
      sort: parseSortParam(params.sort),
      searchTerm: params.filter.searchTerm,
      organisationId: params.filter.organisationId,
      withManagingEntity: params.filter.withManagingEntity ?? true,
    },
    parseResponse: (response: {
      data: {
        managements: Paginated<Management>;
      };
    }) => {
      const { managements } = response.data;
      const { pageInfo, items } = managements;
      const { total, nextCursor, hasMore } = pageInfo;
      return {
        data: items,
        total,
        nextCursor,
        hasMore,
      };
    },
  }),

  [GET_LIST_FOR_OWNER]: (params: any) => ({
    query: gql`
      query managementsForOwner(
        $ownerId: AiloRN!
        $cursor: String
        $pageSize: Int
        $sort: String
      ) {
        managementsForOwner(
          ownerId: $ownerId
          pageCursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
        ) {
          pageInfo {
            hasMore
            nextCursor
            total
          }
          items {
            id
            createdAt
            isDraft
            managementFolio {
              id
              ailorn
            }
            property {
              id
              address {
                unitStreetNumber
                streetName
                suburb
                state
                country
                postcode
              }
            }
            mostRecentTenancy {
              id
              currentRent {
                id
                amountInCents
                period
              }
            }
          }
        }
      }
    `,
    variables: {
      ownerId: params.filter.ownerId,
    },
    parseResponse: (response: { data: ManagementsForOwnerQuery }) => {
      const { managementsForOwner } = response.data;
      const { pageInfo, items } = managementsForOwner!;
      const { total, nextCursor, hasMore } = pageInfo;
      return {
        data: items,
        total,
        nextCursor,
        hasMore,
      };
    },
  }),

  [GET_ONE]: (params: { id?: string }) => ({
    query: gql`
      query management($managementId: ID!) {
        management(managementId: $managementId) {
          ...Management
        }
      }
      ${ManagementFragment}
    `,
    variables: {
      managementId: params.id,
    },
    parseResponse: (response: {
      data: {
        management: ManagementQuery["management"];
      };
    }) => {
      return {
        data: response.data.management,
      };
    },
  }),

  [GET_MANY]: (params: any) => {
    if (params.ids.length === 1) {
      return {
        query: gql`
          query getManyManagements($managementId: ID!) {
            management(managementId: $managementId) {
              ...Management
            }
          }
          ${ManagementFragment}
        `,
        variables: {
          managementId: String(params.ids[0]),
        },
        parseResponse: (response: {
          data: {
            management: Management;
          };
        }) => {
          return {
            data: [response.data.management],
          };
        },
      };
    }

    throw new Error(`Managements.GET_MANY is not yet implemented`);
  },

  [GET_MANY_OWNERS_FOR_INVITE]: (params: any) => ({
    query: gqlignore/* GraphQL */ `
          query managementsOwners(${params.ids.map(
            (id: any, index: any) => `$id${index}: ID!`
          )}) {
            ${params.ids.map(
              (
                id: any,
                index: any
              ) => `managementId${index}: management(managementId: $id${index}) {
              id
              ${managementOwnersForInvitePartialQuery}
            }
            `
            )}
          }
        `,
    variables: { ...bulkActionVars(params.ids) },
    parseResponse: (response: { data: Record<string, any> }) => {
      return {
        data: {
          managements: params.ids.map(
            (id: any, index: any) => response.data[`managementId${index}`]
          ),
        },
      };
    },
  }),

  [GET_ALL_OWNERS]: (params: any) => ({
    query: gql`
      query getAllOwners($managementId: ID!) {
        management(managementId: $managementId) {
          id
          owners {
            owner {
              id
              ... on Company {
                registeredEntityName
              }
              ... on Person {
                firstName
                lastName
              }
            }
          }
        }
      }
    `,
    variables: {
      managementId: String(params.id),
    },
    parseResponse: (response: {
      data: {
        management: Management;
      };
    }) => {
      return {
        data: (response.data.management.owners || []).map(
          (entity) => entity.owner
        ),
      };
    },
  }),

  [GET_ALL_TENANTS]: (params: any) => ({
    query: gql`
      query getAllTenants($managementId: ID!) {
        management(managementId: $managementId) {
          id
          allTenancies {
            id
            tenants {
              tenancyId
              tenant {
                id
                ... on Company {
                  registeredEntityName
                }
                ... on Person {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      managementId: String(params.id),
    },
    parseResponse: (response: {
      data: {
        management: any;
      };
    }) => {
      return {
        data: uniqBy(
          flatMap(
            response?.data?.management?.allTenancies,
            ({ tenants }) => tenants
          ).map((entity) => entity.tenant),
          "id"
        ),
      };
    },
  }),

  [GET_MANY_TENANTS_FOR_INVITE]: (params: any) => ({
    query: gqlignore/* GraphQL */ `
          query managementsTenants(${params.ids.map(
            (id: any, index: any) => `$id${index}: ID!`
          )}) {
            ${params.ids.map(
              (
                id: any,
                index: any
              ) => `managementId${index}: management(managementId: $id${index}) {
              id
              ${managementTenantsForInvitePartialQuery}
            }
            `
            )}
          }
        `,
    variables: { ...bulkActionVars(params.ids) },
    parseResponse: (response: { data: Record<string, any> }) => {
      return {
        data: {
          managements: params.ids.map(
            (id: any, index: any) => response.data[`managementId${index}`]
          ),
        },
      };
    },
  }),

  [CREATE]: (params: any) => ({
    query: gql`
      mutation setupManagementAndTenancy(
        $propertyId: ID
        $property: PropertyInput
        $managingEntityId: ID!
        $management: ManagementSetupInput!
        $tenancy: TenancySetupInput
      ) {
        setupManagementAndTenancy(
          managementAndTenancyDetails: {
            propertyId: $propertyId
            property: $property
            managingEntityId: $managingEntityId
            management: $management
            tenancy: $tenancy
          }
        ) {
          management {
            id
            managingEntity {
              id
            }
            property {
              id
              address {
                unitStreetNumber
                streetName
                suburb
                state
                country
                postcode
              }
            }
            allTenancies {
              id
              endDate
            }
          }
        }
      }
    `,
    variables: removeWhitespace(transformManagementForRequest(params.data)),
    parseResponse: (response: {
      data: {
        setupManagementAndTenancy: ManagementSetupResult;
      };
    }) => {
      return {
        data: response.data.setupManagementAndTenancy.management,
      };
    },
  }),

  [PUBLISH]: (params: any) => ({
    query: gql`
      mutation publishManagement($id: ID!) {
        publishManagement(managementId: $id) {
          id
        }
      }
    `,
    variables: {
      id: params.id,
    },
    parseResponse: (response: {
      data: {
        publishManagement: {
          id: string;
        };
      };
    }) => {
      return {
        data: response.data.publishManagement.id,
      };
    },
  }),

  [PUBLISH_MANY]: (params: any) => ({
    query: gqlignore/* GraphQL */ `
          mutation publishManagements(${params.ids.map(
            (id: any, index: any) => `$id${index}: ID!`
          )}) {
            ${params.ids.map(
              (
                id: any,
                index: any
              ) => `publishManagement${index}: publishManagement(managementId: $id${index}) {
              id
            }
            `
            )}
          }
        `,
    variables: bulkActionVars(params.ids),
    parseResponse: (response: any) => {
      return {
        data: params.ids.map(
          (id: any, index: any) => response.data[`publishManagement${index}`].id
        ),
      };
    },
  }),

  [UPDATE_END_OF_TENANCY]: (params: any) => ({
    query: gql`
      mutation updateEndOfTenancy(
        $tenancyId: ID!
        $endDate: Date
        $reason: VacatingReason
        $notes: String
      ) {
        updateEndOfTenancy(
          tenancyId: $tenancyId
          endDate: $endDate
          reason: $reason
          notes: $notes
        ) {
          id
        }
      }
    `,
    variables: {
      tenancyId: params.tenancyId,
      endDate: params.endDate,
      reason: params.reason,
      notes: params.notes,
    },
    parseResponse: (response: {
      data: {
        updateEndOfTenancy: {
          id: string;
        };
      };
    }) => {
      return {
        data: response.data.updateEndOfTenancy.id,
      };
    },
  }),

  [GET_MANAGEMENT_END_REASONS]: () => ({
    query: gql`
      query managementEndReasons {
        managementEndReasons {
          code
          label
          causes {
            code
            label
          }
        }
      }
    `,
    parseResponse: (response: { data: ManagementEndReasonsQuery }) => ({
      data: response.data,
    }),
  }),

  [END_MANAGEMENT]: (params: any) => ({
    query: gql`
      mutation endManagementV2($input: EndManagementV2Input!) {
        endManagementV2(input: $input) {
          management {
            id
            endDate
            endReason {
              label
              causes {
                code
                label
              }
            }
            endNote
          }
        }
      }
    `,
    variables: <EndManagementV2MutationVariables>{
      input: {
        endCauseCodes: params.endCauses,
        endDate: params.endDate,
        endNote: params.endNote,
        endReasonCode: params.endReasonCode,
        managementId: params.managementId,
      },
    },
    parseResponse: (response: GraphQLResponse<EndManagementV2Mutation>) => ({
      data: response.data.endManagementV2,
    }),
  }),

  [CLEAR_MANAGEMENT_END]: (params: { managementId: string }) => ({
    query: gql`
      mutation clearManagementEnd($managementId: ID!) {
        clearManagementEnd(managementId: $managementId) {
          management {
            id
            endDate
            endAbility {
              canBeEnded
              problem
            }
          }
        }
      }
    `,
    variables: <ClearManagementEndMutationVariables>{
      managementId: params.managementId,
    },
    parseResponse: (response: GraphQLResponse<ClearManagementEndMutation>) => ({
      data: response.data.clearManagementEnd,
    }),
  }),

  [DELETE_DRAFT]: (params: any) => ({
    query: gql`
      mutation deleteDraftManagement($id: ID!) {
        deleteDraftManagement(managementId: $id)
      }
    `,
    variables: {
      id: params.id,
    },
    parseResponse: (response: {
      data: {
        deleteDraftManagement: string;
      };
    }) => {
      return {
        data: response.data.deleteDraftManagement,
      };
    },
  }),

  [UPDATE_OWNER_SHARES]: (params: any) => ({
    query: gql`
      mutation updateOwnerShares($id: ID!, $ownerShares: [OwnerShareInput!]!) {
        updateOwnerShares(managementId: $id, ownerShares: $ownerShares) {
          id
        }
      }
    `,
    variables: {
      id: params.id,
      ownerShares: params.ownerShares,
    },
    parseResponse: (response: {
      data: {
        updateOwnerShares: Management;
      };
    }) => ({
      data: response.data.updateOwnerShares.id,
    }),
  }),

  [SEND_STATEMENT]: (params: any) => ({
    query: gql`
      mutation createStatementsWithSubject(
        $managementAiloRN: AiloRN!
        $startDate: Date!
        $endDate: Date!
        $skipValidation: Boolean!
        $statementType: StatementType!
        $dryRunEmail: String
      ) {
        status: createStatementsWithSubject(
          input: {
            statementSubjectAiloRNs: [$managementAiloRN]
            startDate: $startDate
            endDate: $endDate
            sendEmail: true
            type: $statementType
            skipValidation: $skipValidation
            dryRunEmail: $dryRunEmail
          }
        ) {
          numberOfStatements
        }
      }
    `,
    variables: params.data,

    parseResponse(response: { data: { status: any } }) {
      return {
        data: response.data.status,
      };
    },
  }),

  [GENERATE_TENANCY_LEDGER_STATEMENT]: (params: any) => ({
    query: gql`
      mutation createTenancyLedgerStatement(
        $tenancyAiloRN: AiloRN!
        $startDate: Date!
        $endDate: Date!
      ) {
        createStatementsWithSubject(
          input: {
            type: TenancyLedger
            startDate: $startDate
            endDate: $endDate
            sendEmail: false
            statementSubjectAiloRNs: [$tenancyAiloRN]
            skipManagementHasPaymentsCheck: true
            createdFor: ManualRequest
          }
        ) {
          statementProgresses {
            id
          }
        }
      }
    `,
    variables: params.data,

    parseResponse(response: {
      data: {
        createStatementsWithSubject: {
          statementProgresses: [
            {
              id: string;
            }
          ];
        };
      };
    }) {
      return {
        data: {
          statementProgressesId: response.data.createStatementsWithSubject.statementProgresses.shift()
            ?.id,
        },
      };
    },
  }),

  [GET_STATEMENT_URL]: (param: any) => ({
    query: gql`
      query getStatementFileUrl($statementProgressId: ID!) {
        statementProgress(id: $statementProgressId) {
          isSuccessful
          statement {
            id
            file {
              id
              url
            }
          }
        }
      }
    `,
    variables: {
      statementProgressId: param.statementProgressId,
    },

    parseResponse(response: {
      data: {
        statementProgress: {
          isSuccessful: boolean;
          statement: {
            id: string;
            file: {
              id: string;
              url: string;
            };
          };
        };
      };
    }) {
      return {
        data: response.data.statementProgress,
      };
    },
  }),
};

const managementsClient = createClientFromDefinition(
  ManagementsClientDefinition
);

const ManagementFragment = gql`
  fragment Management on Management {
    id
    createdAt
    isDraft
    firstPublishedAt
    migratedAt
    endDate
    endReason {
      label
      causes {
        label
      }
    }
    endNote
    terminationReason
    terminationNotes
    managingEntity {
      id
      ailoRN
      registeredEntityId
      registeredEntityName
      organisation {
        id
        name
      }
    }
    property {
      id
      address {
        unitStreetNumber
        streetName
        suburb
        state
        country
        postcode
      }
    }
    owners {
      ownerId
      startDate
      endDate
      sharesOwned
      owner {
        id
        ailoRN
        ... on Company {
          registeredEntityId
          registeredEntityName
          owner {
            id
            user {
              id
              onboardedAt
              onboardingCompletedAt
            }
          }
        }
        ... on Person {
          firstName
          lastName
          user {
            id
            onboardedAt
            onboardingCompletedAt
          }
        }
      }
      consumerInvite {
        id
        metadata
      }
    }
    currentManagementAgreement {
      id
      createdAt
      startDate
      fixedTermEndDate
    }

    allManagementAgreements {
      id
      createdAt
      startDate
      fixedTermEndDate
    }

    allTenancies {
      id
      ailoRN
      isDraft
      createdAt
      startDate
      endDate
      voidedAt
      vacatingReason
      vacatingNotes
      mostRecentTenancyAgreement {
        id
      }
      currentRent {
        id
        amountInCents
        period
      }
      currentRentSchedule {
        id
        amountInCents
        period
      }
      liability {
        id
        overdueAmount {
          cents
        }
        paidToDate
        effectivePaidToDate
      }
      tenants {
        startDate
        endDate
        tenancyId
        tenant {
          id
          ailoRN
          ... on Company {
            registeredEntityId
            registeredEntityName
            owner {
              id
              user {
                id
                onboardedAt
                onboardingCompletedAt
              }
            }
          }
          ... on Person {
            firstName
            lastName
            user {
              id
              onboardedAt
              onboardingCompletedAt
            }
          }
        }
        consumerInvite {
          id
          metadata
        }
      }
      tenancyAgreements {
        items {
          id
          startDate
          fixedTermEndDate
        }
      }
      rents {
        items {
          id
          amountInCents
          period
          effectiveDate
          category
          rentSchedules {
            id
            amountInCents
            period
            startDate
            proRata
          }
        }
      }
    }
  }
`;
const legalEntityUserIdPartialQuery = `
  ... on Company {
    id
    owner {
      id
      user {
        id
      }
    }
  }
  ... on Person {
    id
    user {
      id
    }
  }
`;
const managementOwnersForInvitePartialQuery = `
  owners {
    owner {
      id
      ${legalEntityUserIdPartialQuery}
    }
  }
`;
const managementTenantsForInvitePartialQuery = `
  allTenancies {
    id
    tenants {
      tenant {
        id
        ${legalEntityUserIdPartialQuery}
      }
    }
  }
`;
export {
  managementsClient,
  ManagementsClientDefinition,
  managementOwnersForInvitePartialQuery,
  managementTenantsForInvitePartialQuery,
  GENERATE_TENANCY_LEDGER_STATEMENT,
  GET_STATEMENT_URL,
};
