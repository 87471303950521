import React from "react";
import { Edit } from "../../common";
import { SupplierForm } from "./SupplierForm";

const SupplierEdit = (
  props: React.ComponentProps<typeof Edit>
): React.ReactElement => {
  return (
    <Edit redirect="show" {...props}>
      <SupplierForm
        redirect="show"
        toolbarOptions={{
          deleteButton: false,
        }}
      />
    </Edit>
  );
};

export { SupplierEdit };
