import { formatAddress } from "@ailo/domain-helpers";
import { Grid, Typography } from "@material-ui/core";
import { stringify } from "query-string";
import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  Error,
  FunctionField,
  SimpleListLoading,
} from "react-admin";
import { OperationData, Property, Tenancy } from "../../../api";
import { MoneyField, useQueryWithStore } from "../../../common";
import { TenantMoneySettingsCard } from "../../../features/LegalEntity";
import { getTenancyType, TenancyTypeBadge } from "../../../features/Tenancy";
import { LegalEntityShowRecord } from "./LegalEntityShowRecord";

type TenancyRecord = NonNullable<
  OperationData<typeof Tenancy, "get_list_for_tenant">
>[number];

export function TenanciesTab({
  record,
}: {
  record: LegalEntityShowRecord;
}): React.ReactElement {
  const { data, loading, error } = useQueryWithStore({
    type: "get_list_for_tenant",
    resource: Tenancy,
    payload: {
      tenantAilorn: record.ailoRN,
      pagination: { perPage: 1000 },
    },
  });

  if (error) {
    return <Error error={error} />;
  }

  if (loading || !data) {
    return <SimpleListLoading />;
  }

  const tenancies = data ?? [];

  if (tenancies.length === 0) {
    return (
      <Typography style={{ padding: 20, backgroundColor: "white" }}>
        This customer has not been part of any tenancies
      </Typography>
    );
  }

  return (
    <Grid container spacing={2}>
      <ArrayField source="tenancies" record={{ id: "", tenancies }}>
        <Datagrid
          currentSort={{ field: "startDate", order: "DESC" }}
          rowClick={(_id, _resource, tenancy) => {
            const propertyId = tenancy?.management?.property.id;
            if (!propertyId) return "";
            const searchParam = stringify({
              tab: "Tenancy",
              management: tenancy.management.id,
              tenancy: tenancy.id,
            });
            return `/${Property}/${propertyId}/show/?${searchParam}`;
          }}
        >
          <FunctionField<TenancyRecord>
            label="Property Address"
            render={(tenancy) => {
              const address = tenancy?.management?.property.address;
              const addressText = address
                ? formatAddress(address, {
                    format: "street, suburb, state, postcode",
                  })
                : "";
              return addressText;
            }}
          />
          <FunctionField<TenancyRecord>
            label="Status"
            render={(tenancy) => {
              const tenancyType = getTenancyType(
                tenancy?.startDate,
                tenancy?.endDate
              );
              return <TenancyTypeBadge tenancyType={tenancyType} />;
            }}
          />
          <FunctionField<TenancyRecord>
            label="Rent Amount"
            render={(tenancy) => {
              const displayRent = tenancy?.displayRent;
              const displayRentRecord = displayRent
                ? { id: tenancy?.id, cents: +displayRent.amountInCents }
                : undefined;
              return (
                <MoneyField
                  record={{ displayRent: displayRentRecord }}
                  source="displayRent"
                  frequency={displayRent?.period}
                />
              );
            }}
          />
          <DateField label="Start Date" source="startDate" />
          <DateField label="End Date" source="endDate" />
        </Datagrid>
      </ArrayField>

      <Grid item sm={12} md={6}>
        <TenantMoneySettingsCard legalEntityAiloRN={record.ailoRN} allowEdit />
      </Grid>
    </Grid>
  );
}
