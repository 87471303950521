import Grid from "@material-ui/core/Grid";
import React from "react";
import { isPresent } from "ts-is-present";
import { OperationData } from "../../../api";
import { Person, Property } from "../../../api/ResourceName";
import { LegalEntityDetailCard } from "../../../features/LegalEntity";
import { TenantsCentrepayInfo } from "../../TenantsCentrepay/TenantsCentrepayInfo";

export function TenantsList({
  tenancy,
  managingEntity,
}: {
  tenancy: NonNullable<
    OperationData<
      typeof Property,
      "GET_ONE"
    >["managements"][number]["mostRecentTenancy"]
  >;
  managingEntity?: string;
}): React.ReactElement {
  return (
    <Grid container spacing={0}>
      {tenancy.tenants?.filter(isPresent).map((tenantship) => {
        const { tenant } = tenantship;
        return (
          <Grid item xs={6} key={tenant?.id}>
            <LegalEntityDetailCard
              legalEntity={tenant}
              resource={Person}
              additionalInfo={
                managingEntity &&
                tenant?.ailoRN && (
                  <TenantsCentrepayInfo
                    tenancy={tenancy.ailoRN}
                    legalEntity={tenant?.ailoRN}
                    managingEntity={managingEntity}
                  />
                )
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
