import React from "react";
import { BaseBadge } from "./baseBadge";
import { Colors, opacify } from "../colors";

/**
 * @deprecated Use <Badge type="tenant" /> instead.
 */
export function Tenant({ style, children }: any) {
  return (
    <BaseBadge
      color={Colors.TENANT_BLUE}
      style={{ ...style, bgcolor: opacify(Colors.TENANT_BLUE, 0.2) }}
    >
      {children}
    </BaseBadge>
  );
}
