import React from "react";
import { Grid } from "@material-ui/core";
import {
  ErrorDialog,
  SimpleFormDialog,
  useMutation,
} from "../../../../../common";
import { TenancyDeposits } from "../../../../../api";
import { TenancyDepositRecord } from "../TenancyDepositRecord";

const useMarkDepositAsReleased = ({ onSuccess }: { onSuccess?(): void }) => {
  return useMutation(
    {
      type: "mark_tenancy_deposit_as_released",
      resource: TenancyDeposits,
    },
    {
      onSuccess: ({ data: _data }) => onSuccess?.(),
      successMessage: "Marked initial payment as transferred!",
    }
  );
};

export const UpdateDepositDialog = ({
  deposit,
  open,
  onClose,
}: {
  deposit: TenancyDepositRecord;
  open: boolean | undefined;
  onClose: () => void;
}): React.ReactElement => {
  const [markAsReleased, { error }] = useMarkDepositAsReleased({
    onSuccess: onClose,
  });

  const submit = () => {
    return markAsReleased({
      payload: {
        tenancyDepositId: deposit.ailoRN,
      },
    });
  };

  if (error) {
    return (
      <ErrorDialog
        open={open}
        title="Mark initial payment as transferred failed"
        error={error}
        onClose={onClose}
      />
    );
  }

  return (
    <SimpleFormDialog
      open={open}
      title="Mark initial payment as transferred"
      save={submit}
      submitLabel="Update"
      allowSubmitWhenPristine
      onClose={onClose}
      cancelLabel="Cancel"
      style={{ width: 500 }}
    >
      <Grid>Are you sure?</Grid>
    </SimpleFormDialog>
  );
};
