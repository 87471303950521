import gql from "graphql-tag";
import _ from "lodash";

type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function walletOwnerBalancesClient({
  raFetchType,
  params,
  resourceString,
}: Params) {
  return {
    query: gql`
      query walletOwnerBalanceReport($afterDate: Date, $beforeDate: Date) {
        walletOwnerBalanceReport(
          filter: { afterDate: $afterDate, beforeDate: $beforeDate }
        ) {
          items {
            category
            id: reference
            totalBalance {
              cents
            }
          }
        }
      }
    `,
    variables: {
      afterDate: params.filter.afterDate,
      beforeDate: params.filter.beforeDate,
    },
    parseResponse: (response: { data: { walletOwnerBalanceReport: any } }) => {
      const { walletOwnerBalanceReport } = response.data;
      const { items } = walletOwnerBalanceReport;
      items.id = walletOwnerBalanceReport;
      // implement client-side filtering for now
      const categoryFilter = params.filter.category;
      const filteredItems = categoryFilter
        ? _.filter(items, {
            category: categoryFilter,
          })
        : items;
      return {
        data: filteredItems,
        total: null,
      };
    },
  };
}

export { walletOwnerBalancesClient };
