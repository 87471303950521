import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { startCase } from "lodash";
import React from "react";
import {
  DateField,
  Labeled,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { Liability } from "../../api";
import {
  DynamicAiloRNField,
  IdField,
  LinkField,
  MoneyField,
} from "../../common";
import { ChargeCycles } from "./ChargeCycles";
import { OwingEvents } from "./OwingEvents";
import { RatePeriodsAndChargeSchedules } from "./RatePeriodsAndChargeSchedules";

export const RecurringOwingShow = (props: ShowProps): React.ReactElement => {
  return (
    <>
      <Alert severity="warning">
        This page is still under development, do not use it if you don't know
        what it is.
      </Alert>
      <Show {...props} title="Recurring Owing">
        <TabbedShowLayout>
          <Tab label="Summary">
            <IdField label="ID" source="id" />
            <LinkField
              label="Liability"
              source="liability.id"
              sourceResource={Liability}
              includeApiTypeInLinkText
            />
            <DynamicAiloRNField label="Reference" addLabel source="reference" />
            <TextField label="Owing Type" source="owingType" />
            <DateField label="First Paid At" source="progress.firstPaidAt" />
            <DateField label="Last Paid At" source="progress.lastPaidAt" />

            <Labeled label="Paid To Dates" fullWidth>
              <PaidToDates />
            </Labeled>

            <Labeled label="Progress" fullWidth>
              <RecurringOwingProgress />
            </Labeled>

            <Labeled label="Rate Periods + Charge Schedules" fullWidth>
              <RatePeriodsAndChargeSchedules />
            </Labeled>

            <Labeled label="Charge Cycles" fullWidth>
              <ChargeCycles />
            </Labeled>
          </Tab>

          <Tab label="Owing Events">
            <OwingEvents />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

function PaidToDates(props: {
  style?: React.CSSProperties;
}): React.ReactElement {
  const keys = ["effective", "classic"];
  return (
    <Table size="small" padding="none" {...props}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Paid To Date</TableCell>
          <TableCell>Part Payment</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keys.map((key) => (
          <TableRow key={key}>
            <TableCell component="th" scope="row">
              {startCase(key)}
            </TableCell>
            <TableCell>
              <DateField source={`progress.paidTo.${key}.date`} />
            </TableCell>
            <TableCell>
              <MoneyField source={`progress.paidTo.${key}.partPayment`} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function RecurringOwingProgress(props: {
  style?: React.CSSProperties;
}): React.ReactElement {
  const keys = [
    "due",
    "nextDue",
    "nextCharge",
    "arrears",
    "overdue",
    "endOfOwing",
  ];
  return (
    <Table size="small" padding="none" {...props}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Date</TableCell>
          <TableCell>Total Amount</TableCell>
          <TableCell>Tax Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keys.map((key) => (
          <TableRow key={key}>
            <TableCell component="th" scope="row">
              {startCase(key)}
            </TableCell>
            <TableCell>
              <DateField source={`progress.${key}.date`} />
            </TableCell>
            <TableCell>
              <MoneyField source={`progress.${key}.amount.total`} />
            </TableCell>
            <TableCell>
              <MoneyField source={`progress.${key}.amount.tax`} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
