import { AiloRN } from "@ailo/ailorn/build";
import React, { ReactElement } from "react";
import { GET_ONE, useQuery } from "react-admin";
import { Box } from "@material-ui/core";
import { CentrepayFee } from "../../../api";
import { CentrepayFeeSwitch } from "./CentrepayFeeSwitch";

const CentrepayFeeSwitchContainer = ({
  escrowAccount,
}: {
  escrowAccount: AiloRN;
}): ReactElement | null => {
  const {
    data,
  }: {
    data?: boolean;
  } = useQuery({
    type: GET_ONE,
    resource: CentrepayFee,
    payload: {
      escrowAccount: escrowAccount.toString(),
    },
  });

  const checked = !!data;
  return (
    <Box flex={1} alignItems="center">
      Apply Centrepay Fees:
      <CentrepayFeeSwitch checked={checked} escrowAccount={escrowAccount} />
    </Box>
  );
};

export { CentrepayFeeSwitchContainer };
