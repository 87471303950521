import React from "react";
import {
  Datagrid,
  Filter,
  FunctionField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { LegalEntityAvatarField, List } from "../../common";
import { PersonLinkField } from "../../common";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <TextInput label="Search" source="searchTerm" alwaysOn autoFocus />
    <SelectInput
      source="orgType"
      alwaysOn
      choices={[
        {
          id: "Agency",
          name: "Agency",
        },
        {
          id: "Generic",
          name: "Consumer",
        },
      ]}
      emptyText="All"
    />
  </Filter>
);

const CompanyList = (props: Record<string, any>) => {
  return (
    <List
      {...props}
      title="Companies"
      sort={{
        field: "registeredEntityName",
        order: "ASC",
      }}
      bulkActionButtons={false}
      filters={<SearchFilter />}
    >
      <Datagrid rowClick="show">
        <LegalEntityAvatarField
          label="Registered Entity Name"
          sortBy="registeredEntityName"
        />
        <TextField
          label="Trading Name"
          source="organisation.name"
          sortable={false}
        />
        <TextField label="Country" source="country" />
        <FunctionField
          label="Owner"
          render={(record: any) => <PersonLinkField record={record.owner} />}
        />
      </Datagrid>
    </List>
  );
};

export { CompanyList };
