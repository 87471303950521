import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

const WITHDRAW_FUNDS = "withdraw_funds";

function withdrawalClient({ raFetchType, params }: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case WITHDRAW_FUNDS:
      return {
        query: gql`
          mutation withdrawFromWallet(
            $fromWalletId: ID!
            $toPaymentMethodId: ID!
            $amountInCents: Long!
            $idempotencyKey: GeneratedKey!
            $userFacingDescription: String
          ) {
            withdrawFromWallet(
              withdrawInput: {
                fromWalletId: $fromWalletId
                toPaymentMethodId: $toPaymentMethodId
                amount: { cents: $amountInCents }
                idempotencyKey: $idempotencyKey
                userFacingDescription: $userFacingDescription
              }
            ) {
              status
              error
            }
          }
        `,
        variables: {
          fromWalletId: params.fromWalletId,
          toPaymentMethodId: params.toPaymentMethodId,
          amountInCents: params.amountInCents,
          idempotencyKey: params.idempotencyKey,
          userFacingDescription: params.transactionDescription,
        },
        parseResponse: (response: { data: { withdrawFromWallet: any } }) => ({
          data: response.data.withdrawFromWallet,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { withdrawalClient };
