import React from "react";
import { AccountOwner } from "../../../api";
import { useInfinitelyPaginatedQuery } from "../../../common";
import { GeneralLedgerEntryList } from "../../../features/GeneralLedgerEntry";

export function AccountOwnerEntriesList({
  accountOwnerReference,
}: {
  accountOwnerReference: string;
}): React.ReactElement {
  const {
    loaded,
    error,
    data,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    type: "LIST_ACCOUNT_OWNER_ENTRIES",
    resource: AccountOwner,
    payload: {
      accountOwnerReference,
    },
    pageSize: 25,
  });

  if (!loaded) return <GeneralLedgerEntryList.Loading />;
  if (error) return <GeneralLedgerEntryList.Error />;
  if (!data?.length) return <GeneralLedgerEntryList.Empty />;

  return (
    <GeneralLedgerEntryList
      hasMore={hasMore}
      loadMore={loadMore}
      items={data}
    />
  );
}
