import React, { useState } from "react";
import { useMutation, useNotify, useRefresh } from "react-admin";
import {
  ConfirmMutationDialogContent,
  ErrorDialogContent,
  SlideUpDialog,
} from "..";

const useConfirmAction = ({
  type,
  resource,
  payload,
  onSuccess,
  onFailure,
  successNotification,
  failureNotification,
  shouldRefresh,
}: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    {
      type,
      resource,
      payload,
    },
    {
      onSuccess: (response) => {
        onSuccess(response);

        if (shouldRefresh) {
          refresh();
        }

        if (successNotification) {
          notify(successNotification);
        }
      },
      onFailure: (error) => {
        onFailure(error.toString());

        if (failureNotification) {
          notify(`${failureNotification} Error: ${error}`);
        }
      },
    }
  );
};

export const ConfirmMutationDialog = ({
  open,
  onClose,
  onSuccess,
  mutationParams,
  confirmation,
  successNotification,
  failureNotification,
  shouldRefresh = true,
}: any) => {
  const [error, setError] = useState<any>();

  const onDeleteFailure = (hasError: any) => {
    setError(hasError);
  };

  const [confirmAction] = useConfirmAction({
    ...mutationParams,
    onSuccess: (response: any) => {
      onSuccess?.(response);
      onClose?.();
    },
    successNotification,
    onFailure: onDeleteFailure,
    failureNotification,
    shouldRefresh,
  });
  return (
    <SlideUpDialog open={open} onClose={onClose}>
      {!error ? (
        <ConfirmMutationDialogContent
          title={confirmation.title}
          content={confirmation.content}
          onConfirmClick={confirmAction}
          onCancelClick={onClose}
        />
      ) : (
        <ErrorDialogContent
          title={failureNotification}
          error={error}
          onClose={() => {
            onClose();
            setError(undefined);
          }}
        />
      )}
    </SlideUpDialog>
  );
};
