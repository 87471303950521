import React from "react";
import { DateField, Show, SimpleShowLayout, useGetOne } from "react-admin";
import Skeleton from "@material-ui/lab/Skeleton";
import { Transaction } from "../../../../../api";
import { Colors, IdField } from "../../../../../common";

const TransactionShow = (props: Record<string, unknown>) => {
  return (
    <Show {...props} title=" ">
      <SimpleShowLayout>
        <IdField source="id" label="BT Id" />
        <DateField source="createdAt" showTime />
        <DateField
          label="Cleared at"
          source="transactionStatus.clearedAt"
          showTime
        />
      </SimpleShowLayout>
    </Show>
  );
};

const InlineTransactionDisplay = ({
  transactionId,
}: {
  transactionId: string;
}): React.ReactElement | null => {
  const record = useGetOne(Transaction, transactionId);
  const { data, loading, error } = record;

  if (loading && !data) {
    return <Skeleton variant="text" width={160} />;
  }

  if (error) {
    return (
      <div style={{ color: Colors.TEXT.RED }}>
        There was an error displaying transaction info
      </div>
    );
  }

  if (data) {
    return (
      <TransactionShow
        record={record}
        basePath=""
        resource={Transaction}
        id={transactionId}
      />
    );
  }

  return null;
};

export { InlineTransactionDisplay };
