/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from "react";
import { FormDataConsumer, Labeled, required } from "react-admin";
import {
  maxMoney,
  minMoney,
  MoneyInput,
  SimpleFormDialog,
  useMutation,
} from "../../../common";
import { ErrorDialog } from "../../../common/ui/dialogs";
import { BankAccountPaymentMethodOfOwnerInput } from "../BankAccountPaymentMethodOfOwnerInput";
import { WalletInput } from "./WalletInput";

export const TransferFundsDialog = ({
  open,
  onClose,
  legalEntityId,
  wallets,
  transactionDescription,
}: {
  open: boolean;
  onClose: () => void;
  legalEntityId: string;
  wallets: any;
  transactionDescription?: string;
}) => {
  const [transferFunds, { error }] = useMutation<{
    amountInCents: string;
    toPaymentMethodId: string;
    fromWalletId: string;
    idempotencyKey: string;
    transactionDescription?: string;
  }>(
    {
      type: "withdraw_funds",
      resource: "Withdrawals",
    },
    {
      successMessage: `Funds have been withdrawn`,
    }
  );
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;

  const submit = (formData: {
    walletId: any;
    amount: { cents: any };
    toPaymentMethodId: any;
  }) => {
    const wallet = wallets.find((w: { id: any }) => w.id === formData.walletId);
    transferFunds({
      payload: {
        amountInCents: formData.amount.cents,
        toPaymentMethodId: formData.toPaymentMethodId,
        fromWalletId: formData.walletId,
        idempotencyKey: wallet.idempotencyKey,
        transactionDescription,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Transferring funds failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        walletId: wallets[0]?.id,
      }}
      title="Transfer funds"
      open={open}
      validate={(values: { toPaymentMethodId: any; walletId: any }) => {
        const errors = {};

        if (!values.toPaymentMethodId) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'toPaymentMethodId' does not exist on typ... Remove this comment to see the full error message
          errors.toPaymentMethodId = ["A payment method is required"];
        }

        if (!values.walletId) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'walletId' does not exist on type '{}'.
          errors.walletId = ["A wallet is required"];
        }

        return errors;
      }}
      submitLabel="Transfer funds"
      save={submit}
      onClose={onClose}
    >
      <WalletInput label="Wallet" wallets={wallets} source="walletId" />
      <FormDataConsumer>
        {({ formData: { walletId } }) => {
          const wallet = wallets?.find((w: { id: any }) => w.id === walletId);
          return (
            <Labeled label="How much? ($)" fullWidth>
              <MoneyInput
                source="amount"
                defaultValue={wallet.availableBalance}
                validate={[
                  required(),
                  minMoney({
                    cents: 1,
                  }),
                  wallet &&
                    maxMoney(
                      wallet.availableBalance,
                      "The amount cannot exceed the available balance"
                    ),
                ].filter(Boolean)}
              />
            </Labeled>
          );
        }}
      </FormDataConsumer>
      <BankAccountPaymentMethodOfOwnerInput
        label="Transfer to"
        source="toPaymentMethodId"
        owner={legalEntityId}
      />
    </SimpleFormDialog>
  );
};
