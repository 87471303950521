import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { DELETE, useRecordContext } from "react-admin";
import { SimpleFormDialog, useMutation } from "../../common";
import { OperationParams, Team } from "../../api";

type DeleteTeamInput = OperationParams<typeof Team, typeof DELETE>["data"];

function DeleteTeamButton(): React.ReactElement {
  const { id, managements } = useRecordContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Team,
    type: DELETE,
  });

  const hasManagements = managements.pageInfo.total > 0;

  const tooltipTitle = hasManagements
    ? "cannot delete team with managements"
    : "delete team";

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => setDialogOpen(true)}
          disabled={hasManagements}
        >
          <DeleteIcon style={{ padding: 0 }} />
        </IconButton>
        {dialogOpen && (
          <SimpleFormDialog<DeleteTeamInput>
            title="Delete Team"
            submitLabel="Delete"
            cancelLabel="Back"
            initialValues={{ team: id }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            save={(data) => mutate({ payload: { data } })}
          >
            <div />
          </SimpleFormDialog>
        )}
      </div>
    </Tooltip>
  );
}

export { DeleteTeamButton };
