export default (dataProvider: any, transcoDataProvider: any) => (
  fetchType: string,
  resource: string,
  params: Record<string, any>
) => {
  if (resource.startsWith("Transco/")) {
    return transcoDataProvider(fetchType, resource, params);
  }

  return dataProvider(fetchType, resource, params);
};
