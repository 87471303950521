import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { LegalEntityAvatar } from "../../common/ui/avatars";
import { CopyToClipboardButton } from "../../common";
import { LegalEntityField } from "../../common/fields";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));
export const PersonDetailCard = ({ person, status, additionalInfo }: any) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      classes={{
        root: classes.root,
      }}
    >
      <CardHeader
        style={{
          alignItems: "flex-start",
        }}
        avatar={
          <LegalEntityAvatar
            legalEntity={person}
            style={{
              width: 40,
              height: 40,
              fontSize: "1rem",
            }}
          />
        }
        title={
          <Box display="flex">
            <LegalEntityField
              record={{
                person,
              }}
              source="person"
            />
            {status ? <Box pl={2}>{status}</Box> : null}
          </Box>
        }
        subheader={
          <>
            <Box>
              {person.emailAddress}
              <CopyToClipboardButton value={person.emailAddress} />
            </Box>
            <Box>{person.phoneNo}</Box>
            <Box mt={1}>
              {person.id}
              <CopyToClipboardButton value={person.id} />
            </Box>
            <Box>{additionalInfo}</Box>
          </>
        }
      />
    </Card>
  );
};
