import moment from "moment-timezone";

/**
 * @deprecated Use LocalDate from `@ailo/date`
 */
export function formatDate(date: moment.MomentInput): string | null {
  return date ? moment(date).format("D MMM YYYY") : null;
}

/**
 * @deprecated Rename or remove it.
 */
export function formatDateTimeToLocal(date: moment.MomentInput): string | null {
  return date ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : null;
}
