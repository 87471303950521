import React, { useState } from "react";
import { Button } from "react-admin";
import { useHasAuthScope } from "../../../../common/auth/useHasAuthScope";
import { GenerateTenancyLedgerStatementDialog } from "./GenerateTenancyLedgerStatementDialog";
import { TenancyInput } from "./TenancyInput";

export function GenerateTenancyLedgerStatementButton({
  tenancy,
  ...props
}: {
  tenancy: TenancyInput;
}): React.ReactElement | null {
  const [dialogOpen, setDialogOpen] = useState(false);
  const canReadPayments = useHasAuthScope("admin:payments:read");

  if (!canReadPayments) {
    return null;
  }

  return (
    <>
      <GenerateTenancyLedgerStatementDialog
        tenancy={tenancy}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Button
        label="Generate Tenancy Ledger"
        variant="outlined"
        {...props}
        onClick={() => setDialogOpen(true)}
      />
    </>
  );
}
