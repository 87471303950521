import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import React, { ReactElement } from "react";
import {
  DateField,
  EditButton,
  FunctionField,
  ListButton,
  Show,
  ShowProps,
  TextField,
  TopToolbar,
} from "react-admin";
import { Fee, OperationData } from "../../api";
import { FeeType } from "../../api/graphql/types.generated";
import {
  ActionInitiatorField,
  Badge,
  IdField,
  LegalEntityField,
  LinkField,
  ManagementField,
  MoneyField,
  PercentageField,
  PropertyField,
} from "../../common";
import { ArchiveFeeButton, FeeStatusBadge } from "../../features/Fee";
import { LiabilityEntriesGrid } from "../../features/Ledger/LiabilityEntriesGrid";

type FeeRecord = NonNullable<OperationData<typeof Fee, "GET_ONE">>;

const Actions = ({
  basePath,
  data: fee,
}: {
  basePath?: string;
  data?: FeeRecord;
}) => {
  if (!fee) return null;
  return (
    <TopToolbar>
      <ListButton basePath={basePath} />
      {fee.status === "Due" && fee.type === FeeType.OneOffFee && (
        <>
          <EditButton basePath={basePath} record={fee} />
          <ArchiveFeeButton fee={fee} />
        </>
      )}
    </TopToolbar>
  );
};

export function FeeShow(props: Omit<ShowProps, "children">): ReactElement {
  return (
    <Show {...props} actions={<Actions />}>
      <BoxedShowLayout>
        <RaBox display="flex">
          <RaBox marginRight="50px">
            <IdField sourceResource={Fee} />
            <TextField source="type" />
            <MoneyField source="amount" />
            <PercentageField source="percentage" />
            <MoneyField source="baseAmount" />
            <TextField source="baseAmountType" />
            <LinkField label="Bill this fee is applied to" source="appliedTo" />

            <FunctionField<FeeRecord>
              label="Status"
              render={(record) =>
                record?.status ? (
                  <FeeStatusBadge status={record.status} />
                ) : null
              }
            />
            <DateField
              label="Payment date"
              source="liability.firstPaymentDate"
            />
            <TextField label="Tax category" source="taxCategory.name" />
            <TextField label="Description" source="description" />
            <ActionInitiatorField
              label="Created in Ailo"
              initiatedAtSource="createdAt"
              initiatedBySource="createdBy.ailoRN"
            />
            <ActionInitiatorField
              label="Modified"
              initiatedBySource="modifiedBy.ailoRN"
            />
            <ActionInitiatorField
              label="Archived"
              initiatedAtSource="archivedAt"
              initiatedBySource="archivedBy.ailoRN"
            />
            <TextField source="archiveReason" />
          </RaBox>
          <RaBox>
            <PropertyField
              source="management.property"
              managementIdSource="management.id"
            />
            <ManagementField source="management" />
            <LegalEntityField
              label="Managing Entity"
              source="management.managingEntity"
            />
            <DateField
              label="Management started"
              source="management.firstPublishedAt"
            />
            <LegalEntityField
              label="Payee"
              source="management.managingEntity"
            />
            <FunctionField<FeeRecord>
              label="Payer(s)"
              render={(record) => {
                const legalEntities = record?.management.owners
                  ?.map((ownership) => ownership?.owner)
                  .filter(Boolean);
                return (
                  <>
                    <LinkField record={record} source="management" />
                    {legalEntities?.map((legalEntity) => (
                      <div
                        key={legalEntity?.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingLeft: 8,
                          paddingTop: 8,
                        }}
                      >
                        <LegalEntityField record={legalEntity} />
                        <Badge
                          style={{
                            marginLeft: "4px",
                          }}
                          type="investor"
                          text="Investor"
                        />
                      </div>
                    ))}
                  </>
                );
              }}
            />
            <LinkField source="liability" />
            <LinkField
              source="blueprint"
              renderText={(blueprint) => blueprint.name}
            />
          </RaBox>
        </RaBox>
        <FunctionField<FeeRecord>
          label="Liability Entries"
          render={(record) => {
            if (!record?.liability) {
              return null;
            }
            return <LiabilityEntriesGrid liabilityId={record.liability.id} />;
          }}
        />
      </BoxedShowLayout>
    </Show>
  );
}
