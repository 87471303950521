import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

export const SEND_CENTREPAY_ONBOARDING_EMAIL =
  "send_centrepay_onboarding_email";
export const SEND_CENTREPAY_ACCOUNT_SETUP_EMAIL =
  "send_centrepay_account_setup_email";

function notificationsClient({ raFetchType, params }: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case SEND_CENTREPAY_ONBOARDING_EMAIL:
      return {
        query: gql`
          mutation sendCentrepayOnboardingEmail($agencyAilorn: AiloRN!) {
            sendCentrepayOnboardingHelpLink(agencyAilorn: $agencyAilorn) {
              sent
            }
          }
        `,
        variables: {
          agencyAilorn: params.agencyAilorn.toString(),
        },
        parseResponse: (response: {
          data: { sendCentrepayOnboardingHelpLink: { sent: boolean } };
        }) => ({
          data: response.data.sendCentrepayOnboardingHelpLink,
        }),
      };

    case SEND_CENTREPAY_ACCOUNT_SETUP_EMAIL:
      return {
        query: gql`
          mutation sendCentrepayAccountSetupEmail($agencyAilorn: AiloRN!) {
            sendCentrepayAccountSetupInstructions(agencyAilorn: $agencyAilorn) {
              sent
            }
          }
        `,
        variables: {
          agencyAilorn: params.agencyAilorn.toString(),
        },
        parseResponse: (response: {
          data: { sendCentrepayAccountSetupInstructions: { sent: boolean } };
        }) => ({
          data: response.data.sendCentrepayAccountSetupInstructions,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { notificationsClient };
