import React from "react";
import { useQuery } from "react-admin";
import { DetailCard } from "../../../../../common/ui/cards";
import { AiloPaymentMethodFeesList } from "./ailoPaymentMethodFeesList";
import { AddPaymentMethodFeeWaiverDialog } from "../../../addPaymentMethodFeeWaiverDialog";
import { PaymentMethodAiloFee } from "../../../../../api/ResourceName";
import { LoadingList } from "../../../../../common/ui/lists";

export function AiloPaymentMethodFeesCard({
  legalEntityId,
}: any): React.ReactElement {
  const {
    loaded: waiverListLoaded,
    error: waiverListError,
    data: waiverFilterListData,
  } = useQuery({
    type: "list_active_ailo_fees_waiver",
    resource: PaymentMethodAiloFee,
    payload: {
      filter: {
        owner: legalEntityId,
      },
    },
  });

  if (!waiverListLoaded) {
    return <LoadingList />;
  }

  if (waiverListError) {
    console.log(waiverListError);
    return <>Error loading Ailo Service Fees waiver list</>;
  }

  const feeWaivers = waiverFilterListData.map(
    (item: {
      methodType: string;
      methodCategory: string;
      filterName: any;
      filterEntryEffectiveFromInclusive: any;
      filterEntryEffectiveToInclusive: any;
      filterNote: any;
    }) => {
      return {
        type:
          item.methodType === "bank_account"
            ? "Bank account"
            : item.methodType === "credit_card"
            ? item.methodCategory === "debit"
              ? "Debit card"
              : "Credit card"
            : `${item.methodCategory} - ${item.methodType} `,
        filterName: item.filterName,
        filterEntryEffectiveFromInclusive:
          item.filterEntryEffectiveFromInclusive,
        filterEntryEffectiveToInclusive: item.filterEntryEffectiveToInclusive,
        filterNote: item.filterNote,
      };
    }
  );
  const defaultFees = [
    {
      type: "Bank account",
      filterName: "",
      filterEntryEffectiveFromInclusive: null,
      filterEntryEffectiveToInclusive: null,
      filterNote: null,
    },
    {
      type: "Credit card",
      filterName: "",
      filterEntryEffectiveFromInclusive: null,
      filterEntryEffectiveToInclusive: null,
      filterNote: null,
    },
    {
      type: "Debit card",
      filterName: "",
      filterEntryEffectiveFromInclusive: null,
      filterEntryEffectiveToInclusive: null,
      filterNote: null,
    },
  ];
  const mergedFeesList = [];
  const note = feeWaivers.find((e: { filterNote: any }) => e.filterNote)
    ?.filterNote;

  for (let i = 0; i < defaultFees.length; i++) {
    mergedFeesList.push({
      ...defaultFees[i],
      ...feeWaivers.find(
        (itmInner: { type: string }) => itmInner.type === defaultFees[i].type
      ),
    });
  }

  return (
    <DetailCard
      title="Ailo Service Fee Management"
      contents={
        <AiloPaymentMethodFeesList
          mergedFeesList={mergedFeesList}
          note={note}
        />
      }
      action={
        <AddPaymentMethodFeeWaiverDialog
          owner={legalEntityId}
          mergedFeesList={mergedFeesList}
          note={note}
        />
      }
    />
  );
}
