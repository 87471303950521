import {
  TranscoVirtualAccount,
  TranscoVirtualAccountDebits,
} from "../../../ResourceName";
import { virtualAccountsClient } from "../virtualAccounts/virtualAccountsClient";
import { transcoRestClient } from "./transcoRestClient";
import { virtualDebitsClient } from "../virtualAccounts/virtualDebitsClient";

const transcoClient = (
  raFetchType: string,
  resourceString: string,
  params: Record<string, any>
) => {
  switch (resourceString) {
    case TranscoVirtualAccount:
      return virtualAccountsClient(transcoRestClient, raFetchType, params);
    case TranscoVirtualAccountDebits:
      return virtualDebitsClient(transcoRestClient, raFetchType, params);
    default:
      throw new Error(`${resourceString} is not yet implemented`);
  }
};

export { transcoClient };
