import { AiloRN, services } from "@ailo/ailorn";
import { MoneyInterface } from "@ailo/money";
import gql from "graphql-tag";
import { CREATE, GET_MANY, GET_ONE, UPDATE } from "react-admin";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import { FeeBlueprintType } from "../../graphql/types.generated";
import {
  ArchiveFeeBlueprintMutation,
  ArchiveFeeBlueprintMutationVariables,
  CreateFeeBlueprintMutation,
  CreateFeeBlueprintMutationVariables,
  FeeBlueprintQuery,
  FeeBlueprintQueryVariables,
  FeeBlueprintsQuery,
  FeeBlueprintsQueryVariables,
  UnarchiveFeeBlueprintMutation,
  UnarchiveFeeBlueprintMutationVariables,
  UpdateFeeBlueprintMutation,
  UpdateFeeBlueprintMutationVariables,
} from "./feeBlueprintClient.generated";

const detailedBlueprintFragment = gql`
  fragment DetailedFeeBlueprint on FeeBlueprint {
    id
    ailoRN
    type
    name
    taxCategory {
      id
      name
    }
    organisation {
      id
      name
    }
    fixedAmount {
      cents
    }
    oneWeekRentPercentage
    taxTreatment
    chargeType
    frequency
    event {
      type
      shortDescription
      longDescription
    }
    anniversaryDay
    anniversaryMonth
    createdAt
    createdBy {
      __typename
      ... on User {
        id
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
    archived
    archivedAt
    archivedBy {
      __typename
      ... on User {
        id
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
    modifiedBy {
      __typename
      ... on User {
        id
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
  }
`;

function createDetailedBlueprint(
  blueprint: NonNullable<FeeBlueprintQuery["blueprint"]>
) {
  return {
    ...blueprint,
    taxCategoryId: blueprint.taxCategory.id,
    organisationId: blueprint.organisation?.id,
  };
}

export const FeeBlueprintClientDefinition = {
  GET_LIST: (params: {
    filter: {
      organisationId?: string;
      notAppliedToManagementId?: string;
      type?: FeeBlueprintType;
      status?: "archived" | "active";
    };
    pagination: PaginationParams;
  }) => {
    return {
      query: gql`
        query feeBlueprints(
          $cursor: String
          $pageSize: Int
          $organisationId: ID
          $notAppliedToManagementId: ID
          $type: FeeBlueprintType
          $archived: Boolean
        ) {
          blueprints: feeBlueprints(
            conditions: {
              organisationId: $organisationId
              notAppliedToManagementId: $notAppliedToManagementId
              type: $type
              archived: $archived
            }
            cursor: { pageSize: $pageSize, cursor: $cursor }
          ) {
            pageInfo {
              total
              nextCursor
              hasMore
            }
            items {
              id
              ailoRN
              type
              name
              organisation {
                id
                name
              }
              fixedAmount {
                cents
              }
              oneWeekRentPercentage
              taxTreatment
              frequency
              event {
                type
                shortDescription
                longDescription
              }
              anniversaryDay
              anniversaryMonth
              createdAt
              createdBy {
                __typename
                ... on User {
                  id
                  ailoRN
                }
                ... on System {
                  ailoRN
                }
              }
              archived
            }
          }
        }
      `,
      variables: <FeeBlueprintsQueryVariables>{
        ...buildPaginationVariables(params.pagination),
        organisationId: params.filter.organisationId ?? null,
        notAppliedToManagementId: params.filter.notAppliedToManagementId,
        type: params.filter.type,
        archived:
          params.filter.status !== undefined
            ? params.filter.status === "archived"
            : undefined,
      },

      parseResponse(response: GraphQLResponse<FeeBlueprintsQuery>) {
        return {
          data: response.data.blueprints!.items.map((item) => ({
            ...item,
            id: AiloRN.fromString(item.ailoRN).internalId,
          })),
          total: response.data.blueprints!.pageInfo.total,
          nextCursor: response.data.blueprints!.pageInfo.nextCursor,
          hasMore: response.data.blueprints!.pageInfo.hasMore,
        };
      },
    };
  },

  [GET_MANY]: (params: { ids: string[] }) => {
    if (params.ids.length !== 1) {
      throw new TypeError(`We support only GET_MANY for 1 record for now.`);
    }
    const getOne = FeeBlueprintClientDefinition[GET_ONE]({
      id: params.ids[0],
    });
    return {
      ...getOne,
      parseResponse(response: GraphQLResponse<FeeBlueprintQuery>) {
        const { data: blueprint } = getOne.parseResponse?.(response);
        return {
          data: [blueprint],
        };
      },
    };
  },

  [GET_ONE]: (params: { id: string }) => {
    return {
      query: gql`
        query FeeBlueprint($id: ID!) {
          blueprint: feeBlueprint(id: $id) {
            ...DetailedFeeBlueprint
          }
        }
        ${detailedBlueprintFragment}
      `,
      variables: <FeeBlueprintQueryVariables>{
        id: params.id,
      },
      parseResponse({
        data: { blueprint },
      }: GraphQLResponse<FeeBlueprintQuery>) {
        return {
          data: createDetailedBlueprint(blueprint!),
        };
      },
    };
  },

  [CREATE]: (params: {
    data: {
      organisationId: string;
      type: FeeBlueprintType;
      name: string;
      taxCategoryId: string;
      frequency: string;
      event: string;
      anniversaryDay: number;
      anniversaryMonth: number;
      fixedAmount?: MoneyInterface;
      oneWeekRentPercentage: number;
    };
  }) => {
    return {
      query: gql`
        mutation createFeeBlueprint($input: CreateFeeBlueprintInput!) {
          blueprint: createFeeBlueprint(input: $input) {
            ...DetailedFeeBlueprint
          }
        }
        ${detailedBlueprintFragment}
      `,
      variables: <CreateFeeBlueprintMutationVariables>{
        input: {
          organisationId: AiloRN.of(
            services.AuthZ.organisation,
            params.data.organisationId
          ).toString(),
          type: params.data.type,
          name: params.data.name,
          taxCategoryId: params.data.taxCategoryId,
          frequency: params.data.frequency,
          event: params.data.event,
          anniversaryDay: params.data.anniversaryDay,
          anniversaryMonth: params.data.anniversaryMonth,
          fixedAmount: params.data.fixedAmount
            ? {
                cents: params.data.fixedAmount.cents,
              }
            : null,
          oneWeekRentPercentage: params.data.oneWeekRentPercentage,
          taxTreatment: "inclusive",
        },
      },

      parseResponse({
        data: { blueprint },
      }: GraphQLResponse<CreateFeeBlueprintMutation>) {
        return {
          data: createDetailedBlueprint(blueprint!),
        };
      },
    };
  },

  [UPDATE]: (params: {
    data: {
      id: string;
      name: string;
      taxCategoryId: string;
      anniversaryDay: number;
      anniversaryMonth: number;
      fixedAmount?: MoneyInterface;
      oneWeekRentPercentage?: number;
    };
  }) => {
    return {
      query: gql`
        mutation updateFeeBlueprint($input: UpdateFeeBlueprintInput!) {
          blueprint: updateFeeBlueprint(input: $input) {
            ...DetailedFeeBlueprint
          }
        }
        ${detailedBlueprintFragment}
      `,
      variables: <UpdateFeeBlueprintMutationVariables>{
        input: {
          id: params.data.id,
          name: params.data.name,
          taxCategoryId: params.data.taxCategoryId,
          anniversaryDay: params.data.anniversaryDay,
          anniversaryMonth: params.data.anniversaryMonth,
          fixedAmount: params.data.fixedAmount
            ? {
                cents: params.data.fixedAmount.cents,
              }
            : null,
          oneWeekRentPercentage: params.data.oneWeekRentPercentage,
          taxTreatment: "inclusive",
        },
      },

      parseResponse({
        data: { blueprint },
      }: GraphQLResponse<UpdateFeeBlueprintMutation>) {
        return {
          data: createDetailedBlueprint(blueprint!),
        };
      },
    };
  },
  archive: (params: {
    data: {
      id: string;
    };
  }) => {
    return {
      query: gql`
        mutation archiveFeeBlueprint($input: UpdateFeeBlueprintInput!) {
          blueprint: updateFeeBlueprint(input: $input) {
            ...DetailedFeeBlueprint
          }
        }
        ${detailedBlueprintFragment}
      `,
      variables: <ArchiveFeeBlueprintMutationVariables>{
        input: {
          id: params.data.id,
          archived: true,
        },
      },
      parseResponse({ data }: GraphQLResponse<ArchiveFeeBlueprintMutation>) {
        return {
          data: createDetailedBlueprint(data.blueprint!),
        };
      },
    };
  },
  unarchive: (params: {
    data: {
      id: string;
    };
  }) => {
    return {
      query: gql`
        mutation unarchiveFeeBlueprint($input: UpdateFeeBlueprintInput!) {
          blueprint: updateFeeBlueprint(input: $input) {
            ...DetailedFeeBlueprint
          }
        }
        ${detailedBlueprintFragment}
      `,
      variables: <UnarchiveFeeBlueprintMutationVariables>{
        input: {
          id: params.data.id,
          archived: false,
        },
      },
      parseResponse({ data }: GraphQLResponse<UnarchiveFeeBlueprintMutation>) {
        return {
          data: createDetailedBlueprint(data.blueprint!),
        };
      },
    };
  },
};

export const feeBlueprintClient = createClientFromDefinition(
  FeeBlueprintClientDefinition
);
