import { formatRecurringDateFrequency } from "@ailo/date";
import moment from "moment-timezone";
import React, { useCallback, useMemo } from "react";
import {
  FormDataConsumer,
  minValue,
  number,
  required,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { OperationData } from "../../api";
import {
  FeeBlueprintChargeType,
  FeeBlueprintType,
} from "../../api/graphql/types.generated";
import { FeeBlueprint } from "../../api/ResourceName";
import {
  maxMoney,
  minMoney,
  MoneyInput,
  OrganisationField,
  OrganisationInput,
  PercentInput,
  SimpleForm,
  SimpleFormProps,
} from "../../common";
import { formatOrdinalInteger } from "../../common/utils";
import { FeeChargeTypeInput } from "./FeeChargeTypeInput";
import { FeeEventInput } from "./FeeEventInput";
import { FeeTaxCategoryInput } from "./FeeTaxCategoryInput";
import { FeeTypeInput } from "./FeeTypeInput";

type FeeBlueprintRecord = OperationData<typeof FeeBlueprint, "GET_ONE">;

export function FeeBlueprintForm(
  props: SimpleFormProps<FeeBlueprintRecord>
): React.ReactElement {
  const create = !props.record!.id;
  const autoPopulatedFields = useMemo(() => new Set<string>(), []);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      autoPopulatedFields.delete(event.target.name);
    },
    [autoPopulatedFields]
  );

  return (
    <SimpleForm {...props} onChange={onChange}>
      {create ? (
        <OrganisationInput
          source="organisationId"
          validate={[required()]}
          addLabel
        />
      ) : (
        <OrganisationField source="organisation" addLabel />
      )}
      <FeeTaxCategoryInput autoPopulatedFields={autoPopulatedFields} />
      <TextInput autoFocus source="name" validate={[required()]} />
      <FeeTypeInput disabled={!create} />
      <FormDataConsumer>
        {({
          formData: { type, frequency, chargeType },
        }: {
          formData: FeeBlueprintRecord;
        }) => {
          switch (type) {
            case FeeBlueprintType.RecurringFee:
              return (
                <>
                  {create ? (
                    <SelectInput
                      source="frequency"
                      choices={["weekly", "monthly", "annually"].map((id) => ({
                        id,
                        name: id,
                      }))}
                      validate={[required()]}
                    />
                  ) : (
                    <TextField source="frequency" />
                  )}
                  {frequency === "weekly" && (
                    <SelectInput
                      label="Default Charge Date"
                      source="anniversaryDay"
                      choices={[...new Array(7).keys()].map((i) => ({
                        id: i + 1,
                        name: moment.weekdays((i + 1) % 7),
                      }))}
                      validate={[required()]}
                      fullWidth
                    />
                  )}
                  {frequency === "annually" && (
                    <SelectInput
                      label="Default Charge Month"
                      source="anniversaryMonth"
                      choices={[...new Array(12).keys()].map((i) => ({
                        id: i + 1,
                        name: moment.months(i),
                      }))}
                      validate={[required()]}
                      fullWidth
                    />
                  )}
                  {(frequency === "monthly" || frequency === "annually") && (
                    <SelectInput
                      label="Default Charge Date"
                      source="anniversaryDay"
                      choices={[...new Array(28).keys()].map((i) => ({
                        id: i + 1,
                        name: formatOrdinalInteger(i + 1),
                      }))}
                      validate={[required()]}
                      fullWidth
                    />
                  )}

                  <MoneyInput
                    source="fixedAmount"
                    label={`Default Price (incl. tax) ${
                      frequency ? formatRecurringDateFrequency(frequency) : ""
                    } ($)`}
                    validate={[
                      required(),
                      minMoney({ cents: 0 }),
                      maxMoney({ cents: 1300000 }),
                    ]}
                    fullWidth
                  />
                </>
              );

            case FeeBlueprintType.OneOffFee:
              return (
                <>
                  <FeeChargeTypeInput />

                  {chargeType === FeeBlueprintChargeType.FixedAmount ? (
                    <MoneyInput
                      source="fixedAmount"
                      label="Default Price (incl. tax) ($)"
                      fullWidth
                      validate={[
                        required(),
                        minMoney({ cents: 0 }),
                        maxMoney({ cents: 1300000 }),
                      ]}
                    />
                  ) : (
                    <PercentInput
                      source="oneWeekRentPercentage"
                      label="Weeks of rent"
                      format="decimal"
                      dp={4}
                      validate={[required(), number(), minValue(0)]}
                      fullWidth
                    />
                  )}
                </>
              );

            case FeeBlueprintType.EventBasedFee:
              return (
                <>
                  <FeeEventInput />

                  <FeeChargeTypeInput />

                  {chargeType === FeeBlueprintChargeType.FixedAmount ? (
                    <MoneyInput
                      source="fixedAmount"
                      label="Default Price (incl. tax) ($)"
                      fullWidth
                      validate={[
                        required(),
                        minMoney({ cents: 0 }),
                        maxMoney({ cents: 1300000 }),
                      ]}
                    />
                  ) : (
                    <PercentInput
                      source="oneWeekRentPercentage"
                      label="Weeks of rent"
                      format="decimal"
                      dp={4}
                      validate={[required(), number(), minValue(0)]}
                      fullWidth
                    />
                  )}
                </>
              );

            default:
              return <></>;
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  );
}
