import { Box } from "@material-ui/core";
import React from "react";
import { NotificationSwitch } from "./notificationSwitch";
import { SupportedNotificationPreference } from "../utils";

export const CommunicationTypeChannelPreferences = ({
  preference,
  userPreferences,
  userId,
}: {
  preference: SupportedNotificationPreference;
  userPreferences: any;
  userId: any;
}) => {
  return (
    <Box fontSize="0.75rem">
      {preference.description}
      <Box display="flex" pb={2}>
        {preference.channels.map((channel: any) => {
          const currentPreference = findUserPreferenceSetting(
            userPreferences,
            preference.communicationType,
            channel
          );
          const enabled = currentPreference ? currentPreference.enabled : false;

          return (
            <NotificationSwitch
              key={`${preference.communicationType}-${channel}`}
              label={channel}
              userId={userId}
              communicationType={preference.communicationType}
              channel={channel}
              enabled={enabled}
              description={preference.description}
            />
          );
        })}
      </Box>
    </Box>
  );
};

function findUserPreferenceSetting(
  preferences: any[],
  communicationType: any,
  channel: any
) {
  return preferences.find(
    (p: { communicationType: any; channel: any }) =>
      p.communicationType === communicationType && p.channel === channel
  );
}
