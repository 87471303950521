import React from "react";
import { FunctionField } from "react-admin";
import Box from "@material-ui/core/Box";
import { LegalEntityAvatar } from "../ui/avatars";
import { formatLegalEntityName } from "../utils";

const LegalEntityAvatarField = ({ record, label }: any) => {
  const value = formatLegalEntityName(record);
  return (
    <FunctionField
      record={record}
      label={label}
      render={() => {
        return (
          <Box display="flex">
            <LegalEntityAvatar
              legalEntity={record}
              style={{
                marginRight: 10,
              }}
            />
            <span>{value}</span>
          </Box>
        );
      }}
    />
  );
};

export { LegalEntityAvatarField };
