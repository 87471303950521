import React from "react";
import { useRecordContext } from "react-admin";
import { RecurringOwing } from "../../../api";
import { useInfinitelyPaginatedQuery } from "../../../common";
import { OwingEventList } from "./OwingEventList";

export function OwingEvents(): React.ReactElement {
  const { id } = useRecordContext();
  const {
    loaded,
    error,
    data,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    type: "LIST_OWING_EVENTS",
    resource: RecurringOwing,
    payload: {
      recurringOwingId: id.toString(),
    },
    pageSize: 100,
  });

  if (!loaded) return <OwingEventList.Loading />;
  if (error) return <OwingEventList.Error />;
  if (!data?.length) return <OwingEventList.Empty />;

  return <OwingEventList hasMore={hasMore} loadMore={loadMore} items={data} />;
}
