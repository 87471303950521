import React, { useState, useCallback } from "react";
import {
  useMutation,
  useNotify,
  useRedirect,
  FunctionField,
} from "react-admin";
import { Button, Grid, InputLabel, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import type { Management } from "../../api/deprecatedTypes";
import { DraftField } from "./DraftField";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const ManagementStatusField = ({
  record: management,
}: {
  record?: Management;
}) => {
  if (!management) return null;
  return (
    <Grid container spacing={3} direction="row">
      <Grid item direction="column">
        <Grid item>
          <InputLabel shrink>Status</InputLabel>
        </Grid>
        <Grid item>
          <Box pt={1} pb={0.5}>
            <FunctionField render={() => <DraftField record={management} />} />
          </Box>
        </Grid>
      </Grid>
      {management.isDraft ? (
        <Grid
          item
          style={{
            display: "flex",
          }}
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <DeleteDraftManagementButton record={management} />
        </Grid>
      ) : null}
    </Grid>
  );
};

const DeleteDraftManagementButton = ({ record }: { record: Management }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [deleteDraftManagement, { loading }] = useMutation(
    {
      type: "delete_draft",
      resource: "Managements",
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: ({ data }) => {
        notify("Management deleted");
        redirect(`/Managements`);
      },
      onFailure: (error) =>
        notify(`Unable to delete management: ${error.message}`, "error"),
    }
  );
  const openConfirmationDialog = useCallback(() => {
    setDialogOpened(true);
  }, []);
  const cancelDeleteDraftManagement = useCallback(() => {
    setDialogOpened(false);
  }, []);
  const confirmDeleteDraftManagement = useCallback(() => {
    setDialogOpened(false);
    deleteDraftManagement();
  }, [deleteDraftManagement]);
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={openConfirmationDialog}
        disabled={loading}
        startIcon={<DeleteIcon />}
      >
        Delete Draft
      </Button>
      <ConfirmationDialog
        open={dialogOpened}
        onClose={cancelDeleteDraftManagement}
        onDisagree={cancelDeleteDraftManagement}
        onAgree={confirmDeleteDraftManagement}
        title="Are you sure?"
        description="Management and all associated tenancy, ownership, management agreement and fee information will be deleted."
      />
    </>
  );
};
