import { AiloRN, nsEntities } from "@ailo/ailorn";

export function determineAccountOwnerCategory(
  reference: string | undefined
): string {
  if (reference === "ailo:generalledger:accountowner:ailo") {
    return "Ailo money holder";
  }
  if (reference === "ailo:ledgers:system:0001") {
    return "Ailo trading";
  }
  try {
    const { nsEntity } = AiloRN.from(reference);
    switch (nsEntity) {
      case nsEntities.authz.legalentity:
        return "Legal entity";
      case nsEntities.propertymanagement.management:
        return "Management";
      case nsEntities.propertymanagement.tenancy:
        return "Tenancy";
      case nsEntities.bill.supplier:
        return "Supplier";
      case nsEntities.propertymanagement.bondaccount:
        return "Bond account";
      case nsEntities.propertymanagement.centrepayaccount:
        return "Centrepay account";
      default:
        return nsEntity;
    }
  } catch {
    return "Unknown account owner category";
  }
}
