import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { FunctionField, SimpleShowLayout, TextField } from "react-admin";
import { DateTimeField, LegalEntityField } from "../../../../../common/fields";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    "&:first-child": {
      padding: 0,
    },
    "overflow-x": "auto",
  },
}));
export const MigrationTaskInfo = ({ migrationTask }: any) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout className={classes.root} record={migrationTask}>
      <TextField label="Status" source="status" />
      {migrationTask.startedBy.person ? (
        <LegalEntityField label="Started by" source="startedBy.person" />
      ) : (
        <TextField label="Started by" source="startedBy.ailoRN" />
      )}
      <DateTimeField label="Started at" source="startedAt" />
      <DateTimeField label="Last finished at" source="finishedAt" />
      {migrationTask.lastError ? (
        <FunctionField
          label="Last error"
          render={(record: any) => <pre>{record.lastError}</pre>}
        />
      ) : null}
    </SimpleShowLayout>
  );
};
