import { Money } from "@ailo/money";
import React from "react";
import { AutocompleteInput, ReferenceInputProps } from "react-admin";
import { OperationData } from "../../api";
import { TaxTreatment } from "../../api/graphql/types.generated";
import { ManagementFeeBlueprint } from "../../api/ResourceName";
import { ReferenceInput } from "./ReferenceInput";

type ManagementFeeBlueprintInputProps = Omit<
  ReferenceInputProps,
  "source" | "reference"
>;

export function ManagementFeeBlueprintInput({
  source,
  disabled,
  ...props
}: ManagementFeeBlueprintInputProps): React.ReactElement {
  return (
    <ReferenceInput
      source={source}
      reference={ManagementFeeBlueprint}
      {...props}
    >
      <AutocompleteInput
        source="name"
        optionText={(
          blueprint: OperationData<"ManagementFeeBlueprint", "GET_LIST">[number]
        ) => {
          const amountDescription = blueprint?.fixedAmount
            ? Money.from(blueprint.fixedAmount).format()
            : `${blueprint?.oneWeekRentPercentage} weeks of rent`;
          const taxDescription =
            blueprint.taxTreatment === TaxTreatment.Exclusive
              ? "excluding GST"
              : "including GST";

          return `${blueprint?.feeBlueprint.name} (${amountDescription}, ${taxDescription})`;
        }}
        options={{
          disabled,
        }}
      />
    </ReferenceInput>
  );
}
ManagementFeeBlueprintInput.defaultProps = {
  fullWidth: true,
};
