import gql from "graphql-tag";

type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function discrepanciesClient({ raFetchType, params, resourceString }: Params) {
  return {
    query: gql`
      query discrepancyReport(
        $showAll: String
        $afterDate: Date
        $beforeDate: Date
      ) {
        discrepancyReport(
          filter: {
            search: [{ key: "showAll", value: $showAll }]
            dateRange: { afterDate: $afterDate, beforeDate: $beforeDate }
          }
        ) {
          items {
            id
            hasDiscrepancy
            ledger {
              paymentId
              createdAt
              bankingSettlementDate
              paymentMethod
              transactionType
              status
              totalAmount {
                cents
              }
            }
            gateway {
              paymentId
              createdAt
              bankingSettlementDate
              paymentMethod
              transactionType
              status
              totalAmount {
                cents
              }
            }
          }
        }
      }
    `,
    variables: {
      showAll: params.filter.showAll.toString(),
      afterDate: params.filter.afterDate,
      beforeDate: params.filter.beforeDate,
    },
    parseResponse: (response: { data: { discrepancyReport: any } }) => {
      const { discrepancyReport } = response.data;
      const { items } = discrepancyReport;
      items.id = discrepancyReport;
      return {
        data: items,
        total: null,
      };
    },
  };
}

export { discrepanciesClient };
