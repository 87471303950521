/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from "@material-ui/core";
import React from "react";
import {
  DateField,
  FunctionField,
  Link,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
import type { Person as PersonInterface } from "../../api/deprecatedTypes";
import { Person } from "../../api/ResourceName";
import { CopyableTextField } from "../../common";
import { GoButton } from "../../common/ui/buttons";
import { formatPersonName } from "../../common/utils";

const PersonTitle = ({ record }: { record?: PersonInterface }) => {
  return (
    <div>
      <Link to={`/${Person}`}>People</Link> / {formatPersonName(record!)}
    </div>
  );
};

const PersonShowActions = ({ data }: { data?: PersonInterface }) => {
  return data && data.organisation ? (
    <TopToolbar>
      <GoButton
        id={data.id}
        resource={Person}
        label="New Person View"
        variant="outlined"
      />
    </TopToolbar>
  ) : null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const DeprecatedPersonShow = (props: any) => {
  return (
    <Show {...props} title={<PersonTitle />} actions={<PersonShowActions />}>
      <SimpleShowLayout>
        <CopyableTextField label="ID" source="id" />
        <TextField label="Legal First Name" source="legalFirstName" />
        <TextField label="Legal Middle Name" source="legalMiddleName" />
        <TextField label="Legal Last Name" source="lastName" />
        <TextField label="Preferred Name" source="preferredName" />
        <TextField label="TFN" source="taxFileNumber" />
        <TextField label="Job Title" source="jobTitle" />
        <FunctionField
          label="Metadata"
          render={(record: any) => (
            <pre>
              {record.metadata
                ? JSON.stringify(JSON.parse(record.metadata), null, 2)
                : "{}"}
            </pre>
          )}
        />
        <Divider />
        <DateField
          label="Onboarding Started At"
          source="user.onboardedAt"
          emptyText="Not Onboarded"
        />
        <DateField
          label="Onboarding Completed At"
          source="user.onboardingCompletedAt"
          emptyText="Not Onboarded"
        />
      </SimpleShowLayout>
    </Show>
  );
};
