import gql from "graphql-tag";
import { CREATE, GET_ONE } from "react-admin";
import { LocalDate } from "@ailo/date";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
  removeWhitespace,
  specifyDefinition,
  specifyOperation,
  transformTenancyForRequest,
} from "../../common";
import {
  AlterTenancyStartDateMutation,
  AlterTenancyStartDateMutationVariables,
  CreateTenancyMutation,
  CreateTenancyMutationVariables,
  DeleteDraftTenancyMutation,
  DeleteDraftTenancyMutationVariables,
  PublishTenancyMutation,
  PublishTenancyMutationVariables,
  TenanciesForTenantQuery,
  TenanciesForTenantQueryVariables,
  TenancyQuery,
  TenancyQueryVariables,
  VoidTenancyMutation,
  VoidTenancyMutationVariables,
} from "./tenanciesClient.generated";

const getOne = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      query tenancy($id: ID!) {
        tenancy: tenancy(tenancyId: $id) {
          id
          management {
            id
            ailoRN
            property {
              id
              address {
                unitStreetNumber
                streetName
                suburb
                state
                country
                postcode
              }
            }
          }
          liability {
            id
          }
        }
      }
    `,
    variables: <TenancyQueryVariables>{
      id: params.id,
    },
    errorPolicy: "all",
    parseResponse(response: GraphQLResponse<TenancyQuery>) {
      return {
        data: response.data.tenancy,
      };
    },
  };
});

const getListForTenant = specifyOperation(
  (params: { tenantAilorn: string; pagination?: PaginationParams }) => {
    return {
      query: gql`
        query tenanciesForTenant(
          $tenantAilorn: AiloRN!
          $pageSize: Int!
          $cursor: String
        ) {
          tenanciesForTenant(
            tenantId: $tenantAilorn
            pageCursor: { pageSize: $pageSize, cursor: $cursor }
          ) {
            pageInfo {
              hasMore
              nextCursor
              total
            }
            items {
              id
              ailorn: ailoRN
              startDate
              endDate
              management {
                id
                property {
                  id
                  address {
                    unitStreetNumber
                    streetName
                    suburb
                    state
                    country
                    postcode
                  }
                }
              }
              displayRent {
                id
                amountInCents
                period
              }
              liability {
                id
              }
            }
          }
        }
      `,
      variables: <TenanciesForTenantQueryVariables>{
        ...buildPaginationVariables(params.pagination),
        tenantAilorn: params.tenantAilorn,
      },
      parseResponse(response: GraphQLResponse<TenanciesForTenantQuery>) {
        const { tenanciesForTenant } = response.data;
        const { pageInfo, items } = tenanciesForTenant ?? {};
        const { total, nextCursor, hasMore } = pageInfo ?? {};
        return {
          data: items,
          total,
          nextCursor,
          hasMore,
        };
      },
    };
  }
);

const create = specifyOperation((params: any) => {
  return {
    query: gql`
      mutation createTenancy(
        $managementId: ID!
        $tenancyDetails: TenancySetupInput!
      ) {
        createTenancy(
          managementId: $managementId
          tenancyDetails: $tenancyDetails
        ) {
          id
        }
      }
    `,
    variables: <CreateTenancyMutationVariables>(
      removeWhitespace(transformTenancyForRequest(params.data))
    ),
    parseResponse(response: GraphQLResponse<CreateTenancyMutation>) {
      return {
        data: response.data.createTenancy,
      };
    },
  };
});

const publish = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      mutation publishTenancy($id: ID!) {
        publishTenancy(tenancyId: $id) {
          id
        }
      }
    `,
    variables: <PublishTenancyMutationVariables>{
      id: params.id,
    },
    parseResponse(response: GraphQLResponse<PublishTenancyMutation>) {
      return {
        data: response.data.publishTenancy!.id,
      };
    },
  };
});

const deleteDraft = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      mutation deleteDraftTenancy($id: ID!) {
        deleteDraftTenancy(tenancyId: $id)
      }
    `,
    variables: <DeleteDraftTenancyMutationVariables>{
      id: params.id,
    },
    parseResponse(response: GraphQLResponse<DeleteDraftTenancyMutation>) {
      return {
        data: response.data.deleteDraftTenancy,
      };
    },
  };
});

const voidTenancy = specifyOperation((params: { tenancyId: string }) => {
  return {
    query: gql`
      mutation voidTenancy($tenancyId: ID!) {
        voidTenancy(tenancyId: $tenancyId) {
          tenancy {
            id
            voidedAt
          }
        }
      }
    `,
    variables: <VoidTenancyMutationVariables>{
      tenancyId: params.tenancyId,
    },
    parseResponse(response: GraphQLResponse<VoidTenancyMutation>) {
      return {
        data: response.data.voidTenancy,
      };
    },
  };
});

const alterTenancyStartDate = specifyOperation(
  ({ tenancyId, startDate }: { tenancyId: string; startDate: LocalDate }) => {
    return {
      query: gql`
        mutation alterTenancyStartDate($tenancyId: ID!, $startDate: String!) {
          alterTenancyStartDate(tenancyId: $tenancyId, startDate: $startDate) {
            id
            startDate
          }
        }
      `,
      variables: <AlterTenancyStartDateMutationVariables>{
        tenancyId,
        startDate: startDate.toString(),
      },
      parseResponse(response: GraphQLResponse<AlterTenancyStartDateMutation>) {
        return {
          data: response.data.alterTenancyStartDate,
        };
      },
    };
  }
);

export const ALTER_TENANCY_START_DATE = "ALTER_TENANCY_START_DATE";

export const TenanciesClientDefinition = specifyDefinition({
  [GET_ONE]: getOne,
  get_list_for_tenant: getListForTenant,
  [CREATE]: create,
  publish,
  delete_draft: deleteDraft,
  void_tenancy: voidTenancy,
  [ALTER_TENANCY_START_DATE]: alterTenancyStartDate,
});

export const tenanciesClient = createClientFromDefinition(
  TenanciesClientDefinition
);
