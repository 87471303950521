import React from "react";
import { AutocompleteInput } from "react-admin";
import { FeeTaxCategory } from "../../api/ResourceName";
import { ReferenceInput } from "./ReferenceInput";

export function FeeTaxCategoryInput({ ...props }) {
  return (
    <ReferenceInput reference={FeeTaxCategory} {...props}>
      <AutocompleteInput optionText="name" onChange={props.onChange} />
    </ReferenceInput>
  );
}
