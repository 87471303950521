import { Box } from "@material-ui/core";
import React from "react";
import { CopyToClipboardButton } from "../../../../common";

export function CopiableId({
  label,
  copyValue,
}: {
  label: string;
  copyValue: string;
}): React.ReactElement {
  return (
    <Box mt={1} fontSize={14}>
      {label}
      <CopyToClipboardButton value={copyValue} />
    </Box>
  );
}
