import { AiloRN } from "@ailo/ailorn";
import { nsEntities } from "@ailo/ailorn-ns-entity";
import { useCallback } from "react";
import { FeeBlueprint } from "../../api/graphql/types.generated";

interface UseFeeBlueprintAnalytics {
  /**
   * Send an analytics event when a fee blueprint is created, changed etc.
   */
  onFeeBlueprintMutated(event: string, feeBlueprint: FeeBlueprint): void;
}

/**
 * Access fee blueprint analytics helpers through this hook.
 */
export function useFeeBlueprintAnalytics(): UseFeeBlueprintAnalytics {
  const onFeeBlueprintMutated = useCallback(
    (event: string, feeBlueprint: FeeBlueprint) => {
      const organisationAilorns = feeBlueprint.organisation?.id
        ? [
            AiloRN.of(
              nsEntities.authz.organisation,
              feeBlueprint.organisation?.id
            ).toString(),
          ]
        : null;
      const feeBlueprintAilorn = feeBlueprint.ailoRN;

      window.analytics.track(event, {
        organisation_id: organisationAilorns,
        fee_blueprint_id: feeBlueprintAilorn,
      });
    },
    []
  );

  return {
    onFeeBlueprintMutated,
  };
}
