import React from "react";
import List from "@material-ui/core/List";
import { SubscriptionListItem } from "./subscriptionListItem";

function SubscriptionsList({ subscriptions }: any): React.ReactElement {
  if (!subscriptions || subscriptions.length === 0) {
    return <>There are no active subscriptions</>;
  }

  return (
    <List>
      {subscriptions.map((item: any) => (
        <SubscriptionListItem key={item.id} subscription={item} />
      ))}
    </List>
  );
}

export { SubscriptionsList };
