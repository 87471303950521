import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import React from "react";
import { Colors, opacify } from "../../../../../common/ui/colors";

const useStyles = makeStyles({
  root: {
    fontSize: "0.75em",
    color: Colors.AILO_RED,
    backgroundColor: opacify(Colors.AILO_RED, 0.2),
  },
});
export function WaivedFeeChip({ label }: any) {
  const classes = useStyles();
  const display = label || "Waived";
  return (
    <Chip
      label={display}
      size="small"
      classes={{
        root: classes.root,
      }}
    />
  );
}
