import FilterNone from "@material-ui/icons/FilterNone";
import Clipboard from "react-clipboard.js";
import React from "react";
import useNotify from "ra-core/esm/sideEffect/useNotify";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Colors } from "../colors";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 5,
    padding: 0,
    border: "none",
    backgroundColor: "transparent",
    color: "inherit",
    "&:hover": {
      color: Colors.AILO_RED,
      cursor: "pointer",
    },
    "& svg": {
      fontSize: "1em",
    },
  },
}));

const trackClicks = (text: string) => {
  window.analytics.track("Copied to Clipboard", { text });
};

type Props = {
  value: string;
  tooltip?: string;
  children?: React.ReactNode;
};

export const CopyToClipboardButton = ({
  value,
  tooltip = "Copy to clipboard",
  children = <FilterNone />,
}: Props) => {
  const notify = useNotify();
  const classes = useStyles();
  return (
    <Clipboard
      className={classes.root}
      onClick={(event: any) => {
        event.preventDefault();
        event.stopPropagation();
        notify("Copied to clipboard");
        trackClicks(value);
      }}
      button-title={tooltip}
      data-clipboard-text={value}
    >
      {children}
    </Clipboard>
  );
};
