import gql from "graphql-tag";

type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function reconciliationsClient({
  raFetchType,
  params,
  resourceString,
}: Params) {
  return {
    query: gql`
      query bankReconciliationReport($afterDate: Date, $beforeDate: Date) {
        bankReconciliationReport(
          filter: { afterDate: $afterDate, beforeDate: $beforeDate }
        ) {
          items {
            id
            paymentType
            transactionType
            total {
              cents
            }
            fees {
              cents
            }
            count
          }
        }
      }
    `,
    variables: {
      afterDate: params.filter.afterDate,
      beforeDate: params.filter.beforeDate,
    },
    parseResponse: (response: { data: { bankReconciliationReport: any } }) => {
      const { bankReconciliationReport } = response.data;
      const { items } = bankReconciliationReport;
      items.id = bankReconciliationReport;
      return {
        data: items,
        total: null,
      };
    },
  };
}

export { reconciliationsClient };
