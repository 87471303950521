import {
  Datagrid,
  TextField,
  TextInput,
  Filter,
  FunctionField,
} from "react-admin";
import React from "react";
import { LinkField, List } from "../../common";
import { PropertyShowRecord } from "./show/types";
import { Property } from "../../api";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <TextInput autoFocus label="Search" source="searchTerm" alwaysOn />
  </Filter>
);

const PropertiesList = (props: any) => {
  return (
    <List
      {...props}
      title="Properties"
      bulkActionButtons={false}
      filters={<SearchFilter />}
    >
      <Datagrid rowClick="show">
        <FunctionField<PropertyShowRecord>
          {...props}
          label="PROPERTY ID"
          render={(record) => {
            return (
              <LinkField
                record={record}
                source="id"
                sourceResource={Property}
                to={`/${Property}/${record?.id}/show`}
              />
            );
          }}
        />
        <TextField
          label="Unit/Street Number"
          source="address.unitStreetNumber"
        />
        <TextField label="Street Name" source="address.streetName" />
        <TextField label="Suburb" source="address.suburb" />
        <TextField label="State" source="address.state" />
        <TextField label="Postcode" source="address.postcode" />
        <TextField label="Country" source="address.country" />
      </Datagrid>
    </List>
  );
};

export { PropertiesList };
