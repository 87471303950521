import { AiloRN, services } from "@ailo/ailorn";
import { Box } from "@material-ui/core";
import { get, isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { GET_LIST } from "react-admin";
import { PaymentMethod } from "../../../../../api/ResourceName";
import {
  CloseButton,
  EditButton,
  useQueryWithStore,
} from "../../../../../common";
import { DetailCard } from "../../../../../common/ui/cards";
import { LoadingList } from "../../../../../common/ui/lists";
import {
  PaymentMethodListItem,
  PaymentMethodsList,
} from "../../../../../features/Ledger/components/paymentMethods";
import { AddPaymentMethodButton } from "../../../../../features/PaymentMethod/AddPaymentMethodButton";

const settings = {
  [services.Bill.supplier]: {
    createWalletOwner: true,
    deleteExistingPaymentMethods: true,
    showUpdateBillsOption: true,
    allowedMethodTypes: ["bankAccount", "bPay"],
  },

  default: {
    createWalletOwner: false,
    deleteExistingPaymentMethods: false,
    showUpdateBillsOption: false,
    allowedMethodTypes: ["bankAccount"],
  },
} as const;

function paymentSettingsForEntityType(ailoRN: AiloRN) {
  const entity = ailoRN.nsEntity;

  return entity in settings ? get(settings, entity) : settings.default;
}

export const PaymentMethodsCard = ({
  walletOwnerAiloRN,
  managingOrganisationAiloRN,
  includeDeleted = true,
  includeHidden = true,
}: {
  walletOwnerAiloRN: AiloRN;
  managingOrganisationAiloRN?: AiloRN;
  includeDeleted?: boolean;
  includeHidden?: boolean;
}) => {
  const {
    deleteExistingPaymentMethods,
    allowedMethodTypes,
    showUpdateBillsOption,
  } = paymentSettingsForEntityType(walletOwnerAiloRN);
  const { loaded, error, data } = useQueryWithStore({
    type: GET_LIST,
    resource: PaymentMethod,
    payload: {
      filter: {
        owner: walletOwnerAiloRN.toString(),
        includeDeleted,
        includeOnceOff: false,
        includeHidden,
      },
    },
  });

  const renderPaymentMethodList = ({
    loaded,
    error,
    isEmpty,
    groupedPaymentMethods,
  }: any) => {
    if (!loaded) {
      return <LoadingList />;
    }

    if (error) {
      return "Error loading payment methods";
    }

    if (isEmpty) {
      return "There are no payment methods";
    }

    return (
      <PaymentMethodsList
        paymentMethods={groupedPaymentMethods}
        listItemComponent={(item: { id: React.Key | null | undefined }) => (
          <PaymentMethodListItem
            key={item.id}
            paymentMethod={item}
            edit={edit}
          />
        )}
      />
    );
  };

  const isEmptyPaymentMethods = !data || isEmpty(data);
  const sortedPaymentMethods = sortPaymentMethods(data ?? []);
  const groupedPaymentMethods = groupPaymentMethodsByType(sortedPaymentMethods);
  const [edit, setEdit] = useState(false);

  const onEdit = (doEdit: boolean | ((prevState: boolean) => boolean)) => {
    setEdit(doEdit);
  };

  const action = useMemo(
    () =>
      deleteExistingPaymentMethods ? (
        <Box display="flex">
          <AddPaymentMethodButton
            walletOwnerAiloRN={walletOwnerAiloRN}
            managingOrganisationAiloRN={managingOrganisationAiloRN}
            showUpdateBillsOption={showUpdateBillsOption}
            confirmLabel={`${data?.length ? "Replace" : "Add"} payment method`}
          />
        </Box>
      ) : (
        <Box display="flex">
          {edit && (
            <>
              <AddPaymentMethodButton
                walletOwnerAiloRN={walletOwnerAiloRN}
                managingOrganisationAiloRN={managingOrganisationAiloRN}
                allowedMethodTypes={allowedMethodTypes}
              />
              <CloseButton onClick={() => onEdit(false)} />
            </>
          )}
          {!edit && <EditButton onClick={() => onEdit(true)} />}
        </Box>
      ),
    [
      edit,
      deleteExistingPaymentMethods,
      allowedMethodTypes,
      showUpdateBillsOption,
      walletOwnerAiloRN,
      managingOrganisationAiloRN,
      data,
    ]
  );
  return (
    <DetailCard
      title="Payment Methods"
      contents={renderPaymentMethodList({
        loaded,
        error,
        isEmpty: isEmptyPaymentMethods,
        groupedPaymentMethods,
      })}
      action={action}
    />
  );
};

function groupPaymentMethodsByType(paymentMethods: any[]) {
  const paymentMethodsByType = paymentMethods?.reduce(
    (groups: { [x: string]: any[] }, item: { __typename: string | number }) => {
      groups[item.__typename] = groups[item.__typename] || [];

      groups[item.__typename].push(item);

      return groups;
    },
    {}
  );
  const groupedPaymentMethods = [];
  groupedPaymentMethods.push({
    title: "Credit or Debit",
    data: paymentMethodsByType?.CreditCard,
  });
  groupedPaymentMethods.push({
    title: "Bank Accounts",
    data: paymentMethodsByType?.BankAccount,
  });
  groupedPaymentMethods.push({
    title: "BPay",
    data: paymentMethodsByType?.BPay,
  });
  return groupedPaymentMethods;
}

function sortPaymentMethods(paymentMethods: any[]) {
  return paymentMethods.sort(paymentMethodsCompare);
}

function paymentMethodsCompare(
  paymentMethod1: PaymentMethodParams,
  paymentMethod2: PaymentMethodParams
): number {
  const sanitisedPaymentMethod1 = sanitisePaymentMethodParams(paymentMethod1);
  const sanitisedPaymentMethod2 = sanitisePaymentMethodParams(paymentMethod2);
  if (sanitisedPaymentMethod1.isDeleted !== sanitisedPaymentMethod2.isDeleted) {
    if (sanitisedPaymentMethod1.isDeleted) {
      return 1;
    }
    return -1;
  }
  if (sanitisedPaymentMethod1.isOnceOff !== sanitisedPaymentMethod2.isOnceOff) {
    if (sanitisedPaymentMethod1.isOnceOff) {
      return 1;
    }
    return -1;
  }
  if (sanitisedPaymentMethod1.isHidden !== sanitisedPaymentMethod2.isHidden) {
    if (sanitisedPaymentMethod1.isHidden) {
      return 1;
    }
  }
  return -1;
}

interface PaymentMethodParams {
  deletedAt: any;
  isOnceOff: any;
  isHidden: any;
}
interface SanitisedPaymentMethodParams {
  isDeleted: boolean;
  isOnceOff: boolean;
  isHidden: boolean;
}

function sanitisePaymentMethodParams(
  paymentMethod: PaymentMethodParams
): SanitisedPaymentMethodParams {
  return {
    isDeleted: !!paymentMethod.deletedAt,
    isOnceOff: !!paymentMethod.isOnceOff,
    isHidden: !!paymentMethod.isHidden,
  };
}
