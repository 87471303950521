import { CREATE } from "react-admin";
import { useCallback } from "react";
import { useMutation } from "../../../common/api/useMutation";
import { AddCentrepayDirectiveInput } from "../../../api/graphql/types.generated";
import { CentrepayPaymentDirective } from "../../../api/ResourceName";

export function useAddCentrepayDirective(
  tenancy: string,
  legalEntity: string,
  managingEntity: string
): () => Promise<void> {
  const [addCentrepayDirectiveMutation] = useMutation<{
    input: AddCentrepayDirectiveInput;
  }>({
    resource: CentrepayPaymentDirective,
    type: CREATE,
  });
  return useCallback(async () => {
    await addCentrepayDirectiveMutation({
      payload: {
        input: {
          tenancy,
          legalEntity,
          managingEntity,
        },
      },
    });
  }, [addCentrepayDirectiveMutation, tenancy, legalEntity, managingEntity]);
}
