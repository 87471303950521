/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Box, Checkbox, List, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";
import { GET_LIST, useInput, Validator } from "react-admin";
import { isPresent } from "ts-is-present";
import { PaymentMethod } from "../../api/ResourceName";
import { hasPresentKeys, PercentInput, useQueryWithStore } from "../../common";
import { Colors } from "../../common/ui/colors";

type PartialArray<T extends unknown[]> = [...Partial<T>];

type PartialFunction<F extends (...args: unknown[]) => unknown> = (
  ...args: PartialArray<Parameters<F>>
) => ReturnType<F>;

type BankAccountSplitPaymentMethodsOfOwnerInputProps = {
  owner?: string;
  label?: string;
  source: string;
  validate?: PartialFunction<Validator>[];
  hasErrorAlreadyShowing?: boolean;
};

const BankAccountSplitPaymentMethodsOfOwnerInput = ({
  owner,
  source,
  validate,
  label: _label,
  hasErrorAlreadyShowing,
}: BankAccountSplitPaymentMethodsOfOwnerInputProps): React.ReactElement | null => {
  const { loaded, data = [] } = useQueryWithStore({
    type: GET_LIST,
    resource: PaymentMethod,
    payload: {
      filter: {
        owner,
      },
    },
  });

  const [selectedAccounts, setSelectedAccounts] = useState<
    { [k in string]?: boolean }
  >({});

  const toggleAccountSelection = useCallback(
    (id: string) => {
      setSelectedAccounts({ ...selectedAccounts, [id]: !selectedAccounts[id] });
    },
    [selectedAccounts]
  );

  const bankAccountMethods = data
    .filter(isPresent)
    .filter(hasPresentKeys("accountNumber", "accountName"));

  const {
    input: { value, onChange },
  } = useInput({
    source,
    validate,
  });

  const setAccountShare = useCallback(
    (id: string, share: number | string | null): void => {
      onChange({ ...value, [id]: share });
    },
    [onChange, value]
  );

  const isValueEmpty = !value;
  const defaultMethodId = bankAccountMethods.find(
    (account) => account?.isDefaultOut
  )?.id;
  useEffect(() => {
    if (isValueEmpty && defaultMethodId) {
      toggleAccountSelection(defaultMethodId);
      setAccountShare(defaultMethodId, 100);
    }
  }, [isValueEmpty, defaultMethodId, toggleAccountSelection, setAccountShare]);

  const error = validate?.map((validator) => validator(value)).find(isPresent);

  if (!loaded) {
    return null;
  }

  return (
    <Box>
      <List>
        {bankAccountMethods.map((method) => (
          <PaymentMethodListItem
            key={method.id + String(selectedAccounts[method.id])}
            method={method}
            source={source}
            selected={selectedAccounts[method.id]}
            onCheckBoxClick={() => {
              toggleAccountSelection(method.id);
              setAccountShare(
                method.id,
                selectedAccounts[method.id] ? null : ""
              );
            }}
          />
        ))}
      </List>
      {!hasErrorAlreadyShowing && (
        <div style={{ minHeight: 50, maxWidth: "100%" }}>
          {!!error && <Alert severity="error">{error}</Alert>}
        </div>
      )}
    </Box>
  );
};

BankAccountSplitPaymentMethodsOfOwnerInput.defaultProps = {
  addLabel: true,
};

const PaymentMethodListItem = ({
  method,
  selected,
  onCheckBoxClick,

  source,
}: {
  method: { accountName: string; accountNumber: string; id: string };
  selected: boolean | undefined;
  onCheckBoxClick: () => void;

  source: string;
}): React.ReactElement => {
  return (
    <Box display="flex" flexDirection="row" width={400}>
      <Checkbox
        checked={!!selected}
        onChange={onCheckBoxClick}
        name="bankAccountEnabled"
        style={{ height: "fit-content", alignSelf: "center" }}
      />
      <Box height="fit-content" alignSelf="center">
        <Typography>{method.accountName}</Typography>
        <Typography
          style={{
            color: Colors.TEXT.DARK.SECONDARY,
            fontSize: "0.8125rem",
          }}
        >
          {method.accountNumber}
        </Typography>
      </Box>
      <Box
        flex="flex-end"
        marginLeft="auto"
        marginRight={0}
        maxWidth={80}
        alignSelf="center"
        maxHeight={60}
      >
        <PercentInput
          format="decimal"
          label="% share"
          source={`${source}[${method.id}]`}
          disabled={!selected}
          dp={2}
          range={[0, 100]}
        />
      </Box>
    </Box>
  );
};

export { BankAccountSplitPaymentMethodsOfOwnerInput };
