import React, { ReactElement } from "react";
import { DetailCard } from "../../../../../common";
import { PersonCompaniesOwnedList } from "./personCompaniesOwnedList";
import { OperationData, Person } from "../../../../../api";

interface Props {
  person: OperationData<typeof Person, "GET_ONE">;
}

export function PersonCompaniesOwnedCard({ person }: Props): ReactElement {
  return (
    <DetailCard
      title="Companies Owned"
      contents={<PersonCompaniesOwnedList person={person} />}
    />
  );
}
