import React from "react";
import { TextInput, TextInputProps } from "react-admin";

type InvoiceNumberInputProps = Pick<TextInputProps, "style" | "onBlur">;

export function InvoiceNumberInput(
  props: InvoiceNumberInputProps
): React.ReactElement {
  return <TextInput label="Invoice Number" source="invoiceNumber" {...props} />;
}
