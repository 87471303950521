import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React from "react";
import { Colors } from "../colors";

export const RightChevronIcon = () => {
  return (
    <ChevronRight
      style={{
        color: Colors.AILO_RED,
      }}
    />
  );
};
export const LeftChevronIcon = () => {
  return (
    <ChevronLeft
      style={{
        color: Colors.AILO_RED,
      }}
    />
  );
};
