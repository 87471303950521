import React from "react";
import { CreditCardListItem } from "./creditCardListItem";
import { BankAccountListItem } from "./bankAccountListItem";
import { OnceOffBankAccountListItem } from "./onceOffBankAccountListItem";
import { BPayListItem } from "./bPayListItem";

export const PaymentMethodListItem = ({ paymentMethod, edit }: any) => {
  const isDeleted = !!paymentMethod.deletedAt;
  const isHidden = !!paymentMethod.isHidden;

  if (paymentMethod.__typename === "BankAccount") {
    const {
      id,
      bsb,
      accountName,
      accountNumber,
      isAutoPay,
      isAutoWithdraw,
      isOnceOff,
      isDefaultIn,
      isDefaultOut,
    } = paymentMethod;
    return isOnceOff ? (
      <OnceOffBankAccountListItem
        id={id}
        bsb={bsb}
        maskedAccountNumber={accountNumber}
        isDefaultIn={isDefaultIn}
        isDefaultOut={isDefaultOut}
        isDeleted={isDeleted}
        isHidden={isHidden}
        wallet={paymentMethod.wallet}
      />
    ) : (
      <BankAccountListItem
        id={id}
        accountName={accountName}
        bsb={bsb}
        maskedAccountNumber={accountNumber}
        isAutoPay={isAutoPay}
        isAutoWithdraw={isAutoWithdraw}
        isDefaultIn={isDefaultIn}
        isDefaultOut={isDefaultOut}
        isDeleted={isDeleted}
        isHidden={isHidden}
        edit={edit}
        wallet={paymentMethod.wallet}
      />
    );
  }

  if (paymentMethod.__typename === "CreditCard") {
    const {
      id,
      number,
      expiry,
      type,
      isAutoPay,
      isDefaultIn,
      isDefaultOut,
    } = paymentMethod;
    return (
      <CreditCardListItem
        id={id}
        cardType={type}
        maskedCardNumber={number}
        expiry={expiry}
        isAutoPay={isAutoPay}
        isDefaultIn={isDefaultIn}
        isDefaultOut={isDefaultOut}
        isDeleted={isDeleted}
        isHidden={isHidden}
        edit={edit}
        wallet={paymentMethod.wallet}
      />
    );
  }

  const { id, billerCode, isDefaultIn, isDefaultOut } = paymentMethod;
  return (
    <BPayListItem
      id={id}
      billerCode={billerCode}
      isDefaultIn={isDefaultIn}
      isDefaultOut={isDefaultOut}
      isDeleted={isDeleted}
      isHidden={isHidden}
      wallet={paymentMethod.wallet}
    />
  );
};
