import { getTaxCategoryName } from "./getTaxCategoryName";

const getBillLiabilityEntryDescription = (entry: any): string => {
  const taxCategoryName = getTaxCategoryName(entry.liability?.taxCategory);
  const header = taxCategoryName || "Bill";

  switch (entry.entryType) {
    case "PAYMENT":
      if (entry.amount.cents > 0) {
        return `Reversal of ${header} Payment`;
      }

      return `${header} Paid`;

    case "PLANBASED":
      return `${header} Due`;

    case "ADJUSTMENT":
      if (entry.amount.cents > 0) {
        return `One-off ${header} Adjustment`;
      }

      return `${header} Credit`;

    default:
      throw new Error("Unknown liability entry type");
  }
};

const getRentLiabilityEntryDescription = (entry: any): string => {
  const taxCategoryName = entry.liability?.taxCategory
    ? getTaxCategoryName(entry.liability.taxCategory)
    : undefined;
  const header = taxCategoryName || "Rent";

  switch (entry.entryType) {
    case "PAYMENT":
      if (entry.amount.cents > 0) {
        return `Reversal of ${header} Payment`;
      }

      return `${header} Payment`;

    case "PLANBASED":
      return `${header} Due`;

    case "ADJUSTMENT":
      if (entry.amount.cents > 0) {
        return `One-off ${header} Adjustment`;
      }

      return `${header} Credit`;

    default:
      throw new Error("Unknown liability entry type");
  }
};

function getLiabilityEntryDescription(entry: any): string {
  if (entry.liability?.category === "BILL") {
    return getBillLiabilityEntryDescription(entry);
  }

  if (
    entry.liability?.category === "RENT" ||
    entry.liability?.category === "SUBSCRIPTION_BASED"
  ) {
    return getRentLiabilityEntryDescription(entry);
  }

  throw new Error("Unknown liability entry category");
}

export { getLiabilityEntryDescription };
