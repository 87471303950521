import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, Typography } from "@material-ui/core";
import React from "react";
import { ConfirmCloseDialogActions } from "../../../../common/ui/dialogs";

export const DeletePaymentMethodDialogScreen = ({
  isAutoPay,
  isAutoWithdraw,
  onConfirmClick,
  onCancelClick,
}: any) => {
  const autoPlanDescription = getAutoPlanDescription({
    isAutoPay,
    isAutoWithdraw,
  });
  return (
    <>
      <DialogTitle>Remove payment method</DialogTitle>
      <DialogContent>
        {autoPlanDescription && (
          <Typography paragraph>
            By removing this payment method, the {autoPlanDescription} will also
            be removed.
          </Typography>
        )}
        <Typography paragraph>
          Are you sure you want to remove this payment method?
        </Typography>
      </DialogContent>
      <ConfirmCloseDialogActions
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
    </>
  );
};

function getAutoPlanDescription({ isAutoPay, isAutoWithdraw }: any) {
  if (isAutoPay && isAutoWithdraw) {
    return "auto transfer and auto payment";
  }

  if (isAutoPay) {
    return "auto payment";
  }

  if (isAutoWithdraw) {
    return "auto transfer";
  }
}
