import React from "react";
import { DetailCard } from "../../../../../common/ui/cards";
import { SubscriptionsList } from "./subscriptionsList";

export const SubscriptionsCard = ({ activeSubscriptions }: any) => {
  return (
    <DetailCard
      title="Subscriptions"
      contents={<SubscriptionsList subscriptions={activeSubscriptions} />}
    />
  );
};
