import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

/**
 * @deprecated Use <Button /> & Link components directly
 */
export function GoButton(props: {
  id: any;
  resource: any;
  label: any;
  variant?: any;
}) {
  const { id, resource, label, variant } = props;
  return (
    <Button
      component={Link}
      id={id}
      variant={variant}
      color="primary"
      resource={resource}
      size="small"
      to={`/${resource}/${id}/show`}
    >
      {label}
    </Button>
  );
}
