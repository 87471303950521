import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import React, { useState } from "react";
import { Colors } from "../../common";
import { DirectWalletTransferDialog } from "./DirectWalletTransferDialog";

const TransferButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children?: React.ReactNode;
}): React.ReactElement => (
  <Button style={{ textTransform: "none" }} onClick={onClick}>
    <Box p={1} display="flex">
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <MonetizationOnIcon
        style={{ color: Colors.AILO_RED, marginRight: 5 }}
        variant="outlined"
      />
      <Typography
        style={{
          color: Colors.AILO_RED,
          fontSize: "1rem",
          fontWeight: 530,
          paddingTop: 1,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Button>
);

export function DirectWalletTransferButton({
  fromWalletId,
}: {
  fromWalletId: string;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <TransferButton onClick={onClick}>
        Transfer to another wallet
      </TransferButton>
      <DirectWalletTransferDialog
        open={open}
        onClose={onClose}
        fromWalletId={fromWalletId}
      />
    </div>
  );
}
