import { CREATE } from "react-admin";
import { useCallback } from "react";
import { AiloRN } from "@ailo/ailorn";
import { useMutation } from "ra-core";
import { OfflinePaymentAccount } from "../../../../api/ResourceName";

export function useCreateOfflinePaymentAccount(
  tenancy: AiloRN
): () => Promise<void> {
  const [createOfflinePaymentAccountMutation] = useMutation({
    payload: { entity: AiloRN },
    resource: OfflinePaymentAccount,
    type: CREATE,
  });
  return useCallback(async () => {
    await createOfflinePaymentAccountMutation({
      payload: {
        entity: tenancy,
      },
    });
  }, [createOfflinePaymentAccountMutation, tenancy]);
}
