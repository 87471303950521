import inflection from "inflection"; // eslint-disable-line
import { Alert } from "@material-ui/lab";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  number,
  TextInput,
  useDataProvider,
  minLength,
  maxLength,
  useNotify,
  useTranslate,
} from "react-admin";
import { useFormState, useForm } from "react-final-form"; //eslint-disable-line
import { Company } from "../../api";

const removeWhitespace = (v: string): string => v?.replaceAll(" ", "");

const makeHumanFriendly = (text: string, translate: any): string => {
  return translate(text, {
    smart_count: 1,
    _: inflection.humanize(text),
  });
};

const AbrLookupInput = ({
  expectedLength,
  abrLookupAutoPopulateFieldKeys,
  nonOverWriteableFields,
  autoPopulatedFields,
  fieldsThatShouldBeConsistentWithABR,
  ...props
}: {
  expectedLength: number;
  abrLookupAutoPopulateFieldKeys: { [k: string]: string };
  nonOverWriteableFields: Set<string>;
  autoPopulatedFields: Set<string>;
  fieldsThatShouldBeConsistentWithABR: string[];
} & any) => {
  const form = useForm();
  const formState = useFormState();
  const dataProvider = useDataProvider() as any;

  const { source } = props;
  const [lookupSuccess, setLookupSuccess] = useState(false);

  const notify = useNotify();

  const lastAbrLookupPromise = useRef<Promise<void> | null>(null);

  const populateFormFromData = useCallback(
    (data?, source?) => {
      Object.keys(abrLookupAutoPopulateFieldKeys)
        .filter((key) => key !== source)
        .filter(
          (key) =>
            autoPopulatedFields.has(key) ||
            !nonOverWriteableFields.has(key) ||
            !formState.values[key]
        )
        .forEach((key) => {
          form.change(key, data?.[abrLookupAutoPopulateFieldKeys[key]] || null);
          autoPopulatedFields.add(key);
        });
    },
    [
      form,
      formState.values,
      abrLookupAutoPopulateFieldKeys,
      autoPopulatedFields,
      nonOverWriteableFields,
    ]
  );

  const [data, setData] = useState<any>({});

  const onChange = useCallback(
    (event) => {
      // clear related fields
      populateFormFromData({}, source);
      const value = removeWhitespace(event.target.value);
      let abrLookupPromise: Promise<any>;

      const abrLookup = async () => {
        let newData: any = {};
        try {
          abrLookupPromise = dataProvider("abr_lookup", Company, {
            abnOrAcn: value,
          });
          lastAbrLookupPromise.current = abrLookupPromise;
          newData = (await abrLookupPromise).data;
          if (lastAbrLookupPromise.current !== abrLookupPromise) return;
          setLookupSuccess(newData.abn?.length > 0);
        } catch (error) {
          if (lastAbrLookupPromise.current !== abrLookupPromise) return;
          notify((error as Error).message, "warning");
          setLookupSuccess(false);
        }
        form.batch(() => {
          populateFormFromData(newData, source);
        });
        setData(newData);
      };

      if (value?.length === expectedLength && value.match(/^\d+$/)) {
        abrLookup();
      } else {
        setLookupSuccess(false);
      }
    },
    [form, dataProvider, expectedLength, source, notify, populateFormFromData]
  );

  // Trigger ABR lookup when form loads in order to check if form values are still up-to-date
  useEffect(() => {
    onChange({ target: { value: formState.values[source] } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abrLookupSuccessValidation = useCallback(
    (valueFriendlyName: string) => {
      return (value: string) =>
        lookupSuccess || !value
          ? undefined
          : `Can't find a registered company with that ${valueFriendlyName}`;
    },
    [lookupSuccess]
  );

  const lengthErrorMessage = `Must be ${expectedLength} digits`;

  const inconsistentFields = useMemo(() => {
    return fieldsThatShouldBeConsistentWithABR
      .filter(
        (key: string) =>
          data[abrLookupAutoPopulateFieldKeys[key]] !== formState.values[key]
      )
      .map((key: string) => ({
        key,
        value: data[abrLookupAutoPopulateFieldKeys[key]],
      }));
  }, [
    formState,
    data,
    abrLookupAutoPopulateFieldKeys,
    fieldsThatShouldBeConsistentWithABR,
  ]);

  const translate = useTranslate();

  return (
    <>
      <TextInput
        {...props}
        defaultValue={null}
        parse={removeWhitespace}
        validate={[
          ...(props.validate || []),
          number(),
          minLength(expectedLength, lengthErrorMessage),
          maxLength(expectedLength, lengthErrorMessage),
          abrLookupSuccessValidation(props.label),
        ]}
        onChange={onChange}
        style={{ marginBottom: 0 }}
      />
      {lookupSuccess && inconsistentFields.length > 0 && (
        <Alert style={{ marginBottom: 24, width: 224 }} severity="warning">
          Some fields are inconsistent with data retrieved from the Australian
          Business Registry.
          <ul>
            {inconsistentFields.map(
              ({ key, value }: { key: string; value: string }) => (
                <li key={key}>{`${makeHumanFriendly(
                  key,
                  translate
                )} (${value})`}</li>
              )
            )}
          </ul>
          Please check the information in these (and related) fields is correct.
        </Alert>
      )}
      {!formState.values[props.source] && formState.dirtyFields.name && (
        <Alert style={{ marginBottom: 24, width: 224 }} severity="warning">
          It is strongly recommended that you provide an ABN if possible.
        </Alert>
      )}
    </>
  );
};

export { AbrLookupInput };
