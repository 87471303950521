import { AiloRN } from "@ailo/ailorn";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { TenancyLiabilityCard } from "../../components";
import { DepositCard } from "../DepositCard";
import { ManagementTenantsCard } from "../ManagementTenantsCard";
import { OfflinePaymentAccountCards } from "../OfflinePaymentAccountCard/OfflinePaymentAccountCards";
import { BillsCard } from "../PropertyBillsCard";
import { TenancyBondCard } from "../TenancyBondCard";
import { TenancyMoneySettingsCard } from "../TenancyMoneySettingsCard";
import { TenancyWalletCard } from "../TenancyWalletCard";
import { PropertyShowManagement, PropertyShowTenancy } from "../types";

export function TenancyTab({
  management,
  tenancy,
}: {
  management: PropertyShowManagement;
  tenancy?: PropertyShowTenancy;
}): React.ReactElement {
  const deposit = tenancy?.deposit;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {tenancy && (
          <ManagementTenantsCard
            tenancy={tenancy}
            managingEntity={management.managingEntity?.ailoRN}
          />
        )}

        {!management.isDraft && tenancy && (
          <TenancyWalletCard tenancyAiloRN={tenancy.ailoRN} />
        )}
        {tenancy && (
          <TenancyMoneySettingsCard management={management} tenancy={tenancy} />
        )}
      </Grid>
      <Grid item xs={6}>
        {!management.isDraft && tenancy && (
          <TenancyLiabilityCard tenancy={tenancy} />
        )}
        {!management.isDraft && tenancy && (
          <BillsCard
            managementId={management.id}
            payerAilorn={tenancy.ailoRN}
          />
        )}
        {tenancy && deposit && (
          <DepositCard tenancyAiloRN={tenancy.ailoRN} deposit={deposit} />
        )}
        {tenancy?.id && (
          <TenancyBondCard
            tenancyId={tenancy?.id}
            managingEntity={management.managingEntity?.ailoRN}
          />
        )}
        {management.managingEntity?.ailoRN && tenancy && tenancy.ailoRN && (
          <OfflinePaymentAccountCards
            tenancy={AiloRN.from(tenancy.ailoRN)}
            agency={AiloRN.from(management.managingEntity?.ailoRN)}
          />
        )}
      </Grid>
    </Grid>
  );
}
