import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Typography from "@material-ui/core/Typography";
import { Colors } from "../../common";

export const TransferButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children?: React.ReactNode;
}): React.ReactElement => (
  <Button style={{ textTransform: "none" }} onClick={onClick}>
    <Box p={1} display="flex">
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <AccountBalanceIcon
        style={{ color: Colors.AILO_RED, marginRight: 5 }}
        variant="outlined"
      />
      <Typography
        style={{
          color: Colors.AILO_RED,
          fontSize: "1rem",
          fontWeight: 530,
          paddingTop: 1,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Button>
);
