import React from "react";
import { Property } from "../../api/ResourceName";
import { RecurringFeeForm } from "./form";
import { Create } from "../../common";

export const RecurringFeeCreate = (props: any) => {
  return (
    <Create {...props}>
      <RecurringFeeForm
        redirect={(
          _path: any,
          _id: any,
          data: { propertyId: any; managementId: any }
        ) =>
          `/${Property}/${data.propertyId}/show?management=${data.managementId}`
        }
      />
    </Create>
  );
};
