import React from "react";
import Slide from "@material-ui/core/Slide";
import { Dialog } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as any)} />;
});

export const SlideUpDialog = ({
  open,
  onClose,
  disableBackdropClick,
  children,
}: any) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      TransitionComponent={Transition as any}
    >
      {children}
    </Dialog>
  );
};
