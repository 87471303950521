import { Money } from "@ailo/money";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Error,
  FormDataConsumer,
  Loading,
  maxLength,
  required,
  SimpleFormProps,
  TextField,
  TextInput,
  useGetOne,
} from "react-admin";
import { useForm } from "react-final-form"; //eslint-disable-line
import { OperationData } from "../../api";
import {
  FeeBlueprintChargeType,
  FeeType,
} from "../../api/graphql/types.generated";
import { FeeBlueprint } from "../../api/ResourceName";
import {
  FeeBlueprintInput,
  maxMoney,
  minMoney,
  MoneyInput,
  OrganisationField,
  OrganisationInput,
  SimpleForm,
} from "../../common";
import { FeeAmountInput } from "./FeeAmountInput";
import { FeePercentInput } from "./FeePercentInput";

type BlueprintRecord = OperationData<typeof FeeBlueprint, "GET_ONE">;

function PrefillWithBlueprint({
  blueprintId,
  oneWeekRentAmount,
  onBlueprintLoaded,
}: {
  blueprintId: string;
  oneWeekRentAmount?: Money;
  onBlueprintLoaded: (blueprint: BlueprintRecord) => void;
}) {
  const { change } = useForm();
  const { data: blueprint, loading, error } = useGetOne<BlueprintRecord>(
    FeeBlueprint,
    blueprintId
  );

  useEffect(() => {
    if (!blueprint?.fixedAmount) {
      return;
    }
    change("amount", blueprint.fixedAmount);
  }, [change, blueprint?.fixedAmount]);

  useEffect(() => {
    if (
      blueprint?.oneWeekRentPercentage === undefined ||
      blueprint?.oneWeekRentPercentage === null ||
      oneWeekRentAmount === undefined
    ) {
      // TODO try using `destroyOnUnregister` to remove these values when their inputs unmount
      // instead of manually doing it here once react-admin is upgraded to 3.3.4+
      change("percentage", undefined);
      change("baseAmount", undefined);
      change("baseAmountType", undefined);
    } else {
      change("percentage", blueprint.oneWeekRentPercentage);
      change(
        "amount",
        oneWeekRentAmount.multiply(blueprint.oneWeekRentPercentage)
      );
      change("baseAmount", oneWeekRentAmount);
      change("baseAmountType", "OneWeekRent");
    }
  }, [change, blueprint?.oneWeekRentPercentage, oneWeekRentAmount]);

  useEffect(() => {
    if (!blueprint?.taxCategoryId) {
      return;
    }
    change("taxCategoryId", blueprint.taxCategoryId);
  }, [change, blueprint?.taxCategoryId]);

  useEffect(() => {
    if (blueprint) onBlueprintLoaded(blueprint);
  }, [blueprint, onBlueprintLoaded]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return null;
}

export function FeeForm(
  props: Omit<SimpleFormProps, "children">
): React.ReactElement {
  const create = !props.record.id;
  const { organisationId, dailyRate } = props.record;
  const oneWeekRentAmountMoney = useMemo(
    () =>
      dailyRate ? Money.fromCents((+dailyRate * 7).toFixed(2)) : undefined,
    [dailyRate]
  );
  const hasRentDailyRate = dailyRate != null;

  const [chargeType, setChargeType] = useState<
    BlueprintRecord["chargeType"] | undefined
  >(undefined);

  const onBlueprintLoaded = useCallback(
    (blueprint: BlueprintRecord) => {
      setChargeType(blueprint.chargeType);
    },
    [setChargeType]
  );

  return (
    <SimpleForm
      toolbarOptions={{
        saveLabel: create ? "Apply New Fee" : "Save",
        deleteButton: false,
      }}
      destroyOnUnregister
      {...props}
    >
      {create && (
        <FormDataConsumer>
          {({ formData: { blueprintId } }) =>
            blueprintId && (
              <PrefillWithBlueprint
                blueprintId={blueprintId}
                onBlueprintLoaded={onBlueprintLoaded}
                oneWeekRentAmount={oneWeekRentAmountMoney}
              />
            )
          }
        </FormDataConsumer>
      )}

      {create ? (
        <OrganisationInput
          source="organisationId"
          disabled
          validate={[required()]}
        />
      ) : (
        <OrganisationField source="blueprint.organisation" addLabel />
      )}

      {create ? (
        <TextInput source="managementId" disabled validate={[required()]} />
      ) : (
        <TextField label="Management ID" source="management.id" />
      )}

      <TextInput
        source="type"
        initialValue={FeeType.OneOffFee}
        disabled
        validate={[required()]}
      />
      {create ? (
        <FeeBlueprintInput
          source="blueprintId"
          disabled={!create}
          filter={
            create
              ? {
                  organisationId,
                  type: FeeType.OneOffFee,
                }
              : undefined
          }
          validate={[required()]}
        />
      ) : (
        <TextField label="Blueprint Name" source="blueprint.name" />
      )}
      {create ? (
        <TextInput source="taxCategoryId" disabled validate={[required()]} />
      ) : (
        <TextField label="Tax Category" source="taxCategory.name" />
      )}

      {(hasRentDailyRate &&
        chargeType === FeeBlueprintChargeType.OneWeekRentPercentage) ||
      (props.record?.blueprint?.chargeType ===
        FeeBlueprintChargeType.OneWeekRentPercentage &&
        props.record?.baseAmount != null) ? (
        <>
          <FeePercentInput />
          <MoneyInput
            source="baseAmount"
            label={
              create
                ? "Weekly Rent Amount ($)"
                : "Weekly Rent Amount (when fee was created) ($)"
            }
            fullWidth
            validate={[
              required(),
              minMoney({ cents: 100 }),
              maxMoney({ cents: 1300000 }),
            ]}
            disabled
          />
        </>
      ) : null}
      <FeeAmountInput />
      <TextInput
        label="Description for the payer (optional)"
        source="description"
        validate={[maxLength(60)]}
        fullWidth
      />
    </SimpleForm>
  );
}
