import { get, isEmpty } from "lodash";

export function parseSortParam(
  sortObject?: {
    field?: string | null | undefined;
    order?: "DESC" | "ASC" | null | undefined;
  } | null,
  customFieldMapper: Record<string, any> = {}
) {
  if (!sortObject || isEmpty(sortObject)) return null;
  const { field, order } = sortObject;
  const parsedField = get(customFieldMapper, field!) || field;
  const direction = order === "DESC" ? "-" : "";
  return parsedField ? `${direction}${parsedField}` : null;
}
