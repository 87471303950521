import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";
import type { Organisation } from "../../api/deprecatedTypes";
import {
  InjectedFieldProps,
  PublicFieldProps,
} from "./utils/react-admin-types";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
  chip: {
    margin: 4,
  },
});

const OrganisationActiveSubscriptionsField = ({
  record,
}: PublicFieldProps & InjectedFieldProps<Organisation>) => {
  const classes = useStyles();
  return record ? (
    <span className={classes.main}>
      {record.activeSubscriptions &&
        record.activeSubscriptions.items.map((s) => {
          return (
            <Chip key={s.plan.id} className={classes.chip} label={s.plan.id} />
          );
        })}
    </span>
  ) : null;
};

export { OrganisationActiveSubscriptionsField };
