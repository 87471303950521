import React from "react";
import { Button } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { Colors } from "../../../../../common";
import { TenancyDepositRecord } from "../TenancyDepositRecord";
import { UpdateDepositDialog } from "./UpdateDepositDialog";

export const UpdateDepositButton = ({
  deposit,
}: {
  deposit: TenancyDepositRecord;
}): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        disableElevation
        style={{
          textTransform: "none",
          color: Colors.AILO_RED,
        }}
        startIcon={<AssignmentTurnedInIcon />}
        onClick={onClick}
      >
        Update status
      </Button>
      <UpdateDepositDialog deposit={deposit} open={open} onClose={onClose} />
    </div>
  );
};
