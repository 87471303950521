import { ApolloLink } from "apollo-link";

const operationNameLink = (baseUri: string): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ uri = baseUri }) => ({
      uri: `${uri}?op=${operation.operationName}`,
    }));

    return forward(operation);
  });
};

export { operationNameLink };
