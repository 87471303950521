/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  // eslint-disable-next-line no-restricted-imports
  useQueryWithStore as reactAdminUseQueryWithStore,
  QueryOptions,
} from "react-admin";
import {
  TypedResourceName,
  OperationType,
  OperationParams,
  OperationData,
} from "../../api";

type Query<
  TResourceName extends string = string,
  TOperationType extends string | number | symbol = string,
  TPayload = any
> = {
  resource: TResourceName;
  type: TOperationType;
  payload: TPayload;
};

type UseQueryWithStoreResult<TData = any> = {
  data?: TData;
  total?: number;
  error?: any;
  loading: boolean;
  loaded: boolean;
};

export function useQueryWithStore<
  TResourceName = string,
  TOperationType = string,
  TData = TResourceName extends TypedResourceName
    ? TOperationType extends OperationType<TResourceName>
      ? OperationData<TResourceName, TOperationType>
      : any
    : any
>(
  query: TResourceName extends TypedResourceName
    ? TOperationType extends OperationType<TResourceName>
      ? Query<
          TResourceName,
          TOperationType,
          OperationParams<TResourceName, TOperationType>
        >
      : Query<string, string, any>
    : Query<string, string, any>,
  options?: QueryOptions
): UseQueryWithStoreResult<TData>;

export function useQueryWithStore(
  query: Query,
  options?: QueryOptions
): UseQueryWithStoreResult {
  return reactAdminUseQueryWithStore(query, options);
}
