import { LocalDate } from "@ailo/date";
import { Button } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import React, { useState } from "react";
import { Management } from "../../api/ResourceName";
import { CLEAR_MANAGEMENT_END } from "../../api/resources/Management/managementsClient";
import { ConfirmMutationDialog } from "../../common";

export function ClearManagementEndButton({
  managementId,
  endDate,
  style,
}: {
  managementId: string;
  endDate: Date;
  style?: React.CSSProperties;
}): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const hasManagementEnded = isFormerManagement(endDate.toString());

  const label = hasManagementEnded
    ? "Restart management"
    : "Stop ending management";
  const confirmationContent = hasManagementEnded
    ? "Are you sure you want to restart the management? Any recurring fee schedules will start again."
    : "Are you sure you want to stop management from ending?";

  const successMessage = hasManagementEnded
    ? "Management restarted"
    : "Management will no longer end";

  return (
    <div style={style}>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<UpdateIcon />}
        onClick={() => setDialogOpen(true)}
      >
        {label}
      </Button>
      <ConfirmMutationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        confirmation={{
          title: label,
          content: confirmationContent,
        }}
        successNotification={successMessage}
        failureNotification="Failed to clear management end"
        mutationParams={{
          type: CLEAR_MANAGEMENT_END,
          resource: Management,
          payload: {
            managementId,
          },
        }}
      />
    </div>
  );
}

function isFormerManagement(endDate?: string | null): boolean {
  if (!endDate) return false;

  return LocalDate.from(endDate).isSameOrBefore(LocalDate.today());
}
