import moment from "moment-timezone";
import React from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  Link,
  TextField,
  TextInput,
  ExportButton,
  TopToolbar,
} from "react-admin";
import { downloadCSV, useListContext } from "ra-core";
import jsonExport from "jsonexport";
import { LinkField, List } from "../../common";
import { AiloRNField, MoneyField } from "../../common";
import { LeftChevronIcon } from "../../common/ui/icons/chevronIcon";
import { transactionListRowStyle } from "./transactionListRowStyle";
import { formatDateTimeToLocal } from "../../common/utils/formatDate";

const ChevronLink = ({ record, source }: any) => {
  return (
    <Link to={`/Transactions/${record[source]}/show`}>
      <LeftChevronIcon />
    </Link>
  );
};

const PostListActions = () => {
  const { total } = useListContext();
  return (
    <TopToolbar>
      <ExportButton maxResults={total} />
    </TopToolbar>
  );
};

const exporter = (records: any[]) => {
  const recordsForExport = records.map(
    (record: {
      createdAt: any;
      processOrSettlementDate: any;
      clearedAt: any;
      bankSettlementDate: any;
    }) => {
      return {
        ...record,
        createdAt: formatDateTimeToLocal(record.createdAt),
        processOrSettlementDate: formatDateTimeToLocal(
          record.processOrSettlementDate
        ),
        clearedAt: formatDateTimeToLocal(record.clearedAt),
        bankSettlementDate: formatDateTimeToLocal(record.bankSettlementDate),
      };
    }
  );
  jsonExport(recordsForExport, (err: any, csv: any) => {
    downloadCSV(csv, "transactions");
  });
};

const TransactionsList = (props: Record<string, any>) => {
  const afterDateDefaultFilter = moment()
    .subtract(3, "days")
    .format(moment.HTML5_FMT.DATE);
  return (
    <List
      {...props}
      perPage={50}
      title="Transactions"
      bulkActionButtons={false}
      sort={{
        field: "createdAt",
        order: "DESC",
      }}
      filters={
        <Filter {...props}>
          <DateInput label="Settled After" source="afterDate" alwaysOn />
          <DateInput label="Settled Before" source="beforeDate" alwaysOn />
          <TextInput
            label="Transaction Id"
            source="businessTransactionId"
            alwaysOn
          />
        </Filter>
      }
      filterDefaultValues={{
        afterDate: afterDateDefaultFilter,
      }}
      actions={<PostListActions />}
      exporter={exporter}
    >
      <Datagrid rowStyle={transactionListRowStyle}>
        <ChevronLink label="" source="id" />
        <DateField label="Created" source="createdAt" showTime />
        <DateField
          label="Process / Settlement Date"
          source="processOrSettlementDate"
        />
        <DateField label="Clearing Date" source="clearedAt" />
        <DateField label="Bank Settlement Date" source="bankSettlementDate" />
        <MoneyField
          label="Requested Amount ($)"
          source="originalPaymentAmount"
          textAlign="right"
          showAbsolute
        />
        <MoneyField
          label="Total Amount ($)"
          source="totalPaymentAmount"
          textAlign="right"
          showAbsolute
        />
        <MoneyField
          label="Ailo Fee Amount ($)"
          source="ailoFeeAmount"
          textAlign="right"
          showAbsolute
        />
        <TextField label="Status" source="status" />
        <TextField label="Transaction Type" source="transactionType" />
        <TextField label="Payment Type" source="paymentType" />
        <FunctionField
          label="Bank Channel"
          render={(record: any) => {
            return record.bankingChannel ? (
              <ChipField
                record={record}
                label="Bank Channel"
                source="bankingChannel"
              />
            ) : null;
          }}
        />

        <FunctionField
          label="Product"
          render={(record: any) => {
            const result =
              record.product === "SUBSCRIPTION_BASED"
                ? "SUBSCRIPTION"
                : record.product;
            return <span>{result}</span>;
          }}
        />
        <LinkField label="Payer" source="payerId" />
        <LinkField label="Payee" source="payeeId" />
        <AiloRNField
          label="Transaction Id"
          source="businessTransactionId"
          showId
        />
        <AiloRNField label="Payment Id" source="paymentTransactionId" showId />
        <AiloRNField
          label="Response Code"
          source="responseCode"
          showId={false}
        />
        <TextField label="Bill Type" source="billType" />
        <TextField label="Tax Category" source="taxCategory" />
      </Datagrid>
    </List>
  );
};

export { TransactionsList };
