import { AiloRN } from "@ailo/ailorn";
import gql from "graphql-tag";
import { CREATE, DELETE, UPDATE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  CreateTeamMutation,
  CreateTeamMutationVariables,
  DeleteTeamMutation,
  DeleteTeamMutationVariables,
  EditTeamMutation,
  EditTeamMutationVariables,
  MoveAllManagementsToTeamMutation,
  MoveAllManagementsToTeamMutationVariables,
} from "./teamsClient.generated";

export const MOVE_MANAGEMENTS = "MOVE_MANAGEMENTS";

export const TeamClientDefinition = {
  [CREATE]: (params: {
    data: {
      organisationAilorn: AiloRN;
      name: string;
    };
  }) => ({
    query: gql`
      mutation createTeam($organisationAilorn: AiloRN!, $name: String!) {
        createTeam(organisationId: $organisationAilorn, name: $name) {
          ailorn: id
          name
        }
      }
    `,
    variables: <CreateTeamMutationVariables>{
      organisationAilorn: params.data.organisationAilorn.toString(),
      name: params.data.name,
    },
    parseResponse(response: GraphQLResponse<CreateTeamMutation>) {
      return {
        data: response.data.createTeam,
      };
    },
  }),
  [DELETE]: (params: {
    data: {
      team: AiloRN<"propertymanagement:team">;
    };
  }) => ({
    query: gql`
      mutation deleteTeam($team: AiloRN!) {
        deleteTeam(input: { teamAilorn: $team }) {
          teamAilorn
        }
      }
    `,
    variables: <DeleteTeamMutationVariables>{
      team: params.data.team.toString(),
    },
    parseResponse(response: GraphQLResponse<DeleteTeamMutation>) {
      return {
        data: response.data.deleteTeam,
      };
    },
  }),
  [UPDATE]: (params: {
    data: {
      team: AiloRN<"propertymanagement:team">;
      name: string;
    };
  }) => ({
    query: gql`
      mutation editTeam($team: AiloRN!, $name: String!) {
        editTeam(input: { teamAilorn: $team, name: $name }) {
          team {
            id
            name
          }
        }
      }
    `,
    variables: <EditTeamMutationVariables>{
      team: params.data.team.toString(),
      name: params.data.name,
    },
    parseResponse(response: GraphQLResponse<EditTeamMutation>) {
      return {
        data: response.data.editTeam,
      };
    },
  }),
  [MOVE_MANAGEMENTS]: (params: {
    data: {
      fromTeam: AiloRN<"propertymanagement:team">;
      toTeam: AiloRN<"propertymanagement:team">;
    };
  }) => ({
    query: gql`
      mutation moveAllManagementsToTeam($fromTeam: AiloRN!, $toTeam: AiloRN!) {
        moveAllManagementsToTeam(
          input: { fromTeam: $fromTeam, toTeam: $toTeam }
        ) {
          newTeam {
            id
          }
        }
      }
    `,
    variables: <MoveAllManagementsToTeamMutationVariables>{
      fromTeam: params.data.fromTeam.toString(),
      toTeam: params.data.toTeam.toString(),
    },
    parseResponse(response: GraphQLResponse<MoveAllManagementsToTeamMutation>) {
      return {
        data: response.data.moveAllManagementsToTeam,
      };
    },
  }),
};

export const teamClient = createClientFromDefinition(TeamClientDefinition);
