/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformLiabilityPlanChangeRequest(
  setupObject: Record<string, any>
) {
  if (!setupObject) {
    return {};
  }
  const { liabilityId, proposedPlans, ...setupInfo } = setupObject;
  return {
    ...setupInfo,
    liabilityId,
    proposedPlans: proposedPlans.map(
      (t: { amount: any; frequency: any; startDate: moment.MomentInput }) => ({
        amount: {
          cents: t.amount,
        },
        frequency: t.frequency,
        desiredStartDate: moment(t.startDate).format(),
      })
    ),
  };
}
