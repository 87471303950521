import React from "react";
import { ListItemView, NameAvatars, formatDate } from "../../../../common";
import { getTenancyType, TenancyType } from "../../../Tenancy";

const TenancySwitcherItem = ({ tenancy }: any) => {
  const tenancyType = getTenancyType(
    tenancy.startDate,
    tenancy.endDate,
    !!tenancy.voidedAt
  );
  const tenantNames = tenancy.tenants.map((item: any) => item.tenant);
  const timeRange = getTimeRange({
    id: tenancy.id,
    type: tenancyType,
    start: tenancy.startDate,
    end: tenancy.endDate,
    voidedAt: tenancy.voidedAt,
  });
  return (
    <ListItemView
      header={tenancyType}
      subHeader={timeRange}
      rightComponent={<NameAvatars names={tenantNames} />}
    />
  );
};

function getTimeRange({
  id,
  type,
  start,
  end,
  voidedAt,
}: {
  id: string;
  type: TenancyType;
  start: string;
  end?: string;
  voidedAt?: string;
}): string {
  switch (type) {
    case "Active": {
      if (end) return `Ending ${formatDate(end)}`;
      return `Started ${formatDate(start)}`;
    }
    case "Ingoing":
      return `Starting ${formatDate(start)} `;
    case "Void":
      return `Voided ${formatDate(voidedAt)} `;
    case "Former": {
      if (end) {
        return `${formatDate(start)} - ${formatDate(end)} `;
      }
      throw new Error(`Former Tenancy: ${id} end date not provided`);
    }
    default:
      throw new Error(
        `Could not get time range for tenancy: ${id} with ${JSON.stringify({
          type,
          start,
          end,
          voidedAt,
        })}`
      );
  }
}

export { TenancySwitcherItem };
