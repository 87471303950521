import React from "react";
import { TenantsCentrepayInfoProps } from "../TenantsCentrepayInfoProps";
import { useFetchCentrepayPaymentDirective } from "../queries/useFetchCentrepayPaymentDirectives";
import { getCurrentTenancyDirective } from "../getCurrentTenancyDirective";
import { getOtherTenancyDirective } from "../getOtherTenancyDirective";
import { CurrentTenancyDirective } from "./CurrentTenancyDirective";
import { OtherTenancyDirective } from "./OtherTenancyDirective";
import { MissingTenancyDirective } from "./MissingTenancyDirective";

export function PaymentDirective({
  tenancy,
  legalEntity,
  managingEntity,
  existingCrn,
}: { existingCrn: string } & TenantsCentrepayInfoProps): React.ReactElement {
  const paymentDirectives = useFetchCentrepayPaymentDirective({
    legalEntity,
    managingEntity,
  });

  const tenancyDirective = getCurrentTenancyDirective(
    paymentDirectives,
    tenancy
  );
  const otherTenancyDirective = getOtherTenancyDirective(
    paymentDirectives,
    tenancy
  );

  return (
    <>
      {tenancyDirective && (
        <CurrentTenancyDirective
          tenancy={tenancy}
          legalEntity={legalEntity}
          managingEntity={managingEntity}
        />
      )}
      {otherTenancyDirective && (
        <OtherTenancyDirective tenancyDirective={otherTenancyDirective} />
      )}
      {!otherTenancyDirective && !tenancyDirective && existingCrn && (
        <MissingTenancyDirective
          tenancy={tenancy}
          legalEntity={legalEntity}
          managingEntity={managingEntity}
        />
      )}
    </>
  );
}
