import React from "react";
import Box from "@material-ui/core/Box";
import { Badge } from "../../../../common";
import { formatMoney } from "../../../../common";

const RentHistoryDetails = ({ liabilityEntry }: any) => {
  const amount = liabilityEntry.amount.cents;
  const status = getLiabilityEntryStatus(liabilityEntry);
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Box textAlign="right" marginBottom="2px">
        {formatMoney({
          cents: amount,
        })}
      </Box>
      {
        ({
          Pending: <Badge.Default>{status}</Badge.Default>,
          Failed: <Badge.Critical>{status}</Badge.Critical>,
          Reversal: <Badge.Critical>{status}</Badge.Critical>,
          Paid: <Badge.Success>{status}</Badge.Success>,
          Unknown: null,
        } as any)[status]
      }
    </Box>
  );
};

const Status = {
  Pending: "Pending",
  Failed: "Failed",
  Reversal: "Reversal",
  Paid: "Paid",
  Unknown: "Unknown",
};

function getLiabilityEntryStatus(liabilityEntry: any): any {
  if (liabilityEntry.status === "PENDING") return Status.Pending;

  if (liabilityEntry.status === "FAIL" || liabilityEntry.status === "CLEANED") {
    return Status.Failed;
  }

  if (
    liabilityEntry.entryType === "PAYMENT" &&
    liabilityEntry.amount.cents > 0
  ) {
    return Status.Reversal;
  }

  if (liabilityEntry.status === "SUCCESS") return Status.Paid;
  return Status.Unknown;
}

export { RentHistoryDetails };
