import { CREATE } from "react-admin";
import gql from "graphql-tag";
import type {
  RaDataGraphqlClientArgs,
  PlatformSubscription,
} from "../../deprecatedTypes";
import { removeWhitespace, transformUserForRequest } from "../../common";

export const UPDATE_SUBSCRIPTION_END_DATE = "UPDATE_SUBSCRIPTION_END_DATE";

function subscriptionClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CREATE:
      return {
        query: gql`
          mutation createSubscriptions(
            $customer: ID!
            $plan: String!
            $startsOn: Date!
            $endsOn: Date
          ) {
            subscribeCustomer(
              subscription: {
                customerId: $customer
                planId: $plan
                startsOn: $startsOn
                endsOn: $endsOn
              }
            ) {
              id
              plan {
                id
              }
              startsOn
              endsOn
              customer {
                id
              }
            }
          }
        `,
        variables: removeWhitespace(transformUserForRequest(params.data)),
        parseResponse: (response: {
          data: {
            subscribeCustomer: PlatformSubscription;
          };
        }) => ({
          data: response.data.subscribeCustomer,
        }),
      };

    case UPDATE_SUBSCRIPTION_END_DATE:
      return {
        query: gql`
          mutation setSubscriptionEndDate($subscriptionId: ID!, $endsOn: Date) {
            setSubscriptionEndDate(
              subscription: { subscriptionId: $subscriptionId, endsOn: $endsOn }
            ) {
              id
              plan {
                id
              }
              startsOn
              endsOn
              customer {
                id
              }
            }
          }
        `,
        variables: {
          subscriptionId: params.data.subscriptionId,
          endsOn: params.data.endsOn,
        },
        parseResponse: (response: {
          data: {
            setSubscriptionEndDate: PlatformSubscription;
          };
        }) => ({
          data: response.data.setSubscriptionEndDate,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { subscriptionClient };
