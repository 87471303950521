import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { AiloRN } from "@ailo/ailorn";
import { AutoPaymentSettingsCard } from "../../../features/AutoPaymentSettings";
import {
  ManagementFolioFormerManagementsCard,
  ManagementFolioPropertiesCard,
  ManagementFolioShowRecord,
} from "../../../features/ManagementFolio";
import {
  ManagementOwnersCard,
  PropertyAgencyCard,
} from "../../../features/Property";
import { getPrimaryOwnerAilorn } from "./getPrimaryOwner";
import { getPropertyAddresses } from "./getPropertyAddresses";
import { getTeams } from "./getTeams";

export function GeneralTab({
  managementFolio,
}: {
  managementFolio: ManagementFolioShowRecord;
}): React.ReactElement {
  const { wallet, owners } = managementFolio;
  const managements = managementFolio.managements.items;
  const formerManagements = managementFolio.formerManagements.items;

  const primaryOwnerAilorn = getPrimaryOwnerAilorn(managementFolio);
  const propertyAddresses = getPropertyAddresses(managementFolio);
  const teams = getTeams(managementFolio);

  if (!owners || !primaryOwnerAilorn) {
    return (
      <Alert severity="error" style={{ margin: 20 }}>
        Could not find primary owner
      </Alert>
    );
  }

  const managementReferenceList = managementFolio.managements.items.map(
    (item) => AiloRN.from(item.ailorn)
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PropertyAgencyCard
          title="Agency"
          agency={managementFolio.managingEntity}
          teams={teams}
          managementReferenceList={managementReferenceList}
        />
        <ManagementFolioPropertiesCard managements={managements} />
        <ManagementFolioFormerManagementsCard
          managementFolioToManagements={formerManagements}
        />
      </Grid>

      <Grid item xs={6}>
        <ManagementOwnersCard ownerships={owners} />

        <AutoPaymentSettingsCard
          legalEntityAilorn={primaryOwnerAilorn.toString()}
          walletOwnerReference={managementFolio.ailorn}
          allowEdit
          autoWithdrawPlans={wallet?.autoWithdrawPlans?.items ?? []}
          addAutoWithdrawPlanSettings={
            wallet
              ? {
                  walletId: wallet.id,
                  walletName: `Folio Wallet (${propertyAddresses})`,
                  transactionDescription: propertyAddresses,
                }
              : undefined
          }
          autoPayLiabilityStatus={wallet?.autoPayLiabilityStatus ?? undefined}
        />
      </Grid>
    </Grid>
  );
}
