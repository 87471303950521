import React from "react";
import { GET_ONE, useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { CentrepayAccount } from "../../../api";
import { CentrepayWalletCardItem } from "./CentrepayWalletCardItem";
import { VirtualAccountData } from "../../../common";

interface Params {
  agency: AiloRN;
}

const CentrepayWalletCards = ({
  agency,
}: Params): React.ReactElement | null => {
  const {
    data,
  }: {
    data?: VirtualAccountData;
  } = useQuery({
    type: GET_ONE,
    resource: CentrepayAccount,
    payload: {
      entity: agency.toString(),
    },
  });
  if (!data) {
    return null;
  }

  return <CentrepayWalletCardItem data={data} agency={agency} />;
};
export { CentrepayWalletCards };
