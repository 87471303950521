import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

export function autoPayLiabilitiesClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case "cancel_auto_pay_liability_status":
      return {
        query: gql`
          mutation cancelAutoPayLiabilityStatus(
            $payerLegalEntity: AiloRN!
            $payerId: AiloRN
          ) {
            cancelAutoPayLiabilityStatus(
              cancelAutoPayLiabilityStatusInput: {
                payerLegalEntity: $payerLegalEntity
                payerId: $payerId
              }
            ) {
              enabled
              maximumPaymentAmount {
                cents
              }
              paymentMethodId
            }
          }
        `,
        variables: {
          payerLegalEntity: params.payerLegalEntityId,
          payerId: params.payerId,
        },
        parseResponse: (response: {
          data: { cancelAutoPayLiabilityStatus: any };
        }) => ({
          data: response.data.cancelAutoPayLiabilityStatus,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
