import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { MutationSwitch } from "../../../../../../common";

export const NotificationSwitch = ({
  label,
  userId,
  communicationType,
  channel,
  enabled,
  description,
}: any) => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(enabled);
  useEffect((): void => {
    setNotificationsEnabled(enabled);
  }, [enabled]);

  const newStatus = !notificationsEnabled;
  const displayDescription = `${description} (${channel})`;
  return (
    <Box pt={1} pl={2} fontSize="0.75rem">
      {label}:
      <MutationSwitch
        disabled={false}
        shouldRefresh={false}
        checked={notificationsEnabled}
        mutationParams={{
          type: "set_preference",
          resource: "NotificationPreferences",
          payload: {
            userId,
            communicationType,
            channel,
            enabled: newStatus,
          },
        }}
        confirmation={{
          title: "Update notification preference",
          content: `Are you sure you want to switch ${displayDescription} ${
            newStatus ? "on" : "off"
          } for this user?`,
        }}
        successNotification={`Notification preference ${displayDescription} ${
          newStatus ? "enabled" : "disabled"
        }`}
        failureNotification="Failed to set user preference"
        onSuccess={({ data }) => {
          setNotificationsEnabled(data[0].enabled);
        }}
      />
    </Box>
  );
};
