// eslint-disable-next-line no-restricted-imports
import { Edit as ReactAdminEdit, useTranslate } from "react-admin";
import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import inflection from "inflection";

export function Edit(props: any) {
  const translate = useTranslate();
  const resourceSingularName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  });
  return (
    <ReactAdminEdit
      successMessage={`${resourceSingularName} updated`}
      mutationMode="pessimistic"
      actions={null}
      {...props}
    />
  );
}
