import Grid from "@material-ui/core/Grid";
import React from "react";
import { Person } from "../../api/ResourceName";
import { LegalEntityBasicDetailsFragment } from "../../api/resources/Property/propertiesClient.generated";
import { LegalEntityDetailCard } from "../LegalEntity";

export interface Ownership {
  owner?: LegalEntityBasicDetailsFragment | null;
  isPrimary: boolean;
}

export const OwnershipsList = ({
  ownerships,
}: {
  ownerships: Array<Ownership | null>;
}): React.ReactElement | null => {
  return (
    <Grid container spacing={0}>
      {ownerships.map((ownership) => {
        if (!ownership) return null;

        const { owner } = ownership;
        return (
          <Grid item xs={6} key={owner?.id}>
            <LegalEntityDetailCard legalEntity={owner} resource={Person} />
            {ownership.isPrimary ? "Primary Owner" : "Secondary Owner"}
          </Grid>
        );
      })}
    </Grid>
  );
};
