import { AiloRN } from "@ailo/ailorn";
import moment from "moment-timezone";
import { StatementProgressFragmentFragment } from "./statementProgressClient.generated";

export function parseStatementProgress(
  statementProgress: StatementProgressFragmentFragment
) {
  return {
    id: statementProgress.id,
    statementId: statementProgress.statement?.id ?? undefined,
    fileId: statementProgress.statement?.file?.id
      ? AiloRN.fromString(statementProgress.statement?.file?.id).internalId
      : undefined,
    fileUrl: statementProgress.statement?.file?.url ?? undefined,
    billsZipFileId: statementProgress.statement?.billsZipFile?.id
      ? AiloRN.fromString(statementProgress.statement?.billsZipFile?.id)
          .internalId
      : undefined,
    billsZipFileUrl:
      statementProgress.statement?.billsZipFile?.url ?? undefined,
    startDate: statementProgress.rangeStartDate?.slice(0, 10) ?? "",
    endDate: statementProgress.rangeEndDate?.slice(0, 10) ?? "",
    isSuccessful: statementProgress.isSuccessful ?? undefined,
    errorMessage: statementProgress.note ?? undefined,
    createdAt: moment(statementProgress.createdAt),
    dryRunEmail: statementProgress.dryRunEmail ?? undefined,
    sendEmail: statementProgress.sendEmail ?? undefined,
    type: statementProgress.type ?? undefined,
    subject: statementProgress.subject,
  };
}
