import moment from "moment-timezone";
import React from "react";
import type { Liability } from "../../../api/deprecatedTypes";

/**
 * @deprecated Shouldnt be needed anymore
 */
const TimeSubstractToDateField = ({ record }: { record: Liability }) => {
  const timeZone = "Australia/Sydney";
  if ((record as any)?.paidTo) {
    const paidToDate = moment
      .tz((record as any).paidTo, timeZone)
      .subtract(1, "millisecond")
      .format("YYYY-MM-DD (DD MMM YYYY)");
    return <span>{paidToDate}</span>;
  }
  return <span>N/A</span>;
};
export { TimeSubstractToDateField };
