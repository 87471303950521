import { DateInput, Filter } from "react-admin";
import React from "react";

const DateFilter = (props: any) => {
  const label = props.label || "Date";
  const sourceAfter = props.afterSource || "afterDate";
  const sourceBefore = props.beforeDate || "beforeDate";
  return (
    <Filter {...props}>
      <DateInput label={`${label} After`} source={sourceAfter} alwaysOn />
      <DateInput label={`${label} Before`} source={sourceBefore} alwaysOn />
    </Filter>
  );
};

export { DateFilter };
