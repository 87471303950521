import gql from "graphql-tag";
import { CREATE, DELETE } from "react-admin";
import { AiloRN, services } from "@ailo/ailorn";
import type {
  BankAccountBlackListItem,
  RaDataGraphqlClientArgs,
} from "../../deprecatedTypes";

function bankAccountBlacklistsClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CREATE:
      return {
        query: gql`
          mutation createBankAccountBlacklist(
            $ownerLegalEntity: AiloRN!
            $bsb: String!
            $accountNumber: String!
            $description: String!
          ) {
            createBankAccountBlacklist(
              blackListItem: {
                ownerLegalEntity: $ownerLegalEntity
                bsb: $bsb
                accountNumber: $accountNumber
                description: $description
              }
            ) {
              id
              ownerLegalEntity
              bsb
              accountNumber
              description
            }
          }
        `,
        variables: {
          ...params.data,
          ownerLegalEntity: AiloRN.of(
            services.AuthZ.legalEntity,
            params.data.ownerLegalEntity
          ),
        },
        parseResponse: (response: {
          data: {
            createBankAccountBlacklist: BankAccountBlackListItem;
          };
        }) => ({
          data: response.data.createBankAccountBlacklist,
        }),
      };

    case DELETE:
      return {
        query: gql`
          mutation deleteBankAccountBlacklist($id: ID!) {
            deleteBankAccountBlacklist(id: $id) {
              id
              ownerLegalEntity
              bsb
              accountNumber
              description
            }
          }
        `,
        variables: {
          id: params.id,
        },
        parseResponse: (response: {
          data: {
            deleteBankAccountBlacklist: BankAccountBlackListItem;
          };
        }) => ({
          data: response.data.deleteBankAccountBlacklist,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { bankAccountBlacklistsClient };
