import React from "react";
import { DetailCard } from "../../../../../common/ui/cards";
import { UserNotificationPreferences } from "./components";

export const NotificationPreferencesCard = ({ userId }: any) => {
  if (!userId) {
    return (
      <DetailCard
        title="Notification preferences"
        contents="N/A. (This person has no user account.)"
      />
    );
  }

  return (
    <DetailCard
      title="Notification preferences"
      contents={!!userId && <UserNotificationPreferences userId={userId} />}
    />
  );
};
