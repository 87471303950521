import React from "react";
import { Liability } from "../../../../api/ResourceName";
import { useInfinitelyPaginatedQuery } from "../../../../common";
import { TenancyLiabilityEntryListContent } from "./TenancyLiabilityEntryListContent";

const TenancyLiabilityEntryList = ({
  liabilityId,
}: {
  liabilityId: string;
}) => {
  const {
    data,
    error,
    loaded,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    resource: Liability,
    type: "get_entries",
    payload: {
      id: liabilityId,
    },
    pageSize: 100,
  });

  if (!loaded) {
    return <TenancyLiabilityEntryListContent.Loading />;
  }

  if (error) {
    return <TenancyLiabilityEntryListContent.Error />;
  }

  if (!data || data.length === 0) {
    return <TenancyLiabilityEntryListContent.Empty />;
  }

  return (
    <TenancyLiabilityEntryListContent
      liabilityEntries={data}
      hasMore={hasMore}
      loadMore={loadMore}
    />
  );
};

export { TenancyLiabilityEntryList };
