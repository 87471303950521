import { RecurringDate } from "@ailo/date";
import { Money } from "@ailo/money";
import React from "react";
import { AutocompleteInput, ReferenceInputProps } from "react-admin";
import { OperationData } from "../../api";
import { FeeBlueprintType } from "../../api/graphql/types.generated";
import { FeeBlueprint } from "../../api/ResourceName";
import { ReferenceInput } from "./ReferenceInput";

type FeeBlueprintInputProps = Omit<ReferenceInputProps, "source" | "reference">;

export function FeeBlueprintInput({
  source,
  disabled,
  ...props
}: FeeBlueprintInputProps): React.ReactElement {
  return (
    <ReferenceInput source={source} reference={FeeBlueprint} {...props}>
      <AutocompleteInput
        source="name"
        optionText={(
          blueprint: OperationData<"FeeBlueprint", "GET_MANY">[number]
        ) => {
          const amountDescription = blueprint.fixedAmount
            ? Money.from(blueprint.fixedAmount).format()
            : `${blueprint.oneWeekRentPercentage} weeks of rent`;
          const frequencyDescription =
            blueprint.type === FeeBlueprintType.RecurringFee
              ? ` ${new RecurringDate(blueprint as any).format()}` // eslint-disable-line @typescript-eslint/no-explicit-any
              : "";
          return `${blueprint.name} (${amountDescription}${frequencyDescription})`;
        }}
        options={{
          disabled,
        }}
      />
    </ReferenceInput>
  );
}
FeeBlueprintInput.defaultProps = {
  fullWidth: true,
};
