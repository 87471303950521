import { Button } from "@material-ui/core";
import React from "react";
import { DeleteIcon } from "../../icons/deleteIcon";
import { Colors } from "../../colors";
/**
 * @deprecated Use <Button /> directly
 */
const RemoveButton = ({ onClick, children, iconAtStart }: any) => (
  <Button
    disableElevation
    style={{
      textTransform: "none",
      color: Colors.AILO_RED,
    }}
    endIcon={!iconAtStart && <DeleteIcon />}
    startIcon={iconAtStart && <DeleteIcon />}
    onClick={onClick}
  >
    {children || "Remove"}
  </Button>
);

export { RemoveButton };
