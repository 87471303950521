import makeStyles from "@material-ui/core/styles/makeStyles";
import { maxLength } from "react-admin";

export const walletStyles = makeStyles(() => ({
  transferDetails: {
    "& svg": {
      paddingTop: "10px",
    },
  },
  directWalletTransfer: {
    "& svg": {
      paddingTop: "30px",
    },
  },
}));

const MAX_USER_FACING_DESC_CHARS = 25;
export function descriptionHelperText(value?: string): string {
  return `This is visible by consumers (${
    value?.length || 0
  }/${MAX_USER_FACING_DESC_CHARS})`;
}

// eslint-disable-next-line
export function maxUserFacingDescLength() {
  return maxLength(MAX_USER_FACING_DESC_CHARS);
}
