import React from "react";
import {
  BooleanField,
  Button,
  DateField,
  FunctionField,
  Link,
  Show,
  ShowActionsProps,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
import { OperationData } from "../../api";
import {
  AutoPayment,
  Liability,
  PaymentMethod,
  RecurringOwing,
} from "../../api/ResourceName";
import {
  ActionInitiatorField,
  AmountField,
  CopyableTextField,
  LinkField,
  MoneyField,
  MutationButton,
} from "../../common";
import { useHasAuthScope } from "../../common/auth/useHasAuthScope";
import { LiabilityEntriesGrid } from "../../features/Ledger/LiabilityEntriesGrid";
import { PayLiabilityFromWalletButton } from "./PayLiabilityFromWalletButton";

type LiabilityRecord = OperationData<typeof Liability, "GET_ONE">;

function Actions({
  data: record,
}: ShowActionsProps & {
  data?: LiabilityRecord;
}) {
  const canWritePayments = useHasAuthScope("admin:payments:write");

  if (!canWritePayments || !record) {
    return null;
  }

  return (
    <TopToolbar>
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: "/LiabilityAdjustments/create",
          state: {
            record: {
              liabilityId: record.id,
              effectiveAt: new Date(),
            },
          },
        }}
        label="Create Adjustment"
      />
      <PayLiabilityFromWalletButton
        color="primary"
        label="Pay from Wallet"
        liability={record}
      />
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: "/LiabilityStatus/create",
          state: {
            record: {
              liabilityId: record.id,
              archivedAt: record?.status?.archivedAt,
            },
          },
        }}
        label="Archive/Unarchive"
      />
    </TopToolbar>
  );
}

function LiabilityShow(props: ShowProps): React.ReactElement {
  const canWritePayments = useHasAuthScope("admin:payments:write");
  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <CopyableTextField label="ID" source="id" />
        <LinkField
          label="Owing"
          source="owing.id"
          sourceResource={RecurringOwing}
          includeApiTypeInLinkText
        />
        <TextField label="Description" source="description" />
        <LinkField label="Reference" source="reference" />
        <TextField label="Category" source="category" />
        <DateField label="Paid To Date" source="paidToDate" />
        <DateField
          label="Effective Paid To Date"
          source="effectivePaidToDate"
        />
        <MoneyField
          source="overdueAmount"
          label="Overdue amount (sum of past entries amounts, excluding failed/cleaned payment entries)"
        />
        <DateField label="Next Due date" source="nextDueDate" showTime />
        <FunctionField<LiabilityRecord>
          label="Next Due Amount ($)"
          render={(record) => {
            return <AmountField record={record!.nextDueAmount} />;
          }}
        />
        <FunctionField<LiabilityRecord>
          label="Next Charge Amount ($)"
          render={(record) => {
            return <AmountField record={record!.nextChargeAmount} />;
          }}
        />
        <DateField label="Next Charge date" source="nextChargeDate" showTime />

        <BooleanField
          label="Payer Kyc Check Required"
          source="status.payerKycCheckRequired"
          emptyText="unknown"
        />

        <ActionInitiatorField
          label="Archived"
          source="status.isArchived"
          initiatedAtSource="status.archivedAt"
          initiatedBySource="status.createdBy"
        />

        <FunctionField<LiabilityRecord>
          label="Auto Pay When Due"
          render={(record) => {
            if (!record!.autoPaymentDetails) {
              return "Disabled";
            }

            return (
              <>
                Payment Method:{" "}
                <LinkField
                  record={record}
                  source="autoPaymentDetails.paymentMethod.id"
                  sourceResource={PaymentMethod}
                />
                {canWritePayments && (
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    <MutationButton
                      label="Cancel Auto Pay"
                      variant="outlined"
                      color="secondary"
                      resource={AutoPayment}
                      record={record!}
                      type="cancel_auto_payments"
                      payload={{
                        liabilityId: record!.id,
                      }}
                      confirmationAlert="Are you sure you want to cancel auto pay for this liability?"
                      successMessage="Auto pay cancelled."
                    />
                  </div>
                )}
              </>
            );
          }}
        />

        <FunctionField<LiabilityRecord>
          label="Entries"
          render={(record) => {
            return <LiabilityEntriesGrid liabilityId={record!.id} />;
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
}

export { LiabilityShow };
