import gql from "graphql-tag";
import { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { RaDataGraphqlClientResult } from "../../common";

const CREATE_OVERDRAFT_ALLOWANCE = "create_overdraft_allowance";

function walletOverdraftAllowanceClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs): RaDataGraphqlClientResult {
  switch (raFetchType) {
    case CREATE_OVERDRAFT_ALLOWANCE:
      return {
        query: gql`
          mutation createOverdraftAllowance(
            $walletId: ID!
            $amountInCents: Long!
            $startAt: DateTime!
            $endAt: DateTime!
          ) {
            createOverdraftAllowance(
              input: {
                walletId: $walletId
                amount: { cents: $amountInCents }
                startAt: $startAt
                endAt: $endAt
              }
            ) {
              id
              walletId
              amount {
                cents
              }
              startAt
              endAt
              createdAt
              createdBy
              type
            }
          }
        `,
        variables: {
          walletId: params.walletId,
          amountInCents: params.amountInCents,
          startAt: params.startAt,
          endAt: params.endAt,
        },
        parseResponse: (response: {
          data: { createOverdraftAllowance: { [key: string]: string } };
        }) => ({
          data: response.data.createOverdraftAllowance,
        }),
      };
    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { walletOverdraftAllowanceClient };
