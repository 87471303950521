import gql from "graphql-tag";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { PaginationParams } from "../../graphql/types.generated";
import {
  ListChargeCyclesQuery,
  ListChargeCyclesQueryVariables,
  ListOwingEventsQuery,
  ListOwingEventsQueryVariables,
  RecurringOwingQuery,
  RecurringOwingQueryVariables,
} from "./recurringOwingClient.generated";

const PaidToDateFragment = gql`
  fragment PaidToDateFragment on PaidToDate {
    date
    partPayment {
      cents
    }
  }
`;

const PaidToFragment = gql`
  fragment PaidToFragment on PaidTo {
    effective {
      ...PaidToDateFragment
    }
    classic {
      ...PaidToDateFragment
    }
  }
  ${PaidToDateFragment}
`;

const ChargeFragment = gql`
  fragment ChargeFragment on Charge {
    date
    amount {
      total {
        cents
      }
      tax {
        cents
      }
    }
  }
`;

const CumulativeChargeFragment = gql`
  fragment CumulativeChargeFragment on CumulativeCharge {
    date
    amount {
      total {
        cents
      }
      tax {
        cents
      }
    }
  }
`;

const ArrearsFragment = gql`
  fragment ArrearsFragment on Arrears {
    date: calculatedFromDate
    amount: amountInArrears {
      total {
        cents
      }
      tax {
        cents
      }
    }
  }
`;

export const RecurringOwingClientDefinition = {
  GET_ONE: (params: { id: string }) => ({
    query: gql`
      query recurringOwing($id: ID!) {
        recurringOwing(id: $id) {
          id
          liability {
            id
          }
          reference
          owingType
          ratePeriods {
            startDate
            endDate
            frequency
            amount {
              cents
            }
            taxAmount {
              cents
            }
          }
          chargeSchedules {
            firstChargeDate
          }
          progress {
            paidTo {
              ...PaidToFragment
            }
            due {
              ...ChargeFragment
            }
            nextDue {
              ...ChargeFragment
            }
            nextCharge {
              ...ChargeFragment
            }
            arrears {
              ...ArrearsFragment
            }
            overdue {
              ...CumulativeChargeFragment
            }
            endOfOwing {
              ...CumulativeChargeFragment
            }
            firstPaidAt
            lastPaidAt
          }
        }
      }
      ${PaidToFragment}
      ${ChargeFragment}
      ${CumulativeChargeFragment}
      ${ArrearsFragment}
    `,
    variables: <RecurringOwingQueryVariables>{
      id: params.id,
    },
    parseResponse: (response: GraphQLResponse<RecurringOwingQuery>) => ({
      data: response.data.recurringOwing!,
    }),
  }),
  LIST_CHARGE_CYCLES: (params: {
    cursor?: PaginationParams;
    recurringOwingId: string;
  }) => ({
    query: gql`
      query listChargeCycles($pagination: PaginationParams!, $id: ID!) {
        recurringOwing(id: $id) {
          id
          chargeCycles(pagination: $pagination) {
            pageInfo {
              total
              hasNext
              nextCursor
            }
            items {
              id
              startDate
              endDate
              amount {
                cents
              }
              taxAmount {
                cents
              }
            }
          }
        }
      }
    `,
    variables: <ListChargeCyclesQueryVariables>{
      pagination: params.cursor,
      id: params.recurringOwingId,
    },
    parseResponse: (response: GraphQLResponse<ListChargeCyclesQuery>) => ({
      data: response.data.recurringOwing!.chargeCycles!,
    }),
  }),
  LIST_OWING_EVENTS: (params: {
    cursor?: PaginationParams;
    recurringOwingId: string;
  }) => ({
    query: gql`
      query listOwingEvents($pagination: PaginationParams!, $id: ID!) {
        recurringOwing(id: $id) {
          id
          recurringOwingEvents(pagination: $pagination) {
            pageInfo {
              total
              hasNext
              nextCursor
            }
            items {
              id
              causeReference
              eventType
              internalDescription
              description
              changeInOwedAmount {
                cents
              }
              changeInOwedTaxAmount {
                cents
              }
              businessTransaction {
                ailoRN
              }
              reversalOf
              effectiveAt
              createdAt
              modifiedAt
              paidTo {
                ...PaidToFragment
              }
            }
          }
        }
      }
      ${PaidToFragment}
    `,
    variables: <ListOwingEventsQueryVariables>{
      pagination: params.cursor,
      id: params.recurringOwingId,
    },
    parseResponse: (response: GraphQLResponse<ListOwingEventsQuery>) => ({
      data: response.data.recurringOwing!.recurringOwingEvents!,
    }),
  }),
};

export const recurringOwingClient = createClientFromDefinition(
  RecurringOwingClientDefinition
);
