import React, { ReactElement } from "react";
import { useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { VirtualAccountCardItem, VirtualAccountData } from "../../../common";
import { Wallet } from "../../../api";

interface Params {
  data: VirtualAccountData;
  agency: AiloRN;
  index: number;
  includeIndexInTitle: boolean;
}

function TenancyBondWalletCardItem({
  data,
  agency,
  index,
  includeIndexInTitle,
}: Params): ReactElement | null {
  const walletOwnerRef =
    data.paymentMethodCompanion?.paymentMethod.wallet.owner.reference;

  const {
    data: wallet,
  }: {
    data?: {
      id: string;
      availableBalance: { cents: number };
      outstandingBalance: { cents: number };
      idempotencyKey: string;
    };
  } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: walletOwnerRef,
    },
  });

  if (!data || !walletOwnerRef || !wallet) {
    return null;
  }
  const title = `Tenancy Bond Wallet ${includeIndexInTitle ? index + 1 : ""}`;

  return (
    <VirtualAccountCardItem
      agency={agency}
      walletOwnerRef={walletOwnerRef}
      wallet={wallet}
      data={data}
      title={title}
      transferToTrustDescriptionGuide="Use bond reference as the description"
    />
  );
}

export { TenancyBondWalletCardItem };
