import gql from "graphql-tag";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { LedgerPageCursor } from "../../graphql/types.generated";
import {
  GetWalletEntriesByOwnerIdQuery,
  GetWalletEntriesByOwnerIdQueryVariables,
} from "./walletEntryClient.generated";

export const WalletEntryClientDefinition = {
  GET_LIST_BY_OWNER_ARN: (params: {
    filter: { ownerAiloRN: string; relatingToManagementAilorn?: string };
    cursor?: LedgerPageCursor;
  }) => ({
    query: gql`
      query getWalletEntriesByOwnerId(
        $ownerAiloRN: AiloRN!
        $relatingToManagementAilorn: AiloRN
        $cursor: LedgerPageCursor
      ) {
        entries: walletEntriesWithStatusByOwnerRef(
          ownerRef: $ownerAiloRN
          relatingToManagementAilorn: $relatingToManagementAilorn
          aggregated: true
          cursor: $cursor
        ) {
          pageInfo {
            total
            hasMore
            nextCursor
          }
          items {
            id
            amount {
              cents
            }
            status
            isReversal
            isTopUpFee
            createdAt
            liability {
              id
              category
              taxCategory
              recurringFee {
                id
                name
              }
              management {
                id
                property {
                  id
                  address {
                    unitStreetNumber
                    streetName
                    suburb
                    state
                    country
                    postcode
                  }
                }
              }
            }
            paymentMethod {
              __typename
              id
              ... on BankAccount {
                accountName
                accountNumber
              }
              ... on CreditCard {
                expiry
                number
                type
              }
            }
            businessTransaction
          }
        }
      }
    `,
    variables: <GetWalletEntriesByOwnerIdQueryVariables>{
      ownerAiloRN: params.filter.ownerAiloRN,
      relatingToManagementAilorn: params.filter.relatingToManagementAilorn,
      cursor: params.cursor,
    },
    parseResponse: (
      response: GraphQLResponse<GetWalletEntriesByOwnerIdQuery>
    ) => {
      return { data: response.data.entries! };
    },
  }),
};

export const walletEntryClient = createClientFromDefinition(
  WalletEntryClientDefinition
);
