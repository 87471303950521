import { AiloRN } from "@ailo/ailorn";
import React from "react";
import { LinkField } from "../../common/fields";

export function linkifyLedgerDescription(
  description: string | null | undefined
): React.ReactNode | null {
  if (!description) {
    return null;
  }

  const children = (
    <>
      {description
        .split(
          /([[\]]|Wallet owner \[?[\d\p{L}-]+\]?|wallet \[?[\d\p{L}-]+\]?|tenancy: \[?[\d\p{L}-]+\]?|ailo(?::[\d\p{L}-]+?){2}:[\d\p{L}-]+)/u
        )
        .map((match, index) => {
          if (AiloRN.isAiloRNString(match)) {
            return (
              <LinkField
                key={index}
                record={{
                  ailoRN: match,
                }}
                source="ailoRN"
                copyable={false}
              />
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          const m = match as string;

          if (m.match(/^Wallet owner \[?[\d\p{L}-]+\]?$/u)) {
            const id = m.replace("Wallet owner [", "").replace("]", "");
            return (
              <LinkField
                key={index}
                record={{
                  __typename: "LegalEntity",
                  id,
                }}
                copyable={false}
              />
            );
          }

          if (m.match(/^wallet \[?[\d\p{L}-]+\]?$/u)) {
            const id = m.replace("wallet [", "").replace("]", "");
            return (
              <LinkField
                key={index}
                record={{
                  __typename: "Wallet",
                  id,
                }}
                copyable={false}
              />
            );
          }

          if (m.match(/^tenancy: \[?[\d\p{L}-]+\]?$/u)) {
            const id = m.replace("tenancy: ", "").replace("]", "");
            return (
              <LinkField
                key={index}
                record={{
                  __typename: "Tenancy",
                  id,
                }}
                copyable={false}
              />
            );
          }

          return m;
        })}
    </>
  );
  return children;
}
