import { AiloRN } from "@ailo/ailorn";
import React from "react";
import { Datagrid, TextField, Tab, ArrayField } from "react-admin";
import {
  CreateTeamButton,
  DeleteTeamButton,
  EditTeamButton,
} from "../../features/Team";
import { MoveManagementsButton } from "../../features/Team/MoveManagementsButton";

function renderTeamsTab({
  organisationAilorn,
}: {
  organisationAilorn: AiloRN;
}) {
  return (
    <Tab label="Teams">
      <ArrayField source="teams" label="">
        <Datagrid>
          <TextField source="name" label="Team" />
          <TextField
            source="managements.pageInfo.total"
            label="All Managements"
          />
          <TextField
            source="activeManagements.pageInfo.total"
            label="Active Managements"
          />
          <EditTeamButton />
          <DeleteTeamButton />
        </Datagrid>
      </ArrayField>
      <MoveManagementsButton />
      <CreateTeamButton
        organisationAilorn={organisationAilorn}
        style={{ marginTop: 12, marginBottom: 12 }}
      />
    </Tab>
  );
}

export { renderTeamsTab };
