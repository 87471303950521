import { capitalize } from "lodash";
import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FunctionField,
  TextField,
  TextInput,
  SelectInput,
} from "react-admin";
import {
  ArchiveStatusInput,
  CopyableTextField,
  List,
  OrganisationField,
  ReferenceInput,
  StatusField,
} from "../../common";

const SearchFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Supplier Name" source="searchTerm" alwaysOn autoFocus />
    <ReferenceInput
      label="Organisation Name"
      alwaysOn
      source="organisationId"
      reference="Customers"
      filterToQuery={(searchTerm: string) => ({
        searchTerm,
      })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput label="ABN" source="abnLike" alwaysOn />
    <ArchiveStatusInput alwaysOn />
    <SelectInput
      label="KYC Data"
      source="kycDataComplete"
      alwaysOn
      choices={["complete", "incomplete"].map((status) => ({
        id: status,
        name: capitalize(status),
      }))}
      emptyText="All"
      {...props}
    />
  </Filter>
);

const SuppliersList = (props: Record<string, any>) => {
  return (
    <List
      {...props}
      title="Suppliers"
      sort={{
        field: "createdAt",
        order: "DESC",
      }}
      bulkActionButtons={false}
      filters={<SearchFilter />}
      filterDefaultValues={{ status: "active" }}
    >
      <Datagrid rowClick="show">
        <TextField label="Name" source="name" sortable={false} />
        <OrganisationField
          label="Belongs to"
          source="organisation"
          sortable={false}
        />
        <TextField label="ABN" source="abn" sortable={false} />
        <CopyableTextField label="ID" source="id" sortable={false} />
        <FunctionField
          label="Status"
          render={(record: any) => (
            <StatusField
              record={{
                status: record.archivedAt ? "archived" : "active",
              }}
              source="status"
            />
          )}
        />
        <FunctionField
          label="KYC Data"
          render={(record: any) => (
            <StatusField
              record={{
                kycDataComplete: record.kycDataComplete
                  ? "complete"
                  : "incomplete",
              }}
              source="kycDataComplete"
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export { SuppliersList };
