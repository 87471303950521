import { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { FileNameDeDuplicator } from "./FileNameDeDuplicator";

export type FileAttachment = { fileName: string; url: string };

type ZipProps = {
  files: FileAttachment[];
  folderName: string;
  fileName: string;
};

export function useZipAttachments(): {
  zip: (props: ZipProps) => Promise<void>;
  zipping: boolean;
} {
  const [zipping, setZipping] = useState(false);

  function zip(props: ZipProps) {
    setZipping(true);
    return zipAttachments(props).finally(() => setZipping(false));
  }

  return { zip, zipping };
}

function zipAttachments({ files, folderName, fileName }: ZipProps) {
  const zip = new JSZip();
  const deDuplicator = new FileNameDeDuplicator();

  zip.folder(folderName);

  files?.forEach((f) =>
    zip.file(
      `${folderName}/${deDuplicator.next(f.fileName)}`,
      resolveFileBlob(f)
    )
  );

  return zip.generateAsync({ type: "blob" }).then((content) => {
    return saveAs(content, `${fileName}.zip`);
  });
}

function resolveFileBlob({ fileName, url }: FileAttachment) {
  return fetch(url)
    .then((response) => {
      if (response.status === 200 || response.status === 0) {
        return response.blob();
      }
      throw new Error(`Downloading ${fileName} failed: ${response.statusText}`);
    })
    .catch((error) => {
      throw new Error(`Downloading ${fileName} failed: ${error}`);
    });
}
