import { FunctionField } from "react-admin";
import { capitalize } from "@material-ui/core";
import React from "react";
import { Badge } from "../ui/Badge";

export function CurrentnessField({ source, ...props }: any) {
  return (
    <FunctionField
      {...props}
      render={(record: any) => {
        const value = record[source];

        if (!value) {
          return null;
        }

        return (
          <Badge
            type={
              value === "past"
                ? "default"
                : value === "current"
                ? "success"
                : "warning"
            }
          >
            {capitalize(value)}
          </Badge>
        );
      }}
    />
  );
}
CurrentnessField.defaultProps = {
  addLabel: true,
};
