import React from "react";
import { ExternalTrustAccountsCard } from "./ExternalTrustAccountsCard";
import { MigrationTaskCard } from "./MigrationTaskCard";

export const MigrationSection = ({
  legalEntityId,
  organisationId,
  organisationName,
  migrationTask,
  externalTrustAccounts,
}: any) => {
  const hasActiveExternalTrustAccount = !!externalTrustAccounts.find(
    ({ disabled }: any) => !disabled
  );
  return (
    <>
      <MigrationTaskCard
        legalEntityId={legalEntityId}
        migrationTask={migrationTask}
        hasActiveExternalTrustAccount={hasActiveExternalTrustAccount}
      />
      <ExternalTrustAccountsCard
        externalTrustAccounts={externalTrustAccounts}
        legalEntityId={legalEntityId}
        organisationId={organisationId}
        organisationName={organisationName}
      />
    </>
  );
};
