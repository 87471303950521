import { AiloRN, services } from "@ailo/ailorn";
import { Money } from "@ailo/money";
import gql from "graphql-tag";
import { GET_LIST, GET_ONE, UPDATE } from "react-admin";
import { isPresent } from "ts-is-present";
import { capitaliseFirstLetter } from "../../../common/ui/deprecated";
import {
  buildPaginatedResponseFromPaginatedList,
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import {
  ApproveMigratingManagementsMutation,
  ApproveMigratingManagementsMutationVariables,
  GetAiloManagementIdsFromMigratingManagementsQuery,
  GetAiloManagementIdsFromMigratingManagementsQueryVariables,
  MigrateManagementsMutation,
  MigrateManagementsMutationVariables,
  MigratingManagementQuery,
  MigratingManagementQueryVariables,
  MigratingManagementsQuery,
  MigratingManagementsQueryVariables,
  PublishMigratingManagementsMutation,
  PublishMigratingManagementsMutationVariables,
  RemoveTenancyFromMigratingManagementMutation,
  RemoveTenancyFromMigratingManagementMutationVariables,
  UnapproveMigratingManagementsMutation,
  UnapproveMigratingManagementsMutationVariables,
  UpdateMigratingManagementMutation,
  UpdateMigratingManagementMutationVariables,
  UploadMigrationCsvMutation,
  UploadMigrationCsvMutationVariables,
} from "./migratingManagementsClient.generated";
import {
  APPROVE,
  GET_MANY_AILO_MANAGEMENT_IDS,
  MIGRATE,
  PUBLISH,
  REMOVE_TENANCY,
  UNAPPROVE,
  UPLOAD_MIGRATION_CSV,
} from "./migratingManagementsOperations";

export const MigratingManagementClientDefinition = {
  [GET_LIST]: (params: {
    pagination: PaginationParams;
    sort?: { field?: string; order?: "ASC" | "DESC" };
    filter: {
      searchTerm?: string;
      status?: string;
      batchRef?: string;
      portfolioName?: string;
      organisationId?: string;
      withErrors?: boolean;
      withPostApprovalTasks?: boolean;
      withFailedMigration?: boolean;
    };
  }) => ({
    query: gql`
      query migratingManagements(
        $cursor: String
        $pageSize: Int
        $sort: MigratingManagementSortParams!
        $status: String
        $organisationId: AiloRN
        $searchTerm: String
        $batchRef: String
        $portfolioName: String
        $withErrors: Boolean
        $withPostApprovalTasks: Boolean
        $withFailedMigration: Boolean
      ) {
        migratingManagements(
          pagination: { pageSize: $pageSize, cursor: $cursor }
          filters: {
            organisationId: $organisationId
            status: $status
            search: $searchTerm
            batchRef: $batchRef
            portfolioName: $portfolioName
            withErrors: $withErrors
            withPostApprovalTasks: $withPostApprovalTasks
            withFailedMigration: $withFailedMigration
          }
          sort: $sort
        ) {
          pageInfo {
            hasNext
            nextCursor
            total
          }
          items {
            id
            createdAt
            organisation {
              id
              name
            }
            trustManagementId
            body
            paidTo
            partialPaidCents
            modifiedAt
            approvedAt
            approvedBy {
              ... on User {
                person {
                  firstName
                  lastName
                }
              }
            }
            healthcheckRunAt
            portfolioName
            ailoManagement {
              id
            }
            status
            statusChangedAt
            statusChangedBy {
              ... on User {
                person {
                  firstName
                  lastName
                }
              }
            }
            migrationFailureReason
            exclusionReason
            batchRef
            errors
            postApprovalTasks
            migrateAsVacant
          }
        }
      }
    `,
    variables: <MigratingManagementsQueryVariables>{
      ...buildPaginationVariables(params.pagination),
      sort: params.sort?.field
        ? {
            field: capitaliseFirstLetter(params.sort.field),
            direction: params.sort.order === "ASC" ? "Asc" : "Desc",
          }
        : null,
      searchTerm: params.filter.searchTerm,
      status: params.filter.status || "IMPORTED",
      batchRef: params.filter.batchRef,
      portfolioName: params.filter.portfolioName,
      organisationId: params.filter.organisationId
        ? AiloRN.of(
            services.AuthZ.organisation,
            params.filter.organisationId
          ).toString()
        : undefined,
      withErrors: params.filter.withErrors,
      withPostApprovalTasks: params.filter.withPostApprovalTasks,
      withFailedMigration: params.filter.withFailedMigration,
    },
    parseResponse: (response: GraphQLResponse<MigratingManagementsQuery>) => {
      return buildPaginatedResponseFromPaginatedList({
        ...response.data.migratingManagements!,
        items: response.data
          .migratingManagements!.items.filter(isPresent)
          .map((item) => ({
            ...item,
            partialPaidCents: item.partialPaidCents
              ? Money.fromCents(item.partialPaidCents)
              : undefined,
          })),
      });
    },
  }),

  [GET_ONE]: (params: { id: string }) => ({
    query: gql`
      query migratingManagement($id: ID!) {
        migratingManagement: migratingManagement(id: $id) {
          id
          createdAt
          organisation {
            id
            name
            availableFeatures {
              id
            }
          }
          trustManagementId
          body
          paidTo
          partialPaidCents
          modifiedAt
          approvedAt
          approvedBy {
            ... on User {
              person {
                firstName
                lastName
              }
            }
          }
          healthcheckRunAt
          portfolioName
          ailoManagement {
            id
          }
          status
          statusChangedAt
          migrationFailureReason
          batchRef
          errors
          postApprovalTasks
          migrateAsVacant
        }
      }
    `,
    variables: <MigratingManagementQueryVariables>{
      id: params.id,
    },
    parseResponse: ({
      data: { migratingManagement },
    }: GraphQLResponse<MigratingManagementQuery>) => {
      if (!migratingManagement) return { data: null };

      return {
        data: {
          ...migratingManagement,
          // Fill in default values for the Edit form
          // (until a fix/better workaround is found for https://github.com/marmelab/react-admin/issues/6198 )
          body: {
            ...migratingManagement?.body,
            management: {
              ...migratingManagement?.body.management,
              owners: migratingManagement?.body.management.owners?.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (owner: any) => ({
                  ...owner,
                  migratingCustomer: owner.migratingCustomer !== false,
                })
              ),
              tenancy: {
                ...migratingManagement?.body.management.tenancy,
                tenants: migratingManagement?.body.management.tenancy.tenants?.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (tenant: any) => ({
                    ...tenant,
                    migratingCustomer: tenant.migratingCustomer !== false,
                  })
                ),
              },
            },
          },
        },
      };
    },
  }),

  [UPLOAD_MIGRATION_CSV]: (params: {
    data: { base64: string };
    legalEntityId: string;
    batchRef?: string;
  }) => ({
    query: gql`
      mutation uploadMigrationCSV(
        $data: String!
        $legalEntityId: AiloRN!
        $batchRef: String!
      ) {
        uploadMigrationCSV(
          input: {
            legalEntityId: $legalEntityId
            data: $data
            batchRef: $batchRef
          }
        ) {
          id
        }
      }
    `,
    variables: <UploadMigrationCsvMutationVariables>{
      data: params.data.base64,
      legalEntityId: AiloRN.of(
        services.AuthZ.legalEntity,
        params.legalEntityId
      ).toString(),
      batchRef: params.batchRef,
    },
    parseResponse: ({
      data: { uploadMigrationCSV },
    }: GraphQLResponse<UploadMigrationCsvMutation>) => {
      return { data: uploadMigrationCSV ?? [] };
    },
  }),

  [UPDATE]: (params: {
    id: string;
    data: { body: Record<string, unknown> };
  }) => ({
    query: gql`
      mutation updateMigratingManagement($id: ID!, $body: String!) {
        updateMigratingManagement(input: { id: $id, body: $body }) {
          id
          createdAt
          organisation {
            id
            name
          }
          trustManagementId
          body
          paidTo
          partialPaidCents
          modifiedAt
          portfolioName
          ailoManagement {
            id
          }
          status
          statusChangedAt
          migrationFailureReason
          batchRef
          errors
          postApprovalTasks
        }
      }
    `,
    variables: <UpdateMigratingManagementMutationVariables>{
      id: params.id,
      body: JSON.stringify(params.data.body),
    },
    parseResponse: (
      response: GraphQLResponse<UpdateMigratingManagementMutation>
    ) => {
      return {
        data: response.data.updateMigratingManagement,
      };
    },
  }),

  [MIGRATE]: (params: { ids: string[] }) => ({
    query: gql`
      mutation migrateManagements($migratingManagementIds: [ID!]!) {
        migrateManagements(migratingManagementIds: $migratingManagementIds) {
          successes {
            id
          }
        }
      }
    `,
    variables: <MigrateManagementsMutationVariables>{
      migratingManagementIds: params.ids,
    },
    parseResponse: ({ data }: GraphQLResponse<MigrateManagementsMutation>) => {
      return {
        data:
          data.migrateManagements?.successes
            .filter(isPresent)
            .map(({ id }) => id) ?? [],
      };
    },
  }),

  [PUBLISH]: (params: { ids: string[] }) => ({
    query: gql`
      mutation publishMigratingManagements($migratingManagementIds: [ID!]!) {
        publishMigratingManagements(
          migratingManagementIds: $migratingManagementIds
        ) {
          successes {
            id
          }
        }
      }
    `,
    variables: <PublishMigratingManagementsMutationVariables>{
      migratingManagementIds: params.ids,
    },
    parseResponse: ({
      data,
    }: GraphQLResponse<PublishMigratingManagementsMutation>) => {
      return {
        data:
          data.publishMigratingManagements?.successes
            .filter(isPresent)
            .map(({ id }) => id) ?? [],
      };
    },
  }),

  [APPROVE]: (params: { ids: string[] }) => ({
    query: gql`
      mutation approveMigratingManagements($migratingManagementIds: [ID!]!) {
        approveMigratingManagements(
          migratingManagementIds: $migratingManagementIds
        ) {
          successes {
            id
          }
        }
      }
    `,
    variables: <ApproveMigratingManagementsMutationVariables>{
      migratingManagementIds: params.ids,
    },
    parseResponse: ({
      data,
    }: GraphQLResponse<ApproveMigratingManagementsMutation>) => {
      return {
        data:
          data.approveMigratingManagements?.successes
            .filter(isPresent)
            .map(({ id }) => id) ?? [],
      };
    },
  }),

  [UNAPPROVE]: (params: { ids: string[] }) => ({
    query: gql`
      mutation unapproveMigratingManagements($migratingManagementIds: [ID!]!) {
        unapproveMigratingManagements(
          migratingManagementIds: $migratingManagementIds
        ) {
          successes {
            id
          }
        }
      }
    `,
    variables: <UnapproveMigratingManagementsMutationVariables>{
      migratingManagementIds: params.ids,
    },
    parseResponse: ({
      data,
    }: GraphQLResponse<UnapproveMigratingManagementsMutation>) => {
      return {
        data:
          data.unapproveMigratingManagements?.successes
            .filter(isPresent)
            .map(({ id }) => id) ?? [],
      };
    },
  }),

  [GET_MANY_AILO_MANAGEMENT_IDS]: (params: { ids: string[] }) => ({
    query: gql`
      query getAiloManagementIdsFromMigratingManagements(
        $pageSize: Int
        $ids: [ID!]!
      ) {
        migratingManagements(
          pagination: { pageSize: $pageSize }
          filters: { ids: $ids }
          sort: { field: ModifiedAt, direction: Desc }
        ) {
          items {
            id
            ailoManagement {
              id
            }
          }
        }
      }
    `,
    variables: <GetAiloManagementIdsFromMigratingManagementsQueryVariables>{
      ids: params.ids,
      pageSize: params.ids.length,
    },
    parseResponse: ({
      data: { migratingManagements },
    }: GraphQLResponse<GetAiloManagementIdsFromMigratingManagementsQuery>) => {
      const managementIds =
        migratingManagements?.items
          ?.map((mm) => mm?.ailoManagement?.id)
          .filter(isPresent) ?? [];
      return { data: { managementIds } };
    },
  }),

  [REMOVE_TENANCY]: (params: { id: string }) => ({
    query: gql`
      mutation removeTenancyFromMigratingManagement($id: ID!) {
        removeTenancyFromMigratingManagement(input: { id: $id }) {
          id
        }
      }
    `,
    variables: <RemoveTenancyFromMigratingManagementMutationVariables>{
      id: params.id,
    },
    parseResponse: (
      response: GraphQLResponse<RemoveTenancyFromMigratingManagementMutation>
    ) => {
      return {
        data: response.data.removeTenancyFromMigratingManagement,
      };
    },
  }),
};

export const migratingManagementsClient = createClientFromDefinition(
  MigratingManagementClientDefinition
);
