/**
 * @returns A function that can be used to check the type of an object without typing the object itself.
 * This would be useful if you wanted to have a precise object literal type inferred from an object but you want
 * type safety when making the object.
 *
 * @example
 * Define a base type and a specific type of dog
 * ```
 * type CommonDog = { species: "dog"; cuteness: number };
 *
 * const specifyDog = specifyType<CommonDog>();
 *
 * const dog = specifyDog({ species: "dog", cuteness: 100, age: 2 });
 *
 * type SpecificDog = typeof dog; // { species: "dog"; cuteness: number; age: number }
 * ```
 *
 * @example
 * Make a client definition with type safety (see ClientDefinition and billTaxCategoryClient)
 * ```
 * const specifyDefinition = specifyType<ClientDefinition>();
 *
 * const billTaxCategoryDefinition = specifyDefinition({
 *  [GET_MANY]: getMany,
 *  [GET_LIST]: getMany,
 * });
 * ```
 */

export function specifyType<T>() {
  return <U extends T>(object: U): U => {
    return object;
  };
}
