import { LocalDate } from "@ailo/date";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FormDataConsumer } from "react-admin";
import { StatementType } from "../../../../api/graphql/types.generated";
import { isDate, minDate } from "../../../../common/validators";

export function EarlierStatementsAlert({
  minStartDate,
}: {
  minStartDate: LocalDate;
}): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }) =>
        formData.statementType === StatementType.Management &&
        isDate(formData.startDate) &&
        minDate(minStartDate)(formData.startDate) && (
          <Alert
            severity="info"
            style={{
              marginBottom: 16,
              width: 224,
            }}
          >
            {"For ownership statements from before 2nd July 2021 go to the "}
            <a
              href="https://drive.google.com/drive/folders/12nur9_xytR4lQipb-ZmLnN_fNFWVvcKz"
              target="_blank"
              rel="noreferrer"
            >
              Google Drive
            </a>
          </Alert>
        )
      }
    </FormDataConsumer>
  );
}
