/* eslint-disable @typescript-eslint/no-explicit-any */
import { specifyType } from "../../common/utils";
import { RaDataGraphqlClientResult } from "./ra-data-graphql";

type ClientOperation = (
  params: any
) => RaDataGraphqlClientResult<any, any, any>;

export type ClientDefinition = {
  [key: string]: ClientOperation;
};

export type ClientOperationType<TClientDefinition> = keyof TClientDefinition;
export type ClientOperationParams<
  TClientDefinition,
  TOperationType extends keyof TClientDefinition,
  TRequiredParams = any
> = TClientDefinition extends {
  [key in TOperationType]: (params: infer TParams) => unknown;
}
  ? TParams extends TRequiredParams
    ? TParams
    : never
  : never;
export type ClientOperationData<
  TClientDefinition,
  TOperationType extends keyof TClientDefinition,
  TRequiredData = any
> = TClientDefinition extends {
  [key in TOperationType]: (
    params: any
  ) => RaDataGraphqlClientResult<any, any, infer TData>;
}
  ? TData["data"] extends TRequiredData
    ? TData["data"]
    : never
  : never;

export const specifyDefinition = specifyType<ClientDefinition>();

export const specifyOperation = specifyType<ClientOperation>();

export function createClientFromDefinition(
  clientDefinition: ClientDefinition
): ({ raFetchType, params }: { raFetchType: string; params: any }) => any {
  return ({ raFetchType, params }) => {
    const clientFn = clientDefinition[raFetchType];
    if (!clientFn) {
      throw new TypeError(`${raFetchType} is not yet implemented`);
    }
    return (clientFn as any)(params);
  };
}
