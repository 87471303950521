import { Tab, Tabs } from "@material-ui/core";
import { parse, stringify } from "query-string";
import React, { useMemo } from "react";
import { ShowProps, TitleForRecord, useShowController } from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { CustomerTitleCard } from "../../Customer/consumers/components";
import { GeneralTab } from "./GeneralTab";
import {
  isAgency,
  isCompany,
  LegalEntityShowRecord,
} from "./LegalEntityShowRecord";
import { PortfoliosTab } from "./PortfoliosTab";
import { TenanciesTab } from "./TenanciesTab";
import { VirtualAccountsTab } from "./VirtualAccountsTab";
import { WalletTab } from "./WalletTab";

const LegalEntityShow = (props: ShowProps): React.ReactElement | null => {
  const { loading, record } = useShowController<LegalEntityShowRecord>(props);

  const history = useHistory();
  const location = useLocation();

  const isPerson = record?.__typename === "Person";
  const isAgencyCompany = isCompany(record) && isAgency(record);

  const tabs = [
    { label: "General", value: "General" },
    { label: "Investor Portfolios", value: "Portfolios" },
    { label: "Tenancies", value: "Tenancies" },
    { label: isPerson ? "Personal Wallet" : "Company Wallet", value: "Wallet" },
    ...(isAgencyCompany
      ? ([
          {
            label: "Virtual Accounts",
            value: "VirtualAccounts",
          },
        ] as const)
      : []),
  ] as const;

  const { tab: tabSearchParam } = useMemo(() => parse(location.search), [
    location.search,
  ]);
  const tabValue =
    tabs.find((tab) => tab.value === tabSearchParam)?.value ?? "General";

  if (loading || !record) return null;

  return (
    <>
      <TitleForRecord
        record={record}
        defaultTitle={`Legal Entity: ${record.id}`}
      />
      <CustomerTitleCard record={record} />

      <Tabs
        value={tabValue}
        onChange={(_event, newTabValue) => {
          history.push({
            search: stringify({
              tab: newTabValue,
            }),
          });
        }}
      >
        {tabs.map((tab, index) => (
          <Tab label={tab.label} value={tab.value} key={index} />
        ))}
      </Tabs>

      {tabValue === "General" ? (
        <GeneralTab record={record} />
      ) : tabValue === "Portfolios" ? (
        <PortfoliosTab record={record} />
      ) : tabValue === "Tenancies" ? (
        <TenanciesTab record={record} />
      ) : tabValue === "Wallet" ? (
        <WalletTab record={record} />
      ) : tabValue === "VirtualAccounts" ? (
        <VirtualAccountsTab record={record} />
      ) : null}
    </>
  );
};

export { LegalEntityShow };
