import React from "react";
import { regex, required, TextInput, maxLength } from "react-admin";

export const bankAccountFormFields = (
  <>
    <TextInput
      {...({} as any)}
      autoFocus
      autoComplete="off"
      label="Account Name"
      variant="outlined"
      name="accountName"
      fullWidth
      validate={[
        required(),
        maxLength(32, "Account name must contain at most 32 characters"),
      ]}
    />
    <TextInput
      {...({} as any)}
      autoComplete="off"
      label="BSB"
      name="bsb"
      variant="outlined"
      fullWidth
      validate={[
        required(),
        regex(/^\d{6}$/, "BSB number must contain 6 digits only"),
      ]}
    />
    <TextInput
      {...({} as any)}
      autoComplete="off"
      label="Account Number"
      name="accountNumber"
      variant="outlined"
      fullWidth
      validate={[
        required(),
        regex(
          /^\d{6,9}$/,
          "Account no. must contain 6 to 9 digits, please prefix 0 if provided account no. has 5 digits"
        ),
      ]}
    />
  </>
);
