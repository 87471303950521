import React from "react";
import { PersonLinkField } from "./PersonLinkField";
import { CompanyLinkField } from "./CompanyLinkField";

/**
 * @deprecated Use `LegalEntityField` instead.
 */
export const LegalEntityLink = ({ legalEntity, resource, children }: any) => {
  return legalEntity.__typename === "Person" ? (
    <PersonLinkField record={legalEntity} resource={resource}>
      {children}
    </PersonLinkField>
  ) : (
    <CompanyLinkField record={legalEntity} resource={resource}>
      {children}
    </CompanyLinkField>
  );
};
