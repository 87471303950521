import gql from "graphql-tag";
import { GET_ONE, UPDATE } from "react-admin";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

export function managementFeeClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query managementFeeSchedule($id: ID!) {
            fee: managementFeeSchedule(id: $id) {
              id
              ailoRN
              percent
              startDate
              managementAgreement {
                id
                ailoRN
              }
              management {
                id
                ailoRN
                managingEntity {
                  id
                  organisation {
                    id
                    name
                  }
                }
                property {
                  id
                  address {
                    unitStreetNumber
                    streetName
                    suburb
                    state
                    country
                    postcode
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: params.id,
        },

        parseResponse(response: { data: { fee: any } }) {
          return {
            data: response.data.fee,
          };
        },
      };

    case UPDATE:
      return {
        query: gql`
          mutation updateManagementFee($input: UpdateManagementFeeInput!) {
            fee: updateManagementFee(input: $input) {
              id
              ailoRN
              percent
              startDate
            }
          }
        `,
        variables: {
          input: {
            managementAgreementAiloRN: params.data.managementAgreement.ailoRN,
            percent: params.data.percent,
          },
        },

        parseResponse(response: { data: { fee: any } }) {
          return {
            data: response.data.fee,
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
