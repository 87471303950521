import { formatLegalEntityName } from "@ailo/domain-helpers";
import React from "react";
import { LinkField } from "./LinkField";

export function LegalEntityField({
  copyable,
  preferOrganisationName = false,
  ...rest
}: any): React.ReactElement | null {
  return (
    <LinkField
      renderText={(record) =>
        (preferOrganisationName && record.organisation?.name) ||
        formatLegalEntityName(record)
      }
      copyable={copyable ?? false}
      {...rest}
    />
  );
}
LegalEntityField.defaultProps = {
  addLabel: true,
};
