import { styled } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import { Colors, opacify } from "../colors";

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  backgroundColor: opacify(Colors.LIGHT_BLUE, 0.2),
  borderRadius: 8,
  padding: 8,
  color: Colors.TEXT.DARK.SECONDARY,
});

type Props = {
  children?: React.ReactNode;
};

/**
 * Info box with rounded corners and info icon on the left with some default styling.
 */
export function InfoContainer({ children }: Props): React.ReactElement {
  return (
    <StyledContainer>
      <InfoIcon />
      {children}
    </StyledContainer>
  );
}
