import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { ControlPoint } from "@material-ui/icons";
import React, { useMemo } from "react";
import { SelectInput } from "react-admin";
import { isPresent } from "ts-is-present";
import { Customer } from "../../../../../api/ResourceName";
import { OrganisationMembershipsFragment } from "../../../../../api/resources/Organisation/organisationClient.generated";
import {
  DeleteIcon,
  formatPersonName,
  MutationButton,
  PersonInput,
  PersonLinkField,
  SimpleFormDialog,
  useMutation,
} from "../../../../../common";
import { DetailCard } from "../../../../../common/ui/cards";
import { envVars } from "../../../../../envVars";

const MembershipsTable = ({ memberships }: OrganisationMembershipsFragment) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Person</TableCell>
          <TableCell>Role</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {memberships.map(
          (membership: OrganisationMembershipsFragment["memberships"][0]) => (
            <TableRow key={membership.member.id}>
              <TableCell component="th" scope="row">
                <PersonLinkField record={membership.member} />
              </TableCell>
              <TableCell>{membership.role.name}</TableCell>
              <TableCell align="right">
                <RemoveOrganisationMemberInput membership={membership} />
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};

type OrganisationMemberships = Pick<
  OrganisationMembershipsFragment,
  "memberships"
>;

type OrganisationInfo = {
  organisationId: string;
  legalEntityId: string;
};

type AddOrganisationMemberInput = {
  personId: string;
  organisation: OrganisationInfo;
  role: {
    legalEntity: string;
    organization: string;
  };
};

const AddOrganisationMemberButton = (
  organisation: OrganisationInfo
): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const [mutate] = useMutation({
    resource: Customer,
    type: "add_member",
  });

  const availableRoles = useMemo(
    () =>
      Object.entries(envVars.organizationRoleIds.agency)
        .filter(
          ([_, v]) => isPresent(v.legalEntity) && isPresent(v.organization)
        )
        .map(([name, id]) => ({ name, id })),
    []
  );

  const handleAddMember = async (
    input: Partial<AddOrganisationMemberInput>
  ) => {
    await mutate({
      payload: {
        personId: input.personId,
        organisationId: input.organisation!.organisationId,
        legalEntityId: input.organisation!.legalEntityId,
        orgRoleId: input.role!.organization,
        leRoleId: input.role!.legalEntity,
      },
    });
  };

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        style={{
          textTransform: "none",
        }}
        onClick={onClick}
      >
        <Box p={1} display="flex" alignItems="center">
          <ControlPoint
            style={{
              marginRight: "5px",
            }}
          />
          <Typography
            style={{
              fontSize: "0.875rem",
              fontWeight: 530,
            }}
          >
            Add Person
          </Typography>
        </Box>
      </Button>

      <SimpleFormDialog<AddOrganisationMemberInput>
        title="Add Person to Agency"
        submitLabel="Add Person"
        cancelLabel="Cancel"
        initialValues={{ organisation }}
        open={open}
        onClose={onClose}
        save={(data) => handleAddMember(data)}
      >
        <PersonInput source="personId" />

        <SelectInput
          source="role"
          alwaysOn
          allowEmpty={false}
          choices={availableRoles}
        />
      </SimpleFormDialog>
    </>
  );
};

export function OrganisationMembershipsCard({
  organisationInfo,
  organisationMemberships,
}: {
  organisationInfo: OrganisationInfo;
  organisationMemberships: OrganisationMemberships;
}): React.ReactElement {
  return (
    <DetailCard
      title="Organisation Memberships"
      record={organisationMemberships}
      contents={
        <MembershipsTable memberships={organisationMemberships.memberships} />
      }
      action={<AddOrganisationMemberButton {...organisationInfo} />}
    />
  );
}

function RemoveOrganisationMemberInput({
  membership,
}: {
  membership: OrganisationMembershipsFragment["memberships"][0];
}) {
  const { organisation, member, role } = membership;

  return (
    <MutationButton
      label="Remove"
      resource={Customer}
      record={member}
      variant="outlined"
      color="secondary"
      type="remove_member"
      icon={<DeleteIcon />}
      payload={{
        input: {
          organisationId: organisation.id,
          personId: member.id,
          roleId: role.id,
        },
      }}
      confirmationAlert={`Are you sure you want to remove ${formatPersonName(
        member
      )} "${role.name}" role from this organisation?`}
      successMessage={`Role "${
        role.name
      }" successfully removed from ${formatPersonName(member)}`}
    />
  );
}
