import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

const START_MIGRATION = "start_migration";
export function migrationTaskClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case START_MIGRATION:
      return {
        query: gql`
          mutation startMigration($legalEntityId: AiloRN!) {
            startMigration(legalEntityId: $legalEntityId) {
              status
            }
          }
        `,
        variables: {
          legalEntityId: params.legalEntityId,
        },
        parseResponse: (response: { data: { startMigration: any } }) => ({
          data: response.data.startMigration,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
