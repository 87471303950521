import React from "react";
import { FeesChargedListItem } from "./FeesChargedListItem";

export function FeesChargedList({
  fees,
}: {
  fees?: Array<{ id: string }> | null;
}): React.ReactElement {
  if (!fees || fees.length === 0) {
    return <>There are no fees</>;
  }
  return (
    <>
      {fees.map((fee) => (
        <FeesChargedListItem key={fee.id} feeId={fee.id} />
      ))}
    </>
  );
}
