import React from "react";
import { RemoveButton } from "../../../../common";
import { DeletePaymentMethodDialog } from "../../../../resourceViews/Customer/deletePaymentMethodDialog";

export const RemovePaymentMethod = ({
  paymentMethodId,
  isAutoPay,
  isAutoWithdraw,
}: any) => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <RemoveButton onClick={onClick} />
      <DeletePaymentMethodDialog
        paymentMethodId={paymentMethodId}
        isAutoPay={isAutoPay}
        isAutoWithdraw={isAutoWithdraw}
        open={open}
        onClose={onClose}
      />
    </>
  );
};
