import { CircularProgress, Container } from "@material-ui/core";
import React from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  TextField,
} from "react-admin";
import { OperationData, RecurringOwing } from "../../../api";
import {
  DateTimeField,
  DynamicAiloRNField,
  IdField,
  MoneyField,
} from "../../../common";

type OwingEventListType = OperationData<
  typeof RecurringOwing,
  "LIST_OWING_EVENTS"
>["items"];
type OwingEventRecord = NonNullable<OwingEventListType[number]>;

const OwingEventList = ({
  items,
  hasMore,
  loadMore,
}: {
  items?: OwingEventListType;
  hasMore?: boolean;
  loadMore?(): void;
}): React.ReactElement => {
  return (
    <>
      <ArrayField source="items" record={{ id: "", items }}>
        <Datagrid>
          <IdField />
          <DynamicAiloRNField source="causeReference" />
          <TextField source="eventType" />
          <FunctionField<OwingEventRecord>
            label="Description"
            render={(record) => {
              return (
                <>
                  <TextField record={record} source="description" />
                  <Labeled label="Internal" fullWidth>
                    <TextField record={record} source="internalDescription" />
                  </Labeled>
                </>
              );
            }}
          />

          <FunctionField<OwingEventRecord>
            label="Change In Owed Amount"
            render={(record) => {
              return (
                <>
                  <MoneyField record={record} source="changeInOwedAmount" />
                  <Labeled label="Tax amount" fullWidth>
                    <MoneyField
                      record={record}
                      source="changeInOwedTaxAmount"
                    />
                  </Labeled>
                </>
              );
            }}
          />
          <DateTimeField source="effectiveAt" />
          <FunctionField<OwingEventRecord>
            label="Timestamps"
            render={(record) => {
              return (
                <>
                  <Labeled label="Created at" fullWidth>
                    <DateTimeField record={record} source="createdAt" />
                  </Labeled>
                  <Labeled label="Modified at" fullWidth>
                    <DateTimeField record={record} source="modifiedAt" />
                  </Labeled>
                </>
              );
            }}
          />
          <FunctionField<OwingEventRecord>
            label="Effective Paid To Date"
            render={(record) => {
              return (
                <>
                  <DateField record={record} source="paidTo.effective.date" />
                  <Labeled label="Part payment" fullWidth>
                    <MoneyField
                      record={record}
                      source="paidTo.effective.partPayment"
                    />
                  </Labeled>
                </>
              );
            }}
          />
          <FunctionField<OwingEventRecord>
            label="Classic Paid To Date"
            render={(record) => {
              return (
                <>
                  <DateField record={record} source="paidTo.classic.date" />
                  <Labeled label="Part payment" fullWidth>
                    <MoneyField
                      record={record}
                      source="paidTo.classic.partPayment"
                    />
                  </Labeled>
                </>
              );
            }}
          />
          <DynamicAiloRNField
            source="businessTransaction.ailoRN"
            label="Business Transaction"
          />
          <DynamicAiloRNField source="reversalOf" />
        </Datagrid>
      </ArrayField>
      {hasMore && (
        <Button
          label="Load more"
          onClick={loadMore}
          variant="contained"
          fullWidth
        />
      )}
    </>
  );
};

OwingEventList.Loading = () => (
  <Container {...{ align: "center" }}>
    <CircularProgress />
  </Container>
);
OwingEventList.Empty = () => (
  <Container {...{ align: "center" }}>Nothing yet</Container>
);
OwingEventList.Error = () => (
  <Container {...{ align: "center" }}>
    There was an error displaying the list
  </Container>
);

export { OwingEventList };
