import { Money } from "@ailo/money";
import { capitalize } from "lodash";
import React, { ReactElement } from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
} from "react-admin";
import {
  ActionInitiatorField,
  DateTimeField,
  IdField,
  MoneyField,
  StatusField,
  useInfinitelyPaginatedQuery,
} from "../../common";
import { Liability } from "../../resourceViews";
import { formatLiabilityEntryDescription } from "./formatLiabilityEntryDescription";

export function LiabilityEntriesGrid(props: {
  liabilityId: string;
}): ReactElement {
  const { data, hasMore, loadMore } = useInfinitelyPaginatedQuery({
    resource: Liability,
    type: "get_entries",
    payload: {
      id: props.liabilityId,
    },
    pageSize: 100,
  });

  const canCalculateOverdueAmount = !hasMore;
  const entries = React.useMemo(
    () =>
      data?.map((entry, index) => {
        return {
          ...entry,
          liabilityOverdueAmount: canCalculateOverdueAmount
            ? [...data]
                .reverse()
                .slice(0, data.length - index)
                .filter((e) => e.status !== "CLEANED" && e.status !== "FAIL")
                .map((e) => Money.from(e.amount))
                .reduce((a, b) => a.add(b), Money.zero())
            : undefined,
        };
      }),
    [data, canCalculateOverdueAmount]
  );

  type Entry = NonNullable<typeof entries>[number];

  return (
    <>
      <ArrayField record={{ id: "", entries }} source="entries">
        <Datagrid currentSort={{ field: "effectiveAt", order: "DESC" }}>
          <IdField source="id" />
          <FunctionField<Entry>
            source="entryType"
            render={(entry) => capitalize(entry?.entryType)}
          />
          <FunctionField<Entry>
            label="Description"
            render={(entry) => {
              return (
                <>
                  <div>{formatLiabilityEntryDescription(entry)}</div>
                  {entry?.entryType === "PAYMENT" && (
                    <Labeled label="BT Status" fullWidth>
                      <StatusField record={entry} source="status" />
                    </Labeled>
                  )}
                  {/* Keeping this hidden for now, as currently it's always shown inside of the entry's description */}
                  {/* {entry.entryType === "PLANBASED" && (
                  <Labeled label="Cycle Date Range" fullWidth>
                    <DateRangeField
                      record={entry}
                      startDateSource="cycleStartDate"
                      endDateSource="cycleEndDate"
                    />
                  </Labeled>
                )} */}
                  <Labeled label="Created" fullWidth>
                    <ActionInitiatorField
                      record={entry}
                      initiatedAtSource="createdAt"
                      initiatedBySource="createdBy"
                    />
                  </Labeled>
                </>
              );
            }}
          />
          <MoneyField source="amount" />
          <DateTimeField source="effectiveAt" />
          <FunctionField<Entry>
            label="Liability"
            render={(entry) => {
              return (
                <>
                  {canCalculateOverdueAmount && (
                    <Labeled label="Overdue amount" fullWidth>
                      <MoneyField
                        record={entry}
                        source="liabilityOverdueAmount"
                      />
                    </Labeled>
                  )}
                  {entry?.entryType === "PLANBASED" && (
                    <Labeled label="Paid to date" fullWidth>
                      <DateField record={entry} source="paidToDate" />
                    </Labeled>
                  )}
                </>
              );
            }}
          />
        </Datagrid>
      </ArrayField>
      {hasMore && (
        <Button
          label="Load more"
          onClick={loadMore}
          variant="contained"
          fullWidth
        />
      )}
    </>
  );
}
