import React from "react";
import { DetailCard } from "../../../common/ui/cards";
import { ManagementFolioToManagement } from "./ManagementFolioToManagement";
import { ManagementFolioToManagementListItem } from "./ManagementFolioToManagementListItem";

export function ManagementFolioFormerManagementsCard({
  managementFolioToManagements,
}: {
  managementFolioToManagements: ManagementFolioToManagement[];
}): React.ReactElement {
  const contents =
    managementFolioToManagements.length > 0
      ? managementFolioToManagements.map((folioToManagement) => (
          <ManagementFolioToManagementListItem
            key={folioToManagement.id}
            managementFolioToManagement={folioToManagement}
          />
        ))
      : "No former properties";

  return <DetailCard title="Former Properties" contents={contents} />;
}
