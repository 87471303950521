import { AiloRN } from "@ailo/ailorn";
import { useMemo } from "react";
import { LocalStorage, localStorageKeys } from "../utils";

export function useCurrentUserAiloRN(): AiloRN | null | undefined {
  const ailoRNString =
    LocalStorage.getItem(localStorageKeys.currentUser) ?? undefined;
  return useMemo(() => {
    if (!ailoRNString) {
      return undefined;
    }

    return AiloRN.from(ailoRNString);
  }, [ailoRNString]);
}
