import React from "react";
import { GET_LIST } from "ra-core";
import Container from "@material-ui/core/Container";
import { SectionList, useQueryWithStore } from "../../../../common";
import { Bill } from "../../../../api/ResourceName";
import { BillListItem } from "./BillListItem";

export const BillList = ({ payerAilorn }: { payerAilorn: string }) => {
  const {
    loaded: upcomingBillsLoaded,
    error: upcomingBillsError,
    data: upcomingBills,
  } = useQueryWithStore({
    type: GET_LIST,
    resource: Bill,
    payload: {
      pagination: {
        page: 1,
        perPage: 10,
      },
      filter: {
        payerId: [payerAilorn],
        paid: false,
        status: ["APPROVED"],
        taxCategoryIdNotIn: ["MANAGEMENT_FEES"],
      },
      sort: {
        field: "DueDate",
        order: "ASC",
      },
    },
  });
  const {
    loaded: paidBillsLoaded,
    error: paidBillsError,
    data: paidBills,
  } = useQueryWithStore({
    type: GET_LIST,
    resource: Bill,
    payload: {
      pagination: {
        page: 1,
        perPage: 10,
      },
      filter: {
        payerId: [payerAilorn],
        paid: true,
        status: ["APPROVED", "REFUNDED"],
        taxCategoryIdNotIn: ["MANAGEMENT_FEES"],
      },
      sort: {
        field: "PaymentDate",
        order: "DESC",
      },
    },
  });

  if (!upcomingBillsLoaded || !paidBillsLoaded) {
    return <BillListItem.Loading />;
  }

  if (upcomingBillsError || paidBillsError) {
    return <Container>There was an error displaying bills</Container>;
  }

  if ((upcomingBills ?? []).length + (paidBills ?? []).length === 0) {
    return <Container>No bills have been created yet.</Container>;
  }

  const sections = [
    {
      title: "Upcoming",
      data: upcomingBills,
    },
    {
      title: "Paid",
      data: paidBills,
    },
  ];
  return (
    <SectionList
      sections={sections}
      renderItem={({ item, section }: any) => (
        <BillListItem key={item.id} bill={item} type={section.title} />
      )}
      style={{ maxHeight: 500 }}
    />
  );
};
