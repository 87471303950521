import { AiloRN, services } from "@ailo/ailorn";
import { useNotify } from "react-admin";
import { Management } from "../../../../api";
import { StatementType } from "../../../../api/graphql/types.generated";
import { useMutation } from "../../../../common";

export const useSendStatement = ({
  onSuccess,
}: {
  onSuccess?(): void;
}): {
  send: (payload: {
    managementId: string;
    statementType: StatementType;
    sendTo: string;
    skipValidation: boolean;
    dryRunEmail?: string;
  }) => void;
} => {
  const notify = useNotify();

  const [mutate] = useMutation(
    {
      resource: Management,
      type: "send_statement",
    },
    {
      onSuccess: () => {
        notify(
          "Statement requested - reload this page and check the statements section to see the status"
        );
        onSuccess?.();
      },
      onFailure: (error) => {
        notify(`Failure while requesting statement: ${error}`);
      },
    }
  );

  return {
    send: ({
      managementId,
      statementType,
      sendTo,
      skipValidation,
      ...data
    }: {
      managementId: string;
      statementType: StatementType;
      sendTo: string;
      skipValidation: boolean;
      dryRunEmail?: string;
    }) => {
      void mutate({
        payload: {
          data: {
            ...data,
            managementAiloRN: AiloRN.of(
              services.PropertyManagement.management,
              managementId
            ),
            statementType,
            dryRunEmail:
              sendTo === "dryRunEmail" ? data.dryRunEmail : undefined,
            skipValidation: sendTo === "dryRunEmail" ? skipValidation : false,
          },
        },
      });
    },
  };
};
