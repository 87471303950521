import { sortBy } from "lodash";
import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
} from "react-admin";
import { MoneyField } from "../../../common";
import { RecurringOwingRecord } from "../types";

export function RatePeriodsAndChargeSchedules(): React.ReactElement {
  const { ratePeriods, chargeSchedules } = useRecordContext();
  const ratePeriodsAndChargeSchedules = mergeAndSort({
    ratePeriods,
    chargeSchedules,
  });
  return (
    <ArrayField
      source="items"
      record={{ id: "", items: ratePeriodsAndChargeSchedules }}
    >
      <Datagrid>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <TextField source="frequency" />
        <MoneyField source="amount" />
        <MoneyField source="taxAmount" />
        <DateField source="firstChargeDate" />
      </Datagrid>
    </ArrayField>
  );
}

function mergeAndSort({
  ratePeriods,
  chargeSchedules,
}: Pick<RecurringOwingRecord, "ratePeriods" | "chargeSchedules">) {
  const map = new Map();
  ratePeriods.forEach((item) =>
    map.set(item.startDate, { ...item, key: item.startDate })
  );
  chargeSchedules.forEach((item) =>
    map.set(item.firstChargeDate, {
      ...map.get(item.firstChargeDate),
      ...item,
      key: item.firstChargeDate,
    })
  );
  const mergedArr = [...map.values()];
  return sortBy(mergedArr, "key");
}
