import { Button, Link } from "react-admin";
import React from "react";
import { RecurringFee } from "../../../../api/ResourceName";
import { DetailCard } from "../../../../common/ui/cards";
import { PropertyFeeList } from "./PropertyFeeList";

export const PropertyFeesCard = ({ propertyId, management }: any) => {
  return (
    <DetailCard
      title="Recurring Fees"
      contents={
        <PropertyFeeList
          propertyId={propertyId}
          managementFee={management?.currentOrNextManagementFeeSchedule}
          recurringFees={management?.recurringFees}
        />
      }
      action={
        management?.currentOrNextManagementAgreement?.id && (
          <Button
            component={Link}
            to={{
              pathname: `/${RecurringFee}/create`,
              state: {
                record: {
                  organisationId: management?.managingEntity?.organisationId,
                  propertyId,
                  managementId: management?.id,
                  managementAgreementId:
                    management?.currentOrNextManagementAgreement?.id,
                },
              },
            }}
            label="Apply new fee"
          />
        )
      }
    />
  );
};
