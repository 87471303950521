import { MoneyInterface } from "@ailo/money";
import Big from "big.js";
import gql from "graphql-tag";
import { CREATE, GET_LIST, GET_ONE, UPDATE } from "react-admin";
import {
  buildPaginatedResponseFromPaginatedList,
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import {
  FeeBaseAmountType,
  FeeBlueprint,
  FeeStatus,
  FeeType,
} from "../../graphql/types.generated";
import {
  ArchiveFeeMutation,
  ArchiveFeeMutationVariables,
  CreateFeeMutation,
  CreateFeeMutationVariables,
  FeeQuery,
  FeeQueryVariables,
  FeesQuery,
  FeesQueryVariables,
  UpdateFeeMutation,
  UpdateFeeMutationVariables,
} from "./FeeClient.generated";

const detailedFeeFragment = gql`
  fragment DetailedFee on Fee {
    id
    type
    management {
      id
      ailoRN
      managingEntity {
        id
        ailoRN
        registeredEntityName
      }
      owners {
        owner {
          __typename
          id
          ... on Person {
            firstName
            lastName
            phoneNo
            emailAddress
          }
          ... on Company {
            registeredEntityName
          }
        }
      }
      property {
        id
        address {
          unitStreetNumber
          streetName
          suburb
          state
          country
          postcode
        }
      }
    }
    managementFeeBlueprint {
      id
      ailorn
      feeBlueprint {
        id
        ailoRN
        name
        chargeType
        organisation {
          id
          name
        }
      }
    }
    blueprint {
      id
      ailoRN
      name
      chargeType
      organisation {
        id
        name
      }
    }
    appliedTo {
      ailoRN
    }
    amount {
      cents
    }
    percentage
    baseAmount {
      cents
    }
    baseAmountType
    description
    status
    taxCategory {
      id
      name
    }
    liability {
      __typename
      id
      firstPaymentDate
    }
    createdAt
    createdBy {
      __typename
      ... on User {
        id
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
    modifiedBy {
      __typename
      ... on User {
        id
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
    archivedAt
    archivedBy {
      __typename
      ... on User {
        id
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
    archiveReason
  }
`;

function mergeManagementFeeBlueprintAndAgencyFeeBlueprint<Fee>(fee: Fee): Fee {
  if (!fee) return fee;

  const { blueprint, managementFeeBlueprint } = (fee ?? {}) as {
    blueprint?: FeeBlueprint;
    managementFeeBlueprint?: { feeBlueprint: FeeBlueprint };
  };

  return {
    ...fee,
    blueprint: blueprint || managementFeeBlueprint?.feeBlueprint,
  };
}

export const FeeClientDefinition = {
  [GET_LIST]: (params: {
    pagination: PaginationParams;
    filter: { managementId: string; status: FeeStatus | FeeStatus[] };
    sort: {
      field?: string;
      order?: "ASC" | "DESC";
    };
  }) => ({
    query: gql`
      query fees(
        $pageSize: Int
        $cursor: String
        $conditions: FeesQueryConditions
        $sort: [FeeSort!]
      ) {
        fees(
          cursor: { pageSize: $pageSize, cursor: $cursor }
          conditions: $conditions
          sort: $sort
        ) {
          pageInfo {
            hasMore
            nextCursor
            total
          }
          items {
            id
            type
            management {
              id
              ailoRN
            }
            appliedTo {
              ailoRN
            }
            amount {
              cents
            }
            percentage
            taxCategory {
              id
              name
            }
            status
            createdAt
            archivedAt
          }
        }
      }
    `,
    variables: <FeesQueryVariables>{
      ...buildPaginationVariables(params.pagination),
      conditions: {
        managementId: params.filter.managementId
          ? [params.filter.managementId]
          : undefined,
        status: params.filter.status,
      },
      sort:
        params.sort?.field === "status"
          ? ["STATUS_PROGRESS_ASC"]
          : params.sort?.field === "createdAt" && params.sort?.order === "ASC"
          ? ["CREATED_AT_ASC"]
          : params.sort?.field === "createdAt" && params.sort?.order === "DESC"
          ? ["CREATED_AT_DESC"]
          : null,
    },
    parseResponse(response: GraphQLResponse<FeesQuery>) {
      const { data, ...rest } = buildPaginatedResponseFromPaginatedList(
        response.data.fees!
      );
      return {
        data: data.map(mergeManagementFeeBlueprintAndAgencyFeeBlueprint),
        ...rest,
      };
    },
  }),
  [GET_ONE]: (params: { id: string }) => ({
    query: gql`
      query fee($id: ID!) {
        fee(id: $id) {
          ...DetailedFee
        }
      }
      ${detailedFeeFragment}
    `,
    variables: <FeeQueryVariables>{
      id: String(params.id),
    },
    parseResponse(response: GraphQLResponse<FeeQuery>) {
      return {
        data: mergeManagementFeeBlueprintAndAgencyFeeBlueprint(
          response.data.fee
        ),
      };
    },
  }),
  [CREATE]: (params: {
    data: {
      managementId: string;
      blueprintId?: string;
      managementFeeBlueprintId?: string;
      type: FeeType;
      taxCategoryId: string;
      amount: MoneyInterface;
      percentage?: number;
      baseAmount?: MoneyInterface;
      baseAmountType?: FeeBaseAmountType;
      description?: string;
    };
  }) => ({
    query: gql`
      mutation createFee($input: CreateFeeInput!) {
        fee: createFee(input: $input) {
          id
          management {
            id
            property {
              id
            }
          }
        }
      }
    `,
    variables: <CreateFeeMutationVariables>{
      input: {
        managementId: params.data.managementId,
        feeBlueprintId: params.data.blueprintId,
        managementFeeBlueprintId: params.data.managementFeeBlueprintId,
        type: params.data.type,
        taxCategoryId: params.data.taxCategoryId,
        amount: { cents: params.data.amount.cents },
        taxAmount: {
          cents: new Big(params.data.amount.cents).div(11).round().toNumber(),
        },
        percentage: params.data.percentage,
        baseAmount: params.data.baseAmount
          ? { cents: params.data.baseAmount.cents }
          : null,
        baseAmountType: params.data.baseAmountType,
        description: params.data.description,
      },
    },
    parseResponse(response: GraphQLResponse<CreateFeeMutation>) {
      return {
        data: mergeManagementFeeBlueprintAndAgencyFeeBlueprint(
          response.data.fee
        ),
      };
    },
  }),
  [UPDATE]: (params: {
    data: {
      id: string;
      amount: MoneyInterface;
      percentage?: number;
      baseAmount?: MoneyInterface;
      description?: string;
    };
  }) => ({
    query: gql`
      mutation updateFee($input: UpdateFeeInput!) {
        fee: updateFee(input: $input) {
          ...DetailedFee
        }
      }
      ${detailedFeeFragment}
    `,
    variables: <UpdateFeeMutationVariables>{
      input: {
        id: params.data.id,
        amount: { cents: params.data.amount.cents },
        taxAmount: {
          cents: new Big(params.data.amount.cents).div(11).round().toNumber(),
        },
        percentage: params.data.percentage,
        baseAmount: params.data.baseAmount
          ? { cents: params.data.baseAmount.cents }
          : undefined,
        description: params.data.description,
      },
    },
    parseResponse(response: GraphQLResponse<UpdateFeeMutation>) {
      return {
        data: mergeManagementFeeBlueprintAndAgencyFeeBlueprint(
          response.data.fee
        ),
      };
    },
  }),
  archive: (params: { data: { id: string; archiveReason?: string } }) => ({
    query: gql`
      mutation archiveFee($input: UpdateFeeInput!) {
        fee: updateFee(input: $input) {
          id
        }
      }
    `,
    variables: <ArchiveFeeMutationVariables>{
      input: {
        id: params.data.id,
        archiveReason: params.data.archiveReason,
        archived: true,
      },
    },
    parseResponse(response: GraphQLResponse<ArchiveFeeMutation>) {
      return {
        data: mergeManagementFeeBlueprintAndAgencyFeeBlueprint(
          response.data.fee
        ),
      };
    },
  }),
};

export const feeClient = createClientFromDefinition(FeeClientDefinition);
