import { Money } from "@ailo/money";

import { Alert } from "@material-ui/lab";
import React, { ReactElement } from "react";
import {
  FileField,
  FileInput,
  FormDataConsumer,
  minValue,
  number,
  required,
  TextInput,
  useInput,
  useNotify,
} from "react-admin";
import { ManagementFeeBlueprint, OperationData } from "../../../../api";
import {
  maxMoney,
  minMoney,
  MoneyInput,
  OrganisationInput,
  PercentInput,
  SimpleFormDialog,
} from "../../../../common";
import { FeeBlueprintInput } from "../../../../resourceViews/ManagementFeeBlueprint/FeeBlueprintInput";
import { CheckboxWithText } from "../../../../resourceViews/Transaction/components";
import { ExampleManagementsCSV } from "../common";
import { updateManagementFeeBlueprintsForManagements } from "./bulkUpdateManagementFeeBlueprints";

const requiredValidate = [required()];

export function BulkUpdateManagementFeeBlueprintsDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement {
  const notify = useNotify();

  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title="Bulk Update Management Fee Blueprints"
      submitLabel="Submit"
      save={async (formData) => {
        const promise = new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            resolve(reader.result as string)
          );
          reader.addEventListener("error", reject);
          reader.readAsText(formData.data.data.rawFile);
        });

        const csvString = await promise;

        try {
          await updateManagementFeeBlueprintsForManagements({
            csvString,
            feeBlueprintId: formData.feeBlueprint.id,
            remove: formData.remove,
            input: {
              fixedAmount: formData.fixedAmount
                ? { cents: formData.fixedAmount.cents }
                : undefined,
              oneWeekRentPercentage: formData.oneWeekRentPercentage,
              taxTreatment: formData.taxTreatment,
              description: formData.description,
            },
          });
        } catch (error) {
          notify(`Could not update. ${error}`);
          return;
        }

        notify("Management fee blueprints updated");
        onClose();
      }}
      onClose={onClose}
    >
      <FormDataConsumer>{InnerForm}</FormDataConsumer>

      <FileInput
        source="data.data"
        label="CSV file with list of Management IDs"
        accept="text/csv"
        validate={requiredValidate}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <Alert
        severity="info"
        {...{ fullWidth: true }}
        style={{ marginBottom: 12 }}
      >
        If they exist this script will update the management fee blueprints
        corresponding to the managementIds and agency fee blueprint provided.
        Otherwise it will create them.
      </Alert>
      <ExampleManagementsCSV />
    </SimpleFormDialog>
  );
}

function InnerForm({
  formData,
}: {
  formData: OperationData<typeof ManagementFeeBlueprint, "GET_ONE"> & {
    organisation: string;
    remove: boolean;
  };
}): React.ReactElement {
  const feeBlueprint = formData?.feeBlueprint;
  const fixedAmount = formData?.fixedAmount;

  const {
    input: { value: taxTreatment, onChange: onChangeTaxTreatment },
  } = useInput({
    defaultValue: "inclusive",
    source: "taxTreatment",
  });

  const {
    input: { value: remove, onChange: onChangeRemove },
  } = useInput({
    defaultValue: false,
    source: "remove",
  });

  const amount = fixedAmount
    ? Money.fromCents(fixedAmount.cents || 0)
    : undefined;
  const tax = amount?.divide(taxTreatment === "inclusive" ? 11 : 10);

  const formattedTax = tax?.format({
    symbol: true,
    withTrailingZeros: true,
  });

  const formattedTotal = tax
    ? amount
        ?.add(taxTreatment === "inclusive" ? Money.fromCents(0) : tax)
        .format({ symbol: true, withTrailingZeros: true })
    : undefined;

  const text =
    formattedTax &&
    formattedTotal &&
    `GST ${formattedTax}, total ${formattedTotal}`;

  return (
    <>
      <CheckboxWithText
        {...{
          checked: remove,
          onChange: (event): void => onChangeRemove(!!event?.target?.checked),
          label: "Archive Blueprints",
          text:
            "If checked all matching blueprints will be archived, otherwise they will be edited/created.",
        }}
      />
      <OrganisationInput source="organisation" allowEmpty />
      {formData.organisation ? (
        <FeeBlueprintInput organisationId={formData.organisation} />
      ) : null}
      {!remove && (
        <>
          {feeBlueprint?.fixedAmount ? (
            <MoneyInput
              source="fixedAmount"
              label="Price ($)"
              fullWidth
              defaultValue={feeBlueprint.fixedAmount}
              validate={[
                required(),
                minMoney({ cents: 0 }),
                maxMoney({ cents: 1300000 }),
              ]}
            />
          ) : null}
          {feeBlueprint?.oneWeekRentPercentage ? (
            <PercentInput
              source="oneWeekRentPercentage"
              label="Weeks of rent"
              format="decimal"
              dp={4}
              defaultValue={feeBlueprint.oneWeekRentPercentage}
              validate={[required(), number(), minValue(0)]}
              fullWidth
            />
          ) : null}
          {feeBlueprint ? (
            <CheckboxWithText
              {...{
                checked: formData.taxTreatment === "inclusive",
                onChange: (event): void =>
                  onChangeTaxTreatment(
                    event?.target?.checked ? "inclusive" : "exclusive"
                  ),
                label: "Includes GST",
                text,
              }}
            />
          ) : null}
          {feeBlueprint?.event && (
            <>
              <TextInput source="description" style={{ width: "100%" }} />
              <Alert
                severity="info"
                {...{ fullWidth: true }}
                style={{ marginBottom: 12 }}
              >
                {`This fee will be charged ${feeBlueprint.event.longDescription}`}
              </Alert>
            </>
          )}
        </>
      )}
    </>
  );
}
