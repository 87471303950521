import { formatLegalEntityName } from "@ailo/domain-helpers";
import { get } from "lodash";
import React, { ComponentProps } from "react";
import { FunctionField } from "react-admin";
import { LinkField } from "../../common";
import {
  InjectedFieldProps,
  PublicFieldProps,
} from "../../common/fields/utils/react-admin-types";

export function BillPayeeField({
  source,
  linkable,
  copyable,
  ...props
}: {
  source: string;
} & PublicFieldProps &
  InjectedFieldProps &
  Pick<
    ComponentProps<typeof LinkField>,
    "linkable" | "copyable"
  >): React.ReactElement {
  return (
    <FunctionField
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={(bill: any) => {
        const payee = get(bill, source);
        return payee?.__typename === "Management" ? (
          "Owners"
        ) : (
          <LinkField
            record={payee}
            renderText={() =>
              payee.__typename === "LegalEntityCompanion"
                ? formatLegalEntityName(payee.legalEntity)
                : payee.__typename === "Supplier"
                ? payee.name
                : JSON.stringify(payee)
            }
            linkable={linkable}
            copyable={copyable}
          />
        );
      }}
    />
  );
}

BillPayeeField.defaultProps = {
  addLabel: true,
};
