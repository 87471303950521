import { GET_MANY, useQuery } from "react-admin";
import _ from "lodash";
import { AiloRN, services } from "@ailo/ailorn";
import { Bill } from "../../../api";
import { FileAttachment } from "./useZipAttachments";

export function useGetBillsAttachments(
  ids: string[]
): { loading: boolean; attachments?: FileAttachment[] } {
  const idAiloRNs = getBillIdAiloRNs(ids);
  const { loading, data } = useLoadData(idAiloRNs);

  if (loading) return { loading };
  return {
    loading,
    attachments: flattenedAttachments(data),
  };
}

function getBillIdAiloRNs(ids: string[]): string[] {
  return (ids || []).map((id) => AiloRN.of(services.Bill.bill, id).toString());
}

function useLoadData(idAiloRNs: string[] | undefined) {
  return useQuery({
    resource: Bill,
    type: GET_MANY,
    payload: {
      ids: idAiloRNs,
      pagination: {
        perPage: (idAiloRNs || []).length,
      },
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function flattenedAttachments(data: any) {
  return _.chain(data)
    ?.flatMap((bill) =>
      bill?.attachments?.map(
        (attachment: { file: { fileName: string; url: string } }) =>
          attachment.file
      )
    )
    .map((file) => ({
      fileName: file.fileName! as string,
      url: file.url! as string,
    }))
    .value();
}
