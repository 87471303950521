import { SelectInput, SelectInputProps } from "react-admin";
import React from "react";

export function StatementTypeInput(
  props: Omit<SelectInputProps, "choices">
): React.ReactElement | null {
  return (
    <SelectInput
      choices={[
        "AgencyTransfer",
        "Management",
        "TaxSummary",
        "TenancyLedger",
        "TransferSummary",
      ].map((id) => ({
        id,
        name: id,
      }))}
      {...props}
    />
  );
}
