import React from "react";
import {
  Datagrid,
  TextField,
  FunctionField,
  Tab,
  ArrayField,
} from "react-admin";
import { PersonLinkField } from "../../common/fields/deprecated/PersonLinkField";
import { CompanyLinkField } from "../../common/fields/deprecated/CompanyLinkField";
import { CopyableTextField } from "../../common";

function renderOrganisationTab() {
  return (
    <Tab label="Customer Details">
      <CopyableTextField label="Id" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Type" source="orgType" />
      <TextField label="CreatedAt" source="createdAt" />
      <ArrayField source="legalEntities">
        <Datagrid>
          <TextField source="id" />
          <FunctionField
            label="name"
            render={(record: any) => {
              return record.registeredEntityId ? (
                <CompanyLinkField record={record} />
              ) : (
                <PersonLinkField record={record} />
              );
            }}
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}

export { renderOrganisationTab };
