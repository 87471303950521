import { Card, CardContent, CardHeader } from "@material-ui/core";
import { startCase } from "lodash";
import React from "react";
import { envVars } from "../../envVars";

const DashboardPage = () => (
  <Card
    style={{
      margin: "2em",
    }}
  >
    <CardHeader
      title={`Welcome to Ailo-Admin ${startCase(envVars.ailoApiEnv)}`}
    />
    <CardContent>
      From here you can create, read, update and delete information for the ailo
      system.
    </CardContent>
    <CardContent>Click on an area on the side bar to get started.</CardContent>
  </Card>
);

export { DashboardPage };
