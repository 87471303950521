import React, { useEffect } from "react";
import { FormDataConsumer, TextInputProps, useInput } from "react-admin";
import { CreateBillFormData } from "../..";
import { CheckboxWithText } from "../../../Transaction/components";

type InternalBillInputProps = Pick<TextInputProps, "style">;

export function InternalBillInput(
  props: Partial<InternalBillInputProps>
): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }: { formData: Partial<CreateBillFormData> }) =>
        formData.payee?.type === "supplier" ? (
          <InternalBillInputInner {...props} />
        ) : null
      }
    </FormDataConsumer>
  );
}

function InternalBillInputInner({
  ...props
}: InternalBillInputProps): React.ReactElement {
  const {
    input: { value, onChange },
  } = useInput({ defaultValue: false, source: "internal" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => onChange(undefined), []);

  return (
    <CheckboxWithText
      label="Send funds to supplier’s Ailo wallet only (mark bill as internal)"
      checked={value}
      onChange={(event?: { target?: { checked?: boolean } }): void =>
        onChange(!!event?.target?.checked)
      }
      {...props}
    />
  );
}
