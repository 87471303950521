import { AiloRN, services } from "@ailo/ailorn";
import gql from "graphql-tag";
import { GET_LIST } from "react-admin";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

const CANCEL_LIABILITY_PAYMENT_PLAN = "cancel_liability_payment_plan";
export function liabilityPaymentPlansClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_LIST:
      return {
        query: gql`
          query getLiabilityPaymentPlans(
            $enabled: Boolean!
            $payerLegalEntityAiloRN: AiloRN!
          ) {
            liabilityPaymentPlans(
              enabled: $enabled
              payerLegalEntityId: $payerLegalEntityAiloRN
            ) {
              pageInfo {
                hasMore
                nextCursor
                total
              }
              items {
                id
                liabilityId
                ownerAiloRN: owner
                enabled
                createdBy
                createdAt
                details {
                  frequency
                  anniversary
                  startDate
                  endDate
                  amount {
                    cents
                  }
                }
              }
            }
          }
        `,
        variables: {
          enabled: params.filter.enabled,
          payerLegalEntityAiloRN: AiloRN.of(
            services.AuthZ.legalEntity,
            params.filter.payerLegalEntityId || "-"
          ).toString(),
        },

        parseResponse({ data }: any) {
          return {
            data: data.liabilityPaymentPlans.items,
            total: data.liabilityPaymentPlans.pageInfo.total,
            nextCursor: data.liabilityPaymentPlans.pageInfo.nextCursor,
            hasMore: data.liabilityPaymentPlans.pageInfo.hasMore,
          };
        },
      };

    case CANCEL_LIABILITY_PAYMENT_PLAN:
      return {
        query: gql`
          mutation cancelLiabilityPaymentPlan(
            $liabilityPaymentPlanId: ID!
            $payerLegalEntityId: AiloRN!
          ) {
            cancelLiabilityPaymentPlan(
              cancelLiabilityPaymentPlanInput: {
                liabilityPaymentPlanId: $liabilityPaymentPlanId
                payerLegalEntityId: $payerLegalEntityId
              }
            ) {
              id
              enabled
            }
          }
        `,
        variables: {
          liabilityPaymentPlanId: params.liabilityPaymentPlanId,
          payerLegalEntityId: params.payerLegalEntityId,
        },
        parseResponse: (response: {
          data: { cancelLiabilityPaymentPlan: any };
        }) => ({
          data: response.data.cancelLiabilityPaymentPlan,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
