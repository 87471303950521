/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import { ListItemView } from "../../ui/lists";
import { formatDate } from "../../utils/formatDate";
import { NameAvatars } from "../../ui/avatars";
import { Colors } from "../../ui/colors/colors";
import { opacify } from "../../ui/colors";
import { Default } from "../../ui/Badge/default";

const ManagementSwitcherItem = ({ management }: any) => {
  const managingEntityName = management.managingEntity?.registeredEntityName;
  const firstManagementAgreement =
    management.allManagementAgreements.length > 0
      ? management.allManagementAgreements[0]
      : undefined;
  const timeRange = getTimeRange({
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: any; start: any; }' is not... Remove this comment to see the full error message
    id: management.id,
    start: firstManagementAgreement?.startDate,
  });
  const ownerNames = management.owners
    ?.filter((item: { owner: undefined }) => item.owner !== undefined)
    .map((item: { owner: any }) => item.owner);
  const tenantNames = management.mostRecentTenancy?.tenants
    ?.filter((item: { tenant: undefined }) => item.tenant !== undefined)
    .map((item: { tenant: any }) => item.tenant);
  return (
    <ListItemView
      header={managingEntityName}
      subHeader={
        <>
          {timeRange}
          {management.isDraft ? (
            <Default
              style={{
                width: 40,
                marginTop: 1,
              }}
            >
              DRAFT
            </Default>
          ) : null}
        </>
      }
      rightComponent={
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <NameAvatars
              names={ownerNames}
              style={{
                color: Colors.INVESTOR_ORANGE,
                backgroundColor: opacify(Colors.INVESTOR_ORANGE, 0.2),
              }}
            />
          </Grid>
          <Grid item>
            <NameAvatars
              names={tenantNames}
              style={{
                color: Colors.TENANT_BLUE,
                backgroundColor: opacify(Colors.TENANT_BLUE, 0.2),
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

function getTimeRange({ start }: { start: string }): string {
  if (!start) return "";
  const prefix = moment().isBefore(moment(start), "day")
    ? "Starting"
    : "Started";
  return `${prefix} ${formatDate(start)}`;
}

export { ManagementSwitcherItem };
