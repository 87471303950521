import React from "react";
import { Critical } from "./critical";
import { Default } from "./default";
import { Warning } from "./warning";
import { Success } from "./success";
import { Investor } from "./Investor";
import { Tenant } from "./Tenant";

export type BadgeType =
  | "default"
  | "critical"
  | "success"
  | "warning"
  | "investor"
  | "tenant";

export function Badge(props: {
  type?: BadgeType;
  text?: any;
  style?: any;
  children?: any;
}) {
  const { type = "default", text, style, children = text } = props;
  const badgeProps = {
    style,
    children,
  };
  const BadgeComponent =
    type === "critical"
      ? Critical
      : type === "success"
      ? Success
      : type === "warning"
      ? Warning
      : type === "investor"
      ? Investor
      : type === "tenant"
      ? Tenant
      : Default;
  return <BadgeComponent {...badgeProps} />;
}

/**
 * @deprecated Use <Badge type="critical" /> instead.
 */
Badge.Critical = Critical;

/**
 * @deprecated Use <Badge type="warning" /> instead.
 */
Badge.Warning = Warning;

/**
 * @deprecated Use <Badge /> instead.
 */
Badge.Default = Default;

/**
 * @deprecated Use <Badge type="success" /> instead.
 */
Badge.Success = Success;
