import React from "react";
import { Company } from "../../../api/ResourceName";
import { DeprecatedLinkField } from "./DeprecatedLinkField";

/**
 * @deprecated Use LinkField
 */
const CompanyLinkField = ({
  record: { id, registeredEntityName: name },
  resource = Company,
  children,
}: {
  record: any;
  resource?: string | null | undefined;
  children?: React.ReactNode;
}) => {
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    <DeprecatedLinkField resource={resource} value={name} internalId={id}>
      {children}
    </DeprecatedLinkField>
  );
};

export { CompanyLinkField };
