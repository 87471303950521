import { CREATE } from "react-admin";
import gql from "graphql-tag";
import type {
  RaDataGraphqlClientArgs,
  LiabilityAdjustment,
} from "../../deprecatedTypes";
import { transformLiabilityAdjustmentRequest } from "../../common";

function liabilityAdjustmentClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CREATE:
      return {
        query: gql`
          mutation adjust(
            $adjustmentType: String!
            $amount: Long!
            $description: String!
            $descriptionIsCustom: Boolean
            $effectiveAt: DateTime!
            $liabilityId: ID!
            $idempotencyKey: String!
          ) {
            adjustLiabilityEntry(
              adjustmentType: $adjustmentType
              amount: { cents: $amount }
              description: $description
              descriptionIsCustom: $descriptionIsCustom
              effectiveAt: $effectiveAt
              liabilityId: $liabilityId
              idempotencyKey: $idempotencyKey
            ) {
              id
              amount {
                cents
              }
              createdAt
              description
              effectiveAt
              liabilityId
              sourceId
              sourceType
            }
          }
        `,
        variables: transformLiabilityAdjustmentRequest(params.data),
        parseResponse: (response: {
          data: {
            adjustLiabilityEntry: LiabilityAdjustment;
          };
        }) => ({
          data: response.data.adjustLiabilityEntry,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { liabilityAdjustmentClient };
