import { AiloRN, services } from "@ailo/ailorn";
import { trim } from "lodash";
import { GET_MANY, GET_ONE } from "react-admin";
import { useMemo } from "react";
import { isPresent } from "ts-is-present";
import { formatLegalEntityName } from "@ailo/domain-helpers";
import { Management, Supplier } from "../../../../..";
import { GetSuppliersQuery } from "../../../../../../api/resources/Supplier/supplierClient.generated";
import { useQuery } from "../../../../../../common/api/useQuery";
import { getTenancyDescription } from "../getTenancyDescription";

export type PayeeOption =
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "management";
        ailorn: string;
        investorsAilorns: string[];
      };
    }
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "tenancy";
        ailorn: string;
        tenants: { ailorn: string; name: string }[];
        description?: string;
      };
    }
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "agency";
        ailorn: string;
        agencyAilorns: string[];
      };
    }
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "supplier";
        ailorn: string;
      };
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Error = any;

type PayeeOptions = {
  options: PayeeOption[];
  error?: Error;
  loading: boolean;
};

type LegalEntity = Parameters<typeof formatLegalEntityName>["0"];

export function useGetPayeeOptions(
  managementId?: string,
  organisationAilorn?: AiloRN
): PayeeOptions {
  const {
    data: managementData,
    loading: managementLoading,
    error: managementError,
    loaded: managementLoaded,
  } = useQuery(
    {
      type: GET_ONE,
      resource: Management,
      payload: {
        id: managementId,
      },
    },
    { enabled: !!managementId }
  );

  const organisationId = organisationAilorn
    ? organisationAilorn.internalId
    : managementData?.managingEntity?.organisation?.id;

  const {
    data: supplierData,
    loading: supplierLoading,
    error: supplierError,
    loaded: supplierLoaded,
  }: {
    data?: GetSuppliersQuery["suppliers"]["items"];
    loading: boolean;
    loaded: boolean;
    error?: Error;
  } = useQuery(
    {
      type: GET_MANY,
      resource: Supplier,
      payload: {
        organisationId,
      },
    },
    { enabled: !!organisationId }
  );

  const loaded = supplierLoaded && managementLoaded;

  return useMemo((): PayeeOptions => {
    const options: PayeeOption[] = [];

    if (!managementId || !organisationId) {
      return { options, loading: false };
    }

    if (
      !loaded ||
      managementLoading ||
      supplierLoading ||
      !managementData ||
      !supplierData
    ) {
      return {
        options,
        error: managementError || supplierError,
        loading: true,
      };
    }

    const ownerNames =
      managementData.owners
        ?.map((owner) => owner?.owner)
        ?.map(
          (owner) =>
            owner?.__typename && formatLegalEntityName(owner as LegalEntity)
        )
        ?.filter(isPresent)
        ?.map(trim)
        ?.reduce(
          (names, name, index) => `${names}${index ? ", " : ""}${name}`,
          ""
        ) || "Owners";

    const ownerOption: PayeeOption = {
      name: ownerNames,
      id: managementId,
      value: {
        name: ownerNames,
        type: "management",
        investorsAilorns: (managementData.owners ?? [])
          .map((owner) => owner?.owner?.ailoRN)
          .filter(isPresent),
        ailorn: AiloRN.of(
          services.PropertyManagement.management,
          managementId
        ).toString(),
      },
    };

    const agencyName =
      managementData.managingEntity?.registeredEntityName ?? "Agency";

    const agencyOption: PayeeOption = {
      name: agencyName,
      id: managementData.managingEntity!.id,
      value: {
        name: agencyName,
        type: "agency",
        ailorn: managementData.managingEntity!.ailoRN,
        agencyAilorns: [managementData.managingEntity!.ailoRN],
      },
    };

    const tenancyOptions = (managementData.allTenancies ?? []).map(
      (tenancy): PayeeOption => {
        const name = (tenancy?.tenants ?? [])
          .map((tenant) => tenant?.tenant)
          .filter(isPresent)
          .reduce((names, tenant, index) => {
            return `${names}${index ? ", " : ""}${
              tenant.__typename
                ? formatLegalEntityName(tenant as LegalEntity)
                : ""
            }`;
          }, "");

        return {
          name,
          id: tenancy!.id,
          value: {
            name,
            description: getTenancyDescription(tenancy),
            type: "tenancy",
            ailorn: tenancy!.ailoRN,
            tenants: (tenancy!.tenants ?? [])
              .map((tenant) =>
                tenant?.tenant?.ailoRN
                  ? {
                      ailorn: tenant.tenant.ailoRN,
                      name: tenant.tenant.__typename
                        ? formatLegalEntityName(tenant.tenant as LegalEntity)
                        : "unknown tenant",
                    }
                  : undefined
              )
              .filter(isPresent),
          },
        };
      }
    );

    const supplierOptions: PayeeOption[] = supplierData
      .filter((supplier) => supplier.name)
      .sort((a, b) => (a.name! < b.name! ? -1 : a.name! > b.name! ? 1 : 0))
      .map((supplier) => ({
        name: supplier.name || "supplier",
        id: supplier.ailoRN,
        value: {
          name: supplier.name || "supplier",
          type: "supplier",
          ailorn: supplier.ailoRN,
        },
      }));

    options.push(
      agencyOption,
      ownerOption,
      ...tenancyOptions,
      ...supplierOptions
    );

    return {
      options,
      error: managementError,
      loading: true,
    };
  }, [
    managementId,
    organisationId,
    loaded,
    managementLoading,
    supplierLoading,
    managementData,
    supplierData,
    managementError,
    supplierError,
  ]);
}
