import {
  FeeBlueprintChargeType,
  FeeBlueprintType,
} from "../../api/graphql/types.generated";

export const taxCategoryDefaultValues = {
  ADMINISTRATION_FEES: {
    name: "Administration Fees",
    type: FeeBlueprintType.RecurringFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  ADVERTISING_FEES: {
    name: "Advertising Fees",
    type: FeeBlueprintType.EventBasedFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  ADVERTISING_SIGN: {
    name: "Advertising Sign",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  ENTRY_INSPECTION_FEES: {
    name: "Entry Inspection Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  EXIT_INSPECTION_FEES: {
    name: "Exit Inspection Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  PHOTOGRAPHY_FEES: {
    name: "Photography Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  ANNUAL_FEES: {
    name: "Annual Fees",
    type: FeeBlueprintType.RecurringFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  INSPECTION_FEES: {
    name: "Inspection Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  LEASE_RENEWAL_FEES: {
    name: "Lease Renewal Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  LEASE_PREPARATION_FEES: {
    name: "Lease Preparation Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  LEASE_BREAK_FEES: {
    name: "Lease Break Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  LETTING_FEES: {
    name: "Letting Fees",
    type: FeeBlueprintType.EventBasedFee,
    chargeType: FeeBlueprintChargeType.OneWeekRentPercentage,
  },
  MANAGEMENT_FEES: {
    name: "Management Fees",
    type: FeeBlueprintType.RecurringFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  SALES_ADVERTISING_FEES: {
    name: "Sales Advertising Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  TRIBUNAL_FEES: {
    name: "Tribunal Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
  INVOICE_FEES: {
    name: "Invoice Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: FeeBlueprintChargeType.FixedAmount,
  },
};
