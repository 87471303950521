import { AutocompleteInput } from "react-admin";
import React, { useCallback } from "react";
import moment from "moment-timezone";
import { formatPropertyOptionText } from "../utils";
import { Management } from "../../api/ResourceName";
import { ReferenceInput } from "./ReferenceInput";

export function ManagementInput({
  source,
  allowEmpty = false,
  emptyText,
  disabled,
  options,
  ...props
}: any) {
  /**
   * TODO Replace `created at` with a `from: startDate; owners' names` sublabel
   * once https://ailo.atlassian.net/browse/BUG-313?focusedCommentId=34457 is resolved and
   * a resolver for fetching a management's earliest agreement start date is added. This
   * will help differentiate between properties with multiple managements
   */
  const formatOption = useCallback(
    (record) =>
      record.property
        ? `${formatPropertyOptionText(record?.property)} (created at ${moment(
            record.createdAt
          ).format("YYYY-MM-DD hh:mm:ss")})`
        : "All Properties",
    []
  );
  return (
    <ReferenceInput
      reference={Management}
      source={source}
      filterToQuery={(searchTerm: string) => ({
        searchTerm,
        withManagingEntity: false,
      })}
      allowEmpty={allowEmpty}
      {...props}
    >
      <AutocompleteInput
        optionText={formatOption}
        allowEmpty={allowEmpty}
        emptyText={emptyText}
        options={{
          disabled,
          ...options,
          InputProps: {
            // Disable Chrome address autocomplete dropdown
            // ( https://github.com/marmelab/react-admin/issues/5620#issuecomment-773773732 )
            autoComplete: "no",
            ...options?.InputProps,
          },
        }}
      />
    </ReferenceInput>
  );
}
