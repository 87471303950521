import { LocalDate } from "@ailo/date";
import { required } from "ra-core";
import React from "react";
import { DateInput } from "react-admin";
import { SimpleForm } from "../../../common";
import {
  AttachmentsInput,
  PayerInput,
  PayeeInput,
  PaymentDestinationInput,
  DescriptionInput,
  AmountInput,
  PropertyInput,
  ManagementInput,
  CategoryInput,
  InternalBillInput,
} from "./InputComponents";

const BillForm = (
  props: React.ComponentProps<typeof SimpleForm>
): React.ReactElement => {
  return (
    <SimpleForm {...props}>
      <FormContainer>
        <PropertyInput />
        <ManagementInput />
        <Row>
          <PayerInput
            style={{ flex: 1 }}
            helperText="Who's paying this bill?"
          />
          <PayeeInput
            style={{ flex: 1, marginLeft: 16 }}
            helperText="Who's getting paid?"
          />
        </Row>
        <InternalBillInput style={{ width: "100%" }} />
        <PaymentDestinationInput style={{ width: "100%" }} />
        <CategoryInput style={{ width: "100%" }} />
        <Row>
          <DateInput
            style={{ flex: 1 }}
            label="Issue Date"
            source="issueDate"
            validate={[required()]}
            defaultValue={LocalDate.from(new Date(Date.now()))}
          />
          <DateInput
            style={{ flex: 1, marginLeft: 16 }}
            label="Due Date"
            source="dueDate"
            validate={[required()]}
            defaultValue={LocalDate.from(new Date()).add(7, "d")}
          />
        </Row>
        <DescriptionInput style={{ width: "100%" }} />
        <AmountInput style={{ width: "100%" }} />
        <AttachmentsInput />
      </FormContainer>
    </SimpleForm>
  );
};

function FormContainer({ children }: { children: React.ReactNode }) {
  return <div style={{ width: 800 }}>{children}</div>;
}

function Row({ children }: { children: React.ReactNode }) {
  return (
    <div style={{ flexDirection: "row", display: "flex", width: "100%" }}>
      {children}
    </div>
  );
}

export { BillForm };
