import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import {
  ArrayInput,
  DateInput,
  SaveButton,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  TextField,
  Datagrid,
  useMutation,
  ArrayField,
  FunctionField,
  SelectInput,
} from "react-admin";
import currency from "currency.js";
import { flatMap } from "lodash";
import { LiabilityPlanChangePreview } from "../../api/ResourceName";

const useStyles = makeStyles({
  form: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
});

const PlanChangePreviewDialog = ({
  isOpened,
  onClose,
  record,
  liabilityId,
}: any) => {
  const classes = useStyles();
  const [input, setInput] = useState();

  if (input == undefined && record) {
    const rentSchedules = flatMap(
      record.rents.items.map((r: { rentSchedules: any }) => r.rentSchedules)
    );
    setInput({
      ...record,
      rentSchedulesReverse: [
        ...rentSchedules.map((m: any) => ({
          ...m,
          amountInDollars: currency(m.amountInCents).divide(100).format(),
        })),
      ].reverse(),
    });
  }

  const [data, setData] = useState();
  const [mutate, { loading }] = useMutation();

  const loadPreview = (formToSubmit: { rentSchedulesReverse: any[] }) => {
    const plans = formToSubmit.rentSchedulesReverse
      .filter(
        (s: { amountInDollars: any; period: any; startDate: any }) =>
          s && s.amountInDollars && s.period && s.startDate
      )
      .map(
        (s: {
          amountInDollars: currency.Any;
          period: any;
          startDate: any;
        }) => ({
          amount: currency(s.amountInDollars).intValue,
          frequency: s.period,
          startDate: s.startDate,
        })
      );
    mutate(
      {
        type: "preview_liability_plan_update",
        resource: LiabilityPlanChangePreview,
        payload: {
          data: {
            liabilityId,
            proposedPlans: plans,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setData(data);
        },
      }
    );
  };

  const closeDialog = () => {
    setData(undefined);
    onClose();
  };

  return (
    <Dialog open={!!isOpened} onClose={closeDialog} fullWidth maxWidth="lg">
      <DialogTitle>Preview change of plan</DialogTitle>
      <DialogContent>
        {!!data && <PreviewComponent data={data as any} />}
        <SimpleForm
          record={input}
          toolbar={<PostEditToolbar loading={loading} />}
          save={loadPreview as any}
        >
          <ArrayInput source="rentSchedulesReverse">
            <SimpleFormIterator {...({ classes } as any)}>
              <TextInput label="Amount" source="amountInDollars" />
              <SelectInput
                label="Rent Frequency"
                source="period"
                choices={[
                  {
                    id: "weekly",
                    name: "weekly",
                  },
                  {
                    id: "fortnightly",
                    name: "fortnightly",
                  },
                  {
                    id: "monthly",
                    name: "monthly",
                  },
                ]}
              />
              <DateInput label="Start Date" source="startDate" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};

const PreviewComponent = (props: {
  data: {
    mergedPlans: any;
    requiredAdjustment: { cents: any };
    paidToDateWithProposedPlan: any;
    paidToDate: any;
  };
}) => {
  const rowStyle = (record: { isExisting: any }, index: any) => ({
    backgroundColor: record.isExisting
      ? "rgb(191,202,242)"
      : "rgb(193,242,191)",
  });

  return (
    <div>
      <span>Plan Update Preview</span>
      <ArrayField
        label="Proposed Plan Preview"
        source="proposedPlans"
        record={
          {
            proposedPlans: props.data.mergedPlans,
          } as any
        }
      >
        <Datagrid rowStyle={rowStyle as any}>
          <FunctionField
            label="Amount per period ($)"
            render={(record: any) => (
              <span>{currency(record.amount.cents).divide(100).format()}</span>
            )}
          />
          <TextField source="frequency" />
          <DateField source="startDate" />
        </Datagrid>
      </ArrayField>
      <ArrayField
        {...{
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        }}
        label="Adjustment required"
        source="adjustmentRequired"
        record={{
          id: "_",
          adjustmentRequired: [
            {
              adjustmentRequired: props.data.requiredAdjustment.cents,
              paidToDateWithProposedPlan: props.data.paidToDateWithProposedPlan,
              paidToDate: props.data.paidToDate,
            },
          ],
        }}
      >
        <Datagrid>
          <FunctionField
            label="Adjustment required ($)"
            render={(record: any) => (
              <span>
                {currency(Math.abs(record.adjustmentRequired))
                  .divide(100)
                  .format()}{" "}
                {record.adjustmentRequired < 0 ? "Credit" : "Debit"}
              </span>
            )}
          />
          <DateField source="paidToDate" />
          <DateField source="paidToDateWithProposedPlan" />
        </Datagrid>
      </ArrayField>
      <div
        style={{
          fontSize: 12,
          border: "1px solid rgba(224, 224, 224, 1)",
          padding: 12,
        }}
      >
        NOTE:
        <ul>
          <li>
            <span
              style={{
                backgroundColor: "rgb(191,202,242)",
              }}
            >
              Rows in Blue represent current plans that cannot be changed.
            </span>{" "}
            Since we cannot modify our past
          </li>
          <li>
            <span
              style={{
                backgroundColor: "rgb(193,242,191)",
              }}
            >
              Rows in Green represent changes need to be made to move to new
              adjusted plans.
            </span>
          </li>
          <li>
            <strong>Adjustment required</strong> shows that amount required to
            be paid in order to switch from currently active plans to newly
            proposed. Amount being positive means that tenant needs to pay. In
            order to calculate the amount we sum the cost of currently active
            plans from the beginning till the change-over date and subtract the
            cost to run proposed plan till change-over date.
          </li>
          <li>
            <strong>Paid To Date</strong> is the date tenants have paid to
            taking in account active plans only
          </li>
          <li>
            <strong>Paid To Date With Proposed Plan</strong> is the date tenants
            would paid to considering proposed changes to plans
          </li>
          <li
            style={{
              marginTop: 20,
            }}
          >
            <i>change-over date</i> Is the first start date in green.
          </li>
        </ul>
      </div>
    </div>
  );
};

const PostEditToolbar = (props: { [x: string]: any; loading: any }) => {
  const { loading, ...other } = props;
  return (
    <Toolbar {...other}>
      <SaveButton label="Preview" disabled={loading} />
    </Toolbar>
  );
};

export default PlanChangePreviewDialog;
