import IconAdd from "@material-ui/icons/Add";
import React, { ReactElement, useState } from "react";
import { Button } from "react-admin";
import { UploadMigrationCSVDialog } from "./UploadMigrationCSVDialog";

export function UploadMigrationCSVButton(): ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={onClick} label="Upload CSV">
        <IconAdd />
      </Button>
      <UploadMigrationCSVDialog open={open} onClose={onClose} />
    </>
  );
}
