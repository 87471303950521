import React from "react";
import { Button, ButtonProps } from "react-admin";
import { useMutation } from "../../api";

/**
 * @example
 * ```js
  const Actions = ({ basePath, data: record, resource }) => {
    if (!record) return null;
    return (
      <TopToolbar>
        <EditButton basePath={basePath} record={record} />
        {!record.archived && (
          <MutationButton
            {...{ resource, record }}
            type="archive"
            icon={<ArchiveIcon />}
          />
        )}
      </TopToolbar>
    );
  };

  export const RecurringFeeBlueprintShow = (props: Object) => {
    return (
      <Show actions={<Actions />} {...props}>...</Show>
    );
  };
 * ```
 */
export function MutationButton(
  props: Omit<
    ButtonProps,
    "children" | "label" | "disabled" | "onClick" | "type"
  > & {
    type: string;
    label?: string;
    resource: string;
    record: { id: string | number };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
    icon?: React.ReactElement;
    confirmationAlert?: string;
    successMessage?: string;
    errorMessage?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (response: { data: any }) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFailure?: (error?: any) => void;
  }
): React.ReactElement {
  const {
    type,
    label = type,
    resource,
    record,

    payload = {
      data: {
        id: record.id,
      },
    },

    icon,
    confirmationAlert,
    successMessage,
    onSuccess,
    onFailure,
    ...otherProps
  } = props;
  const [mutate, { loading }] = useMutation(
    {
      type,
      resource,
    },
    {
      successMessage:
        successMessage || `${resource} ${record.id} ${label} succeeded.`,
      onSuccess,
      onFailure,
    }
  );
  return (
    <Button
      label={label}
      disabled={loading}
      onClick={() => {
        // eslint-disable-next-line no-alert
        if (confirmationAlert && !window.confirm(confirmationAlert)) {
          return;
        }

        void mutate({ payload });
      }}
      {...otherProps}
    >
      {icon}
    </Button>
  );
}
