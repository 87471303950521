import { formatLegalEntityName } from "@ailo/domain-helpers";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { DetailCard } from "../../../common";
import { TenantMoneySettingsDescriptionList } from "../../../features/LegalEntity/TenantMoneySettingsDescriptionList";

export function TenancyMoneySettingsCard({ tenancy }: any) {
  const tenants = tenancy.tenants.map(
    (tenantships: { tenant: any }) => tenantships.tenant
  );

  if (tenants.length === 0) {
    return null;
  }

  return (
    <DetailCard title="Renter Money Management">
      {tenants.map((tenant: any, index: number) => (
        <Box key={tenant.id} mt={index > 0 ? 2 : 0}>
          <Box mb={1}>
            {tenants.length > 1 && (
              <Typography variant="subtitle1">
                {formatLegalEntityName(tenant)}
              </Typography>
            )}
            <Typography variant="subtitle2">Rent</Typography>
          </Box>
          <TenantMoneySettingsDescriptionList
            key={tenant.id}
            tenantAiloRN={tenant.ailoRN}
            liabilityId={tenancy.liability?.id}
          />
        </Box>
      ))}
    </DetailCard>
  );
}
