import React from "react";
import { Default } from "../../common/ui/Badge/default";
import { Success } from "../../common/ui/Badge/success";

export const StatusEnabled = (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 10,
    }}
  >
    <Success>ON</Success>
  </div>
);

export const StatusDisabled = (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 10,
    }}
  >
    <Default>OFF</Default>
  </div>
);
