import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DialogContentText, Switch } from "@material-ui/core";
import React from "react";

type Props = {
  checked: boolean;
  onChange: (event: { target: { checked: boolean } }) => void;
  text: string;
  label: string;
};

export function SwitchWithText({
  checked,
  onChange,
  text,
  label,
}: Props): React.ReactElement {
  return (
    <>
      <FormControlLabel
        aria-label={label}
        control={
          <Switch checked={checked} value={checked} onChange={onChange} />
        }
        label={label}
      />
      <DialogContentText>{text}</DialogContentText>
    </>
  );
}
