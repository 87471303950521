import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { DateInput, required, TextInput, UPDATE } from "react-admin";
import { OperationParams } from "../../api";
import { ManagementAgreement } from "../../api/ResourceName";
import { SimpleFormDialog, useMutation } from "../../common";

type UpdateManagementAgreementInput = OperationParams<
  typeof ManagementAgreement,
  typeof UPDATE
>["data"];

export function EditManagementAgreementButton({
  managementAgreement,
}: {
  managementAgreement: {
    id: string;
    startDate?: string | null;
    fixedTermEndDate?: string | null;
  };
}): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: ManagementAgreement,
    type: UPDATE,
  });
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Edit
      </Button>
      {dialogOpen && (
        <SimpleFormDialog<UpdateManagementAgreementInput>
          title="Update Management Agreement"
          submitLabel="Update"
          cancelLabel="Cancel"
          initialValues={managementAgreement}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          save={(data) => mutate({ payload: { data } })}
        >
          <TextInput source="id" disabled fullWidth validate={[required()]} />
          <DateInput source="startDate" fullWidth />
          <DateInput source="fixedTermEndDate" fullWidth />
        </SimpleFormDialog>
      )}
    </>
  );
}
