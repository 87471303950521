import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement } from "react";
import { MigrationIssue } from "./migratingManagementsEdit";

export enum HealthCheckType {
  ISSUE = "issue",
  POST_APPROVAL_TASK = "post-approval task",
}

export interface MigratingManagementHealthCheckCardProps {
  issues: MigrationIssue[];
  type: HealthCheckType;
}

export const MigratingManagementsHealthCheckCard = ({
  issues,
  type,
}: MigratingManagementHealthCheckCardProps): ReactElement => {
  return (
    <Alert
      severity={type === HealthCheckType.ISSUE ? "error" : "warning"}
      style={{ marginBottom: 10 }}
    >
      <AlertTitle>
        This management has {issues.length}{" "}
        {type === HealthCheckType.ISSUE ? "unresolved " : ""}
        {type}
        {issues.length === 1 ? "" : "s"}:
      </AlertTitle>
      <div>
        {issues.map(
          (item: MigrationIssue, idx: React.Key | null | undefined) => (
            <div style={{ marginBottom: 10 }} key={idx}>
              <div>
                <strong
                  style={{
                    color:
                      type === HealthCheckType.ISSUE ? "#D32F2F" : "#EF6C00",
                  }}
                >
                  {item.description || item.field}
                </strong>
              </div>
              {item.value ? <div>{item.value}</div> : null}
            </div>
          )
        )}
      </div>
    </Alert>
  );
};
