import { LocalStorage, localStorageKeys } from "../utils";

/**
 * @example
 * ```ts
 * const canWritePayments = useHasAuthScope("admin:payments:write");
 * ```
 */
export function useHasAuthScope(scope: string): boolean {
  const scopes = LocalStorage.getItem(localStorageKeys.authScope);

  if (!scopes) {
    return false;
  }

  return scopes.split(" ").includes(scope);
}
