import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useUpsertCrn } from "./mutations/useUpsertCrn";
import { useDeleteCrnAndDirective } from "./mutations/useDeleteCrnAndDirective";
import { useDeleteCrnDialog } from "./PaymentDirective/dialogs/useDeleteCrnDialog";
import { DeleteIcon, EditIcon } from "../../common";
import { UpsertCrnDialog } from "./UpsertCrnDialog";
import { TenantsCentrepayInfoProps } from "./TenantsCentrepayInfoProps";

export function CrnInfo({
  tenancy,
  legalEntity,
  managingEntity,
  existingCrn,
}: { existingCrn: string } & TenantsCentrepayInfoProps): React.ReactElement {
  const { upsertCrn } = useUpsertCrn(legalEntity, managingEntity);

  const deleteCrnAndDirectiveMutation = useDeleteCrnAndDirective(
    legalEntity,
    managingEntity,
    tenancy
  );

  const [showUpdateCrnDialog, setShowUpsertCrnDialog] = useState(false);
  const { showDeleteCrnDialog, deleteCrnDialog } = useDeleteCrnDialog(
    deleteCrnAndDirectiveMutation
  );

  return (
    <Box style={{ display: "flex" }}>
      <Box>CRN: {existingCrn}</Box>
      <Box
        onClick={() => setShowUpsertCrnDialog(true)}
        style={{ cursor: "pointer", marginLeft: 10 }}
      >
        <EditIcon style={{ height: 20 }} />
      </Box>
      <UpsertCrnDialog
        existingCrn={existingCrn}
        show={showUpdateCrnDialog}
        onClose={() => setShowUpsertCrnDialog(false)}
        submit={async (crn: string) => {
          await upsertCrn(crn);
        }}
      />
      {!!existingCrn && (
        <Box onClick={showDeleteCrnDialog} style={{ cursor: "pointer" }}>
          <DeleteIcon style={{ height: 20 }} />
        </Box>
      )}
      {deleteCrnDialog}
    </Box>
  );
}
