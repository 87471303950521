import { Money } from "@ailo/money";
import Big from "big.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Error,
  FormDataConsumer,
  Loading,
  maxLength,
  required,
  SimpleFormProps,
  TextField,
  TextInput,
  useGetOne,
} from "react-admin";
import { useForm } from "react-final-form"; //eslint-disable-line
import { OperationData } from "../../api";
import {
  FeeBlueprintChargeType,
  FeeType,
  TaxTreatment,
} from "../../api/graphql/types.generated";
import { ManagementFeeBlueprint } from "../../api/ResourceName";
import {
  ManagementFeeBlueprintInput,
  maxMoney,
  minMoney,
  MoneyInput,
  SimpleForm,
} from "../../common";
import { FeeAmountInput } from "./FeeAmountInput";
import { FeePercentInput } from "./FeePercentInput";

type BlueprintRecord = OperationData<typeof ManagementFeeBlueprint, "GET_ONE">;

function PrefillWithBlueprint({
  blueprintId,
  oneWeekRentAmount,
  onBlueprintLoaded,
}: {
  blueprintId: string;
  oneWeekRentAmount?: Money;
  onBlueprintLoaded: (blueprint: BlueprintRecord) => void;
}) {
  const { change } = useForm();
  const { data: blueprint, loading, error } = useGetOne<
    NonNullable<BlueprintRecord>
  >(ManagementFeeBlueprint, blueprintId);

  useEffect(() => {
    if (!blueprint?.fixedAmount) {
      return;
    }
    change(
      "amount",
      Money.from(blueprint.fixedAmount).multiply(
        blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
      )
    );
  }, [change, blueprint?.fixedAmount, blueprint?.taxTreatment]);

  useEffect(() => {
    if (
      blueprint?.oneWeekRentPercentage === undefined ||
      blueprint?.oneWeekRentPercentage === null ||
      oneWeekRentAmount === undefined
    ) {
      // TODO try using `destroyOnUnregister` to remove these values when their inputs unmount
      // instead of manually doing it here once react-admin is upgraded to 3.3.4+
      change("percentage", undefined);
      change("baseAmount", undefined);
      change("baseAmountType", undefined);
    } else {
      change(
        "percentage",
        Big(blueprint.oneWeekRentPercentage).mul(
          blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
        )
      );
      change(
        "amount",
        oneWeekRentAmount
          .multiply(blueprint.oneWeekRentPercentage)
          .multiply(blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1)
      );
      change("baseAmount", oneWeekRentAmount);
      change("baseAmountType", "OneWeekRent");
    }
  }, [
    change,
    blueprint?.oneWeekRentPercentage,
    oneWeekRentAmount,
    blueprint?.taxTreatment,
  ]);

  useEffect(() => {
    if (!blueprint?.feeBlueprint.taxCategory.id) {
      return;
    }
    change("taxCategoryName", blueprint.feeBlueprint.taxCategory.name);
    change("taxCategoryId", blueprint.feeBlueprint.taxCategory.id);
  }, [change, blueprint?.feeBlueprint.taxCategory]);

  useEffect(() => {
    if (blueprint) onBlueprintLoaded(blueprint);
  }, [blueprint, onBlueprintLoaded]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return null;
}

export function FeeForm(
  props: Omit<SimpleFormProps, "children">
): React.ReactElement {
  const create = !props.record.id;
  const { managementId, dailyRate } = props.record;
  const oneWeekRentAmountMoney = useMemo(
    () =>
      dailyRate ? Money.fromCents((+dailyRate * 7).toFixed(2)) : undefined,
    [dailyRate]
  );
  const hasRentDailyRate = dailyRate != null;

  const [chargeType, setChargeType] = useState<
    FeeBlueprintChargeType | undefined
  >(undefined);

  const onBlueprintLoaded = useCallback(
    (blueprint: BlueprintRecord) => {
      setChargeType(blueprint?.feeBlueprint.chargeType);
    },
    [setChargeType]
  );

  return (
    <SimpleForm
      toolbarOptions={{
        saveLabel: create ? "Apply New Fee" : "Save",
        deleteButton: false,
      }}
      destroyOnUnregister
      {...props}
    >
      {create && (
        <FormDataConsumer>
          {({ formData: { managementFeeBlueprintId } }) =>
            managementFeeBlueprintId && (
              <PrefillWithBlueprint
                blueprintId={managementFeeBlueprintId}
                onBlueprintLoaded={onBlueprintLoaded}
                oneWeekRentAmount={oneWeekRentAmountMoney}
              />
            )
          }
        </FormDataConsumer>
      )}

      <TextInput
        source="type"
        initialValue={FeeType.OneOffFee}
        disabled
        validate={[required()]}
      />
      {create ? (
        <ManagementFeeBlueprintInput
          source="managementFeeBlueprintId"
          disabled={!create}
          filter={
            create
              ? {
                  managementId,
                  type: FeeType.OneOffFee,
                }
              : undefined
          }
          validate={[required()]}
        />
      ) : (
        <TextField label="Blueprint Name" source="blueprint.name" />
      )}

      {create ? (
        <TextInput
          source="taxCategoryName"
          label="Tax Category"
          disabled
          validate={[required()]}
        />
      ) : (
        <TextField label="Tax Category" source="taxCategory.name" />
      )}

      {(hasRentDailyRate &&
        chargeType === FeeBlueprintChargeType.OneWeekRentPercentage) ||
      (props.record?.blueprint?.chargeType ===
        FeeBlueprintChargeType.OneWeekRentPercentage &&
        props.record?.baseAmount != null) ? (
        <>
          <FeePercentInput />
          <MoneyInput
            source="baseAmount"
            label={
              create
                ? "Weekly Rent Amount ($)"
                : "Weekly Rent Amount (when fee was created) ($)"
            }
            fullWidth
            validate={[
              required(),
              minMoney({ cents: 100 }),
              maxMoney({ cents: 1300000 }),
            ]}
            disabled
          />
        </>
      ) : null}
      <FeeAmountInput />
      <TextInput
        label="Description for the payer (optional)"
        source="description"
        validate={[maxLength(60)]}
        fullWidth
      />
    </SimpleForm>
  );
}
