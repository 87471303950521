import { AiloRN, services } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { Money } from "@ailo/money";
import React from "react";
import { isPresent } from "ts-is-present";
import { Address, BillStatus } from "../../api/graphql/types.generated";
import { BillClientCreateInput } from "../../api/resources/Bill/billClient";
import { Create } from "../../common";
import { BillForm } from "./BillForm";
import {
  PayeeOption,
  PayerOption,
} from "./BillForm/InputComponents/PaymentOptionHooks";
import { PaymentMethodOption } from "./BillForm/useGetPaymentMethodOptions";

export type CreateBillFormData = {
  management: string;
  managingEntityAilorn: string;
  organisationAilorn: AiloRN;
  property: { id: string; address: Address };
  payer: PayerOption["value"];
  payee: PayeeOption["value"];
  supplierId: string;
  paymentReferenceId: string;
  dueDate: string;
  issueDate: string;
  taxCategory: string;
  amount: { cents: number };
  taxAutoCalculated: boolean;
  taxAmount: { cents: number };
  invoiceNumber: string;
  description: string;
  paymentMethod: PaymentMethodOption;
  files?: { files?: { id: string }[] };
  managementFeeSchedule: { id: string; percent: number };
  applyManagementFee: boolean;
  internal: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BillCreateProps = any;

const BillCreate = (props: BillCreateProps): React.ReactElement => {
  return (
    <Create
      {...props}
      transform={transform}
      redirect="list"
      style={{ width: "fit-content" }}
    >
      <BillForm redirect="list" />
    </Create>
  );
};

function transform(
  formData: CreateBillFormData
): BillClientCreateInput["data"] {
  return {
    paymentReferenceId: formData.paymentReferenceId,
    description: formData.description,
    toBePaidBy: formData.payer.ailorn,
    toBePaidByLegalEntities:
      formData.payer.type === "management"
        ? formData.payer.investorsAilorns
        : formData.payer.type === "tenancy"
        ? formData.payer.tenantsAilorns
        : [],
    supplierId: formData.supplierId,
    payeeAiloRN: formData.payee.ailorn,
    dueDateV2: formData.dueDate,
    issueDateV2: formData.issueDate,
    invoiceNumber: formData.invoiceNumber,
    status: BillStatus.Approved,
    taxCategoryId: formData.taxCategory,
    taxAutoCalculated: formData.taxAutoCalculated,
    lineItems: [
      {
        amount: formData.amount,
        taxAmount: {
          cents: formData.taxAutoCalculated
            ? Money.fromCents(formData.amount.cents).divide(11).cents
            : 0,
        },
        description: formData.description,
        isTax: false,
      },
      !formData.taxAutoCalculated
        ? {
            amount: { cents: formData.taxAmount?.cents ?? 0 },
            taxAmount: { cents: formData.taxAmount?.cents ?? 0 },
            description: "GST",
            isTax: true,
          }
        : undefined,
    ].filter(isPresent),
    attachmentsFileIds: formData.files?.files?.map(({ id }) => id),
    relatingToManagement: AiloRN.of(
      services.PropertyManagement.management,
      formData.management
    ).toString(),
    relatingToOrganisation: formData.organisationAilorn.toString(),
    managingEntityAilorn: formData.managingEntityAilorn,
    propertyAddress: formatAddress(formData.property.address, {
      format: "street, suburb",
    }),
    applyManagementFee: formData.applyManagementFee,
    internal: formData.internal || formData.payee.type !== "supplier",
  };
}

export { BillCreate };
