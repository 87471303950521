import React, { ReactElement } from "react";
import {
  AutocompleteInput,
  FileField,
  FileInput,
  required,
  useNotify,
} from "react-admin";
import { ReferenceInput, SimpleFormDialog } from "../../../../common";
import { addSupplierWithPaymentMethods } from "./addSupplierWithPaymentMethods";

const requiredValidate = [required()];

export function BulkUploadSuppliersDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement {
  const notify = useNotify();
  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title="Upload CSV"
      submitLabel="Upload"
      save={async (formData) => {
        const promise = new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            resolve(reader.result as string)
          );
          reader.addEventListener("error", reject);
          reader.readAsText(formData.data.data.rawFile);
        });

        const csvString = await promise;

        try {
          await addSupplierWithPaymentMethods(
            csvString,
            formData.organisationId
          );
        } catch (error) {
          notify(`Could not create suppliers. ${error}`);
          return;
        }

        notify("Suppliers created");
        onClose();
      }}
      onClose={onClose}
    >
      <ReferenceInput
        alwaysOn
        source="organisationId"
        reference="Customers"
        filterToQuery={(searchTerm: string) => ({
          searchTerm,
        })}
        validate={requiredValidate}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <FileInput
        source="data.data"
        label="CSV file"
        accept="text/csv"
        validate={requiredValidate}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleFormDialog>
  );
}
