import gql from "graphql-tag";
import { DELETE, CREATE } from "react-admin";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { Tenantship } from "../../graphql/types.generated";

function tenantshipsClient({ raFetchType, params }: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case DELETE:
      return {
        query: gql`
          mutation removeTenantship($input: RemoveTenantshipInput!) {
            removeTenantship(input: $input) {
              tenantship {
                tenancyId
                tenant {
                  id
                }
              }
            }
          }
        `,
        variables: {
          input: params.input,
        },
        parseResponse: (response: {
          data: {
            removeTenantship: string;
          };
        }) => {
          return {
            data: response.data.removeTenantship,
          };
        },
      };

    case CREATE:
      return {
        query: gql`
          mutation createTenantship($input: CreateTenantshipInput!) {
            createTenantship(input: $input) {
              tenantship {
                tenancyId
                tenant {
                  id
                }
              }
            }
          }
        `,
        variables: {
          input: params.data,
        },
        parseResponse: (response: {
          data: {
            createTenantship: Tenantship;
          };
        }) => {
          return {
            data: { id: "123", ...response.data.createTenantship },
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { tenantshipsClient };
