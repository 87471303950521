import { MoneyInterface } from "@ailo/money";
import { CardContent } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";
import { Button, FunctionField, Link, SimpleShowLayout } from "react-admin";
import { AccountOwner, StatementProgress, Wallet } from "../../api";
import { DetailCard } from "../../common";
import { AmountField, LinkField } from "../../common/fields";
import { WalletOwnerTransactionList } from "../WalletEntry";

export const WalletCard = ({
  title,
  action,
  wallet,
  walletOwnerAiloRN,
  showTransactions,
  isAgency = false,
}: {
  title?: string | null | undefined;
  action?: React.ReactNode;
  wallet: {
    id: string;
    name: string;
    availableBalance: MoneyInterface;
    totalBalance: MoneyInterface;
  };
  walletOwnerAiloRN?: string | null | undefined;
  showTransactions?: boolean;
  isAgency?: boolean;
}) => {
  const actions = (
    <div style={{ display: "flex", flexDirection: "row" }}>{action}</div>
  );

  return (
    <DetailCard title={title} action={actions}>
      <CardContent style={{ marginTop: -30, marginLeft: -14, padding: 0 }}>
        {walletOwnerAiloRN && (
          <Button
            component={Link}
            to={`/${AccountOwner}/${walletOwnerAiloRN}/show`}
            label="Account Owner View"
            style={{ margin: 14 }}
            variant="outlined"
          />
        )}
        <SimpleShowLayout record={wallet}>
          <LinkField label="ID" sourceResource={Wallet} />
          <FunctionField
            label="Available Balance ($)"
            render={(record: any) => {
              return <AmountField record={record.availableBalance} />;
            }}
          />
          <FunctionField
            label="Total Balance ($)"
            render={(record: any) => {
              return <AmountField record={record.totalBalance} />;
            }}
          />
          <>
            {walletOwnerAiloRN && showTransactions && (
              <WalletOwnerTransactionList
                ownerAiloRN={walletOwnerAiloRN}
                style={{ maxHeight: 500 }}
              />
            )}
          </>
          <>
            {isAgency && (
              <>
                <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                <Button
                  component={Link}
                  to={{
                    pathname: `/${StatementProgress}/list`,
                    search: `?filter={"statementSubject": "${walletOwnerAiloRN}", "type": "AgencyTransfer"}`,
                  }}
                  label="View All Agency Statements"
                />
              </>
            )}
          </>
        </SimpleShowLayout>
      </CardContent>
    </DetailCard>
  );
};
