/* eslint-disable @typescript-eslint/no-explicit-any */
import currency from "currency.js";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformManagementForRequest(
  setupObject: Record<string, any>
) {
  if (!setupObject) {
    return {};
  }
  const {
    propertyId,
    property,
    managingEntityId,
    managementStartDate,
    commissionPercent,
    owners,
    tenancyStartDate,
    agreementStartDate,
    agreementFixedTermEndDate,
    tenants,
    rentAmount,
    rentPeriod,
    rentEffectiveDate,
    createRent,
    createTenancy,
  } = setupObject;
  return {
    propertyId,
    property,
    managingEntityId,
    management: {
      startDate: managementStartDate,
      percent: Number(
        currency(commissionPercent, {
          precision: 4,
        })
          .divide(100)
          .toString()
      ),
      owners: owners.map((owner: { id: any; shares: string }) => ({
        ownerId: owner.id,
        sharesOwned: Number.parseInt(owner.shares, 10),
      })),
    },
    tenancy: createTenancy
      ? {
          startDate: tenancyStartDate,
          tenancyAgreement: {
            startDate: agreementStartDate,
            fixedTermEndDate: agreementFixedTermEndDate,
          },
          rents: createRent
            ? [
                {
                  effectiveDate: rentEffectiveDate,
                  amountInCents: String(currency(rentAmount).intValue),
                  period: rentPeriod,
                  category: "Support",
                },
              ]
            : null,
          tenants: tenants.map((t: any) => ({
            tenantId: t,
          })),
        }
      : null,
  };
}
