import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { isEmpty } from "lodash";
import React from "react";
import { ManagementFolio, OperationData } from "../../../api";
import { ConsumerPropertiesListItem } from "../../Property";

type Management = NonNullable<
  OperationData<typeof ManagementFolio, "GET_ONE">
>["managements"]["items"][number];

const useStyles = makeStyles(() => ({
  root: {
    overflow: "auto",
    maxHeight: 380,
  },
}));

export function ManagementFolioPropertiesList({
  managements,
}: {
  managements: Management[];
}): React.ReactElement {
  const classes = useStyles();

  if (isEmpty(managements)) {
    return <>There are no properties</>;
  }

  const properties = managements.map((management) => {
    return {
      id: management.property.id,
      type: "Investor",
      property: management.property,
      rent: management.mostRecentTenancy?.currentRent,
    };
  });

  return (
    <List className={classes.root}>
      {properties.map((item) => (
        <ConsumerPropertiesListItem key={item.id} item={item} />
      ))}
    </List>
  );
}
