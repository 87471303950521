import { formatAddress } from "@ailo/domain-helpers";
import React from "react";
import { CreateProps, useGetOne } from "react-admin";
import { useHistory } from "react-router-dom";
import { Management } from "..";
import { ManagementQuery } from "../../api/resources/Management/managementsClient.generated";
import { Create } from "../../common";
import { ManagementFeeBlueprintForm } from "./form";

export const ManagementFeeBlueprintCreate = ({
  location,
  ...props
}: CreateProps): React.ReactElement => {
  const history = useHistory();

  const { managementId, organisationId } = (location?.state ?? {}) as {
    managementId: string;
    organisationId: string;
  };

  const management = useGetOne<NonNullable<ManagementQuery["management"]>>(
    Management,
    managementId
  );

  const address = management.data?.property.address
    ? formatAddress(management.data.property.address, {
        format: "street, suburb",
      })
    : undefined;

  const title = `Create Management Fee Blueprint – ${address}`;

  return (
    <Create {...props} title={title}>
      <ManagementFeeBlueprintForm
        redirect={history.goBack}
        managementId={managementId}
        organisationId={organisationId}
      />
    </Create>
  );
};
