/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import { AiloRN } from "@ailo/ailorn";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient } from "apollo-client";
import csv from "csvtojson";
import gql from "graphql-tag";
import { QuartzPlanFrequency } from "../../../../api/graphql/types.generated";
import {
  buildClient,
  ManagementCSVHeader,
  ManagementCSVRow,
  validateManagementCSVRow,
} from "../common";

import {
  CancelAutoWithdrawPlanForBulkScriptMutation,
  CancelAutoWithdrawPlanForBulkScriptMutationVariables,
  CreateAutoWithdrawPlanForBulkScriptMutation,
  CreateAutoWithdrawPlanForBulkScriptMutationVariables,
  GetManagementAutoWithdrawPlanQuery,
  GetManagementAutoWithdrawPlanQueryVariables,
} from "./bulkUpdateAutoWithdrawPlan.generated";

export const updateAutoWithdrawPlansForManagements = async ({
  csvString,
  frequency,
  anniversary,
  anniversaryDaysOfMonth,
  lastDayOfTheMonth,
  startDate,
}: {
  csvString: string;
  frequency: "daily" | "weekly" | "fortnightly" | "monthly";
  anniversary?: number;
  anniversaryDaysOfMonth?: number[];
  lastDayOfTheMonth?: boolean;
  startDate: string;
}): Promise<void> => {
  const client = buildClient();

  let isCSVValid = true;

  await csv()
    .fromString(csvString)
    .subscribe(
      (row: ManagementCSVRow) => {
        try {
          validateManagementCSVRow(row);
        } catch (error) {
          console.error(error);
          isCSVValid = false;
        }
      },
      (err) => console.error(err)
    );

  if (!isCSVValid) {
    throw new Error(
      `The CSV file has invalid data, please fix the errors above.`
    );
  }

  const csvRows: ManagementCSVRow[] = await csv().fromString(csvString);
  const failures: { managementId: string; error: unknown }[] = [];

  for (let index = 0; index < csvRows.length; index++) {
    const managementId = csvRows[index][ManagementCSVHeader.managementId];
    console.group(`Processing management ${managementId}`);

    try {
      console.log("Getting current autowithdraw plan");
      const {
        managementWalletId,
        autoWithdrawPlan,
      } = await getManagementAutoWithdrawPlan(managementId, client);

      console.log(
        `Cancelling current autowithdraw plan ${autoWithdrawPlan.id}`
      );
      await cancelAutoTransferPlan(autoWithdrawPlan.id, client);

      console.log("Creating new autowithdraw plan");
      await createAutoWithdrawPlan({
        walletId: managementWalletId,
        paymentMethodDestinations:
          autoWithdrawPlan.details.paymentMethodDestinations,
        payerLegalEntityId: autoWithdrawPlan.owner,
        frequency,
        anniversary,
        anniversaryDaysOfMonth,
        lastDayOfTheMonth,
        startDate,
        userFacingDescription: autoWithdrawPlan.details.userFacingDescription,
        setAsideAmountCents: autoWithdrawPlan.details.setAsideAmount
          ? autoWithdrawPlan.details.setAsideAmount.cents
          : null,
        client,
      });

      console.log("Finished updating autowithdraw plan");
    } catch (error: unknown) {
      failures.push({
        managementId,
        error: error instanceof Error ? error.message : error,
      });
    }

    console.groupEnd();
  }

  if (failures.length > 0) {
    console.log("Rows that failed to be processed successfully:");
    console.log(failures);
    throw new Error(
      `Autowithdraw plan failed to update for ${failures.length} management(s). Please check the console log for more information.`
    );
  }
};

async function getManagementAutoWithdrawPlan(
  managementId: string,
  client: ApolloClient<unknown>
) {
  const query = gql`
    query getManagementAutoWithdrawPlan($walletOwnerReference: AiloRN!) {
      walletByWalletOwnerReference(
        walletOwnerReference: $walletOwnerReference
      ) {
        id
        autoWithdrawPlans(disableOwnerFilter: true, enabled: true) {
          items {
            id
            enabled
            owner
            details {
              startDate
              frequency
              nextFireTime
              paymentMethodDestinations {
                paymentMethodId
                share
              }
              anniversaryDaysOfMonth
              isLastDayOfTheMonth
              userFacingDescription
              setAsideAmount {
                cents
              }
            }
          }
        }
      }
    }
  `;

  const result = await client.query<
    GetManagementAutoWithdrawPlanQuery,
    GetManagementAutoWithdrawPlanQueryVariables
  >({
    query,
    variables: {
      walletOwnerReference: AiloRN.of(
        "propertymanagement:management",
        managementId
      ).toString(),
    },
  });

  const { data, errors } = result;

  const managementWalletId = data.walletByWalletOwnerReference?.id;
  const autoWithdrawPlans =
    data.walletByWalletOwnerReference?.autoWithdrawPlans?.items ?? [];

  if (errors) {
    throw new Error(
      `Failed to get autowithdraw plan for management: ${managementId}`
    );
  }
  if (!managementWalletId) {
    throw new Error(
      `Could not find management wallet for management: ${managementId}`
    );
  }

  if (autoWithdrawPlans.length !== 1) {
    throw new Error(
      `Expected to find 1 autowithdraw plan for management: ${managementId} but found ${autoWithdrawPlans.length} plan(s)`
    );
  }

  return {
    managementWalletId,
    autoWithdrawPlan: autoWithdrawPlans[0],
  };
}

async function createAutoWithdrawPlan({
  walletId,
  paymentMethodDestinations,
  payerLegalEntityId,
  frequency,
  anniversary,
  anniversaryDaysOfMonth,
  lastDayOfTheMonth,
  startDate,
  userFacingDescription,
  setAsideAmountCents,
  client,
}: {
  walletId: string;
  paymentMethodDestinations: { paymentMethodId: string; share: number }[];
  payerLegalEntityId: string;
  frequency: "daily" | "weekly" | "fortnightly" | "monthly";
  anniversary?: number;
  anniversaryDaysOfMonth?: number[];
  lastDayOfTheMonth?: boolean;
  startDate: string;
  userFacingDescription?: string | null;
  setAsideAmountCents?: number | null;
  client: ApolloClient<unknown>;
}) {
  const mutation = gql`
    mutation createAutoWithdrawPlanForBulkScript(
      $input: CreateAutoWithdrawPlanInputV2!
    ) {
      createAutoWithdrawPlanV2(createAutoWithdrawPlanInput: $input) {
        id
      }
    }
  `;

  const result = await client.mutate<
    CreateAutoWithdrawPlanForBulkScriptMutation,
    CreateAutoWithdrawPlanForBulkScriptMutationVariables
  >({
    mutation,
    variables: {
      input: {
        walletId,
        paymentMethodDestinations: paymentMethodDestinations.map(
          (destination) => ({
            paymentMethodId: destination.paymentMethodId,
            share: destination.share,
          })
        ),
        payerLegalEntityId,
        frequency: frequency as QuartzPlanFrequency,
        anniversary,
        anniversaryDaysOfMonth,
        lastDayOfTheMonth,
        startDate,
        userFacingDescription,
        setAsideAmount: setAsideAmountCents
          ? { cents: setAsideAmountCents }
          : null,
      },
    },
  });
  return result;
}

async function cancelAutoTransferPlan(
  planId: string,
  client: ApolloClient<unknown>
) {
  const mutation = gql`
    mutation cancelAutoWithdrawPlanForBulkScript($autoWithdrawPlanId: ID!) {
      cancelAutoWithdrawPlan(
        cancelAutoWithdrawInput: { autoWithdrawPlanId: $autoWithdrawPlanId }
      ) {
        id
      }
    }
  `;

  const result = await client.mutate<
    CancelAutoWithdrawPlanForBulkScriptMutation,
    CancelAutoWithdrawPlanForBulkScriptMutationVariables
  >({ mutation, variables: { autoWithdrawPlanId: planId } });
  return result;
}
