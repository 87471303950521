import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

export function DescriptionList({
  items,
  descriptionStyle,
  ...props
}: {
  items: Array<{
    key?: string | null | undefined;
    name?: string | React.ReactNode | null;
    description?: string | React.ReactNode | null;
  }>;
  descriptionStyle?: React.CSSProperties;
}) {
  return (
    <Box width="100%" {...props}>
      {items.map((item, index) => (
        <Box key={item.key || index} mt={index > 0 ? 1 : 0}>
          <Grid container>
            <Grid item sm={4}>
              {typeof item.name === "string" ? (
                <Typography variant="body2" color="textSecondary">
                  {item.name}
                </Typography>
              ) : (
                item.name
              )}
            </Grid>
            <Grid item sm={8} style={{ ...descriptionStyle }}>
              {typeof item.description === "string" ? (
                <Typography
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {item.description}
                </Typography>
              ) : (
                item.description
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
