import { AuthService } from "../AuthService";

const auth = new AuthService();
const authProvider = {
  login: () => {
    // TODO why isn't auth.login() called here?
    // Describe it.
    return Promise.reject();
  },
  logout: () => {
    return auth.logout();
  },
  checkAuth: () => {
    if (!auth.hasAccessToken()) {
      auth.saveLocation();
      return Promise.reject({
        redirectTo: "/login",
      });
    }

    const isTokenExpired = auth.isTokenExpired();

    if (isTokenExpired) {
      auth.showLoginPopup();
      return Promise.resolve();
    }

    return Promise.resolve();
  },
  checkError: (params: Record<string, any>) => {
    const { status } = params;

    if (status === 401 || status === 403) {
      auth.logout();
      return Promise.reject(`status error ${status}`);
    }

    return Promise.resolve();
  },
  getPermissions: (params: Record<string, any>) => Promise.resolve(),
};
export { authProvider };
