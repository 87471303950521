import { OperationData, WalletEntry } from "../../../api";
import { getTaxCategoryName } from "./getTaxCategoryName";

const getWithdrawTxDescription = (transaction: {
  isReversal: any;
  amount: { cents: number };
}) => {
  if (transaction.isReversal) {
    return "Funds Reversed";
  }

  return transaction.amount.cents > 0 ? "Funds Deposited" : "Funds Withdrawn";
};

const getBillOrFeeTxDescription = (
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'Transaction'.
  transaction: Transaction,
  type?: "managementfolio"
): string => {
  const feeName = transaction.liability?.recurringFee?.name;
  const taxCategoryName = transaction.liability?.taxCategory
    ? getTaxCategoryName(transaction.liability.taxCategory)
    : undefined;
  const header =
    feeName ||
    taxCategoryName ||
    (transaction.liability?.category === "BILL" ? "Bill" : "Fee");

  if (transaction.isReversal) {
    return `${header} Payment Reversed`;
  }

  if (type === "managementfolio") {
    return `${header} ${
      transaction.amount.cents > 0 ? "Payment Received" : "Payment Transferred"
    }`;
  }

  return `${header} ${
    transaction.amount.cents > 0 ? "Payment Received" : "Paid"
  }`;
};

const getRentTxDescription = (transaction: {
  liability: { taxCategory: string };
  isReversal: any;
  amount: { cents: number };
}) => {
  const taxCategoryName = transaction.liability?.taxCategory
    ? getTaxCategoryName(transaction.liability.taxCategory)
    : undefined;
  const header = taxCategoryName || "Rent";

  if (transaction.isReversal) {
    return `${header} Payment Reversed`;
  }

  return `${header} ${transaction.amount.cents > 0 ? "Received" : "Paid"}`;
};

const getDepositTxDescription = (transaction: {
  isReversal: any;
  amount: { cents: number };
}) => {
  const header = "Initial Payment";
  if (transaction.isReversal) {
    return `${header} Reversed`;
  }

  return `${header} ${transaction.amount.cents > 0 ? "Received" : "Paid"}`;
};

const getTxDescription = (
  transaction: OperationData<
    typeof WalletEntry,
    "GET_LIST_BY_OWNER_ARN"
  >["items"][0],
  type = "managementfolio"
): string => {
  const incoming = transaction.amount.cents > 0;

  if (transaction.isTopUpFee) {
    if (transaction.isReversal) {
      return "Service Fee Reversed";
    }
    return "Service Fee";
  }

  if (incoming && transaction.paymentMethod) {
    if (transaction.isReversal) {
      return "Funds Reversed";
    }
    return "Funds Received";
  }

  if (!transaction.liability?.category) {
    return getWithdrawTxDescription(transaction as any);
  }

  if (["BILL", "FEE"].includes(transaction.liability?.category)) {
    return getBillOrFeeTxDescription(transaction, type as any);
  }

  if (transaction.liability?.category === "RENT") {
    return getRentTxDescription(transaction as any);
  }

  if (transaction.liability?.category === "DEPOSIT") {
    return getDepositTxDescription(transaction as any);
  }

  return "";
};

export { getTxDescription };
