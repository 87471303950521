import React from "react";
import { Button } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { EditIcon } from "../../common";
import { PropertyChangeTeamDialogue } from "./PropertyChangeTeamDialogue";

const PropertyChangeTeamButton = (props: {
  managingEntityOrganisationId: string;
  managementReferenceList: AiloRN[];
}): React.ReactElement => {
  const { managingEntityOrganisationId, managementReferenceList } = props;
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button onClick={onClick}>
        <EditIcon />
      </Button>
      <PropertyChangeTeamDialogue
        open={open}
        onClose={onClose}
        managingEntityOrganisationId={managingEntityOrganisationId}
        managementReferenceList={managementReferenceList}
      />
    </div>
  );
};

export { PropertyChangeTeamButton };
