import { Money, MoneyInterface } from "@ailo/money";

/**
 * Returns error if the value is smaller than `min`.
 */
export function minMoney(
  min: MoneyInterface,
  message?: string | null | undefined
): (value: MoneyInterface | undefined) => string | undefined {
  return (value: MoneyInterface | undefined): string | undefined => {
    if (value && Money.from(value).cents < min.cents) {
      return (
        message ??
        `Value must be larger or equal to ${Money.from(min).format()}`
      );
    }

    return undefined;
  };
}

/**
 * Returns error if the value is larger than `max`.
 */
export function maxMoney(
  max: MoneyInterface,
  message?: string | undefined
): (value: MoneyInterface | undefined) => string | undefined {
  return (value: MoneyInterface | undefined): string | undefined => {
    if (value && Money.from(value).cents > max.cents) {
      return (
        message ??
        `Value must be smaller or equal to ${Money.from(max).format()}`
      );
    }

    return undefined;
  };
}
