import React, { Dispatch } from "react";
import { SimpleShowLayout, useQuery } from "react-admin";
import { Wallet } from "../../../../api";
import { LinkField, MoneyField } from "../../../../common";

export const BondAccountBalance = ({
  walletOwnerRef,
  totalClaimed,
  setIsDisabled,
}: {
  walletOwnerRef: string;
  totalClaimed: number;
  setIsDisabled?: Dispatch<boolean>;
}): React.ReactElement => {
  const {
    data: wallet,
  }: {
    data?: {
      id: string;
      availableBalance: { cents: number };
      outstandingBalance: { cents: number };
    };
  } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: walletOwnerRef,
    },
  });

  if (!wallet) {
    return <></>;
  }

  const enabled =
    totalClaimed <= wallet.availableBalance.cents && totalClaimed > 0;
  if (setIsDisabled) {
    setIsDisabled(!enabled);
  }

  return (
    <SimpleShowLayout record={wallet}>
      <LinkField
        label="Bond account wallet"
        sourceResource="Wallet"
        source="id"
      />
      <MoneyField label="Available Balance ($)" source="availableBalance" />
      <MoneyField label="Total Balance ($)" source="totalBalance" />
    </SimpleShowLayout>
  );
};
