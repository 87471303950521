import { GET_LIST } from "react-admin";
import gql from "graphql-tag";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
} from "../../common";
import { BankReconciliationDetailReportQuery } from "./reconciliationDetailsClient.generated";

type ListParams = {
  pagination: PaginationParams;
  filter: {
    afterDate?: string;
    beforeDate?: string;
    categoryId?: string;
  };
};

export const ReconciliationDetailClientDefinition = {
  [GET_LIST]: (params: ListParams) => {
    return {
      query: gql`
        query bankReconciliationDetailReport(
          $filter: Filter!
          $cursor: BidirectionalPageCursor
        ) {
          bankReconciliationDetailReport(filter: $filter, cursor: $cursor) {
            pageInfo {
              total
              nextCursor
              hasMore
            }
            items {
              id
              categoryId
              paymentType
              transactionType
              total {
                cents
              }
              fees {
                cents
              }
              businessTransactionId
              paymentTransactionId
            }
          }
        }
      `,
      variables: {
        cursor: {
          ...buildPaginationVariables(params.pagination),
        },
        filter: {
          dateRange: {
            afterDate: params.filter.afterDate,
            beforeDate: params.filter.beforeDate,
          },
          search: [{ key: "categoryId", value: params.filter.categoryId }],
        },
      },
      parseResponse(
        response: GraphQLResponse<BankReconciliationDetailReportQuery>
      ) {
        const items = response.data.bankReconciliationDetailReport?.items;
        const pageInfo = response.data.bankReconciliationDetailReport?.pageInfo;

        return {
          data: items,
          total: pageInfo?.total,
          nextCursor: pageInfo?.nextCursor,
          hasMore: pageInfo?.hasMore,
        };
      },
    };
  },
};

export const reconciliationDetailsClient = createClientFromDefinition(
  ReconciliationDetailClientDefinition
);
