import React from "react";
import { makeStyles } from "@material-ui/core";
import { InvestorChip, TenantChip } from "../ui/chips";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
});

const ConsumerTypesField = ({ record, source }: any) => {
  const organisation = record[source];
  const classes = useStyles();
  const access = organisation?.availableFeatures
    ?.filter((f: { id: string }) =>
      ["InvestorAccess", "TenantAccess"].includes(f.id)
    )
    .map((f: { id: any }) => f.id);
  return access ? (
    <span className={classes.main}>
      {access.map((s: string) =>
        s === "InvestorAccess" ? (
          <InvestorChip key={s} />
        ) : (
          <TenantChip key={s} />
        )
      )}
    </span>
  ) : null;
};

export { ConsumerTypesField };
