/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useInput } from "react-admin";
import { Colors } from "../../../common/ui/colors";
import { formatMoney } from "../../../common/ui/deprecated";

const WalletInput = ({ wallets, source }: any): React.ReactElement => {
  const {
    input: { value, onChange },
  } = useInput({
    source,
  });
  return (
    <List>
      {wallets?.map((wallet: any) => (
        <WalletListItem
          key={wallet.id}
          wallet={wallet}
          selected={wallet.id === value}
          onClick={() => onChange(wallet.id)}
        />
      ))}
    </List>
  );
};

WalletInput.defaultProps = {
  addLabel: true,
};

const WalletListItem = ({
  wallet,
  selected,
  onClick,
}: any): React.ReactElement => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={onClick}
      >
        <Box
          style={{
            marginRight: 40,
          }}
        >
          <Typography>{wallet.name}</Typography>
          <Typography
            style={{
              color: Colors.TEXT.DARK.SECONDARY,
              fontSize: "0.8125rem",
            }}
          >
            {`Available balance: ${formatMoney(wallet.availableBalance)}`}
          </Typography>
        </Box>
        {selected ? <Chip label="selected" /> : null}
      </Box>
      <Divider
        style={{
          marginTop: 12,
        }}
      />
    </>
  );
};

export { WalletInput };
