import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Colors } from "../colors";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
    backgroundColor: Colors.SMOKE,
  },
}));
export const CompanyAvatar = ({ name, style }: any) => {
  const classes = useStyles();
  const initial = getInitial(name);
  return (
    <Tooltip title={name}>
      <Avatar alt={name} className={classes.avatar} style={style}>
        {initial}
      </Avatar>
    </Tooltip>
  );
};

function getInitial(name?: string) {
  return name ? name.slice(0, 2).toUpperCase() || "" : "";
}
