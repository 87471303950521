import { Box, Fade, FormControlLabel, Switch } from "@material-ui/core";
import React, { ReactElement, useState } from "react";

const CSV_TEXT = `management_id
7a3fc135-9219-4483-8da7-d077f55879a1
e6b5d86c-30be-4cbf-b05d-c612687de5db`;

export function ExampleManagementsCSV(): ReactElement {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box style={{ height: 180 }}>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Show Example CSV"
      />
      <Box style={{ display: "flex" }}>
        <Fade in={checked}>
          <pre>{CSV_TEXT}</pre>
        </Fade>
      </Box>
    </Box>
  );
}
