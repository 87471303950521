import React, { ReactElement } from "react";
import { Alert } from "@material-ui/lab";

type Props = {
  content: string;
  style?: React.CSSProperties;
};

const containerStyle = {
  borderRadius: "12px",
};

export const WarningContainer = ({ content, style }: Props): ReactElement => {
  return (
    <Alert
      severity="error"
      style={{
        ...containerStyle,
        ...style,
      }}
    >
      {content}
    </Alert>
  );
};
