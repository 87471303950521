/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import {
  FormDataConsumer,
  Labeled,
  TextInput,
  required,
  useMutation,
  useNotify,
  useRefresh,
  Validator,
  RadioButtonGroupInput,
  SelectInput,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { MoneyInterface } from "@ailo/money";
import { validate as validateUUID } from "uuid";
import {
  ErrorDialog,
  minMoney,
  MoneyInput,
  SimpleFormDialog,
} from "../../common";
import { WalletTransfer } from "../../api";
import {
  descriptionHelperText,
  maxUserFacingDescLength,
  walletStyles,
} from "./helpers";

type FormData = {
  fromWalletId: string;
  toWalletId: string;
  amount: MoneyInterface;
  userFacingDescriptionType: string;
  predefinedUserFacingDescription: string;
  customUserFacingDescription: string;
  internalNotes: string;
};

const useDirectWalletTransferBalance = ({
  onSuccess,
}: {
  onSuccess?(): void;
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    {
      type: "transfer_funds",
      resource: WalletTransfer,
    },
    {
      onSuccess: ({ data }) => {
        const error = data?.error;
        if (error) {
          notify(`Failed to perform direct transfer. Error: ${error}`);
        } else {
          refresh();
          notify(`Direct transfer has been successful`);
          onSuccess?.();
        }
      },
      onFailure: (error) => {
        notify(`Failed to perform direct transfer. Error: ${error}`);
      },
    }
  );
};

const validUUID: Validator = (value: string) => {
  if (!validateUUID(value)) {
    return "Not a valid UUID";
  }
  return undefined;
};

type Props = {
  onClose(): void;
  fromWalletId: string;
  open?: boolean;
};
export const DirectWalletTransferDialog = ({
  open,
  onClose,
  fromWalletId,
}: Props): React.ReactElement => {
  const [transferToWallet, { error }] = useDirectWalletTransferBalance({
    onSuccess: onClose,
  });
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;
  const classes = walletStyles();

  const submit = async (formData: FormData) => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `Are you sure you would like to do a direct transfer of ${formData.amount.cents} cents from current wallet ${formData.fromWalletId} to wallet ${formData.toWalletId}? `
      )
    ) {
      return;
    }
    await transferToWallet({
      payload: {
        amountInCents: formData.amount.cents,
        fromWalletId: formData.fromWalletId,
        toWalletId: formData.toWalletId,
        description: formData.internalNotes,
        userFacingDescription:
          formData.userFacingDescriptionType === "predefined"
            ? formData.predefinedUserFacingDescription
            : formData.customUserFacingDescription,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Direct transfer failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        fromWalletId,
        userFacingDescriptionType: "predefined",
      }}
      title="Direct Wallet Transfer"
      open={open}
      validate={(values: FormData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: any = {};
        if (!values.fromWalletId) {
          errors.fromWalletId = ["A source wallet is required"];
        }
        if (!values.toWalletId) {
          errors.toWalletId = ["A destination wallet is required"];
        }
        return errors;
      }}
      submitLabel="Transfer"
      save={submit}
      onClose={onClose}
    >
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <Alert
                severity="warning"
                className={classes.directWalletTransfer}
                style={{ marginBottom: 16 }}
              >
                Only do this if you know what you are doing! Doing this is going
                to move money from the current wallet to the nominated one. Make
                sure you understand the implications and this action has been
                authorised.
              </Alert>
              <Labeled label="Wallet to transfer to" fullWidth>
                <TextInput
                  source="toWalletId"
                  validate={[required(), validUUID].filter(Boolean)}
                />
              </Labeled>
              <Labeled label="How much? (in $)" fullWidth>
                <MoneyInput
                  source="amount"
                  validate={[required(), minMoney({ cents: 1 })].filter(
                    Boolean
                  )}
                />
              </Labeled>

              <Labeled label="Transfer details">
                <Alert severity="info" className={classes.transferDetails}>
                  The description will be visible by the investor on the
                  ownership statement.
                </Alert>
              </Labeled>

              <RadioButtonGroupInput
                label="Description type"
                source="userFacingDescriptionType"
                validate={[required()].filter(Boolean)}
                choices={[
                  {
                    id: "predefined",
                    name: "Predefined description",
                  },
                  { id: "custom", name: "Custom description" },
                ]}
              />

              <FormDataConsumer>
                {() =>
                  formData.userFacingDescriptionType === "predefined" ? (
                    <SelectInput
                      label="Description"
                      source="predefinedUserFacingDescription"
                      validate={[required()].filter(Boolean)}
                      choices={[
                        {
                          id: "Incorrect payment refund",
                          name: "Incorrect payment refund",
                        },
                        { id: "Fee refund", name: "Fee refund" },
                      ]}
                      helperText="This is visible by consumers"
                      fullWidth
                    />
                  ) : (
                    <TextInput
                      label="Description"
                      source="customUserFacingDescription"
                      validate={[required(), maxUserFacingDescLength()].filter(
                        Boolean
                      )}
                      helperText={descriptionHelperText(
                        formData.customUserFacingDescription
                      )}
                      fullWidth
                    />
                  )
                }
              </FormDataConsumer>

              <TextInput
                source="internalNotes"
                validate={[required()].filter(Boolean)}
                helperText="Include ticket number if possible eg: ZD-12345 or L3S-12345"
                fullWidth
              />
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleFormDialog>
  );
};
