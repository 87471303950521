import {
  formatAddress,
  formatLegalEntityName,
  formatNames,
} from "@ailo/domain-helpers";
import { Link, ListProps } from "@material-ui/core";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import React, { ReactElement } from "react";
import {
  Button,
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  FilterProps,
  FunctionField,
  TextField,
} from "react-admin";
import { isPresent } from "ts-is-present";
import { OperationData } from "../../api";
import { StatementProgress } from "../../api/ResourceName";
import { StatementSubjectFragmentFragment } from "../../api/resources/StatementProgress/statementProgressClient.generated";
import { IdField, List, StatementTypeInput } from "../../common";

type StatementProgressRecord = OperationData<
  typeof StatementProgress,
  "GET_LIST"
>[number];

function Filters(props: Omit<FilterProps, "children">) {
  return (
    <Filter {...props}>
      <StatementTypeInput source="type" alwaysOn autoFocus />
    </Filter>
  );
}

export function StatementProgressList(props: ListProps): ReactElement {
  return (
    <List
      {...props}
      title="Statements"
      filters={<Filters />}
      bulkActionButtons={false}
      actions={<ExportButton />}
    >
      <Datagrid rowClick="">
        <FunctionField<StatementProgressRecord>
          label="Customer name"
          render={(record) => parseStatementSubject(record?.subject)}
        />
        <FunctionField<StatementProgressRecord>
          label="Wallet"
          render={(record) => {
            const subject = record?.subject;
            return subject?.__typename === "LegalEntityCompanion"
              ? subject?.legalEntity.__typename === "Company" &&
                subject?.legalEntity.organisation.orgType === "Agency"
                ? "Agency"
                : subject?.legalEntity.__typename
              : subject?.__typename;
          }}
        />
        <TextField source="type" sortable={false} />
        <DateField
          label="Statement starts"
          source="startDate"
          sortable={false}
        />
        <DateField label="Statement ends" source="endDate" sortable={false} />
        <FunctionField<StatementProgressRecord>
          label="Actions"
          render={(record) =>
            record?.fileUrl ? (
              <Link
                href={record.fileUrl}
                target="_blank"
                style={{ paddingLeft: 0, paddingRight: 20 }}
              >
                <Button label="Download" style={{ paddingLeft: 0 }}>
                  <ArchiveOutlinedIcon />
                </Button>
              </Link>
            ) : null
          }
        />
        <DateField label="Created on" source="createdAt" sortable={false} />
        <IdField label="Statement ID" source="statementId" sortable={false} />
        <IdField label="File ID" source="fileId" sortable={false} />
        <IdField label="Progress ID" source="id" sortable={false} />
      </Datagrid>
    </List>
  );
}

function parseStatementSubject(
  subject?: StatementSubjectFragmentFragment["subject"] | null
): string | undefined {
  return subject?.__typename === "Management"
    ? formatAddress(subject.property.address)
    : subject?.__typename === "Tenancy"
    ? formatNames(
        subject?.tenants
          ?.map((tenantship) =>
            tenantship?.tenant
              ? formatLegalEntityName(tenantship.tenant)
              : undefined
          )
          .filter(isPresent) ?? []
      )
    : subject?.__typename === "LegalEntityCompanion" && subject?.legalEntity
    ? formatLegalEntityName(subject.legalEntity)
    : "";
}
