import { FormHelperText } from "@material-ui/core";
import React, { useMemo, useEffect } from "react";
import { CheckboxGroupInput, useInput } from "react-admin";
import { PaymentMethodType } from "../../../api/graphql/types.generated";

const DEFAULT_CHECKBOX_VALUE = ["checked"] as const;

export function UpdateBillsCheckbox({
  showUpdateBillsOption,
  formData,
}: {
  showUpdateBillsOption?: boolean;
  formData?: { methodType: Uncapitalize<PaymentMethodType> };
}): React.ReactElement | null {
  const newPaymentMethodIsBpay = formData?.methodType === "bPay";

  const defaultValue = useMemo(
    () => (newPaymentMethodIsBpay ? [] : DEFAULT_CHECKBOX_VALUE),
    [newPaymentMethodIsBpay]
  );

  const {
    input: { onChange },
  } = useInput({ source: "updateBills", defaultValue });

  useEffect(
    () => (newPaymentMethodIsBpay ? onChange(defaultValue) : undefined),
    [defaultValue, onChange, newPaymentMethodIsBpay]
  );

  const disabled = newPaymentMethodIsBpay;

  const helperText = newPaymentMethodIsBpay
    ? "Bills cannot be updated when the new payment method uses Bpay since the CRN of the customer cannot be inferred. If you need to update bills as well contact L3 for help."
    : undefined;

  return showUpdateBillsOption ? (
    <>
      <CheckboxGroupInput
        defaultValue={defaultValue}
        disabled={disabled}
        choices={[
          {
            id: "checked",
            name: "Update all unpaid bills",
          },
        ]}
        color="primary"
        source="updateBills"
        style={{ marginBottom: helperText ? 0 : undefined }}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  ) : null;
}
