import { FunctionField } from "react-admin";
import { get } from "lodash";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { BondStatusBadge } from "./BondStatusBadge";
import { GetTenancyBondDetailsQuery } from "../../../../api/resources/TenancyBond/tenancyBondsClient.generated";

export function BondStatusField({ source, ...props }: any): React.ReactElement {
  return (
    <FunctionField
      {...props}
      label="Status"
      render={(data: GetTenancyBondDetailsQuery["tenancy"]) => {
        const bond = get(data, source);
        return (
          <Tooltip title={bond?.status}>
            <BondStatusBadge
              status={bond?.status}
              failureReason={bond?.failureReason}
            />
          </Tooltip>
        );
      }}
    />
  );
}

BondStatusField.defaultProps = {
  addLabel: true,
};
