import gql from "graphql-tag";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";

const CREATE_OR_UPDATE_AILO_FEES_WAIVER = "create_update_ailo_fees_waiver";
const LIST_ACTIVE_AILO_FEES_WAIVER = "list_active_ailo_fees_waiver";

function paymentMethodAiloFeesClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case LIST_ACTIVE_AILO_FEES_WAIVER:
      return {
        query: gql`
          query listPaymentMethodAiloFeesWaiverByOwner($owner: AiloRN!) {
            listPaymentMethodAiloFeesWaiverByOwner(owner: $owner) {
              id
              methodType
              methodCategory
              feeName
              feeBasisPoints
              feeFlatCents
              appliesToId
              filterName
              filterType
              filterEntryEffectiveFromInclusive
              filterEntryEffectiveToInclusive
              filterNote
            }
          }
        `,
        variables: {
          owner: params.filter.owner,
        },
        parseResponse: (response: {
          data: { listPaymentMethodAiloFeesWaiverByOwner: any };
        }) => {
          const { listPaymentMethodAiloFeesWaiverByOwner } = response.data;
          return {
            data: listPaymentMethodAiloFeesWaiverByOwner,
          };
        },
      };

    case CREATE_OR_UPDATE_AILO_FEES_WAIVER:
      return {
        query: gql`
          mutation createOrUpdateAiloFeesWaiver(
            $owner: AiloRN!
            $filterEntries: [PaymentMethodAiloFeeFilterEntryInput]
            $notes: String
          ) {
            createOrUpdatePaymentMethodAiloFeeWaiver(
              feeWaiverInput: {
                ownerLegalEntity: $owner
                filterEntries: $filterEntries
                notes: $notes
              }
            ) {
              id
              methodType
              methodCategory
              feeName
              feeBasisPoints
              feeFlatCents
              appliesToId
              filterName
              filterType
              filterEntryEffectiveFromInclusive
              filterEntryEffectiveToInclusive
              filterNote
            }
          }
        `,
        variables: {
          owner: params.owner.toString(),
          notes: params.notes,
          filterEntries: params.filterEntries,
        },
        parseResponse: (response: { data: { addBankAccount: any } }) => ({
          data: response.data.addBankAccount,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { paymentMethodAiloFeesClient };
