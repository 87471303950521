import { useInput } from "ra-core";
import React, { useCallback } from "react";
import { FileUpload } from "../../../../common";

export function AttachmentsInput(): React.ReactElement {
  const {
    input: { value, onChange },
  } = useInput({ source: "files" });

  const onFieldChanged = useCallback(
    (e) => {
      const fieldName = e.target.name;
      const fieldValue = e.target.value;
      onChange({ ...value, [fieldName]: fieldValue });
    },
    [onChange, value]
  );

  return (
    <>
      <FileUpload onChange={onFieldChanged} />
    </>
  );
}
