import { AiloRN } from "@ailo/ailorn";
import gql from "graphql-tag";
import { GET_LIST, GET_ONE } from "react-admin";
import {
  buildPaginatedResponseFromPaginatedList,
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
  specifyDefinition,
  specifyOperation,
} from "../../common";
import {
  ManagementFolioIdsForOwnersQuery,
  ManagementFolioIdsForOwnersQueryVariables,
  ManagementFolioQuery,
  ManagementFolioQueryVariables,
  ManagementFoliosQuery,
  ManagementFoliosQueryVariables,
} from "./managementFolioClient.generated";

export const GET_MANAGEMENT_FOLIO_IDS_FOR_OWNERS =
  "GET_MANAGEMENT_FOLIO_IDS_FOR_OWNERS";

const addressDetailsFragment = gql`
  fragment AddressDetails on Address {
    unitStreetNumber
    streetName
    suburb
    state
    country
    postcode
  }
`;

const legalEntityBasicDetails = gql`
  fragment LegalEntityBasicDetails on LegalEntity {
    __typename
    id
    ailoRN
    ... on Person {
      firstName
      lastName
      emailAddress
      phoneNo
      photo {
        id
        url
        thumbnailUrl
      }
    }
    ... on Company {
      registeredEntityName
    }
  }
`;

const managementFolioDetailsFragment = gql`
  fragment ManagementFolioDetails on ManagementFolio {
    __typename
    id
    ailorn
    managingEntity {
      id
      ailoRN
      organisation {
        id
        name
      }
    }
    owners {
      isPrimary
      owner {
        ...LegalEntityBasicDetails
      }
    }
    wallet {
      id
      availableBalance {
        cents
      }
      totalBalance {
        cents
      }
      owner {
        id
        dueFeeAmount {
          cents
        }
      }
    }
    managements(paginationParams: { pageSize: 1000 }) {
      pageInfo {
        total
      }
      items {
        id
        ailorn: ailoRN
        property {
          id
          address {
            ...AddressDetails
          }
        }
      }
    }
  }
  ${addressDetailsFragment}
  ${legalEntityBasicDetails}
`;

const paymentMethodDetailsFragment = gql`
  fragment PaymentMethodDetails on PaymentMethod {
    __typename
    id
    ... on BankAccount {
      accountName
      accountNumber
      bsb
    }
    ... on BPay {
      billerCode
    }
    ... on CreditCard {
      type
      number
    }
  }
`;

const getList = specifyOperation(
  (params: {
    filter: {
      idOrAilorn?: string;
      managingEntityId?: string;
      ownerId?: string;
    };
    pagination: PaginationParams;
  }) => {
    const filterIdOrAilorn = params.filter.idOrAilorn;
    const isUUID = /^[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ABab][\da-f]{3}-[\da-f]{12}$/.test(
      filterIdOrAilorn ?? ""
    );
    const filterId = AiloRN.isAiloRNString(filterIdOrAilorn)
      ? AiloRN.from(filterIdOrAilorn).internalId
      : isUUID
      ? filterIdOrAilorn
      : undefined;

    return {
      query: gql`
        query managementFolios(
          $id: [ID!]
          $managingEntityId: [ID!]
          $ownerId: [ID!]
          $paginationParams: PaginationParams
        ) {
          managementFolios(
            id: $id
            managingEntityId: $managingEntityId
            ownerId: $ownerId
            paginationParams: $paginationParams
          ) {
            pageInfo {
              total
              nextCursor
              hasMore
            }
            items {
              ...ManagementFolioDetails
            }
          }
        }
        ${managementFolioDetailsFragment}
        ${legalEntityBasicDetails}
      `,
      variables: <ManagementFoliosQueryVariables>{
        id: filterId ? [filterId] : null,
        managingEntityId: params.filter.managingEntityId
          ? [params.filter.managingEntityId]
          : null,
        ownerId: params.filter.ownerId ? [params.filter.ownerId] : null,
        paginationParams: {
          ...buildPaginationVariables(params.pagination),
        },
      },
      parseResponse: (response: GraphQLResponse<ManagementFoliosQuery>) => {
        const { data, ...rest } = buildPaginatedResponseFromPaginatedList(
          response.data.managementFolios!
        );
        return {
          data,
          ...rest,
        };
      },
    };
  }
);

const getOne = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      query managementFolio($id: ID!) {
        managementFolio(id: $id) {
          ...ManagementFolioDetails
          managingEntity {
            id
            ailoRN
            registeredEntityName
            organisationId
            organisation {
              id
              name
            }
            owner {
              ...LegalEntityBasicDetails
            }
          }
          owners {
            isPrimary
            owner {
              ...LegalEntityBasicDetails
            }
          }
          wallet {
            id
            name
            availableBalance {
              cents
            }
            totalBalance {
              cents
            }
            idempotencyKey

            autoWithdrawPlans(enabled: true, disableOwnerFilter: true) {
              items {
                id
                enabled
                owner
                details {
                  frequency
                  anniversary
                  anniversaryDaysOfMonth
                  startDate
                  endDate
                  nextFireTime
                  setAsideAmount {
                    cents
                  }
                  paymentMethodDestinations {
                    paymentMethod {
                      ...PaymentMethodDetails
                    }
                    share
                  }
                  paymentMethod {
                    ...PaymentMethodDetails
                  }
                  isLastDayOfTheMonth
                }
              }
            }

            autoPayLiabilityStatus {
              enabled
              payerId
              maximumPaymentAmount {
                cents
              }
              paymentMethod {
                ...PaymentMethodDetails
              }
            }
          }

          managements(paginationParams: { pageSize: 1000 }) {
            items {
              id
              ailorn: ailoRN
              createdAt
              isDraft
              property {
                id
                address {
                  ...AddressDetails
                }
              }
              mostRecentTenancy {
                id
                currentRent {
                  id
                  amountInCents
                  period
                }
              }
              team {
                id
                name
              }
            }
          }

          formerManagements(paginationParams: { pageSize: 1000 }) {
            items {
              id
              startDatetime
              endDatetime
              management {
                id
                ailorn: ailoRN
                property {
                  id
                  address {
                    ...AddressDetails
                  }
                }
              }
            }
          }
        }
      }
      ${managementFolioDetailsFragment}
      ${addressDetailsFragment}
      ${legalEntityBasicDetails}
      ${paymentMethodDetailsFragment}
    `,
    variables: <ManagementFolioQueryVariables>{
      id: params.id,
    },
    parseResponse: (response: GraphQLResponse<ManagementFolioQuery>) => {
      return {
        data: response.data.managementFolio,
      };
    },
  };
});

const getManagementFolioIdsForOwners = specifyOperation(
  (params: { ids: string[] }) => {
    return {
      query: gql`
        query managementFolioIdsForOwners($ownerIds: [ID!]) {
          managementFolios(
            ownerId: $ownerIds
            paginationParams: { pageSize: 1000 }
          ) {
            items {
              id
            }
          }
        }
      `,
      variables: <ManagementFolioIdsForOwnersQueryVariables>{
        ownerIds: params.ids,
      },
      parseResponse: (
        response: GraphQLResponse<ManagementFolioIdsForOwnersQuery>
      ) => {
        return {
          data: (response.data.managementFolios?.items ?? []).map(
            (folio) => folio.id
          ),
        };
      },
    };
  }
);

export const ManagementFolioClientDefinition = specifyDefinition({
  [GET_ONE]: getOne,
  [GET_LIST]: getList,
  [GET_MANAGEMENT_FOLIO_IDS_FOR_OWNERS]: getManagementFolioIdsForOwners,
});

export const managementFolioClient = createClientFromDefinition(
  ManagementFolioClientDefinition
);
