import { stringify } from "query-string";
import React from "react";
import { Button, Link } from "react-admin";
import { Bill } from "../../../../api/ResourceName";
import { DetailCard } from "../../../../common/ui/cards";
import { BillList } from "./BillList";

export const BillsCard = ({
  managementId,
  payerAilorn,
}: {
  payerAilorn: string;
  managementId: string;
}) => {
  return (
    <DetailCard
      title="Bills"
      contents={<BillList payerAilorn={payerAilorn} />}
      action={
        <Button
          component={Link}
          to={{
            pathname: `/${Bill}/list`,
            search: stringify({
              filter: stringify({ managementId }),
            }),
          }}
          label="View All Bills"
        />
      }
    />
  );
};
