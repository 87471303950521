import { AiloRN, services } from "@ailo/ailorn";
import { trim } from "lodash";
import { GET_ONE } from "react-admin";
import { useMemo } from "react";
import { isPresent } from "ts-is-present";
import { formatLegalEntityName } from "@ailo/domain-helpers";
import { Management } from "../../../../..";
import { useQuery } from "../../../../../../common/api/useQuery";
import { getTenancyDescription } from "../getTenancyDescription";

export type PayerOption =
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "management";
        ailorn: string;
        investorsAilorns: string[];
      };
    }
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "tenancy";
        ailorn: string;
        tenantsAilorns: string[];
        description?: string;
      };
    }
  | {
      name: string;
      id: string;
      value: {
        name: string;
        type: "agency";
        ailorn: string;
        agencyAilorns: string[];
      };
    }
  | {
      name: string;
      id: string;
      value: {
        type: "supplier";
        ailorn: string;
      };
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Error = any;

type PayerOptions = {
  options: PayerOption[];
  error?: Error;
  loading: boolean;
};

type LegalEntity = Parameters<typeof formatLegalEntityName>["0"];

export function useGetPayerOptions(managementId?: string): PayerOptions {
  const {
    data: managementData,
    loading: managementLoading,
    error: managementError,
  } = useQuery(
    {
      type: GET_ONE,
      resource: Management,
      payload: {
        id: managementId,
      },
    },
    { enabled: !!managementId }
  );

  return useMemo((): PayerOptions => {
    const options: PayerOption[] = [];

    if (!managementId) {
      return { options, loading: false };
    }

    if (managementLoading || !managementData) {
      return {
        options,
        error: managementError,
        loading: true,
      };
    }

    const ownerNames =
      managementData.owners
        ?.map((owner) => owner?.owner)
        ?.map(
          (owner) =>
            owner?.__typename && formatLegalEntityName(owner as LegalEntity)
        )
        ?.filter(isPresent)
        ?.map(trim)
        ?.reduce(
          (names, name, index) => `${names}${index ? ", " : ""}${name}`,
          ""
        ) || "Owners";

    const ownerOption: PayerOption = {
      name: ownerNames,
      id: managementId,
      value: {
        name: ownerNames,
        type: "management",
        investorsAilorns: (managementData.owners ?? [])
          .map((owner) => owner?.owner?.ailoRN)
          .filter(isPresent),
        ailorn: AiloRN.of(
          services.PropertyManagement.management,
          managementId
        ).toString(),
      },
    };

    const agencyName =
      managementData.managingEntity?.registeredEntityName ?? "Agency";

    const agencyOption: PayerOption = {
      name: agencyName,
      id: managementData.managingEntity!.id,
      value: {
        name: agencyName,
        type: "agency",
        ailorn: managementData.managingEntity!.ailoRN,
        agencyAilorns: [managementData.managingEntity!.ailoRN],
      },
    };

    const tenancyOptions = (managementData.allTenancies ?? []).map(
      (tenancy): PayerOption => {
        const name = (tenancy?.tenants ?? [])
          .map((tenant) => tenant?.tenant)
          .filter(isPresent)
          .reduce((names, tenant, index) => {
            return `${names}${index ? ", " : ""}${
              tenant.__typename
                ? formatLegalEntityName(tenant as LegalEntity)
                : ""
            }`;
          }, "");

        return {
          name,
          id: tenancy!.id,
          value: {
            name,
            description: getTenancyDescription(tenancy),
            type: "tenancy",
            ailorn: tenancy!.ailoRN,
            tenantsAilorns: (tenancy!.tenants ?? [])
              .map((tenant) => tenant?.tenant?.ailoRN)
              .filter(isPresent),
          },
        };
      }
    );

    options.push(agencyOption);
    options.push(ownerOption);
    options.push(...tenancyOptions);

    return {
      options,
      error: managementError,
      loading: true,
    };
  }, [managementId, managementLoading, managementData, managementError]);
}
