import { AiloRN } from "@ailo/ailorn";
import { CREATE } from "react-admin";
import React, { ReactElement } from "react";
import { Box } from "@material-ui/core";
import { MutationSwitch } from "../../../../common";
import { OfflinePaymentProcessingEnabled } from "../../../../api";

export interface Params {
  failureNotification: string;
  successNotification: string;
  title: string;
  enabled: boolean;
  content: string;
}

export function AutoRentProcessingSwitch({
  dialogParams,
  tenancy,
}: {
  dialogParams: Params;
  tenancy: AiloRN;
}): ReactElement {
  return (
    <Box flex={1} alignItems="center">
      Automatic Processing:
      <MutationSwitch
        checked={!dialogParams.enabled}
        mutationParams={{
          type: CREATE,
          resource: OfflinePaymentProcessingEnabled,
          payload: {
            input: {
              tenancy: tenancy.toString(),
              enabled: dialogParams.enabled,
            },
          },
        }}
        confirmation={{
          title: dialogParams.title,
          content: dialogParams.content,
        }}
        successNotification={dialogParams.successNotification}
        failureNotification={dialogParams.failureNotification}
        disabled={false}
      />
    </Box>
  );
}
