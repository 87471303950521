import { Box, Button, ButtonProps, Typography } from "@material-ui/core";
import React from "react";
import { Colors } from "../colors";

export const DialogControlButton = ({
  onClick,
  children,
  icon,
  disabled,
  ...props
}: ButtonProps & {
  onClick: () => void;
  children: string | React.ReactElement;
  disabled?: boolean;
  icon?: React.ReactElement;
}): React.ReactElement => (
  <Button
    style={{
      textTransform: "none",
    }}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    <Box p={1} display="flex">
      {icon}
      <Typography
        style={{
          color: disabled ? Colors.TEXT.DARK.SECONDARY : Colors.AILO_RED,
          fontSize: "1rem",
          fontWeight: 530,
          paddingTop: 1,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Button>
);
