import React from "react";
import { Box } from "@material-ui/core";
import { useFetchExistingCrn } from "./queries/useFetchExistingCrn";
import { CrnInfo } from "./CrnInfo";
import { TenantsCentrepayInfoProps } from "./TenantsCentrepayInfoProps";
import { PaymentDirective } from "./PaymentDirective";

export function TenantsCentrepayInfo({
  tenancy,
  legalEntity,
  managingEntity,
}: TenantsCentrepayInfoProps): React.ReactElement {
  const existingCrn = useFetchExistingCrn(legalEntity, managingEntity);

  return (
    <Box>
      <CrnInfo
        existingCrn={existingCrn}
        tenancy={tenancy}
        legalEntity={legalEntity}
        managingEntity={managingEntity}
      />
      <PaymentDirective
        existingCrn={existingCrn}
        tenancy={tenancy}
        legalEntity={legalEntity}
        managingEntity={managingEntity}
      />
    </Box>
  );
}
