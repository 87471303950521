import moment from "moment-timezone";
import React from "react";
import { OperationData } from "../../../../api";
import { IdField, ListItemView } from "../../../../common";
import { Liability } from "../../../../resourceViews";
import { getLiabilityEntryDescription } from "../../utils";
import { TransactionDate } from "../transactionDate";
import { RentHistoryDetails } from "./rentHistoryDetails";

const timeZone = "Australia/Sydney";

const TenancyLiabilityEntryListItem = ({
  liabilityEntry,
}: {
  liabilityEntry: OperationData<
    typeof Liability,
    "get_entries"
  >["items"][number];
}) => {
  const header = getLiabilityEntryDescription(liabilityEntry);
  const subHeader = <IdField record={liabilityEntry} source="id" />;
  const date = moment.tz(liabilityEntry.effectiveAt, timeZone);
  return (
    <ListItemView
      header={header}
      subHeader={subHeader}
      leftComponent={<TransactionDate date={date} />}
      rightComponent={<RentHistoryDetails liabilityEntry={liabilityEntry} />}
    />
  );
};

export { TenancyLiabilityEntryListItem };
