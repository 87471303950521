import { envVars } from "../../envVars";

export function getAuth0UserUrl(userAuth0Id: string): string | undefined {
  const tenantSlug =
    envVars.ailoApiEnv === "prod" ? "ailo" : `ailo-${envVars.ailoApiEnv}`;
  return `https://manage.auth0.com/dashboard/au/${tenantSlug}/users/${b64EncodeUnicode(
    userAuth0Id
  )}`;
}

function b64EncodeUnicode(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([\dA-F]{2})/g, (match, p1) => {
      return String.fromCharCode(Number(`0x${p1}`));
    })
  );
}
