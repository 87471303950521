import React from "react";
import { GET_LIST, useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { VirtualAccountData } from "../../../../common";
import { OfflinePaymentAccount } from "../../../../api/ResourceName";
import { CreateOfflinePaymentAccountButton } from "./CreateOfflinePaymentAccountButton";
import { OfflinePaymentAccountDetails } from "./OfflinePaymentAccountDetails";

interface Params {
  tenancy: AiloRN;
  agency: AiloRN;
}

const OfflinePaymentAccountCards = ({
  tenancy,
  agency,
}: Params): React.ReactElement | null => {
  const {
    data,
  }: {
    data?: VirtualAccountData[];
  } = useQuery({
    type: GET_LIST,
    resource: OfflinePaymentAccount,
    payload: {
      entity: tenancy.toString(),
    },
  });
  if (!data) {
    return null;
  }
  if (data.length === 0) {
    return <CreateOfflinePaymentAccountButton tenancy={tenancy} />;
  }

  return (
    <>
      {data.map(
        (offlinePaymentAccountWallet: VirtualAccountData, index: number) => {
          return (
            <OfflinePaymentAccountDetails
              key={index}
              data={offlinePaymentAccountWallet}
              agency={agency}
              tenancy={tenancy}
              index={index}
              includeIndexInTitle={data.length !== 1}
            />
          );
        }
      )}
    </>
  );
};
export { OfflinePaymentAccountCards };
