import React from "react";
import { AddNewButton } from "../../../common";
import { AddPaymentMethodDialog } from "./AddPaymentMethodDialog";

type AddPaymentMethodButtonProps = Pick<
  React.ComponentProps<typeof AddPaymentMethodDialog>,
  | "walletOwnerAiloRN"
  | "managingOrganisationAiloRN"
  | "showUpdateBillsOption"
  | "allowedMethodTypes"
> & {
  confirmLabel?: string;
};

const AddPaymentMethodButton = ({
  walletOwnerAiloRN,
  managingOrganisationAiloRN,
  showUpdateBillsOption = false,
  confirmLabel = `Add payment method`,
  allowedMethodTypes,
}: AddPaymentMethodButtonProps) => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AddNewButton onClick={onClick}>{confirmLabel}</AddNewButton>
      <AddPaymentMethodDialog
        open={open}
        walletOwnerAiloRN={walletOwnerAiloRN}
        managingOrganisationAiloRN={managingOrganisationAiloRN}
        allowedMethodTypes={allowedMethodTypes}
        showUpdateBillsOption={showUpdateBillsOption}
        onClose={onClose}
      />
    </div>
  );
};

export { AddPaymentMethodButton };
