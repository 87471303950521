import { Money } from "@ailo/money";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import Big from "big.js";
import moment from "moment-timezone";
import React from "react";
import { Button, Error, Link, Loading, useGetOne } from "react-admin";
import { OperationData } from "../../../../api";
import { FeeStatus, FeeType } from "../../../../api/graphql/types.generated";
import { Fee } from "../../../../api/ResourceName";
import { ListItem } from "../../../../common";
import { ArchiveFeeButton, FeeStatusBadge } from "../../../../features/Fee";

type FeeRecord = NonNullable<OperationData<typeof Fee, "GET_ONE">>;

export function FeesChargedListItem({
  feeId,
}: {
  feeId: string;
}): React.ReactElement {
  const { data: fee, loading, error } = useGetOne<FeeRecord>(Fee, feeId);

  if (loading) {
    return <Loading />;
  }

  if (error || !fee) {
    return <Error error={error} />;
  }

  const amountDescription = `${Money.from(fee.amount).format()}${
    fee.percentage != null ? ` (${new Big(fee.percentage).mul(100)}%)` : ""
  }`;

  return (
    <ListItem
      title={
        <Box display="flex" fontSize={14}>
          <Link to={`/${Fee}/${fee.id}/show`}>{fee.blueprint?.name}</Link>
          <Box pl={2} ml="auto">
            <FeeStatusBadge status={fee.status} />
          </Box>
        </Box>
      }
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box fontSize={14}>
          {fee.status === FeeStatus.Due
            ? "On rent payment; Before auto transfer"
            : null}
        </Box>

        <Box fontSize={14} color="black">
          {amountDescription}
        </Box>
      </Box>
      {fee.blueprint && (
        <Box fontSize={14} color="black">
          {fee.description}
        </Box>
      )}
      <Box fontSize={14}>
        {moment(fee.createdAt).format("YYYY-MM-DD HH:mm:ss")}
      </Box>

      <Box mt={1}>
        {fee.status === "Due" && fee.type === FeeType.OneOffFee && (
          <>
            <Button
              component={Link}
              to={{
                pathname: `/${Fee}/${fee.id}`,
              }}
              label="Edit"
            >
              <EditIcon />
            </Button>
            <ArchiveFeeButton fee={fee} />
          </>
        )}
      </Box>
    </ListItem>
  );
}
