import { CircularProgress, Container } from "@material-ui/core";
import React from "react";
import { ArrayField, Button, Datagrid, DateField } from "react-admin";
import { OperationData, RecurringOwing } from "../../../api";
import { MoneyField } from "../../../common";

const ChargeCycleList = ({
  items,
  hasMore,
  loadMore,
}: {
  items?: OperationData<typeof RecurringOwing, "LIST_CHARGE_CYCLES">["items"];
  hasMore?: boolean;
  loadMore?(): void;
}): React.ReactElement => {
  return (
    <>
      <ArrayField source="items" record={{ id: "", items }}>
        <Datagrid>
          <DateField source="startDate" />
          <DateField source="endDate" />
          <MoneyField source="amount" />
          <MoneyField source="taxAmount" />
        </Datagrid>
      </ArrayField>
      {hasMore && (
        <Button
          label="Load more"
          onClick={loadMore}
          variant="contained"
          fullWidth
        />
      )}
    </>
  );
};

ChargeCycleList.Loading = () => (
  <Container {...{ align: "center" }}>
    <CircularProgress />
  </Container>
);
ChargeCycleList.Empty = () => (
  <Container {...{ align: "center" }}>Nothing yet</Container>
);
ChargeCycleList.Error = () => (
  <Container {...{ align: "center" }}>
    There was an error displaying the list
  </Container>
);

export { ChargeCycleList };
