import { Divider, Tab, Tabs, Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Warning } from "@material-ui/icons";
import IconPublish from "@material-ui/icons/Publish";
import IconDoneAll from "@material-ui/icons/DoneAll";
import IconThumbDown from "@material-ui/icons/ThumbDown";
import IconThumbUp from "@material-ui/icons/ThumbUp";
import moment from "moment-timezone";
import React, {
  ChangeEvent,
  cloneElement,
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
} from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Datagrid,
  DatagridProps,
  DateField,
  ExportButton,
  Filter,
  FilterProps,
  FunctionField,
  ListProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify,
  useRedirect,
  useUnselectAll,
} from "react-admin";
import { Management, MigratingManagement, OperationData } from "../../api";
import { MigratingManagementStatus } from "../../api/graphql/types.generated";
import {
  APPROVE,
  GET_MANY_AILO_MANAGEMENT_IDS,
  MIGRATE,
  PUBLISH,
  UNAPPROVE,
} from "../../api/resources/MigratingManagement";
import {
  LinkField,
  List,
  MoneyField,
  ReferenceInput,
  useMutation,
} from "../../common";
import { formatPersonName } from "../../common/utils";
import {
  InviteOwnersButton,
  InviteTenantsButton,
  useBulkInviteOwners,
  useBulkInviteTenants,
} from "../../features/User";
import {
  MigratingManagementsEdit,
  MigrationIssue,
} from "./migratingManagementsEdit";
import {
  HealthCheckType,
  MigratingManagementsHealthCheckCard,
} from "./migratingManagementsHealthCheckCard";
import { UploadMigrationCSVButton } from "./uploadMigrationCSV";

type MigratingManagementRecord = NonNullable<
  OperationData<typeof MigratingManagement, "GET_LIST">
>[number];

interface MigratingManagementConsumer {
  firstName: string;
  lastName?: string;
  companyName?: string;
}

const SearchFilter = (
  props: JSX.IntrinsicAttributes &
    Omit<FilterProps, "children"> & { children?: React.ReactNode }
) => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      source="organisationId"
      reference="Customers"
      filterToQuery={(searchTerm: string) => ({
        searchTerm,
      })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Search" source="searchTerm" alwaysOn autoFocus />
    <TextInput label="Batch Reference" source="batchRef" />
    <TextInput label="Portfolio" source="portfolioName" />
    <BooleanInput label="With Errors" source="withErrors" defaultValue />
    <BooleanInput
      label="With Post-Approval Tasks"
      source="withPostApprovalTasks"
      defaultValue
    />
    <BooleanInput
      label="Failed Migration"
      source="withFailedMigration"
      defaultValue
    />
  </Filter>
);

const formatManagementPropertyAddress = (
  record?: MigratingManagementRecord
): string | null => {
  if (!record) return null;
  const { body } = record;

  if (body.management && body.management.property && body.management.property) {
    const { property } = body.management;
    return [
      property.unitStreetNumber,
      property.streetName,
      property.suburb,
      property.postcode,
      property.country,
    ].join(" ");
  }

  return null;
};

const formatApprovedByName = (record?: MigratingManagementRecord) => {
  if (!record?.approvedBy) return null;
  const { approvedBy } = record;

  if (approvedBy.__typename !== "User") return null;
  if (!approvedBy?.person) return null;

  const { firstName, lastName } = approvedBy.person;
  return `${firstName} ${lastName ?? ""}`;
};

const formatStatusChangedByName = (record?: MigratingManagementRecord) => {
  if (!record?.statusChangedBy) return null;
  const { statusChangedBy } = record;

  if (statusChangedBy.__typename !== "User") return null;
  if (!statusChangedBy?.person) return null;

  const { firstName, lastName } = statusChangedBy.person;
  return `${firstName} ${lastName ?? ""}`;
};

const Consumer = ({ consumer }: { consumer: MigratingManagementConsumer }) => {
  const label = consumer.companyName || formatPersonName(consumer);
  return <Chip label={label} />;
};

const Owners = ({ record }: { record: MigratingManagementRecord }) => {
  if (!record) return null;
  const { body } = record;

  if (body.management && body.management.owners) {
    const { owners } = body.management;
    return owners.map((o: MigratingManagementConsumer) => (
      <Consumer key={JSON.stringify(o)} consumer={o} />
    ));
  }

  return null;
};

const Tenants = ({ record }: { record: MigratingManagementRecord }) => {
  if (!record || record.migrateAsVacant) return null;
  const { body } = record;

  if (
    body.management &&
    body.management.tenancy &&
    body.management.tenancy.tenants
  ) {
    const { tenants } = body.management.tenancy;
    return tenants.map((t: MigratingManagementConsumer) => (
      <Consumer key={JSON.stringify(t)} consumer={t} />
    ));
  }

  return null;
};

const MigrationBulkActionButtons = () => {
  const { selectedIds, filterValues } = useListContext();
  const selectedStringIds = selectedIds.map(String);

  const notify = useNotify();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();
  const [
    migrateManagements,
    { loading: migrateManagementsLoading },
  ] = useMutation(
    {
      type: MIGRATE,
      resource: MigratingManagement,
    },
    {
      successMessage: "Management migrations commenced",
      onSuccess: () => {
        redirect(`/${MigratingManagement}`);
        unselectAll(MigratingManagement);
      },
      onFailure: (error) =>
        notify(`Unable to migrate managements: ${error.message}`, "error"),
    }
  );
  const [
    publishMigratingManagements,
    { loading: publishMigratingManagementsLoading },
  ] = useMutation(
    {
      type: PUBLISH,
      resource: MigratingManagement,
    },
    {
      successMessage: "Management migrations & publication commenced",
      onSuccess: () => {
        redirect(`/${MigratingManagement}`);
        unselectAll(MigratingManagement);
      },
      onFailure: (error) =>
        notify(
          `Unable to migrate & publish managements: ${error.message}`,
          "error"
        ),
    }
  );
  const [
    approveManagements,
    { loading: approveManagementsLoading },
  ] = useMutation(
    {
      type: APPROVE,
      resource: MigratingManagement,
    },
    {
      successMessage: "Managements have been marked as approved",
      onSuccess: () => {
        redirect(`/${MigratingManagement}`);
        unselectAll(MigratingManagement);
      },
      onFailure: (error) =>
        notify(`Unable to approve managements: ${error.message}`, "error"),
    }
  );
  const [
    unapproveManagements,
    { loading: unapproveManagementsLoading },
  ] = useMutation(
    {
      type: UNAPPROVE,
      resource: MigratingManagement,
    },
    {
      successMessage: "Managements have been marked as unapproved",
      onSuccess: () => {
        redirect(`/${MigratingManagement}`);
        unselectAll(MigratingManagement);
      },
      onFailure: (error) =>
        notify(`Unable to unapprove managements: ${error.message}`, "error"),
    }
  );
  const { status } = filterValues;
  return (
    <>
      {status === "IMPORTED" && (
        <>
          <Button
            onClick={() => {
              void approveManagements({
                payload: { ids: selectedStringIds },
              });
            }}
            disabled={approveManagementsLoading}
            label="APPROVE"
          >
            <IconThumbUp />
          </Button>
          <Button
            onClick={() => {
              void migrateManagements({
                payload: { ids: selectedStringIds },
              });
            }}
            disabled={migrateManagementsLoading}
            label="CREATE"
          >
            <IconPublish />
          </Button>
        </>
      )}
      {status === "APPROVED" && (
        <>
          <Button
            onClick={() => {
              void publishMigratingManagements({
                payload: { ids: selectedStringIds },
              });
            }}
            disabled={publishMigratingManagementsLoading}
            label="PUBLISH"
          >
            <IconDoneAll />
          </Button>
          <Button
            onClick={() => {
              void unapproveManagements({
                payload: {
                  ids: selectedStringIds,
                },
              });
            }}
            disabled={unapproveManagementsLoading}
            label="UNAPPROVE"
          >
            <IconThumbDown />
          </Button>
        </>
      )}
      {status === "IN_PROGRESS" && (
        <>
          <Button
            onClick={() => {
              void migrateManagements({
                payload: { ids: selectedStringIds },
              });
            }}
            disabled={migrateManagementsLoading}
            label="RETRY"
          >
            <IconPublish />
          </Button>
        </>
      )}
      {status === "CREATED" && (
        <>
          <BulkInviteTenantsButton migratingManagementIds={selectedStringIds} />
          <BulkInviteOwnersButton migratingManagementIds={selectedStringIds} />
        </>
      )}
    </>
  );
};

function BulkInviteOwnersButton({
  migratingManagementIds,
}: {
  migratingManagementIds: Array<string>;
}) {
  const notify = useNotify();
  const [
    bulkInviteOwners,
    { loading: loadingBulkInvite },
  ] = useBulkInviteOwners();
  const [getManagementIds, { loading: loadingGetManagementIds }] = useMutation(
    {
      type: GET_MANY_AILO_MANAGEMENT_IDS,
      resource: MigratingManagement,
    },
    {
      onSuccess: ({ data }) => bulkInviteOwners(data.managementIds),
      onFailure: (error) =>
        notify(`Unable to fetch management ids: ${error.message}`, "error"),
    }
  );

  if (loadingGetManagementIds || loadingBulkInvite) {
    return <CircularProgress size={25} />;
  }

  return (
    <InviteOwnersButton
      onClick={() => {
        void getManagementIds({
          payload: {
            ids: migratingManagementIds,
          },
        });
      }}
    />
  );
}

function BulkInviteTenantsButton({
  migratingManagementIds,
}: {
  migratingManagementIds: Array<string>;
}) {
  const notify = useNotify();
  const [
    bulkInviteTenants,
    { loading: loadingBulkInvite },
  ] = useBulkInviteTenants();
  const [getManagementIds, { loading: loadingGetManagementIds }] = useMutation(
    {
      type: GET_MANY_AILO_MANAGEMENT_IDS,
      resource: MigratingManagement,
    },
    {
      onSuccess: ({ data }) => bulkInviteTenants(data.managementIds),
      onFailure: (error) =>
        notify(`Unable to fetch management ids: ${error.message}`, "error"),
    }
  );

  if (loadingGetManagementIds || loadingBulkInvite) {
    return <CircularProgress size={25} />;
  }

  return (
    <InviteTenantsButton
      onClick={() => {
        void getManagementIds({
          payload: {
            ids: migratingManagementIds,
          },
        });
      }}
    />
  );
}

const rowStyle = (record: MigratingManagementRecord): CSSProperties => {
  let backgroundColor = "white";

  if (!record) return { backgroundColor };

  const hasTasks =
    record.postApprovalTasks && record.postApprovalTasks.length > 0;

  if (hasTasks) backgroundColor = "#FFE0B2";

  if (record.status === MigratingManagementStatus.Created) {
    return { backgroundColor };
  }

  const hasIssues = record.errors && record.errors.length > 0;
  const tooOld =
    !record.healthcheckRunAt ||
    moment(record.healthcheckRunAt).isBefore(moment().subtract(1, "day"));

  if (hasIssues) backgroundColor = "#f2c3bf";
  if (tooOld && !hasTasks && !hasIssues) backgroundColor = "#c0c0c0";

  return { backgroundColor };
};

const hasPostApprovalTasks = (record: MigratingManagementRecord) => {
  return record?.postApprovalTasks && record.postApprovalTasks.length > 0;
};

const MigratingManagementsPostApprovalTasks = (props: {
  record?: MigratingManagementRecord;
}): ReactElement => {
  return (
    <MigratingManagementsHealthCheckCard
      issues={
        ((props.record?.postApprovalTasks || []) as unknown) as MigrationIssue[]
      }
      type={HealthCheckType.POST_APPROVAL_TASK}
    />
  );
};

const TABS = [
  { value: "IMPORTED", label: "IMPORTED" },
  { value: "APPROVED", label: "APPROVED" },
  { value: "IN_PROGRESS", label: "IN PROGRESS" },
  { value: "CREATED", label: "CREATED" },
  { value: "EXCLUDED", label: "EXCLUDED" },
  { value: "IGNORED", label: "IGNORED" },
];

function TabbedDatagrid(props: DatagridProps) {
  const {
    ids,
    filterValues,
    setFilters,
    currentSort,
    setSort,
    displayedFilters,
  } = useListContext();

  const handleChange = useCallback(
    // MUI forces use of {} generic in Tabs.d.ts
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: ChangeEvent<{}>, tab: string) => {
      if (setFilters) {
        setFilters({ ...filterValues, status: tab }, displayedFilters, false);
      }
    },
    [displayedFilters, filterValues, setFilters]
  );

  useEffect(() => {
    if (!filterValues.status) {
      setFilters(
        { ...filterValues, status: "IMPORTED" },
        displayedFilters,
        false
      );
    }
  }, [filterValues, displayedFilters, setFilters]);

  const shownStatus = filterValues.status ?? TABS[0].value;

  useEffect(() => {
    // Ideally we would move this within the handleChange callback, but because of issue https://github.com/marmelab/react-admin/issues/4189,
    // setSort and setFilter cannot be called at once.
    //
    // Likewise, this cannot be set as a prop on the datagrids, because even though that visually updates the sort column indicator, it doesn't
    // actually sort the list.
    if (
      shownStatus === "APPROVED" &&
      currentSort.field !== "approvedAt" &&
      setSort
    ) {
      setSort("approvedAt", "DESC");
    }
  }, [shownStatus, currentSort, setSort]);

  return (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={shownStatus}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />

      <div>
        {shownStatus === "IMPORTED" && (
          <Datagrid
            rowClick="edit"
            expand={<MigratingManagementsEdit />}
            {...props}
            rowStyle={(rowStyle as unknown) as DatagridProps["rowStyle"]}
            ids={ids}
          >
            <TextField
              label="agency"
              source="organisation.name"
              sortable={false}
            />
            <TextField
              label="portfolio"
              source="portfolioName"
              sortable={false}
            />
            <TextField label="Batch" source="batchRef" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Address"
              render={formatManagementPropertyAddress}
              sortable={false}
            />
            <DateField label="Modified At" source="modifiedAt" showTime />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Owners"
              render={(record) => {
                return record ? <Owners record={record} /> : null;
              }}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Tenants"
              render={(record) => {
                return record ? <Tenants record={record} /> : null;
              }}
              sortable={false}
            />
            <DateField source="paidTo" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Partial Paid"
              source="partialPaidCents"
              render={(record) => (
                <MoneyField record={record} source="partialPaidCents" />
              )}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Migration Status"
              render={(record) => {
                return record?.migrationFailureReason ? (
                  <Tooltip title={`Failed: ${record.migrationFailureReason}`}>
                    <Warning />
                  </Tooltip>
                ) : null;
              }}
              sortable={false}
            />
          </Datagrid>
        )}

        {shownStatus === "APPROVED" && (
          <Datagrid
            rowClick="edit"
            expand={<MigratingManagementsEdit />}
            {...props}
            rowStyle={(rowStyle as unknown) as DatagridProps["rowStyle"]}
            ids={ids}
          >
            <TextField
              label="agency"
              source="organisation.name"
              sortable={false}
            />
            <TextField
              label="portfolio"
              source="portfolioName"
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Address"
              render={formatManagementPropertyAddress}
              sortable={false}
            />
            <DateField label="Approved At" source="approvedAt" showTime />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Approved By"
              render={formatApprovedByName}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Migration Status"
              render={(record) => {
                return record?.migrationFailureReason ? (
                  <Tooltip title={`Failed: ${record.migrationFailureReason}`}>
                    <Warning />
                  </Tooltip>
                ) : null;
              }}
              sortable={false}
            />
          </Datagrid>
        )}
        {shownStatus === "IN_PROGRESS" && (
          <Datagrid
            rowClick="edit"
            expand={<MigratingManagementsEdit />}
            {...props}
            rowStyle={(rowStyle as unknown) as DatagridProps["rowStyle"]}
            ids={ids}
          >
            <DateField
              label="In Progress Since"
              source="statusChangedAt"
              showTime
            />
            <TextField
              label="agency"
              source="organisation.name"
              sortable={false}
            />
            <TextField
              label="portfolio"
              source="portfolioName"
              sortable={false}
            />
            <TextField label="Batch" source="batchRef" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Address"
              render={formatManagementPropertyAddress}
              sortable={false}
            />
            <DateField label="Modified At" source="modifiedAt" showTime />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Owners"
              render={(record) => {
                return record ? <Owners record={record} /> : null;
              }}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Tenants"
              render={(record) => {
                return record ? <Tenants record={record} /> : null;
              }}
              sortable={false}
            />
            <DateField source="paidTo" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Partial Paid"
              source="partialPaidCents"
              render={(record) => (
                <MoneyField record={record} source="partialPaidCents" />
              )}
              sortable={false}
            />
          </Datagrid>
        )}
        {shownStatus === "CREATED" && (
          <Datagrid
            {...props}
            ids={ids}
            rowStyle={(rowStyle as unknown) as DatagridProps["rowStyle"]}
            expand={<MigratingManagementsPostApprovalTasks />}
            isRowExpandable={
              (hasPostApprovalTasks as unknown) as DatagridProps["isRowExpandable"]
            }
          >
            <TextField source="id" />
            <TextField
              label="agency"
              source="organisation.name"
              sortable={false}
            />
            <TextField
              label="portfolio"
              source="portfolioName"
              sortable={false}
            />
            <TextField label="Batch" source="batchRef" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Management"
              render={(record) =>
                record?.ailoManagement && (
                  <LinkField
                    record={record}
                    source="ailoManagement.id"
                    sourceResource={Management}
                    renderText={() =>
                      formatManagementPropertyAddress(record) ?? ""
                    }
                  />
                )
              }
              sortable={false}
            />
            <DateField label="Modified At" source="modifiedAt" showTime />
            <DateField label="Created at" source="createdAt" showTime />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Owners"
              render={(record) => {
                return record ? <Owners record={record} /> : null;
              }}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Tenants"
              render={(record) => {
                return record ? <Tenants record={record} /> : null;
              }}
              sortable={false}
            />
          </Datagrid>
        )}
        {shownStatus === "IGNORED" && (
          <Datagrid
            rowClick="show"
            {...props}
            ids={ids}
            isRowSelectable={() => false}
          >
            <TextField source="id" />
            <TextField
              label="agency"
              source="organisation.name"
              sortable={false}
            />
            <TextField
              label="portfolio"
              source="portfolioName"
              sortable={false}
            />
            <TextField label="Batch" source="batchRef" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Address"
              render={formatManagementPropertyAddress}
              sortable={false}
            />
            <DateField label="Modified At" source="modifiedAt" showTime />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Owners"
              render={(record) => {
                return record ? <Owners record={record} /> : null;
              }}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Tenants"
              render={(record) => {
                return record ? <Tenants record={record} /> : null;
              }}
              sortable={false}
            />
            <DateField source="paidTo" sortable={false} />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Partial Paid"
              source="partialPaidCents"
              render={(record) => (
                <MoneyField record={record} source="partialPaidCents" />
              )}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Migration Status"
              render={(record) => {
                return record?.migrationFailureReason ? (
                  <Tooltip title={`Failed: ${record.migrationFailureReason}`}>
                    <Warning />
                  </Tooltip>
                ) : null;
              }}
              sortable={false}
            />
          </Datagrid>
        )}
        {shownStatus === "EXCLUDED" && (
          <Datagrid
            rowClick="show"
            {...props}
            ids={ids}
            isRowSelectable={() => false}
          >
            <TextField
              label="agency"
              source="organisation.name"
              sortable={false}
            />
            <TextField
              label="portfolio"
              source="portfolioName"
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Address"
              render={formatManagementPropertyAddress}
              sortable={false}
            />
            <FunctionField<NonNullable<MigratingManagementRecord>>
              label="Excluded By"
              render={formatStatusChangedByName}
              sortable={false}
            />
            <DateField label="Excluded On" source="statusChangedAt" showTime />
            <TextField
              label="reason"
              source="exclusionReason"
              sortable={false}
            />
          </Datagrid>
        )}
      </div>
    </>
  );
}

const ListActions = (props: ListProps) => {
  return (
    <TopToolbar>
      {props.filters
        ? cloneElement(props.filters, { context: "button" })
        : null}
      <UploadMigrationCSVButton />
      <ExportButton />
    </TopToolbar>
  );
};

export function MigratingManagementsList(props: ListProps): React.ReactElement {
  return (
    <List
      {...props}
      perPage={100}
      title="Migrating Managements"
      actions={<ListActions />}
      bulkActionButtons={<MigrationBulkActionButtons />}
      filters={<SearchFilter />}
      sort={{ field: "modifiedAt", order: "DESC" }}
    >
      <TabbedDatagrid />
    </List>
  );
}
