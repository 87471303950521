import React from "react";
import { Error, Loading, SelectInput, useNotify, useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { Alert } from "@material-ui/lab";
import { SimpleFormDialog, useMutation } from "../../common";
import { Organisation } from "../../api";

const PropertyChangeTeamDialogue = (props: {
  managingEntityOrganisationId: string;
  managementReferenceList: AiloRN[];
  open: boolean;
  onClose: () => void;
}): React.ReactElement => {
  const {
    open,
    onClose,
    managingEntityOrganisationId,
    managementReferenceList,
  } = props;
  const notify = useNotify();

  const { data, loading, error } = useQuery({
    type: "GET_ONE",
    resource: Organisation,
    payload: {
      id: managingEntityOrganisationId,
    },
  });

  const [moveTeam] = useMutation(
    {
      type: "move_managements_to_team",
      resource: Organisation,
    },
    {
      onSuccess: () => {
        notify(`Management has been moved to new team`);
        onClose();
      },
      onFailure: (errors) => {
        notify(`Failed to move management to new team. Error: ${errors}`);
      },
      muteNotifications: true,
    }
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const teams = data.teams.map((team: { id: string; name: string }) => ({
    id: team.id,
    name: team.name,
  }));

  const { title, submitLabel } =
    managementReferenceList.length > 1
      ? {
          title: "Move All Managements To New Team",
          submitLabel: "Move Managements",
        }
      : {
          title: "Move Management To New Team",
          submitLabel: "Move Management",
        };
  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title={title}
      submitLabel={submitLabel}
      save={({ teamId }) =>
        moveTeam({
          payload: {
            managementIds: managementReferenceList,
            teamId,
          },
        })
      }
      onClose={onClose}
    >
      {managementReferenceList.length > 1 && (
        <Alert
          severity="info"
          style={{
            marginBottom: 16,
            width: 224,
          }}
        >
          <a>
            This will reassign all managements in the current folio. To reasign
            individual management, please do so from the Property View
          </a>
        </Alert>
      )}
      <SelectInput
        label="Team Name"
        source="teamId"
        choices={teams}
        fullWidth
      />
    </SimpleFormDialog>
  );
};

export { PropertyChangeTeamDialogue };
