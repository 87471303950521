import moment from "moment-timezone";
import { DaysOfMonthValue } from "../../common/inputs/DaysOfMonthInput";

export function getAnniversaryFields({
  startDate,
  frequency,
  daysOfMonth,
}: {
  startDate: string;
  frequency: "daily" | "weekly" | "fortnightly" | "monthly";
  daysOfMonth?: DaysOfMonthValue;
}): {
  anniversary: number | undefined;
  anniversaryDaysOfMonth: number[] | undefined;
  lastDayOfTheMonth: boolean;
} {
  switch (frequency) {
    case "daily":
      return {
        anniversary: 0,
        anniversaryDaysOfMonth: undefined,
        lastDayOfTheMonth: false,
      };
    case "weekly":
    case "fortnightly":
      return {
        anniversary: moment(startDate).isoWeekday(),
        anniversaryDaysOfMonth: undefined,
        lastDayOfTheMonth: false,
      };
    case "monthly":
      if (
        (daysOfMonth?.days ?? []).length === 0 &&
        !daysOfMonth?.includesLastDay
      ) {
        throw new Error(
          `daysOfMonth.days must be non-empty or daysOfMonth.includesLastDay must be true`
        );
      }
      return {
        anniversary: undefined,
        anniversaryDaysOfMonth: daysOfMonth?.days ?? undefined,
        lastDayOfTheMonth: daysOfMonth?.includesLastDay ?? false,
      };
    default:
      throw new Error(`Unsupported frequency: ${frequency}`);
  }
}
