import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";
import { RemoveButton } from "../../common/ui/buttons";
import { ErrorStyle, HelperText, useStyles } from "./migratingManagementsEdit";
import { PlatformFeature } from "../TenancyBonds/BondFeaturesCard/PlatformFeature";

interface TriggerBasedFeeProps {
  label: string;
  source: string;
}

const MANAGEMENT_FEE_BLUEPRINT_FEATURE_TOGGLE = "ManagementFeeBlueprint";
export const isManagementFeeBlueprintEnabled = (
  availableFeatures: PlatformFeature[]
): boolean => {
  return (
    availableFeatures &&
    Array.isArray(availableFeatures) &&
    !!availableFeatures.find(
      (element: PlatformFeature) =>
        element.id === MANAGEMENT_FEE_BLUEPRINT_FEATURE_TOGGLE
    )
  );
};

const TriggerBasedFeesFormList = (
  props: TriggerBasedFeeProps
): ReactElement => {
  const classes = useStyles();
  const { label, source } = props;
  return (
    <ArrayInput label={label} source={source}>
      <SimpleFormIterator
        disableAdd
        removeButton={<RemoveButton>Remove</RemoveButton>}
      >
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData }) => {
            if (!getSource) return null;
            return (
              <div>
                <Box
                  style={{ width: "100%" }}
                  display={{
                    xs: "block",
                    sm: "flex",
                  }}
                >
                  <Box
                    flex={1}
                    mr={{
                      xs: 0,
                      sm: "0.5em",
                    }}
                  >
                    <TextInput
                      fullWidth
                      disabled
                      label="Fee Template Name"
                      source={getSource("feeName")}
                      helperText={HelperText(formData, getSource("feeName"))}
                      className={ErrorStyle(
                        formData,
                        getSource("feeName"),
                        classes
                      )}
                    />
                  </Box>
                  <Box
                    flex={1}
                    mr={{
                      xs: 0,
                      sm: "0.5em",
                    }}
                  >
                    <TextInput
                      label="Category"
                      fullWidth
                      disabled
                      source={getSource("taxCategory")}
                      helperText={HelperText(
                        formData,
                        getSource("taxCategory")
                      )}
                      className={ErrorStyle(
                        formData,
                        getSource("taxCategory"),
                        classes
                      )}
                    />
                  </Box>

                  <Box
                    flex={1}
                    mr={{
                      xs: 0,
                      sm: "0.5em",
                    }}
                  >
                    <TextInput
                      label={"Price".concat(
                        scopedFormData.valueType === "Fixed"
                          ? " ($)"
                          : scopedFormData.triggerType === "InvoiceReceipt"
                          ? " (% of invoice)"
                          : " (weeks of rent)"
                      )}
                      disabled
                      fullWidth
                      source={getSource("value")}
                      helperText={HelperText(formData, getSource("value"))}
                      className={ErrorStyle(
                        formData,
                        getSource("value"),
                        classes
                      )}
                    />
                  </Box>

                  <Box
                    flex={1}
                    mr={{
                      xs: 0,
                      sm: "0.5em",
                    }}
                  >
                    <TextInput
                      label="Type"
                      disabled
                      fullWidth
                      source={getSource("valueType")}
                      helperText={HelperText(formData, getSource("valueType"))}
                      className={ErrorStyle(
                        formData,
                        getSource("valueType"),
                        classes
                      )}
                    />
                  </Box>

                  <Box
                    flex={1}
                    mr={{
                      xs: 0,
                      sm: "0.5em",
                    }}
                  >
                    <TextInput
                      label="GST inclusive"
                      fullWidth
                      disabled
                      source={getSource("taxInclusive")}
                      helperText={HelperText(
                        formData,
                        getSource("taxInclusive")
                      )}
                      className={ErrorStyle(
                        formData,
                        getSource("taxInclusive"),
                        classes
                      )}
                    />
                  </Box>
                </Box>
              </div>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export { TriggerBasedFeesFormList };
