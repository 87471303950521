import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

const EditIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.9 5.4C20 5.3 20 5.1 20 5C20 2.2 17.8 0 15 0C14.9 0 14.7 -9.68575e-08 14.6 0.0999999C14.5 0.2 14.4 0.2 14.3 0.3L0.3 14.3C0.2 14.4 0.0999999 14.5 0.0999999 14.6C-9.68575e-08 14.7 0 14.9 0 15V19C0 19.6 0.4 20 1 20H5C5.6 20 6 19.6 6 19C6 18.4 5.6 18 5 18H2V15.4L12 5.4L14.6 8L7.3 15.3C6.9 15.7 6.9 16.3 7.3 16.7C7.5 16.9 7.7 17 8 17C8.3 17 8.5 16.9 8.7 16.7L19.7 5.7C19.8 5.6 19.9 5.5 19.9 5.4ZM13.4 4L15.4 2C16.8 2.2 17.8 3.2 18 4.6L16 6.6L13.4 4Z"
        fill="#EE0B4F"
      />
    </SvgIcon>
  );
};

export { EditIcon };
