import React from "react";
import { SectionList } from "../../../common";
import { groupTransactionsByMonth } from "../utils";

const EntriesByMonthList = ({
  entries,
  groupBy,
  listItemComponent,
  insetFooter,
  style,
}: any) => {
  const transactionsByMonth = groupTransactionsByMonth(entries, groupBy);
  return (
    <SectionList
      sections={transactionsByMonth}
      renderItem={({ item }: any) => listItemComponent(item)}
      insetFooter={insetFooter}
      style={style}
    />
  );
};

export { EntriesByMonthList };
