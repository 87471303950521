import Button, { ButtonProps } from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { Colors } from "../../../../common";

export const DialogControlButton = ({
  onClick,
  children,
  icon,
  iconClass,
  disabled,
  ...props
}: ButtonProps & {
  onClick: () => void;
  children: string | React.ReactElement;
  disabled?: boolean;
  icon?: React.ReactElement;
  iconClass?: typeof SvgIcon;
}): React.ReactElement => (
  <Button
    style={{
      textTransform: "none",
    }}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    <Box p={1} display="flex">
      {icon}
      <Typography
        style={{
          color: disabled ? Colors.TEXT.DARK.SECONDARY : Colors.AILO_RED,
          fontSize: "1rem",
          fontWeight: 530,
          paddingTop: 1,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Button>
);
