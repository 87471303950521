import { FeeBlueprintType } from "../../api/graphql/types.generated";

/**
 * Returns a user friendly name for a blueprint type.
 */
export const humanize = (type: FeeBlueprintType): string | undefined => {
  const displayValues = new Map<FeeBlueprintType, string>([
    [FeeBlueprintType.RecurringFee, "Recurring fee"],
    [FeeBlueprintType.OneOffFee, "Manual fee"],
    [FeeBlueprintType.EventBasedFee, "Event-based fee"],
  ]);
  return displayValues.get(type);
};

export const allTypes = Object.values(FeeBlueprintType);

/**
 * List of supported fee blueprint types in the format compatible with `RadioButtonGroupInput`.
 */
export const allHumanizedTypes = allTypes.map((t) => ({
  id: t,
  name: humanize(t),
}));
