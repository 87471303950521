import { GET_ONE } from "react-admin";
import gql from "graphql-tag";
import { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { GetTenancyBondDetailsQuery } from "./tenancyBondsClient.generated";

export const DISBURSE_BOND_CLAIMS = "disburse_bond_claims";

export function tenancyBondsClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query getTenancyBondDetails($id: ID!) {
            tenancy(tenancyId: $id) {
              id
              bond {
                id
                reference
                amount {
                  cents
                }
                status
                failureReason
                fundsReceived {
                  amount {
                    cents
                  }
                  receivedAt
                }
                claims {
                  id
                  amount {
                    cents
                  }
                  liabilityAiloRN
                  claimStatus
                }
              }
            }
          }
        `,
        variables: {
          id: params.id,
        },
        errorPolicy: "all",

        parseResponse(response: {
          data: { tenancy: GetTenancyBondDetailsQuery["tenancy"] };
        }) {
          return {
            data: response.data.tenancy,
          };
        },
      };
    case DISBURSE_BOND_CLAIMS:
      return {
        query: gql`
          mutation disburseTenancyBondClaims($bondId: ID!) {
            disburseTenancyBondClaims(bondId: $bondId) {
              id
              status
            }
          }
        `,
        variables: {
          bondId: params.bondId,
        },
        errorPolicy: "all",
      };
    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
