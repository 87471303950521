/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { GET_LIST, useInput } from "react-admin";
import { PaymentMethod } from "../../api/ResourceName";
import { useQueryWithStore } from "../../common";
import { Colors } from "../../common/ui/colors";

const BankAccountPaymentMethodOfOwnerInput = ({
  owner,
  source,
  validate,
}: any) => {
  const { loaded, data } = useQueryWithStore({
    type: GET_LIST,
    resource: PaymentMethod,
    payload: {
      filter: {
        owner,
      },
    },
  });
  const bankAccountMethods = (data ?? []).filter((paymentMethod: any) => {
    return paymentMethod.__typename === "BankAccount";
  });
  const {
    input: { value, onChange },
  } = useInput({
    source,
    validate,
  });
  const isValueEmpty = !value;
  const defaultMethodId = bankAccountMethods.find(
    (account: any) => account.isDefaultOut
  )?.id;
  useEffect(() => {
    if (isValueEmpty && defaultMethodId) {
      onChange(defaultMethodId);
    }
  }, [isValueEmpty, defaultMethodId, onChange]);

  if (!loaded) {
    return null;
  }

  return (
    <List>
      {bankAccountMethods.map((method: any) => (
        <PaymentMethodListItem
          key={method.id}
          method={method}
          selected={method.id === value}
          onClick={() => onChange(method.id)}
        />
      ))}
    </List>
  );
};

BankAccountPaymentMethodOfOwnerInput.defaultProps = {
  addLabel: true,
};

const PaymentMethodListItem = ({ method, selected, onClick }: any) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={onClick}
        style={{
          marginTop: 5,
        }}
      >
        <Box
          style={{
            marginRight: 50,
          }}
        >
          <Typography>{method.accountName}</Typography>
          <Typography
            style={{
              color: Colors.TEXT.DARK.SECONDARY,
              fontSize: "0.8125rem",
            }}
          >
            {method.accountNumber}
          </Typography>
        </Box>
        <Box>{selected ? <Chip label="selected" /> : null}</Box>
      </Box>
      <Divider
        style={{
          marginTop: 12,
        }}
      />
    </>
  );
};

export { BankAccountPaymentMethodOfOwnerInput };
