/* eslint-disable react-hooks/exhaustive-deps */
import { AiloRN } from "@ailo/ailorn";
import { FormHelperText } from "@material-ui/core";
import { sentence } from "case";
import React, { useEffect, useMemo } from "react";
import {
  AutocompleteInput,
  FormDataConsumer,
  required,
  TextInputProps,
  useInput,
} from "react-admin";
import { CreateBillFormData } from "../../..";
import { Badge, escapeRegexSpecialCharacters } from "../../../../common";
import {
  PayerOption,
  useGetPayerOptions,
} from "./PaymentOptionHooks/useGetPayerOptions";

type PayerInputProps = Pick<TextInputProps, "style" | "helperText"> & {
  managementId?: string;
  payeeId?: string;
};

export function PayerInput(props: PayerInputProps): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }: { formData: CreateBillFormData }) => (
        <PayerInputInner
          managementId={formData.management}
          payeeId={
            formData.payee?.ailorn
              ? AiloRN.from(formData.payee?.ailorn).internalId
              : undefined
          }
          {...props}
        />
      )}
    </FormDataConsumer>
  );
}

export function PayerInputInner({
  managementId,
  payeeId,
  style,
  ...rest
}: PayerInputProps): React.ReactElement {
  const { options: unfilteredOptions } = useGetPayerOptions(managementId);
  const options = useMemo(() => {
    return unfilteredOptions.filter((option) => option.id !== payeeId);
  }, [unfilteredOptions, payeeId]);

  const {
    input: {
      onChange,
      value: { id },
    },
  } = useInput({ source: "payer" });

  useEffect(() => onChange(undefined), [managementId]);
  useEffect(
    () =>
      onChange({
        id,
        ...(options.find((option) => option.id === id)?.value ?? {}),
      }),
    [id]
  );

  return (
    <div style={style}>
      <AutocompleteInput
        label="Payer"
        source="payer.id"
        choices={options}
        disabled={options.length === 0}
        validate={[required()]}
        optionText={<OptionText />}
        inputText={inputText}
        matchSuggestion={(searchString: string, { name }: { name: string }) => {
          return searchString
            ? name
                .toLowerCase()
                .search(
                  escapeRegexSpecialCharacters(searchString.toLowerCase())
                ) > -1
            : true;
        }}
        options={{
          style: { width: "100%" },
          InputProps: {
            type: "search", // needed to stop 1password from trying to auto fill this field
          },
        }}
        resettable
        {...rest}
      />
    </div>
  );
}

function inputText(choice?: PayerOption): string {
  return choice?.name || "";
}

function OptionText({ record }: { record?: PayerOption }) {
  return (
    <div>
      <div style={{ marginRight: 100 }}>{record?.name}</div>
      <Badge
        type={
          record?.value.type === "management"
            ? "investor"
            : record?.value.type === "tenancy"
            ? "tenant"
            : record?.value.type === "agency"
            ? "default"
            : "success"
        }
        text={sentence(record?.value.type ?? "")}
        style={{ position: "absolute", top: 10, right: 16 }}
      />
      {record?.value.type === "tenancy" && record.value.description && (
        <FormHelperText>{record.value.description} </FormHelperText>
      )}
    </div>
  );
}
