import { AiloRN, services } from "@ailo/ailorn";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { PersonActivityHistoryCard } from "../../../features/LegalEntity";
import { ConsumerBasicInfoCard } from "../../Customer/consumers/components";
import { AiloPaymentMethodFeesCard } from "../../Customer/consumers/components/ailoPaymentMethodFees/ailoPaymentMethodFeesCard";
import { LegalEntitiesCard } from "../../Customer/consumers/components/legalEntities/legalEntitiesCard";
import { OrganisationMembershipsCard } from "../../Customer/consumers/components/memberships/organisationMembershipsCard";
import { MigrationSection } from "../../Customer/consumers/components/migration/MigrationSection";
import { NotificationPreferencesCard } from "../../Customer/consumers/components/notifications/notificationPreferencesCard";
import { PaymentMethodsCard } from "../../Customer/consumers/components/paymentMethods/paymentMethodsCard";
import { PersonCompaniesOwnedCard } from "../../Customer/consumers/components/people/personCompaniesOwnedCard";
import { SubscriptionsCard } from "../../Customer/consumers/components/subscriptions/subscriptionsCard";
import {
  isAgency,
  isCompany,
  LegalEntityShowRecord,
} from "./LegalEntityShowRecord";

export function GeneralTab({
  record,
}: {
  record: LegalEntityShowRecord;
}): React.ReactElement {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ConsumerBasicInfoCard record={record} />
        <NotificationPreferencesCard
          userId={record?.__typename === "Person" ? record.user?.id : undefined}
        />

        {isCompany(record) && isAgency(record) && (
          <>
            <OrganisationMembershipsCard
              organisationMemberships={record.organisation}
              organisationInfo={{
                organisationId: record.organisation.id,
                legalEntityId: record.id,
              }}
            />
            <LegalEntitiesCard
              legalEntities={record.organisation.legalEntities}
            />
          </>
        )}

        {isCompany(record) && isAgency(record) && (
          <MigrationSection
            legalEntityId={record.ailoRN}
            organisationId={AiloRN.of(
              services.AuthZ.organisation,
              record.organisation.id
            )}
            organisationName={record.organisation.name}
            migrationTask={record.migrationTask}
            externalTrustAccounts={record.externalTrustAccounts}
          />
        )}

        {record?.__typename === "Person" && (
          <PersonActivityHistoryCard person={record} />
        )}

        {/* <LinkedLegalEntitiesCard
        organisationId={record.organisation.id}
        legalEntityId={record.id}
      /> */}
      </Grid>
      <Grid item xs={6}>
        {record?.__typename === "Person" ? (
          <PersonCompaniesOwnedCard person={record} />
        ) : null}
        <PaymentMethodsCard
          walletOwnerAiloRN={AiloRN.fromString(record.ailoRN)}
        />
        <AiloPaymentMethodFeesCard legalEntityId={record.ailoRN} />
        <SubscriptionsCard
          activeSubscriptions={record.organisation.activeSubscriptions!.items}
        />
      </Grid>
    </Grid>
  );
}
