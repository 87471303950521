import React from "react";
import { Alert } from "@material-ui/lab";

export function HowToSendStatementsAlert(): React.ReactElement {
  return (
    <Alert
      severity="info"
      style={{
        marginBottom: 16,
        width: 224,
      }}
    >
      {"Related document: "}
      <a
        href="https://ailo.atlassian.net/wiki/spaces/TS/pages/1937604642/How+to+send+Ownership+Statement+Investor+Statement"
        target="_blank"
        rel="noreferrer"
      >
        How to send Ownership Statement (Investor Statement)
      </a>
    </Alert>
  );
}
