import { title } from "case";
import type {
  BankAccountMethod,
  BPayMethod,
  CreditCardMethod,
} from "../../../api/deprecatedTypes";
import {
  BankAccountTypeName,
  CreditCardTypeName,
} from "../../../api/deprecatedTypes";

const formatPaymentMethodName = (
  paymentMethod:
    | Omit<CreditCardMethod, "id">
    | Omit<BankAccountMethod, "id">
    | Omit<BPayMethod, "id">
): string => {
  if (paymentMethod.__typename === CreditCardTypeName) {
    const { number, type } = paymentMethod;
    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    const uiMaskedCardNumber = `···· ${number.slice(-4)}`;
    return type
      ? `${title(type)} ${uiMaskedCardNumber}`
      : uiMaskedCardNumber || "Credit Card";
  }

  if (paymentMethod.__typename === BankAccountTypeName) {
    const { accountName } = paymentMethod;
    return accountName ? title(accountName) : "Bank Account";
  }

  return "Payment Method";
};

export { formatPaymentMethodName };
