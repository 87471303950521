import gql from "graphql-tag";
import { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { RaDataGraphqlClientResult } from "../../common";

const CREDIT_FROM_AILO_BANK = "credit_from_ailo_bank";
const WRITE_OFF = "write_off";
const TRANSFER_FUNDS = "transfer_funds";

function walletTransferClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs): RaDataGraphqlClientResult {
  switch (raFetchType) {
    case TRANSFER_FUNDS:
      return {
        query: gql`
          mutation transferToWallet(
            $fromWalletId: ID!
            $toWalletId: ID!
            $amountInCents: Long!
            $description: String!
            $userFacingDescription: String
          ) {
            transferToWallet(
              transferToWalletInput: {
                fromWalletId: $fromWalletId
                toWalletId: $toWalletId
                amount: { cents: $amountInCents }
                description: $description
                userFacingDescription: $userFacingDescription
              }
            ) {
              status
              error
            }
          }
        `,
        variables: {
          fromWalletId: params.fromWalletId,
          toWalletId: params.toWalletId,
          amountInCents: params.amountInCents,
          description: params.description,
          userFacingDescription: params.userFacingDescription,
        },
        parseResponse: (response: {
          data: { transferToWallet: { [key: string]: string } };
        }) => ({
          data: response.data.transferToWallet,
        }),
      };
    case CREDIT_FROM_AILO_BANK:
      return {
        query: gql`
          mutation creditFromAiloBank(
            $walletId: ID!
            $amountInCents: Long!
            $description: String!
            $userFacingDescription: String
          ) {
            creditFromAiloBank(
              walletCreditInput: {
                walletId: $walletId
                amount: { cents: $amountInCents }
                description: $description
                userFacingDescription: $userFacingDescription
              }
            ) {
              status
              error
            }
          }
        `,
        variables: {
          walletId: params.walletId,
          amountInCents: params.amountInCents,
          idempotencyKey: params.idempotencyKey,
          description: params.description,
          userFacingDescription: params.userFacingDescription,
        },
        parseResponse: (response: {
          data: { adjustWalletBalance: { [key: string]: string } };
        }) => ({
          data: response.data.adjustWalletBalance,
        }),
      };
    case WRITE_OFF:
      return {
        query: gql`
          mutation writeOff(
            $walletId: ID!
            $amountInCents: Long!
            $description: String!
            $userFacingDescription: String
          ) {
            writeOff(
              walletCreditInput: {
                walletId: $walletId
                amount: { cents: $amountInCents }
                description: $description
                userFacingDescription: $userFacingDescription
              }
            ) {
              status
              error
            }
          }
        `,
        variables: {
          walletId: params.walletId,
          amountInCents: params.amountInCents,
          idempotencyKey: params.idempotencyKey,
          description: params.description,
          userFacingDescription: params.userFacingDescription,
        },
        parseResponse: (response: {
          data: { adjustWalletBalance: { [key: string]: string } };
        }) => ({
          data: response.data.adjustWalletBalance,
        }),
      };
    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { walletTransferClient };
