import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { required, TextInput, UPDATE, useRecordContext } from "react-admin";
import { EditIcon, SimpleFormDialog, useMutation } from "../../common";
import { Team, OperationParams } from "../../api";

type EditTeamInput = OperationParams<typeof Team, typeof UPDATE>["data"];

function EditTeamButton(): React.ReactElement {
  const { id, name } = useRecordContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Team,
    type: UPDATE,
  });
  return (
    <Tooltip title="edit team name">
      <div>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => setDialogOpen(true)}
        >
          <EditIcon style={{ padding: 0 }} />
        </IconButton>
        {dialogOpen && (
          <SimpleFormDialog<EditTeamInput>
            title="Edit Team"
            submitLabel="Edit"
            cancelLabel="Back"
            initialValues={{ team: id, name }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            save={(data) => mutate({ payload: { data } })}
          >
            <TextInput
              source="name"
              fullWidth
              validate={[required()]}
              defaultValue={name}
            />
          </SimpleFormDialog>
        )}
      </div>
    </Tooltip>
  );
}

export { EditTeamButton };
