import React, { useState } from "react";
import { ConfirmActionDialog } from "../../../../common/ui/dialogs/ConfirmActionDialog/ConfirmActionDialog";

export function useDeletePaymentDirectiveDialog(
  deletePaymentDirectiveMutation: () => void
): {
  showDeleteDirectiveDialog: () => void;
  deletePaymentDirectiveDialog: React.ReactElement;
} {
  const [showDeleteDirectiveDialog, setShowDeleteDirectiveDialog] = useState(
    false
  );
  const deletePaymentDirectiveDialog = (
    <ConfirmActionDialog
      open={showDeleteDirectiveDialog}
      onClose={() => setShowDeleteDirectiveDialog(false)}
      confirmAction={deletePaymentDirectiveMutation}
      dialogText={{
        title: "Confirm delete Centrepay Directive?",
        content:
          "This will remove the tenant's payment directive for this tenancy",
      }}
      failureNotification="Failed to delete payment directive"
    />
  );
  return {
    showDeleteDirectiveDialog: () => setShowDeleteDirectiveDialog(true),
    deletePaymentDirectiveDialog,
  };
}
