import { AiloRN, services } from "@ailo/ailorn";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import {
  Button,
  ButtonProps,
  required,
  TextInput,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import { minMoney, SimpleFormDialog } from "../../common";
import { Liability } from "../../api/ResourceName";
import { MoneyInput } from "../../common";

const usePayLiabilityFromWallet = ({ onSuccess }: { onSuccess?(): void }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    {
      resource: Liability,
      type: "pay_liability_from_wallet",
      payload: {},
    },
    {
      onSuccess: ({ data }) => {
        const error = data?.error;

        if (error) {
          notify(`Failed to pay liability. Error: ${error}`);
          refresh();
          return;
        }

        notify(`Liability payment from wallet has been done.`);
        refresh();
        onSuccess?.();
      },
      onFailure: (error) => {
        notify(`Failed to pay liability. Error: ${error}`);
      },
    }
  );
};

const PayLiabilityFromWalletDialog = ({
  liability,
  open,
  onClose,
}: {
  liability: {
    id: string;
    paymentKey: string;
  };
  open: boolean;
  onClose: () => void;
}) => {
  const [payLiability] = usePayLiabilityFromWallet({
    onSuccess: onClose,
  });

  const submit = (data: {
    liabilityId: string;
    idempotentKey: string;
    amount: any;
    walletId: string | number;
  }) => {
    payLiability({
      payload: {
        data: {
          amount: data.amount,
          idempotentKey: data.idempotentKey,
          liabilityAiloRN: AiloRN.of(
            services.Ledger.liability,
            data.liabilityId
          ).toString(),
          walletAiloRN: AiloRN.of(
            services.Ledger.wallet,
            data.walletId
          ).toString(),
        },
      },
    });
  };

  return (
    <SimpleFormDialog
      initialValues={{
        liabilityId: liability.id,
        idempotentKey: liability.paymentKey,
      }}
      title="Pay Liability from Wallet"
      open={open}
      save={submit}
      onClose={onClose}
    >
      <Alert
        severity="warning"
        style={{
          marginBottom: 16,
        }}
      >
        Only do this if you know what you&lsquo;re doing! This allows for paying
        a liability from any wallet. Useful for example if you want to{" "}
        <a
          href="https://ailo.atlassian.net/wiki/spaces/TS/pages/2065924116/How+to+change+the+owners+of+a+sold+property"
          target="_blank"
          rel="noreferrer"
        >
          transfer money from old to new owners
        </a>
        .
      </Alert>
      <TextInput
        disabled
        source="liabilityId"
        record={{
          liabilityId: liability.id,
        }}
        label="Liablity ID"
      />
      <TextInput source="walletId" label="Wallet ID" validate={[required()]} />
      <MoneyInput
        source="amount"
        label="amount ($)"
        validate={[
          required(),
          minMoney({
            cents: 1,
          }),
        ]}
      />
    </SimpleFormDialog>
  );
};

export function PayLiabilityFromWalletButton({
  liability,
  ...props
}: {
  liability: {
    id: string;
    paymentKey: string;
  };
} & Omit<ButtonProps, "onClick">): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <PayLiabilityFromWalletDialog
        liability={liability}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Button {...props} onClick={() => setDialogOpen(true)} />
    </>
  );
}
