/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AiloRN, services } from "@ailo/ailorn";

export function transformSupplierForRequest(
  supplierObject: Record<string, any>
) {
  if (!supplierObject) {
    return {};
  }
  const {
    organisation,
    streetName,
    unitStreetNumber,
    postcode,
    suburb,
    country,
    state,
    ...supplierInfo
  } = supplierObject;
  return {
    ...supplierInfo,
    organisation: AiloRN.of(
      services.AuthZ.organisation,
      organisation
    ).toString(),
    address: {
      streetName,
      unitStreetNumber,
      postcode,
      suburb,
      country,
      state,
    },
  };
}
