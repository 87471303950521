import React from "react";
import { GET_ONE, useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { BondAccount } from "../../../api";
import { TenancyBondWalletCardItem } from "./TenancyBondWalletCardItem";
import { VirtualAccountData } from "../../../common";

interface Params {
  agency: AiloRN;
}

const TenancyBondWalletCards = ({
  agency,
}: Params): React.ReactElement | null => {
  const {
    data,
  }: {
    data?: VirtualAccountData[];
  } = useQuery({
    type: GET_ONE,
    resource: BondAccount,
    payload: {
      entity: agency.toString(),
    },
  });

  if (!data) {
    return null;
  }

  return (
    <>
      {data.map((bondWallet: VirtualAccountData, index: number) => {
        return (
          <TenancyBondWalletCardItem
            data={bondWallet}
            agency={agency}
            index={index}
            includeIndexInTitle={data.length !== 1}
          />
        );
      })}
    </>
  );
};
export { TenancyBondWalletCards };
