import { LocalDate } from "@ailo/date";

export function minDate(
  min: LocalDate,
  fieldName?: string
): (value: any) => string | undefined {
  return (value): string | undefined => {
    if (!isDate(value)) return `Must be a valid date`;
    if (value && LocalDate.from(value).isBefore(min)) {
      return `${fieldName || "Date"} can't be earlier than ${min.format(
        "DD/MM/YYYY"
      )}`;
    }

    return undefined;
  };
}

export function maxDate({
  max,
  fieldName,
  errorMessage,
  allowEmpty = false,
}: {
  max: LocalDate;
  fieldName?: string;
  errorMessage?: string;
  allowEmpty?: boolean;
}): (value: any) => string | undefined {
  return (value): string | undefined => {
    if (
      !isDate(value) &&
      ((value !== null && value !== undefined) || !allowEmpty)
    ) {
      return "Must be a valid date";
    }

    if (value && LocalDate.from(value).isAfter(max)) {
      return (
        errorMessage ??
        `${fieldName || "Date"} can't be later than ${max.format("DD/MM/YYYY")}`
      );
    }

    return undefined;
  };
}

export function maxDateToday(
  timeZone?: string,
  fieldName?: string
): (value: any) => string | undefined {
  return (value): string | undefined => {
    if (!isDate(value)) return `Must be a valid date`;
    if (value && LocalDate.from(value).isAfter(LocalDate.today(timeZone))) {
      return `${fieldName || "Date"} can't be in the future`;
    }

    return undefined;
  };
}

export function datesLessThanOrEqualTo(
  lesserLabel: string,
  greaterLabel: string
): (_value: any, values: any) => string | undefined {
  return (_value, values): string | undefined => {
    if (!isDate(values[lesserLabel])) {
      return undefined;
    }
    if (!isDate(values[greaterLabel])) {
      return undefined;
    }

    if (
      LocalDate.from(values[lesserLabel]).isAfter(
        LocalDate.from(values[greaterLabel])
      )
    ) {
      return `${toUpperSentenceCase(
        greaterLabel
      )} can't be earlier than ${toLowerSentenceCase(lesserLabel)}`;
    }

    return undefined;
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isDate(value: any): boolean {
  try {
    LocalDate.from(value);
    return true;
  } catch {
    return false;
  }
}

function toUpperSentenceCase(s: string): string {
  return capitalise(toLowerSentenceCase(s));
}

function toLowerSentenceCase(s: string): string {
  return s.replace(/([A-Z])/g, " $1").toLowerCase();
}

function capitalise(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}
