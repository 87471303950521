import React from "react";
import { Datagrid, DateInput, Filter, TextField } from "react-admin";
import { List } from "../../../common";
import { AiloRNField, MoneyField } from "../../../common";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <DateInput label="Banking Date Before" source="beforeDate" alwaysOn />
  </Filter>
);

const NegativeWalletBalancesList = (props: any) => {
  return (
    <List
      {...props}
      title="Negative Wallet Balances"
      bulkActionButtons={false}
      filters={<SearchFilter />}
      pagination={false}
    >
      <Datagrid>
        <TextField label="Owner Reference" source="reference" />
        <TextField label="Wallet Name" source="name" />
        <MoneyField
          label="Total Balance ($)"
          textAlign="right"
          source="balance"
        />
        <AiloRNField label="Wallet Reference" source="id" showId />
      </Datagrid>
    </List>
  );
};

export { NegativeWalletBalancesList };
