export function formatTerminationReason(terminationReason?: string) {
  if (!terminationReason) return "—";

  switch (terminationReason) {
    case "OffBoarded":
      return "Off-boarded";

    default:
      return pascalToSentenceCase(terminationReason);
  }
}

function pascalToSentenceCase(str: string): string {
  const spaceSeparatedString = str.replace(/([A-Z])/g, " $1").slice(1);
  return (
    spaceSeparatedString.charAt(0).toUpperCase() +
    spaceSeparatedString.slice(1).toLowerCase()
  );
}
