/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeWhitespace(obj: Record<string, any>) {
  return Object.keys(obj).reduce<Record<string, any>>((resultObj, key) => {
    const originalValue = obj[key];
    // eslint-disable-next-line no-param-reassign
    resultObj[key] =
      typeof originalValue === "string" ? originalValue.trim() : originalValue;
    return resultObj;
  }, {});
}
