import gql from "graphql-tag";
import { UPDATE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  UpdateManagementAgreementMutation,
  UpdateManagementAgreementMutationVariables,
} from "./managementAgreementClient.generated";

export const ManagementAgreementClientDefinition = {
  [UPDATE]: (params: {
    data: {
      id: string;
      startDate?: string;
      fixedTermEndDate?: string;
    };
  }) => ({
    query: gql`
      mutation updateManagementAgreement(
        $input: UpdateManagementAgreementInput!
      ) {
        updateManagementAgreement(input: $input) {
          managementAgreement {
            id
            createdAt
            startDate
            fixedTermEndDate
          }
        }
      }
    `,
    variables: <UpdateManagementAgreementMutationVariables>{
      input: {
        id: params.data.id,
        startDate: params.data.startDate,
        fixedTermEndDate: params.data.fixedTermEndDate,
      },
    },
    parseResponse(
      response: GraphQLResponse<UpdateManagementAgreementMutation>
    ) {
      return {
        data: response.data.updateManagementAgreement?.managementAgreement,
      };
    },
  }),
};

export const managementAgreementClient = createClientFromDefinition(
  ManagementAgreementClientDefinition
);
