import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
} from "react-admin";
import React, { useCallback } from "react";
import { formatLegalEntityName } from "../utils";
import { ReferenceInput } from "./ReferenceInput";
import { LegalEntity } from "../../api/ResourceName";

export function LegalEntityInput({
  source,
  multiple,
  allowEmpty,
  emptyText,
  disabled,
  options,
  ...props
}: any): React.ReactElement {
  const filterToQuery = useCallback(
    (searchTerm: string) => ({ searchTerm }),
    []
  );

  const formatOptionText = useCallback(
    (option: any) => {
      return option?.id ? formatLegalEntityName(option) : emptyText;
    },
    [emptyText]
  );

  if (multiple) {
    return (
      <ReferenceArrayInput
        reference={LegalEntity}
        source={source}
        filterToQuery={filterToQuery}
        allowEmpty={allowEmpty}
        {...props}
      >
        <AutocompleteArrayInput
          allowEmpty={allowEmpty}
          optionText={formatOptionText}
          options={{
            disabled,
            ...options,
          }}
        />
      </ReferenceArrayInput>
    );
  }

  return (
    <ReferenceInput
      reference={LegalEntity}
      source={source}
      filterToQuery={filterToQuery}
      allowEmpty={allowEmpty}
      {...props}
    >
      <AutocompleteInput
        optionText={formatOptionText}
        allowEmpty={allowEmpty}
        options={{
          disabled,
          ...options,
          InputProps: {
            // Disable Chrome address autocomplete dropdown
            // ( https://github.com/marmelab/react-admin/issues/5620#issuecomment-773773732 )
            autoComplete: "no",
            ...options?.InputProps,
          },
        }}
      />
    </ReferenceInput>
  );
}
