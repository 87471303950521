import { get } from "lodash";
import React from "react";
import { LinkField } from "./LinkField";

export function OrganisationField({ record, source, label }: any) {
  return (
    <LinkField
      record={record}
      label={label}
      source={source}
      renderText={() => get(record, source).name}
    />
  );
}

OrganisationField.defaultProps = {
  addLabel: true,
  label: "Organisation",
};
