import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "react-admin";
import React from "react";

const useStyles = makeStyles({
  uppercase: {
    textTransform: "uppercase",
  },
});

const UppercaseTextField = (props: any) => {
  const classes = useStyles();
  return <TextField className={classes.uppercase} {...props} />;
};

export { UppercaseTextField };
