import React, { useState } from "react";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { DialogControlButton } from "./DialogControlButton";
import { Colors, ConfirmMutationDialog } from "../../../../common";
import { TenancyBond } from "../../../../api";
import { DISBURSE_BOND_CLAIMS } from "../../../../api/resources/TenancyBond/tenancyBondsClient";

export function DisburseBondClaimsDialogControl({
  bondId,
  disabled,
}: {
  bondId: string;
  disabled: boolean;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const icon = getIcon(disabled);

  return (
    <div>
      <DialogControlButton
        onClick={onClick}
        icon={icon}
        disabled={disabled}
        iconClass={AccountBalanceWalletOutlinedIcon}
      >
        Disburse Bond
      </DialogControlButton>
      <ConfirmMutationDialog
        open={open}
        onClose={onClose}
        confirmation={{
          title: "Manually pay bond",
          content:
            "Are you sure you want to manually pay this bond from the managing entity's default bond wallet?",
        }}
        successNotification="The bond disbursal was succesful"
        failureNotification="Bond disbursal failed"
        mutationParams={{
          type: DISBURSE_BOND_CLAIMS,
          resource: TenancyBond,
          payload: {
            bondId,
          },
        }}
      />
    </div>
  );
}

function getIcon(disabled?: boolean) {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <AccountBalanceWalletOutlinedIcon
      style={{
        color: disabled ? Colors.TEXT.DARK.SECONDARY : Colors.AILO_RED,
        marginRight: 5,
      }}
      variant="outlined"
    />
  );
}
