import React from "react";
import { useListFilterContext } from "ra-core";
import { useGetBillsAttachments } from "../hooks";
import { ZipButton } from "./components/ZipButton";
import { ZipButtonWithManagement } from "./components/ZipButtonWithManagement";
import { ZipButtonWithoutManagement } from "./components/ZipButtonWithoutManagement";

type Props = {
  resource: string;
  selectedIds: string[];
};

export function BulkZipBillAttachmentsButton({
  resource,
  selectedIds,
}: Props): React.ReactElement | null {
  const { filterValues } = useListFilterContext();
  const { loading, attachments } = useGetBillsAttachments(selectedIds);
  const { managementId } = filterValues;

  if (loading || !attachments || attachments.length === 0) {
    return <ZipButton.Loading />;
  }

  return managementId ? (
    <ZipButtonWithManagement
      attachments={attachments}
      resource={resource}
      managementId={managementId}
    />
  ) : (
    <ZipButtonWithoutManagement attachments={attachments} resource={resource} />
  );
}
