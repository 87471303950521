import React from "react";
import { Company, OperationData } from "../../../../../api";
import { EditButton } from "../../../../../common";
import { EditCompanyDialog } from "./editCompanyDialog";

type CompanyRecord = OperationData<typeof Company, "GET_ONE">;

export function EditCompanyButton({
  company,
}: {
  company: CompanyRecord;
}): React.ReactElement {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <EditButton onClick={onClick} />
      <EditCompanyDialog open={open} onClose={onClose} company={company} />
    </div>
  );
}
