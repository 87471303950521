import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import React from "react";
import { PaymentMethodList } from "./paymentMethodList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
  },
}));
export const PaymentMethodsList = ({
  paymentMethods,
  listItemComponent,
}: any) => {
  const classes = useStyles();
  return (
    <List className={classes.root} subheader={<li />}>
      {paymentMethods.map(
        ({ title, data }: any) =>
          !!data && (
            <PaymentMethodList
              key={title}
              title={title}
              data={data}
              listItemComponent={listItemComponent}
            />
          )
      )}
    </List>
  );
};
