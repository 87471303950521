/* eslint-disable @typescript-eslint/no-explicit-any */
import gql from "graphql-tag";
import { CREATE } from "ra-core";
import { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { DISABLE, ENABLE } from "./ExternaltrustAccountOperations";
import {
  DisableExternalTrustAccountMutationVariables,
  EnableExternalTrustAccountMutationVariables,
} from "./ExternalTrustAccountClient.generated";

function mapParams({
  organisationId,
  organisationName,
  legalEntityId,
  systemType,
  priority,
  createSecret,
  username,
  password,
  mfa,
  secretArn,
}: Record<string, any>): any {
  let result: any = {
    organisationId,
    legalEntityId,
    priority,
  };
  if (createSecret) {
    result = {
      ...result,
      details: {
        credentials: {
          username,
          password,
          mfa,
        },
        systemType,
        organisationName,
      },
    };
  } else {
    result = {
      ...result,
      secretArn,
    };
  }
  return result;
}

export function externalTrustAccountClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CREATE:
      return {
        query: gql`
          mutation createExternalTrustAccount(
            $details: CreateExternalTrustAccountInput!
          ) {
            createExternalTrustAccount(details: $details) {
              id
              secretArn
              disabled
            }
          }
        `,
        variables: {
          details: mapParams(params),
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parseResponse(response: any) {
          return {
            data: response.data.createExternalTrustAccount,
          };
        },
      };

    case DISABLE:
      return {
        query: gql`
          mutation disableExternalTrustAccount(
            $details: DisableExternalTrustAccountInput!
          ) {
            disableExternalTrustAccount(details: $details) {
              id
              secretArn
              disabled
            }
          }
        `,
        variables: <DisableExternalTrustAccountMutationVariables>{
          details: params.details,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parseResponse(response: any) {
          return {
            data: response.data.disableExternalTrustAccount,
          };
        },
      };

    case ENABLE:
      return {
        query: gql`
          mutation enableExternalTrustAccount(
            $details: EnableExternalTrustAccountInput!
          ) {
            enableExternalTrustAccount(details: $details) {
              id
              secretArn
              disabled
            }
          }
        `,
        variables: <EnableExternalTrustAccountMutationVariables>{
          details: params.details,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parseResponse(response: any) {
          return {
            data: response.data.enableExternalTrustAccount,
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
