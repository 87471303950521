import { CREATE, DELETE, GET_LIST } from "react-admin";
import gql from "graphql-tag";
import { AiloRN } from "@ailo/ailorn";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  AddCentrepayDirectiveInput,
  DeleteCentrepayDirectiveInput,
  GetCentrepayPaymentDirectivesFilter,
} from "../../graphql/types.generated";
import {
  AddCentrepayDirectiveMutation,
  DeleteCentrepayDirectiveMutation,
  GetCentrepayPaymentDirectivesQuery,
} from "./centrepayPaymentDirectiveClient.generated";

export const CentrepayPaymentDirectiveClientDefinition = {
  [CREATE]: (params: { input: AddCentrepayDirectiveInput }) => ({
    query: gql`
      mutation addCentrepayDirective($input: AddCentrepayDirectiveInput!) {
        addCentrepayDirective(input: $input) {
          legalEntity
          managingEntity
          tenancy
        }
      }
    `,
    variables: params,
    parseResponse: (
      response: GraphQLResponse<AddCentrepayDirectiveMutation>
    ) => {
      return {
        data: response.data.addCentrepayDirective,
      };
    },
  }),
  [DELETE]: (params: { input: DeleteCentrepayDirectiveInput }) => ({
    query: gql`
      mutation deleteCentrepayDirective(
        $input: DeleteCentrepayDirectiveInput!
      ) {
        deleteCentrepayDirective(input: $input)
      }
    `,
    variables: params,
    parseResponse: (
      response: GraphQLResponse<DeleteCentrepayDirectiveMutation>
    ) => {
      return {
        data: response.data,
      };
    },
  }),
  [GET_LIST]: (params: { input: GetCentrepayPaymentDirectivesFilter }) => ({
    query: gql`
      query getCentrepayPaymentDirectives(
        $input: GetCentrepayPaymentDirectivesFilter!
      ) {
        getCentrepayPaymentDirectives(input: $input) {
          legalEntity
          managingEntity
          tenancy
        }
      }
    `,
    variables: params,
    parseResponse: (
      response: GraphQLResponse<GetCentrepayPaymentDirectivesQuery>
    ) => {
      return {
        data: response.data.getCentrepayPaymentDirectives.map(
          (centrepayTenancy) => ({
            legalEntity: AiloRN.from(centrepayTenancy.legalEntity),
            managingEntity: AiloRN.from(centrepayTenancy.managingEntity),
            tenancy: AiloRN.from(centrepayTenancy.tenancy),
          })
        ),
      };
    },
  }),
};

export const centrepayPaymentDirectiveClient = createClientFromDefinition(
  CentrepayPaymentDirectiveClientDefinition
);
