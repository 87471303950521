import { AiloRN } from "@ailo/ailorn";
import { MoneyInterface } from "@ailo/money";
import gql from "graphql-tag";
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from "react-admin";
import {
  buildPaginationVariables,
  createClientFromDefinition,
  GraphQLResponse,
  PaginationParams,
  specifyDefinition,
  specifyOperation,
} from "../../common";
import { TaxTreatment } from "../../graphql/types.generated";
import {
  ArchiveManagementFeeBlueprintMutation,
  ArchiveManagementFeeBlueprintMutationVariables,
  CreateManagementFeeBlueprintMutation,
  CreateManagementFeeBlueprintMutationVariables,
  GetManagementFeeBlueprintsQuery,
  GetManagementFeeBlueprintsQueryVariables,
  ManagementFeeBlueprintQuery,
  ManagementFeeBlueprintQueryVariables,
  UpdateManagementFeeBlueprintMutation,
  UpdateManagementFeeBlueprintMutationVariables,
} from "./managementFeeBlueprintClient.generated";

export const detailedBlueprintFragment = gql`
  fragment DetailedManagementFeeBlueprint on ManagementFeeBlueprint {
    id
    ailorn
    feeBlueprint {
      id
      ailorn: ailoRN
      name
      fixedAmount {
        cents
      }
      organisation {
        id
        name
      }
      oneWeekRentPercentage
      taxCategory {
        id
        name
      }
      taxTreatment
      chargeType
      frequency
      event {
        longDescription
      }
    }
    fixedAmount {
      cents
    }
    oneWeekRentPercentage
    taxTreatment
    description
  }
`;

const getOne = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      query managementFeeBlueprint($id: ID!) {
        blueprint: managementFeeBlueprint(id: $id) {
          ...DetailedManagementFeeBlueprint
        }
      }
      ${detailedBlueprintFragment}
    `,
    variables: <ManagementFeeBlueprintQueryVariables>{
      id: params.id,
    },
    parseResponse({
      data: { blueprint },
    }: GraphQLResponse<ManagementFeeBlueprintQuery>) {
      return {
        data: blueprint,
      };
    },
  };
});

const getList = specifyOperation(
  (params: {
    filter: {
      managementId?: string;
      feeBlueprintId?: string;
      status?: "archived" | "active";
    };
    pagination: PaginationParams;
  }) => {
    return {
      query: gql`
        query getManagementFeeBlueprints(
          $conditions: ManagementFeeBlueprintsQueryConditions
          $cursor: PageCursorWithoutSort
        ) {
          blueprints: managementFeeBlueprints(
            conditions: $conditions
            cursor: $cursor
          ) {
            items {
              ...DetailedManagementFeeBlueprint
            }
            pageInfo {
              total
              nextCursor
              hasMore
            }
          }
        }
        ${detailedBlueprintFragment}
      `,
      variables: <GetManagementFeeBlueprintsQueryVariables>{
        ...buildPaginationVariables(params.pagination),
        conditions: {
          managementId: params.filter.managementId ?? null,
          feeBlueprintId: params.filter.feeBlueprintId ?? null,
          archived: false,
        },
      },

      parseResponse(
        response: GraphQLResponse<GetManagementFeeBlueprintsQuery>
      ) {
        return {
          data: response.data.blueprints!.items.map((item) => ({
            ...item,
            id: AiloRN.fromString(item.ailorn).internalId,
          })),
          total: response.data.blueprints!.pageInfo.total,
          nextCursor: response.data.blueprints!.pageInfo.nextCursor,
          hasMore: response.data.blueprints!.pageInfo.hasMore,
        };
      },
    };
  }
);

const getMany = specifyOperation((params: { ids: string[] }) => {
  if (params.ids.length !== 1) {
    throw new TypeError(`We support only GET_MANY for 1 record for now.`);
  }
  const getOneOperation = getOne({
    id: params.ids[0],
  });
  return {
    ...getOneOperation,
    parseResponse(response: GraphQLResponse<ManagementFeeBlueprintQuery>) {
      const { data: blueprint } = getOneOperation.parseResponse?.(response);
      return {
        data: [blueprint],
      };
    },
  };
});

const create = specifyOperation(
  (params: {
    data: {
      managementId: string;
      feeBlueprintId: string;
      taxTreatment: TaxTreatment;
      fixedAmount?: MoneyInterface;
      oneWeekRentPercentage?: number;
    };
  }) => {
    return {
      query: gql`
        mutation createManagementFeeBlueprint(
          $input: CreateManagementFeeBlueprintInput!
        ) {
          blueprint: createManagementFeeBlueprint(input: $input) {
            id
            ailorn
          }
        }
      `,
      variables: <CreateManagementFeeBlueprintMutationVariables>{
        input: {
          managementId: params.data.managementId,
          feeBlueprintId: params.data.feeBlueprintId,
          taxTreatment: params.data.taxTreatment,
          fixedAmount: params.data.fixedAmount
            ? {
                cents: params.data.fixedAmount.cents,
              }
            : null,
          oneWeekRentPercentage: params.data.oneWeekRentPercentage,
        },
      },

      parseResponse({
        data: { blueprint },
      }: GraphQLResponse<CreateManagementFeeBlueprintMutation>) {
        return {
          data: blueprint,
        };
      },
    };
  }
);

const update = specifyOperation(
  (params: {
    data: {
      id: string;
      taxTreatment: "inclusive" | "exclusive";
      oneWeekRentPercentage?: number;
      fixedAmount?: MoneyInterface;
    };
  }) => {
    return {
      query: gql`
        mutation updateManagementFeeBlueprint(
          $input: UpdateManagementFeeBlueprintInput!
        ) {
          blueprint: updateManagementFeeBlueprint(input: $input) {
            id
            ailorn
          }
        }
      `,
      variables: <UpdateManagementFeeBlueprintMutationVariables>{
        input: {
          id: params.data.id,
          fixedAmount: params.data.fixedAmount
            ? {
                cents: params.data.fixedAmount.cents,
              }
            : null,
          oneWeekRentPercentage: params.data.oneWeekRentPercentage,
          taxTreatment: params.data.taxTreatment,
        },
      },

      parseResponse({
        data: { blueprint },
      }: GraphQLResponse<UpdateManagementFeeBlueprintMutation>) {
        return {
          data: blueprint,
        };
      },
    };
  }
);

const archive = specifyOperation((params: { id: string }) => {
  return {
    query: gql`
      mutation archiveManagementFeeBlueprint(
        $input: UpdateManagementFeeBlueprintInput!
      ) {
        blueprint: updateManagementFeeBlueprint(input: $input) {
          id
          ailorn
        }
      }
    `,
    variables: <ArchiveManagementFeeBlueprintMutationVariables>{
      input: {
        id: params.id,
        archived: true,
      },
    },
    parseResponse({
      data,
    }: GraphQLResponse<ArchiveManagementFeeBlueprintMutation>) {
      return {
        data: data.blueprint,
      };
    },
  };
});

export const ManagementFeeBlueprintClientDefinition = specifyDefinition({
  [GET_ONE]: getOne,
  [GET_LIST]: getList,
  [GET_MANY]: getMany,
  [CREATE]: create,
  [UPDATE]: update,
  [DELETE]: archive,
});

export const managementfeeBlueprintClient = createClientFromDefinition(
  ManagementFeeBlueprintClientDefinition
);
