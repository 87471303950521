import React from "react";
import { EditProps, GET_ONE, useGetOne } from "react-admin";
import { useHistory } from "react-router-dom";
import { ManagementFeeBlueprint, OperationData } from "../../api";
import { Edit } from "../../common";
import { ManagementFeeBlueprintForm } from "./form";

export const ManagementFeeBlueprintEdit = (
  props: EditProps
): React.ReactElement => {
  const history = useHistory();
  const managementFeeBlueprint = useGetOne<
    NonNullable<OperationData<typeof ManagementFeeBlueprint, typeof GET_ONE>>
  >(ManagementFeeBlueprint, props.id!);
  const name = managementFeeBlueprint.data?.feeBlueprint.name;

  return (
    <Edit
      {...props}
      title={name ? `Edit ${name} Blueprint` : "Edit Management Fee Blueprint"}
    >
      <ManagementFeeBlueprintForm
        redirect={history.goBack}
        name={name}
        toolbarOptions={{
          deleteButton: true,
        }}
      />
    </Edit>
  );
};
