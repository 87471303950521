// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import {
  ArchiveManagementFeeBlueprintMutation,
  ArchiveManagementFeeBlueprintMutationVariables,
} from "./cancel.generated";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function cancel(id: string, client: ApolloClient<unknown>) {
  const mutation = gql`
    mutation archiveManagementFeeBlueprint(
      $input: UpdateManagementFeeBlueprintInput!
    ) {
      blueprint: updateManagementFeeBlueprint(input: $input) {
        id
        ailorn
      }
    }
  `;

  const result = await client.mutate<
    ArchiveManagementFeeBlueprintMutation,
    ArchiveManagementFeeBlueprintMutationVariables
  >({ mutation, variables: { input: { id, archived: true } } });
  return result;
}
