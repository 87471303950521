import { Money } from "@ailo/money";
import { Container } from "@material-ui/core";
import React from "react";
import {
  FormDataConsumer,
  required,
  TextInputProps,
  useInput,
} from "react-admin";
import { CreateBillFormData } from "../..";
import { MoneyInput } from "../../../../common";
import { CheckboxWithText } from "../../../Transaction/components";

type AmountInputProps = Pick<TextInputProps, "style"> &
  Partial<
    Pick<
      CreateBillFormData,
      "managementFeeSchedule" | "payee" | "payer" | "taxAmount"
    >
  >;

export function AmountInput(
  props: Partial<AmountInputProps>
): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }: { formData: CreateBillFormData }) => (
        <AmountInputInner
          managementFeeSchedule={formData.managementFeeSchedule}
          payee={formData.payee}
          payer={formData.payer}
          taxAmount={formData.taxAmount}
          {...props}
        />
      )}
    </FormDataConsumer>
  );
}

function AmountInputInner({
  payee,
  payer,
  taxAmount,
  managementFeeSchedule,
  style,
}: AmountInputProps): React.ReactElement {
  const {
    input: { value: amount },
  }: { input: { value?: { cents: number } } } = useInput({ source: "amount" });
  const {
    input: { value: taxIncluded, onChange: onChangeTaxIncluded },
  } = useInput({
    defaultValue: true,
    source: "taxAutoCalculated",
  });
  const {
    input: { value: applyManagementFee, onChange: onChangeApplyManagementFee },
  } = useInput({
    defaultValue: false,
    source: "applyManagementFee",
  });

  const feeValid = !!(
    payee?.type === "management" &&
    payer?.type === "tenancy" &&
    managementFeeSchedule?.percent
  );

  return (
    <>
      <Container
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          padding: 0,
          ...style,
        }}
      >
        <Container style={{ flex: 4, padding: 0 }}>
          <MoneyInput
            label="Amount ($)"
            source="amount"
            validate={[required()]}
            style={{ width: "100%" }}
          />
        </Container>
        <Container
          style={{
            flex: 1,
            padding: 0,
            marginLeft: 16,
            alignItems: "center",
          }}
        >
          <CheckboxWithText
            {...{
              checked: taxIncluded,
              onChange: (event?: { target?: { checked?: boolean } }): void =>
                onChangeTaxIncluded(!!event?.target?.checked),
              label: "Includes GST",
              text: taxIncluded
                ? `GST ${Money.fromCents(amount?.cents ?? 0)
                    .divide(11)
                    .format({ symbol: true, withTrailingZeros: true })}`
                : "",
            }}
          />
        </Container>
      </Container>
      {!taxIncluded && (
        <>
          <MoneyInput
            label="GST Amount"
            source="taxAmount"
            style={{ width: "100%" }}
          />
          <div style={{ fontSize: 16, marginBottom: 10 }}>
            {`Total amount including tax: ${Money.fromCents(amount?.cents || 0)
              .add(Money.fromCents(taxAmount?.cents || 0))
              .format({ symbol: true, withTrailingZeros: true })}`}
          </div>
        </>
      )}
      {feeValid && (
        <Container
          style={{
            width: "100%",
            padding: 0,
          }}
        >
          <CheckboxWithText
            {...{
              checked: applyManagementFee,
              onChange: (event?: { target?: { checked?: boolean } }): void =>
                onChangeApplyManagementFee(!!event?.target?.checked),
              label: `Charge ${managementFeeSchedule!.percent * 100}%${
                amount?.cents
                  ? ` (${Money.fromCents(amount.cents)
                      .multiply(managementFeeSchedule!.percent)
                      .format({ symbol: true, withTrailingZeros: true })})`
                  : ""
              } management fee to investors once the bill is paid.`,
            }}
          />
        </Container>
      )}
    </>
  );
}
