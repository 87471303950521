import { CardContent } from "@material-ui/core";
import React, { ReactElement } from "react";
import { DetailCard } from "../../../common";
import { PlatformFeature } from "./PlatformFeature";
import { BondFeaturesList } from "./BondFeaturesList";

function BondFeaturesCard({
  featureToggles,
  display,
}: {
  featureToggles: PlatformFeature[];
  display: boolean;
}): ReactElement | null {
  if (!display) {
    return null;
  }
  return (
    <DetailCard title="Bond Feature Toggles">
      <CardContent style={{ padding: 0 }}>
        <BondFeaturesList featureToggles={featureToggles} />
      </CardContent>
    </DetailCard>
  );
}

export { BondFeaturesCard };
