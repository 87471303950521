import React from "react";
// TODO Use new SimpleForm
// eslint-disable-next-line no-restricted-imports
import { DateTimeInput, SimpleForm, TextInput } from "react-admin";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useLocation } from "react-router-dom";
import { Create } from "../../common";

const LiabilityStatusCreate = (props: Record<string, any>) => {
  const history = useHistory();
  const location = useLocation();
  const canGoBack = history.length > 0;
  return (
    <Create {...props}>
      <SimpleForm
        redirect={
          canGoBack
            ? props.history.goBack
            : `/Liabilities/${(location as any).state.record.liabilityId}/show`
        }
      >
        <TextInput label="Liability" source="liabilityId" disabled />
        <DateTimeInput source="archivedAt" />
        <Alert severity="warning">
          Setting empty archived at will UnArchive.
        </Alert>
      </SimpleForm>
    </Create>
  );
};
export { LiabilityStatusCreate };
