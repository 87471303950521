import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Colors } from "../colors";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10,
    backgroundColor: Colors.SMOKE,
  },
}));
export const ImageAvatar = ({ source, name, style }: any) => {
  const classes = useStyles();
  return (
    <Tooltip title={name}>
      <Avatar
        src={source}
        alt={name}
        className={classes.avatar}
        style={style}
      />
    </Tooltip>
  );
};
