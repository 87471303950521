import Alert from "@material-ui/lab/Alert";
import React from "react";
import { DateInput, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { ID } from "@ailo/ailorn-ns-entity";
import { Tenancy } from "../../../api/graphql/types.generated";

type Props = {
  entity: Tenancy;
  save: ({ id, startDate }: { id: ID; startDate: string }) => void;
  onFormChange: (...args: Array<any>) => any;
  error: string;
};

const PostEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const TenancyStartDateForm = ({
  entity,
  save,
  onFormChange,
  error,
}: Props): React.ReactElement => {
  return (
    <SimpleForm
      toolbar={<PostEditToolbar />}
      record={entity}
      save={save}
      submitOnEnter={false}
    >
      {error ? (
        <Alert severity="error" {...({ fullWidth: true } as any)}>
          {error}
        </Alert>
      ) : null}

      <DateInput
        label="Start date"
        source="startDate"
        fullWidth
        onChange={onFormChange}
      />
    </SimpleForm>
  );
};
