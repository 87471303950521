import { Button } from "@material-ui/core";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";

export const EditButton = ({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement => (
  <Button
    variant="outlined"
    color="primary"
    startIcon={<EditIcon />}
    onClick={onClick}
  >
    Edit
  </Button>
);
