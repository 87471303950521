import React, { useCallback, useMemo } from "react";
import { AutocompleteInput, required, TextInput } from "react-admin";
import { Typography } from "@material-ui/core";
import { SimpleForm, ReferenceInput, NonInput } from "../../common";
import { AbrLookupInput } from "./AbrLookupInput";

const SupplierForm = (
  props: React.ComponentProps<typeof SimpleForm>
): React.ReactElement => {
  const edit = !!props.record?.id;
  // TODO: should we use useState instead?
  const autoPopulatedFields = useMemo(() => new Set(), []);
  const abrLookupAutoPopulateFieldKeys = {
    abn: "abn",
    state: "addressState",
    postcode: "addressPostcode",
    name: "registeredEntityName",
    registeredEntityName: "registeredEntityName",
  };
  const nonOverWriteableFields = new Set(["name", "state", "postcode"]);
  const fieldsThatShouldBeConsistentWithABR = ["state", "postcode"];
  const onChange = useCallback(
    (event) => {
      autoPopulatedFields.delete(event.target.name);
    },
    [autoPopulatedFields]
  );

  return (
    <SimpleForm {...props}>
      {edit ? <TextInput source="id" disabled /> : <></>}
      <ReferenceInput
        alwaysOn
        label="Organisation"
        source={edit ? "organisation.id" : "organisation"}
        reference="Customers"
        filterToQuery={(searchTerm: string) => ({ searchTerm })}
        validate={[required()]}
        disabled={edit}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <AbrLookupInput
        autoFocus
        label="ABN"
        source="abn"
        expectedLength={11}
        autoPopulatedFields={autoPopulatedFields}
        abrLookupAutoPopulateFieldKeys={abrLookupAutoPopulateFieldKeys}
        nonOverWriteableFields={nonOverWriteableFields}
        fieldsThatShouldBeConsistentWithABR={
          fieldsThatShouldBeConsistentWithABR
        }
      />
      <TextInput
        label="Registered Entity Name"
        defaultValue=""
        source="registeredEntityName"
        disabled
      />
      <TextInput
        label="Display Name"
        source="name"
        validate={[required()]}
        onChange={onChange}
        helperText="This is the name the agency and consumers will use to identify the supplier in Ailo. This name does not have to match Entity, Business, nor Trading name."
      />
      <TextInput label="Email for payment receipts " source="emailAddress" />
      <NonInput>
        <Typography variant="subtitle1" gutterBottom style={{ marginTop: 44 }}>
          Registered Business Address
        </Typography>
      </NonInput>

      <TextInput label="Unit/Street Number" source="unitStreetNumber" />
      <TextInput label="Street Name" source="streetName" />
      <TextInput label="Suburb or Town" source="suburb" />
      <TextInput label="State" source="state" onChange={onChange} />
      <TextInput label="Postcode" source="postcode" onChange={onChange} />
      <TextInput
        label="Country"
        source="country"
        initialValue="Australia"
        disabled
      />
    </SimpleForm>
  );
};

export { SupplierForm };
