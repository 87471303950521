import { Alert } from "@material-ui/lab";
import React from "react";
import {
  TextInput,
  required,
  SelectInput,
  DateInput,
  BooleanInput,
  NumberInput,
  CreateProps,
  minValue,
} from "react-admin";
import { Create, SimpleForm } from "../../common";

const requiredValidate = [required()];

const RentCreate = (
  props: CreateProps & {
    record?: {
      managementId: string;
    };
  }
): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm redirect={() => props.history?.goBack()}>
        <TextInput label="Tenancy ID" source="tenancyId" />
        <SelectInput
          label="Rent Frequency"
          source="period"
          validate={requiredValidate}
          choices={[
            {
              id: "weekly",
              name: "weekly",
            },
            {
              id: "fortnightly",
              name: "fortnightly",
            },
            {
              id: "monthly",
              name: "monthly",
            },
          ]}
        />
        <NumberInput
          min="0"
          label="Rent Amount ($)"
          source="amount"
          validate={[...requiredValidate, minValue(0)]}
        />
        <DateInput
          label="Effective Date"
          source="effectiveDate"
          validate={requiredValidate}
        />
        <BooleanInput source="setsChargeDate" label="Set New Charge Date" />
        <Alert
          severity="warning"
          style={{
            marginBottom: 16,
          }}
        >
          Leave this as true if you want to change the date that rent is
          charged, ie 4th of the month to 23rd of the month, or want to change
          the rent frequency. If set to false, and the rent change is in the
          middle of a rent cycle, a pro-rata rent schedule will be created. For
          explanations of common errors click{" "}
          <a
            href="https://ailo.atlassian.net/wiki/spaces/AILO/pages/1648394515/Creating+Rents"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Alert>
      </SimpleForm>
    </Create>
  );
};

export { RentCreate };
