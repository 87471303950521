import { Box, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ReactElement } from "react";
import {
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  required,
  SelectInput,
  useNotify,
} from "react-admin";
import { SimpleFormDialog } from "../../../../common";
import {
  DaysOfMonthInput,
  getMonthlyStartDate,
} from "../../../../common/inputs/DaysOfMonthInput";
import { getAnniversaryFields } from "../../../../features/AutoWithdrawPlan";
import { updateAutoWithdrawPlansForManagements } from "./bulkUpdateAutoWithdrawPlan";
import { ExampleManagementsCSV } from "../common";

const requiredValidate = [required()];

const FREQUENCIES = ["daily", "weekly", "fortnightly", "monthly"];

export function BulkUploadAutoWithdrawPlanDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement {
  const notify = useNotify();
  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title="Bulk Update Management Auto Withdraw Plans"
      submitLabel="Submit"
      save={async (formData) => {
        const promise = new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            resolve(reader.result as string)
          );
          reader.addEventListener("error", reject);
          reader.readAsText(formData.data.data.rawFile);
        });

        const csvString = await promise;

        try {
          const startDate =
            formData.frequency === "monthly"
              ? getMonthlyStartDate(
                  formData.daysOfMonth?.days ?? [],
                  formData.daysOfMonth?.includesLastDay ?? false
                )?.toString()
              : formData.startDate;

          if (!startDate) {
            throw new Error("Could not derive startDate");
          }

          const {
            anniversary,
            anniversaryDaysOfMonth,
            lastDayOfTheMonth,
          } = getAnniversaryFields({
            startDate,
            frequency: formData.frequency,
            daysOfMonth: formData.daysOfMonth,
          });

          await updateAutoWithdrawPlansForManagements({
            csvString,
            frequency: formData.frequency,
            anniversary,
            anniversaryDaysOfMonth,
            lastDayOfTheMonth,
            startDate,
          });
        } catch (error) {
          notify(`Could not update. ${error}`);
          return;
        }

        notify("Auto withdraw plans updated");
        onClose();
      }}
      onClose={onClose}
    >
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 16,
              }}
            >
              <SelectInput
                source="frequency"
                label="frequency"
                defaultValue="monthly"
                choices={FREQUENCIES.map((id) => ({ id, name: id }))}
              />
              {formData.frequency === "monthly" && (
                <DaysOfMonthInput
                  source="daysOfMonth"
                  label="Select the day(s) of the month transfers should occur"
                  validate={[required()]}
                />
              )}
              {formData.frequency === "monthly" ? (
                <TextField
                  label="Start Date"
                  value={
                    getMonthlyStartDate(
                      formData.daysOfMonth?.days ?? [],
                      formData.daysOfMonth?.includesLastDay ?? false
                    )?.toString() ?? ""
                  }
                  disabled
                />
              ) : (
                <DateInput
                  label="Start Date"
                  source="startDate"
                  validate={[required()]}
                />
              )}
            </Box>
          );
        }}
      </FormDataConsumer>

      <FileInput
        source="data.data"
        label="CSV file with list of Management IDs"
        accept="text/csv"
        validate={requiredValidate}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <Alert
        severity="info"
        {...{ fullWidth: true }}
        style={{ marginBottom: 12 }}
      >
        Managements must already have an Auto Withdraw Plan. This script will
        update the frequency while leaving all other settings the same (e.g.
        payment destinations).
      </Alert>
      <ExampleManagementsCSV />
    </SimpleFormDialog>
  );
}
