import { makeStyles } from "@material-ui/core";
import React from "react";
import { CopyToClipboardButton } from "./CopyToClipboardButton";

type Props = {
  /**
   * Value to be copied when clicked.
   */
  value: string;

  /**
   * Text to be displayed which is the same as value by default.
   */
  displayText?: string;

  /**
   * Tooltip displayed when the cursor is hovered on top of the element.
   */
  tooltip?: string;
};

const useStyles = makeStyles(() => ({
  content: {
    maxWidth: 55,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export const TruncatedCopyToClipboardTextButton = ({
  value,
  displayText = value,
  tooltip = displayText,
}: Props): React.ReactElement => {
  const classes = useStyles();
  return (
    <CopyToClipboardButton value={value} tooltip={tooltip}>
      <div className={classes.content}>{displayText}</div>
    </CopyToClipboardButton>
  );
};
