import DialogContent from "@material-ui/core/DialogContent";
import { DialogContentText } from "@material-ui/core";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CloseDialogActions } from "./CloseDialogActions";

/**
 * @deprecated Use `ErrorDialog` instead.
 */
const ErrorDialogContent = ({ title, onClose, error }: any) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{error?.message || error}</DialogContentText>
      </DialogContent>

      <CloseDialogActions onClick={onClose} />
    </>
  );
};

export { ErrorDialogContent };
