import React, { ComponentProps } from "react";
import { LinkField } from "./LinkField";

export function IdField(
  props: Omit<
    ComponentProps<typeof LinkField>,
    "linkable" | "includeApiTypeInLinkText"
  >
): React.ReactElement {
  return (
    <LinkField {...props} linkable={false} includeApiTypeInLinkText={false} />
  );
}

IdField.defaultProps = {
  addLabel: true,
  source: "id",
};
