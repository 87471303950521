import { ChipField } from "react-admin";
import React from "react";

const transcoServiceStyle = {
  backgroundColor: "#2b2929",
  color: "#ffffff",
};
const ailoServiceStyle = {
  backgroundColor: "#f50057",
  color: "#ffffff",
};

const ServiceField = ({ record, ...rest }: any) =>
  record ? (
    <ChipField
      source="service"
      record={record}
      {...rest}
      style={record.service === "AILO" ? ailoServiceStyle : transcoServiceStyle}
    />
  ) : null;

export { ServiceField };
