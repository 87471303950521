import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import buildGraphQLProvider from "ra-data-graphql";
import { LegacyDataProvider } from "react-admin";
import { v4 as uuid } from "uuid";
import { from } from "apollo-link";
import { envVars } from "../envVars";
import {
  AccountOwner,
  AutoPayLiability,
  AutoPayment,
  AutoWithdrawPlan,
  BankAccountBlackList,
  Bill,
  BillTaxCategory,
  BondAccount,
  CentrepayAccount,
  CentrepayFee,
  Company,
  Customer,
  DeprecatedCompany,
  DeprecatedPerson,
  Discrepancy,
  ExternalTrustAccount,
  Fee,
  FeeBlueprint,
  FeeTaxCategory,
  File,
  LedgerEvent,
  LegalEntity,
  Liability,
  LiabilityAdjustment,
  LiabilityCategory,
  LiabilityPaymentPlan,
  LiabilityPlanChangePreview,
  LiabilityStatus,
  Management,
  ManagementFee,
  ManagementFeeBlueprint,
  MigratingManagement,
  MigrationAgency,
  MigrationTask,
  NegativeWalletBalance,
  Notifications,
  NotificationPreference,
  PaymentMethod,
  PaymentMethodAiloFee,
  Person,
  Property,
  Reconciliation,
  ReconciliationDetail,
  RecurringFee,
  RecurringOwing,
  Rent,
  StatementProgress,
  Subscription,
  Supplier,
  Team,
  Tenancy,
  TenancyAgreement,
  TenancyBond,
  TenancyDeposits,
  Tenantship,
  Transaction,
  TrustAccounts,
  User,
  Wallet,
  WalletEntry,
  WalletOverdraftAllowance,
  WalletOwnerBalance,
  WalletTransfer,
  Withdrawal,
  CentrepayCrn,
  CentrepayPaymentDirective,
  OfflinePaymentAccount,
  ManagementFolio,
  OfflinePaymentProcessingEnabled,
  FeeEvent,
  TrustTransaction,
  ManagementAgreement,
} from "./ResourceName";
import { autoPayLiabilitiesClient } from "./resources/AutoPayLiability/autoPayLiabilitiesClient";
import { autoPaymentsClient } from "./resources/AutoPayment/autoPaymentsClient";
import { autoWithdrawPlansClient } from "./resources/AutoWithdrawPlan/autoWithdrawPlansClient";
import { bankAccountBlacklistsClient } from "./resources/BankAccountBlacklist/bankAccountBlacklistsClient";
import { billClient } from "./resources/Bill/billClient";
import { companiesClient } from "./resources/Company/companiesClient";
import { PersonClient } from "./resources/Person/PersonClient";
import { filesClient } from "./resources/File/filesClient";
import { accountOwnerClient } from "./resources/AccountOwner";
import introspectionQueryResultData from "./graphqlFragmentTypes.generated.json";
import { ledgerEventClient } from "./resources/LedgerEvent";
import { legalEntitiesClient } from "./resources/LegalEntity/legalEntitiesClient";
import { liabilitiesClient } from "./resources/Liability/liabilitiesClient";
import { liabilityAdjustmentClient } from "./resources/Liability/liabilityAdjustmentClient";
import { liabilityPlanChangePreview } from "./resources/Liability/liabilityPlanChangePreviewClient";
import { liabilityStatusClient } from "./resources/Liability/liabilityStatusClient";
import { liabilityPaymentPlansClient } from "./resources/LiabilityPaymentPlan/liabilityPaymentPlansClient";
import { managementFeeClient } from "./resources/ManagementFee/managementFeeClient";
import { managementsClient } from "./resources/Management/managementsClient";
import { migrationTaskClient } from "./resources/MigrationTask/migrationTaskClient";
import { notificationPreferencesClient } from "./resources/NotificationPreference/notificationPreferencesClient";
import { organisationClient } from "./resources/Organisation/organisationClient";
import { paymentMethodAiloFeesClient } from "./resources/PaymentMethod/paymentMethodAiloFeesClient";
import { paymentMethodsClient } from "./resources/PaymentMethod/paymentMethodsClient";
import { DeprecatedPersonClient } from "./resources/DeprecatedPerson/DeprecatedPersonClient";
import { propertiesClient } from "./resources/Property/propertiesClient";
import { reconciliationDetailsClient } from "./resources/Reconciliation/reconciliationDetailsClient";
import { reconciliationsClient } from "./resources/Reconciliation/reconciliationsClient";
import {
  feeBlueprintClient,
  feeTaxCategoryClient,
  feeEventClient,
} from "./resources/FeeBlueprint";
import { rentsClient } from "./resources/Rent/rentsClient";
import { discrepanciesClient } from "./resources/Report/discrepancies/discrepanciesClient";
import { liabilityCategoriesClient } from "./resources/Report/liabilityCategories/liabilityCategoriesClient";
import { walletBalancesClient } from "./resources/Report/walletBalances/walletBalancesClient";
import { walletOwnerBalancesClient } from "./resources/Report/walletOwnerBalances/walletOwnerBalancesClient";
import { subscriptionClient } from "./resources/Subscription/subscriptionClient";
import { supplierClient } from "./resources/Supplier/supplierClient";
import { tenanciesClient } from "./resources/Tenancy/tenanciesClient";
import { transactionsClient } from "./resources/Transaction/transactionsClient";
import { transcoClient } from "./resources/Transco/client/transcoClient";
import { usersClient } from "./resources/User/usersClient";
import { walletClient } from "./resources/Wallet";
import { walletEntryClient } from "./resources/WalletEntry/walletEntryClient";
import { withdrawalClient } from "./resources/Withdrawal/withdrawalClient";
import withFileUpload from "./withFileUpload";
import withTranscoRestClient from "./withTranscoRestClient";
import { walletTransferClient } from "./resources/WalletTransfer/walletTransferClient";
import { RaDataGraphqlClientResult } from "./common";
import { feeClient } from "./resources/Fee/FeeClient";
import { walletOverdraftAllowanceClient } from "./resources/WalletOverdraftAllowance/walletOverdraftAllowanceClient";
import { externalTrustAccountClient } from "./resources/ExternalTrustAccount/ExternalTrustAccountClient";
import { TenancyDepositsClient } from "./resources/TenancyDeposit/TenancyDepositsClient";
import { recurringFeeClient } from "./resources/RecurringFee";
import { recurringOwingClient } from "./resources/RecurringOwing";
import { statementProgressClient } from "./resources/StatementProgress/statementProgressClient";
import {
  migratingManagementsClient,
  UPLOAD_MIGRATION_CSV,
} from "./resources/MigratingManagement";
import { bondAccountClient } from "./resources/BondAccount/bondAccountClient";
import { migrationAgenciesClient } from "./resources/MigrationAgency";
import { teamClient } from "./resources/Team/teamsClient";
import { tenancyAgreementClient } from "./resources/TenancyAgreement/tenancyAgreementClient";
import { billTaxCategoryClient } from "./resources/Bill/billTaxCategoryClient";
import { tenancyBondsClient } from "./resources/TenancyBond/tenancyBondsClient";
import { managementfeeBlueprintClient } from "./resources/ManagementFeeBlueprint";
import { trustAccountClient } from "./resources/TrustAccount/trustAccountClient";
import { LocalStorage, localStorageKeys } from "../common";
import { centrepayAccountClient } from "./resources/Centrepay/centrepayClient";
import { notificationsClient } from "./resources/Notifications/notificationsClient";
import { centrepayCrnClient } from "./resources/CentrepayCrn/centrepayCrnClient";
import { centrepayPaymentDirectiveClient } from "./resources/CentrepayDirective/centrepayPaymentDirectiveClient";
import { offlinePaymentAccountClient } from "./resources/OfflinePaymentAccount/offlinePaymentAccountClient";
import { managementFolioClient } from "./resources/ManagementFolio/managementFolioClient";
import { offlinePaymentProcessingEnabledClient } from "./resources/OfflinePaymentProcessingEnabled/offlinePaymentProcessingEnabledClient";
import { operationNameLink } from "./operationNameLink";
import { centrepayFeeClient } from "./resources/Centrepay/centrepayFeeClient";
import { tenantshipsClient } from "./resources/Tenantships/tenantshipsClient";
import { trustTransactionsClient } from "./resources/TrustTransaction/transactionsClient";
import { managementAgreementClient } from "./resources/ManagementAgreement/managementAgreementClient";

function queryBuilder() {
  return (
    raFetchType: string,
    resourceString: string,
    params: Record<string, any>
  ): RaDataGraphqlClientResult => {
    const clientArgs = {
      raFetchType,
      resourceString,
      params,
    };

    switch (resourceString) {
      case Customer:
        return organisationClient(clientArgs);

      case Person:
        return PersonClient(clientArgs);

      case DeprecatedCompany:
        return companiesClient(clientArgs);

      case LegalEntity:
        return legalEntitiesClient(clientArgs);

      case DeprecatedPerson:
        return DeprecatedPersonClient(clientArgs);

      case Company:
        return companiesClient(clientArgs);

      case Management:
        return managementsClient(clientArgs);

      case ManagementAgreement:
        return managementAgreementClient(clientArgs);

      case ManagementFee:
        return managementFeeClient(clientArgs);

      case ManagementFolio:
        return managementFolioClient(clientArgs);

      case Team:
        return teamClient(clientArgs);

      case Tenancy:
        return tenanciesClient(clientArgs);

      case TenancyAgreement:
        return tenancyAgreementClient(clientArgs);

      case TenancyBond:
        return tenancyBondsClient(clientArgs);

      case TenancyDeposits:
        return TenancyDepositsClient(clientArgs);

      case Tenantship:
        return tenantshipsClient(clientArgs);

      case MigratingManagement:
        return migratingManagementsClient(clientArgs);

      case MigrationAgency:
        return migrationAgenciesClient(clientArgs);

      case MigrationTask:
        return migrationTaskClient(clientArgs);

      case Property:
        return propertiesClient(clientArgs);

      case Subscription:
        return subscriptionClient(clientArgs);

      case Rent:
        return rentsClient(clientArgs);

      case Fee:
        return feeClient(clientArgs);

      case Supplier:
        return supplierClient(clientArgs);

      case Bill:
        return billClient(clientArgs);

      case Liability:
        return liabilitiesClient(clientArgs);

      case LiabilityStatus:
        return liabilityStatusClient(clientArgs);

      case LiabilityAdjustment:
        return liabilityAdjustmentClient(clientArgs);

      case Transaction:
        return transactionsClient(clientArgs);

      case Reconciliation:
        return reconciliationsClient(clientArgs);

      case ReconciliationDetail:
        return reconciliationDetailsClient(clientArgs);

      case LiabilityCategory:
        return liabilityCategoriesClient(clientArgs);

      case WalletOwnerBalance:
        return walletOwnerBalancesClient(clientArgs);

      case NegativeWalletBalance:
        return walletBalancesClient(clientArgs);

      case Discrepancy:
        return discrepanciesClient(clientArgs);

      case User:
        return usersClient(clientArgs);

      case LiabilityPlanChangePreview:
        return liabilityPlanChangePreview(clientArgs);

      case WalletEntry:
        return walletEntryClient(clientArgs);

      case BankAccountBlackList:
        return bankAccountBlacklistsClient(clientArgs);

      case PaymentMethod:
        return paymentMethodsClient(clientArgs);

      case PaymentMethodAiloFee:
        return paymentMethodAiloFeesClient(clientArgs);

      case Notifications:
        return notificationsClient(clientArgs);

      case NotificationPreference:
        return notificationPreferencesClient(clientArgs);

      case File:
        return filesClient(clientArgs);

      case Withdrawal:
        return withdrawalClient(clientArgs);

      case FeeTaxCategory:
        return feeTaxCategoryClient(clientArgs);

      case FeeEvent:
        return feeEventClient(clientArgs);

      case BillTaxCategory:
        return billTaxCategoryClient(clientArgs);

      case FeeBlueprint:
        return feeBlueprintClient(clientArgs);

      case ManagementFeeBlueprint:
        return managementfeeBlueprintClient(clientArgs);

      case RecurringFee:
        return recurringFeeClient(clientArgs);

      case AutoWithdrawPlan:
        return autoWithdrawPlansClient(clientArgs);

      case AutoPayLiability:
        return autoPayLiabilitiesClient(clientArgs);

      case LiabilityPaymentPlan:
        return liabilityPaymentPlansClient(clientArgs);

      case AutoPayment:
        return autoPaymentsClient(clientArgs);

      case Wallet:
        return walletClient(clientArgs);

      case WalletTransfer:
        return walletTransferClient(clientArgs);

      case WalletOverdraftAllowance:
        return walletOverdraftAllowanceClient(clientArgs);

      case ExternalTrustAccount:
        return externalTrustAccountClient(clientArgs);

      case StatementProgress:
        return statementProgressClient(clientArgs);

      case BondAccount:
        return bondAccountClient(clientArgs);

      case OfflinePaymentAccount:
        return offlinePaymentAccountClient(clientArgs);

      case OfflinePaymentProcessingEnabled:
        return offlinePaymentProcessingEnabledClient(clientArgs);

      case CentrepayAccount:
        return centrepayAccountClient(clientArgs);

      case CentrepayCrn:
        return centrepayCrnClient(clientArgs);

      case CentrepayFee:
        return centrepayFeeClient(clientArgs);

      case CentrepayPaymentDirective:
        return centrepayPaymentDirectiveClient(clientArgs);

      case TrustAccounts:
        return trustAccountClient(clientArgs);

      case AccountOwner:
        return accountOwnerClient(clientArgs);

      case LedgerEvent:
        return ledgerEventClient(clientArgs);

      case RecurringOwing:
        return recurringOwingClient(clientArgs);

      case TrustTransaction:
        return trustTransactionsClient(clientArgs);

      default:
        throw new Error(`${resourceString} is not yet implemented`);
    }
  };
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = LocalStorage.getItem(localStorageKeys.accessToken);
  // return the headers to the context so httpLink can read them
  return {
    headers: { ...headers, Authorization: token ? `Bearer ${token}` : "" },
  };
});

const urlParams = new URLSearchParams(window.location.search);
const deviceId =
  urlParams.get("ailo_device") ||
  LocalStorage.getItem(localStorageKeys.ailoDeviceId) ||
  uuid();
LocalStorage.setItem(localStorageKeys.ailoDeviceId, deviceId);
const sessionId = urlParams.get("ailo_session") || uuid();

function fetchWithCorrelationId(
  input: RequestInfo,
  opts: RequestInit | undefined
): Promise<Response> {
  let headers = opts?.headers || {};
  headers = {
    ...headers,
    "Ailo-Device-Id": deviceId,
    "Ailo-Session-Id": sessionId,
    "Ailo-Correlation-Id": uuid(),
    "apollographql-client-name": envVars.clientName,
  };
  return fetch(input, { ...opts, headers });
}

const httpLink = from([
  operationNameLink(envVars.adminGateway.domain),
  new HttpLink({
    fetch: fetchWithCorrelationId,
    uri: envVars.adminGateway.domain,
  }),
]);

export async function createDataProvider(): Promise<LegacyDataProvider> {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const graphQLDataProvider = await buildGraphQLProvider({
    buildQuery: queryBuilder,
    clientOptions: {
      cache: new InMemoryCache({
        fragmentMatcher,
      }),
      link: authLink.concat(httpLink),
    },
  });

  const dataProvider: LegacyDataProvider = async (type, resource, params) => {
    // Needed for `useReference` workaround,
    // so that it returns empty resource if `id` arg is undefined.
    if (resource === "dumb") {
      await new Promise((resolve, reject) => {
        reject(new Error("dumb resource not found"));
      });
    }

    return graphQLDataProvider(type, resource, params);
  };

  const dataProviderWithFileUpload = withFileUpload(dataProvider, {
    [MigratingManagement]: [UPLOAD_MIGRATION_CSV],
  });
  return withTranscoRestClient(dataProviderWithFileUpload, transcoClient);
}
