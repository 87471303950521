import { Money } from "@ailo/money";
import React from "react";
import { SimpleShowLayout } from "react-admin";
import { OperationData } from "../../../api";
import { Liability, Property } from "../../../api/ResourceName";
import { LinkField, MoneyField } from "../../../common";
import { DetailCard } from "../../../common/ui/cards";
import { TenancyLiabilityEntryList } from "../../../features/Ledger/components/tenancyTransactions/TenancyLiabilityEntryList";

export const TenancyLiabilityCard = ({
  tenancy: { liability },
}: {
  tenancy: NonNullable<
    OperationData<
      typeof Property,
      "GET_ONE"
    >["managements"][number]["mostRecentTenancy"]
  >;
}) => {
  if (!liability) {
    return null;
  }

  return (
    <DetailCard
      title="Tenancy Liability"
      contentStyle={{
        padding: 0,
      }}
      contents={
        <SimpleShowLayout
          record={{
            ...liability,
            overdueAbsoluteAmount: Money.from(liability.overdueAmount).abs(),
          }}
          resource={Liability}
        >
          <LinkField source="id" sourceResource={Liability} />
          <MoneyField
            label={
              liability.overdueAmount.cents >= 0
                ? "Overdue amount (Balance owed)"
                : "Overpaid amount (Balance in credit)"
            }
            source="overdueAbsoluteAmount"
          />
          <>
            <TenancyLiabilityEntryList liabilityId={liability.id} />
          </>
        </SimpleShowLayout>
      }
    />
  );
};
