import { UPDATE } from "ra-core";
import React from "react";
import { SelectInput, TextInput } from "react-admin";
import { minimalTimezoneSet } from "compact-timezone-list";
import { Company, OperationData } from "../../../../../api";
import {
  ErrorDialog,
  SimpleFormDialog,
  useMutation,
} from "../../../../../common";
import { splitByComma } from "../../../../Company";

const useEditCompany = ({ onSuccess }: { onSuccess?(): void }) => {
  return useMutation(
    {
      type: UPDATE,
      resource: Company,
    },
    {
      onSuccess: ({ data: _data }) => onSuccess?.(),
      successMessage: "Person has been updated",
    }
  );
};

type CompanyRecord = OperationData<typeof Company, "GET_ONE">;

export const EditCompanyDialog = (props: {
  open: boolean | undefined;
  onClose(): void;
  company: CompanyRecord;
}): React.ReactElement => {
  const { open, onClose, company } = props;
  const [editCompany, { error }] = useEditCompany({
    onSuccess: onClose,
  });

  const submit = (formData: { reason: string }) => {
    return editCompany({
      payload: {
        ...company,
        ...formData,
      },
    });
  };

  if (error) {
    return (
      <ErrorDialog
        open={open}
        title="Updating company failed"
        error={error}
        onClose={onClose}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        ...company,
      }}
      title="Edit company"
      submitLabel="Update"
      cancelLabel="Cancel"
      open={open}
      onClose={onClose}
      save={submit}
      allowSubmitWhenPristine
    >
      <TextInput label="ABN" source="registeredEntityId" />
      <TextInput label="Legal Name" source="registeredEntityName" />
      <TextInput label="Primary Contact Email" source="emailAddress" />
      {company.organisation.orgType === "Agency" && (
        <TextInput
          label="Accounts Email"
          source="accountsEmail"
          helperText="Enter multiple email addresses by inserting a comma in-between"
          parse={splitByComma}
        />
      )}
      <TextInput label="Address 1" source="address1" />
      <TextInput label="Address 2" source="address2" />
      <TextInput label="Suburb" source="suburb" />
      <TextInput label="State" source="state" />
      <TextInput label="Postcode" source="postcode" />
      <TextInput label="Country" source="country" />
      <SelectInput
        source="timezone"
        alwaysOn
        choices={minimalTimezoneSet.map(({ tzCode }: { tzCode: string }) => ({
          id: tzCode,
          name: tzCode,
        }))}
      />
    </SimpleFormDialog>
  );
};
