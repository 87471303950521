import { get } from "lodash";
import React, { ReactElement } from "react";
import { BooleanField, DateField, FunctionField } from "react-admin";
import { LinkField } from "./LinkField";
import {
  InjectedFieldProps,
  PublicFieldProps,
} from "./utils/react-admin-types";

export function ActionInitiatorField({
  source,
  initiatedAtSource,
  initiatedBySource,
  ...props
}: {
  initiatedAtSource?: string;
  initiatedBySource?: string;
} & PublicFieldProps &
  InjectedFieldProps): ReactElement {
  return (
    <FunctionField
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={(record: any) => {
        const initiatedSource =
          source || initiatedAtSource || initiatedBySource;
        if (!initiatedSource) {
          throw new TypeError(
            `At least one of the following props has to be given: source, initiatedAtSource, initiatedBySource`
          );
        }
        const initiated = get(record, initiatedSource);
        const initiatedAt = initiatedAtSource
          ? get(record, initiatedAtSource)
          : undefined;
        const initiatedBy = initiatedBySource
          ? get(record, initiatedBySource)
          : undefined;

        if (!initiated) {
          return (
            <BooleanField
              record={{
                id: record.id,
                initiated: false,
              }}
              source="initiated"
            />
          );
        }

        if (initiatedBy && initiatedAt) {
          return (
            <>
              At{" "}
              <DateField
                record={{
                  id: record.id,
                  initiatedAt,
                }}
                source="initiatedAt"
                showTime
              />{" "}
              by{" "}
              <LinkField
                record={{
                  initiatedBy,
                }}
                source="initiatedBy"
              />
            </>
          );
        }

        if (initiatedBy) {
          return (
            <>
              By{" "}
              <LinkField
                record={{
                  id: record.id,
                  initiatedBy,
                }}
                source="initiatedBy"
              />
            </>
          );
        }

        if (initiatedAt) {
          return (
            <>
              At{" "}
              <DateField
                record={{
                  id: record.id,
                  initiatedAt,
                }}
                source="initiatedAt"
                showTime
              />
            </>
          );
        }

        return (
          <BooleanField
            record={{
              id: record.id,
              initiated: true,
            }}
            source="initiated"
          />
        );
      }}
    />
  );
}
ActionInitiatorField.defaultProps = {
  addLabel: true,
  label: "ActionInitiator",
};
