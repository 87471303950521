import React from "react";
import { useGetOne } from "react-admin";
import { formatAddress } from "@ailo/domain-helpers";
import { FileAttachment } from "../../hooks";
import { ZipButton } from "./ZipButton";
import { Management } from "../../../../api";

type Props = {
  managementId: string;
  resource: string;
  attachments: FileAttachment[];
};

export function ZipButtonWithManagement({
  attachments,
  resource,
  managementId,
}: Props): React.ReactElement | null {
  const { loading, data } = useGetOne(Management, managementId);

  if (loading) return <ZipButton.Loading />;

  const address = data?.property?.address;
  const prefix = address
    ? formatAddress(address, { format: "street" })
    : managementId;

  return (
    <ZipButton
      attachments={attachments}
      resource={resource}
      fileName={`${prefix}_Bills`}
    />
  );
}
