import React from "react";
import { CreateProps, useNotify, useRedirect } from "react-admin";
import { FeeBlueprint } from "../../api/graphql/types.generated";
import { Create } from "../../common";
import { useFeeBlueprintAnalytics } from "./useFeeBlueprintAnalytics";
import { FeeBlueprintForm } from "./form";

export const FeeBlueprintCreate = (props: CreateProps): React.ReactElement => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { onFeeBlueprintMutated } = useFeeBlueprintAnalytics();

  return (
    <Create
      {...props}
      onSuccess={({ data }: { data: FeeBlueprint }) => {
        redirect(`/FeeBlueprint/${data.id}/show`);
        notify("Fee blueprint created");
        onFeeBlueprintMutated("Fee Blueprint Created", data);
      }}
    >
      <FeeBlueprintForm redirect="show" />
    </Create>
  );
};
