import { formatPersonName } from "./formatPersonName";

/**
 * @deprecated Use helpers from `@ailo/domain-helpers`
 */
export const formatPersonOptionText = (person: {
  firstName: string;
  lastName?: string | null | undefined;
  phoneNo?: string | null | undefined;
}): string | null | undefined => {
  return person
    ? `${formatPersonName(person)} [${person.phoneNo || "no phone"}]`
    : null;
};
