import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import { DialogContentText } from "@material-ui/core";
import React from "react";
import { EditPaymentMethodAiloFeeWaiverForm } from "./form";
import { ConfirmCloseDialogActions } from "../../../../common/ui/dialogs";

function AddUpdatePaymentMethodFeeWaiverScreen(props: {
  onChange: any;
  onSubmit: any;
  onClose: any;
  values: any;
}) {
  const { onChange, onSubmit, onClose, values } = props;
  return (
    <>
      <DialogTitle>Waive service fees</DialogTitle>
      <DialogContent>
        <Container
          maxWidth="xs"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <DialogContentText>Add/update service fees waiver</DialogContentText>
          <EditPaymentMethodAiloFeeWaiverForm
            onChange={onChange}
            values={values}
            loading
          />
        </Container>
      </DialogContent>
      <ConfirmCloseDialogActions
        confirmText="Save changes"
        onConfirmClick={onSubmit}
        onCancelClick={onClose}
      />
    </>
  );
}

export { AddUpdatePaymentMethodFeeWaiverScreen };
