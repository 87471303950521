import { AiloRN } from "@ailo/ailorn";
import moment from "moment-timezone";
import React from "react";
import { Badge } from "../../../../common/ui/Badge";
import { ManagementFeeCard } from "./ManagementFeeCard";
import { RecurringFeeCard } from "./RecurringFeeCard";

export function PropertyFeeList({
  propertyId,
  managementFee,
  recurringFees,
}: any) {
  const isEmpty = recurringFees.length === 0 && !managementFee;

  if (isEmpty) {
    return <>There are no fees</>;
  }

  recurringFees.sort(
    (
      a: { nextOccurrence: null; startDate: moment.MomentInput },
      b: { nextOccurrence: null; startDate: moment.MomentInput }
    ) => {
      const aHasEnded = a.nextOccurrence === null;
      const bHasEnded = b.nextOccurrence === null;
      if (aHasEnded && !bHasEnded) return 1;
      if (bHasEnded && !aHasEnded) return -1;
      const aHasStarted = moment(a.startDate).isBefore(moment());
      const bHasStarted = moment(b.startDate).isBefore(moment());
      if (aHasStarted && !bHasStarted) return -1;
      if (bHasStarted && !aHasStarted) return 1;
      if (moment(a.startDate).isBefore(b.startDate)) return -1;
      return 1;
    }
  );
  return (
    <>
      {managementFee && (
        <ManagementFeeCard
          managementFee={managementFee} // TODO: Use management time-zone
          status={
            moment(managementFee.startDate).isBefore(moment()) ? (
              <Badge.Success>Current</Badge.Success>
            ) : (
              <Badge.Warning>Future</Badge.Warning>
            )
          }
        />
      )}
      {recurringFees.map((fee: { ailoRN: string }) => {
        return (
          <RecurringFeeCard
            key={fee.ailoRN}
            propertyId={propertyId}
            recurringFeeId={AiloRN.fromString(fee.ailoRN).internalId}
          />
        );
      })}
    </>
  );
}
