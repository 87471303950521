import { FormDataConsumer, GET_ONE, useInput } from "react-admin";
import React, { useEffect, useCallback } from "react";
import { AiloRN, services } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";
import { Property } from "../../..";
import { Colors, useQueryWithStore } from "../../../../common";
import { ManagementSwitcher } from "../../../Property/components";

type ManagementInputProps = { id: string };

const source = "management";
const property = "property";

export function ManagementInput(): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <div
          style={{
            width: "100%",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1, margin: 1, marginBottom: 24 }}>
            <div
              style={{
                marginBottom: 4,
                color: Colors.SMOKE,
                fontSize: 12,
              }}
            >
              Management
            </div>
            <ManagementInputInner {...formData[property]} />
          </div>
        </div>
      )}
    </FormDataConsumer>
  );
}

function ManagementInputInner({
  id,
}: ManagementInputProps): React.ReactElement | null {
  const { data, loaded, loading } = useQueryWithStore(
    {
      type: GET_ONE,
      resource: Property,
      payload: {
        id,
      },
    },
    { enabled: !!id }
  );

  const { managements } = data || {};

  const defaultValue = managements?.[managements.length - 1].id;

  const {
    input: { value: selectedManagementId, onChange: onChangeManagement },
  } = useInput({
    source,
    defaultValue,
  });

  const {
    input: { onChange: onChangeOrg },
  } = useInput({
    source: "organisationAilorn",
  });

  const {
    input: { onChange: onChangeManagingEntity },
  } = useInput({
    source: "managingEntityAilorn",
  });

  const {
    input: { onChange: onChangeManagementFeeSchedule },
  } = useInput({
    source: "managementFeeSchedule",
  });

  const onChange = useCallback(
    (managementId) => {
      const management = managements?.find((man) => man.id === managementId);
      const managingEntity = management?.managingEntity;
      const managingEntityAilorn = managingEntity?.ailoRN;
      const organisationId = managingEntity?.organisationId;
      const organisationAilorn = organisationId
        ? AiloRN.of(services.AuthZ.organisation, organisationId)
        : undefined;
      const managementFeeSchedule =
        management?.currentOrNextManagementFeeSchedule;

      onChangeManagement(managementId);
      onChangeManagingEntity(managingEntityAilorn);
      onChangeOrg(organisationAilorn);
      onChangeManagementFeeSchedule(managementFeeSchedule);
    },
    [
      managements,
      onChangeManagement,
      onChangeManagementFeeSchedule,
      onChangeManagingEntity,
      onChangeOrg,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(managements?.[managements.length - 1]?.id), [
    managements,
  ]);

  if (!loaded || loading || !managements || !selectedManagementId) {
    return (
      <ManagementSwitcher.Empty placeHolder="Select a property to see options" />
    );
  }

  return (
    <ManagementSwitcher
      disabled={managements.length === 1}
      value={selectedManagementId}
      managements={managements.filter(isPresent)}
      onChange={onChange}
    />
  );
}
