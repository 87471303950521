import { LocalDate, RecurringDate } from "@ailo/date";
import {
  Box,
  Button,
  FormHelperText,
  makeStyles,
  styled,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";
import { InputHelperText, InputProps, Labeled, useInput } from "react-admin";
import { Colors } from "..";

export type DaysOfMonthValue = {
  days: number[];
  includesLastDay: boolean;
};

type DaysOfMonthInputProps = {
  label?: string;
  helperText?: string;
} & InputProps;

const MonthlyRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  minHeight: 36,
  marginTop: 4,
  marginBottom: 4,
  marginLeft: 6.25,
  marginRight: 6.25,
});

const DayButtonBaseStyle = {
  height: 36,
  width: 36,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 18,
  padding: 0,
  minWidth: 0,
};

const LastDayButtonBaseStyle = {
  height: 36,
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 18,
};

const useStyles = makeStyles((theme) => ({
  DayButton: {
    ...DayButtonBaseStyle,
    background: "white",
    "&:hover": {
      background: theme.palette.grey,
    },
  },
  DayButtonSelected: {
    ...DayButtonBaseStyle,
    background: Colors.AILO_RED,
    "&:hover": {
      background: Colors.AILO_RED,
    },
  },
  LastDayButton: {
    ...LastDayButtonBaseStyle,
    background: "white",
    "&:hover": {
      background: theme.palette.grey,
    },
  },
  LastDayButtonSelected: {
    ...LastDayButtonBaseStyle,
    background: Colors.AILO_RED,
    "&:hover": {
      background: Colors.AILO_RED,
    },
  },
}));

// Returns null if the value is "empty" so it interacts with `isRequired` correctly
const sanitiseValue = (value: DaysOfMonthValue): DaysOfMonthValue | null => {
  return value.days.length === 0 && !value.includesLastDay ? null : value;
};

export function DaysOfMonthInput(
  props: DaysOfMonthInputProps
): React.ReactElement {
  const { input, isRequired } = useInput(props);

  const {
    value,
    onChange,
  }: {
    value: DaysOfMonthValue | null;
    onChange: (value: DaysOfMonthValue | null) => void;
  } = input;

  const { label, helperText } = props;

  const classes = useStyles();
  const selectedDays = value?.days ?? [];
  const includesLastDay = value?.includesLastDay ?? false;

  const toggleDay = (day: number): void => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];
    onChange(
      sanitiseValue({
        days: updatedDays,
        includesLastDay,
      })
    );
  };

  const toggleIncludesLastDay = (): void => {
    onChange(
      sanitiseValue({
        days: selectedDays,
        includesLastDay: !includesLastDay,
      })
    );
  };

  return (
    <>
      <Labeled label={label} isRequired={isRequired}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {[...new Array(4)].map((e, row) => {
            return (
              <MonthlyRow key={row}>
                {[...new Array(7)].map((e1, day) => {
                  const monthDay = day + 1 + row * 7;
                  const isSelected = selectedDays.includes(monthDay);
                  return (
                    <Button
                      onClick={() => toggleDay(monthDay)}
                      className={
                        isSelected
                          ? classes.DayButtonSelected
                          : classes.DayButton
                      }
                      key={monthDay}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          color: isSelected
                            ? "white"
                            : Colors.TEXT.DARK.PRIMARY,
                        }}
                      >
                        {monthDay}
                      </Typography>
                    </Button>
                  );
                })}
              </MonthlyRow>
            );
          })}

          <MonthlyRow>
            <Button
              onClick={toggleIncludesLastDay}
              className={
                includesLastDay
                  ? classes.LastDayButtonSelected
                  : classes.LastDayButton
              }
              style={{ textTransform: "none" }}
            >
              <Typography
                variant="body1"
                style={{
                  color: includesLastDay ? "white" : Colors.TEXT.DARK.PRIMARY,
                }}
              >
                Last day of the month
              </Typography>
            </Button>
          </MonthlyRow>
        </Box>
      </Labeled>
      <FormHelperText>
        <InputHelperText touched helperText={helperText} />
      </FormHelperText>
    </>
  );
}

export const getMonthlyStartDate = (
  selectedDays: number[],
  includesLastDay: boolean
): LocalDate | undefined => {
  const nextOccurrences = selectedDays.map((day) =>
    new RecurringDate({
      frequency: "monthly",
      anniversaryDay: day,
    }).getNextOccurrence()
  );

  if (includesLastDay) {
    nextOccurrences.push(
      new RecurringDate({
        frequency: "monthly",
        anniversaryDay: moment().daysInMonth(),
      }).getNextOccurrence()
    );
  }

  if (nextOccurrences.length === 0) {
    return undefined;
  }

  return LocalDate.min(...(nextOccurrences as [LocalDate, ...LocalDate[]]));
};
