import { AiloRN, services } from "@ailo/ailorn";
import gql from "graphql-tag";
import { CREATE, GET_ONE, UPDATE } from "react-admin";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  CancelRecurringFeeMutation,
  CancelRecurringFeeMutationVariables,
  CreateRecurringFeeMutation,
  CreateRecurringFeeMutationVariables,
  RecurringFeeQuery,
  RecurringFeeQueryVariables,
  UpdateRecurringFeeMutation,
  UpdateRecurringFeeMutationVariables,
} from "./recurringFeeClient.generated";

const detailedRecurringFeeFragment = gql`
  fragment DetailedRecurringFee on RecurringFee {
    id
    ailoRN: id
    name
    createdBy
    createdAt
    modifiedBy
    cancelled
    archived
    currentness
    startDate
    endDate
    description
    blueprint: blueprintV2 {
      id
      ailoRN
      name
      taxCategory {
        id
        name
      }
      organisation {
        id
        name
      }
      frequency
      anniversaryDay
      anniversaryMonth
    }
    managementAgreement {
      id
    }
    management {
      id
      property {
        id
        address {
          unitStreetNumber
          streetName
          suburb
          state
          country
          postcode
        }
      }
    }
    currentOrNextSchedule {
      id
      taxInclusiveAmount {
        cents
      }
      startDate
    }
    liability {
      __typename
      id
      overdueAmount {
        cents
      }
    }
    nextOccurrence {
      taxInclusiveAmount {
        cents
      }
      date
    }
    schedules {
      taxInclusiveAmount {
        cents
      }
      startDate
    }
  }
`;

function createDetailedRecurringFee(
  fee: NonNullable<GraphQLResponse<RecurringFeeQuery>["data"]["fee"]>
) {
  return {
    ...fee,
    id: AiloRN.fromString(fee.ailoRN).internalId,
    blueprintId: fee.blueprint.id,
    organisationId: fee.blueprint.organisation!.id,
    frequency: fee.blueprint.frequency,
    managementId: fee.management!.id,
    managementAgreementId: fee.managementAgreement!.id,
    propertyId: fee.management!.property.id,
    taxInclusiveAmount: fee.currentOrNextSchedule?.taxInclusiveAmount,
    startDate: fee.currentOrNextSchedule?.startDate,
  };
}

export const RecurringFeeClientDefinition = {
  [GET_ONE]: (params: { id: string }) => ({
    query: gql`
      query recurringFee($id: AiloRN!) {
        fee: recurringFee(id: $id) {
          ...DetailedRecurringFee
        }
      }
      ${detailedRecurringFeeFragment}
    `,
    variables: <RecurringFeeQueryVariables>{
      id: AiloRN.of(
        services.PropertyManagement.recurringFee,
        params.id
      ).toString(),
    },
    parseResponse(response: GraphQLResponse<RecurringFeeQuery>) {
      return {
        data: createDetailedRecurringFee(response.data.fee!),
      };
    },
  }),
  [CREATE]: (params: {
    data: {
      blueprintId: string;
      managementAgreementId: string;
      taxInclusiveAmount: { cents: number };
      startDate: string;
      description: string | null;
    };
  }) => ({
    query: gql`
      mutation createRecurringFee($input: RecurringFeeInput!) {
        fee: createRecurringFee(input: $input) {
          ...DetailedRecurringFee
        }
      }
      ${detailedRecurringFeeFragment}
    `,
    variables: <CreateRecurringFeeMutationVariables>{
      input: {
        blueprintId: AiloRN.of(
          services.PropertyManagement.feeBlueprint,
          params.data.blueprintId
        ).toString(),
        managementAgreementId: AiloRN.of(
          services.PropertyManagement.managementAgreement,
          params.data.managementAgreementId
        ).toString(),
        schedules: [
          {
            taxAmount: {
              cents: Math.round(params.data.taxInclusiveAmount.cents / 11),
            },
            taxInclusiveAmount: {
              cents: params.data.taxInclusiveAmount.cents,
            },
            startDate: params.data.startDate,
          },
        ],
        description: params.data.description,
      },
    },
    parseResponse(response: GraphQLResponse<CreateRecurringFeeMutation>) {
      return {
        data: createDetailedRecurringFee(response.data.fee!),
      };
    },
  }),
  [UPDATE]: (params: {
    data: {
      id: string;
      taxInclusiveAmount: { cents: number };
      startDate: string;
      description: string | null;
    };
  }) => ({
    query: gql`
      mutation updateRecurringFee($input: UpdateRecurringFeeInput!) {
        fee: updateRecurringFee(input: $input) {
          ...DetailedRecurringFee
        }
      }
      ${detailedRecurringFeeFragment}
    `,
    variables: <UpdateRecurringFeeMutationVariables>{
      input: {
        id: AiloRN.of(
          services.PropertyManagement.recurringFee,
          params.data.id
        ).toString(),
        futureSchedules: [
          {
            taxAmount: {
              cents: Math.round(params.data.taxInclusiveAmount.cents / 11),
            },
            taxInclusiveAmount: {
              cents: params.data.taxInclusiveAmount.cents,
            },
            startDate: params.data.startDate,
          },
        ],
        description: params.data.description,
      },
    },
    parseResponse(response: GraphQLResponse<UpdateRecurringFeeMutation>) {
      return {
        data: createDetailedRecurringFee(response.data.fee!),
      };
    },
  }),

  cancel: (params: { data: { id: string } }) => ({
    query: gql`
      mutation cancelRecurringFee($id: AiloRN!) {
        fee: cancelRecurringFee(input: { id: $id }) {
          ...DetailedRecurringFee
        }
      }
      ${detailedRecurringFeeFragment}
    `,
    variables: <CancelRecurringFeeMutationVariables>{
      id: AiloRN.of(
        services.PropertyManagement.recurringFee,
        params.data.id
      ).toString(),
    },
    parseResponse(response: GraphQLResponse<CancelRecurringFeeMutation>) {
      return {
        data: createDetailedRecurringFee(response.data.fee!),
      };
    },
  }),
};

export const recurringFeeClient = createClientFromDefinition(
  RecurringFeeClientDefinition
);
