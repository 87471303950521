import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { envVars } from "../../../../envVars";
import { LocalStorage, localStorageKeys } from "../../../../common";

const apiUrl = envVars.transco.domain;

const fetchJson = (url: string, options: any = {}) => {
  const token = LocalStorage.getItem(localStorageKeys.accessToken);
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`,
  };
  return fetchUtils.fetchJson(url, options);
};

const transcoRestClient = {
  getList: (
    resource: any,
    params: { pagination: { pageSize: any }; sort: any }
  ) => {
    const { pageSize } = params.pagination;
    const query = {
      sort: params.sort,
      pageSize,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetchJson(url).then(({ json }) => ({
      data: json.items,
      total: json.totalItems,
    }));
  },
  upload: async (
    resource: any,
    params: { data: { file: { file: RequestInfo } } }
  ) => {
    const blob = await fetch(params.data.file.file).then((r) => r.blob());
    const formData = new FormData();
    formData.append("file", blob);
    return fetchJson(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: json,
    }));
  },
};
export { transcoRestClient };
