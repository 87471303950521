export const Colors = {
  AILO_RED: "rgb(238,11,79)",
  SPACE_BLACK: "rgb(28,30,38)",
  SMOKE: "rgb(154,155,167)",
  CLOUD: "rgb(247,247,247)",
  LIGHT_BLUE: "rgb(149,181,205)",
  TENANT_BLUE: "rgb(68,74,216)",
  INVESTOR_ORANGE: "rgb(230,153,25)",
  STATUS: {
    ERROR: "rgb(230,74,25)",
    WARNING: "rgb(245,166,35)",
    SUCCESS: "rgb(71,158,50)",
    INFO: "rgb(0, 127, 255)",
  },
  TEXT: {
    DARK: {
      PRIMARY: "rgb(28,30,38)",
      SECONDARY: "rgba(28,30,38,0.60)",
      PLACEHOLDER: "rgba(28,30,38,0.50)",
    },
    SECONDARY_CHARCOAL: "rgba(41, 43, 50, 1)",
    GREEN: "rgb(60,134,43)",
    ORANGE: "rgb(195,130,21)",
    RED: "rgb(195,63,21)",
  },
};
