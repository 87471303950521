import Select from "@material-ui/core/Select";
import React, { useCallback, useMemo, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import { SelectedTenancy } from "../../../features/Ledger/components/tenancyTransactions/selectedTenancy";
import { opacify, Colors } from "../../../common/ui/colors";
import { TenancySwitcherItem } from "../../../features/Ledger/components/tenancyTransactions/tenancySwitcherItem";
import { getTenancyType, sortTenancies } from "../../../features/Tenancy";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 20,
    paddingLeft: 20,
    height: 40,
    backgroundColor: opacify(Colors.SMOKE, 0.1),
  },
  menuPaper: {
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: opacify(Colors.SPACE_BLACK, 0.1),
  },
}));

const TenancySwitcher = ({ onChange, value, tenancies }: any) => {
  const classes = useStyles();
  const sortedTenanciesDropDownOptions = useMemo(
    () =>
      sortTenancies(tenancies).map((tenancy) => ({
        value: tenancy.id,
        label: "",
      })),
    [tenancies]
  );
  const [, setMenuIsOpen] = useState(false);
  const renderMenuItem = useCallback(
    (item) => {
      const tenancy = tenancies.find((t: { id: any }) => t.id === item.value);
      return tenancy ? (
        <MenuItem
          style={{
            padding: 0,
          }}
          value={tenancy.id}
          key={tenancy.id}
        >
          <TenancySwitcherItem tenancy={tenancy} />
        </MenuItem>
      ) : null;
    },
    [tenancies]
  );

  const renderSelectedValue = (v: any) => {
    const tenancy = tenancies.find((t: { id: any }) => t.id === v);
    const tenancyType = getTenancyType(
      tenancy?.startDate,
      tenancy?.endDate,
      !!tenancy?.voidedAt
    );
    const tenantNames = tenancy?.tenants
      .filter((item: any) => !!item)
      .map((item: { tenant: any }) => item.tenant);
    return tenancy ? (
      <SelectedTenancy tenancyType={tenancyType} tenantNames={tenantNames} />
    ) : null;
  };

  const changeTenancy = (event: { target: { value: any } }) => {
    onChange(event.target.value);
  };

  return (
    <Select
      value={value || ""}
      className={classes.root}
      fullWidth
      renderValue={renderSelectedValue}
      onOpen={() => setMenuIsOpen(true)}
      onClose={() => setMenuIsOpen(false)}
      onChange={changeTenancy}
      MenuProps={{
        classes: {
          paper: classes.menuPaper,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
    >
      {sortedTenanciesDropDownOptions.map(renderMenuItem)}
    </Select>
  );
};

export { TenancySwitcher };
