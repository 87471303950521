import { FunctionField } from "react-admin";
import { formatRecurringDateFrequency } from "@ailo/date";
import { Money } from "@ailo/money";
import React from "react";
import { get } from "lodash";

export function MoneyField({
  record,
  source,
  taxTreatmentSource,
  frequencySource,
  frequency: frequencyProp,
  emptyText,
  ...props
}: any) {
  return (
    <FunctionField
      record={record}
      {...props}
      render={(record: any) => {
        const value = get(record, source);

        if (!value) {
          return emptyText ?? null;
        }

        const taxTreatment = taxTreatmentSource
          ? get(record, taxTreatmentSource)
          : undefined;
        const taxAdornment =
          taxTreatment === "inclusive"
            ? " (incl. tax)"
            : taxTreatment === "exclusive"
            ? " (excl. tax)"
            : taxTreatment === "noTax"
            ? " (no tax)"
            : undefined;
        const frequency =
          frequencyProp ||
          (frequencySource ? get(record, frequencySource) : undefined);
        const frequencyAdornment = frequency
          ? formatRecurringDateFrequency(frequency)
          : undefined;
        const adornment =
          [frequencyAdornment, taxAdornment].filter(Boolean).join(" ") ||
          undefined;
        return (
          <>
            {Money.from(value).format({
              withTrailingZeros: true,
            })}
            {adornment}
          </>
        );
      }}
    />
  );
}
MoneyField.defaultProps = {
  addLabel: true,
};
