import Box from "@material-ui/core/Box";
import React from "react";
import { Link } from "react-admin";
import { InvestorChip, TenantChip } from "../../../common/ui/chips";
import { RightChevronIcon } from "../../../common/ui/icons/chevronIcon";

export const PropertyTypeAside = ({
  item,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
}): React.ReactElement => {
  const { type, property } = item;
  const propertyId = property.id;
  return (
    <Box display="flex" alignItems="center">
      {type === "Investor" ? (
        <InvestorChip label="Investor" />
      ) : (
        <TenantChip label="Rental" />
      )}

      <Link to={`/Properties/${propertyId}/show`}>
        <RightChevronIcon />
      </Link>
    </Box>
  );
};
