import { Button, Link, TextField, useListContext } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { stringify } from "query-string";

const useStyles = makeStyles({
  link: {
    minWidth: "auto",
  },
});

const LinkToRelatedWallets = ({ record }: any) => {
  const { filterValues } = useListContext();
  const classes = useStyles();

  return record.category === "ailo liability" ? (
    <TextField
      source="category"
      style={{
        padding: "4px 5px 4px 11.5px",
        textTransform: "uppercase",
        fontSize: "13px",
      }}
    />
  ) : (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/walletOwnerBalances/list",
        search: stringify({
          filter: JSON.stringify({
            category: record.category,
            afterDate: filterValues.afterDate,
            beforeDate: filterValues.beforeDate,
          }),
        }),
      }}
      label={record.category}
      className={classes.link}
    />
  );
};

export { LinkToRelatedWallets };
