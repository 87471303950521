import { Error, Loading, useQuery } from "react-admin";
import React from "react";
import { Wallet } from "../../../api";
import { WalletCard } from "../../../features/Wallet/WalletCard";

export function TenancyWalletCard({
  tenancyAiloRN,
}: {
  tenancyAiloRN: string;
}): React.ReactElement {
  const { data, loading, error } = useQuery({
    type: "get_one_by_wallet_owner_ailorn",
    resource: Wallet,
    payload: {
      walletOwnerAiloRN: tenancyAiloRN,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <WalletCard
      title="Tenancy Wallet"
      wallet={data}
      walletOwnerAiloRN={tenancyAiloRN}
      showTransactions={false}
    />
  );
}
