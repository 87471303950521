import { keyBy } from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Datagrid, DateField, TextField, FunctionField } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { ListContextProvider } from "ra-core";
import {
  MoneyField,
  UppercaseTextField,
  replaceUnderscores,
  CopyableTextField,
} from "../../../common";
import { ServiceField } from "./serviceField";

const useStyles = makeStyles({
  card: {
    margin: "0.5em",
    verticalAlign: "top",
    marginBottom: "1em",
  },
  hiddenHeader: {
    visibility: "hidden",
    height: 1,
    lineHeight: 1,
    width: 100,
    paddingTop: 0,
    paddingBottom: 0,
  },
  visibleHeader: {
    visibility: "visible",
    width: 100,
    color: "rgb(28, 30, 38)",
  },
  paymentIdColumn: {
    width: 200,
  },
});

const DiscrepanciesGrid = ({ ids, data, ...rest }: any) => {
  const classes = useStyles();
  return (
    <div
      style={{
        margin: "1em",
      }}
    >
      {ids.map((id: string, index: number) => {
        // combine ledger and gateway transactions
        const allTxn = data[id].ledger
          .map((item: any) => ({ ...item, service: "AILO" }))
          .concat(
            data[id].gateway.map((item: any) => ({
              ...item,
              service: "TRANSCO",
            }))
          );
        const txnData = keyBy(allTxn, "paymentId");
        const txnIds = allTxn.map(({ paymentId }: any) => paymentId);

        const discrepancyRowStyle = () => ({
          backgroundColor: data[id].hasDiscrepancy ? "#ffe6e6" : "#fff",
        });

        return (
          <Card key={id} className={classes.card}>
            <CardContent>
              <ListContextProvider
                value={{ ...rest, selectedIds: [], data: txnData, ids: txnIds }}
              >
                <Datagrid
                  rowStyle={discrepancyRowStyle}
                  optimized
                  classes={{
                    headerCell:
                      index === 0
                        ? classes.visibleHeader
                        : classes.hiddenHeader,
                  }}
                >
                  <ServiceField label="Service" />
                  <DateField
                    label="Created"
                    source="createdAt"
                    showTime
                    sortable={false}
                  />
                  <DateField
                    label="Banking Settlement Date"
                    source="bankingSettlementDate"
                  />
                  <UppercaseTextField label="Status" source="status" />
                  <MoneyField
                    label="Total Amount"
                    source="totalAmount"
                    textAlign="right"
                  />
                  <FunctionField
                    label="Payment Method"
                    source="paymentMethod"
                    render={replaceUnderscores}
                  />
                  <TextField
                    label="Transaction Type"
                    source="transactionType"
                  />
                  <CopyableTextField
                    label="Payment Id"
                    source="paymentId"
                    headerClassName={classes.paymentIdColumn}
                  />
                </Datagrid>
              </ListContextProvider>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export { DiscrepanciesGrid };
