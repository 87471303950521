import React, { useState } from "react";
import { Button, ButtonProps, TextInput } from "react-admin";
import { Fee } from "../../api/ResourceName";
import { useMutation } from "../../common";
import { SimpleFormDialog } from "../../common/views/SimpleFormDialog";

export const ArchiveFeeButton = ({
  fee,
  ...props
}: Omit<ButtonProps, "onClick"> & {
  fee: { id: string };
}): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Fee,
    type: "archive",
  });

  return (
    <>
      <Button label="Cancel" onClick={() => setDialogOpen(true)} {...props} />
      {dialogOpen && (
        <SimpleFormDialog<{ id: string; archiveReason?: string }>
          title="Cancel Fee"
          submitLabel="Cancel Fee"
          cancelLabel="Back"
          initialValues={{
            id: fee.id,
            archiveReason: "",
          }}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          save={(data) => mutate({ payload: { data } })}
        >
          <TextInput
            variant="outlined"
            label="Reason for cancelling"
            source="archiveReason"
          />
        </SimpleFormDialog>
      )}
    </>
  );
};
