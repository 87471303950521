import { RESET_PAGINATION_RESOURCE } from "./types";

export type ResetPaginationResourceAction = {
  type: string;
  resource: string;
};
export function resetPaginationResource(
  resource: string
): ResetPaginationResourceAction {
  return {
    type: RESET_PAGINATION_RESOURCE,
    resource,
  };
}
