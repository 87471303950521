import gql from "graphql-tag";
import currency from "currency.js";
import { GET_LIST, GET_ONE } from "react-admin";
import { buildPaginationVariables, parseSortParam } from "../../common";

const VOID_TRANSACTION = "void_transaction";
const REFUND_TRANSACTION = "refund_transaction";
const PARTIAL_REFUND_TRANSACTION = "partial_refund_transaction";
const REVERSE_TRANSACTION = "reverse_transaction";
type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function transactionsClient({ raFetchType, params, resourceString }: Params) {
  switch (raFetchType) {
    case GET_LIST:
      return {
        query: gql`
          query transactionReport(
            $cursor: String
            $pageSize: Int!
            $sort: String
            $afterDate: Date
            $beforeDate: Date
            $businessTransactionId: String
          ) {
            transactionReport(
              cursor: { pageSize: $pageSize, cursor: $cursor, sort: $sort }
              filter: {
                search: [
                  { key: "filterDate", value: "processOrSettlementDate" }
                  {
                    key: "businessTransactionId"
                    value: $businessTransactionId
                  }
                ]
                dateRange: { afterDate: $afterDate, beforeDate: $beforeDate }
              }
            ) {
              pageInfo {
                total
                hasMore
                nextCursor
              }
              items {
                id
                businessTransactionId
                paymentTransactionId
                createdAt
                processOrSettlementDate
                clearedAt
                bankSettlementDate
                product
                originalPaymentAmount {
                  cents
                }
                totalPaymentAmount {
                  cents
                }
                ailoFeeAmount {
                  cents
                }
                paymentType
                transactionType
                payerId
                payeeId
                gatewayPaymentId
                status
                responseCode
                channel
                bankingChannel
                billType
                taxCategory
              }
            }
          }
        `,
        variables: {
          ...buildPaginationVariables(params.pagination),
          afterDate: params.filter.afterDate,
          beforeDate: params.filter.beforeDate,
          businessTransactionId: params.filter.businessTransactionId,
          sort: parseSortParam(params.sort),
        },
        parseResponse: (response: { data: { transactionReport: any } }) => {
          const { transactionReport } = response.data;
          const { pageInfo, items } = transactionReport;
          const foo = items.map((item: { businessTransactionId: any }) => {
            return { ...item, id: item.businessTransactionId };
          });
          const { total, nextCursor, hasMore } = pageInfo;
          return {
            data: foo,
            total,
            nextCursor,
            hasMore,
          };
        },
      };

    case GET_ONE:
      return {
        query: gql`
          query transaction($id: AiloRN!) {
            transactionDetailsById(businessTransactionId: $id) {
              type
              transactionRef
              transactionAmount {
                cents
              }
              requestedAmount {
                cents
              }
              feeAmount {
                cents
              }
              paymentMethod {
                id
                isOnceOff
                isDefaultIn
                isDefaultOut
                isAutoPay
                isAutoWithdraw
                deletedAt
                topUpFee {
                  feeBps
                  feeFlatCents
                }
                __typename
                ... on BankAccount {
                  bsb
                  accountName
                  accountNumber
                  id
                  __typename
                }
                ... on CreditCard {
                  number
                  category
                  expiry
                  id
                  type
                  __typename
                }
                ... on BPay {
                  billerCode
                  __typename
                }
              }
              liability {
                id
                reference
                category
                taxCategory
              }
              transactionStatus {
                status
                createdAt
                clearedAt
              }
              message
              payer
              createdAt
              paymentChannel
            }
          }
        `,
        variables: {
          id: params.id,
        },
        parseResponse: (response: {
          data: { transactionDetailsById: any };
        }) => {
          return {
            data: { ...response.data.transactionDetailsById, id: params.id },
          };
        },
      };

    case VOID_TRANSACTION:
      return {
        query: gql`
          mutation cleanUnclearedBusinessTransaction(
            $id: ID!
            $description: String!
          ) {
            cleanUnclearedBusinessTransaction(
              businessTransactionId: $id
              description: $description
            ) {
              status
            }
          }
        `,
        variables: {
          id: String(params.id),
          description: params.description,
        },
        parseResponse: (response: {
          data: { cleanUnclearedBusinessTransaction: any };
        }) => ({
          data: response.data.cleanUnclearedBusinessTransaction,
        }),
      };

    case REFUND_TRANSACTION:
      return {
        query: gql`
          mutation refund(
            $id: AiloRN!
            $description: String!
            $cascade: Boolean
          ) {
            refundLiabilityPayment(
              businessTxId: $id
              description: $description
              cascade: $cascade
            ) {
              businessTransactionStatus {
                status
              }
            }
          }
        `,
        variables: {
          id: String(params.id),
          description: params.description,
          cascade: params.cascade,
        },
        parseResponse: (response: {
          data: {
            refundLiabilityPayment: {
              businessTransactionStatus: { status: any };
            };
          };
        }) => {
          return {
            data:
              response.data?.refundLiabilityPayment?.businessTransactionStatus
                ?.status,
          };
        },
      };

    case PARTIAL_REFUND_TRANSACTION:
      return {
        query: gql`
          mutation partialRefund(
            $id: AiloRN!
            $description: String!
            $amount: MoneyInput!
            $walletId: String!
            $cascade: Boolean
          ) {
            partialRefundLiabilityPayment(
              businessTxId: $id
              description: $description
              amount: $amount
              destinationWalletId: $walletId
              cascade: $cascade
            ) {
              businessTransactionStatus {
                status
              }
            }
          }
        `,
        variables: {
          id: String(params.id),
          description: params.description,
          cascade: params.cascade,
          amount: { cents: currency(params.amount).intValue },
          walletId: params.walletId,
        },
        parseResponse: (response: {
          data: {
            partialRefundLiabilityPayment: {
              businessTransactionStatus: { status: any };
            };
          };
        }) => {
          return {
            data:
              response.data?.partialRefundLiabilityPayment
                ?.businessTransactionStatus?.status,
          };
        },
      };
    case REVERSE_TRANSACTION:
      return {
        query: gql`
          mutation reverse($businessTxId: ID!) {
            reverseBusinessTx(businessTxId: $businessTxId) {
              id
              idempotentKey
              status
            }
          }
        `,
        variables: {
          businessTxId: String(params.businessTxId),
        },
        parseResponse: (response: {
          data: { reverseBusinessTx: { status: any } };
        }) => {
          return {
            data: response.data?.reverseBusinessTx?.status,
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { transactionsClient };
