import { Company, OperationData, Person } from "../../../api";

export type LegalEntityShowRecord =
  | OperationData<typeof Person, "GET_ONE">
  | OperationData<typeof Company, "GET_ONE">;

export const isCompany = (
  legalEntity?: LegalEntityShowRecord
): legalEntity is OperationData<typeof Company, "GET_ONE"> =>
  legalEntity?.__typename === "Company";

export const isAgency = (
  company: OperationData<typeof Company, "GET_ONE">
): boolean => company.organisation.orgType === "Agency";
