import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { ListItemView } from ".";

const LoadingList = () => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Skeleton variant="text" height={50} />
      </Grid>
      <Grid item>
        <ListItemView
          header={<Skeleton variant="text" width={160} />}
          subHeader={<Skeleton variant="text" width={80} />}
          leftComponent={
            <Skeleton
              variant="rect"
              height={40}
              width={40}
              style={{
                marginRight: 16,
                borderRadius: 8,
              }}
            />
          }
          rightComponent={
            <Skeleton
              variant="rect"
              height={40}
              width={40}
              style={{
                borderRadius: 8,
              }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export { LoadingList };
