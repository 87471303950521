import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import React, { ReactElement } from "react";
import { DetailCardHeader } from "./DetailCardHeader";
import { ErrorBoundary } from "../../error";

const useStyles = makeStyles(() => ({
  root: {
    margin: 10,
    fontSize: "0.875rem",
  },
}));

export const BaseDetailCard = ({
  avatar,
  title,
  subheader,
  contents,
  children = contents,
  action,
  contentStyle,
  cardStyle,
}: any) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} style={cardStyle}>
      <DetailCardHeader
        title={title}
        subheader={subheader}
        avatar={avatar || null}
        action={action}
      />
      {children && <CardContent style={contentStyle}>{children}</CardContent>}
    </Card>
  );
};

const DetailCard = (props: any) => {
  return (
    <ErrorBoundary
      fallbackComponent={() => <DetailCardFallback title={props.title} />}
    >
      <BaseDetailCard {...props} />
    </ErrorBoundary>
  );
};

interface DetailCardFallbackProps {
  title: string;
  error?: Error;
}
function DetailCardFallback({
  title,
  error: _error,
}: DetailCardFallbackProps): ReactElement {
  return (
    <BaseDetailCard
      title={title}
      subheader="A technical error occurred. This content couldn't be loaded"
    />
  );
}

/**
 * This is required to use `DetailCard` with ra-compact-ui's `CompactShowLayout`
 * https://github.com/ValentinnDimitroff/ra-compact-ui#compactshowlayout
 */
DetailCard.displayName = "DetailCard";
export { DetailCard };
