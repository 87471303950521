/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import React, { useState } from "react";
import { Colors } from "../../../common/ui/colors";
import { TransferFundsDialog } from "./TransferFundsDialog";

const TransferButton = ({ onClick, children }: any) => (
  <Button
    style={{
      textTransform: "none",
    }}
    onClick={onClick}
  >
    <Box p={1} display="flex">
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <AccountBalanceWalletOutlinedIcon
        style={{
          color: Colors.AILO_RED,
          marginRight: 5,
        }}
        variant="outlined"
      />
      <Typography
        style={{
          color: Colors.AILO_RED,
          fontSize: "1rem",
          fontWeight: 530,
          paddingTop: 1,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Button>
);

export function TransferFundsButton({
  legalEntityId,
  wallets,
  transactionDescription,
}: {
  legalEntityId: string;
  wallets: any;
  transactionDescription?: string;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TransferButton onClick={onClick}>Transfer</TransferButton>
      <TransferFundsDialog
        open={open}
        onClose={onClose}
        legalEntityId={legalEntityId}
        wallets={wallets}
        transactionDescription={transactionDescription}
      />
    </div>
  );
}
