import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React from "react";
import { LegalEntity } from "../../../../../api/graphql/types.generated";
import { CopyableTextField, LegalEntityField } from "../../../../../common";
import { DetailCard } from "../../../../../common/ui/cards";

const LegalEntitiesTable = ({
  legalEntities,
}: {
  legalEntities: Array<Partial<LegalEntity>>;
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {legalEntities.map((legalEntity: Partial<LegalEntity>) => (
          <TableRow key={legalEntity.id}>
            <TableCell component="th" scope="row">
              <CopyableTextField
                label="Legal Entity ID"
                source="id"
                record={legalEntity}
              />
            </TableCell>
            <TableCell>
              <LegalEntityField record={legalEntity} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export function LegalEntitiesCard({
  legalEntities,
}: {
  legalEntities: Array<Partial<LegalEntity>>;
}): React.ReactElement {
  return (
    <DetailCard
      title="Legal Entities"
      record={legalEntities}
      contents={<LegalEntitiesTable legalEntities={legalEntities} />}
    />
  );
}
