import React from "react";
import { useMutation, useNotify, useRefresh, Button } from "react-admin";

const useStartMigrationTask = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    {
      type: "start_migration",
      resource: "MigrationTask",
    },
    {
      onSuccess: () => {
        refresh();
        notify(`Migration data refresh has been successfully triggered`);
      },
      onFailure: (error) => {
        notify(`Failed to refresh migration data. Error: ${error}`);
      },
    }
  );
};

export const RefreshMigrationDataButton = ({ legalEntityId }: any) => {
  const [refreshData] = useStartMigrationTask();

  const onClick = () =>
    refreshData({
      payload: {
        legalEntityId,
      },
    });

  return <Button onClick={onClick} label="Start data refresh" />;
};
