import { Box, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Big from "big.js";
import React, { useState } from "react";
import {
  CREATE,
  DateInput,
  FormDataConsumer,
  Labeled,
  required,
  SelectInput,
  useInput,
  useQuery,
} from "react-admin";
import { AutoWithdrawPlan } from "../../../resourceViews";
import { useMutation } from "../../../common/api";
import { LinkField } from "../../../common/fields";
import {
  DaysOfMonthInput,
  DaysOfMonthValue,
  getMonthlyStartDate,
} from "../../../common/inputs/DaysOfMonthInput";
import { ErrorDialog } from "../../../common/ui/dialogs";
import { SimpleFormDialog } from "../../../common/views/SimpleFormDialog";
import { getAnniversaryFields } from "../../AutoWithdrawPlan";
import { BankAccountSplitPaymentMethodsOfOwnerInput } from "../BankAccountSplitPaymentMethodsOfOwnerInput";

const frequencies = ["daily", "weekly", "fortnightly", "monthly"];
const MAX_ACCOUNTS = 20;

type Props = {
  open: boolean;
  onClose: () => void;
  legalEntityAiloRN: string;
  walletId: string;
  walletName?: string;
  transactionDescription?: string;
};

const Wallet = ({
  walletValidate,
  label,
  walletId,
  walletName,
}: {
  walletValidate: () => string | undefined;
  label: string;
  walletId: string;
  walletName?: string;
}): React.ReactElement => {
  useInput({
    source: "walletId",
    validate: walletValidate,
  });
  return (
    <Labeled label={label}>
      <LinkField
        renderText={walletName ? () => walletName : undefined}
        record={walletId}
        copyable={false}
      />
    </Labeled>
  );
};

export const AddAutoWithdrawPlanDialog = ({
  open,
  onClose,
  legalEntityAiloRN,
  walletId,
  walletName,
  transactionDescription,
}: Props): React.ReactElement => {
  const { data: hasAutoWithdrawPlanEnabled } = useQuery({
    type: "wallet_has_auto_withdraw_plan_enabled",
    resource: AutoWithdrawPlan,
    payload: { walletId },
  });

  const walletValidate = () =>
    hasAutoWithdrawPlanEnabled
      ? "This wallet already has an auto transfer plan enabled, you must disable it before making a new one."
      : undefined;

  const [createAutoWithdrawPlan, { error }] = useMutation<{
    walletId: string;
    paymentMethodDestinations: { [id in string]?: number };
    frequency: string;
    startDate: string;
    anniversary?: number;
    anniversaryDaysOfMonth?: number[];
    isLastDayOfTheMonth: boolean;
    payerLegalEntityId: string;
    userFacingDescription?: string;
  }>(
    {
      type: CREATE,
      resource: AutoWithdrawPlan,
    },
    {
      successMessage: `Auto withdraw plan has been created`,
    }
  );

  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;

  const submit = (formData: {
    startDate: string;
    frequency: "daily" | "weekly" | "fortnightly" | "monthly";
    paymentMethodDestinations: { [id in string]?: number };
    daysOfMonth?: DaysOfMonthValue;
  }) => {
    const startDate =
      formData.frequency === "monthly"
        ? getMonthlyStartDate(
            formData.daysOfMonth?.days ?? [],
            formData.daysOfMonth?.includesLastDay ?? false
          )?.toString()
        : formData.startDate;

    if (!startDate) {
      throw new Error("Could not derive startDate");
    }

    const {
      anniversary,
      anniversaryDaysOfMonth,
      lastDayOfTheMonth,
    } = getAnniversaryFields({
      startDate,
      frequency: formData.frequency,
      daysOfMonth: formData.daysOfMonth,
    });

    void createAutoWithdrawPlan({
      payload: {
        walletId,
        payerLegalEntityId: legalEntityAiloRN,
        startDate,
        frequency: formData.frequency,
        anniversary,
        anniversaryDaysOfMonth,
        isLastDayOfTheMonth: lastDayOfTheMonth,
        paymentMethodDestinations: formData.paymentMethodDestinations,
        userFacingDescription: transactionDescription,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Creating auto withdraw plan failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      title="Add Auto Transfer Plan"
      open={open}
      submitLabel="Add Plan"
      save={submit}
      onClose={onClose}
    >
      <Wallet
        label="Wallet"
        walletName={walletName}
        walletId={walletId}
        walletValidate={walletValidate}
      />
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 16,
              }}
            >
              <SelectInput
                source="frequency"
                label="frequency"
                defaultValue="monthly"
                choices={frequencies.map((id) => ({ id, name: id }))}
              />
              {formData.frequency === "monthly" && (
                <DaysOfMonthInput
                  source="daysOfMonth"
                  label="Select the day(s) of the month transfers should occur"
                  validate={[required()]}
                />
              )}
              {formData.frequency === "monthly" ? (
                <TextField
                  label="Start Date"
                  value={
                    getMonthlyStartDate(
                      formData.daysOfMonth?.days ?? [],
                      formData.daysOfMonth?.includesLastDay ?? false
                    )?.toString() ?? ""
                  }
                  disabled
                />
              ) : (
                <DateInput
                  label="Start Date"
                  source="startDate"
                  validate={[required()]}
                />
              )}
            </Box>
          );
        }}
      </FormDataConsumer>
      <BankAccountSplitPaymentMethodsOfOwnerInput
        label="Bank Accounts"
        source="paymentMethodDestinations"
        owner={legalEntityAiloRN}
        hasErrorAlreadyShowing={hasAutoWithdrawPlanEnabled}
        validate={[
          required("Please select an account"),
          (v: { [k in string]?: number | null }) => {
            const numberOfAccounts = Object.keys(v).filter((k) => v[k] !== null)
              .length;
            return numberOfAccounts > MAX_ACCOUNTS
              ? `No more than ${MAX_ACCOUNTS} accounts may be selected`
              : numberOfAccounts < 1
              ? "Please select an account"
              : undefined;
          },
          (v: { [k in string]?: number | null }) => {
            const total = Object.keys(v).reduce(
              (sum: Big, x: keyof typeof v) => sum.add(v[x] || 0),
              Big(0)
            );
            return total.eq(100)
              ? undefined
              : `Must add to 100% (currently ${total}%)`;
          },
        ]}
      />
      {hasAutoWithdrawPlanEnabled && (
        <div style={{ width: "100%" }}>
          <Alert severity="error">{walletValidate()}</Alert>
        </div>
      )}
    </SimpleFormDialog>
  );
};

// TODO: add optional input fields (endDate and setAsideAmount)
// TODO: extract anniversary field
