import { CardHeader } from "@material-ui/core";
import React from "react";
import { BulkUpdateAutoWithdrawPlanButton } from "./bulkUpdateAutoWithdrawPlan";
import { BulkUpdateManagementFeeBlueprintsButton } from "./bulkUpdateManagementFeeBlueprints";
import { BulkUploadSuppliersButton } from "./bulkUploadSuppliers";

export function ScriptsPage(): React.ReactElement {
  return (
    <div>
      <CardHeader title="Select a script to run:" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <BulkUploadSuppliersButton />
        <BulkUpdateAutoWithdrawPlanButton />
        <BulkUpdateManagementFeeBlueprintsButton />
      </div>
    </div>
  );
}
