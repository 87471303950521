import React from "react";
import { Colors, opacify } from "../../../../common";
import { BaseBadge } from "../../../../common/ui/Badge/baseBadge";

export function LastUsedPaymentMethodBadge({ style, children }: any) {
  return (
    <BaseBadge
      color={Colors.TENANT_BLUE}
      style={{
        ...style,
        bgcolor: opacify(Colors.TENANT_BLUE, 0.2),
        marginLeft: 1,
      }}
    >
      {children}
    </BaseBadge>
  );
}
