import { useGetOne } from "ra-core";

/**
 * Different implementation of `useReference` that uses `useGetOne` instead of `useGetMany`.
 * See ReferenceInput/index.js for info why is it needed
 * https://github.com/marmelab/react-admin/issues/2287#issuecomment-777129674
 */
export const useReferenceUsingUseGetOne = (props: any) => {
  const { reference, id } = props.id
    ? props
    : {
        reference: "dumb",
        id: undefined,
      };
  const { data, error, loading, loaded } = useGetOne(reference, id);

  if (reference === "dumb") {
    return {
      referenceRecord: undefined,
      error: undefined,
      loading: false,
      loaded: true,
    };
  }

  return {
    referenceRecord: error ? undefined : data,
    error,
    loading,
    loaded,
  };
};
