import React, { useState } from "react";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { required, SelectInput, useRecordContext } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { SimpleFormDialog, useMutation } from "../../common";
import { OperationParams, Team } from "../../api";
import { MOVE_MANAGEMENTS } from "../../api/resources/Team/teamsClient";

type MoveManagementsInput = OperationParams<
  typeof Team,
  typeof MOVE_MANAGEMENTS
>["data"];

interface TeamData {
  id: AiloRN;
  name: string;
  managements: { pageInfo: { total: number } };
}

function MoveManagementsButton(): React.ReactElement {
  const { teams } = useRecordContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Team,
    type: MOVE_MANAGEMENTS,
  });

  const fromTeams = teams
    .filter((team: TeamData) => team.managements.pageInfo.total > 0)
    .map((team: TeamData) => {
      return { name: team.name, id: team.id };
    });

  const toTeams = teams.map((team: TeamData) => {
    return { name: team.name, id: team.id };
  });

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Move Managements
      </Button>
      {dialogOpen && (
        <SimpleFormDialog<MoveManagementsInput>
          title="Move Managements To Team"
          submitLabel="Move"
          cancelLabel="Back"
          initialValues={{ fromTeam: undefined, toTeam: undefined }}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          save={(data) => mutate({ payload: { data } })}
        >
          <SelectInput
            label="From Team"
            source="fromTeam"
            alwaysOn
            translateChoice={false}
            validate={[required()]}
            choices={fromTeams}
          />
          <SelectInput
            label="To Team"
            source="toTeam"
            alwaysOn
            translateChoice={false}
            validate={[required()]}
            choices={toTeams}
          />
        </SimpleFormDialog>
      )}
    </div>
  );
}

export { MoveManagementsButton };
