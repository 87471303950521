import React from "react";
import type { Management, Tenancy } from "../../api/deprecatedTypes";

const DraftField = ({ record }: { record: Management | Tenancy }) => {
  return record.isDraft ? (
    <span
      style={{
        color: "rgb(245,166,35)",
      }}
    >
      Draft
    </span>
  ) : (
    <span
      style={{
        color: "green",
      }}
    >
      Published
    </span>
  );
};

export { DraftField };
