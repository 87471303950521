import React, { useState } from "react";
import { ConfirmActionDialog } from "../../../../common/ui/dialogs/ConfirmActionDialog/ConfirmActionDialog";

export function useDeleteCrnDialog(
  deleteCrnAndDirectiveMutation: () => void
): {
  showDeleteCrnDialog: () => void;
  deleteCrnDialog: React.ReactElement;
} {
  const [showDeleteCrnDialog, setShowDeleteCrnDialog] = useState(false);
  const deleteCrnDialog = (
    <ConfirmActionDialog
      open={showDeleteCrnDialog}
      onClose={() => setShowDeleteCrnDialog(false)}
      confirmAction={deleteCrnAndDirectiveMutation}
      dialogText={{
        title: "Confirm delete Centrepay CRN?",
        content:
          "This will remove both this tenant's CRN and the tenant's payment directive for this tenancy, if one exists.",
      }}
      failureNotification="Failed to delete CRN and/or Payment directive"
    />
  );
  return {
    showDeleteCrnDialog: () => setShowDeleteCrnDialog(true),
    deleteCrnDialog,
  };
}
