import React from "react";
import Button from "@material-ui/core/Button";
import { CancelBillDialog } from "./CancelBillDialog";

export function CancelBillButton(props: { billId: any; disabled: any }) {
  const { billId, disabled } = props;
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={onClick}
        disabled={disabled}
      >
        Cancel
      </Button>
      <CancelBillDialog open={open} onClose={onClose} billId={billId} />
    </div>
  );
}
