import React from "react";
import { Box } from "@material-ui/core";
import { TenantsCentrepayInfoProps } from "../TenantsCentrepayInfoProps";
import { useDeletePaymentDirective } from "../mutations/useDeletePaymentDirective";
import { useDeletePaymentDirectiveDialog } from "./dialogs/useDeletePaymentDirectiveDialog";
import { DeleteIcon } from "../../../common";

export function CurrentTenancyDirective({
  tenancy,
  legalEntity,
  managingEntity,
}: TenantsCentrepayInfoProps): React.ReactElement {
  const deletePaymentDirectiveMutation = useDeletePaymentDirective(
    legalEntity,
    managingEntity,
    tenancy
  );

  const {
    showDeleteDirectiveDialog,
    deletePaymentDirectiveDialog,
  } = useDeletePaymentDirectiveDialog(deletePaymentDirectiveMutation);

  return (
    <Box style={{ display: "flex" }}>
      Centrepay payment directive
      <Box onClick={showDeleteDirectiveDialog} style={{ cursor: "pointer" }}>
        <DeleteIcon style={{ height: 20 }} />
      </Box>
      {deletePaymentDirectiveDialog}
    </Box>
  );
}
