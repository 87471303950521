import React from "react";
import { BooleanInput, required, TextInput } from "react-admin";
import { OperationData } from "../../../../../api";
import { Person as PersonResource } from "../../../../../api/ResourceName";
import { SimpleFormDialog, useMutation } from "../../../../../common";

type Person = OperationData<typeof PersonResource, "GET_ONE">;

export const EditPersonEmailDialog = (props: {
  open: boolean | undefined;
  onClose(): void;
  person: Person;
}): React.ReactElement => {
  const { open, onClose, person } = props;
  const [mutate] = useMutation({
    resource: PersonResource,
    type: "update_email",
  });

  return (
    <SimpleFormDialog<{
      id: string;
      email: string;
      sendEmailChangeWarningEmail?: boolean;
    }>
      initialValues={{
        id: person.user?.id,
        email: person.emailAddress,
        sendEmailChangeWarningEmail: true,
      }}
      title="Update email"
      submitLabel="Update"
      open={open}
      onClose={onClose}
      save={(data) => mutate({ payload: data })}
      allowSubmitWhenPristine
    >
      <TextInput label="Email" source="email" validate={[required()]} />
      <BooleanInput
        label="Send warning to previous email"
        source="sendEmailChangeWarningEmail"
      />
    </SimpleFormDialog>
  );
};
