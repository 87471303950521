import React from "react"; // eslint-disable-line no-use-before-define
import { SelectInput } from "react-admin";
import { capitalize } from "lodash";

export function ArchiveStatusInput(
  props: React.ComponentProps<typeof SelectInput>
): React.ReactElement | null {
  return (
    <SelectInput
      source="status"
      alwaysOn
      choices={["active", "archived"].map((status) => ({
        id: status,
        name: capitalize(status),
      }))}
      emptyText="All"
      {...props}
    />
  );
}
