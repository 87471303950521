import React from "react";
import { GET_ONE, useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { OfflinePaymentProcessingEnabled } from "../../../../api/ResourceName";
import { AutoRentProcessingSwitchContainer } from "./AutoRentProcessingSwitchContainer";

interface Params {
  tenancy: AiloRN;
}

const PaymentProcessingEnabledToggle = ({
  tenancy,
}: Params): React.ReactElement | null => {
  const {
    data,
  }: {
    data?: boolean;
  } = useQuery({
    type: GET_ONE,
    resource: OfflinePaymentProcessingEnabled,
    payload: {
      tenancy: tenancy.toString(),
    },
  });
  const offlinePaymentProcessingEnabled = !!data;
  return (
    <AutoRentProcessingSwitchContainer
      offlinePaymentProcessingEnabled={offlinePaymentProcessingEnabled}
      tenancy={tenancy}
    />
  );
};
export { PaymentProcessingEnabledToggle };
