/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import {
  FormDataConsumer,
  Labeled,
  TextInput,
  required,
  useMutation,
  useNotify,
  useRefresh,
  UseMutationValue,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { MoneyInterface } from "@ailo/money";
import {
  ErrorDialog,
  minMoney,
  MoneyInput,
  SimpleFormDialog,
} from "../../common";
import { WalletTransfer } from "../../api";
import {
  descriptionHelperText,
  maxUserFacingDescLength,
  walletStyles,
} from "./helpers";

type FormData = {
  walletId: number;
  internalNotes: string;
  userFacingDescription: string;
  amount: MoneyInterface;
};

const useCreditFromAiloBankWallet = ({
  onSuccess,
}: {
  onSuccess?(): void;
}): UseMutationValue => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    {
      type: "credit_from_ailo_bank",
      resource: WalletTransfer,
    },
    {
      onSuccess: ({ data }) => {
        const error = data?.error;
        if (error) {
          notify(`Failed to adjust wallet balance. Error: ${error}`);
        } else {
          refresh();
          notify(`Wallet balance has been adjusted`);
          onSuccess?.();
        }
      },
      onFailure: (error) => {
        notify(`Failed to adjust wallet balance. Error: ${error}`);
      },
    }
  );
};

type Props = { open: boolean; onClose: () => void; walletId: string };

export const AiloBankCreditDialog = ({
  open,
  onClose,
  walletId,
}: Props): React.ReactElement => {
  const [creditFromAiloBankWallet, { error }] = useCreditFromAiloBankWallet({
    onSuccess: onClose,
  });
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;
  const classes = walletStyles();

  const submit = async (formData: FormData) => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `Are you sure you would like to move ${formData.amount.cents} cents from Ailo Bank Wallet to walletId ${walletId}? `
      )
    ) {
      return;
    }
    await creditFromAiloBankWallet({
      payload: {
        amountInCents: formData.amount.cents,
        walletId,
        description: formData.internalNotes,
        userFacingDescription: formData.userFacingDescription,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Adjusting wallet balance failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        walletId,
        userFacingDescription: "Funds deposited",
      }}
      title="Transfer Credit from Ailo Bank Wallet"
      open={open}
      validate={(values: FormData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: any = {};
        if (!values.walletId) {
          errors.walletId = ["A wallet is required"];
        }
        return errors;
      }}
      submitLabel="Transfer"
      save={submit}
      onClose={onClose}
    >
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <Alert
                severity="warning"
                icon={false}
                style={{ marginBottom: 16 }}
              >
                <p>
                  Only do this if you know what you are doing! This is going to
                  move money from the Ailo Bank wallet to the current wallet.
                </p>
                <p>
                  This should be used in scenarios where money has been moved to
                  Ailo Bank <b>outside of the platform</b>.
                </p>
                <p>
                  For example, when debt it recovered from an investor or
                  supplier by an operations team.
                </p>
              </Alert>
              <Labeled label="How much? (in $)" fullWidth>
                <MoneyInput
                  source="amount"
                  validate={[required(), minMoney({ cents: 1 })].filter(
                    Boolean
                  )}
                />
              </Labeled>

              <Labeled label="Transfer details">
                <Alert severity="info" className={classes.transferDetails}>
                  The description will be visible by the investor on the
                  ownership statement.
                </Alert>
              </Labeled>

              <TextInput
                label="Description"
                source="userFacingDescription"
                validate={[required(), maxUserFacingDescLength()].filter(
                  Boolean
                )}
                helperText={descriptionHelperText(
                  formData.userFacingDescription
                )}
                fullWidth
              />
              <TextInput
                source="internalNotes"
                validate={[required()].filter(Boolean)}
                helperText="Include ticket number if possible eg: ZD-12345 or L3S-12345"
                fullWidth
              />
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleFormDialog>
  );
};
