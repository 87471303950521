import React from "react";
import { ListItemView } from "../../../../../common/ui/lists";
import { FeeStatusAside } from "./feeStatusAside";

export const AiloPaymentMethodFeesListItem = ({ item }: any) => {
  const { type } = item;
  return (
    <ListItemView
      header={type}
      rightComponent={<FeeStatusAside item={item} />}
    />
  );
};
