import { AiloRN } from "@ailo/ailorn";
import { CentrepayPaymentDirectiveType } from "./CentrepayPaymentDirectiveType";

export function getCurrentTenancyDirective(
  paymentDirectives: CentrepayPaymentDirectiveType[],
  tenancy: string
): CentrepayPaymentDirectiveType | undefined {
  return (paymentDirectives || []).find((paymentDirective) =>
    paymentDirective.tenancy.equals(AiloRN.from(tenancy))
  );
}
