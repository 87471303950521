import moment from "moment-timezone";

export type TenancyType = "Ingoing" | "Active" | "Former" | "Void";

export function getTenancyType(
  startDate?: string | null,
  endDate?: string | null,
  voided?: boolean
): TenancyType {
  if (voided) {
    return "Void";
  }

  if (moment().isBefore(moment(startDate), "day")) return "Ingoing";

  if (!endDate || moment().isSameOrBefore(moment(endDate), "day")) {
    return "Active";
  }

  return "Former";
}
