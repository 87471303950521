import React from "react";
import { Button } from "@material-ui/core";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { Colors } from "../../../../../common";
import { TenancyDepositRecord } from "../TenancyDepositRecord";
import { TransferDepositDialog } from "./TransferDepositDialog";

export const TransferDepositButton = ({
  deposit,
  tenancyAiloRN,
}: {
  deposit: TenancyDepositRecord;
  tenancyAiloRN: string;
}): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        disableElevation
        style={{
          textTransform: "none",
          color: Colors.AILO_RED,
        }}
        startIcon={<AccountBalanceWalletOutlinedIcon />}
        onClick={onClick}
      >
        Transfer
      </Button>
      <TransferDepositDialog
        deposit={deposit}
        tenancyAiloRN={tenancyAiloRN}
        open={open}
        onClose={onClose}
      />
    </div>
  );
};
