// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const bulkActionVars = (ids: Array<string>) => {
  const vars = {};

  for (let i = 0; i < ids.length; i++) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    vars[`id${i}`] = ids[i];
  }

  return vars;
};
