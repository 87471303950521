import React from "react";
import { ConfirmMutationDialog, RemoveButton } from "../../../../../common";
import { TenancyDeposits } from "../../../../../api";

export const CancelDepositButton = ({
  tenancyDepositId,
}: {
  tenancyDepositId: string;
}): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <RemoveButton onClick={onClick} iconAtStart>
        Cancel
      </RemoveButton>
      <ConfirmMutationDialog
        open={open}
        onClose={onClose}
        confirmation={{
          title: "Cancel initial payment",
          content:
            "Are you sure you want to cancel this initial payment request?",
        }}
        successNotification="The initial payment has been cancelled"
        failureNotification="Cancel initial payment failed"
        mutationParams={{
          type: "cancel_tenancy_deposit",
          resource: TenancyDeposits,
          payload: {
            tenancyDepositId,
          },
        }}
      />
    </div>
  );
};
