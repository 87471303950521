import { capitalize } from "lodash";
import { envVars } from "../../envVars";

export function updateTitle(): string {
  const title = `Ailo Admin ${capitalize(envVars.ailoApiEnv)}`;

  document.title = title;

  return title;
}
