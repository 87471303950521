import { ListSubheader } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Colors } from "../../colors";

interface DetailCardListSubheaderProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export function DetailCardListSubheader({
  children,
  style,
}: DetailCardListSubheaderProps): ReactElement {
  return (
    <ListSubheader
      style={{
        backgroundColor: Colors.CLOUD,
        marginTop: 9,
        marginLeft: -16,
        marginRight: -16,
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: 12,
        ...style,
      }}
    >
      {children}
    </ListSubheader>
  );
}
