import React, { useState } from "react";
import { useMutation, useNotify, useRefresh } from "react-admin";
import { AddUpdatePaymentMethodFeeWaiverScreen } from "./components";
import { EditButton } from "../../../common";
import { ErrorDialogContent, SlideUpDialog } from "../../../common/ui/dialogs";

const useAddFeeWaiver = ({ onClose, payload, setError }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const payloadAddFees = {
    owner: payload.owner,
    notes: payload.notes,
    filterEntries: [
      {
        paymentMethodType: "debit_card",
        active: !!payload.debitCardEnabled,
        startDate: payload.debitCardStart,
        endDate: payload.debitCardEnd,
      },
      {
        paymentMethodType: "credit_card",
        active: !!payload.creditCardEnabled,
        startDate: payload.creditCardStart,
        endDate: payload.creditCardEnd,
      },
      {
        paymentMethodType: "bank_account",
        active: !!payload.bankAccountEnabled,
        startDate: payload.bankAccountStart,
        endDate: payload.bankAccountEnd,
      },
    ],
  };
  return useMutation(
    {
      type: "create_update_ailo_fees_waiver",
      resource: "PaymentMethodAiloFees",
      payload: payloadAddFees,
    },
    {
      onSuccess: () => {
        onClose();
        refresh();
        notify(`Ailo Service Fee Waiver has been updated`);
      },
      onFailure: (error) => {
        setError(error.toString());
        notify(`Failed to edit Ailo Service Fee Waiver. Error: ${error}`);
      },
    }
  );
};

const PaymentMethodFeeWaiverDialog = ({
  open,
  onClose,
  owner,
  mergedFeesList,
  note,
}: any) => {
  const feesListMap = mergedFeesList.reduce(
    (map: { [x: string]: any }, obj: { type: string | number }) => {
      map[obj.type] = obj;
      return map;
    },
    {}
  );
  const [values, setValues] = useState<any>({
    bankAccountEnabled: feesListMap["Bank account"].filterName !== "",
    bankAccountStart:
      feesListMap["Bank account"].filterEntryEffectiveFromInclusive,
    bankAccountEnd: feesListMap["Bank account"].filterEntryEffectiveToInclusive,
    creditCardEnabled: feesListMap["Credit card"].filterName !== "",
    creditCardStart:
      feesListMap["Credit card"].filterEntryEffectiveFromInclusive,
    creditCardEnd: feesListMap["Credit card"].filterEntryEffectiveToInclusive,
    debitCardEnabled: feesListMap["Debit card"].filterName !== "",
    debitCardStart: feesListMap["Debit card"].filterEntryEffectiveFromInclusive,
    debitCardEnd: feesListMap["Debit card"].filterEntryEffectiveToInclusive,
    notes: note,
    error: undefined,
  });

  const changeHandler = (e: {
    target: { name: any; type: string; checked: any; value: any };
  }) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleClose = () => {
    setValues({});
    onClose();
  };

  const [addFeeWaiver] = useAddFeeWaiver({
    onClose: handleClose,
    payload: { ...values, owner },
    setError: (error: any) => setValues({ ...values, error }),
  });
  const screen = values.error ? (
    <ErrorDialogContent
      title="Adding service fee waivers failed"
      error={values.error}
      onClose={handleClose}
    />
  ) : (
    <AddUpdatePaymentMethodFeeWaiverScreen
      onChange={changeHandler}
      onSubmit={addFeeWaiver}
      onClose={handleClose}
      values={values}
    />
  );
  return (
    <SlideUpDialog open={open} onClose={onClose}>
      {screen}
    </SlideUpDialog>
  );
};

const AddPaymentMethodFeeWaiverDialog = ({
  owner,
  mergedFeesList,
  note,
}: any) => {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <EditButton onClick={onClick} />
      <PaymentMethodFeeWaiverDialog
        open={open}
        onClose={onClose}
        owner={owner}
        mergedFeesList={mergedFeesList}
        note={note}
      />
    </div>
  );
};

export { AddPaymentMethodFeeWaiverDialog };
