import { AiloRN, nsEntities } from "@ailo/ailorn";
import { get } from "lodash";
import React from "react";
import { FunctionField, FunctionFieldProps, Record } from "react-admin";
import {
  Bill,
  LedgerEvent,
  LegalEntity,
  Liability,
  Management,
  ManagementFolio,
  PaymentMethod,
  RecurringOwing,
  Supplier,
  Tenancy,
  Transaction,
} from "../../api";
import { CopyableTextField } from "./CopyableTextField";
import { LinkField } from "./LinkField";

export function DynamicAiloRNField({
  data,
  source,
}: { data?: Record; source: string } & Omit<
  FunctionFieldProps,
  "render"
>): React.ReactElement {
  return (
    <FunctionField
      record={data}
      render={(record?: Record) => {
        const field = get(record, source);
        if (!field) return null;
        if (AiloRN.isAiloRNString(field)) {
          try {
            const ailorn = AiloRN.from(field);
            const resource = getResource(ailorn);
            if (resource) {
              const { sourceResource, useAiloRN } = resource;
              return (
                <LinkField
                  includeApiTypeInLinkText
                  useAiloRNInLink={useAiloRN}
                  sourceResource={sourceResource}
                  record={field}
                />
              );
            }
          } catch (error) {
            if (!(error instanceof TypeError)) throw error;
          }
        }

        return <CopyableTextField source={source} record={record} />;
      }}
    />
  );
}

function getResource(
  ailorn: AiloRN
): { sourceResource: string; useAiloRN?: boolean } | null {
  if (ailorn.isA(nsEntities.propertymanagement.management)) {
    return { sourceResource: Management };
  }
  if (ailorn.isA(nsEntities.propertymanagement.managementfolio)) {
    return { sourceResource: ManagementFolio };
  }
  if (ailorn.isA(nsEntities.propertymanagement.tenancy)) {
    return { sourceResource: Tenancy };
  }
  if (ailorn.isA(nsEntities.authz.legalentity)) {
    return { sourceResource: LegalEntity };
  }
  if (ailorn.isA(nsEntities.bill.bill)) return { sourceResource: Bill };
  if (ailorn.isA(nsEntities.bill.supplier)) return { sourceResource: Supplier };
  if (ailorn.isA(nsEntities.ledger.businesstransaction)) {
    return { sourceResource: Transaction, useAiloRN: true };
  }
  if (ailorn.isA(nsEntities.ledger.liability)) {
    return { sourceResource: Liability };
  }
  if (ailorn.isA(nsEntities.paymentgateway.paymentmethod)) {
    return { sourceResource: PaymentMethod };
  }
  if (ailorn.isA(nsEntities.owing.recurringowing)) {
    return { sourceResource: RecurringOwing };
  }
  if (ailorn.isA(nsEntities.generalledger.event)) {
    return { sourceResource: LedgerEvent };
  }
  return null;
}
