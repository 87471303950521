import React from "react";
import { Button, DialogActions, DialogContentText } from "@material-ui/core";
import { useMutation, useNotify, useRefresh } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import { Colors } from "../../common/ui/colors";
import { CopyToClipboardButton } from "../../common";
import { SwitchWithText, CheckboxWithText } from "./components";

const RefundTransactionDialog = ({ open, onClose, transactionId }: any) => {
  const [description, setDescription] = React.useState("");
  const [cascade, setCascade] = React.useState(true);
  const [partialRefund, setPartialRefund] = React.useState(false);
  const [refundToWallet, setRefundToWallet] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [walletId, setWalletId] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleChange = (event: { target: { value: string } }) => {
    setDescription(event.target.value);
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const [refundTransaction] = useMutation(
    {
      type: "refund_transaction",
      resource: "Transactions",
      payload: {
        id: transactionId,
        description,
        cascade,
      },
    },
    {
      onSuccess: (result) => {
        onClose();
        refresh();
        notify(
          `Transaction ${transactionId} is being refunded. Status: ${result.data}`
        );
      },
      onFailure: (error) => {
        setErrorMessage(error.toString());
        notify(
          `Failed to refund transaction ${transactionId}. Error: ${error}`,
          "error"
        );
      },
    }
  );
  const [partialRefundTransaction] = useMutation(
    {
      type: "partial_refund_transaction",
      resource: "Transactions",
      payload: {
        id: transactionId,
        description,
        cascade,
        amount,
        walletId,
      },
    },
    {
      onSuccess: (result) => {
        onClose();
        refresh();
        notify(
          `Transaction ${transactionId} is being refunded. Status: ${result.data}`
        );
      },
      onFailure: (error) => {
        setErrorMessage(error.toString());
        notify(
          `Failed to refund transaction ${transactionId}. Error: ${error}`,
          "error"
        );
      },
    }
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Refund Transaction</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Refunding a transaction will return the money to the source, even if
          the source is outside of Ailo.
        </DialogContentText>
        <DialogContentText>
          Refunding will fail if there is not enough money in the wallet that
          the money needs to be taken from.
        </DialogContentText>
        <CheckboxWithText
          checked={cascade}
          onChange={(event: { target: { checked: boolean } }) =>
            setCascade(event.target.checked)
          }
          label="Refund associated fees"
          text={
            "If the above is not checked, make sure you refund the management fee " +
            " first (unless you don't need to), and make sure you check that there" +
            " will be enough money to refund the rent after you refund the management" +
            " fee."
          }
        />
        <TextField
          autoFocus
          id="name"
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleChange}
          style={{
            marginTop: 16,
          }}
          fullWidth
        />
        <DialogContentText
          style={{
            color: Colors.TEXT.RED,
          }}
        >
          {!description && "Description required"}
        </DialogContentText>
        <DialogContentText
          style={{
            color: Colors.TEXT.RED,
          }}
        >
          {errorMessage}
          <CopyToClipboardButton value={errorMessage} />
        </DialogContentText>
        <SwitchWithText
          checked={partialRefund}
          onChange={(event: { target: { checked: boolean } }) =>
            setPartialRefund(event.target.checked)
          }
          label="Partial refund"
          text="Partial refund is going to refund the transaction to the consumer of the liability, and, if wallet is specified, will move money to the nominated wallet. For rent refunds, the destination wallet will typically be a wallet of an individual tenant. Amount should  be less or equal to the amount of the transaction."
        />
        {partialRefund && (
          <>
            <DialogContentText
              style={{
                color: Colors.TEXT.RED,
              }}
            >
              Partial refund will NOT refund the payment (credit card, direct
              debit etc).
            </DialogContentText>
            <TextField
              autoFocus
              id="amount"
              type="number"
              value={amount}
              label="Amount to be refunded"
              variant="outlined"
              onChange={(event: { target: { value: string } }) =>
                setAmount(Number.parseFloat(event.target.value))
              }
              style={{
                marginTop: 16,
              }}
              fullWidth
            />
            <DialogContentText
              style={{
                color: Colors.TEXT.RED,
              }}
            >
              {!amount && "Amount required"}
            </DialogContentText>
            <SwitchWithText
              checked={refundToWallet}
              onChange={(event: { target: { checked: boolean } }) =>
                setRefundToWallet(event.target.checked)
              }
              label="To specified wallet"
              text={
                "If wallet is specified, money will end up there, if it's not - it will land in the liability consumer wallet (management wallet, or tenancy wallet etc)."
              }
            />
            {refundToWallet && (
              <>
                <TextField
                  autoFocus
                  id="walletId"
                  value={walletId}
                  label="WalletId to refund to"
                  variant="outlined"
                  onChange={(event: { target: { value: string } }) =>
                    setWalletId(event.target.value)
                  }
                  style={{
                    marginTop: 16,
                  }}
                  fullWidth
                />
              </>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            if (partialRefund) {
              if (description && amount) {
                partialRefundTransaction();
              }
            } else if (description) {
              refundTransaction();
            }
          }}
          color="primary"
        >
          Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const RefundTransactionField = ({
  transactionId,
  status,
  style,
}: {
  transactionId: string;
  status: string;
  style?: Record<string, any>;
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = React.useCallback(() => {
    setOpenDialog(false);
  }, []);
  const onClick = React.useCallback(() => {
    setOpenDialog(true);
  }, []);
  const refundingEnabled = status === "SUCCESS";
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={onClick}
        disabled={!refundingEnabled}
        style={style}
      >
        Refund Transaction
      </Button>
      <RefundTransactionDialog
        open={openDialog}
        onClose={closeDialog}
        transactionId={transactionId}
      />
    </div>
  );
};
