import Box from "@material-ui/core/Box";
import { ControlPoint } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { Colors } from "../../colors";
/**
 * @deprecated Use <Button /> directly
 */
const AddNewButton = ({ onClick, children }: any) => (
  <Button
    style={{
      textTransform: "none",
    }}
    onClick={onClick}
  >
    <Box p={1} display="flex" alignItems="center">
      <ControlPoint
        style={{
          color: Colors.AILO_RED,
          marginRight: "5px",
        }}
      />
      <Typography
        style={{
          color: Colors.AILO_RED,
          fontSize: "0.875rem",
          fontWeight: 530,
        }}
      >
        {children}
      </Typography>
    </Box>
  </Button>
);

export { AddNewButton };
