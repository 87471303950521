import { CREATE } from "react-admin";
import gql from "graphql-tag";
import type {
  RaDataGraphqlClientArgs,
  LiabilityStatus,
} from "../../deprecatedTypes";

function liabilityStatusClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CREATE:
      return {
        query: gql`
          mutation archiveLiability($liabilityId: ID!, $archivedAt: DateTime) {
            archiveLiability(
              input: { liabilityId: $liabilityId, archivedAt: $archivedAt }
            ) {
              id
              liabilityId
              archivedAt
              createdAt
              createdBy
              payerKycCheckRequired
              isArchived
            }
          }
        `,
        variables: params.data,
        parseResponse: (response: {
          data?: {
            archiveLiability?: LiabilityStatus;
          };
        }) => ({
          data: response?.data?.archiveLiability,
        }),
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { liabilityStatusClient };
