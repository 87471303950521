import { DateTimeWithTimeZone } from "@ailo/date";
import Box from "@material-ui/core/Box";
import { title } from "case";
import React from "react";
import {
  Colors,
  CopyableTextField,
  DATE_TIME_FORMAT,
  LinkField,
  ListItemView,
} from "../../../../common";
import { PaymentMethodAction } from "./paymentMethodAction";

export const BankAccountListItem = ({
  id,
  accountName,
  bsb,
  maskedAccountNumber,
  isAutoPay,
  isAutoWithdraw,
  isDefaultIn,
  isDefaultOut,
  isDeleted,
  isHidden,
  edit,
  wallet,
}: any) => {
  const header = accountName ? title(accountName) : "";
  const deletedStyle = {
    color: Colors.TEXT.DARK.SECONDARY,
  };
  const accountNumber = `${bsb}  ${maskedAccountNumber}`;
  return (
    <ListItemView
      header={header}
      subHeader={
        <>
          <Box
            style={{
              whiteSpace: "pre",
            }}
          >
            {accountNumber}
          </Box>
          <CopyableTextField
            source="id"
            record={{
              id,
            }}
            style={{
              marginTop: 1,
            }}
          />
          Added on{" "}
          {DateTimeWithTimeZone.from(wallet.createdAt).format(DATE_TIME_FORMAT)}
          <br />
          <LinkField record={wallet} />
        </>
      }
      rightComponent={
        <PaymentMethodAction
          paymentMethodId={id}
          isAutoPay={isAutoPay}
          isAutoWithdraw={isAutoWithdraw}
          isDefaultIn={isDefaultIn}
          isDefaultOut={isDefaultOut}
          isDeleted={isDeleted}
          isHidden={isHidden}
          edit={edit}
        />
      }
      style={isDeleted ? deletedStyle : null}
    />
  );
};
