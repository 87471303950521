import gql from "graphql-tag";

export const GeneralLedgerPaginatedEntriesFragment = gql`
  fragment GeneralLedgerPaginatedEntries on PaginatedEntries {
    pageInfo {
      total
      hasNext
      nextCursor
    }
    items {
      id
      amount {
        cents
      }
      userFacingDescription
      createdAt
      account {
        id
        subtype
        reference
        book
        accountOwner {
          reference
        }
      }
      ledgerEvent {
        id
      }
    }
  }
`;
