export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Ailo reference */
  AiloRN: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** IANA Time Zone name. (e.g. "America/Los_Angeles") */
  TimeZone: string;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** Date string. (e.g. "2007-12-03") */
  LocalDate: string;
  Long: number;
  GeneratedKey: string;
  ZoneId: any;
  /** Url syntax string */
  Url: any;
  /** Markdown syntax string */
  Markdown: any;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: Record<string, any>;
  URL: any;
  /** Base64-encoded binary file */
  Binary: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export enum AbaFileFormat {
  Balanced = "balanced",
  Unbalanced = "unbalanced",
}

export type AbrCompanyLookupResult = {
  __typename?: "AbrCompanyLookupResult";
  registeredEntityName: Scalars["String"];
  addressPostcode: Scalars["String"];
  addressState: Scalars["String"];
  abn: Scalars["String"];
  acn?: Maybe<Scalars["String"]>;
};

export type Account = {
  __typename?: "Account";
  accountOwner: AccountOwner;
  balance: Money;
  bill?: Maybe<Bill>;
  book: Book;
  id: Scalars["ID"];
  reference: Scalars["AiloRN"];
  subtype: AccountSubtype;
};

export type AccountOwner = {
  __typename?: "AccountOwner";
  availableBalance: Money;
  managingEntity?: Maybe<LegalEntityCompanion>;
  reference: Scalars["AiloRN"];
  totalBalance: Money;
};

export type AccountOwnerAvailableBalanceArgs = {
  book?: Book;
};

export type AccountOwnerTotalBalanceArgs = {
  book?: Book;
};

export enum AccountSortField {
  Book = "BOOK",
  Reference = "REFERENCE",
  Subtype = "SUBTYPE",
  Type = "TYPE",
}

export enum AccountSubtype {
  CashCleared = "CASH_CLEARED",
  CashClearing = "CASH_CLEARING",
  Contribution = "CONTRIBUTION",
  Disbursement = "DISBURSEMENT",
  Expense = "EXPENSE",
  Income = "INCOME",
  Payable = "PAYABLE",
  PayableCleared = "PAYABLE_CLEARED",
  PayableClearing = "PAYABLE_CLEARING",
  Receivable = "RECEIVABLE",
}

export type AccountTag = {
  __typename?: "AccountTag";
  accountOwnerReference: Scalars["AiloRN"];
  accountSubtype: AccountSubtype;
  book: Book;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type AccountTagBalance = {
  __typename?: "AccountTagBalance";
  accountTag: AccountTag;
  balance?: Maybe<Money>;
  balanceCreatedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isLatestEntry: Scalars["Boolean"];
  snowflake: Scalars["Long"];
};

export type AccountTagBalanceIsLatestEntryArgs = {
  asOf?: Maybe<Scalars["DateTime"]>;
};

export enum AccountType {
  Asset = "ASSET",
  Equity = "EQUITY",
  Liability = "LIABILITY",
}

export type AcknowledgeThreadInput = {
  threadAilorn: Scalars["AiloRN"];
  lastAcknowledgedAt: Scalars["DateTime"];
  lastAcknowledgedMessageAilorn: Scalars["AiloRN"];
};

export type Action = {
  __typename?: "Action";
  id: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  type: ActionType;
  typeLabel: Scalars["String"];
  dueDate: Scalars["DateTime"];
  project: Project;
  assignee?: Maybe<Person>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  modifiedBy?: Maybe<User>;
  doneAt?: Maybe<Scalars["DateTime"]>;
  status: ActionStatus;
  meta?: Maybe<ActionMeta>;
};

export type ActionCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type ActionInitiator = User | System;

export type ActionMeta =
  | AssignTenancyAgreementActionMeta
  | AssignRentActionMeta
  | AssignTenancyActionMeta
  | AssignNewManagementActionMeta
  | AssignFormActionMeta
  | RequestSignatureActionMeta
  | ManageDepositActionMeta
  | TrackDocusignActionMeta
  | TrackInspectionCompletedActionMeta
  | GenerateInspectionReportActionMeta
  | EndTenancyActionMeta
  | ConfirmInspectionScheduleActionMeta
  | AssignMessageActionMeta;

export enum ActionStatus {
  Active = "Active",
  Done = "Done",
}

export enum ActionType {
  Reminder = "Reminder",
  AssignTenancyAgreement = "AssignTenancyAgreement",
  AssignTenancy = "AssignTenancy",
  AssignNewManagement = "AssignNewManagement",
  AssignRent = "AssignRent",
  AssignForm = "AssignForm",
  RequestSignature = "RequestSignature",
  ManageDeposit = "ManageDeposit",
  TrackDocusign = "TrackDocusign",
  TrackInspectionCompleted = "TrackInspectionCompleted",
  GenerateInspectionReport = "GenerateInspectionReport",
  EndTenancy = "EndTenancy",
  ConfirmInspectionSchedule = "ConfirmInspectionSchedule",
  AssignMessage = "AssignMessage",
}

export type AddCentrepayDirectiveInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type AddCentrepayDirectiveOutput = {
  __typename?: "AddCentrepayDirectiveOutput";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type AddMemberToLegalEntityInput = {
  memberPersonId: Scalars["String"];
  legalEntityId: Scalars["String"];
  roleId: Scalars["String"];
};

export type AddMemberToOrganisationInput = {
  personId: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type Address = {
  __typename?: "Address";
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  postcode?: Maybe<Scalars["String"]>;
};

export type AddTrustAccountInput = {
  agency: Scalars["AiloRN"];
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
};

export type AdjustmentLiabilityEntry = LiabilityEntry & {
  __typename?: "AdjustmentLiabilityEntry";
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  description?: Maybe<Scalars["String"]>;
  descriptionIsCustom?: Maybe<Scalars["Boolean"]>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  reversedByAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  reversesAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  sourceId?: Maybe<Scalars["ID"]>;
  sourceType?: Maybe<SourceType>;
  /** @deprecated Irrelevant to adjustment liability entry */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type AdjustWalletBalanceInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletId: Scalars["ID"];
};

export type AgencyPropertiesQueryInput = {
  paginationParams: PaginationParams;
};

export type AgencyProperty = {
  __typename?: "AgencyProperty";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  property: Property;
  agencyOrganisation: Organisation;
  /**
   * returns the first to exist of active, next or previous management
   * in that order.
   */
  mostRelevantManagement?: Maybe<Management>;
  managements: Array<Management>;
  address: Address;
};

export type AiloForm = Form & {
  __typename?: "AiloForm";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
  name: Scalars["String"];
  template: AiloFormTemplate;
  /** Number of answers associated with this page */
  respondedFields: Scalars["Int"];
  /** Number of fields associated with this page (Excludes the 'EMPTY' field type) */
  totalFields: Scalars["Int"];
  pages: Array<AiloFormPage>;
  ailorn: Scalars["AiloRN"];
};

export type AiloFormField = {
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
};

export type AiloFormFieldDate = AiloFormField & {
  __typename?: "AiloFormFieldDate";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  placeholder?: Maybe<Scalars["String"]>;
  dateResponse?: Maybe<AiloFormResponseDate>;
};

export type AiloFormFieldEmpty = AiloFormField & {
  __typename?: "AiloFormFieldEmpty";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
};

export type AiloFormFieldInfo = {
  __typename?: "AiloFormFieldInfo";
  description: Scalars["Markdown"];
  icon?: Maybe<Scalars["Url"]>;
};

export type AiloFormFieldNumber = AiloFormField & {
  __typename?: "AiloFormFieldNumber";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  unit?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  numberResponse?: Maybe<AiloFormResponseNumber>;
};

export type AiloFormFieldText = AiloFormField & {
  __typename?: "AiloFormFieldText";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  placeholder?: Maybe<Scalars["String"]>;
  textResponse?: Maybe<AiloFormResponseText>;
};

export type AiloFormFieldYesNo = AiloFormField & {
  __typename?: "AiloFormFieldYesNo";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  includeNA: Scalars["Boolean"];
  yesNoResponse?: Maybe<AiloFormResponseYesNo>;
};

export type AiloFormPage = {
  __typename?: "AiloFormPage";
  id: Scalars["ID"];
  index: Scalars["Int"];
  icon?: Maybe<Scalars["Url"]>;
  name: Scalars["String"];
  parentTemplate: AiloFormTemplate;
  parentForm: AiloForm;
  /** List of all fields flattened including children fields */
  allFields: Array<AiloFormField>;
  /** List of all top level fields, query children to get next depth of fields */
  topLevelFields: Array<AiloFormField>;
};

export type AiloFormResponse = {
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
};

export type AiloFormResponseDate = AiloFormResponse & {
  __typename?: "AiloFormResponseDate";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  date: Scalars["DateTime"];
};

export type AiloFormResponseNumber = AiloFormResponse & {
  __typename?: "AiloFormResponseNumber";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  number: Scalars["Float"];
};

export type AiloFormResponsePayloadInput = {
  DATE?: Maybe<Scalars["DateTime"]>;
  NUMBER?: Maybe<Scalars["Float"]>;
  TEXT?: Maybe<Scalars["String"]>;
  YES_NO?: Maybe<Scalars["String"]>;
};

export type AiloFormResponseText = AiloFormResponse & {
  __typename?: "AiloFormResponseText";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  text: Scalars["String"];
};

export type AiloFormResponseYesNo = AiloFormResponse & {
  __typename?: "AiloFormResponseYesNo";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  yesNoNa: AiloFormYesNoResponse;
};

export type AiloFormTemplate = {
  __typename?: "AiloFormTemplate";
  id: Scalars["ID"];
  type: AiloFormType;
  supportedState: AustralianState;
  version: Scalars["Int"];
  name: Scalars["String"];
  pages: Array<AiloFormTemplatePage>;
};

export type AiloFormTemplateField = {
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
};

export type AiloFormTemplateFieldDate = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldDate";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldEmpty = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldEmpty";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
};

export type AiloFormTemplateFieldNumber = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldNumber";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  unit?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldText = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldText";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldYesNo = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldYesNo";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  includeNA: Scalars["Boolean"];
};

export type AiloFormTemplatePage = {
  __typename?: "AiloFormTemplatePage";
  id: Scalars["ID"];
  index: Scalars["Int"];
  icon?: Maybe<Scalars["Url"]>;
  name: Scalars["String"];
  parentTemplate: AiloFormTemplate;
  /** List of all fields flattened including children fields */
  allFields: Array<AiloFormTemplateField>;
  /** List of all top level fields, query children to get next depth of fields */
  topLevelFields: Array<AiloFormTemplateField>;
};

export enum AiloFormType {
  IngoingInspection = "INGOING_INSPECTION",
}

export enum AiloFormYesNoResponse {
  Yes = "YES",
  No = "NO",
  Na = "NA",
}

export type AiloRnFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["AiloRN"]>;
  neq?: Maybe<Scalars["AiloRN"]>;
  gt?: Maybe<Scalars["AiloRN"]>;
  gte?: Maybe<Scalars["AiloRN"]>;
  lt?: Maybe<Scalars["AiloRN"]>;
  lte?: Maybe<Scalars["AiloRN"]>;
  like?: Maybe<Scalars["AiloRN"]>;
  notLike?: Maybe<Scalars["AiloRN"]>;
  iLike?: Maybe<Scalars["AiloRN"]>;
  notILike?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export enum AllowedOperations {
  None = "None",
  Create = "Create",
  Edit = "Edit",
}

export type AllTenanciesQueryFilterInput = {
  includeOffboarded?: Maybe<Scalars["Boolean"]>;
  includeVoided?: Maybe<Scalars["Boolean"]>;
  includeDrafts?: Maybe<Scalars["Boolean"]>;
};

export type AllTenanciesQueryInput = {
  paginationParams: PaginationParams;
  filters?: Maybe<AllTenanciesQueryFilterInput>;
};

export type AmountDueToDateInput = {
  dateTime?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
};

export type ArchiveLiabilityInput = {
  archivedAt?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
};

export type ArchiveProjectsInput = {
  ids: Array<Scalars["ID"]>;
};

export type ArchiveProjectsPayload = {
  __typename?: "ArchiveProjectsPayload";
  projects: Array<Project>;
};

export type Arrears = {
  __typename?: "Arrears";
  /** @deprecated Use `amountInArrears` instead */
  amount: TaxableAmount;
  /**  Amount in arrears, value can be negative when overpaid  */
  amountInArrears: TaxableAmount;
  calculatedFromDate?: Maybe<Scalars["Date"]>;
  calculatedToDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `calculatedFromDate` instead */
  date: Scalars["Date"];
  /**  Number of days in arrears, value can be negative when overpaid  */
  daysInArrears: Scalars["Int"];
};

export type AssigneeAilornsComparison = {
  in?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type AssignFormActionMeta = {
  __typename?: "AssignFormActionMeta";
  form?: Maybe<Form>;
};

export type AssignMessageActionMeta = {
  __typename?: "AssignMessageActionMeta";
  messageTemplate?: Maybe<Scalars["String"]>;
  subjectTemplate?: Maybe<Scalars["String"]>;
};

export type AssignNewManagementActionMeta = {
  __typename?: "AssignNewManagementActionMeta";
  management?: Maybe<Management>;
};

export type AssignRentActionMeta = {
  __typename?: "AssignRentActionMeta";
  rent?: Maybe<Rent>;
};

export type AssignTenancyActionMeta = {
  __typename?: "AssignTenancyActionMeta";
  tenancy?: Maybe<Tenancy>;
};

export type AssignTenancyAgreementActionMeta = {
  __typename?: "AssignTenancyAgreementActionMeta";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export enum AustralianState {
  Nsw = "NSW",
  Wa = "WA",
  Sa = "SA",
  Nt = "NT",
  Tas = "TAS",
  Act = "ACT",
  Qld = "QLD",
  Vic = "VIC",
}

export type AutoPayLiabilityStatus = {
  __typename?: "AutoPayLiabilityStatus";
  enabled: Scalars["Boolean"];
  maximumPaymentAmount?: Maybe<Money>;
  /**
   * If null, it means it'll be paid using Ailo Wallet.
   * TODO: Return `WalletPaymentMethod` instead.
   */
  payerId: Scalars["AiloRN"];
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated Use `AutoPayLiabilityStatus.paymentMethod.id` instead */
  paymentMethodId?: Maybe<Scalars["ID"]>;
};

export type AutoPaymentDetails = {
  __typename?: "AutoPaymentDetails";
  paymentMethod?: Maybe<PaymentMethod>;
};

export type AutoWithdrawPlan = {
  __typename?: "AutoWithdrawPlan";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  details: AutoWithdrawPlanDetails;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  owner: Scalars["AiloRN"];
  wallet: Wallet;
  walletId: Scalars["ID"];
};

export type AutoWithdrawPlanDetails = {
  __typename?: "AutoWithdrawPlanDetails";
  /** If `frequency` = Monthly, use `anniversaryDaysOfMonth` instead. */
  anniversary?: Maybe<Scalars["Int"]>;
  /**
   * Only used when `frequency` = Monthly. Allowed values: 1-28.
   * e.g. ['1', '15'] means a withdrawal occurs on the 1st and 15th of every month.
   */
  anniversaryDaysOfMonth?: Maybe<Array<Scalars["Int"]>>;
  autoWithdrawPlanId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  createdByV2?: Maybe<ActionInitiator>;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  id: Scalars["ID"];
  /**
   * Only used when `frequency` = Monthly.
   * If `true`, withdrawal will happen on the last day of the month.
   * e.g. 30th June, 31st July, 28th Feb or 29th Feb depending on leap year.
   */
  isLastDayOfTheMonth?: Maybe<Scalars["Boolean"]>;
  nextFireTime: Scalars["DateTime"];
  /** @deprecated Use `paymentMethodDestinations` instead */
  paymentMethod: PaymentMethod;
  paymentMethodDestinations: Array<PaymentMethodDestination>;
  /** @deprecated Use `paymentMethodDestinations` instead */
  paymentMethodId: Scalars["ID"];
  setAsideAmount?: Maybe<Money>;
  startDate: Scalars["Date"];
  timezoneId: Scalars["String"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type Banana = {
  __typename?: "Banana";
  id: Scalars["ID"];
  weight: Weight;
};

export type BankAccount = PaymentMethod & {
  __typename?: "BankAccount";
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  ailoRN: Scalars["AiloRN"];
  bsb?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type BankAccountBlacklist = {
  __typename?: "BankAccountBlacklist";
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  ownerLegalEntity: Scalars["AiloRN"];
};

export type BankAccountBlacklistInput = {
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description: Scalars["String"];
  ownerLegalEntity: Scalars["AiloRN"];
};

export type BankAccountInput = {
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  onceOff?: Maybe<Scalars["Boolean"]>;
};

export type BankReconciliationReport = {
  __typename?: "BankReconciliationReport";
  items?: Maybe<Array<Maybe<ReconciliationReportItem>>>;
};

export type BelongToTeamsArgInput = {
  teamIds?: Array<Scalars["AiloRN"]>;
  includeUnassigned?: Scalars["Boolean"];
};

/** @deprecated Use `PaginationParams` instead when possible. */
export type BidirectionalPageCursor = {
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type BidirectionalPageInfo = {
  __typename?: "BidirectionalPageInfo";
  total: Scalars["Int"];
  hasNext: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  hasPrevious: Scalars["Boolean"];
  previousCursor?: Maybe<Scalars["String"]>;
};

export type BidirectionalPaginatedTenancies = {
  __typename?: "BidirectionalPaginatedTenancies";
  pageInfo: BidirectionalPageInfo;
  items: Array<Tenancy>;
};

export type Bill = {
  __typename?: "Bill";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  /** Never null (nullable only because of federation reasons). */
  organisation?: Maybe<Organisation>;
  paymentReference?: Maybe<PaymentReference>;
  /** @deprecated Use `Bill.payer.ailoRN` instead. */
  toBePaidBy: Scalars["AiloRN"];
  /** Never null (nullable only because of federation reasons). */
  payer?: Maybe<BillPayer>;
  /**
   * Never null (nullable only because of federation reasons).
   * @deprecated Use `Bill.payee` instead.
   */
  supplier?: Maybe<Supplier>;
  /** Never null (nullable only because of federation reasons). */
  payee?: Maybe<BillPayee>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use `Bill.dueDateV2` instead */
  dueDate: Scalars["Date"];
  dueDateV2: Scalars["LocalDate"];
  /** @deprecated Use `Bill.issueDateV2` instead */
  issueDate: Scalars["Date"];
  issueDateV2: Scalars["LocalDate"];
  timeZone: Scalars["TimeZone"];
  liabilityState?: Maybe<BillLiabilityState>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  status: BillStatus;
  agencyStatus: BillAgencyStatus;
  taxCategory: TaxCategory;
  /**
   * When bill was created, was the tax automatically calculated by the UI?
   * (If null, it means it was created before the automatic tax calculation was available.)
   */
  taxAutoCalculated?: Maybe<Scalars["Boolean"]>;
  amount: Money;
  lineItems?: Maybe<PaginatedLineItems>;
  relatingToManagement: Scalars["AiloRN"];
  relatedToTeam?: Maybe<Scalars["AiloRN"]>;
  createdAt: Scalars["DateTime"];
  /** @deprecated Use `Bill.createdByV2.ailoRN` instead. */
  createdBy: Scalars["AiloRN"];
  /** If null it means the bill has been created by Ailo system. */
  createdByUser?: Maybe<User>;
  modifiedBy: Scalars["AiloRN"];
  liability?: Maybe<Liability>;
  organisationArchiveReason?: Maybe<Scalars["String"]>;
  /**
   * It represents the agency legal entity.
   * Never nullable (nullable only because of federation error policy).
   */
  managingEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  /** It is only used to populate bill liability payment decription when debiting investor bank account, not consumer facing */
  propertyAddress?: Maybe<Scalars["String"]>;
  applyManagementFee: Scalars["Boolean"];
  /** If true the funds will not be automatically withdrawn by the supplier */
  internal: Scalars["Boolean"];
  attachments: Array<BillAttachment>;
  /** @deprecated use `Bill.organisation.ailoRN` instead. */
  relatingToOrganisation: Scalars["AiloRN"];
  management?: Maybe<Management>;
  archivableByOrganisation: Scalars["Boolean"];
};

export enum BillAgencyStatus {
  Cancelled = "Cancelled",
  Refunded = "Refunded",
  RefundFailed = "RefundFailed",
  AwaitingPayment = "AwaitingPayment",
  Overdue = "Overdue",
  Due = "Due",
  PaymentSent = "PaymentSent",
  Paid = "Paid",
}

export type BillAttachment = {
  __typename?: "BillAttachment";
  id: Scalars["ID"];
  /**
   * This should never be null, but because it comes from external service,
   * in a case of unexpected server error, it will be returned as null.
   * In such case, client should treat it as internal server error,
   * show an error indicator and report it to Sentry.
   */
  file?: Maybe<File>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
};

export type BillDefaultPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<Scalars["String"]>;
};

export type BillInput = {
  paymentReferenceId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  /** Tenancy / Management / Agency Legal Entity ID */
  toBePaidBy: Scalars["AiloRN"];
  /** Tenancy Tenants / Management Owners / Agency Legal Entity IDs */
  toBePaidByLegalEntities?: Maybe<Array<Scalars["AiloRN"]>>;
  /** @deprecated Use `payeeAiloRN` instead. */
  supplierId?: Maybe<Scalars["ID"]>;
  payeeAiloRN?: Maybe<Scalars["AiloRN"]>;
  /** @deprecated Use `dueDateV2` instead. */
  dueDate?: Maybe<Scalars["Date"]>;
  dueDateV2?: Maybe<Scalars["LocalDate"]>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  status: BillStatus;
  taxCategoryId: Scalars["String"];
  /** @deprecated Use `issueDateV2` instead. */
  issueDate?: Maybe<Scalars["Date"]>;
  issueDateV2?: Maybe<Scalars["LocalDate"]>;
  timeZone?: Maybe<Scalars["TimeZone"]>;
  taxAutoCalculated?: Maybe<Scalars["Boolean"]>;
  lineItems: Array<LineItemInput>;
  attachmentsFileIds?: Maybe<Array<Scalars["AiloRN"]>>;
  relatingToManagement: Scalars["AiloRN"];
  relatingToOrganisation: Scalars["AiloRN"];
  managingEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  applyManagementFee?: Maybe<Scalars["Boolean"]>;
  internal?: Maybe<Scalars["Boolean"]>;
};

export type BillLiabilityState = {
  __typename?: "BillLiabilityState";
  /** @deprecated Use `BillLiabilityState.payByDateV2` instead */
  payByDate?: Maybe<Scalars["Date"]>;
  payByDateV2?: Maybe<Scalars["LocalDate"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  /** @deprecated Use `BillLiabilityState.paidAt` instead */
  paymentDate?: Maybe<Scalars["Date"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  dueAmount?: Maybe<Money>;
};

export type BillPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<BillSortParams>;
};

export type BillPayee = Supplier | Management | LegalEntityCompanion;

export type BillPayer = Tenancy | Management | LegalEntityCompanion;

export enum BillPayerType {
  Tenancy = "Tenancy",
  Management = "Management",
  LegalEntity = "LegalEntity",
}

export enum BillPaymentStatus {
  Due = "DUE",
  Paid = "PAID",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Failed = "FAILED",
}

export enum BillSortField {
  CreatedAt = "CreatedAt",
  DueDate = "DueDate",
  PayByDate = "PayByDate",
  PaymentDate = "PaymentDate",
}

export type BillSortParams = {
  field: BillSortField;
  direction: SortDirection;
};

export enum BillStatus {
  Void = "VOID",
  Approved = "APPROVED",
  Refunded = "REFUNDED",
}

export type BondAuthority = {
  __typename?: "BondAuthority";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  agencyLegalEntityAilorn: Scalars["AiloRN"];
  userFacingName: Scalars["String"];
};

export type BondAuthorityAggregateGroupBy = {
  __typename?: "BondAuthorityAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
};

export type BondAuthorityCountAggregate = {
  __typename?: "BondAuthorityCountAggregate";
  id?: Maybe<Scalars["Int"]>;
};

export type BondAuthorityEdge = {
  __typename?: "BondAuthorityEdge";
  /** The node containing the BondAuthority */
  node: BondAuthority;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type BondAuthorityMaxAggregate = {
  __typename?: "BondAuthorityMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type BondAuthorityMinAggregate = {
  __typename?: "BondAuthorityMinAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export enum BondDisbursalFailureReason {
  ReceivedLessFundsThanClaimed = "ReceivedLessFundsThanClaimed",
  ReceivedMoreFundsThanClaimed = "ReceivedMoreFundsThanClaimed",
  PaymentError = "PaymentError",
}

export enum Book {
  Accrual = "ACCRUAL",
  Cash = "CASH",
}

export type BooleanFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
};

export type BPay = PaymentMethod & {
  __typename?: "BPay";
  ailoRN: Scalars["AiloRN"];
  billerCode: Scalars["String"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type BPayInput = {
  billerCode: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type BulkActionMigratingManagementsErrorPayload = {
  __typename?: "BulkActionMigratingManagementsErrorPayload";
  id: Scalars["ID"];
  failureReason: Scalars["String"];
};

export type BulkActionMigratingManagementsPayload = {
  __typename?: "BulkActionMigratingManagementsPayload";
  successes: Array<MigratingManagement>;
  errors: Array<BulkActionMigratingManagementsErrorPayload>;
};

export type BulkActionMigrationTasksErrorPayload = {
  __typename?: "BulkActionMigrationTasksErrorPayload";
  legalEntityId: Scalars["AiloRN"];
  failureReason: Scalars["String"];
};

export type BulkActionMigrationTasksPayload = {
  __typename?: "BulkActionMigrationTasksPayload";
  successes: Array<Scalars["AiloRN"]>;
  errors: Array<BulkActionMigrationTasksErrorPayload>;
};

export type BusinessTransaction = {
  __typename?: "BusinessTransaction";
  ailoRN: Scalars["AiloRN"];
  amount: Money;
  children?: Maybe<Array<Maybe<BusinessTransaction>>>;
  /** Returns date & time of clearedAt or null if it is not cleared yet. */
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  /**
   * Returns date of clearedAt if the business transaction is cleared, else it
   * returns an estimate based on created date and today's date.
   * If the status of the business transaction is FAIL or CLEANED, null is returned.
   * Currently does not support business transactions without a liability due to lack of Zone data in Business Transaction.
   */
  estimatedClearDate?: Maybe<Scalars["Date"]>;
  /** Returns date & time of clearedAt (Can be before cleared) */
  expectedClearedAt?: Maybe<Scalars["DateTime"]>;
  /** Returns date of when latest business transaction status is created if it's status is FAIL or CLEANED and null otherwise. */
  failedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  idempotentKey: Scalars["String"];
  isReversal: Scalars["Boolean"];
  parent?: Maybe<BusinessTransaction>;
  payer?: Maybe<Scalars["AiloRN"]>;
  payerLegalEntity?: Maybe<LegalEntityCompanion>;
  paymentChannel?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReferenceId?: Maybe<Scalars["String"]>;
  status?: Maybe<BusinessTxStatusEnum>;
  type: BusinessTxType;
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type BusinessTransactionStatus = {
  __typename?: "BusinessTransactionStatus";
  status: BusinessTxStatusEnum;
};

export enum BusinessTxStatusEnum {
  Cleaned = "CLEANED",
  Fail = "FAIL",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export enum BusinessTxType {
  PaymentAuto = "PAYMENT_AUTO",
  PaymentManual = "PAYMENT_MANUAL",
  Refund = "REFUND",
  Reverse = "REVERSE",
  TopUpManual = "TOP_UP_MANUAL",
  VirtualAccountDebit = "VIRTUAL_ACCOUNT_DEBIT",
  WithdrawAuto = "WITHDRAW_AUTO",
  WithdrawManual = "WITHDRAW_MANUAL",
}

export type CancelAutoPayInput = {
  liabilityId: Scalars["ID"];
};

export type CancelAutoPayLiabilityStatusInput = {
  /**
   * Legal Entity ARN (Person/Company) if it is meant to pay liabilities using their "personal" wallet,
   * or Management folio ARN, if it's meant to pay liabilities using management folio wallet.
   */
  payerId?: Maybe<Scalars["AiloRN"]>;
  /** Current user legal entity ID. Will get deprecated soon, once we are able to get user person ARN from new authz token. */
  payerLegalEntity: Scalars["AiloRN"];
};

export type CancelAutoWithdrawPlanInput = {
  autoWithdrawPlanId: Scalars["ID"];
};

export type CancelInspectionAppointmentResult = {
  __typename?: "CancelInspectionAppointmentResult";
  inspectionAppointment: InspectionAppointment;
};

export type CancelLiabilityPaymentPlanInput = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type CancelRecurringFeeInput = {
  id: Scalars["AiloRN"];
  /**
   * If not provided, fee will end with the current cycle. (Future cycles will not happen.)
   * In case the fee hasn't started yet, it'll end immediately. (It's end date will
   * be set to the date before the fee start date.)
   *
   * Optionally, pass in the date on which the fee should end.
   * Such date needs to be an end date of a fee cycle.
   */
  endDate?: Maybe<Scalars["String"]>;
};

export type CancelVacateAbility = {
  __typename?: "CancelVacateAbility";
  ableToCancel: Scalars["Boolean"];
  notAbleToCancelReason?: Maybe<NotAbleToCancelReason>;
};

export type CentrepayAccount = {
  __typename?: "CentrepayAccount";
  id: Scalars["ID"];
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
};

export type CentrepayDirective = {
  __typename?: "CentrepayDirective";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type CentrepayFeeOverrideInput = {
  escrowAccount: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export type CentrepayFeeSetting = {
  __typename?: "CentrepayFeeSetting";
  ailoRN: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export enum Channel {
  Email = "Email",
  Push = "Push",
}

export type Charge = {
  __typename?: "Charge";
  amount: TaxableAmount;
  date: Scalars["Date"];
};

export type ChargeCycle = {
  __typename?: "ChargeCycle";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type ChargeSchedule = {
  __typename?: "ChargeSchedule";
  firstChargeDate: Scalars["Date"];
};

export type ChargeScheduleInput = {
  firstChargeDate: Scalars["Date"];
};

export type ChatV2 = {
  __typename?: "ChatV2";
  ailorn: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  participants: Array<Contact>;
  threads?: Maybe<PaginatedThreadsV2>;
  mostRecentMessage: MessageSummaryV2;
  readonly: Scalars["Boolean"];
  unreadMessagesCount: Scalars["Int"];
};

export type ChatV2ThreadsArgs = {
  pagination?: Maybe<PaginationInputV2>;
};

export type ClaimedFilesCount = {
  __typename?: "ClaimedFilesCount";
  totalFiles: Scalars["Int"];
  validFiles: Scalars["Int"];
};

export type ClaimFilesResponse = {
  __typename?: "ClaimFilesResponse";
  ok: Scalars["Boolean"];
  /** Present if `ok === true`. */
  files?: Maybe<Array<File>>;
  /** Present if `ok === false`. */
  errors?: Maybe<Array<ClaimFilesResponseError>>;
};

export type ClaimFilesResponseError = {
  __typename?: "ClaimFilesResponseError";
  fileId: Scalars["AiloRN"];
  code: ValidateFileErrorCode;
};

export type ClearManagementEndPayload = {
  __typename?: "ClearManagementEndPayload";
  management?: Maybe<Management>;
};

export enum CommunicationType {
  Default = "DEFAULT",
  InvestorStatement = "INVESTOR_STATEMENT",
  TaxSummary = "TAX_SUMMARY",
  TransferSummary = "TRANSFER_SUMMARY",
  AgencyTransfer = "AGENCY_TRANSFER",
}

export type Company = LegalEntity & {
  __typename?: "Company";
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  organisation: Organisation;
  timezone: Scalars["TimeZone"];
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  registeredEntityId?: Maybe<Scalars["String"]>;
  registeredEntityName: Scalars["String"];
  eulaSignedBy?: Maybe<Person>;
  currentUserPermissions: Array<Scalars["String"]>;
  accountsEmail: Array<Scalars["String"]>;
  owner: Person;
  /** Legal Entity Memberships in which this legal entity is the group of which the other people are members. */
  legalEntityMembershipsAsLegalEntity: Array<LegalEntityMembership>;
  trustAccounts: CompanyTrustAccountsConnection;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  tenancyBondVirtualAccounts: Array<TenancyBondAccount>;
  externalTrustAccounts?: Maybe<Array<ExternalTrustAccount>>;
  migrationTask?: Maybe<MigrationTask>;
  /** this can go when payment gateway splits */
  allWalletsAvailableBalance: Money;
  allWalletsTotalBalance: Money;
  kycVerifiedAccordingToLedgerService?: Maybe<Scalars["Boolean"]>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  wallets: Array<Wallet>;
  contact?: Maybe<Contact>;
};

export type CompanyTrustAccountsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountFilter>;
  sorting?: Maybe<Array<TrustAccountSort>>;
};

export type CompanyStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type CompanyTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type CompanyManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type CompanyPaymentMethodsArgs = {
  methodType?: Maybe<PaymentMethodType>;
};

export type CompanyContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type CompanyFilters = {
  ownerId?: Maybe<Scalars["String"]>;
  registeredEntityNames?: Maybe<Array<Scalars["String"]>>;
};

export type CompanyServiceInput = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  organisationId?: Maybe<Scalars["ID"]>;
  registeredEntityName: Scalars["String"];
  registeredEntityId?: Maybe<Scalars["String"]>;
  ownerId: Scalars["String"];
  emailAddress?: Maybe<Scalars["String"]>;
  accountsEmail?: Maybe<Array<Scalars["String"]>>;
};

export type CompanyTrustAccountsConnection = {
  __typename?: "CompanyTrustAccountsConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountEdge>;
};

export type CompleteAssignFormActionInput = {
  id: Scalars["ID"];
  formAilorn: Scalars["AiloRN"];
};

export type CompleteAssignFormActionPayload = {
  __typename?: "CompleteAssignFormActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignNewManagementActionInput = {
  id: Scalars["ID"];
  managementAilorn: Scalars["AiloRN"];
};

export type CompleteAssignNewManagementActionPayload = {
  __typename?: "CompleteAssignNewManagementActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignRentActionInput = {
  rentAilorn: Scalars["AiloRN"];
};

export type CompleteAssignRentActionPayload = {
  __typename?: "CompleteAssignRentActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignTenancyActionInput = {
  id: Scalars["ID"];
  tenancyAilorn: Scalars["AiloRN"];
  inspectionAppointmentAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CompleteAssignTenancyActionPayload = {
  __typename?: "CompleteAssignTenancyActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignTenancyAgreementActionInput = {
  tenancyId: Scalars["ID"];
  agreementDetails: TenancyAgreementDetails;
};

export type ConfirmInspectionScheduleActionMeta = {
  __typename?: "ConfirmInspectionScheduleActionMeta";
  tenancy?: Maybe<Tenancy>;
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export type ConsumerInvite = {
  __typename?: "ConsumerInvite";
  id: Scalars["ID"];
  metadata: Scalars["String"];
};

export type ConsumerSetupInput = {
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  migratingCustomer?: Maybe<Scalars["Boolean"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
};

export type Contact = {
  __typename?: "Contact";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  types: Array<ContactType>;
  /**
   * The contact's preferred name (or first name if they haven't indicated a preferred name), followed by their last name
   * (if any), in a format ready for display. Does not include middle names.
   */
  displayName: Scalars["String"];
  /** The contact's preferred name, or their first name if they haven't indicated a preferred name */
  displayFirstName: Scalars["String"];
  status: ContactStatus;
  legalEntity?: Maybe<LegalEntity>;
  legalEntityType?: Maybe<ContactLegalEntityType>;
  user?: Maybe<User>;
  primaryEmail?: Maybe<Scalars["String"]>;
  photo?: Maybe<File>;
  /** The owner of this contact, e.g. a Person contact who owns a Company contact */
  owner?: Maybe<Contact>;
  /** Contacts that are owned by this contact. i.e. Company contacts that are owned by a Person contact */
  contactsOwned: Array<Contact>;
  /**
   * This is the confidential part of the contact that is accessible only for entities that have a read permission against the
   * requested contact's organisation. Other entities would only be able to see the "non-confidential" fields.
   * Null if a non-authorised user tries to query for this
   */
  confidential?: Maybe<ContactConfidential>;
  allAddresses: Array<ContactPropertyAddress>;
  investorAddresses: Array<ManagementPropertyAddress>;
  tenantAddresses: Array<TenancyPropertyAddress>;
  teams: Array<Maybe<Team>>;
  mailboxAccesses: Array<MailboxAccess>;
};

export type ContactConfidential = {
  __typename?: "ContactConfidential";
  organisation?: Maybe<Organisation>;
  primaryPhone?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  onAppAt?: Maybe<Scalars["DateTime"]>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  contactRole?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  middleNames?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
  modifiedBy: ActionInitiator;
};

export enum ContactLegalEntityType {
  Person = "Person",
  Company = "Company",
}

export type ContactOrEmail = {
  contactAilorn?: Maybe<Scalars["AiloRN"]>;
  email?: Maybe<Scalars["String"]>;
};

export type ContactPropertyAddress = {
  managementAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
};

export type ContactsByManagement = {
  __typename?: "ContactsByManagement";
  agencyContacts: Array<Contact>;
  investorContacts: Array<Contact>;
};

export type ContactsByTenancy = {
  __typename?: "ContactsByTenancy";
  agencyContacts: Array<Contact>;
  tenantContacts: Array<Contact>;
};

export type ContactsFilters = {
  legalEntityTypes?: Maybe<Array<ContactLegalEntityType>>;
  excludeContactAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type ContactSortParams = {
  field: Scalars["String"];
  direction: SortDirection;
};

export type ContactsQueryInput = {
  pagination: PaginationParams;
};

export enum ContactStatus {
  NotOnApp = "NotOnApp",
  OnApp = "OnApp",
  Archived = "Archived",
}

export enum ContactType {
  Investor = "Investor",
  Tenant = "Tenant",
  Supplier = "Supplier",
  Agent = "Agent",
  Other = "Other",
}

export type CreateActionInput = {
  name: Scalars["String"];
  description: Scalars["String"];
  projectId: Scalars["ID"];
  assigneeAilorn: Scalars["AiloRN"];
  type: ActionType;
  dueDate: Scalars["DateTime"];
};

export type CreateAiloFormCreateAiloFormResponseInput = {
  payload: AiloFormResponsePayloadInput;
  fieldId: Scalars["ID"];
};

export type CreateAiloFormInput = {
  templateId: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
  name: Scalars["String"];
  responses?: Maybe<Array<CreateAiloFormCreateAiloFormResponseInput>>;
};

export type CreateAiloFormResult = {
  __typename?: "CreateAiloFormResult";
  form: AiloForm;
};

export type CreateAutoWithdrawPlanInputV2 = {
  anniversary?: Maybe<Scalars["Int"]>;
  /**
   * Only used when `frequency` = Monthly. Allowed values: 1-28.
   * e.g. ['1', '15'] means a withdrawal occurs on the 1st and 15th of every month.
   */
  anniversaryDaysOfMonth?: Maybe<Array<Scalars["Int"]>>;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  /**
   * Only used when `frequency` = Monthly.
   * If `true`, withdrawal will happen on the last day of the month.
   * e.g. 30th June, 31st July, 28th Feb or 29th Feb depending on leap year.
   */
  lastDayOfTheMonth?: Maybe<Scalars["Boolean"]>;
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodDestinations: Array<PaymentMethodDestinationInput>;
  setAsideAmount?: Maybe<MoneyInput>;
  startDate: Scalars["Date"];
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletId: Scalars["ID"];
};

export type CreateBananaInput = {
  bananaId: Scalars["ID"];
};

export type CreateBondInput = {
  tenancyAilorn: Scalars["AiloRN"];
  reference?: Maybe<Scalars["String"]>;
  amount: MoneyInput;
  bondAccountAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateChatInputV2 = {
  participants: Array<ContactOrEmail>;
  initialThread: ThreadInputV2;
  organisationAilorn: Scalars["AiloRN"];
};

export type CreateExternalTrustAccountInput = {
  organisationId: Scalars["AiloRN"];
  legalEntityId: Scalars["AiloRN"];
  secretArn?: Maybe<Scalars["String"]>;
  details?: Maybe<ExternalTrustAccountDetailsInput>;
  priority?: Maybe<Scalars["Int"]>;
};

export type CreateFeeBlueprintInput = {
  name: Scalars["String"];
  type: FeeBlueprintType;
  taxCategoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency?: Maybe<FeeFrequency>;
  event?: Maybe<FeeEventType>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  organisationId: Scalars["AiloRN"];
};

export type CreateFeeInput = {
  type: FeeType;
  feeBlueprintId?: Maybe<Scalars["String"]>;
  managementFeeBlueprintId?: Maybe<Scalars["String"]>;
  managementId: Scalars["String"];
  amount: MoneyInput;
  taxAmount?: Maybe<MoneyInput>;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<MoneyInput>;
  baseAmountType?: Maybe<FeeBaseAmountType>;
  taxCategoryId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateInspectionAppointmentInput = {
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn: Scalars["AiloRN"];
  type: InspectionType;
  inspectingAgentAilorn: Scalars["AiloRN"];
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type CreateInspectionAppointmentsResult = {
  __typename?: "CreateInspectionAppointmentsResult";
  inspectionAppointments: Array<InspectionAppointment>;
};

export type CreateInspectionAreaFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionAreaId: Scalars["ID"];
};

export type CreateInspectionAreaFileResult = {
  __typename?: "CreateInspectionAreaFileResult";
  inspectionAreaFile: InspectionAreaFile;
};

export type CreateInspectionAreaInput = {
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  inspectionId: Scalars["ID"];
  files?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  features?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
};

export type CreateInspectionAreaInputFeatureInput = {
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  files?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
};

export type CreateInspectionAreaInputFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
};

export type CreateInspectionAreaResult = {
  __typename?: "CreateInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type CreateInspectionFeatureFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionFeatureId: Scalars["ID"];
};

export type CreateInspectionFeatureFileResult = {
  __typename?: "CreateInspectionFeatureFileResult";
  inspectionFeatureFile: InspectionFeatureFile;
};

export type CreateInspectionFeatureInput = {
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  inspectionAreaId: Scalars["ID"];
  files?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
};

export type CreateInspectionFeatureInputFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
};

export type CreateInspectionFeatureResult = {
  __typename?: "CreateInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type CreateInspectionInput = {
  inspectionAppointmentId: Scalars["String"];
  agencyAilorn?: Maybe<Scalars["AiloRN"]>;
  type: InspectionType;
  inspectingAgentAilorn: Scalars["AiloRN"];
  startedAt: Scalars["DateTime"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  areas?: Maybe<Array<CreateInspectionInputAreaInput>>;
  ailoFormAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateInspectionInputAreaInput = {
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  files?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  features?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
};

export type CreateInspectionResult = {
  __typename?: "CreateInspectionResult";
  inspection: Inspection;
};

export type CreateLiabilityPaymentPlanInput = {
  amount: MoneyInput;
  anniversary: Scalars["Int"];
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  liabilityId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodId: Scalars["ID"];
  startDate: Scalars["Date"];
};

export type CreateManagementFeeBlueprintInput = {
  managementId: Scalars["ID"];
  feeBlueprintId: Scalars["ID"];
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CreateOneKeyInput = {
  /** The record to create */
  key: KeyInput;
};

export type CreateOneTrustAccountInput = {
  /** The record to create */
  trustAccount: CreateTrustAccountInput;
};

export type CreateOneTrustAccountReconciliationAdjustmentInput = {
  /** The record to create */
  trustAccountReconciliationAdjustment: CreateTrustAccountReconciliationAdjustmentInput;
};

export type CreateOneTrustAccountStatementBalanceInput = {
  /** The record to create */
  trustAccountStatementBalance: CreateTrustAccountStatementBalanceInput;
};

export type CreateOrUpdateManagementNoteInput = {
  id?: Maybe<Scalars["ID"]>;
  managementId: Scalars["ID"];
  note: Scalars["String"];
};

export type CreateOrUpdateManagementNotePayload = {
  __typename?: "CreateOrUpdateManagementNotePayload";
  managementNote: ManagementNote;
};

export type CreateOtherContactInput = {
  organisationAilorn: Scalars["AiloRN"];
  primaryEmail: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
};

export type CreatePaymentMethodInput = {
  bankAccountInput?: Maybe<BankAccountInput>;
  bpayInput?: Maybe<BPayInput>;
  /** If true, create a `walletOwner` if it doesn't already exist before adding the provided payment method to it */
  createWalletOwner?: Maybe<Scalars["Boolean"]>;
  creditCardInput?: Maybe<CreditCardInputV2>;
  deleteExistingPaymentMethods?: Maybe<Scalars["Boolean"]>;
  /** ID reference from external system. Not used by ledger. */
  externalId?: Maybe<Scalars["String"]>;
  /**
   * Organisation (e.g. agency) that is allowed to manage the provided wallet
   * owner. Must be provided when the walletOwnerAiloRN is a supplier AiloRN.
   */
  managingOrganisationAiloRN?: Maybe<Scalars["AiloRN"]>;
  walletOwnerAiloRN: Scalars["AiloRN"];
};

export type CreatePersonCompanyInput = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  organisationId?: Maybe<Scalars["ID"]>;
  registeredEntityName: Scalars["String"];
  registeredEntityId?: Maybe<Scalars["String"]>;
};

export type CreateProjectAbility = {
  __typename?: "CreateProjectAbility";
  ableToCreate: Scalars["Boolean"];
  notAbleToCreateReason?: Maybe<Scalars["String"]>;
};

export type CreateProjectInput = {
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  organisationAilorn: Scalars["AiloRN"];
  assigneeAilorn: Scalars["AiloRN"];
  type: ProjectType;
  dueDate: Scalars["Date"];
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
  meta: Scalars["JSONObject"];
};

export type CreateReiFormFromUserTemplateInput = {
  /** template id of form to generate */
  reiUserTemplateId: Scalars["Int"];
  /** Form name for future reference */
  name: Scalars["String"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
  /** Tenancy Ailorn of the property */
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateReiTokenInput = {
  /** REI Token */
  token: Scalars["String"];
  /** State the REI account was linked to */
  supportedAustralianState?: Maybe<AustralianState>;
  /** AiloRN of person the token is associated with */
  personAilorn: Scalars["AiloRN"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
};

export type CreateRentInput = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  category: RentCategory;
  setsChargeDate: Scalars["Boolean"];
};

export type CreateRentReviewPayload = {
  __typename?: "CreateRentReviewPayload";
  rent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type CreateRentsInput = {
  tenancyId: Scalars["ID"];
  rents: Array<CreateRentInput>;
};

export type CreateRentsPayload = {
  __typename?: "CreateRentsPayload";
  rents: Array<Rent>;
  query: Query;
};

export type CreateStatementInput = {
  statementOwnerAiloRNs: Array<Scalars["AiloRN"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  type: StatementType;
  /** Statement created for is default to manual request */
  createdFor?: Maybe<StatementCreatedFor>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  /**
   * overrideStatementValues should be null unless overriding real data. To
   * override the real data, provide a string containing a JSON of the exact
   * statement values wanted for the statement
   */
  overrideStatementValues?: Maybe<Scalars["String"]>;
};

export type CreateStatementsForAllManagementsInput = {
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  sendEmail?: Maybe<Scalars["Boolean"]>;
  type: StatementType;
  dryRunEmail?: Maybe<Scalars["String"]>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
};

export type CreateStatementsResponse = {
  __typename?: "CreateStatementsResponse";
  statementProgresses: Array<StatementProgress>;
  numberOfStatements: Scalars["Int"];
};

export type CreateStatementWithSubjectInput = {
  statementSubjectAiloRNs: Array<Scalars["AiloRN"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  type: StatementType;
  /** Statement created for is default to manual request */
  createdFor?: Maybe<StatementCreatedFor>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  /**
   * overrideStatementValues should be null unless overriding real data. To
   * override the real data, provide a string containing a JSON of the exact
   * statement values wanted for the statement
   */
  overrideStatementValues?: Maybe<Scalars["String"]>;
};

export type CreateSupplierInput = {
  organisationAiloRN: Scalars["AiloRN"];
  abn?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  address?: Maybe<SupplierAddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
};

export type CreateTenancyAgreementPayload = {
  __typename?: "CreateTenancyAgreementPayload";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export type CreateTenancyBondClaim = {
  amount: MoneyInput;
  bondAiloRN: Scalars["AiloRN"];
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
};

export type CreateTenantshipInput = {
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate?: Maybe<Scalars["Date"]>;
};

export type CreateTenantshipPayload = {
  __typename?: "CreateTenantshipPayload";
  tenantship: Tenantship;
};

export type CreateThreadInputV2 = {
  thread: ThreadInputV2;
  chatAilorn: Scalars["AiloRN"];
};

export type CreateTrustAccountInput = {
  ownerLegalEntityAilorn: Scalars["AiloRN"];
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
};

export type CreateTrustAccountReconciliationAdjustmentInput = {
  amountInCents: Scalars["Long"];
  applyOn: Scalars["LocalDate"];
  details: Scalars["String"];
  reportPeriodId: Scalars["ID"];
};

export type CreateTrustAccountStatementBalanceInput = {
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
  reportPeriodId: Scalars["ID"];
};

export type CreateUpdateAutoPayInput = {
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type CreditCard = PaymentMethod & {
  __typename?: "CreditCard";
  ailoRN: Scalars["AiloRN"];
  category?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  expiry?: Maybe<Scalars["Date"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  /** Masked credit card number, e.g. "512345XXXXXX2346". */
  number?: Maybe<Scalars["String"]>;
  topUpFee?: Maybe<TransactionFee>;
  /** E.g. "visa", "mastercard", "amex". */
  type?: Maybe<Scalars["String"]>;
  wallet: Wallet;
};

export type CreditCardInput = {
  description?: Maybe<Scalars["String"]>;
  rawCreditCardPayload: Scalars["String"];
};

export type CreditCardInputV2 = {
  category: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  expiry: Scalars["String"];
  number: Scalars["String"];
  token: Scalars["String"];
  type: Scalars["String"];
};

export type CumulativeCharge = {
  __typename?: "CumulativeCharge";
  amount: TaxableAmount;
  date: Scalars["Date"];
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate first */
  first?: Maybe<Scalars["Int"]>;
  /** Paginate last */
  last?: Maybe<Scalars["Int"]>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars["DateTime"];
  upper: Scalars["DateTime"];
};

export type DateRange = {
  __typename?: "DateRange";
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export enum DateRangeCurrentness {
  Past = "past",
  Current = "current",
  Future = "future",
}

export type DateRangeFilter = {
  afterDate?: Maybe<Scalars["Date"]>;
  beforeDate?: Maybe<Scalars["Date"]>;
};

export type DeleteCentrepayDirectiveInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type DeleteCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type DeleteManagementFileInput = {
  fileAilorn: Scalars["AiloRN"];
  managementId: Scalars["ID"];
};

export type DeleteManagementFilePayload = {
  __typename?: "DeleteManagementFilePayload";
  file: File;
  query: Query;
};

export type DeleteOneKeyInput = {
  /** The id of the record to delete. */
  id: Scalars["ID"];
};

export type DeleteRentInput = {
  rentId: Scalars["ID"];
};

export type DeleteRentPayload = {
  __typename?: "DeleteRentPayload";
  rentId: Scalars["ID"];
  query: Query;
};

export type DeleteTeamInput = {
  teamId: Scalars["AiloRN"];
};

export type DeleteTeamPayload = {
  __typename?: "DeleteTeamPayload";
  teamId: Scalars["AiloRN"];
};

export type DeleteTenancyFileInput = {
  fileAilorn: Scalars["AiloRN"];
  tenancyId: Scalars["ID"];
};

export type DeleteTenancyFilePayload = {
  __typename?: "DeleteTenancyFilePayload";
  file: File;
  query: Query;
};

export type DeprecatedStatementData = {
  __typename?: "DeprecatedStatementData";
  lineItems?: Maybe<Array<StatementLineItem>>;
  netEarnings: Money;
  totalExpenses: Money;
  totalExpensesGST: Money;
  totalIncome: Money;
};

export type DisableExternalTrustAccountInput = {
  externalTrustAccountId: Scalars["Int"];
};

export type DisburseFundsResponse = {
  __typename?: "DisburseFundsResponse";
  success: Scalars["Boolean"];
};

export type DisburseToTrustAccountInput = {
  amount: MoneyInput;
  agency: Scalars["AiloRN"];
  escrowAccount: Scalars["AiloRN"];
  idempotencyKey: Scalars["String"];
  description: Scalars["String"];
};

export type DiscrepancyReport = {
  __typename?: "DiscrepancyReport";
  items?: Maybe<Array<Maybe<DiscrepancyReportItem>>>;
};

export type DiscrepancyReportItem = {
  __typename?: "DiscrepancyReportItem";
  id?: Maybe<Scalars["String"]>;
  hasDiscrepancy?: Maybe<Scalars["Boolean"]>;
  ledger?: Maybe<Array<Maybe<TransactionDetail>>>;
  gateway?: Maybe<Array<Maybe<TransactionDetail>>>;
};

export enum DomainType {
  Consumer = "Consumer",
  Agency = "Agency",
}

export type EditableRentReviewPayload = {
  __typename?: "EditableRentReviewPayload";
  rent?: Maybe<Rent>;
  problem?: Maybe<EditableRentReviewProblem>;
};

export type EditableRentReviewProblem = ProblemInterface & {
  __typename?: "EditableRentReviewProblem";
  message: Scalars["String"];
};

export type EditRentReviewInput = {
  rentId: Scalars["ID"];
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  setsChargeDate: Scalars["Boolean"];
  scheduledRentReviewDate: Scalars["Date"];
};

export type EditRentReviewPayload = {
  __typename?: "EditRentReviewPayload";
  rent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type EditTeamInput = {
  teamAilorn: Scalars["AiloRN"];
  name: Scalars["String"];
};

export type EditTeamPayload = {
  __typename?: "EditTeamPayload";
  team: Team;
};

export type EftPaymentSettings = {
  __typename?: "EftPaymentSettings";
  /** Agency legal entity */
  agency: Scalars["AiloRN"];
  directEntryUserId?: Maybe<Scalars["String"]>;
  abaFileFormat: AbaFileFormat;
};

export type EftPaymentSettingsInput = {
  /** Agency legal entity */
  agency: Scalars["AiloRN"];
  directEntryUserId?: Maybe<Scalars["String"]>;
  abaFileFormat: AbaFileFormat;
};

export type EnableExternalTrustAccountInput = {
  externalTrustAccountId: Scalars["Int"];
};

export type EndManagementV2Input = {
  managementId: Scalars["ID"];
  endDate: Scalars["Date"];
  endReasonCode: ManagementEndReasonCode;
  endCauseCodes?: Maybe<Array<ManagementEndCauseCode>>;
  endNote: Scalars["String"];
};

export type EndManagementV2Payload = {
  __typename?: "EndManagementV2Payload";
  management: Management;
};

export type EndTenancyActionMeta = {
  __typename?: "EndTenancyActionMeta";
  tenancy?: Maybe<Tenancy>;
};

export type Entry = {
  __typename?: "Entry";
  account: Account;
  accountBalance?: Maybe<Money>;
  amount: Money;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  ledgerEvent: LedgerEvent;
  snowflake: Scalars["Long"];
  userFacingDescription: Scalars["String"];
};

export enum EntrySortField {
  CreatedAt = "CREATED_AT",
  Snowflake = "SNOWFLAKE",
}

export enum ExternalMailboxStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export type ExternalMetadataInput = {
  externalSourceId: Scalars["Int"];
  externalFeeTemplateId: Scalars["ID"];
  triggerId?: Maybe<Scalars["ID"]>;
  triggerType?: Maybe<PMeFeeTriggerType>;
  name?: Maybe<Scalars["String"]>;
  chartAccountName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
  valueType?: Maybe<PMeFeeValueType>;
};

export type ExternalTrustAccount = {
  __typename?: "ExternalTrustAccount";
  id: Scalars["Int"];
  company?: Maybe<Company>;
  secretArn: Scalars["String"];
  priority?: Maybe<Scalars["Int"]>;
  disabled: Scalars["Boolean"];
};

export type ExternalTrustAccountCredentialsInput = {
  username: Scalars["String"];
  password: Scalars["String"];
  mfa?: Maybe<Scalars["String"]>;
};

export type ExternalTrustAccountDetailsInput = {
  credentials: ExternalTrustAccountCredentialsInput;
  systemType: ExternalTrustAccountSystemType;
  organisationName: Scalars["String"];
};

export enum ExternalTrustAccountSystemType {
  PropertyMe = "PropertyMe",
}

export type Fee = {
  __typename?: "Fee";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  type: FeeType;
  management: Management;
  blueprint?: Maybe<FeeBlueprint>;
  managementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  /** Never null if type = BillManagementFee */
  appliedTo?: Maybe<Bill>;
  /**
   * Bill if type = BillManagementFee
   * Tenancy if type = EventBasedFee & blueprint.eventType = firstRentSettled
   */
  appliedToV2?: Maybe<FeeAppliedTo>;
  amount: Money;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<Money>;
  baseAmountType?: Maybe<FeeBaseAmountType>;
  taxCategory: FeeTaxCategory;
  description?: Maybe<Scalars["String"]>;
  /** TODO META-381: Make this never null after backfilling status */
  status?: Maybe<FeeStatus>;
  createdAt: Scalars["DateTime"];
  /** Never null. */
  createdBy?: Maybe<ActionInitiator>;
  /** Never null. */
  modifiedBy?: Maybe<ActionInitiator>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  archiveReason?: Maybe<Scalars["String"]>;
  taxAmount?: Maybe<Money>;
  liability?: Maybe<Liability>;
};

export type FeeAppliedTo = Bill | Tenancy;

export enum FeeBaseAmountType {
  Bill = "Bill",
  OneWeekRent = "OneWeekRent",
}

export type FeeBlueprint = {
  __typename?: "FeeBlueprint";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  type: FeeBlueprintType;
  name: Scalars["String"];
  taxCategory: FeeTaxCategory;
  event?: Maybe<FeeEvent>;
  /** @deprecated Use `description` on `Fee` or `RecurringFee` instead. */
  description?: Maybe<Scalars["String"]>;
  /** Present if `chargeType == FixedAmount`. Null otherwise. */
  fixedAmount?: Maybe<Money>;
  taxTreatment: TaxTreatment;
  /** Present if `chargeType == OneWeekRentPercentage`. Null otherwise. */
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  chargeType: FeeBlueprintChargeType;
  /** Present if `type == RecurringFee`. Null otherwise. */
  frequency?: Maybe<FeeFrequency>;
  /** Present if `type == RecurringFee`. Null otherwise. */
  anniversaryDay?: Maybe<Scalars["Int"]>;
  /** Present if `type == RecurringFee && frequency == "annually"`. Null otherwise. */
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  /** Always present. (Nullable only because it's a federated field.) */
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  createdBy: ActionInitiator;
  modifiedBy: ActionInitiator;
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  archiveReason?: Maybe<Scalars["String"]>;
  supported?: Maybe<Scalars["Boolean"]>;
};

export enum FeeBlueprintChargeType {
  FixedAmount = "FixedAmount",
  OneWeekRentPercentage = "OneWeekRentPercentage",
}

export type FeeBlueprintsQueryConditions = {
  organisationId?: Maybe<Scalars["ID"]>;
  type?: Maybe<FeeBlueprintType>;
  /** Exclude agency fee blueprints that are linked to non-archived management fee blueprints on the given management */
  notAppliedToManagementId?: Maybe<Scalars["ID"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum FeeBlueprintType {
  RecurringFee = "RecurringFee",
  OneOffFee = "OneOffFee",
  EventBasedFee = "EventBasedFee",
}

export type FeeEvent = {
  __typename?: "FeeEvent";
  type: FeeEventType;
  shortDescription: Scalars["String"];
  longDescription: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export enum FeeEventType {
  FirstRentSettled = "firstRentSettled",
}

export enum FeeFrequency {
  Weekly = "weekly",
  Monthly = "monthly",
  Annually = "annually",
}

export type FeeOccurrence = {
  __typename?: "FeeOccurrence";
  date: Scalars["Date"];
  taxAmount: Money;
  taxInclusiveAmount: Money;
};

export enum FeeSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Sorted in this order: Due, PaymentPending, Paid, RefundPending, Refunded, Cancelled */
  StatusProgressAsc = "STATUS_PROGRESS_ASC",
}

export type FeesQueryConditions = {
  type?: Maybe<Array<FeeType>>;
  managementId?: Maybe<Array<Scalars["ID"]>>;
  appliedToAiloRN?: Maybe<Array<Scalars["AiloRN"]>>;
  status?: Maybe<Array<FeeStatus>>;
};

export enum FeeStatus {
  Due = "Due",
  PaymentPending = "PaymentPending",
  Paid = "Paid",
  Cancelled = "Cancelled",
  RefundPending = "RefundPending",
  Refunded = "Refunded",
}

export type FeeTaxCategory = {
  __typename?: "FeeTaxCategory";
  id: Scalars["String"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export enum FeeType {
  BillManagementFee = "BillManagementFee",
  OneOffFee = "OneOffFee",
  EventBasedFee = "EventBasedFee",
}

export type File = {
  __typename?: "File";
  id: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  fileName: Scalars["String"];
  fileSize?: Maybe<Scalars["Int"]>;
  kind: FileKind;
  contentType?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
  thumbnailStatus: ThumbnailStatus;
};

export enum FileKind {
  AgencyTransferStatement = "AgencyTransferStatement",
  AuthZPersonPhoto = "AuthZ_PersonPhoto",
  BillBillAttachment = "Bill_BillAttachment",
  ChatMessageFilePart = "Chat_MessageFilePart",
  InspectionReport = "InspectionReport",
  StatementInvestorStatement = "Statement_InvestorStatement",
  ProjectFile = "ProjectFile",
  TenancyFile = "TenancyFile",
  ManagementFile = "ManagementFile",
  InspectionFile = "InspectionFile",
  Receipt = "Receipt",
  CentrepayReport = "CentrepayReport",
  Unknown = "unknown",
}

export type FileShareLink = {
  __typename?: "FileShareLink";
  id: Scalars["ID"];
  file: File;
  url: Scalars["String"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
};

export type FileShareLinkMetadataInput = {
  type: FileShareLinkMetadataType;
  recipientEmail: Scalars["String"];
  notificationId?: Maybe<Scalars["String"]>;
  emailDeliveryId?: Maybe<Scalars["String"]>;
};

export enum FileShareLinkMetadataType {
  EmailNotificationLink = "EmailNotificationLink",
}

export type FileWithUploadUrl = {
  __typename?: "FileWithUploadUrl";
  file: File;
  uploadUrl: Scalars["String"];
};

export type Filter = {
  search?: Maybe<Array<Maybe<SearchFilter>>>;
  dateRange?: Maybe<DateRangeFilter>;
};

export type FindOrCreateBlueprintInput = {
  name: Scalars["String"];
  type: FeeBlueprintType;
  taxCategoryId: Scalars["ID"];
  taxTreatment: TaxTreatment;
  event?: Maybe<FeeEventType>;
  organisationAilorn: Scalars["AiloRN"];
  externalMetadata: ExternalMetadataInput;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency?: Maybe<FeeFrequency>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
};

export type FindOrCreateManagementFeeBlueprintInput = {
  managementId: Scalars["ID"];
  feeBlueprintId: Scalars["ID"];
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  pmeData?: Maybe<ExternalMetadataInput>;
  description?: Maybe<Scalars["String"]>;
};

export type FindOrCreateOtherContactsInput = {
  organisationAilorn: Scalars["AiloRN"];
  emails: Array<Scalars["String"]>;
};

export type FirstAndLastEntries = {
  __typename?: "FirstAndLastEntries";
  firstEntry?: Maybe<Entry>;
  lastEntry?: Maybe<Entry>;
};

export type Form = {
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
};

export type FundReceived = {
  __typename?: "FundReceived";
  amount: Money;
  receivedAt: Scalars["DateTime"];
};

export type GatewayCapturePaymentDetails = {
  __typename?: "GatewayCapturePaymentDetails";
  url?: Maybe<Scalars["URL"]>;
};

export type GeneralLedgerInteraction = {
  __typename?: "GeneralLedgerInteraction";
  reference: Scalars["AiloRN"];
};

export type GeneralProjectMeta = {
  __typename?: "GeneralProjectMeta";
  management?: Maybe<Management>;
};

export type GeneralStatementFilter = {
  subject?: Maybe<Scalars["AiloRN"]>;
  types?: Maybe<Array<StatementType>>;
  createdFor?: Maybe<Array<StatementCreatedFor>>;
};

export type GenerateInspectionReportActionMeta = {
  __typename?: "GenerateInspectionReportActionMeta";
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export type GenerateInspectionReportInput = {
  inspectionId: Scalars["String"];
};

export type GenerateInspectionReportResult = {
  __typename?: "GenerateInspectionReportResult";
  inspection: Inspection;
};

export type GenerateLiabilityEntriesInput = {
  liabilityIds: Array<Scalars["ID"]>;
};

export type GenerateLiabilityEntriesPayload = {
  __typename?: "GenerateLiabilityEntriesPayload";
  liabilityIds: Array<Scalars["ID"]>;
};

export type GetCentrepayersInput = {
  tenancy: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type GetCentrepayPaymentDirectivesFilter = {
  legalEntity?: Maybe<Scalars["AiloRN"]>;
  managingEntity?: Maybe<Scalars["AiloRN"]>;
  tenancy?: Maybe<Scalars["AiloRN"]>;
};

export type GetCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type GetCrnOutput = {
  __typename?: "GetCrnOutput";
  crn: Scalars["String"];
};

export type GetLegalEntityByCrnInput = {
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type GetLegalEntityByCrnOutput = {
  __typename?: "GetLegalEntityByCrnOutput";
  legalEntity: Scalars["AiloRN"];
};

export type GetMailboxAuthorizationUrlInput = {
  contactAilorn: Scalars["AiloRN"];
  provider: MailboxProvider;
};

export type GetOrCreateWalletOwnerAndBankAccountInput = {
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description: Scalars["String"];
  walletOwnerAiloRN: Scalars["AiloRN"];
};

export type GetTrustAccountInput = {
  agency: Scalars["AiloRN"];
};

export type GraphQlAilornFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["AiloRN"]>;
  neq?: Maybe<Scalars["AiloRN"]>;
  gt?: Maybe<Scalars["AiloRN"]>;
  gte?: Maybe<Scalars["AiloRN"]>;
  lt?: Maybe<Scalars["AiloRN"]>;
  lte?: Maybe<Scalars["AiloRN"]>;
  like?: Maybe<Scalars["AiloRN"]>;
  notLike?: Maybe<Scalars["AiloRN"]>;
  iLike?: Maybe<Scalars["AiloRN"]>;
  notILike?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["ID"]>;
  neq?: Maybe<Scalars["ID"]>;
  gt?: Maybe<Scalars["ID"]>;
  gte?: Maybe<Scalars["ID"]>;
  lt?: Maybe<Scalars["ID"]>;
  lte?: Maybe<Scalars["ID"]>;
  like?: Maybe<Scalars["ID"]>;
  notLike?: Maybe<Scalars["ID"]>;
  iLike?: Maybe<Scalars["ID"]>;
  notILike?: Maybe<Scalars["ID"]>;
  in?: Maybe<Array<Scalars["ID"]>>;
  notIn?: Maybe<Array<Scalars["ID"]>>;
};

export type IgnoreMe = {
  __typename?: "IgnoreMe";
  company?: Maybe<Company>;
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
  person?: Maybe<Person>;
};

export type InboundMailDirectionDetails = {
  __typename?: "InboundMailDirectionDetails";
  direction: MailDirection;
};

export type IncomeAndExpenseByTaxCategoryData = {
  __typename?: "IncomeAndExpenseByTaxCategoryData";
  expenses: Array<IncomeAndExpenseByTaxCategoryDataEntry>;
  income: Array<IncomeAndExpenseByTaxCategoryDataEntry>;
};

export type IncomeAndExpenseByTaxCategoryDataEntry = {
  __typename?: "IncomeAndExpenseByTaxCategoryDataEntry";
  taxCategory: Scalars["String"];
  totalAmount: Money;
  totalTaxAmount: Money;
};

export type IncomeAndExpenseByTaxCategoryInput = {
  accountOwnerReference: Scalars["AiloRN"];
  /** Filter to include only entries which have created_at < createdBefore */
  createdBefore: Scalars["DateTime"];
  /** Filter to include only entries which have created_at >= createdFrom */
  createdFrom: Scalars["DateTime"];
};

export type IngoingTenancyUpdateInput = {
  tenancyId: Scalars["ID"];
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  tenancyAgreement: TenancyAgreementSetupInput;
  rents: Array<RentSetupInput>;
};

export type Inspection = {
  __typename?: "Inspection";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  type: InspectionType;
  startedAt: Scalars["DateTime"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  areas: Array<InspectionArea>;
  inspectionAppointment: InspectionAppointment;
  property: Property;
  agency: Organisation;
  inspectingAgent: Person;
  /** @deprecated Use areaFilesForThumbnail and featureFieldsForThumbnail instead */
  files: Array<InspectionAreaFile>;
  areaFilesForThumbnail: Array<InspectionAreaFile>;
  featureFilesForThumbnail: Array<InspectionFeatureFile>;
  ailoForm?: Maybe<AiloForm>;
  tenancy: Tenancy;
  reportGenerationState: ReportGenerationState;
  latestReportProgress?: Maybe<InspectionReportProgress>;
};

export type InspectionAreaFilesForThumbnailArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type InspectionFeatureFilesForThumbnailArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type InspectionAggregateGroupBy = {
  __typename?: "InspectionAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointment = {
  __typename?: "InspectionAppointment";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  type: InspectionType;
  startTime?: Maybe<Scalars["DateTime"]>;
  inspection?: Maybe<Inspection>;
  property: Property;
  tenancy: Tenancy;
  agency: Organisation;
  inspectingAgent: Person;
  previousInspection?: Maybe<Inspection>;
};

export type InspectionAppointmentAggregateGroupBy = {
  __typename?: "InspectionAppointmentAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentConnection = {
  __typename?: "InspectionAppointmentConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<InspectionAppointmentEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type InspectionAppointmentCountAggregate = {
  __typename?: "InspectionAppointmentCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  propertyAilorn?: Maybe<Scalars["Int"]>;
  tenancyAilorn?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  inspectingAgentAilorn?: Maybe<Scalars["Int"]>;
  startTime?: Maybe<Scalars["Int"]>;
};

export type InspectionAppointmentEdge = {
  __typename?: "InspectionAppointmentEdge";
  /** The node containing the InspectionAppointment */
  node: InspectionAppointment;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type InspectionAppointmentFilter = {
  id?: Maybe<IdFilterComparison>;
  propertyAilorn?: Maybe<InspectionAppointmentPropertyAilornFilterComparison>;
  tenancyAilorn?: Maybe<InspectionAppointmentTenancyAilornFilterComparison>;
  type?: Maybe<InspectionAppointmentTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionAppointmentInspectingAgentAilornFilterComparison>;
  startTime?: Maybe<DateFieldComparison>;
  inspection?: Maybe<InspectionAppointmentFilterInspectionFilter>;
};

export type InspectionAppointmentFilterInspectionFilter = {
  id?: Maybe<IdFilterComparison>;
  type?: Maybe<InspectionTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionInspectingAgentAilornFilterComparison>;
  startedAt?: Maybe<DateFieldComparison>;
  completedAt?: Maybe<DateFieldComparison>;
};

export type InspectionAppointmentInspectingAgentAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentMaxAggregate = {
  __typename?: "InspectionAppointmentMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentMinAggregate = {
  __typename?: "InspectionAppointmentMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentPropertyAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentSort = {
  field: InspectionAppointmentSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum InspectionAppointmentSortFields {
  Id = "id",
  PropertyAilorn = "propertyAilorn",
  TenancyAilorn = "tenancyAilorn",
  Type = "type",
  InspectingAgentAilorn = "inspectingAgentAilorn",
  StartTime = "startTime",
}

export type InspectionAppointmentTenancyAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentTypeFilterComparison = {
  eq?: Maybe<InspectionType>;
  in?: Maybe<Array<InspectionType>>;
  notIn?: Maybe<Array<InspectionType>>;
};

export type InspectionArea = {
  __typename?: "InspectionArea";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  inspection: Inspection;
  files: Array<InspectionAreaFile>;
  features: Array<InspectionFeature>;
};

export enum InspectionAreaCondition {
  Excellent = "Excellent",
  Good = "Good",
  Fair = "Fair",
  Poor = "Poor",
}

export type InspectionAreaFile = {
  __typename?: "InspectionAreaFile";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionArea: InspectionArea;
  file: File;
};

export type InspectionConnection = {
  __typename?: "InspectionConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<InspectionEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type InspectionCountAggregate = {
  __typename?: "InspectionCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  inspectingAgentAilorn?: Maybe<Scalars["Int"]>;
  startedAt?: Maybe<Scalars["Int"]>;
  completedAt?: Maybe<Scalars["Int"]>;
};

export type InspectionEdge = {
  __typename?: "InspectionEdge";
  /** The node containing the Inspection */
  node: Inspection;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type InspectionFeature = {
  __typename?: "InspectionFeature";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  inspectionArea: InspectionArea;
  files: Array<InspectionFeatureFile>;
};

export type InspectionFeatureFile = {
  __typename?: "InspectionFeatureFile";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionFeature: InspectionFeature;
  file: File;
};

export type InspectionFilter = {
  id?: Maybe<IdFilterComparison>;
  type?: Maybe<InspectionTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionInspectingAgentAilornFilterComparison>;
  startedAt?: Maybe<DateFieldComparison>;
  completedAt?: Maybe<DateFieldComparison>;
};

export type InspectionInspectingAgentAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionMaxAggregate = {
  __typename?: "InspectionMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionMinAggregate = {
  __typename?: "InspectionMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionReportProgress = {
  __typename?: "InspectionReportProgress";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  fileAilorn?: Maybe<Scalars["AiloRN"]>;
  /** Report generation completion time */
  completedAt?: Maybe<Scalars["DateTime"]>;
  /** Report generation failure time */
  failedAt?: Maybe<Scalars["DateTime"]>;
  inspection: Inspection;
};

export type InspectionSort = {
  field: InspectionSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum InspectionSortFields {
  Id = "id",
  Type = "type",
  InspectingAgentAilorn = "inspectingAgentAilorn",
  StartedAt = "startedAt",
  CompletedAt = "completedAt",
}

export enum InspectionType {
  Ingoing = "Ingoing",
  Outgoing = "Outgoing",
  Routine = "Routine",
}

export type InspectionTypeFilterComparison = {
  eq?: Maybe<InspectionType>;
  in?: Maybe<Array<InspectionType>>;
  notIn?: Maybe<Array<InspectionType>>;
};

export type InternalSupplierInput = {
  name?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  organisationReference: Scalars["AiloRN"];
  internalReference: Scalars["AiloRN"];
};

export type IntFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Int"]>;
  neq?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  between?: Maybe<IntFieldComparisonBetween>;
  notBetween?: Maybe<IntFieldComparisonBetween>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars["Int"];
  upper: Scalars["Int"];
};

export type Key = {
  __typename?: "Key";
  id: Scalars["ID"];
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  property: Property;
  organisation: Organisation;
};

export type KeyAggregateGroupBy = {
  __typename?: "KeyAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyCountAggregate = {
  __typename?: "KeyCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  organisationAilorn?: Maybe<Scalars["Int"]>;
};

export type KeyDeleteResponse = {
  __typename?: "KeyDeleteResponse";
  id?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  property?: Maybe<Property>;
  organisation?: Maybe<Organisation>;
};

export type KeyEdge = {
  __typename?: "KeyEdge";
  /** The node containing the Key */
  node: Key;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type KeyFilter = {
  id?: Maybe<KeyIdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  organisationAilorn?: Maybe<KeyOrganisationAilornFilterComparison>;
};

export type KeyIdFilterComparison = {
  eq?: Maybe<Scalars["ID"]>;
};

export type KeyInput = {
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  propertyAilorn: Scalars["AiloRN"];
  organisationAilorn: Scalars["AiloRN"];
};

export type KeyMaxAggregate = {
  __typename?: "KeyMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyMinAggregate = {
  __typename?: "KeyMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyOrganisationAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
};

export type KeySort = {
  field: KeySortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum KeySortFields {
  Id = "id",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  OrganisationAilorn = "organisationAilorn",
}

export type LeaseRenewalDetails = {
  id?: Maybe<Scalars["ID"]>;
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type LeaseRenewalProjectMeta = {
  __typename?: "LeaseRenewalProjectMeta";
  management: Management;
  tenancy: Tenancy;
  tenancyAgreement?: Maybe<TenancyAgreement>;
  rent?: Maybe<Rent>;
};

/** @deprecated Use `BidirectionalPageInfo` instead when possible. */
export type LedgerBidirectionalPageInfo = {
  __typename?: "LedgerBidirectionalPageInfo";
  /** @deprecated Use `hasNext` instead. */
  hasMore: Scalars["Boolean"];
  hasNext: Scalars["Boolean"];
  hasPrevious: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  previousCursor?: Maybe<Scalars["String"]>;
  total: Scalars["Int"];
};

export type LedgerEvent = {
  __typename?: "LedgerEvent";
  ailoRN: Scalars["AiloRN"];
  businessTransaction?: Maybe<BusinessTransaction>;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  domainEventKey: Scalars["String"];
  entries?: Maybe<PaginatedEntries>;
  id: Scalars["ID"];
  interaction: GeneralLedgerInteraction;
  occurredAt: Scalars["DateTime"];
  owing?: Maybe<RecurringOwing>;
  paymentMethod?: Maybe<PaymentMethodCompanion>;
  reference: Scalars["AiloRN"];
  reversalDepth?: Maybe<Scalars["Int"]>;
  type: LedgerEventType;
};

export type LedgerEventEntriesArgs = {
  pagination?: PaginationParams;
};

export enum LedgerEventType {
  Cleared = "CLEARED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  InitiatedAndCleared = "INITIATED_AND_CLEARED",
}

/** @deprecated Use `BidirectionalPageCursor` instead when possible. */
export type LedgerPageCursor = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  /** sort is not supported yet. */
  sort?: Maybe<Scalars["String"]>;
};

export type LegalEntity = {
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  currentUserPermissions: Array<Scalars["String"]>;
  timezone: Scalars["TimeZone"];
  emailAddress?: Maybe<Scalars["String"]>;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  contact?: Maybe<Contact>;
};

export type LegalEntityStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type LegalEntityTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type LegalEntityManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type LegalEntityContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type LegalEntityCompanion = {
  __typename?: "LegalEntityCompanion";
  id: Scalars["ID"];
  /** @deprecated Use `LegalEntityCompanion.ailoRNV2` instead. */
  ailoRN: Scalars["String"];
  ailoRNV2: Scalars["AiloRN"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisation: Organisation;
  legalEntity: LegalEntity;
};

export type LegalEntityMembership = {
  __typename?: "LegalEntityMembership";
  member: Person;
  legalEntity: LegalEntity;
  role: LegalEntityRole;
};

export type LegalEntityPersonRole = Role & {
  __typename?: "LegalEntityPersonRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
  legalEntity: LegalEntity;
  person: Person;
};

export type LegalEntityRole = Role & {
  __typename?: "LegalEntityRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export enum LegalEntityType {
  Company = "Company",
  Person = "Person",
}

export type LegalEntityWithExternalTrustAccount = {
  __typename?: "LegalEntityWithExternalTrustAccount";
  legalEntityId: Scalars["AiloRN"];
  company?: Maybe<Company>;
  disabled: Scalars["Boolean"];
  status?: Maybe<MigrationTaskStatus>;
  finishedAt?: Maybe<Scalars["DateTime"]>;
};

export type LegalEntityWithExternalTrustAccountsFilters = {
  organisationId?: Maybe<Scalars["AiloRN"]>;
};

export enum LegalEntityWithExternalTrustAccountSortField {
  FinishedAt = "FinishedAt",
}

export type LegalEntityWithExternalTrustAccountSortParams = {
  field: LegalEntityWithExternalTrustAccountSortField;
  direction: SortDirection;
};

export type Liability = {
  __typename?: "Liability";
  /**
   * Amount computed base on how many full cycle, how many Days into a partial
   * cycle and how much are paid up to now for this liability.
   * e.g. Tenant occupied a $100/wk property for 2 days so far and paid $30.
   * The DailyRate of this property is $100/7 = $14.29 per day.
   * This field should be 2days * $14.29 - $30 = -$1.42 (i.e. Tenant overpaid $1.42 so far and not in arrears)
   */
  amountInArrears: Money;
  autoPaymentDetails?: Maybe<AutoPaymentDetails>;
  category: Scalars["String"];
  /**
   * A poorly named and designed timestamp representing a date compute base on
   * whether payment for full cycles of liability has been made.
   * The time should always be 00am of a Date in liability's zone. This timestamp
   * also require minus 1 sec treatment to be displayed as a Date that's accurate
   * for the domain.
   * e.g. Tenant paid 1 full week Rent for this Mon-Sun, this field would be Monday
   * 00am in `zone`. The actual Paid To Date should be Sunday.
   * @deprecated Use `paidToDate` instead
   */
  classicPaidTo?: Maybe<Scalars["DateTime"]>;
  consumerReference?: Maybe<Scalars["AiloRN"]>;
  /** The daily rent rate for the latest plan. */
  dailyRate: Money;
  /**
   * The number of days a tenant has stayed in a property and not paid anything for.
   * Eg. Tenant moves in 1/1/2021 and hasn't paid. Tenant is 1 day in arrears because they didn't pay for 1/1/2021.
   * If this tenant makes a payment for any amount, they will be 0 days in arrears, even if it doesn't cover a full day.
   */
  daysInArrears: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  /**
   * the amount of whichever the not-fully-paid cycle (past, today or future). if
   * no partial-paid period, this will be full amount of whichever cycle
   * due-to-be-paid next.
   */
  dueAmount?: Maybe<Money>;
  /**
   * the due date of whichever not-fully-paid cycle (past, today or future). if no
   * partial-paid period, this will be full amount of whichever cycle
   * due-to-be-paid next.
   */
  dueDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Date compute base on how much is paid against this liability up til now.
   * Does not require minus 1 day or 1 sec treatment. The Date is in this Liability's `zone`.
   * e.g. Tenant paid $30 into this Mon-Sun (rent = $100/week). It is 2 days and a
   * bit worth of Rent. This field should be this Tuesday (i.e paid for Monday and
   * Tuesday inclusive)
   * e.g. Tenant paid nothing into this Mon-Sun, this field should be last Sunday.
   */
  effectivePaidToDate?: Maybe<Scalars["Date"]>;
  entries?: Maybe<PaginatedLiabilityEntries>;
  firstPaymentDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  lastPaymentDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `category` instead */
  liabilityType?: Maybe<Scalars["String"]>;
  linkedChildBusinessTransactions?: Maybe<Array<BusinessTransaction>>;
  management?: Maybe<Management>;
  /** the full amount of the next (as of today) rent cycle, regardless any rent payment */
  nextChargeAmount?: Maybe<Money>;
  /** the start date of the next (as of today) rent cycle, regardless any rent payment */
  nextChargeCreateDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `nextChargeDueDate`. they are the same, this one is poorly named. */
  nextChargeDate?: Maybe<Scalars["DateTime"]>;
  /** the due date of the next rent cycle, regardless any rent payment */
  nextChargeDueDate?: Maybe<Scalars["DateTime"]>;
  /** the due amount of the next (as of today) not-fully-paid rent cycle */
  nextDueAmount?: Maybe<Money>;
  /** the due date of the next (as of today) not-fully-paid rent cycle */
  nextDueDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Poorly named field representing Amount computed base on how many full
   * liability cycles owed and how much are paid up to now.
   * Produce wrong result for any Liability with Overlapping LiabilityPlans, as this field uses Liaiblity Entry sum.
   * @deprecated Use `overdueAmount` instead
   */
  outstandingBalance: Money;
  /**
   * Amount computed base on how many full liability cycles owed and how much are paid up to now.
   * e.g. Tenant occupied a $100/wk property for 2 days so far and paid $30. This field should be $100 - $30
   */
  overdueAmount: Money;
  /**
   * A poorly named and designed timestamp representing Effective Paid To Date, a
   * Date compute base on how much is paid against this liability up til now.
   * The time should always be 00am of a Date in liability's zone. This timestamp
   * also require minus 1 sec treatment to be displayed as a Date that's accurate
   * for the domain.
   * e.g. Tenant paid $30 into this Mon-Sun (rent = $100/week). It is 2 days and a
   * bit worth of Rent. This field will be 00am of Wed. The actual Effective Paid
   * To Date should be Tuesday.
   * @deprecated Use `effectivePaidToDate` instead
   */
  paidTo?: Maybe<Scalars["DateTime"]>;
  /**
   * Date compute base on whether payment for full cycles of liability has been made.
   * Does not require minus 1 day or 1 sec treatment. The Date is in this Liability's `zone`.
   * e.g. Tenant paid 1 full week Rent for this Mon-Sun, this field should be this Sunday.
   * e.g. Tenant paid nothing for this Mon-Sun, this field should be last Sunday.
   */
  paidToDate?: Maybe<Scalars["Date"]>;
  paymentKey: Scalars["GeneratedKey"];
  paymentPlans?: Maybe<PaginatedLiabilityPaymentPlans>;
  providerReference?: Maybe<Scalars["AiloRN"]>;
  reference: Scalars["String"];
  referencedEntity?: Maybe<LiabilityReferencedEntity>;
  relatingToManagement?: Maybe<Scalars["AiloRN"]>;
  status?: Maybe<LiabilityStatus>;
  taxCategory: Scalars["String"];
  /**
   * If this liability has a defined termination date, what is the Amount owed from
   * now to that termination day (incl. the termination day itself)
   * Null when there's no termination date known for the liability.
   * e.g. as of today, a Tenant owes nothing and even over paid $30. The property will be terminated in 1 month and 12 days.
   * This field should calculate how much will be owed between now and 1mth12days
   * base on rent schedule, and minus $30 overpayment.
   */
  terminationAmountDue?: Maybe<Money>;
  /** The inclusive date of the liability's termination. The value is null if liability has not been terminated. */
  terminationDate?: Maybe<Scalars["Date"]>;
  timeZoneName?: Maybe<Scalars["ZoneId"]>;
  totalAmountPaid: Money;
  recurringFee?: Maybe<RecurringFee>;
  owing?: Maybe<RecurringOwing>;
};

export type LiabilityEntriesArgs = {
  cursor?: Maybe<BidirectionalPageCursor>;
  filterTypes?: Maybe<Array<SourceType>>;
  sort?: Maybe<LiabilityEntrySort>;
};

export type LiabilityPaymentPlansArgs = {
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type LiabilityCalculation = {
  __typename?: "LiabilityCalculation";
  paidToDate: Scalars["Date"];
  timeZoneName: Scalars["ZoneId"];
};

export type LiabilityCalculationInput = {
  asOf: Scalars["Date"];
  liabilityId: Scalars["ID"];
};

export type LiabilityCategoryItem = {
  __typename?: "LiabilityCategoryItem";
  id: Scalars["ID"];
  category: Scalars["String"];
  amountLiable: Money;
};

export type LiabilityCategoryReport = {
  __typename?: "LiabilityCategoryReport";
  items?: Maybe<Array<Maybe<LiabilityCategoryItem>>>;
};

export type LiabilityChangePreview = {
  __typename?: "LiabilityChangePreview";
  earliestStartDateTime: Scalars["DateTime"];
  effectivePaidToDate?: Maybe<Scalars["Date"]>;
  effectivePaidToDateWithProposedPlan?: Maybe<Scalars["Date"]>;
  mergedPlans: Array<Maybe<Plan>>;
  /** @deprecated Use `effectivePaidToDate` instead */
  paidToDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `effectivePaidToDateWithProposedPlan` instead */
  paidToDateWithProposedPlan?: Maybe<Scalars["DateTime"]>;
  requiredAdjustment: Money;
};

export type LiabilityEntry = {
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** @deprecated Use status in PaymentLiabilityEntry businessTransaction */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type LiabilityEntrySort = {
  field: LiabilityEntrySortField;
};

export enum LiabilityEntrySortField {
  DisplayDate = "DISPLAY_DATE",
  EffectiveAt = "EFFECTIVE_AT",
}

export type LiabilityPaymentPlan = {
  __typename?: "LiabilityPaymentPlan";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  details: LiabilityPaymentPlanDetails;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  liabilityId: Scalars["ID"];
  owner: Scalars["AiloRN"];
};

export type LiabilityPaymentPlanDetails = {
  __typename?: "LiabilityPaymentPlanDetails";
  amount: Money;
  anniversary: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  id: Scalars["ID"];
  liabilityPaymentPlanId: Scalars["ID"];
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars["ID"];
  startDate: Scalars["Date"];
  timezoneId: Scalars["String"];
};

export enum LiabilityPlanFrequency {
  Daily = "DAILY",
  Fortnightly = "FORTNIGHTLY",
  Monthly = "MONTHLY",
  OnceOff = "ONCE_OFF",
  Weekly = "WEEKLY",
}

export type LiabilityPlanInput = {
  asOfDate?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
  proposedPlans: Array<Maybe<ProposedPlan>>;
};

/**
 * There should be `RecurringFee` here as well,
 * but we temporarily do not support it.
 * (Should be done eventually with steps listed at https://github.com/ailohq/property-management-service/pull/241 )
 */
export type LiabilityReferencedEntity = Bill | Fee | Tenancy;

export type LiabilityReport = {
  __typename?: "LiabilityReport";
  reference: Scalars["String"];
  reportItems?: Maybe<Array<Maybe<LiabilityReportItem>>>;
};

export type LiabilityReportItem = {
  __typename?: "LiabilityReportItem";
  amount: Money;
  /** @deprecated Use `daysInArrearsV2` */
  daysInArrears: Scalars["Int"];
  daysInArrearsV2: Scalars["Int"];
  nextDueDate: Scalars["DateTime"];
  outstandingBalance: Money;
  /** @deprecated Use `paidToDateV2` */
  paidToDate: Scalars["DateTime"];
  paidToDateV2: Scalars["DateTime"];
  paymentDate: Scalars["DateTime"];
  /** @deprecated Use `rentArrearsV2` */
  rentArrears: Money;
  rentArrearsV2: Money;
};

export type LiabilityStatus = {
  __typename?: "LiabilityStatus";
  archivedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  id: Scalars["ID"];
  isArchived: Scalars["Boolean"];
  liabilityId: Scalars["ID"];
  payerKycCheckRequired: Scalars["Boolean"];
};

export type LineItem = {
  __typename?: "LineItem";
  id: Scalars["ID"];
  /** @deprecated Use `LineItem.id` instead */
  ailoRN: Scalars["AiloRN"];
  /** @deprecated Use `LineItem.taxInclusiveAmount` instead. */
  amount: Money;
  taxInclusiveAmount: Money;
  taxAmount: Money;
  description: Scalars["String"];
  isTax: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
};

export type LineItemInput = {
  /** Tax inclusive amount. */
  amount: MoneyInput;
  taxAmount?: Maybe<MoneyInput>;
  description: Scalars["String"];
  isTax: Scalars["Boolean"];
};

export type ListAccountsFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  accountReferences?: Maybe<Array<Scalars["AiloRN"]>>;
  accountSubtypes?: Maybe<Array<AccountSubtype>>;
  accountTypes?: Maybe<Array<AccountType>>;
  books?: Maybe<Array<Book>>;
  /** Default: includeAccountsWithNonPositiveBalances = true */
  includeAccountsWithNonPositiveBalances?: Maybe<Scalars["Boolean"]>;
};

export type ListAccountsSortParams = {
  direction: SortDirection;
  field: AccountSortField;
};

export type ListAccountTagBalancesFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  accountSubtypes: Array<AccountSubtype>;
  asOf?: Maybe<Scalars["DateTime"]>;
  book?: Maybe<Book>;
  tagValue?: Maybe<Scalars["String"]>;
};

export type ListEntriesFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  /**
   * Filter to include only entries which have account's reference match NS Entities.
   * NS Entities don't have "ailo:" prefix. Usage example: [ "authz:legalentity", "bill:bill" ]
   */
  accountReferenceNSEntities?: Maybe<Array<Scalars["String"]>>;
  accountReferences?: Maybe<Array<Scalars["AiloRN"]>>;
  accountSubtypes?: Maybe<Array<AccountSubtype>>;
  accountTypes?: Maybe<Array<AccountType>>;
  books?: Maybe<Array<Book>>;
  /** Filter to include only entries which have created_at < createdBefore */
  createdBefore?: Maybe<Scalars["DateTime"]>;
  /** Filter to include only entries which have created_at >= createdFrom */
  createdFrom?: Maybe<Scalars["DateTime"]>;
  includeEmptyAccountBalance?: Maybe<Scalars["Boolean"]>;
};

export type ListEntriesSortParams = {
  direction: SortDirection;
  field: EntrySortField;
};

export type ListPurposesFilters = {
  payableBy?: Maybe<Scalars["AiloRN"]>;
  payableTo?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<Scalars["String"]>;
};

export type ListTransactionsFilters = {
  createdAfter?: Maybe<Scalars["DateTime"]>;
  createdBefore?: Maybe<Scalars["DateTime"]>;
  management?: Maybe<Scalars["AiloRN"]>;
  moneyHolder: Scalars["AiloRN"];
  type?: Maybe<Scalars["String"]>;
};

export type ListTransactionsSortParams = {
  direction: SortDirection;
  field: TransactionsSortField;
};

export type LocalDateFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["LocalDate"]>;
  neq?: Maybe<Scalars["LocalDate"]>;
  gt?: Maybe<Scalars["LocalDate"]>;
  gte?: Maybe<Scalars["LocalDate"]>;
  lt?: Maybe<Scalars["LocalDate"]>;
  lte?: Maybe<Scalars["LocalDate"]>;
  like?: Maybe<Scalars["LocalDate"]>;
  notLike?: Maybe<Scalars["LocalDate"]>;
  iLike?: Maybe<Scalars["LocalDate"]>;
  notILike?: Maybe<Scalars["LocalDate"]>;
  in?: Maybe<Array<Scalars["LocalDate"]>>;
  notIn?: Maybe<Array<Scalars["LocalDate"]>>;
};

export type LocalDateRangeCondition = {
  gt?: Maybe<Scalars["LocalDate"]>;
  gte?: Maybe<Scalars["LocalDate"]>;
  lt?: Maybe<Scalars["LocalDate"]>;
  lte?: Maybe<Scalars["LocalDate"]>;
};

export type LockTrustAccountInput = {
  lockedUpTo: Scalars["DateTime"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type LongFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Long"]>;
  neq?: Maybe<Scalars["Long"]>;
  gt?: Maybe<Scalars["Long"]>;
  gte?: Maybe<Scalars["Long"]>;
  lt?: Maybe<Scalars["Long"]>;
  lte?: Maybe<Scalars["Long"]>;
  like?: Maybe<Scalars["Long"]>;
  notLike?: Maybe<Scalars["Long"]>;
  iLike?: Maybe<Scalars["Long"]>;
  notILike?: Maybe<Scalars["Long"]>;
  in?: Maybe<Array<Scalars["Long"]>>;
  notIn?: Maybe<Array<Scalars["Long"]>>;
};

export type Mail = {
  __typename?: "Mail";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  mailThreadAilorn: Scalars["AiloRN"];
  fromContact?: Maybe<Contact>;
  toContacts: Array<Maybe<Contact>>;
  attachmentFiles: Array<Maybe<File>>;
  externalId: Scalars["String"];
  externalSentAt: Scalars["DateTime"];
  fromEmailAddress: Scalars["String"];
  toEmailAddresses: Array<Scalars["String"]>;
  subject: Scalars["String"];
  htmlBody: Scalars["String"];
  plaintextBody: Scalars["String"];
  provider: MailboxProvider;
  directionDetails: MailDirectionDetails;
};

export type Mailbox = {
  __typename?: "Mailbox";
  emailAddress: Scalars["String"];
  externalStatus: ExternalMailboxStatus;
  externalStatusLastCheckedAt: Scalars["DateTime"];
  provider: MailboxProvider;
};

export type MailboxAccess = {
  __typename?: "MailboxAccess";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  contactWithAccess?: Maybe<Contact>;
  grantedAt: Scalars["DateTime"];
  mailbox: Mailbox;
};

export enum MailboxProvider {
  Gmail = "Gmail",
}

export enum MailDirection {
  Inbound = "Inbound",
  Outbound = "Outbound",
}

export type MailDirectionDetails =
  | InboundMailDirectionDetails
  | OutboundMailDirectionDetails;

export type ManageDepositActionMeta = {
  __typename?: "ManageDepositActionMeta";
  deposit?: Maybe<TenancyDeposit>;
};

export type Management = {
  __typename?: "Management";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  isDraft?: Maybe<Scalars["Boolean"]>;
  managingEntity?: Maybe<Company>;
  firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  owners?: Maybe<Array<Maybe<Ownership>>>;
  endDate?: Maybe<Scalars["Date"]>;
  createdAt: Scalars["DateTime"];
  migratedAt?: Maybe<Scalars["DateTime"]>;
  terminationReason?: Maybe<ManagementTerminationReason>;
  terminationNotes?: Maybe<Scalars["String"]>;
  endAbility: ManagementEndAbility;
  endReason?: Maybe<ManagementEndReason>;
  endNote?: Maybe<Scalars["String"]>;
  managementFolioAilorn: Scalars["AiloRN"];
  managementFolio: ManagementFolio;
  agencyProperty: AgencyProperty;
  /**
   * Tenancies with an end date before today's date,
   * Ordered by date range desc (the ones that have ended recently first).
   */
  formerTenancies?: Maybe<BidirectionalPaginatedTenancies>;
  /** Ordered by date range desc (the ones that have been voided recently first). */
  voidedTenancies?: Maybe<BidirectionalPaginatedTenancies>;
  /** Tenancy with a start date later than today */
  nextTenancy?: Maybe<Tenancy>;
  /** Tenancy with start date before or equal to today and closest to today's date */
  mostRecentTenancy?: Maybe<Tenancy>;
  /**
   * returns the first to exist of active, next or previous tenancy
   * in that order.
   */
  mostRelevantTenancy?: Maybe<Tenancy>;
  currentManagementAgreement?: Maybe<ManagementAgreement>;
  currentOrNextManagementAgreement?: Maybe<ManagementAgreement>;
  allManagementAgreements: Array<ManagementAgreement>;
  managementAgreements?: Maybe<PaginatedManagementAgreements>;
  currentOrNextManagementFeeSchedule?: Maybe<ManagementFeeSchedule>;
  fees?: Maybe<PaginatedFees>;
  recurringFees: Array<RecurringFee>;
  property: Property;
  files?: Maybe<PaginatedFiles>;
  note?: Maybe<ManagementNote>;
  allTenancies?: Maybe<Array<Maybe<Tenancy>>>;
  team?: Maybe<Team>;
  /** Statements with subject = Mangement Ailorn */
  statements?: Maybe<PaginatedStatements>;
  projects?: Maybe<PaginatedProjects>;
  ownerContacts: Array<Contact>;
};

export type ManagementFormerTenanciesArgs = {
  pageCursor?: Maybe<TenancyCursor>;
};

export type ManagementVoidedTenanciesArgs = {
  pageCursor?: Maybe<TenancyCursor>;
};

export type ManagementFeesArgs = {
  filter?: Maybe<ManagementFeeFilter>;
  cursor?: Maybe<PageCursorWithoutSort>;
  sort?: Maybe<Array<FeeSort>>;
};

export type ManagementFilesArgs = {
  pageCursor?: PaginationParams;
};

export type ManagementStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type ManagementProjectsArgs = {
  pageCursor?: ProjectCursor;
};

export type ManagementAgreement = {
  __typename?: "ManagementAgreement";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  startDate?: Maybe<Scalars["String"]>;
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["Int"];
  modifiedBy: Scalars["Int"];
  managementFeeSchedules?: Maybe<PaginatedManagementFeeSchedules>;
};

export type ManagementAndTenancyInput = {
  propertyId?: Maybe<Scalars["ID"]>;
  property?: Maybe<PropertyInput>;
  managingEntityId: Scalars["ID"];
  management: ManagementSetupInput;
  tenancy?: Maybe<TenancySetupInput>;
};

export enum ManagementCannotBeEndedProblem {
  TenancyEndDateMissing = "TenancyEndDateMissing",
}

export type ManagementEndAbility = {
  __typename?: "ManagementEndAbility";
  canBeEnded: Scalars["Boolean"];
  problem?: Maybe<ManagementCannotBeEndedProblem>;
};

export type ManagementEndCause = {
  __typename?: "ManagementEndCause";
  code: ManagementEndCauseCode;
  label: Scalars["String"];
};

export enum ManagementEndCauseCode {
  Fees = "Fees",
  LevelOfService = "LevelOfService",
  Communication = "Communication",
  ChangingTeamMembers = "ChangingTeamMembers",
  Other = "Other",
}

export type ManagementEndReason = {
  __typename?: "ManagementEndReason";
  code: ManagementEndReasonCode;
  label: Scalars["String"];
  causes: Array<ManagementEndCause>;
};

export enum ManagementEndReasonCode {
  LostToCompetitor = "LostToCompetitor",
  LostToOwnerSelfManaging = "LostToOwnerSelfManaging",
  OwnerOrFamilyMovedIn = "OwnerOrFamilyMovedIn",
  ManagementTerminatedByOurAgency = "ManagementTerminatedByOurAgency",
  ManagementSoldToAnotherAgency = "ManagementSoldToAnotherAgency",
  PropertyRenovations = "PropertyRenovations",
  PropertySaleByOurAgency = "PropertySaleByOurAgency",
  PropertySaleByAnotherAgency = "PropertySaleByAnotherAgency",
  PropertySaleByOwner = "PropertySaleByOwner",
  Other = "Other",
  Offboarded = "Offboarded",
}

export type ManagementFeeBlueprint = {
  __typename?: "ManagementFeeBlueprint";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  feeBlueprint: FeeBlueprint;
  management: Management;
  fixedAmount?: Maybe<Money>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  chargeType: FeeBlueprintChargeType;
  createdAt: Scalars["DateTime"];
  createdBy: ActionInitiator;
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<ActionInitiator>;
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  description?: Maybe<Scalars["String"]>;
};

export type ManagementFeeBlueprintsQueryConditions = {
  managementId?: Maybe<Scalars["ID"]>;
  feeBlueprintId?: Maybe<Scalars["ID"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<FeeBlueprintType>;
};

export type ManagementFeeFilter = {
  type?: Maybe<Array<FeeType>>;
  appliedToAiloRN?: Maybe<Array<Scalars["AiloRN"]>>;
  status?: Maybe<Array<FeeStatus>>;
};

export type ManagementFeeSchedule = {
  __typename?: "ManagementFeeSchedule";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  /**
   * Percentage of rent that will deduce the fee amount.
   * In range 0 - 1.
   */
  percent: Scalars["Float"];
  startDate: Scalars["String"];
  management: Management;
  managementAgreement: ManagementAgreement;
};

export type ManagementFilterAddressSimilarityInput = {
  search: Scalars["String"];
  /**
   * Threshold from 0 to 1 for how similar returned results must be
   * 0: All results
   * 1: Extremely similar
   */
  threshold?: Scalars["Float"];
};

export type ManagementFilterParams = {
  name: ManagementFilterTypes;
  args?: Maybe<MgmtFilterArgInput>;
};

export enum ManagementFilterTypes {
  HasActiveTenancyInArrears = "HasActiveTenancyInArrears",
  HasActiveTenancyInArrearsFromOwing = "HasActiveTenancyInArrearsFromOwing",
  HasOverdueTenancy = "HasOverdueTenancy",
  HasOverdueTenancyFromOwing = "HasOverdueTenancyFromOwing",
  HasActiveTenancy = "HasActiveTenancy",
  HasTenancyUpForRentReviewOrLeaseRenewal = "HasTenancyUpForRentReviewOrLeaseRenewal",
  /**
   * enum BelongToTeams will eventually be deprecated,
   * use BelongToTeamsAndUnassigned instead
   */
  BelongToTeams = "BelongToTeams",
  BelongToTeamsAndUnassigned = "BelongToTeamsAndUnassigned",
  Active = "Active",
  DefaultPropertyList = "DefaultPropertyList",
  Terminated = "Terminated",
  HasOccupancyStatus = "HasOccupancyStatus",
  AddressSimilarity = "AddressSimilarity",
  ExcludeLost = "ExcludeLost",
}

export type ManagementFolio = {
  __typename?: "ManagementFolio";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  managingEntity?: Maybe<Company>;
  owners: Array<ManagementFolioOwnership>;
  /**
   * All managements including current and former ones that were previously in this
   * folio before they were moved to a different folio.
   * The same management can appear multiple times in this list if it was moved out, back in and out again.
   */
  allManagements: PaginatedManagementFolioToManagements;
  /**
   * Managements that were previously in this folio before they were moved to a different folio.
   * The same management can appear multiple times in this list if it was moved out, back in and out again.
   * @deprecated Use allManagements instead
   */
  formerManagements: PaginatedManagementFolioToManagements;
  /** Managements that are currently in this Management Folio. */
  managements: PaginatedManagements;
  wallet?: Maybe<Wallet>;
};

export type ManagementFolioAllManagementsArgs = {
  paginationParams?: PaginationParams;
  filter?: Maybe<ManagementFolioManagementFilter>;
};

export type ManagementFolioFormerManagementsArgs = {
  paginationParams?: PaginationParams;
};

export type ManagementFolioManagementsArgs = {
  paginationParams?: PaginationParams;
};

export type ManagementFolioManagementFilter = {
  isCurrent?: Maybe<Scalars["Boolean"]>;
};

export type ManagementFolioOwnership = {
  __typename?: "ManagementFolioOwnership";
  managementFolio: ManagementFolio;
  owner?: Maybe<LegalEntity>;
  startDate: Scalars["LocalDate"];
  isPrimary: Scalars["Boolean"];
  consumerInvite?: Maybe<ConsumerInvite>;
};

export type ManagementFolioToManagement = {
  __typename?: "ManagementFolioToManagement";
  id: Scalars["ID"];
  management: Management;
  managementFolio: ManagementFolio;
  startDatetime: Scalars["DateTime"];
  endDatetime?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
};

export type ManagementNote = {
  __typename?: "ManagementNote";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  management: Management;
  note: Scalars["String"];
};

export type ManagementPropertyAddress = ContactPropertyAddress & {
  __typename?: "ManagementPropertyAddress";
  managementAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
  firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export type ManagementSetupInput = {
  startDate: Scalars["String"];
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  percent: Scalars["Float"];
  owners: Array<OwnershipSetupInput>;
};

export enum ManagementTerminationReason {
  Lost = "Lost",
  OffBoarded = "OffBoarded",
}

export type MessageInputV2 = {
  text?: Maybe<Scalars["String"]>;
  fileAilorns: Array<Scalars["AiloRN"]>;
  sendEmailFromMailboxAccessAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MessageSummaryV2 = {
  __typename?: "MessageSummaryV2";
  /**
   * The first portion of the message, truncated at 64 characters.
   * If the message contains only attachments, this will contain
   * a text summary, e.g. "sent 2 files".
   */
  snippet: Scalars["String"];
  attachmentCount: Scalars["Int"];
  sentAt: Scalars["DateTime"];
  sender: Contact;
};

export type MessageV2 = {
  __typename?: "MessageV2";
  ailorn: Scalars["AiloRN"];
  sender: Contact;
  sentAt: Scalars["DateTime"];
  text?: Maybe<Scalars["String"]>;
  files: Array<File>;
  mail?: Maybe<Mail>;
};

export type MgmtFilterArgInput = {
  BelongToTeams?: Maybe<Array<Scalars["AiloRN"]>>;
  BelongToTeamsAndUnassigned?: Maybe<BelongToTeamsArgInput>;
  HasOccupancyStatus?: Maybe<Array<OccupancyStatus>>;
  AddressSimilarity?: Maybe<ManagementFilterAddressSimilarityInput>;
};

export type MigratingManagement = {
  __typename?: "MigratingManagement";
  id: Scalars["ID"];
  organisation?: Maybe<Organisation>;
  legalEntity?: Maybe<LegalEntity>;
  trustManagementId: Scalars["String"];
  trustTenancyId?: Maybe<Scalars["String"]>;
  ailoManagement?: Maybe<Management>;
  ailoTenancy?: Maybe<Tenancy>;
  body: Scalars["JSON"];
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
  modifiedBy?: Maybe<ActionInitiator>;
  healthcheckRunAt?: Maybe<Scalars["DateTime"]>;
  portfolioName?: Maybe<Scalars["String"]>;
  status: MigratingManagementStatus;
  statusChangedAt: Scalars["DateTime"];
  statusChangedBy?: Maybe<ActionInitiator>;
  approvedBy?: Maybe<ActionInitiator>;
  approvedByDisplay?: Maybe<Scalars["String"]>;
  approvedAt?: Maybe<Scalars["DateTime"]>;
  migrationFailureReason?: Maybe<Scalars["String"]>;
  exclusionReason?: Maybe<Scalars["String"]>;
  exclusionNotes?: Maybe<Scalars["String"]>;
  hasVacatingTenancy: Scalars["Boolean"];
  migrateAsVacant: Scalars["Boolean"];
  errors?: Maybe<Scalars["JSON"]>;
  postApprovalTasks?: Maybe<Scalars["JSON"]>;
  batchRef?: Maybe<Scalars["String"]>;
  paidTo?: Maybe<Scalars["Date"]>;
  partialPaidCents?: Maybe<Scalars["String"]>;
  /** Simplified publish status for display to users, only relevant when status is Approved or InProgress */
  publishStatus?: Maybe<MigratingManagementPublishStatus>;
};

export enum MigratingManagementPublishStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Approved = "Approved",
}

export type MigratingManagementsFilters = {
  ids?: Maybe<Array<Scalars["ID"]>>;
  organisationId?: Maybe<Scalars["AiloRN"]>;
  search?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  batchRef?: Maybe<Scalars["String"]>;
  portfolioName?: Maybe<Scalars["String"]>;
  withErrors?: Maybe<Scalars["Boolean"]>;
  withPostApprovalTasks?: Maybe<Scalars["Boolean"]>;
  withFailedMigration?: Maybe<Scalars["Boolean"]>;
};

export enum MigratingManagementSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  ModifiedAt = "ModifiedAt",
  ApprovedAt = "ApprovedAt",
  Address = "Address",
  DataIssues = "DataIssues",
  Portfolio = "Portfolio",
  StatusChangedAt = "StatusChangedAt",
  PublishStatus = "PublishStatus",
  ExclusionReason = "ExclusionReason",
}

export type MigratingManagementSortParams = {
  field: MigratingManagementSortField;
  direction: SortDirection;
};

export enum MigratingManagementStatus {
  Created = "Created",
  Ignored = "Ignored",
  Excluded = "Excluded",
  InProgress = "InProgress",
  Approved = "Approved",
  Imported = "Imported",
}

export enum MigrationIssueKey {
  VacatingTenancy = "VacatingTenancy",
}

export type MigrationTask = {
  __typename?: "MigrationTask";
  legalEntityId: Scalars["AiloRN"];
  company?: Maybe<Company>;
  status: MigrationTaskStatus;
  startedBy?: Maybe<ActionInitiator>;
  startedAt: Scalars["DateTime"];
  finishedAt?: Maybe<Scalars["DateTime"]>;
  lastError?: Maybe<Scalars["String"]>;
};

export enum MigrationTaskStatus {
  Ingesting = "Ingesting",
  Transforming = "Transforming",
  Success = "Success",
  Failed = "Failed",
}

export type Money = {
  __typename?: "Money";
  cents: Scalars["Long"];
};

export type MoneyInput = {
  cents: Scalars["Long"];
};

export type MoveAllManagementsToTeamInput = {
  fromTeam: Scalars["AiloRN"];
  toTeam: Scalars["AiloRN"];
};

export type MoveAllManagementsToTeamPayload = {
  __typename?: "MoveAllManagementsToTeamPayload";
  newTeam: Team;
};

export type Mutation = {
  __typename?: "Mutation";
  unlockReportPeriod: ReportPeriodLockChangeResult;
  createOneTrustAccountReconciliationAdjustment: TrustAccountReconciliationAdjustment;
  removeOneTrustAccountReconciliationAdjustment: TrustAccountReconciliationAdjustment;
  createOneTrustAccountStatementBalance: TrustAccountStatementBalance;
  upsertEftPaymentSettings: EftPaymentSettings;
  createOneTrustAccount: TrustAccount;
  updateOneTrustAccount: TrustAccount;
  createStatements: CreateStatementsResponse;
  createStatementsWithSubject: CreateStatementsResponse;
  createStatementsForAllManagements: Scalars["String"];
  retryCreateStatement?: Maybe<Statement>;
  addTrustAccount?: Maybe<TrustBankAccount>;
  disburseFundsToTrustAccount?: Maybe<DisburseFundsResponse>;
  addCentrepayDirective: AddCentrepayDirectiveOutput;
  deleteCentrepayDirective: Scalars["Boolean"];
  upsertCentrepayFeeOverride: CentrepayFeeSetting;
  upsertOfflineProcessingEnabledForTenancy: OfflinePaymentProcessingEnabled;
  createTenancy?: Maybe<Tenancy>;
  updateEndOfTenancy?: Maybe<Tenancy>;
  updateIngoingTenancy?: Maybe<Tenancy>;
  reviewLease?: Maybe<Tenancy>;
  voidTenancy: VoidTenancyPayload;
  alterTenancyStartDate?: Maybe<Tenancy>;
  createRents?: Maybe<CreateRentsPayload>;
  deleteRent?: Maybe<DeleteRentPayload>;
  /** @deprecated Use `createFeeBlueprint` instead. */
  createRecurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  createFeeBlueprint?: Maybe<FeeBlueprint>;
  findOrCreateFeeBlueprint?: Maybe<FeeBlueprint>;
  /** @deprecated Use `updateFeeBlueprint` instead. */
  updateRecurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  updateFeeBlueprint?: Maybe<FeeBlueprint>;
  clearManagementEnd?: Maybe<ClearManagementEndPayload>;
  updateManagementAgreement: UpdateManagementAgreementPayload;
  updateManagementFee: ManagementFeeSchedule;
  createFee?: Maybe<Fee>;
  updateFee?: Maybe<Fee>;
  createRecurringFee?: Maybe<RecurringFee>;
  createRecurringFees?: Maybe<Array<RecurringFee>>;
  /**
   * Cancels the recurring fee by setting its end date, to stop charging the fee.
   * Any outstanding balance still needs to be paid off.
   */
  cancelRecurringFee?: Maybe<RecurringFee>;
  updateRecurringFee?: Maybe<RecurringFee>;
  setupManagementAndTenancy?: Maybe<SetupResult>;
  createProperty?: Maybe<Property>;
  updateProperty?: Maybe<Property>;
  createRentCredit?: Maybe<RentCreditDetailsPayload>;
  createTenancyAgreement?: Maybe<CreateTenancyAgreementPayload>;
  updateTenancyAgreement?: Maybe<UpdateTenancyAgreementPayload>;
  /** @deprecated Use `createRentReview` instead. */
  reviewRent?: Maybe<Tenancy>;
  createRentReview?: Maybe<CreateRentReviewPayload>;
  editRentReview?: Maybe<EditRentReviewPayload>;
  attachFilesToTenancy?: Maybe<Tenancy>;
  attachFilesToManagement?: Maybe<Management>;
  deleteTenancyFile?: Maybe<DeleteTenancyFilePayload>;
  deleteManagementFile?: Maybe<DeleteManagementFilePayload>;
  findOrCreateManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  createManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  updateManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  createOrUpdateManagementNote?: Maybe<CreateOrUpdateManagementNotePayload>;
  endManagementV2: EndManagementV2Payload;
  deleteDraftManagement?: Maybe<Scalars["ID"]>;
  publishManagement?: Maybe<Management>;
  updateOwnerShares?: Maybe<Management>;
  deleteDraftTenancy?: Maybe<Scalars["ID"]>;
  publishTenancy?: Maybe<Tenancy>;
  createTenancyBondAccount: TenancyBondAccount;
  createTenancyBond?: Maybe<TenancyBond>;
  updateTenancyBond?: Maybe<TenancyBond>;
  upsertTenancyBondClaims: Array<Maybe<TenancyBondClaim>>;
  removeBondClaim: TenancyBond;
  disburseTenancyBondClaims: TenancyBond;
  cancelTenancyDeposit?: Maybe<TenancyDeposit>;
  transferTenancyDeposit?: Maybe<TenancyDeposit>;
  markTenancyDepositAsReleased?: Maybe<TenancyDeposit>;
  createTenantship: CreateTenantshipPayload;
  removeTenantship: RemoveTenantshipPayload;
  createTeam?: Maybe<Team>;
  deleteTeam: DeleteTeamPayload;
  editTeam: EditTeamPayload;
  movePeopleToTeam?: Maybe<Team>;
  moveManagementsToTeam?: Maybe<Team>;
  moveAllManagementsToTeam?: Maybe<MoveAllManagementsToTeamPayload>;
  createCentrepayAccount: CentrepayAccount;
  createOfflinePaymentAccount: OfflinePaymentAccount;
  createProject?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  closeProject?: Maybe<Project>;
  reopenProject?: Maybe<Project>;
  /** Deprecated - use archiveProjects instead */
  archiveProject?: Maybe<Scalars["ID"]>;
  archiveProjects: ArchiveProjectsPayload;
  unarchiveProject?: Maybe<Project>;
  createAction?: Maybe<Action>;
  updateAction?: Maybe<Action>;
  reassignAction?: Maybe<Action>;
  updateActionDueDate?: Maybe<Action>;
  completeAction?: Maybe<Action>;
  uncompleteAction?: Maybe<Action>;
  addProjectFiles?: Maybe<Array<Maybe<ProjectFile>>>;
  removeProjectFiles?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  completeAssignTenancyAgreementAction?: Maybe<Action>;
  completeAssignRentAction?: Maybe<CompleteAssignRentActionPayload>;
  completeAssignTenancyAction?: Maybe<CompleteAssignTenancyActionPayload>;
  completeAssignNewManagementAction?: Maybe<CompleteAssignNewManagementActionPayload>;
  completeAssignFormAction?: Maybe<CompleteAssignFormActionPayload>;
  lockTrustAccount?: Maybe<TrustAccountLock>;
  payFromWallet?: Maybe<SagaStatusResponse>;
  receiptFunds?: Maybe<SagaStatusResponse>;
  walletTransfer?: Maybe<SagaStatusResponse>;
  createRecurringOwing?: Maybe<RecurringOwing>;
  updateRecurringOwing?: Maybe<RecurringOwing>;
  /** @deprecated Use createOrUpdateUserPreferenceV2 */
  createOrUpdateUserPreference?: Maybe<UserPreference>;
  createOrUpdateUserPreferenceV2?: Maybe<Array<Maybe<UserPreference>>>;
  sendCentrepayOnboardingHelpLink?: Maybe<NotificationSent>;
  sendCentrepayAccountSetupInstructions?: Maybe<NotificationSent>;
  createExternalTrustAccount?: Maybe<ExternalTrustAccount>;
  disableExternalTrustAccount?: Maybe<ExternalTrustAccount>;
  enableExternalTrustAccount?: Maybe<ExternalTrustAccount>;
  uploadMigrationCSV?: Maybe<Array<MigratingManagement>>;
  updateMigratingManagement?: Maybe<MigratingManagement>;
  removeTenancyFromMigratingManagement?: Maybe<MigratingManagement>;
  approveMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  unapproveMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  migrateManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  publishMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  startMigration?: Maybe<MigrationTask>;
  startAllMigrationTasks?: Maybe<BulkActionMigrationTasksPayload>;
  revokeMailboxAccess: Scalars["Boolean"];
  sendMail: Mail;
  getMailboxAuthorizationUrl?: Maybe<Scalars["String"]>;
  /** @deprecated Use `createPaymentMethod` instead */
  addBPay?: Maybe<BPay>;
  /** @deprecated Use `createPaymentMethod` instead */
  addBankAccount?: Maybe<BankAccount>;
  /** @deprecated Use `createPaymentMethod` instead */
  addCreditCard?: Maybe<CreditCard>;
  adjustLiabilityEntry?: Maybe<AdjustmentLiabilityEntry>;
  archiveLiability: LiabilityStatus;
  cancelAutoPay?: Maybe<ResponseStatus>;
  cancelAutoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  cancelAutoWithdrawPlan?: Maybe<AutoWithdrawPlan>;
  cancelLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  cleanOrphanedBusinessTransaction: BusinessTransactionStatus;
  cleanUnclearedBusinessTransaction: BusinessTransactionStatus;
  /**
   * Use for BILL HOP1 late reversal and HOP2 has already been SUCCESS and cleared. Details: http://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * No real world payment transaction will be created, purely Ailo adjustment internally.
   */
  createAdjustmentForPayer: BusinessTransaction;
  createAutoWithdrawPlanV2?: Maybe<AutoWithdrawPlan>;
  createBankAccountBlacklist: BankAccountBlacklist;
  createLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  createOrUpdatePaymentMethodAiloFeeWaiver: Array<Maybe<PaymentMethodAiloFees>>;
  createOverdraftAllowance: OverdraftAllowance;
  createPaymentMethod?: Maybe<PaymentMethod>;
  createUpdateAutoPay?: Maybe<ResponseStatus>;
  creditFromAiloBank?: Maybe<ResponseStatus>;
  deleteBankAccountBlacklist: BankAccountBlacklist;
  /**
   * Graphql best practice require mutation return something.
   * This should always return true.
   * Exceptions may be thrown in error.
   */
  deletePaymentMethod: Scalars["Boolean"];
  emitLiabilityStateChangedEvent?: Maybe<Liability>;
  generateLiabilityEntries: GenerateLiabilityEntriesPayload;
  /**
   * This should only be used by admin or service-to-service communication.
   * This is used to prevent creating duplicate bank account.
   * This never creates once-off bank account.
   */
  getOrCreateWalletOwnerAndBankAccount?: Maybe<BankAccount>;
  partialRefundLiabilityPayment: Array<RefundOutput>;
  payLiability?: Maybe<ResponseStatus>;
  payLiabilityFromWallet?: Maybe<ResponseStatus>;
  /**
   * Use for RENT/FEE/BILL HOP1 REDUND. Details: https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * If the payment is coming from a payment method, it will credit the money back
   * to the payment method, so real world payment transaction will be initiated
   * If the payment is coming from a wallet, it will credit the money back to the wallet.
   */
  refundLiabilityPayment: Array<RefundOutput>;
  reverseAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  /**
   * Use for RENT late revsersal, make sure the management fee has been refund
   * first before late reversal of RENT. Details: https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * Use for BILL Hop2 late reversal, make sure the param are bill HOP2 BT id, no
   * overdraft allowance will be created against Ailo bank wallet.
   * Use for BILL Hop1 late reversal and HOP2 VOIDed or FAILed.  make sure the
   * param are bill HOP1 BT id, no overdraft allowance will be created against
   * supplier wallet
   * Use for wallet withdraw late reversal, no overdraft allowance will be created.
   * No real world payment transaction will be created, purely Ailo adjustment internally.
   */
  reverseBusinessTx: BusinessTransaction;
  transferToWallet?: Maybe<ResponseStatus>;
  updateAutoWithdrawPlanV2?: Maybe<AutoWithdrawPlan>;
  updateLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  upsertAutoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  withdrawFromWallet?: Maybe<ResponseStatus>;
  writeOff?: Maybe<ResponseStatus>;
  createOneKey: Key;
  updateOneKey: Key;
  deleteOneKey: KeyDeleteResponse;
  createInspection: CreateInspectionResult;
  updateInspection: UpdateInspectionResult;
  removeInspection: RemoveInspectionResult;
  createInspectionArea: CreateInspectionAreaResult;
  updateInspectionArea: UpdateInspectionAreaResult;
  removeInspectionArea: RemoveInspectionAreaResult;
  createInspectionAreaFile: CreateInspectionAreaFileResult;
  removeInspectionAreaFile: RemoveInspectionAreaFileResult;
  createInspectionFeature: CreateInspectionFeatureResult;
  updateInspectionFeature: UpdateInspectionFeatureResult;
  removeInspectionFeature: RemoveInspectionFeatureResult;
  createInspectionFeatureFile: CreateInspectionFeatureFileResult;
  removeInspectionFeatureFile: RemoveInspectionFeatureFileResult;
  createInspectionAppointments: CreateInspectionAppointmentsResult;
  updateInspectionAppointment: UpdateInspectionAppointmentResult;
  cancelInspectionAppointment: CancelInspectionAppointmentResult;
  setRoutineInspectionDueDate: SetRoutineInspectionDueDateResult;
  generateInspectionReport: GenerateInspectionReportResult;
  createBanana?: Maybe<Banana>;
  createReiToken: ReiToken;
  updateReiToken: ReiToken;
  removeReiToken: ReiToken;
  createReiFormFromUserTemplate: ReiForm;
  createAiloForm: CreateAiloFormResult;
  updateAiloForm: UpdateAiloFormResult;
  /**
   * Creates a new file entity attached to the current user,
   * and returns it together with an AWS S3 Upload URL to which the user can upload a file.
   */
  startFileUpload: FileWithUploadUrl;
  /**
   * Validate if the file has been uploaded.
   *
   * File needs to belong to the current user,
   * or if it's a m2m call, requires "file:claim" scope.
   */
  validateFile: ValidateFileResponse;
  /**
   * Claims files and sets their permitted read entities (related to the claimer).
   *
   * Requires "file:claim" scope.
   */
  claimFiles: ClaimFilesResponse;
  /**
   * Unclaims files and removes all read permissions (related to the claimer).
   *
   * Requires "file:claim" scope.
   */
  unclaimFiles: Scalars["Boolean"];
  createFileShareLinks: Array<FileShareLink>;
  deleteFileShareLinks: OkResult;
  createZipFile: File;
  subscribeCustomer?: Maybe<PlatformSubscription>;
  setSubscriptionEndDate?: Maybe<PlatformSubscription>;
  createOtherContact: Contact;
  findOrCreateOtherContacts: Array<Contact>;
  syncLegalEntity: Array<Contact>;
  createChatV2?: Maybe<ChatV2>;
  createThreadV2: ThreadV2;
  sendMessageV2: MessageV2;
  acknowledgeThread?: Maybe<ThreadV2>;
  unacknowledgeThreadFromMessage?: Maybe<ThreadV2>;
  createBill?: Maybe<Bill>;
  archiveBill?: Maybe<Bill>;
  addBillAttachments?: Maybe<Bill>;
  createPaymentReference?: Maybe<PaymentReference>;
  findOrCreatePaymentReference?: Maybe<PaymentReference>;
  createSupplier?: Maybe<Supplier>;
  findOrCreateInternalSupplier?: Maybe<Supplier>;
  archiveSupplier?: Maybe<Supplier>;
  updateSupplier?: Maybe<Supplier>;
  noopAutopayment?: Maybe<Scalars["Boolean"]>;
  sendEmailVerificationCode?: Maybe<SendEmailVerificationResponse>;
  verifyEmail?: Maybe<VerifyEmailResponse>;
  cancelEmailVerifications?: Maybe<Scalars["Boolean"]>;
  onboardUsers?: Maybe<Array<Maybe<User>>>;
  updateUserEmailAddress?: Maybe<User>;
  completeOnboardingTask?: Maybe<UserOnboardingTask>;
  acceptTermsOfServices?: Maybe<Person>;
  updatePersonProfileDetails?: Maybe<Person>;
  createPerson?: Maybe<Person>;
  createUserForPerson?: Maybe<Person>;
  createPeople?: Maybe<Array<Person>>;
  updateCompanyProfileDetails?: Maybe<Company>;
  createCompany?: Maybe<Company>;
  addMemberToOrganisation?: Maybe<OrganisationMembership>;
  removeMemberFromOrganisation?: Maybe<Scalars["Boolean"]>;
  addMemberToLegalEntity?: Maybe<LegalEntityMembership>;
  removeMemberFromLegalEntity?: Maybe<Scalars["Boolean"]>;
  upsertCrn?: Maybe<UpsertCrnOutput>;
  deleteCrn?: Maybe<Scalars["Boolean"]>;
  atpEmptyStarterSchemaNoop?: Maybe<Scalars["Boolean"]>;
};

export type MutationUnlockReportPeriodArgs = {
  input: UnlockReportPeriodInput;
};

export type MutationCreateOneTrustAccountReconciliationAdjustmentArgs = {
  input: CreateOneTrustAccountReconciliationAdjustmentInput;
};

export type MutationRemoveOneTrustAccountReconciliationAdjustmentArgs = {
  input: RemoveTrustAccountReconciliationAdjustmentInput;
};

export type MutationCreateOneTrustAccountStatementBalanceArgs = {
  input: CreateOneTrustAccountStatementBalanceInput;
};

export type MutationUpsertEftPaymentSettingsArgs = {
  input: EftPaymentSettingsInput;
};

export type MutationCreateOneTrustAccountArgs = {
  input: CreateOneTrustAccountInput;
};

export type MutationUpdateOneTrustAccountArgs = {
  input: UpdateOneTrustAccountInput;
};

export type MutationCreateStatementsArgs = {
  input: CreateStatementInput;
};

export type MutationCreateStatementsWithSubjectArgs = {
  input: CreateStatementWithSubjectInput;
};

export type MutationCreateStatementsForAllManagementsArgs = {
  input: CreateStatementsForAllManagementsInput;
};

export type MutationRetryCreateStatementArgs = {
  progressAiloRN: Scalars["AiloRN"];
};

export type MutationAddTrustAccountArgs = {
  input: AddTrustAccountInput;
};

export type MutationDisburseFundsToTrustAccountArgs = {
  input: DisburseToTrustAccountInput;
};

export type MutationAddCentrepayDirectiveArgs = {
  input: AddCentrepayDirectiveInput;
};

export type MutationDeleteCentrepayDirectiveArgs = {
  input: DeleteCentrepayDirectiveInput;
};

export type MutationUpsertCentrepayFeeOverrideArgs = {
  input: CentrepayFeeOverrideInput;
};

export type MutationUpsertOfflineProcessingEnabledForTenancyArgs = {
  input: OfflinePaymentProcessingEnabledInput;
};

export type MutationCreateTenancyArgs = {
  tenancyDetails: TenancySetupInput;
  managementId: Scalars["ID"];
  autoPublish?: Maybe<Scalars["Boolean"]>;
  doNotNotify?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateEndOfTenancyArgs = {
  tenancyId: Scalars["ID"];
  endDate?: Maybe<Scalars["Date"]>;
  reason?: Maybe<VacatingReason>;
  notes?: Maybe<Scalars["String"]>;
};

export type MutationUpdateIngoingTenancyArgs = {
  tenancyDetails: IngoingTenancyUpdateInput;
};

export type MutationReviewLeaseArgs = {
  tenancyId: Scalars["ID"];
  agreementDetails?: Maybe<LeaseRenewalDetails>;
  allowCurrentTenancyAgreementToLapse?: Maybe<Scalars["Boolean"]>;
};

export type MutationVoidTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type MutationAlterTenancyStartDateArgs = {
  tenancyId: Scalars["ID"];
  startDate: Scalars["String"];
};

export type MutationCreateRentsArgs = {
  input: CreateRentsInput;
};

export type MutationDeleteRentArgs = {
  input: DeleteRentInput;
};

export type MutationCreateRecurringFeeBlueprintArgs = {
  input: RecurringFeeBlueprintInput;
};

export type MutationCreateFeeBlueprintArgs = {
  input: CreateFeeBlueprintInput;
};

export type MutationFindOrCreateFeeBlueprintArgs = {
  input: FindOrCreateBlueprintInput;
};

export type MutationUpdateRecurringFeeBlueprintArgs = {
  input: UpdateRecurringFeeBlueprintInput;
};

export type MutationUpdateFeeBlueprintArgs = {
  input: UpdateFeeBlueprintInput;
};

export type MutationClearManagementEndArgs = {
  managementId: Scalars["ID"];
};

export type MutationUpdateManagementAgreementArgs = {
  input: UpdateManagementAgreementInput;
};

export type MutationUpdateManagementFeeArgs = {
  input: UpdateManagementFeeInput;
};

export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};

export type MutationUpdateFeeArgs = {
  input: UpdateFeeInput;
};

export type MutationCreateRecurringFeeArgs = {
  input: RecurringFeeInput;
};

export type MutationCreateRecurringFeesArgs = {
  input: Array<RecurringFeeInput>;
};

export type MutationCancelRecurringFeeArgs = {
  input: CancelRecurringFeeInput;
};

export type MutationUpdateRecurringFeeArgs = {
  input: UpdateRecurringFeeInput;
};

export type MutationSetupManagementAndTenancyArgs = {
  managementAndTenancyDetails: ManagementAndTenancyInput;
  autoPublish?: Maybe<Scalars["Boolean"]>;
  doNotNotify?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreatePropertyArgs = {
  propertyDetails: PropertyInput;
};

export type MutationUpdatePropertyArgs = {
  updatePropertyInput: UpdatePropertyInput;
};

export type MutationCreateRentCreditArgs = {
  input: RentCreditDetailsInput;
};

export type MutationCreateTenancyAgreementArgs = {
  tenancyId: Scalars["ID"];
  agreementDetails: TenancyAgreementDetails;
};

export type MutationUpdateTenancyAgreementArgs = {
  input: UpdateTenancyAgreementInput;
};

export type MutationReviewRentArgs = {
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
};

export type MutationCreateRentReviewArgs = {
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
};

export type MutationEditRentReviewArgs = {
  input: EditRentReviewInput;
};

export type MutationAttachFilesToTenancyArgs = {
  tenancyId: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
};

export type MutationAttachFilesToManagementArgs = {
  managementId: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
  fileOwnerAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MutationDeleteTenancyFileArgs = {
  input: DeleteTenancyFileInput;
};

export type MutationDeleteManagementFileArgs = {
  input: DeleteManagementFileInput;
};

export type MutationFindOrCreateManagementFeeBlueprintArgs = {
  input: FindOrCreateManagementFeeBlueprintInput;
};

export type MutationCreateManagementFeeBlueprintArgs = {
  input: CreateManagementFeeBlueprintInput;
};

export type MutationUpdateManagementFeeBlueprintArgs = {
  input: UpdateManagementFeeBlueprintInput;
};

export type MutationCreateOrUpdateManagementNoteArgs = {
  input: CreateOrUpdateManagementNoteInput;
};

export type MutationEndManagementV2Args = {
  input: EndManagementV2Input;
};

export type MutationDeleteDraftManagementArgs = {
  managementId: Scalars["ID"];
};

export type MutationPublishManagementArgs = {
  managementId: Scalars["ID"];
};

export type MutationUpdateOwnerSharesArgs = {
  managementId: Scalars["ID"];
  ownerShares: Array<OwnerShareInput>;
};

export type MutationDeleteDraftTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type MutationPublishTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type MutationCreateTenancyBondAccountArgs = {
  managingEntity: Scalars["AiloRN"];
};

export type MutationCreateTenancyBondArgs = {
  bondDetails: CreateBondInput;
};

export type MutationUpdateTenancyBondArgs = {
  bondDetails: UpdateBondInput;
};

export type MutationUpsertTenancyBondClaimsArgs = {
  bond: UpsertTenancyBondClaims;
};

export type MutationRemoveBondClaimArgs = {
  bondAilorn: Scalars["AiloRN"];
};

export type MutationDisburseTenancyBondClaimsArgs = {
  bondId: Scalars["ID"];
};

export type MutationCancelTenancyDepositArgs = {
  tenancyDepositId: Scalars["AiloRN"];
};

export type MutationTransferTenancyDepositArgs = {
  tenancyDepositId: Scalars["AiloRN"];
  transferAmount?: Maybe<MoneyInput>;
};

export type MutationMarkTenancyDepositAsReleasedArgs = {
  tenancyDepositId: Scalars["AiloRN"];
};

export type MutationCreateTenantshipArgs = {
  input: CreateTenantshipInput;
};

export type MutationRemoveTenantshipArgs = {
  input: RemoveTenantshipInput;
};

export type MutationCreateTeamArgs = {
  organisationId: Scalars["AiloRN"];
  name: Scalars["String"];
};

export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};

export type MutationEditTeamArgs = {
  input: EditTeamInput;
};

export type MutationMovePeopleToTeamArgs = {
  memberIds: Array<Scalars["AiloRN"]>;
  teamId: Scalars["AiloRN"];
};

export type MutationMoveManagementsToTeamArgs = {
  managementIds: Array<Scalars["AiloRN"]>;
  teamId: Scalars["AiloRN"];
};

export type MutationMoveAllManagementsToTeamArgs = {
  input: MoveAllManagementsToTeamInput;
};

export type MutationCreateCentrepayAccountArgs = {
  managingEntity: Scalars["AiloRN"];
};

export type MutationCreateOfflinePaymentAccountArgs = {
  managingEntity: Scalars["AiloRN"];
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationCloseProjectArgs = {
  id: Scalars["ID"];
};

export type MutationReopenProjectArgs = {
  id: Scalars["ID"];
};

export type MutationArchiveProjectArgs = {
  id: Scalars["ID"];
};

export type MutationArchiveProjectsArgs = {
  input: ArchiveProjectsInput;
};

export type MutationUnarchiveProjectArgs = {
  id: Scalars["ID"];
};

export type MutationCreateActionArgs = {
  input: CreateActionInput;
};

export type MutationUpdateActionArgs = {
  input: UpdateActionInput;
};

export type MutationReassignActionArgs = {
  id: Scalars["ID"];
  assigneeAilorn: Scalars["AiloRN"];
};

export type MutationUpdateActionDueDateArgs = {
  id: Scalars["ID"];
  dueDate: Scalars["DateTime"];
};

export type MutationCompleteActionArgs = {
  id: Scalars["ID"];
};

export type MutationUncompleteActionArgs = {
  id: Scalars["ID"];
};

export type MutationAddProjectFilesArgs = {
  projectId: Scalars["ID"];
  fileAilorns: Array<Scalars["AiloRN"]>;
};

export type MutationRemoveProjectFilesArgs = {
  projectFileIds: Array<Scalars["ID"]>;
};

export type MutationCompleteAssignTenancyAgreementActionArgs = {
  id: Scalars["ID"];
  input: CompleteAssignTenancyAgreementActionInput;
};

export type MutationCompleteAssignRentActionArgs = {
  id: Scalars["ID"];
  input: CompleteAssignRentActionInput;
};

export type MutationCompleteAssignTenancyActionArgs = {
  input: CompleteAssignTenancyActionInput;
};

export type MutationCompleteAssignNewManagementActionArgs = {
  input: CompleteAssignNewManagementActionInput;
};

export type MutationCompleteAssignFormActionArgs = {
  input: CompleteAssignFormActionInput;
};

export type MutationLockTrustAccountArgs = {
  input: LockTrustAccountInput;
};

export type MutationPayFromWalletArgs = {
  payFromWalletInput: PayFromWalletInput;
};

export type MutationReceiptFundsArgs = {
  receiptFundsInput: ReceiptFundsInput;
};

export type MutationWalletTransferArgs = {
  walletTransferInput: WalletTransferInput;
};

export type MutationCreateRecurringOwingArgs = {
  owing: RecurringOwingInput;
};

export type MutationUpdateRecurringOwingArgs = {
  owing: RecurringOwingCalculationInput;
  owingId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationCreateOrUpdateUserPreferenceArgs = {
  preference: UserNotificationPreferenceInput;
};

export type MutationCreateOrUpdateUserPreferenceV2Args = {
  preference: UserNotificationPreferenceInputV2;
};

export type MutationSendCentrepayOnboardingHelpLinkArgs = {
  agencyAilorn: Scalars["AiloRN"];
};

export type MutationSendCentrepayAccountSetupInstructionsArgs = {
  agencyAilorn: Scalars["AiloRN"];
};

export type MutationCreateExternalTrustAccountArgs = {
  details: CreateExternalTrustAccountInput;
};

export type MutationDisableExternalTrustAccountArgs = {
  details: DisableExternalTrustAccountInput;
};

export type MutationEnableExternalTrustAccountArgs = {
  details: EnableExternalTrustAccountInput;
};

export type MutationUploadMigrationCsvArgs = {
  input: UploadMigrationCsvInput;
};

export type MutationUpdateMigratingManagementArgs = {
  input: UpdateMigratingManagementInput;
};

export type MutationRemoveTenancyFromMigratingManagementArgs = {
  input: RemoveTenancyFromMigratingManagementInput;
};

export type MutationApproveMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationUnapproveMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationMigrateManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationPublishMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationStartMigrationArgs = {
  legalEntityId: Scalars["AiloRN"];
};

export type MutationRevokeMailboxAccessArgs = {
  input: RevokeMailboxAccessInput;
};

export type MutationSendMailArgs = {
  input: SendMailInput;
};

export type MutationGetMailboxAuthorizationUrlArgs = {
  input: GetMailboxAuthorizationUrlInput;
};

export type MutationAddBPayArgs = {
  details: BPayInput;
  owner: Scalars["AiloRN"];
};

export type MutationAddBankAccountArgs = {
  details: BankAccountInput;
  owner: Scalars["AiloRN"];
};

export type MutationAddCreditCardArgs = {
  details: CreditCardInput;
  owner: Scalars["AiloRN"];
};

export type MutationAdjustLiabilityEntryArgs = {
  adjustmentType: Scalars["String"];
  amount: MoneyInput;
  description: Scalars["String"];
  descriptionIsCustom?: Maybe<Scalars["Boolean"]>;
  effectiveAt: Scalars["DateTime"];
  idempotencyKey: Scalars["String"];
  liabilityId: Scalars["ID"];
};

export type MutationArchiveLiabilityArgs = {
  input: ArchiveLiabilityInput;
};

export type MutationCancelAutoPayArgs = {
  cancelAutoPayInput: CancelAutoPayInput;
};

export type MutationCancelAutoPayLiabilityStatusArgs = {
  cancelAutoPayLiabilityStatusInput: CancelAutoPayLiabilityStatusInput;
};

export type MutationCancelAutoWithdrawPlanArgs = {
  cancelAutoWithdrawInput: CancelAutoWithdrawPlanInput;
};

export type MutationCancelLiabilityPaymentPlanArgs = {
  cancelLiabilityPaymentPlanInput: CancelLiabilityPaymentPlanInput;
};

export type MutationCleanOrphanedBusinessTransactionArgs = {
  businessTransactionId: Scalars["ID"];
};

export type MutationCleanUnclearedBusinessTransactionArgs = {
  businessTransactionId: Scalars["ID"];
  description: Scalars["String"];
};

export type MutationCreateAdjustmentForPayerArgs = {
  businessTxId: Scalars["ID"];
  payerReference: Scalars["AiloRN"];
};

export type MutationCreateAutoWithdrawPlanV2Args = {
  createAutoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2;
};

export type MutationCreateBankAccountBlacklistArgs = {
  blackListItem: BankAccountBlacklistInput;
};

export type MutationCreateLiabilityPaymentPlanArgs = {
  createLiabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput;
};

export type MutationCreateOrUpdatePaymentMethodAiloFeeWaiverArgs = {
  feeWaiverInput: PaymentMethodAiloFeeWaiverInput;
};

export type MutationCreateOverdraftAllowanceArgs = {
  input: OverdraftAllowanceInput;
};

export type MutationCreatePaymentMethodArgs = {
  input?: Maybe<CreatePaymentMethodInput>;
};

export type MutationCreateUpdateAutoPayArgs = {
  createUpdateAutoPayInput: CreateUpdateAutoPayInput;
};

export type MutationCreditFromAiloBankArgs = {
  walletCreditInput: AdjustWalletBalanceInput;
};

export type MutationDeleteBankAccountBlacklistArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePaymentMethodArgs = {
  disableAutoPlans?: Maybe<Scalars["Boolean"]>;
  paymentMethodId: Scalars["ID"];
};

export type MutationEmitLiabilityStateChangedEventArgs = {
  liabilityId: Scalars["ID"];
};

export type MutationGenerateLiabilityEntriesArgs = {
  input: GenerateLiabilityEntriesInput;
};

export type MutationGetOrCreateWalletOwnerAndBankAccountArgs = {
  input: GetOrCreateWalletOwnerAndBankAccountInput;
};

export type MutationPartialRefundLiabilityPaymentArgs = {
  amount: MoneyInput;
  businessTxId: Scalars["AiloRN"];
  cascade?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  destinationWalletId?: Maybe<Scalars["String"]>;
};

export type MutationPayLiabilityArgs = {
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type MutationPayLiabilityFromWalletArgs = {
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liability: Scalars["AiloRN"];
  wallet: Scalars["AiloRN"];
};

export type MutationRefundLiabilityPaymentArgs = {
  businessTxId: Scalars["AiloRN"];
  cascade?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
};

export type MutationReverseAdjustmentArgs = {
  input: ReverseAdjustmentInput;
};

export type MutationReverseBusinessTxArgs = {
  businessTxId: Scalars["ID"];
};

export type MutationTransferToWalletArgs = {
  transferToWalletInput: TransferToWalletInput;
};

export type MutationUpdateAutoWithdrawPlanV2Args = {
  updateAutoWithdrawInput: UpdateAutoWithdrawPlanInputV2;
};

export type MutationUpdateLiabilityPaymentPlanArgs = {
  updateLiabilityPaymentPlanInput: UpdateLiabilityPaymentPlanInput;
};

export type MutationUpsertAutoPayLiabilityStatusArgs = {
  upsertAutoPayLiabilityStatusInput: UpsertAutoPayLiabilityStatusInput;
};

export type MutationWithdrawFromWalletArgs = {
  withdrawInput: WithdrawInput;
};

export type MutationWriteOffArgs = {
  walletCreditInput: AdjustWalletBalanceInput;
};

export type MutationCreateOneKeyArgs = {
  input: CreateOneKeyInput;
};

export type MutationUpdateOneKeyArgs = {
  input: UpdateOneKeyInput;
};

export type MutationDeleteOneKeyArgs = {
  input: DeleteOneKeyInput;
};

export type MutationCreateInspectionArgs = {
  createInspectionInput: CreateInspectionInput;
};

export type MutationUpdateInspectionArgs = {
  updateInspectionInput: UpdateInspectionInput;
};

export type MutationRemoveInspectionArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAreaArgs = {
  createInspectionAreaInput: CreateInspectionAreaInput;
};

export type MutationUpdateInspectionAreaArgs = {
  updateInspectionAreaInput: UpdateInspectionAreaInput;
};

export type MutationRemoveInspectionAreaArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAreaFileArgs = {
  createInspectionAreaFileInput: CreateInspectionAreaFileInput;
};

export type MutationRemoveInspectionAreaFileArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionFeatureArgs = {
  createInspectionFeatureInput: CreateInspectionFeatureInput;
};

export type MutationUpdateInspectionFeatureArgs = {
  updateInspectionFeatureInput: UpdateInspectionFeatureInput;
};

export type MutationRemoveInspectionFeatureArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionFeatureFileArgs = {
  createInspectionFeatureFileInput: CreateInspectionFeatureFileInput;
};

export type MutationRemoveInspectionFeatureFileArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAppointmentsArgs = {
  input: Array<CreateInspectionAppointmentInput>;
  agencyAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MutationUpdateInspectionAppointmentArgs = {
  input: UpdateInspectionAppointmentInput;
};

export type MutationCancelInspectionAppointmentArgs = {
  id: Scalars["ID"];
};

export type MutationSetRoutineInspectionDueDateArgs = {
  input: SetRoutineInspectionDueDateInput;
};

export type MutationGenerateInspectionReportArgs = {
  generateInspectionReportInput: GenerateInspectionReportInput;
};

export type MutationCreateBananaArgs = {
  input: CreateBananaInput;
};

export type MutationCreateReiTokenArgs = {
  createReiTokenInput: CreateReiTokenInput;
};

export type MutationUpdateReiTokenArgs = {
  updateReiTokenInput: UpdateReiTokenInput;
};

export type MutationRemoveReiTokenArgs = {
  removeReiTokenInput: RemoveReiTokenInput;
};

export type MutationCreateReiFormFromUserTemplateArgs = {
  createReiFormFromUserTemplateInput: CreateReiFormFromUserTemplateInput;
};

export type MutationCreateAiloFormArgs = {
  createAiloFormInput: CreateAiloFormInput;
};

export type MutationUpdateAiloFormArgs = {
  updateAiloFormInput: UpdateAiloFormInput;
};

export type MutationStartFileUploadArgs = {
  fileName: Scalars["String"];
  fileKind?: Maybe<FileKind>;
  ownerId?: Maybe<Scalars["AiloRN"]>;
};

export type MutationValidateFileArgs = {
  fileId: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
};

export type MutationClaimFilesArgs = {
  filesIds: Array<Scalars["AiloRN"]>;
  fileKind?: Maybe<FileKind>;
  ownerId: Scalars["AiloRN"];
  claimerId: Scalars["AiloRN"];
  permittedReadEntitiesIds?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type MutationUnclaimFilesArgs = {
  filesIds: Array<Scalars["AiloRN"]>;
  claimerId: Scalars["AiloRN"];
};

export type MutationCreateFileShareLinksArgs = {
  fileIds: Array<Scalars["AiloRN"]>;
  metadata: FileShareLinkMetadataInput;
  shareUntil?: Maybe<Scalars["DateTime"]>;
};

export type MutationDeleteFileShareLinksArgs = {
  ids: Array<Scalars["ID"]>;
};

export type MutationCreateZipFileArgs = {
  fileIds: Array<Scalars["AiloRN"]>;
  fileName: Scalars["String"];
  ownerId?: Maybe<Scalars["AiloRN"]>;
};

export type MutationSubscribeCustomerArgs = {
  subscription?: Maybe<PlatformSubscriptionInput>;
};

export type MutationSetSubscriptionEndDateArgs = {
  subscription?: Maybe<SubscriptionEndDateInput>;
};

export type MutationCreateOtherContactArgs = {
  input: CreateOtherContactInput;
};

export type MutationFindOrCreateOtherContactsArgs = {
  input: FindOrCreateOtherContactsInput;
};

export type MutationSyncLegalEntityArgs = {
  input: SyncLegalEntityInput;
};

export type MutationCreateChatV2Args = {
  input: CreateChatInputV2;
};

export type MutationCreateThreadV2Args = {
  input: CreateThreadInputV2;
};

export type MutationSendMessageV2Args = {
  input: SendMessageInputV2;
};

export type MutationAcknowledgeThreadArgs = {
  input: AcknowledgeThreadInput;
};

export type MutationUnacknowledgeThreadFromMessageArgs = {
  input: UnacknowledgeThreadFromMessageInput;
};

export type MutationCreateBillArgs = {
  billDetails: BillInput;
};

export type MutationArchiveBillArgs = {
  billId: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
};

export type MutationAddBillAttachmentsArgs = {
  billId: Scalars["AiloRN"];
  attachmentsFileIds: Array<Scalars["AiloRN"]>;
  payersLegalEntitiesIds?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type MutationCreatePaymentReferenceArgs = {
  paymentReferenceDetails: PaymentReferenceInput;
};

export type MutationFindOrCreatePaymentReferenceArgs = {
  paymentReferenceDetails: PaymentReferenceInput;
};

export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};

export type MutationFindOrCreateInternalSupplierArgs = {
  supplierDetails: InternalSupplierInput;
};

export type MutationArchiveSupplierArgs = {
  supplierId: Scalars["ID"];
};

export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};

export type MutationSendEmailVerificationCodeArgs = {
  emailAddress: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  emailAddress: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MutationOnboardUsersArgs = {
  userIds: Array<Scalars["AiloRN"]>;
  sendWelcomeEmail?: Scalars["Boolean"];
};

export type MutationUpdateUserEmailAddressArgs = {
  userId: Scalars["AiloRN"];
  emailAddress: Scalars["String"];
  sendEmailChangeWarningEmail?: Maybe<Scalars["Boolean"]>;
};

export type MutationCompleteOnboardingTaskArgs = {
  onboardingTaskId: OnboardingTaskId;
};

export type MutationUpdatePersonProfileDetailsArgs = {
  input: UpdatePersonProfileDetailsInput;
};

export type MutationCreatePersonArgs = {
  personDetails?: Maybe<PersonServiceInput>;
  userDetails?: Maybe<UserServiceInput>;
};

export type MutationCreateUserForPersonArgs = {
  personId: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
};

export type MutationCreatePeopleArgs = {
  peopleDetails: Array<PersonServiceInput>;
  useExistingPersonOnClashingEmail?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateCompanyProfileDetailsArgs = {
  input: UpdateCompanyProfileDetailsInput;
};

export type MutationCreateCompanyArgs = {
  companyDetails: CompanyServiceInput;
  organisationDetails?: Maybe<OrganisationServiceInput>;
};

export type MutationAddMemberToOrganisationArgs = {
  input: AddMemberToOrganisationInput;
};

export type MutationRemoveMemberFromOrganisationArgs = {
  input: RemoveMemberFromOrganisationInput;
};

export type MutationAddMemberToLegalEntityArgs = {
  input: AddMemberToLegalEntityInput;
};

export type MutationRemoveMemberFromLegalEntityArgs = {
  input: RemoveMemberFromLegalEntityInput;
};

export type MutationUpsertCrnArgs = {
  input: UpsertCrnInput;
};

export type MutationDeleteCrnArgs = {
  input: DeleteCrnInput;
};

export type NewManagementProjectMeta = {
  __typename?: "NewManagementProjectMeta";
  management?: Maybe<Management>;
};

export type NewTenancyProjectMeta = {
  __typename?: "NewTenancyProjectMeta";
  management: Management;
  tenancy?: Maybe<Tenancy>;
};

export enum NotAbleToCancelReason {
  TenancyIsNotVacating = "TenancyIsNotVacating",
  TenancyEndDateIsInThePast = "TenancyEndDateIsInThePast",
  ManagementHasIngoingTenancy = "ManagementHasIngoingTenancy",
  AlreadyChargedForPeriodWithEndDate = "AlreadyChargedForPeriodWithEndDate",
  ManagementIsEnding = "ManagementIsEnding",
}

export type NotificationBody = {
  __typename?: "NotificationBody";
  notificationId: Scalars["AiloRN"];
  notificationType: Scalars["String"];
  channel: Scalars["String"];
  title: Scalars["String"];
  templateName: Scalars["String"];
  templateHash: Scalars["String"];
  deliveryStatus: NotificationDeliveryStatus;
  recipients: Array<Maybe<User>>;
  body: Scalars["String"];
};

export enum NotificationDeliveryStatus {
  Sent = "Sent",
  Delivered = "Delivered",
  Failed = "Failed",
  Skipped = "Skipped",
}

export type NotificationSent = {
  __typename?: "NotificationSent";
  sent: Scalars["Boolean"];
};

export enum OccupancyStatus {
  Vacant = "Vacant",
  Occupied = "Occupied",
  Vacating = "Vacating",
}

export type OfflinePaymentAccount = {
  __typename?: "OfflinePaymentAccount";
  id: Scalars["ID"];
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
};

export type OfflinePaymentProcessingEnabled = {
  __typename?: "OfflinePaymentProcessingEnabled";
  tenancy: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export type OfflinePaymentProcessingEnabledInput = {
  tenancy: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export type OffsetPageInfo = {
  __typename?: "OffsetPageInfo";
  total: Scalars["Int"];
  hasMore: Scalars["Boolean"];
  nextOffset?: Maybe<Scalars["Int"]>;
};

export type OffsetPaginationInputV2 = {
  pageSize: Scalars["Int"];
  offset?: Maybe<Scalars["Int"]>;
};

export type OkResult = {
  __typename?: "OkResult";
  _ok: Scalars["Boolean"];
};

export type OnboardingTask = {
  __typename?: "OnboardingTask";
  id: OnboardingTaskId;
  description: Scalars["String"];
};

export enum OnboardingTaskId {
  CompleteAiloOverview = "CompleteAiloOverview",
  AddPaymentMethod = "AddPaymentMethod",
  VerifyPhoneNumber = "VerifyPhoneNumber",
  AcceptTermsOfService = "AcceptTermsOfService",
  AllowPushNotifications = "AllowPushNotifications",
  SetupAutoRentPayment = "SetupAutoRentPayment",
  GetToKnowAilo = "GetToKnowAilo",
  AcceptAgencyTermsOfService = "AcceptAgencyTermsOfService",
  PayTenancyDeposit = "PayTenancyDeposit",
}

export type Organisation = {
  __typename?: "Organisation";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  name: Scalars["String"];
  orgType: OrganisationType;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  members?: Maybe<PaginatedPeople>;
  legalEntities: Array<LegalEntity>;
  memberships: Array<OrganisationMembership>;
  teams: Array<Team>;
  availableFeatures: Array<PlatformFeature>;
  activeSubscriptions?: Maybe<PaginatedPlatformSubscriptions>;
  pastSubscriptions?: Maybe<PaginatedPlatformSubscriptions>;
  effectiveUserContact?: Maybe<Contact>;
  /** Returns only the external suppliers. */
  suppliers: PaginatedSuppliers;
  /** @deprecated Use `Organisation.suppliers` instead */
  externalSuppliers: PaginatedSuppliers;
};

export type OrganisationMembersArgs = {
  pageCursor?: Maybe<PageCursor>;
};

export type OrganisationActiveSubscriptionsArgs = {
  cursor: PageCursor;
};

export type OrganisationPastSubscriptionsArgs = {
  cursor: PageCursor;
};

export type OrganisationSuppliersArgs = {
  cursor: BillDefaultPageCursor;
};

export type OrganisationExternalSuppliersArgs = {
  cursor: BillDefaultPageCursor;
};

export type OrganisationMembership = {
  __typename?: "OrganisationMembership";
  member: Person;
  organisation: Organisation;
  /** @deprecated Use `OrganisationMembership.member` instead. */
  person: Person;
  role: OrganisationRole;
};

export type OrganisationPersonRole = Role & {
  __typename?: "OrganisationPersonRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
  organisation: Organisation;
};

export type OrganisationRole = Role & {
  __typename?: "OrganisationRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type OrganisationServiceInput = {
  name: Scalars["String"];
  orgType: OrganisationType;
};

export type OrganisationsFilter = {
  orgType?: Maybe<OrganisationType>;
};

export enum OrganisationType {
  Agency = "Agency",
  Generic = "Generic",
}

export enum OutboundMailDeliveryError {
  InvalidToAddress = "InvalidToAddress",
  MailboxFull = "MailboxFull",
  Unknown = "Unknown",
}

export enum OutboundMailDeliveryStatus {
  Sending = "Sending",
  Sent = "Sent",
  Error = "Error",
}

export type OutboundMailDirectionDetails = {
  __typename?: "OutboundMailDirectionDetails";
  direction: MailDirection;
  outboundDeliveryStatus: OutboundMailDeliveryStatus;
  outboundDeliveryStatusUpdatedAt: Scalars["DateTime"];
  outboundDeliveryError?: Maybe<OutboundMailDeliveryError>;
};

export type OverdraftAllowance = {
  __typename?: "OverdraftAllowance";
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  endAt: Scalars["DateTime"];
  id: Scalars["ID"];
  startAt: Scalars["DateTime"];
  type: Scalars["String"];
  walletId: Scalars["ID"];
};

export type OverdraftAllowanceInput = {
  amount: MoneyInput;
  endAt: Scalars["DateTime"];
  startAt: Scalars["DateTime"];
  walletId: Scalars["ID"];
};

export type OwingProgress = {
  __typename?: "OwingProgress";
  /**  arrears representing all not fully paid days up to and including today  */
  arrears: Arrears;
  /**  the earliest not fully paid charge  */
  due?: Maybe<Charge>;
  /**  cumulative charge representing total remaining amount to be paid  */
  endOfOwing?: Maybe<CumulativeCharge>;
  firstPaidAt?: Maybe<Scalars["DateTime"]>;
  lastPaidAt?: Maybe<Scalars["DateTime"]>;
  /**  next (from today) charge regardless of whether it is paid or not  */
  nextCharge?: Maybe<Charge>;
  /**  the next (from today) not fully paid charge  */
  nextDue?: Maybe<Charge>;
  /**  cumulative charge representing all not fully paid cycles that are due up to today  */
  overdue: CumulativeCharge;
  paidTo: PaidTo;
  /**  The total amount of money paid towards this owing. This does not include adjustments */
  totalPaid: Money;
};

export type OwingReferencedEntity = RecurringFee | Tenancy;

export type OwingSummary = {
  __typename?: "OwingSummary";
  chargeCycle: UnpersistedChargeCycle;
  chargeRates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  firstChargeDate: Scalars["Date"];
  frequency: RecurringFrequency;
};

export type OwnerShareInput = {
  ownerId: Scalars["ID"];
  sharesOwned: Scalars["Int"];
};

export type Ownership = {
  __typename?: "Ownership";
  managementId: Scalars["ID"];
  owner?: Maybe<LegalEntity>;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  sharesOwned: Scalars["Int"];
  sharesRatio: Scalars["Float"];
  /**
   * Total shares owned by all ownerships of the management
   * @deprecated Use `Management.sharesRatio` or `Management.sharesOwned` instead.
   */
  totalShares: Scalars["Int"];
  ownerId: Scalars["ID"];
  consumerInvite?: Maybe<ConsumerInvite>;
};

export type OwnershipInput = {
  managementId: Scalars["ID"];
  ownerId: Scalars["ID"];
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  sharesOwned: Scalars["Int"];
};

export type OwnershipSetupInput = {
  ownerId?: Maybe<Scalars["ID"]>;
  sharesOwned: Scalars["Int"];
  consumer?: Maybe<ConsumerSetupInput>;
};

export type PageCursor = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type PageCursorWithoutSort = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  total: Scalars["Int"];
  hasMore: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
};

export type PaginatedAccounts = {
  __typename?: "PaginatedAccounts";
  items: Array<Maybe<Account>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedAccountTagBalances = {
  __typename?: "PaginatedAccountTagBalances";
  items: Array<Maybe<AccountTagBalance>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedActions = {
  __typename?: "PaginatedActions";
  pageInfo: PageInfo;
  items: Array<Action>;
};

export type PaginatedAgencyProperties = {
  __typename?: "PaginatedAgencyProperties";
  pageInfo: BidirectionalPageInfo;
  items: Array<AgencyProperty>;
};

export type PaginatedAgencyPropertySearchItems = {
  __typename?: "PaginatedAgencyPropertySearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<AgencyProperty>;
};

export type PaginatedAutoWithdrawPlans = {
  __typename?: "PaginatedAutoWithdrawPlans";
  items?: Maybe<Array<AutoWithdrawPlan>>;
  pageInfo: PageInfo;
};

export type PaginatedBills = {
  __typename?: "PaginatedBills";
  pageInfo: BidirectionalPageInfo;
  items: Array<Bill>;
};

export type PaginatedChargeCycles = {
  __typename?: "PaginatedChargeCycles";
  items: Array<Maybe<ChargeCycle>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedChatsInputV2 = {
  organisationAilorn: Scalars["AiloRN"];
  pagination: OffsetPaginationInputV2;
  /**
   * All chats returned must have at least one of the following
   * contacts as a participant.
   */
  mustIncludeOneOf?: Maybe<Array<ContactOrEmail>>;
  /**
   * All chats returned must have all of the following contacts
   * as participants.
   */
  mustIncludeAllOf?: Maybe<Array<ContactOrEmail>>;
  /**
   * All chats returned must have all of, and only, the following contacts
   * as participants.
   */
  mustIncludeExactly?: Maybe<Array<ContactOrEmail>>;
};

export type PaginatedChatsV2 = {
  __typename?: "PaginatedChatsV2";
  offsetPageInfo: OffsetPageInfo;
  items: Array<ChatV2>;
};

export type PaginatedCompanies = {
  __typename?: "PaginatedCompanies";
  pageInfo: PageInfo;
  items: Array<Company>;
};

export type PaginatedContacts = {
  __typename?: "PaginatedContacts";
  pageInfo: BidirectionalPageInfo;
  items: Array<Contact>;
};

export type PaginatedContactSearchItems = {
  __typename?: "PaginatedContactSearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<Contact>;
};

export type PaginatedEntries = {
  __typename?: "PaginatedEntries";
  items: Array<Maybe<Entry>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedFeeBlueprints = {
  __typename?: "PaginatedFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<FeeBlueprint>;
};

export type PaginatedFees = {
  __typename?: "PaginatedFees";
  pageInfo: PageInfo;
  items: Array<Fee>;
};

export type PaginatedFiles = {
  __typename?: "PaginatedFiles";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<File>>;
};

export type PaginatedLegalEntities = {
  __typename?: "PaginatedLegalEntities";
  pageInfo: PageInfo;
  items: Array<LegalEntity>;
};

export type PaginatedLegalEntityWithExternalTrustAccounts = {
  __typename?: "PaginatedLegalEntityWithExternalTrustAccounts";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<LegalEntityWithExternalTrustAccount>>;
};

export type PaginatedLiabilityEntries = {
  __typename?: "PaginatedLiabilityEntries";
  items: Array<LiabilityEntry>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedLiabilityPaymentPlans = {
  __typename?: "PaginatedLiabilityPaymentPlans";
  items?: Maybe<Array<LiabilityPaymentPlan>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedLineItems = {
  __typename?: "PaginatedLineItems";
  pageInfo: PageInfo;
  items: Array<LineItem>;
};

export type PaginatedManagementAgreements = {
  __typename?: "PaginatedManagementAgreements";
  pageInfo: PageInfo;
  items: Array<ManagementAgreement>;
};

export type PaginatedManagementFeeBlueprints = {
  __typename?: "PaginatedManagementFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<ManagementFeeBlueprint>;
};

export type PaginatedManagementFeeSchedules = {
  __typename?: "PaginatedManagementFeeSchedules";
  pageInfo: PageInfo;
  items: Array<Maybe<ManagementFeeSchedule>>;
};

export type PaginatedManagementFolioOwnerships = {
  __typename?: "PaginatedManagementFolioOwnerships";
  pageInfo: PageInfo;
  items: Array<ManagementFolioOwnership>;
};

export type PaginatedManagementFolios = {
  __typename?: "PaginatedManagementFolios";
  pageInfo: PageInfo;
  items: Array<ManagementFolio>;
};

export type PaginatedManagementFolioToManagements = {
  __typename?: "PaginatedManagementFolioToManagements";
  pageInfo: PageInfo;
  items: Array<ManagementFolioToManagement>;
};

export type PaginatedManagements = {
  __typename?: "PaginatedManagements";
  pageInfo: PageInfo;
  items: Array<Management>;
};

export type PaginatedMessagesV2 = {
  __typename?: "PaginatedMessagesV2";
  pageInfo: PageInfo;
  items: Array<Maybe<MessageV2>>;
};

export type PaginatedMigratingManagements = {
  __typename?: "PaginatedMigratingManagements";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<MigratingManagement>>;
};

export type PaginatedOrganisations = {
  __typename?: "PaginatedOrganisations";
  pageInfo: PageInfo;
  items: Array<Maybe<Organisation>>;
};

export type PaginatedOwnerships = {
  __typename?: "PaginatedOwnerships";
  pageInfo: PageInfo;
  items: Array<Ownership>;
};

export type PaginatedPaymentMethods = {
  __typename?: "PaginatedPaymentMethods";
  items: Array<Maybe<PaymentMethod>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedPaymentReferences = {
  __typename?: "PaginatedPaymentReferences";
  pageInfo: PageInfo;
  items: Array<Maybe<PaymentReference>>;
};

export type PaginatedPeople = {
  __typename?: "PaginatedPeople";
  pageInfo: PageInfo;
  items: Array<Person>;
};

export type PaginatedPlatformSubscriptions = {
  __typename?: "PaginatedPlatformSubscriptions";
  pageInfo: PageInfo;
  items: Array<PlatformSubscription>;
};

export type PaginatedProjectFiles = {
  __typename?: "PaginatedProjectFiles";
  pageInfo: PageInfo;
  items: Array<ProjectFile>;
};

export type PaginatedProjects = {
  __typename?: "PaginatedProjects";
  pageInfo: BidirectionalPageInfo;
  items: Array<Project>;
};

export type PaginatedProperties = {
  __typename?: "PaginatedProperties";
  pageInfo: PageInfo;
  items: Array<Property>;
};

export type PaginatedReconciliationDetail = {
  __typename?: "PaginatedReconciliationDetail";
  items: Array<ReconciliationDetailReportItem>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedRecurringFeeBlueprints = {
  __typename?: "PaginatedRecurringFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<RecurringFeeBlueprint>;
};

export type PaginatedRecurringOwingEvents = {
  __typename?: "PaginatedRecurringOwingEvents";
  items: Array<Maybe<RecurringOwingEvent>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedRents = {
  __typename?: "PaginatedRents";
  pageInfo: PageInfo;
  items: Array<Rent>;
};

export type PaginatedStatementProgresses = {
  __typename?: "PaginatedStatementProgresses";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<StatementProgress>>;
};

export type PaginatedStatements = {
  __typename?: "PaginatedStatements";
  pageInfo: BidirectionalPageInfo;
  items: Array<Statement>;
};

export type PaginatedStatementTransactionLineItem = {
  __typename?: "PaginatedStatementTransactionLineItem";
  items: Array<StatementTransactionLineItem>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedSuppliers = {
  __typename?: "PaginatedSuppliers";
  pageInfo: PageInfo;
  items: Array<Supplier>;
};

export type PaginatedTenancies = {
  __typename?: "PaginatedTenancies";
  pageInfo: PageInfo;
  items: Array<Tenancy>;
};

export type PaginatedTenancyAgreements = {
  __typename?: "PaginatedTenancyAgreements";
  pageInfo: PageInfo;
  items: Array<Maybe<TenancyAgreement>>;
};

export type PaginatedTenancySearchResultItems = {
  __typename?: "PaginatedTenancySearchResultItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<TenancySearchResult>;
};

export type PaginatedThreadsV2 = {
  __typename?: "PaginatedThreadsV2";
  pageInfo: PageInfo;
  items: Array<Maybe<ThreadV2>>;
};

export type PaginatedTransactionReport = {
  __typename?: "PaginatedTransactionReport";
  items?: Maybe<Array<Maybe<TransactionReportItem>>>;
  pageInfo?: Maybe<LedgerBidirectionalPageInfo>;
};

export type PaginatedTransactions = {
  __typename?: "PaginatedTransactions";
  items: Array<Maybe<Transaction>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedTrustAccountWalletSearchItems = {
  __typename?: "PaginatedTrustAccountWalletSearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<TrustAccountWallet>;
};

export type PaginatedUserEmailVerifications = {
  __typename?: "PaginatedUserEmailVerifications";
  pageInfo: PageInfo;
  items: Array<UserEmailVerification>;
};

export type PaginatedUsers = {
  __typename?: "PaginatedUsers";
  pageInfo: PageInfo;
  items: Array<Maybe<User>>;
};

export type PaginatedWalletEntriesWithBtStatus = {
  __typename?: "PaginatedWalletEntriesWithBtStatus";
  items: Array<WalletEntry>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginationInputV2 = {
  pageSize: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
};

export type PaginationParams = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
};

export type PaginationParamsWithPageNumber = {
  cursor?: Maybe<Scalars["String"]>;
  /** Current page number (positive value) */
  page?: Maybe<Scalars["Int"]>;
  /** Number of items per page */
  pageSize?: Maybe<Scalars["Int"]>;
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
};

export type PaidTo = {
  __typename?: "PaidTo";
  classic: PaidToDate;
  effective: PaidToDate;
};

export type PaidToDate = {
  __typename?: "PaidToDate";
  date: Scalars["Date"];
  partPayment: Money;
};

export type PaidToDateDetails = {
  __typename?: "PaidToDateDetails";
  amountInArrearsBeforeEntry: Money;
  daysInArrearsBeforeEntry: Scalars["Int"];
  effectivePaidToDate: Scalars["Date"];
  effectivePaidToDateBeforeEntry: Scalars["Date"];
  effectivePaidToDateCredit: Money;
  paidToDate: Scalars["Date"];
  paidToDateBeforeEntry: Scalars["Date"];
  paidToDateCredit: Money;
};

export enum PaidUsingType {
  AiloWallet = "AILO_WALLET",
  EftIntoTrustAccount = "EFT_INTO_TRUST_ACCOUNT",
  PaymentMethod = "PAYMENT_METHOD",
}

export type PayFromWalletInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  idempotencyKey: Scalars["String"];
  purposeId: Scalars["ID"];
  walletOwner: Scalars["AiloRN"];
};

export type PaymentAllocationInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  purposeId: Scalars["ID"];
};

export type PaymentLiabilityEntry = LiabilityEntry & {
  __typename?: "PaymentLiabilityEntry";
  amount: Money;
  businessTransaction: BusinessTransaction;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** @deprecated Use status in PaymentLiabilityEntry businessTransaction */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type PaymentMethod = {
  ailoRN: Scalars["AiloRN"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type PaymentMethodAiloFeeFilterEntryInput = {
  active: Scalars["Boolean"];
  endDate?: Maybe<Scalars["Date"]>;
  paymentMethodType: Scalars["String"];
  startDate?: Maybe<Scalars["Date"]>;
};

export type PaymentMethodAiloFees = {
  __typename?: "PaymentMethodAiloFees";
  appliesToId?: Maybe<Scalars["String"]>;
  feeBasisPoints?: Maybe<Scalars["Int"]>;
  feeFlatCents?: Maybe<Scalars["Int"]>;
  feeName?: Maybe<Scalars["String"]>;
  filterEntryEffectiveFromInclusive?: Maybe<Scalars["Date"]>;
  filterEntryEffectiveToInclusive?: Maybe<Scalars["Date"]>;
  filterName?: Maybe<Scalars["String"]>;
  filterNote?: Maybe<Scalars["String"]>;
  filterType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  methodCategory?: Maybe<Scalars["String"]>;
  methodType: Scalars["String"];
};

export type PaymentMethodAiloFeeWaiverInput = {
  filterEntries?: Maybe<Array<Maybe<PaymentMethodAiloFeeFilterEntryInput>>>;
  notes?: Maybe<Scalars["String"]>;
  ownerLegalEntity: Scalars["AiloRN"];
};

export type PaymentMethodCompanion = {
  __typename?: "PaymentMethodCompanion";
  id: Scalars["AiloRN"];
  paymentMethod: PaymentMethod;
};

export type PaymentMethodDestination = {
  __typename?: "PaymentMethodDestination";
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars["ID"];
  share: Scalars["Int"];
};

export type PaymentMethodDestinationInput = {
  paymentMethodId: Scalars["ID"];
  share: Scalars["Int"];
};

export type PaymentMethodSearchOptions = {
  externalId?: Maybe<Scalars["String"]>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  includeHidden?: Maybe<Scalars["Boolean"]>;
  includeOnceOff?: Maybe<Scalars["Boolean"]>;
  ownerAiloRN?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<PaymentMethodType>;
};

export enum PaymentMethodType {
  BPay = "BPay",
  BankAccount = "BankAccount",
  CreditCard = "CreditCard",
  VirtualAccount = "VirtualAccount",
}

export type PaymentReference = {
  __typename?: "PaymentReference";
  id: Scalars["ID"];
  supplier: Supplier;
  /**
   * Always present if supplier is external, otherwise empty.
   * @deprecated Use `PaymentReference.supplierPaymentMethodCompanion.ailoRN` instead.
   */
  supplierPaymentMethodReference?: Maybe<Scalars["AiloRN"]>;
  /**
   * Only present if it's an external supplier.
   * If it's an internal supplier, the bill will be paid to supplier's Ailo wallet.
   */
  supplierPaymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
  crn?: Maybe<Scalars["String"]>;
  paymentDescription?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
};

export type PaymentReferenceInput = {
  supplierId: Scalars["ID"];
  /** Required if supplier is external. */
  supplierPaymentMethodReference?: Maybe<Scalars["AiloRN"]>;
  crn?: Maybe<Scalars["String"]>;
  paymentDescription?: Maybe<Scalars["String"]>;
};

export type PaymentReferencesQueryConditions = {
  /** @deprecated Use `PaymentReferencesQueryConditions.payeeId` instead */
  supplierId?: Maybe<Scalars["ID"]>;
  payeeId?: Maybe<Scalars["AiloRN"]>;
  billPayerId?: Maybe<Scalars["AiloRN"]>;
};

export type Permission = {
  __typename?: "Permission";
  id: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type Person = LegalEntity & {
  __typename?: "Person";
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  organisation: Organisation;
  timezone: Scalars["TimeZone"];
  emailAddress?: Maybe<Scalars["String"]>;
  /**
   * Future email address that is currently in pending verification status.
   * Will be set to null once verification succeeds or expires.
   */
  pendingEmailAddress?: Maybe<Scalars["String"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  /** Equal to `preferredName` if given, otherwise `legalFirstName`. */
  firstName: Scalars["String"];
  legalFirstName: Scalars["String"];
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  photo?: Maybe<File>;
  /**
   * Has KYC (Know Your Customer) verification check has been done for this person?
   * It is required for them to be eligible to transfer money out of Ailo platform.
   */
  kycVerified: Scalars["Boolean"];
  user?: Maybe<User>;
  occupation?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  taxFileNumber?: Maybe<Scalars["String"]>;
  signUpCompleted?: Maybe<Scalars["Boolean"]>;
  currentUserPermissions: Array<Scalars["String"]>;
  metadata?: Maybe<Scalars["String"]>;
  phoneNoVerifiedAt?: Maybe<Scalars["DateTime"]>;
  companiesOwned?: Maybe<PaginatedCompanies>;
  organisationMemberships: Array<OrganisationMembership>;
  /** Legal Entity Memberships in which this person is a member. */
  legalEntityMembershipsAsMember: Array<LegalEntityMembership>;
  /** @deprecated Use `Person.organisationMemberships`. */
  organisationRoles: Array<OrganisationPersonRole>;
  /** @deprecated Use `Person.legalEntityMembershipsAsMember`. */
  legalEntityRoles: Array<LegalEntityPersonRole>;
  /** Legal Entity Memberships in which this legal entity is the group of which the other people are members. */
  legalEntityMembershipsAsLegalEntity: Array<LegalEntityMembership>;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  team?: Maybe<Team>;
  /** this can go when payment gateway splits */
  allWalletsAvailableBalance: Money;
  allWalletsTotalBalance: Money;
  kycVerifiedAccordingToLedgerService?: Maybe<Scalars["Boolean"]>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  wallets: Array<Wallet>;
  reiTokensForOrg: Array<ReiToken>;
  reiTemplatesForOrg: Array<ReiTemplate>;
  contact?: Maybe<Contact>;
};

export type PersonCompaniesOwnedArgs = {
  pageCursor?: Maybe<PageCursor>;
  filters?: Maybe<CompanyFilters>;
};

export type PersonStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type PersonTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type PersonManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type PersonTeamArgs = {
  organisationId: Scalars["ID"];
};

export type PersonPaymentMethodsArgs = {
  methodType?: Maybe<PaymentMethodType>;
};

export type PersonReiTokensForOrgArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonReiTemplatesForOrgArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonServiceInput = {
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  phoneNoVerifiedAt?: Maybe<Scalars["DateTime"]>;
  occupation?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  taxFileNumber?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  pmeContactId?: Maybe<Scalars["String"]>;
  pmeContactPersonId?: Maybe<Scalars["String"]>;
  companiesOwned?: Maybe<Array<CreatePersonCompanyInput>>;
};

export type Plan = {
  __typename?: "Plan";
  amount: Money;
  /** @deprecated No longer needed. PM service also got rid of it. */
  endDate?: Maybe<Scalars["DateTime"]>;
  frequency: QuartzPlanFrequency;
  isExisting?: Maybe<Scalars["Boolean"]>;
  startDate: Scalars["DateTime"];
};

export type PlanBasedLiabilityEntry = LiabilityEntry & {
  __typename?: "PlanBasedLiabilityEntry";
  amount: Money;
  /** @deprecated Use `paidToDate` instead */
  classicPaidTo: Scalars["Date"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  cycleEndDate: Scalars["Date"];
  cycleStartDate: Scalars["Date"];
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  frequency?: Maybe<LiabilityPlanFrequency>;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** The date the liability has been paid to after this entry took place. */
  paidToDate: Scalars["Date"];
  /** @deprecated Irrelevant to plan based liability entry */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type PlatformFeature = {
  __typename?: "PlatformFeature";
  id: PlatformFeatureId;
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
};

export enum PlatformFeatureId {
  AgencyAccess = "AgencyAccess",
  AgencyAppPropertyBills = "AgencyAppPropertyBills",
  AgencyAppPropertyFees = "AgencyAppPropertyFees",
  AgencyAppSupplierCreate = "AgencyAppSupplierCreate",
  AgencyAppViewConsumersMoneySettings = "AgencyAppViewConsumersMoneySettings",
  AgencyIncomeTotals = "AgencyIncomeTotals",
  AgencyInternalSuppliers = "AgencyInternalSuppliers",
  AgencyTransferStatement = "AgencyTransferStatement",
  AgencyWalletManualWithdrawal = "AgencyWalletManualWithdrawal",
  AllowFeatureOverride = "AllowFeatureOverride",
  AnalyticsDashboard = "AnalyticsDashboard",
  ApplyNewFee = "ApplyNewFee",
  ArchiveBillAsAgent = "ArchiveBillAsAgent",
  ArrearsListFromOwing = "ArrearsListFromOwing",
  AuthorizedHelpUrl = "AuthorizedHelpUrl",
  AutoPayBillsAsInvestor = "AutoPayBillsAsInvestor",
  AutoPayRent = "AutoPayRent",
  AutoWalletWithdraw = "AutoWalletWithdraw",
  AutoWithdrawPlan = "AutoWithdrawPlan",
  BillAttachments = "BillAttachments",
  BillManagementFee = "BillManagementFee",
  Bills = "Bills",
  BondsReport = "BondsReport",
  BypassRentLimit = "BypassRentLimit",
  CancelRecurringFee = "CancelRecurringFee",
  Centrepay = "Centrepay",
  Chat = "Chat",
  ChatDetailsWeb = "ChatDetailsWeb",
  ConsumerAccess = "ConsumerAccess",
  ConsumerAppKycCheck = "ConsumerAppKycCheck",
  ConsumerAppUserEmailChange = "ConsumerAppUserEmailChange",
  ConsumerProfileProperties = "ConsumerProfileProperties",
  ContactsTab = "ContactsTab",
  ContactsTabCustomers = "ContactsTabCustomers",
  ContactsTabOtherContacts = "ContactsTabOtherContacts",
  CreateAndViewPropertyKeys = "CreateAndViewPropertyKeys",
  CreateGeneralProject = "CreateGeneralProject",
  CreateLeaseRenewalProject = "CreateLeaseRenewalProject",
  CreateNewManagementProject = "CreateNewManagementProject",
  CreateNewTenancyProject = "CreateNewTenancyProject",
  CreateRoutineInspectionProject = "CreateRoutineInspectionProject",
  CreateVacatingTenancyProject = "CreateVacatingTenancyProject",
  CubejsReporting = "CubejsReporting",
  Customers = "Customers",
  DeletePropertyKeys = "DeletePropertyKeys",
  Deposits = "Deposits",
  Disbursements = "Disbursements",
  DisplayFormsTab = "DisplayFormsTab",
  EditApplyNewFee = "EditApplyNewFee",
  EditManagementEnd = "EditManagementEnd",
  EditManagementFee = "EditManagementFee",
  EditPropertyKeys = "EditPropertyKeys",
  EditRecurringFee = "EditRecurringFee",
  EditTenancyBondClaim = "EditTenancyBondClaim",
  EnableBillAutoPayLimit = "EnableBillAutoPayLimit",
  EndManagement = "EndManagement",
  EventBasedFee = "EventBasedFee",
  ExpensesCard = "ExpensesCard",
  FeeBlueprints = "FeeBlueprints",
  FeeFreeRent = "FeeFreeRent",
  FileUpload = "FileUpload",
  FilterBillByTeam = "FilterBillByTeam",
  ForceFileDownloadFlag = "ForceFileDownloadFlag",
  InAppReview = "InAppReview",
  InactiveChatEnhancements = "InactiveChatEnhancements",
  InvestorAccess = "InvestorAccess",
  InvestorFormerTenanciesCard = "InvestorFormerTenanciesCard",
  InvestorInternalSuppliers = "InvestorInternalSuppliers",
  InvestorPropertyWallet = "InvestorPropertyWallet",
  InvestorRentTab = "InvestorRentTab",
  LedgerRemake = "LedgerRemake",
  LiabilityPaymentPlan = "LiabilityPaymentPlan",
  LpmaAccess = "LpmaAccess",
  MailboxLinking = "MailboxLinking",
  ManageExpenseForm = "ManageExpenseForm",
  ManagementFeeBlueprint = "ManagementFeeBlueprint",
  ManagementPortfolios = "ManagementPortfolios",
  MetabaseReporting = "MetabaseReporting",
  MobileTeamChatsFilter = "MobileTeamChatsFilter",
  MoveManagementToFolio = "MoveManagementToFolio",
  NewConsumerAppNav = "NewConsumerAppNav",
  NewLostManagementsList = "NewLostManagementsList",
  NotificationPriming = "NotificationPriming",
  Onboarding = "Onboarding",
  OnboardingTabInPmApp = "OnboardingTabInPMApp",
  OnboardingTabPublishProperty = "OnboardingTabPublishProperty",
  OnboardingTabRefresh = "OnboardingTabRefresh",
  OneOffFeeBlueprints = "OneOffFeeBlueprints",
  OverdueListFromOwing = "OverdueListFromOwing",
  OwnershipStatementBillAndFeeDescription = "OwnershipStatementBillAndFeeDescription",
  OwnershipStatementInclBeforeGst = "OwnershipStatementInclBeforeGst",
  OwnershipStatementShowNextRentScheduleForNewProperties = "OwnershipStatementShowNextRentScheduleForNewProperties",
  PmJoinThread = "PmJoinThread",
  Projects = "Projects",
  Properties = "Properties",
  PropertyIssuesMissingFees = "PropertyIssuesMissingFees",
  PropertySearchFromSearchService = "PropertySearchFromSearchService",
  Receipting = "Receipting",
  RemoveManagementEnd = "RemoveManagementEnd",
  ReportingModalFilter = "ReportingModalFilter",
  ReportingTeamFilter = "ReportingTeamFilter",
  RevenueReport = "RevenueReport",
  SplitJointOwnershipStatement = "SplitJointOwnershipStatement",
  StatementsAddedToManagementFiles = "StatementsAddedToManagementFiles",
  TenancyBondClaim = "TenancyBondClaim",
  TenancyBonds = "TenancyBonds",
  TenancyDetailsDragAndDrop = "TenancyDetailsDragAndDrop",
  TenancyLedgerArrearsInHeader = "TenancyLedgerArrearsInHeader",
  TenancyLedgerAvoidOverlappingRentPeriod = "TenancyLedgerAvoidOverlappingRentPeriod",
  TenancyLedgerHeadersUseLedger = "TenancyLedgerHeadersUseLedger",
  TenancyLedgerLineItemsUseLedger = "TenancyLedgerLineItemsUseLedger",
  TenancyLedgerStatementDownload = "TenancyLedgerStatementDownload",
  TenantAccess = "TenantAccess",
  TransactionFees = "TransactionFees",
  TransferBillsDueWarning = "TransferBillsDueWarning",
  TransferDeposits = "TransferDeposits",
  TransferSummaryStatement = "TransferSummaryStatement",
  TruncateProjectList = "TruncateProjectList",
  TrustAccounting = "TrustAccounting",
  TrustReconciliation = "TrustReconciliation",
  ViewAgencyWallet = "ViewAgencyWallet",
  ViewAgencyWalletTransactions = "ViewAgencyWalletTransactions",
  ViewBillAsAgent = "ViewBillAsAgent",
  VoidIngoingTenancy = "VoidIngoingTenancy",
  WalletAccess = "WalletAccess",
  WalletStatementUserFacingDescription = "WalletStatementUserFacingDescription",
  WelcomeMessageParticipants = "WelcomeMessageParticipants",
  ZendeskWidget = "ZendeskWidget",
  ZendeskWidgetRestrictedForms = "ZendeskWidgetRestrictedForms",
}

export type PlatformPlan = {
  __typename?: "PlatformPlan";
  id: Scalars["String"];
  price: Money;
  product: PlatformProduct;
  features: Array<PlatformFeature>;
};

export type PlatformProduct = {
  __typename?: "PlatformProduct";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type PlatformSubscription = {
  __typename?: "PlatformSubscription";
  id: Scalars["ID"];
  plan: PlatformPlan;
  startsOn: Scalars["Date"];
  endsOn?: Maybe<Scalars["Date"]>;
  customer?: Maybe<Organisation>;
};

export type PlatformSubscriptionInput = {
  customerId: Scalars["ID"];
  planId: Scalars["String"];
  startsOn: Scalars["Date"];
  endsOn?: Maybe<Scalars["Date"]>;
};

export enum PMeFeeTriggerType {
  FirstRentReceipt = "FirstRentReceipt",
  Manual = "Manual",
  FirstReceiptPerStatement = "FirstReceiptPerStatement",
  InvoiceReceipt = "InvoiceReceipt",
  InspectionOnRoutine = "InspectionOnRoutine",
  InspectionOnEntry = "InspectionOnEntry",
  InspectionOnExit = "InspectionOnExit",
  BillCreated = "BillCreated",
}

export enum PMeFeeValueType {
  Fixed = "Fixed",
  Percent = "Percent",
}

export type PmPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<Scalars["String"]>;
};

export type PreviewedRentSchedule = {
  __typename?: "PreviewedRentSchedule";
  amountInCents: Scalars["String"];
  period: RentFrequency;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type PreviewMultipleRentReviewsInput = {
  rentFrequency: QuartzPlanFrequency;
  rentReviewDetails: Array<RentReviewInput>;
};

export type ProblemInterface = {
  message: Scalars["String"];
};

export type Project = {
  __typename?: "Project";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  type: ProjectType;
  dueDate: Scalars["Date"];
  organisation?: Maybe<Organisation>;
  assignee: Person;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  modifiedBy?: Maybe<User>;
  closedAt?: Maybe<Scalars["DateTime"]>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  status: ProjectStatus;
  reference: Scalars["String"];
  meta?: Maybe<ProjectMeta>;
  actions?: Maybe<PaginatedActions>;
  files?: Maybe<PaginatedProjectFiles>;
};

export type ProjectActionsArgs = {
  cursor?: ActionCursor;
};

export type ProjectFilesArgs = {
  cursor?: ProjectFileCursor;
};

export type ProjectCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type ProjectFile = {
  __typename?: "ProjectFile";
  id: Scalars["ID"];
  file?: Maybe<File>;
  project: Project;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
};

export type ProjectFileCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type ProjectFilter = {
  status?: Maybe<StatusComparison>;
  assigneeAilorns?: Maybe<AssigneeAilornsComparison>;
  types?: Maybe<ProjectTypesComparison>;
};

export type ProjectMeta =
  | GeneralProjectMeta
  | LeaseRenewalProjectMeta
  | NewTenancyProjectMeta
  | NewManagementProjectMeta
  | VacatingTenancyProjectMeta
  | RoutineInspectionProjectMeta;

export enum ProjectStatus {
  Open = "Open",
  Closed = "Closed",
  Archived = "Archived",
}

export enum ProjectType {
  General = "General",
  LeaseRenewal = "LeaseRenewal",
  NewTenancy = "NewTenancy",
  NewManagement = "NewManagement",
  VacatingTenancy = "VacatingTenancy",
  RoutineInspection = "RoutineInspection",
}

export type ProjectTypesComparison = {
  in?: Maybe<Array<ProjectType>>;
};

export type Property = {
  __typename?: "Property";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  address: Address;
  /**
   * Returns all agency properties associated with this property.
   * Throws if requested without admin read permissions.
   */
  agencyProperties: Array<AgencyProperty>;
  managements: Array<Management>;
  keys: Array<Key>;
  inspections: Array<Inspection>;
  inspectionAppointments: Array<InspectionAppointment>;
};

export type PropertyKeysArgs = {
  filter?: Maybe<KeyFilter>;
  sorting?: Maybe<Array<KeySort>>;
};

export type PropertyInspectionsArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PropertyInspectionAppointmentsArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PropertyInput = {
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  postcode?: Maybe<Scalars["String"]>;
};

export type ProposedPlan = {
  amount: MoneyInput;
  desiredStartDate: Scalars["DateTime"];
  frequency: QuartzPlanFrequency;
};

export type ProposedRent = {
  amountInCents: Scalars["String"];
  period: RentFrequency;
  effectiveDate: Scalars["Date"];
  setsChargeDate: Scalars["Boolean"];
};

export type Purpose = {
  __typename?: "Purpose";
  id: Scalars["ID"];
  payable?: Maybe<PurposeReference>;
  payableBy: Scalars["AiloRN"];
  payableTo: Scalars["AiloRN"];
  reference: Scalars["AiloRN"];
  type: PurposeType;
};

export type PurposeReference = Bill | RecurringOwing | TrustAccountWallet;

export enum PurposeType {
  AccountTransfer = "ACCOUNT_TRANSFER",
  Bill = "BILL",
  Deposit = "DEPOSIT",
  Disbursement = "DISBURSEMENT",
  Fee = "FEE",
  NegativeWalletBalance = "NEGATIVE_WALLET_BALANCE",
  Rent = "RENT",
}

export enum QuartzPlanFrequency {
  Daily = "daily",
  Fortnightly = "fortnightly",
  Monthly = "monthly",
  Weekly = "weekly",
}

export type Query = {
  __typename?: "Query";
  reconciliationReportPeriod?: Maybe<ReconciliationReportPeriod>;
  reconciliationReportPeriods: ReconciliationReportPeriodConnection;
  reconciliationReportPeriodByDate?: Maybe<ReconciliationReportPeriod>;
  trustAccountReconciliationAdjustments: TrustAccountReconciliationAdjustmentConnection;
  findEftPaymentSettings?: Maybe<EftPaymentSettings>;
  trustAccount?: Maybe<TrustAccount>;
  trustAccounts: TrustAccountConnection;
  trustAccountWallet?: Maybe<TrustAccountWallet>;
  trustAccountWallets: TrustAccountWalletConnection;
  statement?: Maybe<Statement>;
  statements?: Maybe<PaginatedStatements>;
  statementProgresses?: Maybe<PaginatedStatementProgresses>;
  statementProgress?: Maybe<StatementProgress>;
  searchContacts?: Maybe<PaginatedContactSearchItems>;
  searchAgencyProperties?: Maybe<PaginatedAgencyPropertySearchItems>;
  searchTrustAccountWallets?: Maybe<PaginatedTrustAccountWalletSearchItems>;
  searchTenancies?: Maybe<PaginatedTenancySearchResultItems>;
  getTrustAccounts: Array<TrustBankAccount>;
  getCentrepayers: Array<CentrepayDirective>;
  getCentrepayPaymentDirectives: Array<CentrepayDirective>;
  getCentrepayFeeSetting: CentrepayFeeSetting;
  getOfflineProcessingEnabledForTenancy: OfflinePaymentProcessingEnabled;
  tenancy?: Maybe<Tenancy>;
  /** Needs admin permissions to query. */
  allTenancies: BidirectionalPaginatedTenancies;
  rent?: Maybe<Rent>;
  /** @deprecated Use `feeBlueprint` instead. */
  recurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  feeBlueprint?: Maybe<FeeBlueprint>;
  /** @deprecated Use `feeBlueprints` instead. */
  recurringFeeBlueprints?: Maybe<Array<RecurringFeeBlueprint>>;
  /** @deprecated Use `feeBlueprints` instead. */
  recurringFeeBlueprintsV2?: Maybe<PaginatedRecurringFeeBlueprints>;
  feeBlueprints?: Maybe<PaginatedFeeBlueprints>;
  feeTaxCategories?: Maybe<Array<FeeTaxCategory>>;
  managementFeeSchedule: ManagementFeeSchedule;
  managementFolio?: Maybe<ManagementFolio>;
  managementFolios?: Maybe<PaginatedManagementFolios>;
  fee?: Maybe<Fee>;
  fees?: Maybe<PaginatedFees>;
  feeEvents?: Maybe<Array<FeeEvent>>;
  recurringFee?: Maybe<RecurringFee>;
  recurringFees?: Maybe<Array<RecurringFee>>;
  properties?: Maybe<PaginatedProperties>;
  property?: Maybe<Property>;
  tenancyAgreement?: Maybe<TenancyAgreement>;
  managementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  managementFeeBlueprints?: Maybe<PaginatedManagementFeeBlueprints>;
  managementEndReasons: Array<ManagementEndReason>;
  agencyProperty?: Maybe<AgencyProperty>;
  agencyPropertyByOrgAndProperty?: Maybe<AgencyProperty>;
  agencyProperties: PaginatedAgencyProperties;
  management?: Maybe<Management>;
  managements?: Maybe<PaginatedManagements>;
  /** @deprecated Use LegalEntity.managements instead */
  managementsForOwner?: Maybe<PaginatedManagements>;
  rentSchedule?: Maybe<RentSchedule>;
  /** @deprecated Use LegalEntity.tenancies instead */
  tenanciesForTenant?: Maybe<PaginatedTenancies>;
  getTenancyBondAccountsByManagingEntity: Array<TenancyBondAccount>;
  team?: Maybe<Team>;
  getCentrepayAccountByManagingEntity?: Maybe<CentrepayAccount>;
  getOfflinePaymentAccountsByManagingEntity: Array<OfflinePaymentAccount>;
  project?: Maybe<Project>;
  canCreateProjectOfType?: Maybe<CreateProjectAbility>;
  projectsByOrganisation?: Maybe<PaginatedProjects>;
  action?: Maybe<Action>;
  checkSagaStatus?: Maybe<SagaStatusResponse>;
  getTrustAccountLock?: Maybe<TrustAccountLock>;
  payablePurposes?: Maybe<Array<Purpose>>;
  paidToDatePreview: PaidTo;
  recurringOwing?: Maybe<RecurringOwing>;
  recurringOwingByReference?: Maybe<RecurringOwing>;
  recurringOwingPreviewSummary: Array<OwingSummary>;
  /** @deprecated use userPreferencesByUserIdAndType */
  userPreferenceByUserId?: Maybe<UserPreference>;
  userPreferencesByUserIdAndType?: Maybe<Array<Maybe<UserPreference>>>;
  getNotification: NotificationBody;
  migrationLegalEntitiesWithExternalTrustAccount?: Maybe<PaginatedLegalEntityWithExternalTrustAccounts>;
  migratingManagement?: Maybe<MigratingManagement>;
  migratingManagements?: Maybe<PaginatedMigratingManagements>;
  mailByIds: Array<Mail>;
  /**
   * Amount due to be paid up til the 00am of the given timestamp minus all payment made against this liability up til now.
   * e.g. if liability is $100/wk rent. Tenant paid nothing, and input time is Wed 10am, this will return 2days * 100/7
   */
  amountDueToDate?: Maybe<Money>;
  autoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  autoPayLiabilityStatuses?: Maybe<Array<Maybe<AutoPayLiabilityStatus>>>;
  autoWithdrawPlanById?: Maybe<AutoWithdrawPlan>;
  autoWithdrawPlans?: Maybe<PaginatedAutoWithdrawPlans>;
  bankReconciliationDetailReport?: Maybe<PaginatedReconciliationDetail>;
  bankReconciliationReport?: Maybe<BankReconciliationReport>;
  gatewayCapturePaymentDetails?: Maybe<GatewayCapturePaymentDetails>;
  ignoreMe?: Maybe<IgnoreMe>;
  liabilityById?: Maybe<Liability>;
  liabilityCalculation?: Maybe<LiabilityCalculation>;
  liabilityEntry?: Maybe<LiabilityEntry>;
  liabilityPaymentPlanById?: Maybe<LiabilityPaymentPlan>;
  liabilityPaymentPlans?: Maybe<PaginatedLiabilityPaymentPlans>;
  liabilityPlanChangePreview?: Maybe<LiabilityChangePreview>;
  liabilityReportIgnoreMe?: Maybe<LiabilityReport>;
  listBankAccountBlacklistByOwner?: Maybe<Array<BankAccountBlacklist>>;
  listPaymentMethodAiloFeesWaiverByOwner?: Maybe<
    Array<Maybe<PaymentMethodAiloFees>>
  >;
  managementFolioIgnoreMe?: Maybe<ManagementFolio>;
  paymentMethodById?: Maybe<PaymentMethod>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  /** @deprecated Use `paymentMethods` instead. */
  paymentMethodsByOwner?: Maybe<PaginatedPaymentMethods>;
  previewMultipleRentReviews?: Maybe<Array<Maybe<RentReviewPlan>>>;
  rentReviewPreview?: Maybe<Array<Maybe<RentReviewPlan>>>;
  /** @deprecated The return type is deprecated. This is only being used for integration tests and will be removed eventually. */
  statementByAccount?: Maybe<DeprecatedStatementData>;
  taxSummaryStatementData: TaxSummaryStatementData;
  /**
   * Query to only get the tenancy ledger line items that are related to initial
   * payment. Intended as a stop gap until the dedicated
   * deposit service exists
   */
  tenancyLedgerInitialPaymentLineItems: Array<TenancyLedgerLineItem>;
  tenancyLedgerLineItems: Array<TenancyLedgerLineItem>;
  totalClearedRentReceivedInWallets: TotalClearedRentReceived;
  transactionDetailsById?: Maybe<TransactionDetails>;
  transactionReport?: Maybe<PaginatedTransactionReport>;
  transferSummaryStatementData?: Maybe<WalletStatementData>;
  /**
   * Query to get the range of a transfer summary statement.
   * The input businessTx and matching toDateTime corresponds to the transfer from
   * management wallet to an investor's bank account.
   * This query returns the timestamp of that transfer (toDateTime), and the
   * timestamp of the previous non-failed transfer (fromDateTime).
   * TransferSummaryStatementRange is to be applied as part of
   * TransferSummaryStatementInput for the transferSummaryStatementData endpoint.
   */
  transferSummaryStatementRange?: Maybe<TransferSummaryStatementRange>;
  walletBalanceReport?: Maybe<WalletBalanceReport>;
  walletById?: Maybe<Wallet>;
  walletByWalletOwnerReference?: Maybe<Wallet>;
  /** @deprecated Use `walletEntriesByOwnerRef` instead */
  walletEntriesWithStatusByOwnerRef?: Maybe<PaginatedWalletEntriesWithBtStatus>;
  walletOwnerLiabilityReport?: Maybe<WalletOwnerLiabilityReport>;
  walletStatement?: Maybe<WalletStatementData>;
  key?: Maybe<Key>;
  inspections: Array<Inspection>;
  inspection: Inspection;
  inspectionAreas: Array<InspectionArea>;
  inspectionArea: InspectionArea;
  inspectionFeatures: Array<InspectionFeature>;
  inspectionFeature: InspectionFeature;
  inspectionConnection: InspectionConnection;
  inspectionAppointment: InspectionAppointment;
  inspectionAppointmentConnection: InspectionAppointmentConnection;
  accountOwner?: Maybe<AccountOwner>;
  accountTagBalances?: Maybe<PaginatedAccountTagBalances>;
  accounts?: Maybe<PaginatedAccounts>;
  entries?: Maybe<PaginatedEntries>;
  firstAndLastEntries?: Maybe<FirstAndLastEntries>;
  incomeAndExpenseByTaxCategory?: Maybe<IncomeAndExpenseByTaxCategoryData>;
  ledgerEvent?: Maybe<LedgerEvent>;
  transactions?: Maybe<PaginatedTransactions>;
  reiTokens: Array<ReiToken>;
  reiToken: ReiToken;
  form: Form;
  reiForm: ReiForm;
  ailoForms: Array<AiloForm>;
  ailoForm: AiloForm;
  ailoFormTemplates: Array<AiloFormTemplate>;
  ailoFormTemplate: AiloFormTemplate;
  ailoFormTemplateByType: AiloFormTemplate;
  ailoLiabilityReport: LiabilityCategoryReport;
  walletOwnerBalanceReport: WalletOwnerBalanceReport;
  discrepancyReport: DiscrepancyReport;
  file?: Maybe<File>;
  claimedFilesCount?: Maybe<ClaimedFilesCount>;
  contacts: PaginatedContacts;
  contactsByIds: Array<Contact>;
  contactsByEmails: Array<Contact>;
  contactsByUserAilorns: Array<Contact>;
  contactsByTenancy: ContactsByTenancy;
  contactsByManagement: ContactsByManagement;
  findChatsV2: PaginatedChatsV2;
  chatV2: ChatV2;
  threadV2: ThreadV2;
  messageV2: MessageV2;
  unreadMessagesCount: Scalars["Int"];
  billById: Bill;
  bills?: Maybe<PaginatedBills>;
  paymentReferences?: Maybe<PaginatedPaymentReferences>;
  taxCategories: Array<TaxCategory>;
  taxCategoryById?: Maybe<TaxCategory>;
  /** @deprecated Use `supplier` instead */
  supplierById?: Maybe<Supplier>;
  supplier?: Maybe<Supplier>;
  suppliers: PaginatedSuppliers;
  emptyAutopayment?: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use 'effectiveUser' instead */
  currentUser?: Maybe<User>;
  effectiveUser?: Maybe<User>;
  actualUser?: Maybe<User>;
  user?: Maybe<User>;
  users: Array<User>;
  userEmailVerifications?: Maybe<PaginatedUserEmailVerifications>;
  userEmailVerification?: Maybe<UserEmailVerification>;
  legalEntity?: Maybe<LegalEntity>;
  legalEntities?: Maybe<PaginatedLegalEntities>;
  personEmailInUse: Scalars["Boolean"];
  people?: Maybe<PaginatedPeople>;
  person?: Maybe<Person>;
  abrCompanyLookup?: Maybe<AbrCompanyLookupResult>;
  company?: Maybe<Company>;
  companies?: Maybe<PaginatedCompanies>;
  organisation?: Maybe<Organisation>;
  organisations?: Maybe<PaginatedOrganisations>;
  roles?: Maybe<Array<Role>>;
  /** @deprecated Use `LegalEntity.legalEntityMembershipsAsLegalEntity` instead. */
  rolesAtLegalEntity?: Maybe<Array<LegalEntityPersonRole>>;
  getCrn?: Maybe<GetCrnOutput>;
  getLegalEntityByCrn?: Maybe<GetLegalEntityByCrnOutput>;
  atpEmptyStarterSchemaNoop?: Maybe<Scalars["Boolean"]>;
};

export type QueryReconciliationReportPeriodArgs = {
  id: Scalars["ID"];
};

export type QueryReconciliationReportPeriodsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ReconciliationReportPeriodFilter>;
  sorting?: Maybe<Array<ReconciliationReportPeriodSort>>;
};

export type QueryReconciliationReportPeriodByDateArgs = {
  input: ReportPeriodByDateInput;
};

export type QueryTrustAccountReconciliationAdjustmentsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountReconciliationAdjustmentFilter>;
  sorting?: Maybe<Array<TrustAccountReconciliationAdjustmentSort>>;
};

export type QueryFindEftPaymentSettingsArgs = {
  agency: Scalars["AiloRN"];
};

export type QueryTrustAccountArgs = {
  id: Scalars["ID"];
};

export type QueryTrustAccountsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountFilter>;
  sorting?: Maybe<Array<TrustAccountSort>>;
};

export type QueryTrustAccountWalletArgs = {
  id: Scalars["ID"];
};

export type QueryTrustAccountWalletsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountWalletFilter>;
  sorting?: Maybe<Array<TrustAccountWalletSort>>;
};

export type QueryStatementArgs = {
  id: Scalars["ID"];
};

export type QueryStatementsArgs = {
  filter?: Maybe<GeneralStatementFilter>;
  paginationParams?: Maybe<PaginationParams>;
  sort?: Maybe<Array<StatementSort>>;
};

export type QueryStatementProgressesArgs = {
  filter: StatementProgressFilter;
  cursor: StatementProgressCursor;
};

export type QueryStatementProgressArgs = {
  id: Scalars["ID"];
};

export type QuerySearchContactsArgs = {
  organisationAilornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  typeOf?: Maybe<Array<Scalars["String"]>>;
  legalEntityType?: Maybe<Array<Scalars["String"]>>;
  query?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<ContactSortParams>>;
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type QuerySearchAgencyPropertiesArgs = {
  input: SearchAgencyPropertiesQueryInput;
};

export type QuerySearchTrustAccountWalletsArgs = {
  input: SearchTrustAccountWalletsQueryInput;
};

export type QuerySearchTenanciesArgs = {
  input: SearchTenanciesQueryInput;
};

export type QueryGetTrustAccountsArgs = {
  input: GetTrustAccountInput;
};

export type QueryGetCentrepayersArgs = {
  input: GetCentrepayersInput;
};

export type QueryGetCentrepayPaymentDirectivesArgs = {
  input: GetCentrepayPaymentDirectivesFilter;
};

export type QueryGetCentrepayFeeSettingArgs = {
  escrowAccount: Scalars["AiloRN"];
};

export type QueryGetOfflineProcessingEnabledForTenancyArgs = {
  tenancy: Scalars["AiloRN"];
};

export type QueryTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type QueryAllTenanciesArgs = {
  input: AllTenanciesQueryInput;
};

export type QueryRentArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRecurringFeeBlueprintArgs = {
  id: Scalars["AiloRN"];
};

export type QueryFeeBlueprintArgs = {
  id: Scalars["ID"];
};

export type QueryRecurringFeeBlueprintsArgs = {
  conditions: RecurringFeeBlueprintsQueryInput;
};

export type QueryRecurringFeeBlueprintsV2Args = {
  conditions?: Maybe<RecurringFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryFeeBlueprintsArgs = {
  conditions?: Maybe<FeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryManagementFeeScheduleArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFolioArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFoliosArgs = {
  id?: Maybe<Array<Scalars["ID"]>>;
  ownerId?: Maybe<Array<Scalars["ID"]>>;
  managingEntityId?: Maybe<Array<Scalars["ID"]>>;
  paginationParams?: PaginationParams;
};

export type QueryFeeArgs = {
  id: Scalars["ID"];
};

export type QueryFeesArgs = {
  conditions?: Maybe<FeesQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
  sort?: Maybe<Array<FeeSort>>;
};

export type QueryRecurringFeeArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRecurringFeesArgs = {
  conditions: RecurringFeesQueryInput;
};

export type QueryPropertiesArgs = {
  pageCursor: PmPageCursor;
  search?: Maybe<Scalars["String"]>;
};

export type QueryPropertyArgs = {
  propertyId: Scalars["ID"];
};

export type QueryTenancyAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFeeBlueprintArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFeeBlueprintsArgs = {
  conditions?: Maybe<ManagementFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryAgencyPropertyArgs = {
  id: Scalars["ID"];
};

export type QueryAgencyPropertyByOrgAndPropertyArgs = {
  organisationAilorn: Scalars["AiloRN"];
  propertyId: Scalars["ID"];
};

export type QueryAgencyPropertiesArgs = {
  input: AgencyPropertiesQueryInput;
};

export type QueryManagementArgs = {
  managementId: Scalars["ID"];
};

export type QueryManagementsArgs = {
  pageCursor: PmPageCursor;
  organisationId?: Maybe<Scalars["ID"]>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryManagementsForOwnerArgs = {
  ownerId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type QueryRentScheduleArgs = {
  id: Scalars["AiloRN"];
};

export type QueryTenanciesForTenantArgs = {
  tenantId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type QueryGetTenancyBondAccountsByManagingEntityArgs = {
  managingEntity: Scalars["AiloRN"];
};

export type QueryTeamArgs = {
  teamId: Scalars["AiloRN"];
};

export type QueryGetCentrepayAccountByManagingEntityArgs = {
  managingEntity: Scalars["AiloRN"];
};

export type QueryGetOfflinePaymentAccountsByManagingEntityArgs = {
  managingEntity: Scalars["AiloRN"];
};

export type QueryProjectArgs = {
  id: Scalars["ID"];
};

export type QueryCanCreateProjectOfTypeArgs = {
  managementAilorn: Scalars["AiloRN"];
  type: ProjectType;
};

export type QueryProjectsByOrganisationArgs = {
  organisationAilorn: Scalars["AiloRN"];
  filter?: Maybe<ProjectFilter>;
  pageCursor?: ProjectCursor;
};

export type QueryActionArgs = {
  id: Scalars["ID"];
};

export type QueryCheckSagaStatusArgs = {
  sagaId?: Maybe<Scalars["ID"]>;
};

export type QueryGetTrustAccountLockArgs = {
  trustAccountAilorn: Scalars["AiloRN"];
};

export type QueryPayablePurposesArgs = {
  filters: ListPurposesFilters;
};

export type QueryPaidToDatePreviewArgs = {
  owing: RecurringOwingCalculationInput;
  totalAmountPaid?: Maybe<MoneyInput>;
};

export type QueryRecurringOwingArgs = {
  id: Scalars["ID"];
};

export type QueryRecurringOwingByReferenceArgs = {
  ref: Scalars["AiloRN"];
};

export type QueryRecurringOwingPreviewSummaryArgs = {
  owing: RecurringOwingCalculationInput;
};

export type QueryUserPreferenceByUserIdArgs = {
  userId: Scalars["AiloRN"];
};

export type QueryUserPreferencesByUserIdAndTypeArgs = {
  userId: Scalars["AiloRN"];
  communicationType?: Maybe<CommunicationType>;
};

export type QueryGetNotificationArgs = {
  notificationId: Scalars["AiloRN"];
};

export type QueryMigrationLegalEntitiesWithExternalTrustAccountArgs = {
  pagination: PaginationParams;
  filters: LegalEntityWithExternalTrustAccountsFilters;
  sort: LegalEntityWithExternalTrustAccountSortParams;
};

export type QueryMigratingManagementArgs = {
  id: Scalars["ID"];
};

export type QueryMigratingManagementsArgs = {
  pagination: PaginationParams;
  filters?: Maybe<MigratingManagementsFilters>;
  sort: MigratingManagementSortParams;
};

export type QueryMailByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryAmountDueToDateArgs = {
  amountDueToDateInput: AmountDueToDateInput;
};

export type QueryAutoPayLiabilityStatusArgs = {
  payerId?: Maybe<Scalars["AiloRN"]>;
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryAutoPayLiabilityStatusesArgs = {
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryAutoWithdrawPlanByIdArgs = {
  autoWithdrawPlanId: Scalars["ID"];
  payerLegalEntityId?: Maybe<Scalars["AiloRN"]>;
};

export type QueryAutoWithdrawPlansArgs = {
  disableOwnerFilter?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryBankReconciliationDetailReportArgs = {
  cursor?: Maybe<BidirectionalPageCursor>;
  filter?: Maybe<Filter>;
};

export type QueryBankReconciliationReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryGatewayCapturePaymentDetailsArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryLiabilityByIdArgs = {
  liabilityId: Scalars["ID"];
};

export type QueryLiabilityCalculationArgs = {
  liabilityCalculationInput: LiabilityCalculationInput;
};

export type QueryLiabilityEntryArgs = {
  liabilityEntryId: Scalars["ID"];
};

export type QueryLiabilityPaymentPlanByIdArgs = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryLiabilityPaymentPlansArgs = {
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryLiabilityPlanChangePreviewArgs = {
  planToPreview: LiabilityPlanInput;
};

export type QueryListBankAccountBlacklistByOwnerArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryListPaymentMethodAiloFeesWaiverByOwnerArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryPaymentMethodByIdArgs = {
  paymentMethodId: Scalars["ID"];
};

export type QueryPaymentMethodsArgs = {
  conditions?: Maybe<PaymentMethodSearchOptions>;
};

export type QueryPaymentMethodsByOwnerArgs = {
  options?: Maybe<PaymentMethodSearchOptions>;
  owner: Scalars["AiloRN"];
};

export type QueryPreviewMultipleRentReviewsArgs = {
  proposedPlan: PreviewMultipleRentReviewsInput;
};

export type QueryRentReviewPreviewArgs = {
  proposedPlan: RentReviewProposedPlanInput;
};

export type QueryStatementByAccountArgs = {
  statementByAccountInput: StatementByAccountInput;
};

export type QueryTaxSummaryStatementDataArgs = {
  statementRequest: TaxSummaryStatementInput;
};

export type QueryTenancyLedgerInitialPaymentLineItemsArgs = {
  input?: Maybe<TenancyLedgerLineItemsInput>;
};

export type QueryTenancyLedgerLineItemsArgs = {
  input?: Maybe<TenancyLedgerLineItemsInput>;
};

export type QueryTotalClearedRentReceivedInWalletsArgs = {
  ownerRef: Scalars["AiloRN"];
};

export type QueryTransactionDetailsByIdArgs = {
  businessTransactionId: Scalars["AiloRN"];
};

export type QueryTransactionReportArgs = {
  cursor: LedgerPageCursor;
  filter?: Maybe<Filter>;
};

export type QueryTransferSummaryStatementDataArgs = {
  input?: Maybe<TransferSummaryStatementInput>;
};

export type QueryTransferSummaryStatementRangeArgs = {
  input?: Maybe<TransferSummaryStatementRangeInput>;
};

export type QueryWalletBalanceReportArgs = {
  filter?: Maybe<Filter>;
};

export type QueryWalletByIdArgs = {
  walletId: Scalars["ID"];
};

export type QueryWalletByWalletOwnerReferenceArgs = {
  walletOwnerReference: Scalars["AiloRN"];
};

export type QueryWalletEntriesWithStatusByOwnerRefArgs = {
  aggregated?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<LedgerPageCursor>;
  ownerRef: Scalars["AiloRN"];
  relatingToManagementAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type QueryWalletOwnerLiabilityReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryWalletStatementArgs = {
  input: WalletStatementInput;
};

export type QueryKeyArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionAreaArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionFeatureArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionConnectionArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<InspectionFilter>;
  sorting?: Maybe<Array<InspectionSort>>;
};

export type QueryInspectionAppointmentArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionAppointmentConnectionArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<InspectionAppointmentFilter>;
  sorting?: Maybe<Array<InspectionAppointmentSort>>;
};

export type QueryAccountOwnerArgs = {
  reference: Scalars["AiloRN"];
};

export type QueryAccountTagBalancesArgs = {
  filter?: Maybe<ListAccountTagBalancesFilters>;
  paginationParams?: PaginationParams;
};

export type QueryAccountsArgs = {
  filters: ListAccountsFilters;
  pagination?: PaginationParams;
  sorts?: Array<ListAccountsSortParams>;
};

export type QueryEntriesArgs = {
  filters: ListEntriesFilters;
  pagination?: PaginationParams;
  sorts?: Array<ListEntriesSortParams>;
};

export type QueryFirstAndLastEntriesArgs = {
  filters: ListEntriesFilters;
};

export type QueryIncomeAndExpenseByTaxCategoryArgs = {
  input?: Maybe<IncomeAndExpenseByTaxCategoryInput>;
};

export type QueryLedgerEventArgs = {
  id: Scalars["ID"];
};

export type QueryTransactionsArgs = {
  filter: ListTransactionsFilters;
  paginationParams?: PaginationParams;
  sort?: Array<ListTransactionsSortParams>;
};

export type QueryReiTokenArgs = {
  id: Scalars["ID"];
};

export type QueryFormArgs = {
  id: Scalars["ID"];
};

export type QueryReiFormArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormTemplateByTypeArgs = {
  version?: Maybe<Scalars["Int"]>;
  state: AustralianState;
  type: AiloFormType;
};

export type QueryAiloLiabilityReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryWalletOwnerBalanceReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryDiscrepancyReportArgs = {
  filter?: Maybe<Filter>;
};

export type QueryFileArgs = {
  id: Scalars["AiloRN"];
};

export type QueryClaimedFilesCountArgs = {
  claimerAilorn: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
};

export type QueryContactsArgs = {
  input: ContactsQueryInput;
};

export type QueryContactsByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryContactsByEmailsArgs = {
  organisationAilorn: Scalars["AiloRN"];
  emails: Array<Scalars["String"]>;
};

export type QueryContactsByUserAilornsArgs = {
  organisationAilorn: Scalars["AiloRN"];
  userAilorns: Array<Scalars["AiloRN"]>;
};

export type QueryContactsByTenancyArgs = {
  tenancyAilorn: Scalars["AiloRN"];
  filters?: Maybe<ContactsFilters>;
};

export type QueryContactsByManagementArgs = {
  managementAilorn: Scalars["AiloRN"];
  filters?: Maybe<ContactsFilters>;
};

export type QueryFindChatsV2Args = {
  input: PaginatedChatsInputV2;
};

export type QueryChatV2Args = {
  chatAilorn: Scalars["AiloRN"];
};

export type QueryThreadV2Args = {
  threadAilorn: Scalars["AiloRN"];
};

export type QueryMessageV2Args = {
  messageAilorn: Scalars["AiloRN"];
};

export type QueryUnreadMessagesCountArgs = {
  input: UnreadMessagesCountInput;
};

export type QueryBillByIdArgs = {
  billId: Scalars["ID"];
};

export type QueryBillsArgs = {
  id?: Maybe<Array<Scalars["AiloRN"]>>;
  organisationId?: Maybe<Array<Scalars["AiloRN"]>>;
  payerId?: Maybe<Array<Scalars["AiloRN"]>>;
  payerIdNotIn?: Maybe<Array<Scalars["AiloRN"]>>;
  payerType?: Maybe<Array<BillPayerType>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
  taxCategoryId?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<BillStatus>>;
  paymentStatus?: Maybe<Array<BillPaymentStatus>>;
  management?: Maybe<Array<Scalars["AiloRN"]>>;
  team?: Maybe<Array<Scalars["AiloRN"]>>;
  paid?: Maybe<Scalars["Boolean"]>;
  dueDate?: Maybe<LocalDateRangeCondition>;
  payByDate?: Maybe<LocalDateRangeCondition>;
  paymentDate?: Maybe<LocalDateRangeCondition>;
  invoiceNumber?: Maybe<Array<Scalars["String"]>>;
  agencyStatus?: Maybe<Array<BillAgencyStatus>>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<PageCursorWithoutSort>;
  excludeBillsWithEmptyPaymentStatus?: Maybe<Scalars["Boolean"]>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
};

export type QueryPaymentReferencesArgs = {
  cursor?: Maybe<BillDefaultPageCursor>;
  conditions?: Maybe<PaymentReferencesQueryConditions>;
};

export type QueryTaxCategoryByIdArgs = {
  taxCategoryId: Scalars["String"];
};

export type QuerySupplierByIdArgs = {
  supplierId: Scalars["ID"];
};

export type QuerySupplierArgs = {
  id: Scalars["ID"];
};

export type QuerySuppliersArgs = {
  cursor: PageCursor;
  conditions?: Maybe<SuppliersQueryInput>;
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type QueryUsersArgs = {
  ids: Array<Scalars["Int"]>;
};

export type QueryUserEmailVerificationsArgs = {
  userId?: Maybe<Scalars["AiloRN"]>;
  emailAddressStartsWith?: Maybe<Scalars["String"]>;
  pageCursor?: Maybe<PageCursor>;
};

export type QueryUserEmailVerificationArgs = {
  id: Scalars["AiloRN"];
};

export type QueryLegalEntityArgs = {
  id: Scalars["AiloRN"];
};

export type QueryLegalEntitiesArgs = {
  pageCursor?: Maybe<PageCursor>;
  searchQuery?: Maybe<Scalars["String"]>;
};

export type QueryPersonEmailInUseArgs = {
  email: Scalars["String"];
};

export type QueryPeopleArgs = {
  pageCursor?: Maybe<PageCursor>;
  search?: Maybe<Scalars["String"]>;
  domainType?: Maybe<DomainType>;
};

export type QueryPersonArgs = {
  id: Scalars["ID"];
};

export type QueryAbrCompanyLookupArgs = {
  abnOrAcn: Scalars["String"];
};

export type QueryCompanyArgs = {
  id: Scalars["ID"];
};

export type QueryCompaniesArgs = {
  pageCursor?: Maybe<PageCursor>;
  search?: Maybe<Scalars["String"]>;
  orgType?: Maybe<OrganisationType>;
};

export type QueryOrganisationArgs = {
  id: Scalars["ID"];
};

export type QueryOrganisationsArgs = {
  pageCursor?: Maybe<PageCursor>;
  orgType?: Maybe<OrganisationType>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryRolesArgs = {
  conditions?: Maybe<RolesQueryConditions>;
};

export type QueryRolesAtLegalEntityArgs = {
  legalEntity: Scalars["AiloRN"];
};

export type QueryGetCrnArgs = {
  input: GetCrnInput;
};

export type QueryGetLegalEntityByCrnArgs = {
  input: GetLegalEntityByCrnInput;
};

export type ReceiptFundsInput = {
  allocations: Array<PaymentAllocationInput>;
  idempotencyKey: Scalars["String"];
  paidByReference?: Maybe<Scalars["String"]>;
  paidUsing?: Maybe<PaidUsingType>;
  trustAccount: Scalars["AiloRN"];
};

export type ReconciliationDetailReportItem = {
  __typename?: "ReconciliationDetailReportItem";
  businessTransactionId: Scalars["AiloRN"];
  categoryId: Scalars["Int"];
  fees?: Maybe<Money>;
  id: Scalars["Int"];
  paymentTransactionId?: Maybe<Scalars["AiloRN"]>;
  paymentType?: Maybe<Scalars["String"]>;
  requested?: Maybe<Money>;
  total: Money;
  transactionType?: Maybe<Scalars["String"]>;
};

export type ReconciliationReportItem = {
  __typename?: "ReconciliationReportItem";
  count: Scalars["Long"];
  fees?: Maybe<Money>;
  id?: Maybe<Scalars["Int"]>;
  paymentType?: Maybe<Scalars["String"]>;
  requested?: Maybe<Money>;
  total: Money;
  transactionType?: Maybe<Scalars["String"]>;
};

export type ReconciliationReportPeriod = {
  __typename?: "ReconciliationReportPeriod";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  month: Scalars["Int"];
  year: Scalars["Int"];
  locked: Scalars["Boolean"];
  trustAccountAilorn: Scalars["AiloRN"];
  trustAccount: TrustAccount;
  legalEntity?: Maybe<Company>;
  lastAddedStatementBalance?: Maybe<TrustAccountStatementBalance>;
  canAlterLock: Scalars["Boolean"];
  adjustmentSumInCents: Scalars["Long"];
  isReconciling: Scalars["Boolean"];
};

export type ReconciliationReportPeriodConnection = {
  __typename?: "ReconciliationReportPeriodConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<ReconciliationReportPeriodEdge>;
};

export type ReconciliationReportPeriodEdge = {
  __typename?: "ReconciliationReportPeriodEdge";
  /** The node containing the ReconciliationReportPeriod */
  node: ReconciliationReportPeriod;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type ReconciliationReportPeriodFilter = {
  and?: Maybe<Array<ReconciliationReportPeriodFilter>>;
  or?: Maybe<Array<ReconciliationReportPeriodFilter>>;
  id?: Maybe<IdFilterComparison>;
  month?: Maybe<IntFieldComparison>;
  year?: Maybe<IntFieldComparison>;
  locked?: Maybe<BooleanFieldComparison>;
  trustAccountAilorn?: Maybe<GraphQlAilornFilterComparison>;
};

export type ReconciliationReportPeriodSort = {
  field: ReconciliationReportPeriodSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ReconciliationReportPeriodSortFields {
  Id = "id",
  Month = "month",
  Year = "year",
  Locked = "locked",
  TrustAccountAilorn = "trustAccountAilorn",
}

export type RecurringFee = {
  __typename?: "RecurringFee";
  /** @deprecated Use `idV2` or `ailoRN` instead. */
  id: Scalars["AiloRN"];
  idV2: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  name: Scalars["String"];
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  managementAgreement?: Maybe<ManagementAgreement>;
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  management?: Maybe<Management>;
  taxCategory: FeeTaxCategory;
  taxTreatment: TaxTreatment;
  frequency: FeeFrequency;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  /** @deprecated Use `blueprintV2` instead. */
  blueprint: RecurringFeeBlueprint;
  blueprintV2: FeeBlueprint;
  schedules: Array<RecurringFeeSchedule>;
  /**
   * The most recent schedule that has already started (even if it has already
   * ended due to fee being cancelled or management ending).
   * If none exist, return the schedule that will start next.
   */
  currentOrNextSchedule?: Maybe<RecurringFeeSchedule>;
  liability?: Maybe<Liability>;
  /** Whether local date is currently on or past the fee's final charge cycle */
  nextOccurrence?: Maybe<FeeOccurrence>;
  cancelled: Scalars["Boolean"];
  /**
   * True if the fee has been cancelled while it was still in the future,
   * or archived before it has been paid.
   *
   * An archived fee is archived forever;
   * and its' liabilities will also get archived.
   */
  archived: Scalars["Boolean"];
  currentness: DateRangeCurrentness;
  owing?: Maybe<RecurringOwing>;
};

export type RecurringFeeBlueprint = {
  __typename?: "RecurringFeeBlueprint";
  id: Scalars["AiloRN"];
  type: Scalars["String"];
  name: Scalars["String"];
  taxCategory: FeeTaxCategory;
  description?: Maybe<Scalars["String"]>;
  fixedAmount: Money;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency: FeeFrequency;
  frequencyFormatted: Scalars["String"];
  anniversaryDay: Scalars["Int"];
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  /** @deprecated Use `organisation.id` instead. */
  createdByOrganisation: Scalars["AiloRN"];
  /** @deprecated Use `organisation.id` instead. */
  organisationId: Scalars["AiloRN"];
  /** Always present. (Nullable only because it's a federated field.) */
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<Scalars["AiloRN"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type RecurringFeeBlueprintInput = {
  name: Scalars["String"];
  taxCategoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  fixedAmount: MoneyInput;
  taxTreatment: TaxTreatment;
  frequency: FeeFrequency;
  anniversaryDay: Scalars["Int"];
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  organisationId: Scalars["AiloRN"];
};

export type RecurringFeeBlueprintsQueryConditions = {
  organisationId?: Maybe<Scalars["AiloRN"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export type RecurringFeeBlueprintsQueryInput = {
  createdByOrganisation: Scalars["AiloRN"];
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum RecurringFeeCurrentness {
  Past = "past",
  Current = "current",
  Future = "future",
}

export type RecurringFeeInput = {
  blueprintId: Scalars["AiloRN"];
  managementAgreementId: Scalars["AiloRN"];
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  schedules: Array<RecurringFeeScheduleInput>;
};

export enum RecurringFeePaymentStatus {
  Overdue = "overdue",
  /**
   * Currently not overdue but may become overdue in the future
   * once the next occurrence starts
   */
  PaidForNow = "paidForNow",
  /**
   * Currently not overdue and fee has no more occurrences so it should
   * never become overdue again
   */
  PaidCompletely = "paidCompletely",
}

export type RecurringFeeSchedule = {
  __typename?: "RecurringFeeSchedule";
  id: Scalars["AiloRN"];
  feeId: Scalars["AiloRN"];
  taxAmount: Money;
  taxInclusiveAmount: Money;
  startDate: Scalars["String"];
  createdAt: Scalars["String"];
  createdBy: Scalars["AiloRN"];
};

export type RecurringFeeScheduleInput = {
  taxAmount: MoneyInput;
  taxInclusiveAmount: MoneyInput;
  startDate: Scalars["String"];
};

export type RecurringFeesQueryInput = {
  managementId?: Maybe<Scalars["AiloRN"]>;
  managementAgreementId?: Maybe<Scalars["AiloRN"]>;
  createdByOrganisation?: Maybe<Scalars["AiloRN"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  currentness?: Maybe<Array<RecurringFeeCurrentness>>;
  paymentStatus?: Maybe<Array<RecurringFeePaymentStatus>>;
};

export enum RecurringFrequency {
  Annually = "ANNUALLY",
  Fortnightly = "FORTNIGHTLY",
  Monthly = "MONTHLY",
  Onceoff = "ONCEOFF",
  Weekly = "WEEKLY",
}

export type RecurringOwing = {
  __typename?: "RecurringOwing";
  ailoRN: Scalars["AiloRN"];
  chargeCycles?: Maybe<PaginatedChargeCycles>;
  chargeSchedules: Array<ChargeSchedule>;
  id: Scalars["ID"];
  liability?: Maybe<Liability>;
  owedBy: Scalars["AiloRN"];
  owedTo: Scalars["AiloRN"];
  owingType: RecurringOwingType;
  progress: OwingProgress;
  ratePeriods: Array<RecurringOwingRatePeriod>;
  recurringOwingEvents?: Maybe<PaginatedRecurringOwingEvents>;
  reference: Scalars["AiloRN"];
  referencedEntity?: Maybe<OwingReferencedEntity>;
  taxCategory: Scalars["String"];
};

export type RecurringOwingChargeCyclesArgs = {
  pagination?: PaginationParams;
};

export type RecurringOwingRecurringOwingEventsArgs = {
  pagination?: PaginationParams;
};

export type RecurringOwingCalculationInput = {
  chargeSchedules: Array<ChargeScheduleInput>;
  ratePeriods: Array<RecurringOwingRatePeriodInput>;
};

export type RecurringOwingEvent = {
  __typename?: "RecurringOwingEvent";
  arrearsBeforeEntry?: Maybe<Arrears>;
  businessTransaction?: Maybe<BusinessTransaction>;
  causeReference: Scalars["AiloRN"];
  changeInOwedAmount: Money;
  changeInOwedTaxAmount: Money;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  effectiveAt: Scalars["DateTime"];
  emittedAt?: Maybe<Scalars["DateTime"]>;
  eventType: RecurringOwingEventType;
  id: Scalars["ID"];
  internalDescription: Scalars["String"];
  modifiedAt: Scalars["DateTime"];
  paidTo?: Maybe<PaidTo>;
  paidToBeforeEntry?: Maybe<PaidTo>;
  recurringOwing?: Maybe<RecurringOwing>;
  reversalOf?: Maybe<Scalars["AiloRN"]>;
};

export enum RecurringOwingEventType {
  Adjustment = "ADJUSTMENT",
  AdjustmentReversal = "ADJUSTMENT_REVERSAL",
  OwingChanged = "OWING_CHANGED",
  OwingDue = "OWING_DUE",
  Payment = "PAYMENT",
  PaymentFailure = "PAYMENT_FAILURE",
  PaymentReversal = "PAYMENT_REVERSAL",
  Refund = "REFUND",
  RefundFailure = "REFUND_FAILURE",
  RefundReversal = "REFUND_REVERSAL",
}

export type RecurringOwingInput = {
  chargeSchedules: Array<ChargeScheduleInput>;
  liabilityId?: Maybe<Scalars["AiloRN"]>;
  managingEntities?: Maybe<Array<Scalars["AiloRN"]>>;
  owedBy: Scalars["AiloRN"];
  owedTo: Scalars["AiloRN"];
  owingType: RecurringOwingType;
  ratePeriods: Array<RecurringOwingRatePeriodInput>;
  reference: Scalars["AiloRN"];
  relatedToManagement?: Maybe<Scalars["AiloRN"]>;
  taxCategory: Scalars["String"];
  timezone: Scalars["String"];
};

export type RecurringOwingRatePeriod = {
  __typename?: "RecurringOwingRatePeriod";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: RecurringFrequency;
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type RecurringOwingRatePeriodInput = {
  amount: MoneyInput;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: RecurringFrequency;
  startDate: Scalars["Date"];
  taxAmount?: Maybe<MoneyInput>;
};

export enum RecurringOwingType {
  Fee = "FEE",
  Rent = "RENT",
}

export type RefundOutput = {
  __typename?: "RefundOutput";
  businessTransaction: BusinessTransaction;
  businessTransactionStatus: BusinessTransactionStatus;
};

export type ReiForm = Form & {
  __typename?: "ReiForm";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
  supportedState: AustralianState;
  reiFormInfo: ReiFormInfo;
  viewUrl: Scalars["String"];
  downloadUrl: Scalars["String"];
  remoteSigningPDF: Scalars["Binary"];
  ailorn: Scalars["AiloRN"];
  remoteSigningStatus: ReiFormRemoteSigningStatus;
};

export enum ReiFormErrorCode {
  /** The form is not found in REI system, perhaps it has been deleted */
  ReiFormNotFound = "REI_FORM_NOT_FOUND",
  /** REI doesn't allow to get the form with that ID */
  ReiUnauthorised = "REI_UNAUTHORISED",
  /** The linked account and the form state don't match */
  ReiTokenFormStatesMismatch = "REI_TOKEN_FORM_STATES_MISMATCH",
}

export type ReiFormInfo = {
  __typename?: "ReiFormInfo";
  name: Scalars["String"];
  finalised: Scalars["Boolean"];
};

export type ReiFormRemoteSigner = {
  __typename?: "ReiFormRemoteSigner";
  name: Scalars["String"];
  /** Sign status */
  status: ReiFormRemoteSignerStatus;
  /** Date if signed */
  signed?: Maybe<Scalars["DateTime"]>;
};

export enum ReiFormRemoteSignerStatus {
  /** The form is waiting for the signer to sign */
  Pending = "PENDING",
  /** The signer has opened the form in Docusign */
  Delivered = "DELIVERED",
  /** The signer has declined to sign a form */
  Declined = "DECLINED",
  /** The signer has completed signing a form */
  Completed = "COMPLETED",
}

export type ReiFormRemoteSigningStatus = {
  __typename?: "ReiFormRemoteSigningStatus";
  status: SigningStatus;
  /** Date if sent */
  sent?: Maybe<Scalars["DateTime"]>;
  /** List of signers with names and signed dates if any */
  recipients: Array<ReiFormRemoteSigner>;
};

export type ReiTemplate = {
  __typename?: "ReiTemplate";
  id: Scalars["Int"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type ReiToken = {
  __typename?: "ReiToken";
  id: Scalars["ID"];
  token: Scalars["String"];
  email: Scalars["String"];
  supportedState: AustralianState;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  person: Person;
  organisation: Organisation;
};

export type RelayPageInfo = {
  __typename?: "RelayPageInfo";
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars["ConnectionCursor"]>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars["ConnectionCursor"]>;
};

/** Sort Directions */
export enum RelaySortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type RemoveInspectionAreaFileResult = {
  __typename?: "RemoveInspectionAreaFileResult";
  inspectionAreaFile: InspectionAreaFile;
};

export type RemoveInspectionAreaResult = {
  __typename?: "RemoveInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type RemoveInspectionFeatureFileResult = {
  __typename?: "RemoveInspectionFeatureFileResult";
  inspectionFeatureFile: InspectionFeatureFile;
};

export type RemoveInspectionFeatureResult = {
  __typename?: "RemoveInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type RemoveInspectionResult = {
  __typename?: "RemoveInspectionResult";
  inspection: Inspection;
};

export type RemoveMemberFromLegalEntityInput = {
  memberPersonId: Scalars["String"];
  legalEntityId: Scalars["String"];
  roleId: Scalars["String"];
};

export type RemoveMemberFromOrganisationInput = {
  personId: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type RemoveReiTokenInput = {
  /** ID of the REI Token to be deleted */
  id: Scalars["String"];
  /** AiloRN of person the token is associated with */
  personAilorn: Scalars["AiloRN"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
};

export type RemoveTenancyFromMigratingManagementInput = {
  id: Scalars["ID"];
};

export type RemoveTenantshipInput = {
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
};

export type RemoveTenantshipPayload = {
  __typename?: "RemoveTenantshipPayload";
  tenantship: Tenantship;
};

export type RemoveTrustAccountReconciliationAdjustmentInput = {
  id: Scalars["ID"];
  removedReason: Scalars["String"];
  removeFromReportPeriodId: Scalars["ID"];
};

export type Rent = {
  __typename?: "Rent";
  /** @deprecated Use rent.ailorn instead. This field will eventually be changed to type ID */
  id: Scalars["AiloRN"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["String"];
  /** In cents with double-precision */
  dailyRate: Scalars["Float"];
  period: RentFrequency;
  effectiveDate: Scalars["Date"];
  rentIncreaseInCents: Scalars["String"];
  setsChargeDate: Scalars["Boolean"];
  category: RentCategory;
  previousRent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  rentSchedules: Array<RentSchedule>;
  proRataSchedulesForRentUpdate: Array<PreviewedRentSchedule>;
};

export type RentProRataSchedulesForRentUpdateArgs = {
  proposedRent: ProposedRent;
};

export enum RentCategory {
  Legacy = "Legacy",
  Support = "Support",
  Inherited = "Inherited",
  RentReview = "RentReview",
  LeaseRenewal = "LeaseRenewal",
  NewTenancy = "NewTenancy",
}

export type RentCreditDetailsInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  tenancyId: Scalars["ID"];
  effectiveDate: Scalars["Date"];
  idempotencyKey: Scalars["String"];
  notifyTenants?: Scalars["Boolean"];
  notifyInvestors?: Scalars["Boolean"];
};

export type RentCreditDetailsPayload = {
  __typename?: "RentCreditDetailsPayload";
  rentCredit?: Maybe<AdjustmentLiabilityEntry>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export enum RentFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Fortnightly = "fortnightly",
  Monthly = "monthly",
}

export type RentPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
};

export type RentReviewDetails = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  scheduledRentReviewDate: Scalars["Date"];
  period: RentFrequency;
};

export type RentReviewInput = {
  amount: MoneyInput;
  effectiveDate: Scalars["Date"];
};

export type RentReviewPlan = {
  __typename?: "RentReviewPlan";
  amount: Money;
  description?: Maybe<Scalars["String"]>;
  effectiveFrom: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type RentReviewProposedPlanInput = {
  amount: MoneyInput;
  desiredStartFrom: Scalars["Date"];
  liabilityRef: Scalars["AiloRN"];
};

export type RentSchedule = {
  __typename?: "RentSchedule";
  id: Scalars["AiloRN"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  startDate: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type RentSetupInput = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  category: RentCategory;
};

export enum RentSort {
  EffectiveDateAsc = "EFFECTIVE_DATE_ASC",
  EffectiveDateDesc = "EFFECTIVE_DATE_DESC",
}

export type ReportGenerationState = {
  __typename?: "ReportGenerationState";
  /** Returns true if an ongoing report progress does not exist */
  canGenerateReport: Scalars["Boolean"];
};

export type ReportPeriodByDateInput = {
  month: Scalars["Int"];
  year: Scalars["Int"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type ReportPeriodLockChangeResult = {
  __typename?: "ReportPeriodLockChangeResult";
  status: ReportPeriodLockChangeResultStatus;
  failureReason?: Maybe<Scalars["String"]>;
  report?: Maybe<ReconciliationReportPeriod>;
};

export enum ReportPeriodLockChangeResultStatus {
  Success = "SUCCESS",
  Failure = "FAILURE",
}

export type RequestSignatureActionMeta = {
  __typename?: "RequestSignatureActionMeta";
  form?: Maybe<Form>;
};

export type ResponseStatus = {
  __typename?: "ResponseStatus";
  businessTransaction?: Maybe<BusinessTransaction>;
  businessTransactionId?: Maybe<Scalars["AiloRN"]>;
  error?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type ReverseAdjustmentInput = {
  adjustmentId: Scalars["ID"];
  description: Scalars["String"];
};

export type RevokeMailboxAccessInput = {
  mailboxAccessAilorn: Scalars["AiloRN"];
};

export type Role = {
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type RolesQueryConditions = {
  type?: Maybe<RoleType>;
};

export enum RoleType {
  OrganisationRole = "OrganisationRole",
  LegalEntityRole = "LegalEntityRole",
}

export type RoutineInspectionProjectMeta = {
  __typename?: "RoutineInspectionProjectMeta";
  management: Management;
  tenancy: Tenancy;
  inspectionAppointment: InspectionAppointment;
};

export type SagaStatusResponse = {
  __typename?: "SagaStatusResponse";
  error?: Maybe<Scalars["String"]>;
  idempotencyKey: Scalars["String"];
  sagaId?: Maybe<Scalars["ID"]>;
  status: Scalars["String"];
};

export type SearchAgencyPropertiesQueryInput = {
  managingEntityOrgAilorn?: Maybe<Scalars["AiloRN"]>;
  query: Scalars["String"];
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on
   * "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process
   * query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than
   * 10,000 hits. This limit is a safeguard set by the index.max_result_window
   * index setting. If you need to page through more than 10,000 hits, use the
   * search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type SearchFilter = {
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SearchTenanciesQueryInput = {
  filters?: Maybe<TenanciesQueryFilterInput>;
  sort?: Maybe<Array<TenanciesSortParams>>;
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on
   * "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process
   * query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than
   * 10,000 hits. This limit is a safeguard set by the index.max_result_window
   * index setting. If you need to page through more than 10,000 hits, use the
   * search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type SearchTrustAccountWalletsQueryInput = {
  trustAccountOwnerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  query?: Maybe<Scalars["String"]>;
  isTenancyWallet?: Maybe<Scalars["Boolean"]>;
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on
   * "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process
   * query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than
   * 10,000 hits. This limit is a safeguard set by the index.max_result_window
   * index setting. If you need to page through more than 10,000 hits, use the
   * search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export enum SendEmailVerificationErrorCode {
  EmailAlreadyVerified = "EMAIL_ALREADY_VERIFIED",
  EmailUsedByAnotherPerson = "EMAIL_USED_BY_ANOTHER_PERSON",
}

export type SendEmailVerificationErrorResponse = {
  __typename?: "SendEmailVerificationErrorResponse";
  errorCode: SendEmailVerificationErrorCode;
};

export type SendEmailVerificationResponse =
  | SendEmailVerificationSuccessResponse
  | SendEmailVerificationErrorResponse;

export type SendEmailVerificationSuccessResponse = {
  __typename?: "SendEmailVerificationSuccessResponse";
  ok: Scalars["Boolean"];
};

export type SendMailInput = {
  sendFromMailboxAccessAilorn: Scalars["AiloRN"];
  toContactAilorns: Array<Scalars["AiloRN"]>;
  replyToMailThreadAilorn?: Maybe<Scalars["AiloRN"]>;
  subject: Scalars["String"];
  /** Plain text email body, will be formatted with generic styling before sending. */
  plaintextBody: Scalars["String"];
  attachmentFileAilorns: Array<Scalars["AiloRN"]>;
};

export type SendMessageInputV2 = {
  message: MessageInputV2;
  threadAilorn: Scalars["AiloRN"];
};

export type SetRoutineInspectionDueDateInput = {
  tenancyAilorn: Scalars["AiloRN"];
  dueDate: Scalars["Date"];
};

export type SetRoutineInspectionDueDateResult = {
  __typename?: "SetRoutineInspectionDueDateResult";
  tenancy: Tenancy;
};

export type SetupResult = {
  __typename?: "SetupResult";
  management?: Maybe<Management>;
  tenancy?: Maybe<Tenancy>;
};

export enum SigningStatus {
  /** The REI account is not linked with DocuSign yet */
  NotAvailable = "NOT_AVAILABLE",
  /** The form is waiting to be sent */
  Pending = "PENDING",
  /** The form has been sent to its signers */
  Sent = "SENT",
  /** All the signers have signed the form */
  Completed = "COMPLETED",
  /** One of the form signers has declined to sign */
  Declined = "DECLINED",
  /** REI returned an unknown status */
  Unknown = "UNKNOWN",
}

export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc",
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = "NULLS_FIRST",
  NullsLast = "NULLS_LAST",
}

export enum SourceType {
  Adjustment = "ADJUSTMENT",
  Payment = "PAYMENT",
  Planbased = "PLANBASED",
}

export type Statement = {
  __typename?: "Statement";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  file?: Maybe<File>;
  billsZipFile?: Maybe<File>;
  statementSubject: Scalars["AiloRN"];
  subject?: Maybe<StatementSubject>;
  type: StatementType;
  rangeStartDate: Scalars["Date"];
  rangeEndDate: Scalars["Date"];
  createdFor: StatementCreatedFor;
  statementValues?: Maybe<Scalars["String"]>;
  statementProgress?: Maybe<StatementProgress>;
};

export type StatementAdjustmentLineItem = StatementLineItem2 & {
  __typename?: "StatementAdjustmentLineItem";
  adjustmentAmount: Money;
  clearedDate?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  sourceId: Scalars["ID"];
  sourceType: StatementLineItemSourceType;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  walletId: Scalars["ID"];
};

export type StatementByAccountInput = {
  account: Scalars["AiloRN"];
  from: Scalars["Date"];
  to: Scalars["Date"];
  zoneId: Scalars["ZoneId"];
};

export enum StatementCreatedFor {
  ScheduledJob = "ScheduledJob",
  ManualRequest = "ManualRequest",
  Disbursement = "Disbursement",
}

export type StatementCursor = {
  pageSize?: Maybe<Scalars["Int"]>;
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type StatementFilter = {
  statementTypes?: Maybe<Array<StatementType>>;
  createdFor?: Maybe<Array<StatementCreatedFor>>;
};

export type StatementLineItem = {
  __typename?: "StatementLineItem";
  account: Scalars["AiloRN"];
  amount: Money;
  businessTransactionType?: Maybe<Scalars["String"]>;
  category: StatementLineItemCategoryType;
  description?: Maybe<Scalars["String"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  gst: Money;
  knowledgedDate: Scalars["Date"];
  liability: Liability;
  sourceType: StatementLineItemSourceType;
};

export type StatementLineItem2 = {
  clearedDate?: Maybe<Scalars["Date"]>;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  sourceId: Scalars["ID"];
  sourceType: StatementLineItemSourceType;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  walletId: Scalars["ID"];
};

export enum StatementLineItemCategoryType {
  Expense = "EXPENSE",
  Income = "INCOME",
}

export enum StatementLineItemSourceType {
  AdjustmentEntry = "AdjustmentEntry",
  StatementEntry = "StatementEntry",
}

export type StatementProgress = {
  __typename?: "StatementProgress";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  statement?: Maybe<Statement>;
  statementSubject?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<StatementType>;
  rangeStartDate?: Maybe<Scalars["Date"]>;
  rangeEndDate?: Maybe<Scalars["Date"]>;
  createdFor?: Maybe<StatementCreatedFor>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  isSuccessful?: Maybe<Scalars["Boolean"]>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  overrideStatementValues?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  attemptNo?: Maybe<Scalars["Int"]>;
  rangeStartBusinessTxId?: Maybe<Scalars["String"]>;
  rangeEndBusinessTxId?: Maybe<Scalars["String"]>;
  subject?: Maybe<StatementSubject>;
};

export type StatementProgressCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type StatementProgressFilter = {
  sendEmail?: Maybe<Scalars["Boolean"]>;
  statementSubject?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<StatementType>;
  types?: Maybe<Array<StatementType>>;
};

export enum StatementSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  RangeStartDateAsc = "RANGE_START_DATE_ASC",
  RangeStartDateDesc = "RANGE_START_DATE_DESC",
  RangeEndDateAsc = "RANGE_END_DATE_ASC",
  RangeEndDateDesc = "RANGE_END_DATE_DESC",
}

export type StatementSubject = LegalEntityCompanion | Management | Tenancy;

export type StatementTransactionLineItem = StatementLineItem2 & {
  __typename?: "StatementTransactionLineItem";
  btAmount: Money;
  btCreatedAt: Scalars["DateTime"];
  btStatusCreatedAt: Scalars["DateTime"];
  businessTransactionId: Scalars["ID"];
  businessTransactionStatus: Scalars["String"];
  businessTransactionType?: Maybe<Scalars["String"]>;
  clearedDate?: Maybe<Scalars["Date"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  gst: Money;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  parentBusinessTransactionId?: Maybe<Scalars["ID"]>;
  reversalBusinessTransactionType?: Maybe<Scalars["String"]>;
  reversalOfBusinessTransactionId?: Maybe<Scalars["ID"]>;
  serviceFeeAmount: Money;
  sourceId: Scalars["ID"];
  sourcePaymentMethodMaskedDetails?: Maybe<Scalars["String"]>;
  sourcePaymentMethodType?: Maybe<Scalars["String"]>;
  sourceType: StatementLineItemSourceType;
  sourceWalletId?: Maybe<Scalars["ID"]>;
  sourceWalletOwner?: Maybe<LegalEntityCompanion>;
  sourceWalletOwnerReference?: Maybe<Scalars["AiloRN"]>;
  sourceWalletType?: Maybe<StatementWalletType>;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  targetPaymentMethodMaskedDetails?: Maybe<Scalars["String"]>;
  targetPaymentMethodType?: Maybe<Scalars["String"]>;
  targetWalletId?: Maybe<Scalars["ID"]>;
  targetWalletOwner?: Maybe<LegalEntityCompanion>;
  targetWalletOwnerReference?: Maybe<Scalars["AiloRN"]>;
  targetWalletType?: Maybe<StatementWalletType>;
  taxCategory?: Maybe<Scalars["String"]>;
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletAvailableBalance: Money;
  walletChangeAmount: Money;
  walletId: Scalars["ID"];
  walletOverdraftAllowance: Money;
  walletStatementRunningBalance?: Maybe<Money>;
  walletTotalBalance: Money;
};

export enum StatementType {
  AgencyTransfer = "AgencyTransfer",
  Management = "Management",
  TaxSummary = "TaxSummary",
  TenancyLedger = "TenancyLedger",
  TransferSummary = "TransferSummary",
}

export enum StatementWalletType {
  AiloBank = "AILO_BANK",
  AiloTrading = "AILO_TRADING",
  AiloWriteOff = "AILO_WRITE_OFF",
  ManagementFolio = "MANAGEMENT_FOLIO",
  PaymentMethod = "PAYMENT_METHOD",
  Personal = "PERSONAL",
  Tenancy = "TENANCY",
  Unknown = "UNKNOWN",
}

export type StatusComparison = {
  eq?: Maybe<ProjectStatus>;
};

export type SubscriptionEndDateInput = {
  subscriptionId: Scalars["ID"];
  endsOn?: Maybe<Scalars["Date"]>;
};

export type Supplier = {
  __typename?: "Supplier";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  abn?: Maybe<Scalars["String"]>;
  /**
   * String containing entire address. Will be removed in the future after migrating
   * this field to the new address fields.
   */
  businessAddress?: Maybe<Scalars["String"]>;
  address?: Maybe<SupplierAddress>;
  emailAddress?: Maybe<Scalars["String"]>;
  organisationReference: Scalars["AiloRN"];
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  /**
   * If null it means the bill has been created by Ailo system.
   * @deprecated Use `Supplier.createdByV2` instead
   */
  createdBy?: Maybe<User>;
  createdByV2?: Maybe<ActionInitiator>;
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<ActionInitiator>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `Supplier.archivedByV2` instead */
  archivedBy?: Maybe<Scalars["AiloRN"]>;
  archivedByV2?: Maybe<ActionInitiator>;
  /** @deprecated Use `Bill.payee` instead */
  internalReference?: Maybe<Scalars["AiloRN"]>;
  /** @deprecated Use `Bill.payee` instead */
  internalLegalEntity?: Maybe<LegalEntityCompanion>;
  paymentMethods: Array<Maybe<PaymentMethodCompanion>>;
  kycDataComplete: Scalars["Boolean"];
};

export type SupplierAddress = {
  __typename?: "SupplierAddress";
  streetName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type SupplierAddressInput = {
  streetName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type SupplierBankAccountInput = {
  description?: Maybe<Scalars["String"]>;
  bsb: Scalars["String"];
  accountNumber: Scalars["String"];
  accountName: Scalars["String"];
  onceOff?: Maybe<Scalars["Boolean"]>;
};

export type SupplierBPayInput = {
  description?: Maybe<Scalars["String"]>;
  billerCode: Scalars["String"];
};

export type SupplierCreditCardInputV2 = {
  description?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  type: Scalars["String"];
  category: Scalars["String"];
  number: Scalars["String"];
  expiry: Scalars["String"];
};

export type SupplierPaymentMethodInput = {
  creditCardInput?: Maybe<SupplierCreditCardInputV2>;
  bankAccountInput?: Maybe<SupplierBankAccountInput>;
  bpayInput?: Maybe<SupplierBPayInput>;
};

export type SuppliersQueryInput = {
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  registeredEntityNameLike?: Maybe<Scalars["String"]>;
  nameLike?: Maybe<Scalars["String"]>;
  abnLike?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  kycDataComplete?: Maybe<Scalars["Boolean"]>;
  organisationReference?: Maybe<Scalars["AiloRN"]>;
};

export type SyncLegalEntityInput = {
  legalEntityAilorn: Scalars["AiloRN"];
};

export type System = {
  __typename?: "System";
  ailoRN: Scalars["AiloRN"];
};

export type TaxableAmount = {
  __typename?: "TaxableAmount";
  tax: Money;
  total: Money;
};

export type TaxCategory = {
  __typename?: "TaxCategory";
  id: Scalars["String"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export type TaxSummaryEntry = {
  __typename?: "TaxSummaryEntry";
  taxCategory: Scalars["String"];
  totalAiloFees: Money;
  totalAmount: Money;
  totalGST: Money;
};

export type TaxSummaryStatementData = {
  __typename?: "TaxSummaryStatementData";
  expenseTransactions: Array<TaxSummaryTransactionEntry>;
  expenses: Array<TaxSummaryEntry>;
  income: Array<TaxSummaryEntry>;
  incomeTransactions: Array<TaxSummaryTransactionEntry>;
};

export type TaxSummaryStatementInput = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
  walletOwnerReference: Scalars["AiloRN"];
  zoneId: Scalars["ZoneId"];
};

export type TaxSummaryTransactionEntry = {
  __typename?: "TaxSummaryTransactionEntry";
  amount: Money;
  bill?: Maybe<Bill>;
  feeAmount: Money;
  gstAmount: Money;
  liabilityReference?: Maybe<Scalars["AiloRN"]>;
  taxCategory: Scalars["String"];
  transactionClearedAt?: Maybe<Scalars["DateTime"]>;
  transactionCreatedAt: Scalars["DateTime"];
};

export enum TaxTreatment {
  Inclusive = "inclusive",
  Exclusive = "exclusive",
  NoTax = "noTax",
}

export type Team = {
  __typename?: "Team";
  id: Scalars["AiloRN"];
  name: Scalars["String"];
  organisation?: Maybe<Organisation>;
  members: Array<Maybe<Person>>;
  managements: PaginatedManagements;
  contacts: Array<Contact>;
};

export type TeamManagementsArgs = {
  pageCursor: PmPageCursor;
  filter?: Array<ManagementFilterParams>;
};

export type TeamFilterInput = {
  ailornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
};

export type TenanciesQueryFilterInput = {
  query?: Maybe<Scalars["String"]>;
  team?: Maybe<TeamFilterInput>;
  managingEntityOrgAilorn?: Maybe<Scalars["AiloRN"]>;
  excludeVoided?: Maybe<Scalars["Boolean"]>;
  excludeVacated?: Maybe<Scalars["Boolean"]>;
  excludeWithPendingRoutineAppointment?: Maybe<Scalars["Boolean"]>;
  excludeOffboarded?: Maybe<Scalars["Boolean"]>;
};

export enum TenanciesSortField {
  RoutineInspectionDueDate = "RoutineInspectionDueDate",
}

export type TenanciesSortParams = {
  field: TenanciesSortField;
  direction: SortDirection;
};

export type Tenancy = {
  __typename?: "Tenancy";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  voidedAt?: Maybe<Scalars["Date"]>;
  vacatingReason?: Maybe<VacatingReason>;
  vacatingNotes?: Maybe<Scalars["String"]>;
  canCancelVacate?: Maybe<CancelVacateAbility>;
  isVoidable: VoidTenancyAbility;
  /** Date which the another rent review should be performed */
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  validVacateEndDateRange?: Maybe<DateRange>;
  /** @deprecated Use `leaseReviewAllowedOperation` instead */
  leaseReviewAllowed?: Maybe<Scalars["Boolean"]>;
  leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
  agencyProperty: AgencyProperty;
  /**
   * returns the first to exist of currentRent, nextRent, mostRecentRent
   * in that order. Represents the rent users are most likely
   * interested in.
   */
  displayRent?: Maybe<Rent>;
  currentRent?: Maybe<Rent>;
  nextRent?: Maybe<Rent>;
  nextRentReview?: Maybe<Rent>;
  mostRecentRentReview?: Maybe<Rent>;
  rents: PaginatedRents;
  property: Property;
  currentRentSchedule?: Maybe<RentSchedule>;
  nextRentSchedule?: Maybe<RentSchedule>;
  proRataSchedulesForNewRent: Array<PreviewedRentSchedule>;
  tenancyAgreements?: Maybe<PaginatedTenancyAgreements>;
  mostRecentTenancyAgreement?: Maybe<TenancyAgreement>;
  nextTenancyAgreement?: Maybe<TenancyAgreement>;
  latestTenancyAgreement?: Maybe<TenancyAgreement>;
  rentReviewAllowed?: Maybe<Scalars["Boolean"]>;
  editableRentReview?: Maybe<EditableRentReviewPayload>;
  bond?: Maybe<TenancyBond>;
  files?: Maybe<PaginatedFiles>;
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  management?: Maybe<Management>;
  tenants?: Maybe<Array<Maybe<Tenantship>>>;
  liability?: Maybe<Liability>;
  liabilityReport?: Maybe<LiabilityReport>;
  isDraft?: Maybe<Scalars["Boolean"]>;
  deposit?: Maybe<TenancyDeposit>;
  rent?: Maybe<RecurringOwing>;
  inspections: Array<Inspection>;
  inspectionAppointments: Array<InspectionAppointment>;
  lastCompletedRoutineInspection?: Maybe<Inspection>;
  routineInspectionDueDate?: Maybe<Scalars["Date"]>;
  tenantContacts: Array<Contact>;
  billsAsPayer?: Maybe<PaginatedBills>;
};

export type TenancyRentsArgs = {
  cursor?: RentPageCursor;
  sort?: Maybe<Array<RentSort>>;
};

export type TenancyProRataSchedulesForNewRentArgs = {
  proposedRent: ProposedRent;
};

export type TenancyFilesArgs = {
  pageCursor?: PaginationParams;
};

export type TenancyBillsAsPayerArgs = {
  id?: Maybe<Array<Scalars["AiloRN"]>>;
  organisationId?: Maybe<Array<Scalars["AiloRN"]>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
  taxCategoryId?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<BillStatus>>;
  paid?: Maybe<Scalars["Boolean"]>;
  payByDate?: Maybe<LocalDateRangeCondition>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<PageCursorWithoutSort>;
  excludeBillsWithEmptyPaymentStatus?: Maybe<Scalars["Boolean"]>;
};

export type TenancyAgreement = {
  __typename?: "TenancyAgreement";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  startDate?: Maybe<Scalars["Date"]>;
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
  /**
   * Represents the date when the user made the decision to allow the current lease to lapse.
   * This is NOT the date which the tenancy is allowed to go into periodic from.
   */
  allowedToLapseAt?: Maybe<Scalars["DateTime"]>;
};

export type TenancyAgreementDetails = {
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type TenancyAgreementSetupInput = {
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type TenancyBond = {
  __typename?: "TenancyBond";
  id: Scalars["ID"];
  amount: Money;
  /** @deprecated use 'amount' */
  amountInCents?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  status: TenancyBondStatus;
  failureReason?: Maybe<BondDisbursalFailureReason>;
  fundsReceived?: Maybe<Array<FundReceived>>;
  claims?: Maybe<Array<TenancyBondClaim>>;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
};

export type TenancyBondAccount = {
  __typename?: "TenancyBondAccount";
  id: Scalars["ID"];
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
};

export type TenancyBondClaim = {
  __typename?: "TenancyBondClaim";
  id: Scalars["ID"];
  amount: Money;
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
  claimStatus: TenancyBondClaimStatus;
};

export enum TenancyBondClaimStatus {
  Unpaid = "Unpaid",
  Pending = "Pending",
  Paid = "Paid",
}

export type TenancyBondSetupInput = {
  amountInCents: Scalars["String"];
  reference: Scalars["String"];
};

export enum TenancyBondStatus {
  Unclaimed = "Unclaimed",
  AwaitingFunds = "AwaitingFunds",
  FundsReceived = "FundsReceived",
  Disbursing = "Disbursing",
  ClaimSuccessful = "ClaimSuccessful",
  DisbursementFailed = "DisbursementFailed",
  BondResolved = "BondResolved",
}

export type TenancyCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type TenancyDeposit = {
  __typename?: "TenancyDeposit";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  amount: Money;
  releasedAt?: Maybe<Scalars["DateTime"]>;
  releasedAmount: Money;
  releasedBy?: Maybe<Scalars["AiloRN"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status: TenancyDepositStatus;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  liability?: Maybe<Liability>;
};

export type TenancyDepositSetupInput = {
  amount: MoneyInput;
};

export enum TenancyDepositStatus {
  Void = "VOID",
  New = "NEW",
  Due = "DUE",
  Pending = "PENDING",
  Paid = "PAID",
  Released = "RELEASED",
  Failed = "FAILED",
}

export type TenancyLedgerLineItem = {
  __typename?: "TenancyLedgerLineItem";
  amount: Money;
  businessTransaction?: Maybe<BusinessTransaction>;
  displayDateTime: Scalars["DateTime"];
  isFailOrVoid: Scalars["Boolean"];
  liability: Liability;
  liabilityEntryDateTime: Scalars["DateTime"];
  paidToDateDetails?: Maybe<PaidToDateDetails>;
  type: TenancyLedgerLineItemType;
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type TenancyLedgerLineItemsInput = {
  tenancy: Scalars["AiloRN"];
};

export enum TenancyLedgerLineItemType {
  Adjustment = "ADJUSTMENT",
  Bill = "BILL",
  Rent = "RENT",
}

export type TenancyPropertyAddress = ContactPropertyAddress & {
  __typename?: "TenancyPropertyAddress";
  managementAilorn: Scalars["AiloRN"];
  managementEndDate?: Maybe<Scalars["Date"]>;
  tenancyAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  voidedAt?: Maybe<Scalars["Date"]>;
};

export type TenancySearchResult = {
  __typename?: "TenancySearchResult";
  id: Scalars["ID"];
  tenancy: Tenancy;
};

export type TenancySetupInput = {
  startDate: Scalars["Date"];
  endDate?: Maybe<Scalars["Date"]>;
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  tenants: Array<TenantshipSetupInput>;
  tenancyAgreement: TenancyAgreementSetupInput;
  rents?: Maybe<Array<RentSetupInput>>;
  bond?: Maybe<TenancyBondSetupInput>;
  deposit?: Maybe<TenancyDepositSetupInput>;
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type Tenantship = {
  __typename?: "Tenantship";
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
  tenant?: Maybe<LegalEntity>;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  consumerInvite?: Maybe<ConsumerInvite>;
};

export type TenantshipSetupInput = {
  tenantId?: Maybe<Scalars["ID"]>;
  consumer?: Maybe<ConsumerSetupInput>;
};

export enum ThreadDeliveryMechanism {
  Chat = "Chat",
  Email = "Email",
}

export type ThreadInputV2 = {
  message: MessageInputV2;
  subject: Scalars["String"];
  /**
   * If "Email" is selected as one of the delivery mechanisms, then
   * all messages sent in this thread (including the initial message)
   * must also have a "sendEmailFromMailboxAccessAilorn" set in MessageInputV2
   */
  deliveryMechanisms?: Maybe<Array<ThreadDeliveryMechanism>>;
  /**
   * Entities in Ailo that this thread is related to. Currently supported relations are:
   *  - project:action
   *  - project:project
   *  - propertymanagement:management
   */
  relatedToAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type ThreadParticipant = {
  __typename?: "ThreadParticipant";
  contact: Contact;
  joinedAt: Scalars["DateTime"];
  isAdditionalParticipant: Scalars["Boolean"];
};

export type ThreadRelatedEntity = Action | Project | Management;

export type ThreadV2 = {
  __typename?: "ThreadV2";
  ailorn: Scalars["AiloRN"];
  subject: Scalars["String"];
  participants: Array<Contact>;
  threadParticipants: Array<ThreadParticipant>;
  deliveryMechanisms: Array<ThreadDeliveryMechanism>;
  firstMessage: MessageV2;
  mostRecentMessage: MessageV2;
  oldestUnreadMessage?: Maybe<MessageV2>;
  /** This does not include the first message in the thread. */
  replies: PaginatedMessagesV2;
  /** This does not count the first message in the thread. */
  numberOfReplies: Scalars["Int"];
  chat: ChatV2;
  relatedTo: Array<Maybe<ThreadRelatedEntity>>;
  unreadMessagesCount: Scalars["Int"];
};

export type ThreadV2RepliesArgs = {
  pagination: PaginationInputV2;
};

export enum ThumbnailStatus {
  Generating = "Generating",
  Generated = "Generated",
  None = "None",
}

export type TotalClearedRentReceived = {
  __typename?: "TotalClearedRentReceived";
  count: Scalars["Int"];
};

export type TrackDocusignActionMeta = {
  __typename?: "TrackDocusignActionMeta";
  form?: Maybe<Form>;
};

export type TrackInspectionCompletedActionMeta = {
  __typename?: "TrackInspectionCompletedActionMeta";
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export type Transaction = {
  __typename?: "Transaction";
  allocations: Array<Maybe<TransactionAllocation>>;
  auditNumber?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["AiloRN"]>;
  management?: Maybe<Management>;
  referenceNumber?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TransactionAllocation = {
  __typename?: "TransactionAllocation";
  amount?: Maybe<Money>;
  description?: Maybe<Scalars["String"]>;
};

export type TransactionDetail = {
  __typename?: "TransactionDetail";
  paymentId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  bankingSettlementDate?: Maybe<Scalars["DateTime"]>;
  paymentMethod?: Maybe<Scalars["String"]>;
  transactionType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  totalAmount?: Maybe<Money>;
};

export type TransactionDetails = {
  __typename?: "TransactionDetails";
  createdAt: Scalars["DateTime"];
  feeAmount: Money;
  idempotentKey?: Maybe<Scalars["String"]>;
  liability?: Maybe<Liability>;
  message?: Maybe<Scalars["String"]>;
  payer?: Maybe<Scalars["AiloRN"]>;
  paymentChannel?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReferenceId?: Maybe<Scalars["String"]>;
  paymentRequestDescription?: Maybe<Scalars["String"]>;
  requestedAmount: Money;
  transactionAmount: Money;
  transactionRef: Scalars["AiloRN"];
  transactionStatus: TransactionStatus;
  type?: Maybe<Scalars["String"]>;
};

export type TransactionFee = {
  __typename?: "TransactionFee";
  feeBps: Scalars["Int"];
  feeFlatCents: Scalars["Int"];
  waived?: Maybe<Scalars["Boolean"]>;
  waivedTo?: Maybe<Scalars["Date"]>;
};

export type TransactionReportItem = {
  __typename?: "TransactionReportItem";
  ailoFeeAmount?: Maybe<Money>;
  bankSettlementDate?: Maybe<Scalars["DateTime"]>;
  bankingChannel?: Maybe<Scalars["String"]>;
  billType?: Maybe<Scalars["String"]>;
  businessTransactionId: Scalars["AiloRN"];
  channel?: Maybe<Scalars["String"]>;
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  gatewayPaymentId?: Maybe<Scalars["String"]>;
  /** @deprecated Use `businessTransactionId` or `paymentTransactionId` instead */
  id?: Maybe<Scalars["AiloRN"]>;
  methodType?: Maybe<Scalars["String"]>;
  originalPaymentAmount?: Maybe<Money>;
  payeeId?: Maybe<Scalars["AiloRN"]>;
  payerId?: Maybe<Scalars["AiloRN"]>;
  paymentTransactionId?: Maybe<Scalars["AiloRN"]>;
  paymentTransactionType?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<Scalars["String"]>;
  processOrSettlementDate?: Maybe<Scalars["DateTime"]>;
  product?: Maybe<Scalars["String"]>;
  responseCode?: Maybe<Scalars["String"]>;
  reversalType?: Maybe<Scalars["String"]>;
  reversedBusinessTransactionId?: Maybe<Scalars["AiloRN"]>;
  reversedPaymentTransaction?: Maybe<Scalars["AiloRN"]>;
  status?: Maybe<Scalars["String"]>;
  taxCategory?: Maybe<Scalars["String"]>;
  totalPaymentAmount?: Maybe<Money>;
  transactionType?: Maybe<Scalars["String"]>;
};

export enum TransactionsSortField {
  AuditNumber = "AUDIT_NUMBER",
}

export type TransactionStatus = {
  __typename?: "transactionStatus";
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  status: BusinessTxStatusEnum;
};

export type TransferSummaryStatementInput = {
  /** Transfer business tx id the statement data starts from exclusive */
  from?: Maybe<Scalars["ID"]>;
  /** Transfer business tx id the statement data is up to inclusive */
  to: Scalars["ID"];
  walletOwnerReference: Scalars["AiloRN"];
};

export type TransferSummaryStatementRange = {
  __typename?: "TransferSummaryStatementRange";
  fromBusinessTxId?: Maybe<Scalars["ID"]>;
  fromDateTime: Scalars["DateTime"];
  toBusinessTxId: Scalars["ID"];
  toDateTime: Scalars["DateTime"];
};

export type TransferSummaryStatementRangeInput = {
  /** Business transaction id of the disbursement. */
  businessTxId: Scalars["ID"];
  /** Wallet owner of wallet being disbursed from. */
  walletOwnerReference: Scalars["AiloRN"];
};

export type TransferToWalletInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  fromWalletId: Scalars["ID"];
  toWalletId: Scalars["ID"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type TrustAccount = {
  __typename?: "TrustAccount";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  ownerLegalEntityAilorn: Scalars["AiloRN"];
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
  ownerLegalEntity: Company;
};

export type TrustAccountAggregateGroupBy = {
  __typename?: "TrustAccountAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountConnection = {
  __typename?: "TrustAccountConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountEdge>;
};

export type TrustAccountCountAggregate = {
  __typename?: "TrustAccountCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["Int"]>;
};

export type TrustAccountEdge = {
  __typename?: "TrustAccountEdge";
  /** The node containing the TrustAccount */
  node: TrustAccount;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountFilter = {
  and?: Maybe<Array<TrustAccountFilter>>;
  or?: Maybe<Array<TrustAccountFilter>>;
  id?: Maybe<IdFilterComparison>;
  ownerLegalEntityAilorn?: Maybe<AiloRnFilterComparison>;
};

export type TrustAccountLock = {
  __typename?: "TrustAccountLock";
  lockedUpTo: Scalars["DateTime"];
  status: TrustAccountLockStatus;
  trustAccountAilorn: Scalars["AiloRN"];
};

export enum TrustAccountLockStatus {
  Confirmed = "CONFIRMED",
  Unconfirmed = "UNCONFIRMED",
}

export type TrustAccountMaxAggregate = {
  __typename?: "TrustAccountMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountMinAggregate = {
  __typename?: "TrustAccountMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountReconciliationAdjustment = {
  __typename?: "TrustAccountReconciliationAdjustment";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["Long"];
  applyOn: Scalars["LocalDate"];
  details: Scalars["String"];
  reportPeriodId: Scalars["ID"];
  removedReason?: Maybe<Scalars["String"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
  /** @deprecated Use removedFrom */
  removedAt?: Maybe<Scalars["DateTime"]>;
  reportPeriod: ReconciliationReportPeriod;
};

export type TrustAccountReconciliationAdjustmentAggregateGroupBy = {
  __typename?: "TrustAccountReconciliationAdjustmentAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentAvgAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentAvgAggregate";
  amountInCents?: Maybe<Scalars["Float"]>;
};

export type TrustAccountReconciliationAdjustmentConnection = {
  __typename?: "TrustAccountReconciliationAdjustmentConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountReconciliationAdjustmentEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type TrustAccountReconciliationAdjustmentCountAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  amountInCents?: Maybe<Scalars["Int"]>;
  applyOn?: Maybe<Scalars["Int"]>;
  reportPeriodId?: Maybe<Scalars["Int"]>;
  removedFrom?: Maybe<Scalars["Int"]>;
};

export type TrustAccountReconciliationAdjustmentEdge = {
  __typename?: "TrustAccountReconciliationAdjustmentEdge";
  /** The node containing the TrustAccountReconciliationAdjustment */
  node: TrustAccountReconciliationAdjustment;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountReconciliationAdjustmentFilter = {
  and?: Maybe<Array<TrustAccountReconciliationAdjustmentFilter>>;
  or?: Maybe<Array<TrustAccountReconciliationAdjustmentFilter>>;
  id?: Maybe<IdFilterComparison>;
  amountInCents?: Maybe<LongFilterComparison>;
  applyOn?: Maybe<LocalDateFilterComparison>;
  reportPeriodId?: Maybe<IdFilterComparison>;
  removedFrom?: Maybe<LocalDateFilterComparison>;
  reportPeriod?: Maybe<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>;
};

export type TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter = {
  and?: Maybe<
    Array<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>
  >;
  or?: Maybe<
    Array<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>
  >;
  id?: Maybe<IdFilterComparison>;
  month?: Maybe<IntFieldComparison>;
  year?: Maybe<IntFieldComparison>;
  locked?: Maybe<BooleanFieldComparison>;
  trustAccountAilorn?: Maybe<GraphQlAilornFilterComparison>;
};

export type TrustAccountReconciliationAdjustmentMaxAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentMinAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentSort = {
  field: TrustAccountReconciliationAdjustmentSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountReconciliationAdjustmentSortFields {
  Id = "id",
  AmountInCents = "amountInCents",
  ApplyOn = "applyOn",
  ReportPeriodId = "reportPeriodId",
  RemovedFrom = "removedFrom",
}

export type TrustAccountReconciliationAdjustmentSumAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentSumAggregate";
  amountInCents?: Maybe<Scalars["Float"]>;
};

export type TrustAccountSort = {
  field: TrustAccountSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountSortFields {
  Id = "id",
  OwnerLegalEntityAilorn = "ownerLegalEntityAilorn",
}

export type TrustAccountStatementBalance = {
  __typename?: "TrustAccountStatementBalance";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
  trustAccountAilorn: Scalars["ID"];
  relatedReportPeriods: Array<ReconciliationReportPeriod>;
};

export type TrustAccountStatementBalanceRelatedReportPeriodsArgs = {
  filter?: Maybe<ReconciliationReportPeriodFilter>;
  sorting?: Maybe<Array<ReconciliationReportPeriodSort>>;
};

export type TrustAccountWallet = {
  __typename?: "TrustAccountWallet";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  trustAccount: TrustAccount;
  trustAccountId: Scalars["ID"];
  entityReference: Scalars["AiloRN"];
  type: TrustAccountWalletType;
  code: Scalars["String"];
  entity: TrustAccountWalletEntity;
};

export type TrustAccountWalletAggregateGroupBy = {
  __typename?: "TrustAccountWalletAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletConnection = {
  __typename?: "TrustAccountWalletConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountWalletEdge>;
};

export type TrustAccountWalletCountAggregate = {
  __typename?: "TrustAccountWalletCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  trustAccountId?: Maybe<Scalars["Int"]>;
  entityReference?: Maybe<Scalars["Int"]>;
};

export type TrustAccountWalletEdge = {
  __typename?: "TrustAccountWalletEdge";
  /** The node containing the TrustAccountWallet */
  node: TrustAccountWallet;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountWalletEntity =
  | LegalEntityCompanion
  | ManagementFolio
  | Tenancy
  | Supplier
  | BondAuthority;

export type TrustAccountWalletFilter = {
  and?: Maybe<Array<TrustAccountWalletFilter>>;
  or?: Maybe<Array<TrustAccountWalletFilter>>;
  id?: Maybe<IdFilterComparison>;
  trustAccountId?: Maybe<IdFilterComparison>;
  entityReference?: Maybe<AiloRnFilterComparison>;
};

export type TrustAccountWalletMaxAggregate = {
  __typename?: "TrustAccountWalletMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletMinAggregate = {
  __typename?: "TrustAccountWalletMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletSort = {
  field: TrustAccountWalletSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountWalletSortFields {
  Id = "id",
  TrustAccountId = "trustAccountId",
  EntityReference = "entityReference",
}

export enum TrustAccountWalletType {
  ManagementFolio = "MANAGEMENT_FOLIO",
  Tenancy = "TENANCY",
  Agency = "AGENCY",
  BondAuthority = "BOND_AUTHORITY",
  Supplier = "SUPPLIER",
  Investor = "INVESTOR",
  Renter = "RENTER",
}

export type TrustBankAccount = {
  __typename?: "TrustBankAccount";
  agency: Scalars["AiloRN"];
  paymentMethod: PaymentMethodCompanion;
  createdBy: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
};

export type TrustReceipt = {
  __typename?: "TrustReceipt";
  id: Scalars["ID"];
};

export type TrustReceiptAggregateGroupBy = {
  __typename?: "TrustReceiptAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
};

export type TrustReceiptCountAggregate = {
  __typename?: "TrustReceiptCountAggregate";
  id?: Maybe<Scalars["Int"]>;
};

export type TrustReceiptEdge = {
  __typename?: "TrustReceiptEdge";
  /** The node containing the TrustReceipt */
  node: TrustReceipt;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustReceiptMaxAggregate = {
  __typename?: "TrustReceiptMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type TrustReceiptMinAggregate = {
  __typename?: "TrustReceiptMinAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type UnacknowledgeThreadFromMessageInput = {
  messageAilorn: Scalars["AiloRN"];
};

export type UnlockReportPeriodInput = {
  month: Scalars["Int"];
  year: Scalars["Int"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type UnpersistedChargeCycle = {
  __typename?: "UnpersistedChargeCycle";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type UnreadMessagesCountInput = {
  organisationAilorn: Scalars["AiloRN"];
};

export enum UnvoidableTenancyReason {
  TenancyIsNotIngoing = "TenancyIsNotIngoing",
  DepositHasBeenPaid = "DepositHasBeenPaid",
}

export type UpdateActionInput = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  assigneeAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<ActionType>;
  dueDate?: Maybe<Scalars["DateTime"]>;
  meta?: Maybe<Scalars["JSONObject"]>;
};

export type UpdateAiloFormInput = {
  name?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  updateResponses?: Maybe<Array<UpdateAiloFormResponseInput>>;
  /** creates or overwrites responses for specific fields */
  createResponses?: Maybe<Array<CreateAiloFormCreateAiloFormResponseInput>>;
  deleteResponseForFields?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateAiloFormResponseInput = {
  payload: AiloFormResponsePayloadInput;
  id: Scalars["ID"];
};

export type UpdateAiloFormResult = {
  __typename?: "UpdateAiloFormResult";
  form: AiloForm;
};

export type UpdateAutoWithdrawPlanInputV2 = {
  autoWithdrawPlanId: Scalars["ID"];
  paymentMethodDestinations: Array<PaymentMethodDestinationInput>;
  setAsideAmount?: Maybe<MoneyInput>;
};

export type UpdateBondInput = {
  tenancyAilorn: Scalars["AiloRN"];
  reference?: Maybe<Scalars["String"]>;
  amount?: Maybe<MoneyInput>;
  bondAccountAilorn?: Maybe<Scalars["AiloRN"]>;
  claimedOn?: Maybe<Scalars["Date"]>;
};

export type UpdateCompanyProfileDetailsInput = {
  id: Scalars["AiloRN"];
  registeredEntityId?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["TimeZone"]>;
  accountsEmail?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateFeeBlueprintInput = {
  id: Scalars["ID"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  taxCategoryId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  event?: Maybe<FeeEventType>;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  taxTreatment?: Maybe<TaxTreatment>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateFeeInput = {
  id: Scalars["String"];
  amount?: Maybe<MoneyInput>;
  taxAmount?: Maybe<MoneyInput>;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<MoneyInput>;
  description?: Maybe<Scalars["String"]>;
  /** Must be either `true` or `null` */
  archived?: Maybe<Scalars["Boolean"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateInspectionAppointmentInput = {
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
};

export type UpdateInspectionAppointmentResult = {
  __typename?: "UpdateInspectionAppointmentResult";
  inspectionAppointment: InspectionAppointment;
};

export type UpdateInspectionAreaInput = {
  index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  inspected?: Maybe<Scalars["Boolean"]>;
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  id: Scalars["ID"];
  createFiles?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  removeFiles?: Maybe<Array<Scalars["ID"]>>;
  updateFeatures?: Maybe<Array<UpdateInspectionFeatureInput>>;
  createFeatures?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
  removeFeatures?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionAreaResult = {
  __typename?: "UpdateInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type UpdateInspectionFeatureInput = {
  name?: Maybe<Scalars["String"]>;
  index?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  createFiles?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
  removeFiles?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionFeatureResult = {
  __typename?: "UpdateInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type UpdateInspectionInput = {
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  ailoFormAilorn?: Maybe<Scalars["AiloRN"]>;
  id: Scalars["ID"];
  updateAreas?: Maybe<Array<UpdateInspectionAreaInput>>;
  createAreas?: Maybe<Array<CreateInspectionInputAreaInput>>;
  removeAreas?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionResult = {
  __typename?: "UpdateInspectionResult";
  inspection: Inspection;
};

export type UpdateKeyInput = {
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type UpdateLiabilityPaymentPlanInput = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodId: Scalars["ID"];
};

export type UpdateManagementAgreementInput = {
  id: Scalars["ID"];
  startDate?: Maybe<Scalars["String"]>;
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
};

export type UpdateManagementAgreementPayload = {
  __typename?: "UpdateManagementAgreementPayload";
  managementAgreement?: Maybe<ManagementAgreement>;
};

export type UpdateManagementFeeBlueprintInput = {
  id: Scalars["ID"];
  archived?: Maybe<Scalars["Boolean"]>;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  taxTreatment?: Maybe<TaxTreatment>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateManagementFeeInput = {
  managementAgreementAiloRN: Scalars["AiloRN"];
  percent: Scalars["Float"];
};

export type UpdateMigratingManagementInput = {
  id: Scalars["ID"];
  body: Scalars["String"];
};

export type UpdateOneKeyInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateKeyInput;
};

export type UpdateOneTrustAccountInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateTrustAccountInput;
};

export type UpdatePersonProfileDetailsInput = {
  id: Scalars["AiloRN"];
  legalFirstName?: Maybe<Scalars["String"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  photoFileId?: Maybe<Scalars["AiloRN"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type UpdateProjectInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  assigneeAilorn: Scalars["AiloRN"];
  type: ProjectType;
  dueDate: Scalars["Date"];
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type UpdatePropertyInput = {
  id: Scalars["ID"];
  address: PropertyInput;
};

export type UpdateRecurringFeeBlueprintInput = {
  id: Scalars["AiloRN"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  taxCategoryId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment?: Maybe<TaxTreatment>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateRecurringFeeInput = {
  id: Scalars["AiloRN"];
  /**
   * If provided, must contain at least one schedule.
   * Replaces any existing future schedules with the provided list.
   */
  futureSchedules?: Maybe<Array<RecurringFeeScheduleInput>>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateReiTokenInput = {
  /** REI Token */
  token?: Maybe<Scalars["String"]>;
  /** State the REI account was linked to */
  supportedAustralianState?: Maybe<AustralianState>;
  /** AiloRN of person the token is associated with */
  personAilorn?: Maybe<Scalars["AiloRN"]>;
  /** AiloRN of agency organisation */
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
  id: Scalars["ID"];
};

export type UpdateSupplierInput = {
  id: Scalars["ID"];
  abn?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<SupplierAddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<SupplierPaymentMethodInput>;
  replaceExistingDueBillsPaymentMethod?: Maybe<Scalars["Boolean"]>;
};

export type UpdateTenancyAgreementInput = {
  id: Scalars["ID"];
  startDate: Scalars["LocalDate"];
  /** If not provided, the mutation will remove the existing `fixedTermEndDate` */
  fixedTermEndDate?: Maybe<Scalars["LocalDate"]>;
};

export type UpdateTenancyAgreementPayload = {
  __typename?: "UpdateTenancyAgreementPayload";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export type UpdateTrustAccountInput = {
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
};

export type UploadMigrationCsvInput = {
  legalEntityId: Scalars["AiloRN"];
  data: Scalars["String"];
  batchRef: Scalars["String"];
};

export type UpsertAutoPayLiabilityStatusInput = {
  /**
   * Maximum payable amount that auto pay will cover, default to be 40k and
   * configurable via env var: ledger.auto-pay-liability.maximum-payment-amount
   */
  maximumPaymentAmount?: Maybe<MoneyInput>;
  /**
   * Legal Entity ARN (Person/Company) if it is meant to pay liabilities using their "personal" wallet,
   * or Management folio ARN, if it's meant to pay liabilities using management folio wallet.
   */
  payerId?: Maybe<Scalars["AiloRN"]>;
  /** Current user legal entity ID. Will get deprecated soon, once we are able to get user person ARN from new authz token. */
  payerLegalEntity: Scalars["AiloRN"];
  paymentMethodId?: Maybe<Scalars["ID"]>;
};

export type UpsertCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type UpsertCrnOutput = {
  __typename?: "UpsertCrnOutput";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type UpsertTenancyBondClaim = {
  id?: Maybe<Scalars["ID"]>;
  amount: MoneyInput;
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
  claimStatus?: Maybe<TenancyBondClaimStatus>;
};

export type UpsertTenancyBondClaims = {
  bondId: Scalars["ID"];
  claims: Array<UpsertTenancyBondClaim>;
};

export type User = {
  __typename?: "User";
  id: Scalars["Int"];
  ailoRN: Scalars["AiloRN"];
  auth0Id?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  onboardedAt?: Maybe<Scalars["DateTime"]>;
  onboardingCompletedAt?: Maybe<Scalars["DateTime"]>;
  person: Person;
  organisations: Array<Organisation>;
  onboardingTasks: Array<UserOnboardingTask>;
  /** Returns true if the user has a role (via person) in any org with type "Agency". */
  isAgent?: Maybe<Scalars["Boolean"]>;
};

export type UserOrganisationsArgs = {
  filter?: Maybe<OrganisationsFilter>;
};

export type UserEmailVerification = {
  __typename?: "UserEmailVerification";
  id: Scalars["AiloRN"];
  user: User;
  emailAddress: Scalars["String"];
  verificationCode: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export type UserNotificationPreferenceInput = {
  userId: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export type UserNotificationPreferenceInputV2 = {
  userId: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
  channel?: Maybe<Channel>;
  communicationType: CommunicationType;
};

export type UserOnboardingTask = {
  __typename?: "UserOnboardingTask";
  /** @deprecated Use `onboardingTask.id` */
  onboardingTaskId: OnboardingTaskId;
  onboardingTask: OnboardingTask;
  completedAt: Scalars["DateTime"];
};

export type UserPreference = {
  __typename?: "UserPreference";
  userId: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
  channel?: Maybe<Channel>;
  communicationType?: Maybe<CommunicationType>;
};

export type UserServiceInput = {
  metadata: Scalars["String"];
};

export enum VacatingReason {
  WithinTermsOfAgreement = "WithinTermsOfAgreement",
  LeaseBreak = "LeaseBreak",
  Termination = "Termination",
  LostManagement = "LostManagement",
  OffBoarded = "OffBoarded",
}

export type VacatingTenancyProjectMeta = {
  __typename?: "VacatingTenancyProjectMeta";
  management: Management;
  tenancy: Tenancy;
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export enum ValidateFileErrorCode {
  FileNotFound = "FileNotFound",
  FileNotUploaded = "FileNotUploaded",
  InvalidFileType = "InvalidFileType",
}

export type ValidateFileResponse = {
  __typename?: "ValidateFileResponse";
  ok: Scalars["Boolean"];
  /** Present if `ok === true`. */
  file?: Maybe<File>;
  /** Present if `ok === false`. */
  errorCode?: Maybe<ValidateFileErrorCode>;
};

export enum VerifyEmailErrorCode {
  VerificationCodeNotFound = "VERIFICATION_CODE_NOT_FOUND",
  VerificationCodeExpired = "VERIFICATION_CODE_EXPIRED",
  EmailUsedByAnotherPerson = "EMAIL_USED_BY_ANOTHER_PERSON",
}

export type VerifyEmailErrorResponse = {
  __typename?: "VerifyEmailErrorResponse";
  errorCode: VerifyEmailErrorCode;
};

export type VerifyEmailResponse =
  | VerifyEmailSuccessResponse
  | VerifyEmailErrorResponse;

export type VerifyEmailSuccessResponse = {
  __typename?: "VerifyEmailSuccessResponse";
  user: User;
};

export type VirtualAccount = PaymentMethod & {
  __typename?: "VirtualAccount";
  accountNumber?: Maybe<Scalars["String"]>;
  ailoRN: Scalars["AiloRN"];
  bsb?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  reference?: Maybe<Scalars["String"]>;
  topUpFee?: Maybe<TransactionFee>;
  virtualAccountId?: Maybe<Scalars["String"]>;
  wallet: Wallet;
};

export type VoidTenancyAbility = {
  __typename?: "VoidTenancyAbility";
  voidable: Scalars["Boolean"];
  unvoidableReason?: Maybe<UnvoidableTenancyReason>;
};

export type VoidTenancyPayload = {
  __typename?: "VoidTenancyPayload";
  tenancy: Tenancy;
};

export type Wallet = {
  __typename?: "Wallet";
  autoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  autoWithdrawPlans?: Maybe<PaginatedAutoWithdrawPlans>;
  availableBalance: Money;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  idempotencyKey: Scalars["GeneratedKey"];
  name: Scalars["String"];
  owner: WalletOwner;
  totalBalance: Money;
};

export type WalletAutoWithdrawPlansArgs = {
  disableOwnerFilter?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  payerLegalEntityId?: Maybe<Scalars["AiloRN"]>;
};

export type WalletBalance = {
  __typename?: "WalletBalance";
  availableBalance: Money;
  clearedBalance: Money;
  overdraftAllowance: Money;
  totalBalance: Money;
};

export type WalletBalanceItem = {
  __typename?: "WalletBalanceItem";
  balance: Money;
  id: Scalars["AiloRN"];
  name: Scalars["String"];
  reference: Scalars["AiloRN"];
};

export type WalletBalanceReport = {
  __typename?: "WalletBalanceReport";
  items: Array<Maybe<WalletBalanceItem>>;
};

export type WalletEntry = {
  __typename?: "WalletEntry";
  amount: Money;
  businessTransaction: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isReversal: Scalars["Boolean"];
  isTopUpFee: Scalars["Boolean"];
  liability?: Maybe<Liability>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: BusinessTxStatusEnum;
};

export type WalletOwner = {
  __typename?: "WalletOwner";
  /** How much money the wallet owner currently owes due to fees */
  dueFeeAmount: Money;
  id: Scalars["ID"];
  reference: Scalars["AiloRN"];
};

export type WalletOwnerBalanceItem = {
  __typename?: "WalletOwnerBalanceItem";
  reference: Scalars["AiloRN"];
  category: Scalars["String"];
  totalBalance: Money;
};

export type WalletOwnerBalanceReport = {
  __typename?: "WalletOwnerBalanceReport";
  items?: Maybe<Array<Maybe<WalletOwnerBalanceItem>>>;
};

export type WalletOwnerLiabilityReport = {
  __typename?: "WalletOwnerLiabilityReport";
  items: Array<Maybe<WalletOwnerLiabilityReportItem>>;
};

export type WalletOwnerLiabilityReportItem = {
  __typename?: "WalletOwnerLiabilityReportItem";
  reference: Scalars["AiloRN"];
  totalBalance: Money;
};

export type WalletStatementData = {
  __typename?: "WalletStatementData";
  closingBalance: WalletBalance;
  lineItemsByStatementTransactionDate: Array<StatementLineItem2>;
  openingBalance: WalletBalance;
  taxSummary: TaxSummaryStatementData;
  transactionLineItemsByStatementTransactionDate?: Maybe<PaginatedStatementTransactionLineItem>;
};

export type WalletStatementDataTransactionLineItemsByStatementTransactionDateArgs = {
  paginationParams?: Maybe<PaginationParams>;
};

export type WalletStatementInput = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  walletOwnerReference: Scalars["AiloRN"];
  zoneId: Scalars["ZoneId"];
};

export type WalletTransferInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  destinationWalletOwner: Scalars["AiloRN"];
  idempotencyKey: Scalars["String"];
  sourceWalletOwner: Scalars["AiloRN"];
};

export type Weight = {
  __typename?: "Weight";
  kgs: Scalars["Long"];
};

export type WithdrawInput = {
  amount: MoneyInput;
  fromWalletId: Scalars["ID"];
  idempotencyKey: Scalars["GeneratedKey"];
  toPaymentMethodId: Scalars["ID"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};
