import React from "react";

/**
 * This component is meant to wrap components that aren't react-admin input fields within forms or show-views.
 *
 * https://github.com/marmelab/react-admin/issues/4209#issuecomment-812021043
 */
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
export const NonInput = React.memo(function NonInput({ children }) {
  return children;
});
