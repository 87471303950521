import { DateTimeWithTimeZone } from "@ailo/date";
import Box from "@material-ui/core/Box";
import moment from "moment-timezone";
import React from "react";
import { CreditCardTypeName } from "../../../../api/deprecatedTypes";
import {
  Badge,
  Colors,
  CopyableTextField,
  DATE_TIME_FORMAT,
  LinkField,
  ListItemView,
} from "../../../../common";
import { formatPaymentMethodName } from "../../utils";
import { PaymentMethodAction } from "./paymentMethodAction";

export const CreditCardListItem = ({
  id,
  cardType,
  maskedCardNumber,
  expiry,
  isAutoPay,
  isDefaultIn,
  isDefaultOut,
  isDeleted,
  isHidden,
  edit,
  wallet,
}: any) => {
  const header = formatPaymentMethodName({
    __typename: CreditCardTypeName,
    type: cardType,
    number: maskedCardNumber,
  });
  const expiryReformatted = expiry
    ? moment(expiry, "YYYY-MM-DD").format("M/YY")
    : "unknown";
  const expired = expiry
    ? moment().isAfter(moment(expiry, "YYYY-MM-DD"))
    : false;
  const deletedStyle = {
    color: Colors.TEXT.DARK.SECONDARY,
  };
  return (
    <ListItemView
      header={header}
      subHeader={
        <>
          <Box>{`Expires ${expiryReformatted}`}</Box>
          {expired && (
            <Box
              display="flex"
              style={{
                marginTop: 4,
              }}
            >
              <Badge type={!isDeleted ? "critical" : "default"}>Expired</Badge>
            </Box>
          )}
          <CopyableTextField
            source="id"
            record={{
              id,
            }}
            style={{
              marginTop: 1,
            }}
          />
          Added on{" "}
          {DateTimeWithTimeZone.from(wallet.createdAt).format(DATE_TIME_FORMAT)}
          <br />
          <LinkField record={wallet} />
        </>
      }
      rightComponent={
        <PaymentMethodAction
          paymentMethodId={id}
          isAutoPay={isAutoPay}
          isDefaultIn={isDefaultIn}
          isDefaultOut={isDefaultOut}
          isDeleted={isDeleted}
          isHidden={isHidden}
          edit={edit}
        />
      }
      style={isDeleted ? deletedStyle : null}
    />
  );
};
