import { CircularProgress } from "@material-ui/core";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import createHistory from "history/createBrowserHistory";
import React from "react";
import { Admin, RaThemeOptions, Resource } from "react-admin";
import {
  AccountOwner,
  BankAccountBlackList,
  Bill,
  createDataProvider,
  DeprecatedPerson,
  FeeBlueprint,
  FeeTaxCategory,
  LedgerEvent,
  LegalEntity,
  LiabilityPaymentPlan,
  LiabilityStatus,
  ManagementFee,
  ManagementFeeBlueprint,
  ManagementFolio,
  MigratingManagement,
  MigrationAgency,
  PaymentMethod,
  Person,
  RecurringFee,
  RecurringOwing,
  StatementProgress,
  TrustAccounts,
  User,
  Wallet,
  TrustTransaction,
} from "../api";
import { Colors } from "../common";
import {
  AccountOwnerShow,
  BillCreate,
  BillList,
  BillShow,
  Company,
  CompanyList,
  CreateCompany,
  Customer,
  CustomerList,
  CustomerShow,
  DeprecatedPersonShow,
  DiscrepanciesList,
  Fee,
  FeeBlueprintCreate,
  FeeBlueprintEdit,
  FeeBlueprintList,
  FeeBlueprintShow,
  FeeCreate,
  FeeEdit,
  FeeList,
  FeeShow,
  LegalEntityShow,
  LegalEntityShowRedirect,
  LiabilityAdjustmentCreate,
  LiabilityCategoriesList,
  LiabilityPaymentPlanList,
  LiabilityShow,
  ManagementCreate,
  ManagementFeeEdit,
  ManagementShow,
  ManagementsList,
  MigratingManagementsEdit,
  MigratingManagementsList,
  NegativeWalletBalancesList,
  PersonCreate,
  PersonList,
  PropertiesList,
  PropertiesShow,
  ReconciliationDetailsList,
  ReconciliationsList,
  RecurringFeeCreate,
  RecurringFeeEdit,
  RecurringFeeShow,
  SubscriptionCreate,
  SupplierCreate,
  SupplierEdit,
  SupplierShow,
  SuppliersList,
  TenancyCreate,
  TenancyShow,
  TransactionShow,
  TransactionsList,
  TrustAccountsCreate,
  VirtualAccountDebitsCreate,
  VirtualAccountDebitsList,
  VirtualAccountsCreate,
  VirtualAccountsList,
  WalletOwnerBalancesList,
  WalletShow,
  PaymentMethodShow,
  RecurringOwingShow,
  LedgerEventShow,
  TrustTransactionsList,
} from "../resourceViews";
import { BankAccountBlackListsCreate } from "../resourceViews/BankAccountBlackList";
import { LiabilityStatusCreate } from "../resourceViews/Liability/liabilityStatusCreate";
import { RentCreate } from "../resourceViews/Rent";
import { UserShow } from "../resourceViews/User";
import { authProvider } from "./auth";
import { customRoutes } from "./customRoutes";
import Layout from "./layout/Layout";
import { DashboardPage, LoginPage } from "./pages";
import { customReducers, customSagas } from "./redux";
import { MigrationAgencyList } from "../resourceViews/MigrationAgency";
import {
  ManagementFeeBlueprintCreate,
  ManagementFeeBlueprintEdit,
  ManagementFeeBlueprintList,
} from "../resourceViews/ManagementFeeBlueprint";
import { updateTitlebar } from "./titlebar";
import { StatementProgressList } from "../resourceViews/StatementProgress";
import {
  ManagementFolioShow,
  ManagementFolioList,
} from "../resourceViews/ManagementFolio";
import { TenantshipCreate } from "../resourceViews/Tenantship";

type Props = {};
type State = {
  dataProvider: any;
};
const muiTheme = createMuiTheme({
  typography: {
    subtitle2: {
      color: Colors.TEXT.SECONDARY_CHARCOAL,
    },
  },
  overrides: {
    MuiTabs: {
      indicator: {
        height: 3,
        backgroundColor: Colors.AILO_RED,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        fontWeight: 500,
        minWidth: 0,
        "@media (min-width: 600px)": {
          minWidth: 0,
        },
        "&$selected": {
          color: Colors.AILO_RED,
        },
      },
      wrapper: {
        alignItems: "flex-start",
      },
    },
    MuiTableCell: {
      head: {
        color: Colors.TEXT.DARK.SECONDARY,
        textTransform: "uppercase",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: Colors.TEXT.DARK.SECONDARY,
        "&$active": {
          color: Colors.TEXT.DARK.SECONDARY,
        },
      },
    },
  },
});
const adminTheme: RaThemeOptions = {
  ...muiTheme,
  sidebar: {
    width: 275,
    // any can be removed once https://github.com/marmelab/react-admin/pull/6197 is released
  } as any,
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dataProvider: null,
    };
  }

  componentDidMount() {
    createDataProvider().then((dataProvider) =>
      this.setState({
        dataProvider,
      })
    );
  }

  render() {
    const history = createHistory();
    history.listen(() => window.analytics?.page());
    const title = updateTitlebar();

    const { dataProvider } = this.state;

    if (!dataProvider) {
      return (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Admin
          disableTelemetry
          title={title}
          theme={adminTheme}
          layout={Layout}
          history={history}
          authProvider={authProvider}
          dashboard={DashboardPage}
          loginPage={LoginPage}
          customRoutes={customRoutes}
          customReducers={customReducers}
          customSagas={customSagas}
          dataProvider={dataProvider}
        >
          <Resource name="dumb" />
          <Resource name={AccountOwner} show={AccountOwnerShow} />
          <Resource name={LedgerEvent} show={LedgerEventShow} />
          <Resource name={RecurringOwing} show={RecurringOwingShow} />
          <Resource name={Customer} list={CustomerList} show={CustomerShow} />
          <Resource name={DeprecatedPerson} show={DeprecatedPersonShow} />
          <Resource
            name={Person}
            list={PersonList}
            show={LegalEntityShow}
            create={PersonCreate}
          />
          <Resource
            name={Company}
            list={CompanyList}
            show={LegalEntityShow}
            create={CreateCompany}
          />
          <Resource
            name="Managements"
            list={ManagementsList}
            show={ManagementShow}
            create={ManagementCreate}
          />
          <Resource
            name={MigratingManagement}
            list={MigratingManagementsList}
            edit={MigratingManagementsEdit}
          />
          <Resource name={MigrationAgency} list={MigrationAgencyList} />
          <Resource
            name="Properties"
            list={PropertiesList}
            show={PropertiesShow}
          />
          <Resource name="Files" />
          <Resource name="Subscriptions" create={SubscriptionCreate} />
          <Resource name="Rents" create={RentCreate} />
          <Resource name="Tenantship" create={TenantshipCreate} />
          <Resource name="Tenancy" show={TenancyShow} create={TenancyCreate} />
          <Resource
            name={Fee}
            list={FeeList}
            show={FeeShow}
            create={FeeCreate}
            edit={FeeEdit}
          />
          <Resource
            name="Suppliers"
            create={SupplierCreate}
            edit={SupplierEdit}
            list={SuppliersList}
            show={SupplierShow}
          />
          <Resource
            name={Bill}
            list={BillList}
            show={BillShow}
            create={BillCreate}
          />
          <Resource name={ManagementFee} edit={ManagementFeeEdit} />
          <Resource
            name={RecurringFee}
            show={RecurringFeeShow}
            create={RecurringFeeCreate}
            edit={RecurringFeeEdit}
          />
          <Resource
            name={FeeBlueprint}
            list={FeeBlueprintList}
            show={FeeBlueprintShow}
            create={FeeBlueprintCreate}
            edit={FeeBlueprintEdit}
          />
          <Resource
            name={ManagementFeeBlueprint}
            list={ManagementFeeBlueprintList}
            create={ManagementFeeBlueprintCreate}
            edit={ManagementFeeBlueprintEdit}
          />
          <Resource
            name={ManagementFolio}
            show={ManagementFolioShow}
            list={ManagementFolioList}
          />
          <Resource name={FeeTaxCategory} />
          <Resource name={Wallet} show={WalletShow} />
          <Resource name="Liabilities" show={LiabilityShow} />
          <Resource
            name="LiabilityAdjustments"
            create={LiabilityAdjustmentCreate}
          />
          <Resource name={PaymentMethod} show={PaymentMethodShow} />
          <Resource
            name={LiabilityPaymentPlan}
            list={LiabilityPaymentPlanList}
          />
          <Resource name={LiabilityStatus} create={LiabilityStatusCreate} />
          <Resource name={LegalEntity} show={LegalEntityShowRedirect} />
          <Resource name={User} show={UserShow} />
          <Resource
            name="Transactions"
            list={TransactionsList}
            show={TransactionShow}
          />
          <Resource name="Reconciliations" list={ReconciliationsList} />
          <Resource
            name="ReconciliationDetails"
            list={ReconciliationDetailsList}
          />
          <Resource name="LiabilityCategories" list={LiabilityCategoriesList} />
          <Resource name="WalletOwnerBalances" list={WalletOwnerBalancesList} />
          <Resource
            name="NegativeWalletBalances"
            list={NegativeWalletBalancesList}
          />
          <Resource name="Discrepancies" list={DiscrepanciesList} />
          <Resource
            name="Transco/VirtualAccounts"
            list={VirtualAccountsList}
            create={VirtualAccountsCreate}
          />
          <Resource
            name="Transco/VirtualAccountDebits"
            list={VirtualAccountDebitsList}
            create={VirtualAccountDebitsCreate}
          />
          <Resource
            name={BankAccountBlackList}
            create={BankAccountBlackListsCreate}
          />
          <Resource name={TrustAccounts} create={TrustAccountsCreate} />
          <Resource name={StatementProgress} list={StatementProgressList} />
          <Resource name={TrustTransaction} list={TrustTransactionsList} />
        </Admin>
      </MuiThemeProvider>
    );
  }
}

export { App };
