import gql from "graphql-tag";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import {
  CancelTenancyDepositMutation,
  MarkTenancyDepositAsReleasedMutation,
  TransferTenancyDepositMutation,
} from "./TenancyDepositsClient.generated";

export const TenancyDepositsClientDefinition = {
  cancel_tenancy_deposit: (params: { tenancyDepositId: string }) => ({
    query: gql`
      mutation cancelTenancyDeposit($tenancyDepositId: AiloRN!) {
        deposit: cancelTenancyDeposit(tenancyDepositId: $tenancyDepositId) {
          id
        }
      }
    `,
    variables: {
      tenancyDepositId: params.tenancyDepositId,
    },
    parseResponse(response: GraphQLResponse<CancelTenancyDepositMutation>) {
      return {
        data: response.data.deposit,
      };
    },
  }),
  transfer_tenancy_deposit: (params: {
    tenancyDepositId: string;
    amount: { cents: number };
  }) => ({
    query: gql`
      mutation transferTenancyDeposit(
        $tenancyDepositId: AiloRN!
        $transferAmount: MoneyInput
      ) {
        deposit: transferTenancyDeposit(
          tenancyDepositId: $tenancyDepositId
          transferAmount: $transferAmount
        ) {
          id
        }
      }
    `,
    variables: {
      tenancyDepositId: params.tenancyDepositId,
      transferAmount: { cents: params.amount.cents },
    },
    parseResponse(response: GraphQLResponse<TransferTenancyDepositMutation>) {
      return {
        data: response.data.deposit,
      };
    },
  }),
  mark_tenancy_deposit_as_released: (params: { tenancyDepositId: string }) => ({
    query: gql`
      mutation markTenancyDepositAsReleased($tenancyDepositId: AiloRN!) {
        deposit: markTenancyDepositAsReleased(
          tenancyDepositId: $tenancyDepositId
        ) {
          id
        }
      }
    `,
    variables: {
      tenancyDepositId: params.tenancyDepositId,
    },
    parseResponse(
      response: GraphQLResponse<MarkTenancyDepositAsReleasedMutation>
    ) {
      return {
        data: response.data.deposit,
      };
    },
  }),
};

export const TenancyDepositsClient = createClientFromDefinition(
  TenancyDepositsClientDefinition
);
