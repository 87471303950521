import { AiloRN } from "@ailo/ailorn";
import gql from "graphql-tag";
import { GET_LIST } from "react-admin";
import { isPresent } from "ts-is-present";
import {
  buildPaginatedResponseFromPaginatedList,
  createClientFromDefinition,
  GraphQLResponse,
  specifyDefinition,
  specifyOperation,
} from "../../common";
import { PaginationParams, StatementType } from "../../graphql/types.generated";
import { parseStatementProgress } from "./parseStatementProgress";
import {
  RetryCreateStatementMutation,
  RetryCreateStatementMutationVariables,
  StatementProgressesForInfinitelyPaginatedQueryQuery,
  StatementProgressesForInfinitelyPaginatedQueryQueryVariables,
  StatementProgressesQuery,
  StatementProgressesQueryVariables,
} from "./statementProgressClient.generated";

const RETRY_GENERATING_STATEMENT = "retry_generating_statement";

const statementSubjectFragment = gql`
  fragment StatementSubjectFragment on StatementProgress {
    subject {
      ... on LegalEntityCompanion {
        id
        ailorn: ailoRNV2
        legalEntity {
          __typename
          id
          ... on Person {
            firstName
            lastName
            phoneNo
            emailAddress
          }
          ... on Company {
            registeredEntityName
            organisation {
              id
              orgType
            }
          }
        }
      }
      ... on Management {
        id
        ailorn: ailoRN
        property {
          id
          address {
            unitStreetNumber
            streetName
            suburb
            state
            country
            postcode
          }
        }
      }
      ... on Tenancy {
        id
        ailorn: ailoRN
        tenants {
          tenant {
            __typename
            id
            ... on Person {
              firstName
              lastName
              phoneNo
              emailAddress
            }
            ... on Company {
              registeredEntityName
            }
          }
        }
      }
    }
  }
`;

const statementProgressFragment = gql`
  fragment StatementProgressFragment on StatementProgress {
    id
    statement {
      id
      file {
        id
        url
      }
      billsZipFile {
        id
        url
      }
    }
    rangeStartDate
    rangeEndDate
    isSuccessful
    note
    createdAt
    dryRunEmail
    sendEmail
    type
    ...StatementSubjectFragment
  }
  ${statementSubjectFragment}
`;

const getList = specifyOperation(
  (params: {
    filter: {
      sendEmail?: boolean;
      statementSubject?: AiloRN;
      type?: StatementType;
      types?: Array<StatementType>;
    };
    cursor?: PaginationParams;
  }) => {
    const { sendEmail, statementSubject, type, types } = params.filter;

    return {
      query: gql`
        query statementProgresses(
          $filter: StatementProgressFilter!
          $cursor: StatementProgressCursor!
        ) {
          statementProgresses(filter: $filter, cursor: $cursor) {
            pageInfo {
              hasNext
              nextCursor
              total
            }
            items {
              ...StatementProgressFragment
            }
          }
        }
        ${statementProgressFragment}
      `,
      variables: <StatementProgressesQueryVariables>{
        filter: {
          sendEmail,
          statementSubject: statementSubject?.toString(),
          types: types ?? (type ? [type] : undefined),
        },
        cursor: {
          ...params.cursor,
          paginateBackward: params.cursor?.before,
        },
      },
      parseResponse: (response: GraphQLResponse<StatementProgressesQuery>) => {
        if (!response.data.statementProgresses) {
          throw new Error("Failed to fetch statement progress list");
        }
        const { data, ...rest } = buildPaginatedResponseFromPaginatedList(
          response.data.statementProgresses
        );
        return {
          data: data.filter(isPresent).map(parseStatementProgress),
          ...rest,
        };
      },
    };
  }
);

const getListForInfinitelyPaginatedQuery = specifyOperation(
  (params: {
    filter: {
      sendEmail?: boolean;
      statementSubject?: AiloRN;
      type?: StatementType;
      types?: Array<StatementType>;
    };
    cursor?: PaginationParams;
  }) => {
    const { sendEmail, statementSubject, type, types } = params.filter;

    return {
      query: gql`
        query statementProgressesForInfinitelyPaginatedQuery(
          $filter: StatementProgressFilter!
          $cursor: StatementProgressCursor!
        ) {
          statementProgresses(filter: $filter, cursor: $cursor) {
            pageInfo {
              hasNext
              nextCursor
              total
            }
            items {
              ...StatementProgressFragment
            }
          }
        }
        ${statementProgressFragment}
      `,
      variables: <StatementProgressesForInfinitelyPaginatedQueryQueryVariables>{
        filter: {
          sendEmail,
          statementSubject: statementSubject?.toString(),
          types: types ?? (type ? [type] : undefined),
        },
        cursor: {
          ...params.cursor,
          paginateBackward: params.cursor?.before,
        },
      },
      parseResponse: (
        response: GraphQLResponse<StatementProgressesForInfinitelyPaginatedQueryQuery>
      ) => {
        if (!response.data.statementProgresses) {
          throw new Error("Failed to fetch statement progress list");
        }
        return {
          data: {
            pageInfo: response.data.statementProgresses.pageInfo,
            items: response.data.statementProgresses.items
              .filter(isPresent)
              .map(parseStatementProgress),
          },
        };
      },
    };
  }
);

const retryGeneratingStatement = specifyOperation(
  (params: { progressAiloRN: AiloRN }) => {
    return {
      query: gql`
        mutation retryCreateStatement($progressAiloRN: AiloRN!) {
          retryCreateStatement(progressAiloRN: $progressAiloRN) {
            id
          }
        }
      `,
      variables: <RetryCreateStatementMutationVariables>{
        progressAiloRN: params.progressAiloRN.toString(),
      },
      parseResponse: (
        response: GraphQLResponse<RetryCreateStatementMutation>
      ) => {
        const id = response.data?.retryCreateStatement?.id;
        if (!id) {
          throw new Error("Failed to create statement");
        }

        return {
          data: { id },
        };
      },
    };
  }
);

export const StatementProgressClientDefinition = specifyDefinition({
  [GET_LIST]: getList,
  /**
   * react-admin expects `getList` to return `{ data: Record[], total: number }`
   * but our `useInfinitelyPaginatedQuery` expects a list query response to extend `{ data: PaginatedList }`
   * TODO: refactor `useInfinitelyPaginatedQuery` to accept the return type of `getList` so this can be removed
   */
  GET_LIST_FOR_INFINITELY_PAGINATED_QUERY: getListForInfinitelyPaginatedQuery,
  [RETRY_GENERATING_STATEMENT]: retryGeneratingStatement,
});

export const statementProgressClient = createClientFromDefinition(
  StatementProgressClientDefinition
);
