import React, { useState } from "react";
import { ConfirmActionDialog } from "../../../../common/ui/dialogs/ConfirmActionDialog/ConfirmActionDialog";

export function useAddCentrepayPaymentDirectiveDialog(
  addCentrepayDirective: () => Promise<void>
): {
  showAddDirectiveDialog: () => void;
  confirmAddDirectiveDialog: React.ReactElement;
} {
  const [showAddDirectiveDialog, setShowAddDirectiveDialog] = useState(false);
  const confirmAddDirectiveDialog = (
    <ConfirmActionDialog
      open={showAddDirectiveDialog}
      onClose={() => setShowAddDirectiveDialog(false)}
      confirmAction={addCentrepayDirective}
      dialogText={{
        title: "Confirm create Centrepay Directive?",
        content: "This will create a payment directive for this tenancy",
      }}
      failureNotification="Failed to create payment directive"
    />
  );
  return {
    showAddDirectiveDialog: () => setShowAddDirectiveDialog(true),
    confirmAddDirectiveDialog,
  };
}
