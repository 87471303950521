import type { Saga } from "redux-saga";
import {
  CRUD_CHANGE_LIST_PARAMS,
  CRUD_GET_LIST_SUCCESS,
  crudGetList,
} from "react-admin";
import { put, takeEvery, all, select, call } from "redux-saga/effects";
import {
  removeLastPage,
  resetPaginationResource,
  updateLatestPage,
  GET_NEXT_PAGE,
  GET_PREV_PAGE,
} from "../actions";
import { getCrudGetListParams } from "./selectors";

export function* _fetchListWithCursor({ resource }: Record<string, any>): any {
  const { pagination, sort, filter } = yield select(
    getCrudGetListParams,
    resource
  );
  yield put(crudGetList(resource, pagination, sort, filter));
}
export function* _getPrevPage({ resource }: Record<string, any>): any {
  yield put(removeLastPage(resource));
  yield call(_fetchListWithCursor, {
    resource,
  });
}
export function* _getListSuccess({ meta, payload }: Record<string, any>): any {
  const { resource } = meta;
  const { nextCursor, hasMore } = payload;
  yield put(updateLatestPage(resource, nextCursor, hasMore));
}
export function* _aorListParamsChanged({ meta }: Record<string, any>): any {
  yield put(resetPaginationResource(meta.resource));
}

function* paginationSaga(): any {
  yield all([
    takeEvery(CRUD_CHANGE_LIST_PARAMS, _aorListParamsChanged),
    takeEvery(CRUD_GET_LIST_SUCCESS, _getListSuccess),
    takeEvery(GET_NEXT_PAGE, _fetchListWithCursor),
    takeEvery(GET_PREV_PAGE, _getPrevPage),
  ]);
}

export { paginationSaga };
