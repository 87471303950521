import React from "react";
import { Button } from "react-admin";
import { OperationData } from "../../../../api";
import { LoadingList } from "../../../../common";
import { Liability } from "../../../../resourceViews";
import { EmptyTransactionsList } from "../emptyTransactionsList";
import { EntriesByMonthList } from "../entriesByMonthList";
import { ErrorTransactionsList } from "../errorTransactionsList";
import { TenancyLiabilityEntryListItem } from "./TenancyLiabilityEntryListItem";

export function TenancyLiabilityEntryListContent({
  liabilityEntries,
  hasMore,
  loadMore,
}: {
  liabilityEntries: OperationData<typeof Liability, "get_entries">["items"];
  hasMore?: boolean;
  loadMore?(): void;
}) {
  return (
    <>
      <EntriesByMonthList
        entries={liabilityEntries}
        groupBy="effectiveAt"
        listItemComponent={(item: any) => (
          <TenancyLiabilityEntryListItem key={item.id} liabilityEntry={item} />
        )}
        style={{ maxHeight: 500 }}
      />
      {hasMore && (
        <Button
          label="Load more"
          onClick={loadMore}
          variant="contained"
          fullWidth
        />
      )}
    </>
  );
}

TenancyLiabilityEntryListContent.Loading = LoadingList;
TenancyLiabilityEntryListContent.Empty = EmptyTransactionsList;
TenancyLiabilityEntryListContent.Error = ErrorTransactionsList;
