import { AiloRN } from "@ailo/ailorn";
import {
  getNsEntityFromApiType,
  getApiTypeFromNsEntity,
} from "@ailo/ailorn-ns-entity";
import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { Link } from "react-admin";
import {
  getApiTypeForResourceName,
  getResourceNameForApiType,
} from "../../api";
import { CopyToClipboardButton } from "../ui";
import { TruncatedCopyToClipboardTextButton } from "../ui/buttons/TruncatedCopyToClipboardTextButton";
import { PublicFieldProps } from "./utils/react-admin-types";

const useStyles = makeStyles(
  (theme) => ({
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  }),
  { name: "RaLink" }
);

export function LinkField({
  record,
  source,
  sourceResource,
  includeApiTypeInLinkText = !sourceResource,
  useAiloRNInLink = false,
  renderText,
  linkable = true,
  copyable = true,
  to,
}: {
  label?: string;
  record?: any;
  source?: string;
  /**
   * Linked resource name.
   * Pass `false` if doesn't correspond to any of the existing resources.
   */
  sourceResource?: string | false;
  includeApiTypeInLinkText?: boolean;
  useAiloRNInLink?: boolean;
  renderText?: (record: any) => string;
  linkable?: boolean;
  copyable?: boolean;
  to?: string;
} & PublicFieldProps): React.ReactElement | null {
  const classes = useStyles();
  const sourceRecord = source ? get(record, source) : record;

  if (sourceRecord == null) {
    return null;
  }

  const ailoRN =
    typeof sourceRecord === "string" && AiloRN.isAiloRNString(sourceRecord)
      ? AiloRN.from(sourceRecord)
      : sourceRecord.ailoRN
      ? AiloRN.from(sourceRecord.ailoRN)
      : sourceRecord.ailorn
      ? AiloRN.from(sourceRecord.ailorn)
      : undefined;

  if (ailoRN?.entity === "system") {
    return (
      <div>
        {ailoRN.toString()}
        {copyable && (
          <CopyToClipboardButton
            value={ailoRN.toString()}
            tooltip={`Copy "${ailoRN.toString()}" ailorn to clipboard`}
          />
        )}
      </div>
    );
  }

  const id =
    typeof sourceRecord === "string" && !AiloRN.isAiloRNString(sourceRecord)
      ? sourceRecord
      : sourceRecord.id || ailoRN?.internalId;

  const apiEntityType =
    sourceRecord?.__typename ??
    (ailoRN ? getApiTypeFromNsEntity(ailoRN.nsEntity) : undefined) ??
    (sourceResource ? getApiTypeForResourceName(sourceResource) : undefined);

  const inferredAiloRN =
    ailoRN ??
    (id && apiEntityType
      ? AiloRN.of(getNsEntityFromApiType(apiEntityType), id)
      : undefined);
  const resource =
    sourceResource ||
    (apiEntityType ? getResourceNameForApiType(apiEntityType) : undefined);
  const apiTypeLabel = apiEntityType ?? resource;
  const text = renderText
    ? renderText(sourceRecord)
    : includeApiTypeInLinkText && apiTypeLabel
    ? `${apiTypeLabel}#${id}`
    : id;

  const hasLink = linkable && !!resource;

  if (!resource && hasLink && sourceResource !== false) {
    throw new TypeError(`Invalid record.${source}: It either needs:
      - to have \`ailoRN\` property, or
      - to have \`id\` property, and a \`sourceResource\` prop needs to be present on the \`LinkField\` component`);
  }

  const defaultLinkId = useAiloRNInLink && inferredAiloRN ? inferredAiloRN : id;

  return (
    <div>
      {hasLink && (to || resource) ? (
        to && to.startsWith("http") ? (
          <a
            href={to}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            {text}
          </a>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to={to ?? `/${resource}/${defaultLinkId}/show`}
            // Stop click event propagation,
            // so that it can be clicked when `LinkField` is put in a `List` view
            onClick={(event) => event.stopPropagation()}
          >
            {text}
          </Link>
        )
      ) : (
        text
      )}
      {copyable && inferredAiloRN && text !== id && (
        <TruncatedCopyToClipboardTextButton
          value={inferredAiloRN.toString()}
          displayText={id}
          tooltip={`Copy "${inferredAiloRN.toString()}" ailorn to clipboard`}
        />
      )}
      {copyable && (
        <CopyToClipboardButton
          value={id}
          tooltip={`Copy "${id}" id to clipboard`}
        />
      )}
    </div>
  );
}

LinkField.defaultProps = {
  addLabel: true,
};
