import { capitalize } from "@material-ui/core";
import React from "react";
import { FunctionField, FunctionFieldProps } from "react-admin";
import { Badge } from "../ui/Badge";

export function StatusField({
  source,
  ...props
}: { source: string } & Omit<FunctionFieldProps<any>, "render">) {
  return (
    <FunctionField
      {...props}
      render={(record: any) => {
        const value = record[source]?.toLowerCase();

        if (!value) {
          return null;
        }

        return (
          <Badge
            type={
              [
                "archived",
                "cancelled",
                "void",
                "draft",
                "former",
                "past",
              ].includes(value)
                ? "default"
                : ["future", "incomplete", "pending"].includes(value)
                ? "warning"
                : ["fail", "cleaned"].includes(value)
                ? "critical"
                : "success"
            }
          >
            {capitalize(value)}
          </Badge>
        );
      }}
    />
  );
}
StatusField.defaultProps = {
  addLabel: true,
};
