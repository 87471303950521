import gql from "graphql-tag";
import { GET_LIST, GET_ONE } from "react-admin";
import {
  createClientFromDefinition,
  specifyDefinition,
  GraphQLResponse,
} from "../..";
import {
  FindOrCreatePaymentReferenceMutation,
  FindOrCreatePaymentReferenceMutationVariables,
  GetPaymentMethodsByOwnerQuery,
} from "./paymentMethodsClient.generated";

const CREATE_PAYMENT_METHOD = "create_payment_method";
const DELETE_PAYMENT_METHOD = "delete_payment_method";
export const FIND_OR_CREATE_PAYMENT_REFERENCE =
  "find_or_create_payment_reference";

export const paymentMethodsDefinition = specifyDefinition({
  [FIND_OR_CREATE_PAYMENT_REFERENCE]: (
    params: FindOrCreatePaymentReferenceMutationVariables["paymentReferenceDetails"]
  ) => ({
    query: gql`
      mutation findOrCreatePaymentReference(
        $paymentReferenceDetails: PaymentReferenceInput!
      ) {
        findOrCreatePaymentReference(
          paymentReferenceDetails: $paymentReferenceDetails
        ) {
          id
          supplier {
            ailoRN
          }
          supplierPaymentMethodReference
          crn
          paymentDescription
        }
      }
    `,
    variables: <FindOrCreatePaymentReferenceMutationVariables>{
      paymentReferenceDetails: params,
    },
    parseResponse: ({
      data,
    }: GraphQLResponse<FindOrCreatePaymentReferenceMutation>) => ({ data }),
  }),
  [GET_LIST]: (params: {
    filter: {
      owner: any;
      includeDeleted?: any;
      includeOnceOff?: any;
      includeHidden?: any;
    };
  }) => ({
    query: gql`
      query getPaymentMethodsByOwner(
        $owner: AiloRN!
        $includeDeleted: Boolean
        $includeOnceOff: Boolean
        $includeHidden: Boolean
      ) {
        paymentMethodsByOwner(
          owner: $owner
          options: {
            includeDeleted: $includeDeleted
            includeOnceOff: $includeOnceOff
            includeHidden: $includeHidden
          }
        ) {
          pageInfo {
            total
            hasMore
            nextCursor
          }
          items {
            id
            ailorn: ailoRN
            isOnceOff
            isHidden
            isDefaultIn
            isDefaultOut
            isAutoPay
            isAutoWithdraw
            deletedAt
            topUpFee {
              feeBps
              feeFlatCents
            }
            ... on CreditCard {
              number
              category
              expiry
              id
              type
              __typename
            }
            ... on BankAccount {
              bsb
              accountName
              accountNumber
              id
              __typename
            }
            ... on BPay {
              billerCode
              __typename
            }
            wallet {
              __typename
              id
              createdAt
            }
          }
        }
      }
    `,
    variables: {
      owner: params.filter.owner,
      includeDeleted: !!params.filter.includeDeleted,
      includeOnceOff: !!params.filter.includeOnceOff,
      includeHidden: !!params.filter.includeHidden,
    },
    parseResponse: (response: { data: GetPaymentMethodsByOwnerQuery }) => {
      const { paymentMethodsByOwner } = response.data;
      const { pageInfo, items } = paymentMethodsByOwner ?? {};
      const { total, nextCursor, hasMore } = pageInfo ?? {};
      return {
        data: items,
        total,
        nextCursor,
        hasMore,
      };
    },
  }),
  [GET_ONE]: (params: { id: any }) => ({
    query: gql`
      query getPaymentMethod($id: ID!) {
        paymentMethod: paymentMethodById(paymentMethodId: $id) {
          __typename
          id
          deletedAt
        }
      }
    `,
    variables: {
      id: params.id,
    },

    parseResponse({ data }: any) {
      return {
        data: data.paymentMethod,
      };
    },
  }),
  [CREATE_PAYMENT_METHOD]: (params: {
    owner: { toString: () => any };
    managingOrganisationAiloRN: { toString: () => any };
    methodType: string;
    bsb: any;
    accountNumber: any;
    accountName: any;
    description: any;
    billerCode: any;
    createWalletOwner: any;
    deleteExistingPaymentMethods: any;
  }) => ({
    query: gql`
      mutation createPaymentMethod(
        $owner: AiloRN!
        $managingOrganisationAiloRN: AiloRN
        $bankAccountInput: BankAccountInput
        $bpayInput: BPayInput
        $createWalletOwner: Boolean
        $deleteExistingPaymentMethods: Boolean
      ) {
        createPaymentMethod(
          input: {
            walletOwnerAiloRN: $owner
            managingOrganisationAiloRN: $managingOrganisationAiloRN
            createWalletOwner: $createWalletOwner
            deleteExistingPaymentMethods: $deleteExistingPaymentMethods
            bankAccountInput: $bankAccountInput
            bpayInput: $bpayInput
          }
        ) {
          id
        }
      }
    `,
    variables: {
      owner: params.owner.toString(),
      managingOrganisationAiloRN:
        params.managingOrganisationAiloRN?.toString() ?? undefined,
      bankAccountInput:
        params.methodType === "bankAccount"
          ? {
              bsb: params.bsb,
              accountNumber: params.accountNumber,
              accountName: params.accountName,
              description: params.description,
            }
          : undefined,
      bpayInput:
        params.methodType === "bPay"
          ? {
              billerCode: params.billerCode,
            }
          : undefined,
      createWalletOwner: params.createWalletOwner || false,
      deleteExistingPaymentMethods:
        params.deleteExistingPaymentMethods || false,
    },
    parseResponse: (response: { data: { createPaymentMethod: any } }) => ({
      data: response.data.createPaymentMethod,
    }),
  }),
  [DELETE_PAYMENT_METHOD]: (params: {
    paymentMethodId: any;
    disableAutoPlans: any;
  }) => ({
    query: gql`
      mutation deletePaymentMethod(
        $paymentMethodId: ID!
        $disableAutoPlans: Boolean
      ) {
        deletePaymentMethod(
          paymentMethodId: $paymentMethodId
          disableAutoPlans: $disableAutoPlans
        )
      }
    `,
    variables: {
      paymentMethodId: params.paymentMethodId,
      disableAutoPlans: params.disableAutoPlans,
    },
    parseResponse: (response: { data: { deletePaymentMethod: any } }) => ({
      data: response.data.deletePaymentMethod,
    }),
  }),
});

export const paymentMethodsClient = createClientFromDefinition(
  paymentMethodsDefinition
);
