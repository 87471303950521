import { formatAddress } from "@ailo/domain-helpers";
import { capitalize } from "lodash";
import React from "react";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  ListProps,
  TextField,
  useGetOne,
} from "react-admin";
import { Management, ManagementFeeBlueprint, OperationData } from "../../api";
import { ManagementQuery } from "../../api/resources/Management/managementsClient.generated";
import { List, MoneyField } from "../../common";
import { InfoContainer } from "../../common/ui/containers/InfoContainer";

type ManagementFeeBlueprintRecord = OperationData<
  typeof ManagementFeeBlueprint,
  "GET_LIST"
>[number];

type ListState = {
  managementId: string;
};

const CreateBlueprint = (state: ListState) => (
  <div>
    <CreateButton
      to={{
        pathname: `/${ManagementFeeBlueprint}/create`,
        state,
      }}
      label="Add"
    />
  </div>
);

const EmptyView = () => {
  return (
    <InfoContainer>
      No manual or event-based fees templates have been added to this property
      yet.
    </InfoContainer>
  );
};

export const ManagementFeeBlueprintList = (
  props: ListProps
): React.ReactElement => {
  const state = (props.location?.state ?? {
    managementId: "",
  }) as ListState;
  const { managementId } = state;

  const management = useGetOne<NonNullable<ManagementQuery["management"]>>(
    Management,
    managementId
  );

  const address = management.data?.property.address
    ? formatAddress(management.data.property.address, {
        format: "street, suburb",
      })
    : undefined;

  const title = `Fee Schedule – ${address}`;

  return (
    <List
      {...props}
      title={title}
      bulkActionButtons={false}
      filterDefaultValues={{ managementId, status: "active" }}
      actions={CreateBlueprint(state)}
      empty={<EmptyView />}
    >
      <Datagrid rowClick="edit">
        <TextField label="Name" source="feeBlueprint.name" sortable={false} />
        <FunctionField<ManagementFeeBlueprintRecord>
          label="Price"
          render={(record) =>
            record?.fixedAmount ? (
              <MoneyField
                label="Price"
                source="fixedAmount"
                frequencySource="frequency"
                sortable={false}
              />
            ) : (
              `${record?.oneWeekRentPercentage} weeks of rent`
            )
          }
        />
        <TextField label="GST" source="taxTreatment" />
        <FunctionField<ManagementFeeBlueprintRecord>
          label="Charged"
          render={(record) => {
            const feeBlueprint = record?.feeBlueprint;
            if (!feeBlueprint) {
              return null;
            }
            if (feeBlueprint.event) {
              return capitalize(feeBlueprint.event.longDescription);
            }
            return "Manually";
          }}
        />
      </Datagrid>
    </List>
  );
};
