import { AiloRN, services } from "@ailo/ailorn";
import { flatten } from "lodash";
import { useMutation, useNotify, useRefresh } from "react-admin";
import { isPresent } from "ts-is-present";
import type {
  LegalEntityInfo,
  Management,
  User,
} from "../../api/deprecatedTypes";

export function useOnboardUsers(): [
  (userIds: number[]) => void,
  {
    loading: boolean;
  }
] {
  const refresh = useRefresh();
  const notify = useNotify();
  const [onboardUser, { loading }] = useMutation(
    {
      type: "onboard",
      resource: "Users",
      payload: {},
    },
    {
      onSuccess: ({ data }) => {
        notify("Users invited");
        refresh();
      },
      onFailure: (error) =>
        notify(`Invite users failed: ${error.message}`, "error"),
    }
  );
  return [
    (userIds) =>
      onboardUser({
        payload: {
          userIds: userIds.map((userId) =>
            AiloRN.of(services.AuthZ.user, userId)
          ),
        },
      }),
    {
      loading,
    },
  ];
}
export function useBulkInviteOwners() {
  return useBulkInviteConsumers(useOnboardOwners, "get_many_owners_for_invite");
}
export function useBulkInviteTenants() {
  return useBulkInviteConsumers(
    useOnboardTenants,
    "get_many_tenants_for_invite"
  );
}

function useBulkInviteConsumers(
  useOnboardConsumers: {
    (): [(managements: Management[]) => void, { loading: boolean }];
    (): [(managements: Management[]) => void, { loading: boolean }];
    (): [any, { loading: any }];
  },
  getManyConsumersForInviteMutationType: string
) {
  const notify = useNotify();
  const [
    onboardConsumers,
    { loading: loadingOnboardConsumers },
  ] = useOnboardConsumers();
  const [
    getManyConsumersForInvite,
    { loading: loadingGetConsumers },
  ] = useMutation(
    {
      type: getManyConsumersForInviteMutationType,
      resource: "Managements",
      payload: {},
    },
    {
      onSuccess: ({ data }) => onboardConsumers(data.managements),
      onFailure: (error) =>
        notify(`Unable to fetch invite data: ${error.message}`, "error"),
    }
  );
  return [
    (managementIds: any) =>
      getManyConsumersForInvite({
        payload: {
          ids: managementIds,
        },
      }),
    {
      loading: loadingGetConsumers || loadingOnboardConsumers,
    },
  ] as any;
}

function useOnboardOwners(): [
  (managements: Array<Management>) => void,
  {
    loading: boolean;
  }
] {
  const [onboardUsers, { loading }] = useOnboardUsers();
  return [
    (managements) => {
      const userIds = flatten(
        managements.map((m) => {
          if (!m?.owners) return [];

          const owners = m.owners.map(({ owner }) => owner).filter(isPresent);
          return getLegalEntitiesUserIds(owners);
        })
      );
      return onboardUsers(userIds);
    },
    {
      loading,
    },
  ];
}

function useOnboardTenants(): [
  (managements: any) => void,
  {
    loading: boolean;
  }
] {
  const [onboardUsers, { loading }] = useOnboardUsers();
  return [
    (managements) => {
      const userIds = flatten<number>(
        managements.map((m: { allTenancies: any; id: any }) => {
          const tenancy = m.allTenancies[0];
          if (!tenancy?.tenants) return [];

          const tenants = tenancy.tenants.map(({ tenant }: any) => tenant);
          return getLegalEntitiesUserIds(tenants);
        })
      );
      return onboardUsers(userIds);
    },
    {
      loading,
    },
  ];
}

function getLegalEntitiesUserIds(legalEntities: LegalEntityInfo[]): number[] {
  return legalEntities
    .filter((legalEntity) => !!legalEntity)
    .map(getLegalEntityUser)
    .filter((user) => !!user)
    .map((user) => user.id);
}

function getLegalEntityUser(legalEntity: any): User {
  const { user } = legalEntity.owner ? legalEntity.owner : legalEntity;
  return user;
}
