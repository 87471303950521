import React from "react";
import { NotificationPreference } from "../../../../../../api/ResourceName";
import { useQueryWithStore } from "../../../../../../common";
import { LoadingList } from "../../../../../../common/ui/lists";
import { supportedNotificationPreferences } from "../utils";
import { CommunicationTypeChannelPreferences } from "./communicationTypeChannelPreferences";

export function UserNotificationPreferences({
  userId,
}: any): React.ReactElement {
  const { loaded, error, data } = useQueryWithStore({
    type: "get_user_preferences",
    resource: NotificationPreference,
    payload: {
      userId,
    },
  });

  if (!loaded) {
    return <LoadingList />;
  }

  if (error) {
    return <>Error loading notification preferences</>;
  }

  return (
    <>
      {supportedNotificationPreferences.map((preference) => (
        <CommunicationTypeChannelPreferences
          key={preference.communicationType}
          preference={preference}
          userPreferences={data}
          userId={userId}
        />
      ))}
    </>
  );
}
