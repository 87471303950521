import React, { useCallback, useState } from "react";
import {
  Tab,
  Datagrid,
  TextField,
  FunctionField,
  ArrayField,
  useMutation,
  useNotify,
  useRefresh,
} from "react-admin";
import GroupIcon from "@material-ui/icons/Group";
import CheckIcon from "@material-ui/icons/Check";
import {
  Dialog,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { PersonLinkField } from "../../common/fields/deprecated/PersonLinkField";
import { formatPersonName } from "../../common/utils";
import type { Person, Team } from "../../api/deprecatedTypes";

function renderPeopleTab({ teams }: { teams: Team[] }) {
  return (
    <Tab label="People">
      <ArrayField source="memberships" label="">
        <Datagrid>
          <FunctionField
            label="name"
            render={(record: any) => <PersonLinkField record={record.member} />}
          />
          <TextField
            source="member.team.name"
            label="Team"
            sortable={false}
            style={{
              minWidth: 120,
              display: "inline-block",
            }}
          />
          <FunctionField
            label="Role Name"
            render={() => (
              <TextField
                source="role.name"
                label="Role"
                sortable={false}
                style={{
                  minWidth: 120,
                  display: "inline-block",
                }}
              />
            )}
          />
          <FunctionField
            label=""
            render={(record: any) => (
              <ChangeTeamInput record={record.member} teams={teams} />
            )}
          />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
}

function ChangeTeamInput({ record, teams }: { record: Person; teams: Team[] }) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [showDialog, setShowDialog] = useState(false);
  const [movePeopleToTeam, { loading }] = useMutation(
    {
      resource: "Customers",
      type: "move_people_to_team",
      payload: {},
    },
    {
      onSuccess: () => {
        notify("Team updated");
        refresh();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  const changeTeam = useCallback(
    (team: Team) => {
      movePeopleToTeam({
        payload: {
          memberIds: [record.id],
          teamId: team.id,
        },
      });
      setShowDialog(false);
    },
    [movePeopleToTeam, record]
  );
  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        startIcon={<GroupIcon />}
        onClick={() => setShowDialog(true)}
        style={{
          textTransform: "none",
        }}
        disabled={loading}
        disableElevation
      >
        Change Team
      </Button>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        style={{
          minWidth: 500,
        }}
      >
        <DialogTitle>Change Team for {formatPersonName(record)}</DialogTitle>
        <List>
          {teams.map((team) => {
            const selected = team.id === record.team?.id;
            return (
              <ListItem
                button
                onClick={() => changeTeam(team)}
                selected={selected}
                disabled={selected}
                key={team.id}
              >
                <ListItemIcon>
                  {selected ? <CheckIcon /> : <GroupIcon />}
                </ListItemIcon>
                <ListItemText primary={team.name} />
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </div>
  );
}

export { renderPeopleTab };
