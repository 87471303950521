import { AiloRN } from "@ailo/ailorn";
import type { Record } from "ra-core";
import React from "react";
import {
  Button,
  Link,
  Show,
  TabbedShowLayout,
  TopToolbar,
  useShowController,
} from "react-admin";
import { DelayedLinearProgress } from "../../common/ui/deprecated/DelayedLinearProgress";
import { renderSubscriptionsTab } from "./renderSubscriptionsTab";
import { renderOrganisationTab } from "./organisationTab";
import { renderPeopleTab } from "./peopleTab";
import { renderTeamsTab } from "./teamsTab";

const CustomerTitle = ({ record }: Record) => {
  return `Customer / ${record.name}`;
};

const CustomerShowActions = ({ data }: Record) => {
  return data ? (
    <TopToolbar>
      <Button
        color="primary"
        component={Link}
        to={{
          pathname: "/Subscriptions/create",
          state: {
            record: {
              customer: data.id,
              startsOn: new Date(),
            },
          },
        }}
        label="Subscribe to Plan"
      />
    </TopToolbar>
  ) : null;
};

// @ts-expect-error ts-migrate(2315) FIXME: Type 'Record' is not generic.
const CustomerShow = (props: Record<string, any>) => {
  const { loaded, record } = useShowController(props);

  if (!loaded) {
    return (
      <div
        style={{
          width: 200,
          margin: 16,
        }}
      >
        <DelayedLinearProgress loading={!loaded} />
      </div>
    );
  }

  return (
    <Show
      {...props}
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type '{}' but required... Remove this comment to see the full error message
      title={<CustomerTitle />}
      // @ts-expect-error ts-migrate(2741) FIXME: Property 'id' is missing in type '{}' but required... Remove this comment to see the full error message
      actions={<CustomerShowActions />}
    >
      <TabbedShowLayout>
        {renderOrganisationTab()}
        {renderSubscriptionsTab()}
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {record.orgType === "Agency" && [
          renderPeopleTab({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            teams: record.teams,
          }),
          renderTeamsTab({
            organisationAilorn: AiloRN.fromString(record!.ailorn),
          }),
        ]}
      </TabbedShowLayout>
    </Show>
  );
};

export { CustomerShow };
