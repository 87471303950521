import React from "react";
import moment from "moment-timezone";
import Box from "@material-ui/core/Box";
import type { Management } from "../../api/deprecatedTypes";

export const ManagementEndDateField = ({
  record: management,
}: {
  record: Management;
}) => {
  const endDateMoment = moment(management.endDate);
  const colour = moment().isAfter(endDateMoment)
    ? "rgb(230,74,25)"
    : "rgb(245,166,35)";
  if (!management.endDate) return <span>—</span>;

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        color: colour,
      }}
    >
      <span>{endDateMoment.format("DD/MM/YYYY")}</span>
      <span>{management.endReason?.label ?? "-"}</span>
    </Box>
  );
};
