import { WebAuth } from "auth0-js";
import { envVars } from "../../../envVars";
import { LocalStorage, localStorageKeys } from "../../../common";

type AuthPromiseResult = {
  isAuthenticated: boolean;
  error?: string;
  returnTo?: string;
};

class AuthService {
  webAuthOptions = {
    domain: envVars.auth0.domain,
    clientID: envVars.auth0.clientId,
    redirectUri:
      envVars.auth0.callbackUrl || `${window.location.origin}/callback`,
    audience: envVars.auth0.audience,
    responseType: "token id_token",
    scope: "openid",
  };

  auth0 = new WebAuth(this.webAuthOptions);

  login(): void {
    const returnTo = LocalStorage.getItem(localStorageKeys.returnTo);
    LocalStorage.removeItem(localStorageKeys.returnTo);
    this.auth0.authorize({
      appState: {
        returnTo,
      },
    });
  }

  showLoginPopup(): void {
    this.auth0.popup.authorize(this.webAuthOptions, () => {
      // do nothing.  The handling will be done by the callback page.
    });
  }

  handleAuthentication(): Promise<AuthPromiseResult> {
    const authPromise = new Promise<AuthPromiseResult>((resolve) => {
      this.auth0.parseHash((err, authResult) => {
        if (
          authResult &&
          authResult.accessToken &&
          authResult.idToken &&
          authResult.expiresIn
        ) {
          this.setSession({
            accessToken: authResult.accessToken,
            idToken: authResult.idToken,
            expiresIn: authResult.expiresIn,
            scope: authResult.scope ?? "",
          });
          resolve({
            isAuthenticated: true,
            returnTo: authResult.appState?.returnTo,
          });
          this.auth0.popup.callback({ hash: "" });
        } else if (err) {
          resolve({
            isAuthenticated: false,
            error: err.error,
          });
        }
      });
    });
    return authPromise;
  }

  setSession(authResult: {
    accessToken: string;
    idToken: string;
    expiresIn: number;
    scope: string;
  }): void {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    const currentUserAiloRN = this.getCurrentUserAiloRNFromAuthScope(
      authResult.scope
    );
    LocalStorage.setItem(localStorageKeys.accessToken, authResult.accessToken);
    LocalStorage.setItem(localStorageKeys.idToken, authResult.idToken);
    LocalStorage.setItem(localStorageKeys.expiresAt, expiresAt);

    if (currentUserAiloRN) {
      LocalStorage.setItem(localStorageKeys.currentUser, currentUserAiloRN);
      window.analytics?.identify(currentUserAiloRN);
    } else {
      LocalStorage.removeItem(localStorageKeys.currentUser);
    }

    if (authResult.scope) {
      LocalStorage.setItem(localStorageKeys.authScope, authResult.scope);
    } else {
      LocalStorage.removeItem(localStorageKeys.authScope);
    }
  }

  clearSession(): void {
    LocalStorage.removeItem(localStorageKeys.accessToken);
    LocalStorage.removeItem(localStorageKeys.idToken);
    LocalStorage.removeItem(localStorageKeys.expiresAt);
    LocalStorage.removeItem(localStorageKeys.currentUser);
    LocalStorage.removeItem(localStorageKeys.authScope);
    window.analytics?.reset();
  }

  getCurrentUserAiloRNFromAuthScope(scope: string): string | null | undefined {
    const findUser = (scope: string) => {
      const roleRegexp = /user:(\w+)/g;
      const match = roleRegexp.exec(scope);

      if (match) {
        return match[1];
      }

      return null;
    };

    const allScopes = scope.split(" ");
    const matches = allScopes.map((s) => findUser(s)).filter((x) => x);
    return matches.length > 0 && matches[0]
      ? `ailo:authz:user:${matches[0]}`
      : undefined;
  }

  async logout(): Promise<void> {
    this.clearSession();
    this.auth0.logout({
      returnTo: `${window.location.origin}/login`,
    });
    // `this.auth0.logout()` resolves when browser initiates the redirect to auth0.
    // Fact is, it shouldn't resolve, because logout finishes only after the user lands on the logout page.
    // Let's return a promise that resolves only after ~3s.
    //
    // This should be enough for the user to be sent away from current page to the logout page,
    // but also not too much, so that if the user cancels the redirect, they can retry the logout.
    //
    // Without this, admin proceeds to login page before logout is done,
    // which ends up with no logout happening at all.
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  }

  hasAccessToken(): boolean {
    return !!LocalStorage.getItem(localStorageKeys.accessToken);
  }

  isTokenExpired(): boolean {
    const expiresAtString = LocalStorage.getItem(localStorageKeys.expiresAt);

    if (expiresAtString != undefined) {
      const expiresAt = JSON.parse(expiresAtString);
      return expiresAt < new Date().getTime();
    }

    return false;
  }

  saveLocation(): void {
    LocalStorage.setItem(localStorageKeys.returnTo, window.location.href);
  }
}

export { AuthService };
