import React from "react";
import moment from "moment-timezone";
import { SubscriptionAmount } from "./subscriptionAmount";
import { ListItemView } from "../../../../../common/ui/lists";

export const SubscriptionListItem = ({ subscription }: any) => {
  const { startsOn, endsOn } = subscription;
  const subscriptionPeriod = getSubscriptionPeriod(startsOn, endsOn);
  return (
    <ListItemView
      header={subscription.plan.id}
      subHeader={subscriptionPeriod}
      rightComponent={<SubscriptionAmount price={subscription.plan.price} />}
    />
  );
};

function getSubscriptionPeriod(
  startsOn: moment.MomentInput,
  endsOn: moment.MomentInput
) {
  return (
    moment(startsOn).format("DD MMM YYYY") +
    (endsOn ? ` - ${moment(endsOn).format("DD MMM YYYY")}` : "")
  );
}
