import { UPDATE_LATEST_PAGE } from "./types";

export type LatestPagePayloadType = {
  nextCursor: string | null | undefined;
  hasMore: boolean | null | undefined;
};
export type UpdateLatestPageAction = {
  type: string;
  payload: LatestPagePayloadType;
  resource: string;
};
export function updateLatestPage(
  resource: string,
  nextCursor: string | null | undefined,
  hasMore: boolean | null | undefined
): UpdateLatestPageAction {
  return {
    type: UPDATE_LATEST_PAGE,
    payload: {
      nextCursor,
      hasMore,
    },
    resource,
  };
}
