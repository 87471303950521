import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Colors, Name, NameAvatars } from "../../../../common";
import { TenancyType } from "../../../Tenancy";

const SelectedTenancy = ({
  tenancyType,
  tenantNames,
}: {
  tenancyType: TenancyType;
  tenantNames: Name[];
}) => {
  const tenancyColor =
    tenancyType === "Active"
      ? Colors.STATUS.SUCCESS
      : tenancyType === "Ingoing"
      ? Colors.STATUS.INFO
      : Colors.STATUS.ERROR;

  return (
    <Grid container justify="space-between" alignItems="center">
      <Box pr={10} style={{ color: tenancyColor }}>
        {tenancyType}
      </Box>
      <Grid item>
        <NameAvatars names={tenantNames} />
      </Grid>
    </Grid>
  );
};

export { SelectedTenancy };
