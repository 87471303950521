import React from "react";
import { BaseBadge } from "./baseBadge";
import { Colors, opacify } from "../colors";

/**
 * @deprecated Use <Badge type="warning" /> instead.
 */
export function Warning({ style, children }: any) {
  return (
    <BaseBadge
      color={Colors.TEXT.ORANGE}
      style={{ ...style, bgcolor: opacify(Colors.STATUS.WARNING, 0.2) }}
    >
      {children}
    </BaseBadge>
  );
}
