import gql from "graphql-tag";
import { GET_ONE } from "ra-core";
import type { RaDataGraphqlClientArgs, User } from "../../deprecatedTypes";

const ONBOARD = "onboard";
export function usersClient({
  raFetchType,
  params,
  resourceString,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query getUserById($id: Int!) {
            user(id: $id) {
              id
              ailoRN
              person {
                id
                ailoRN
              }
            }
          }
        `,
        variables: {
          id: +params.id,
        },

        parseResponse(response: { data: { user: any } }) {
          return {
            data: response.data.user,
          };
        },
      };

    case ONBOARD:
      return {
        query: gql`
          mutation onboardUsers($userIds: [AiloRN!]!) {
            onboardUsers(userIds: $userIds) {
              ...UserFragment
            }
          }
          ${userFragment}
        `,
        variables: params,
        parseResponse: (response: {
          data: {
            onboardUsers: User[];
          };
        }) => {
          return {
            data: response.data.onboardUsers,
          };
        },
      };

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}
const userFragment = gql`
  fragment UserFragment on User {
    id
    auth0Id
    onboardedAt
    onboardingCompletedAt
    createdAt
    person {
      id
      firstName
      lastName
    }
  }
`;
