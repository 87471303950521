/* eslint-disable @typescript-eslint/no-explicit-any */
import currency from "currency.js";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformTenancyForRequest(setupObject: Record<string, any>) {
  if (!setupObject) {
    return {};
  }
  const {
    tenants,
    tenancyStartDate,
    agreementStartDate,
    agreementFixedTermEndDate,
    rentAmount,
    rentPeriod,
    rentEffectiveDate,
    createRent,
    managementId,
  } = setupObject;
  return {
    managementId,
    tenancyDetails: {
      startDate: tenancyStartDate,
      tenants: tenants.map((t: any) => ({
        tenantId: t,
      })),
      tenancyAgreement: {
        startDate: agreementStartDate,
        fixedTermEndDate: agreementFixedTermEndDate,
      },
      rents: createRent
        ? [
            {
              effectiveDate: rentEffectiveDate,
              amountInCents: String(currency(rentAmount).intValue),
              period: rentPeriod,
              category: "Support",
            },
          ]
        : null,
    },
  };
}
