/* eslint-disable @typescript-eslint/no-explicit-any */
import currency from "currency.js";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function transformLiabilityAdjustmentRequest(
  setupObject: Record<string, any>
) {
  if (!setupObject) {
    return {};
  }
  const { amount, ...setupInfo } = setupObject;
  return { ...setupInfo, amount: String(currency(amount).intValue) };
}
