import React from "react";
import { Badge } from "../../../../common";

export function StatementCreateStatus({
  isSuccessful,
  errorMessage,
}: {
  isSuccessful?: boolean;
  errorMessage?: string;
}): React.ReactElement {
  if (isSuccessful) return <Badge type="success">Sent</Badge>;

  if (isSuccessful == null) return <Badge type="warning">In Progress</Badge>;

  if (errorMessage && errorMessage.includes("does not have any payments")) {
    return <Badge type="warning">Not available</Badge>;
  }

  return <Badge type="critical">Failed</Badge>;
}
