import { AiloRN } from "@ailo/ailorn";
import React, { ReactElement } from "react";
import { AutoRentProcessingSwitch } from "./AutoRentProcessingSwitch";

const AutoRentProcessingSwitchContainer = ({
  offlinePaymentProcessingEnabled,
  tenancy,
}: {
  offlinePaymentProcessingEnabled: boolean;
  tenancy: AiloRN<string>;
}): ReactElement => {
  const enablingProperties = {
    enabled: true,
    title: "Automatically pay rent",
    content:
      "Rent will automatically be paid by any deposits into this account. " +
      "Are you sure you want to do this?",
    successNotification: "Automatic rent processing has been enabled",
    failureNotification: "Automatic rent processing update failed",
  };
  const disablingProperties = {
    enabled: false,
    title: "Disable automatic rent payments",
    content:
      "This will disable the automatic payment of rent by any deposits into this account. " +
      "Are you sure you want to do this?",
    successNotification: "Automatic rent processing has been disabled",
    failureNotification: "Automatic rent processing update failed",
  };

  const switchProperties = offlinePaymentProcessingEnabled
    ? disablingProperties
    : enablingProperties;

  return (
    <AutoRentProcessingSwitch
      dialogParams={switchProperties}
      tenancy={tenancy}
    />
  );
};

export { AutoRentProcessingSwitchContainer };
