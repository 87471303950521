import { formatPaymentMethodName } from "@ailo/domain-helpers";
import { Money } from "@ailo/money";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Error, Loading } from "react-admin";
import {
  AutoPayment,
  LegalEntity,
  LiabilityPaymentPlan,
} from "../../api/ResourceName";
import { DescriptionList } from "../../common";
import { useQuery } from "../../common/api/useQuery";
import { MutationSwitch } from "../../common/ui/buttons/MutationSwitch";
import { isTruthy } from "../../common/utils/typeHelpers/isTruthy";

export function TenantMoneySettingsDescriptionList(props: {
  tenantAiloRN: string;
  liabilityId: string;
  edit?: boolean;
}) {
  const { tenantAiloRN, liabilityId, edit } = props;
  const { data, loading, error } = useQuery({
    type: "get_tenant_money_settings",
    resource: LegalEntity,
    payload: {
      tenantAiloRN,
      liabilityId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <DescriptionList
      descriptionStyle={{
        textAlign: "right",
      }}
      items={[
        {
          name: "Autopay",
          description: data?.liabilityPaymentPlans?.length ? (
            "Rent paid using payment schedules"
          ) : (
            <>
              {edit && (
                <MutationSwitch
                  checked={data?.liability?.autoPaymentDetails}
                  mutationParams={
                    data?.liability?.autoPaymentDetails && {
                      type: "cancel_auto_payments",
                      resource: AutoPayment,
                      payload: {
                        liabilityId,
                      },
                    }
                  }
                  confirmation={{
                    title: "Remove Autopay when due",
                    content:
                      "Are you sure you want to remove autopay when due?",
                  }}
                  successNotification="Autopay when due has been removed"
                  failureNotification="Remove autopay when due failed"
                />
              )}
              {data?.liability?.autoPaymentDetails ? (
                <Typography
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {[
                    "Rent paid when due",
                    data?.liability.autoPaymentDetails.paymentMethod
                      ? formatPaymentMethodName(
                          data?.liability.autoPaymentDetails.paymentMethod
                        )
                      : "Unknown payment method",
                  ]
                    .filter(Boolean)
                    .join("\n")}
                </Typography>
              ) : (
                !edit && "Switched off"
              )}
            </>
          ),
        },
        !!data?.liabilityPaymentPlans?.length && {
          name: "Payment schedules",
          description: data.liabilityPaymentPlans.map((plan) => (
            <Box key={plan.id} pb={2}>
              {edit && (
                <MutationSwitch
                  checked={plan.enabled}
                  mutationParams={{
                    type: "cancel_liability_payment_plan",
                    resource: LiabilityPaymentPlan,
                    payload: {
                      liabilityPaymentPlanId: plan.id,
                      payerLegalEntityId: plan.owner,
                    },
                  }}
                  confirmation={{
                    title: "Remove Auto Payment Schedule",
                    content:
                      "Are you sure you want to remove this auto payment schedule?",
                  }}
                  successNotification="Auto Payment Schedule has been removed"
                  failureNotification="Remove auto payment schedule failed"
                />
              )}
              <Typography
                variant="body2"
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {[
                  `${Money.from(plan.amount)} ${plan.recurringDate.format({
                    type: "/FF",
                  })}`,
                  `Next payment ${
                    plan.recurringDate
                      .getNextOccurrenceInDateRange(plan.dateRange)
                      ?.format("DD MMM") ?? "-"
                  }`,
                  plan.dateRange.end &&
                    `End date: ${plan.dateRange.end?.format()}`,
                  plan.paymentMethod
                    ? formatPaymentMethodName(plan.paymentMethod)
                    : "Unknown payment method",
                ]
                  .filter(Boolean)
                  .join("\n")}
              </Typography>
            </Box>
          )),
        },
      ].filter(isTruthy)}
    />
  );
}
