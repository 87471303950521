import React from "react";
import { LegalEntityInput } from "../inputs";

/**
 * There is a bug affecting most/all(?) our reference inputs where a
 * search term can be applied but before the user can select an option,
 * the filter rerenders without the search term.
 *
 * To reproduce:
 * 1. Type a search phrase that yields result(s)
 * 2. Select a result
 * 3. Type a search phrase that includes the currently selected option into the filter
 * 4. The filter will show results but immediately rerender with an empty search phrase
 */
export function LegalEntityFilter(props: any) {
  return (
    <LegalEntityInput
      allowEmpty
      emptyText="All legal entities"
      {...props}
      options={{
        InputProps: {
          // Disable Chrome address autocomplete dropdown
          // ( https://github.com/marmelab/react-admin/issues/5620#issuecomment-773773732 )
          autoComplete: "off",
        },
      }}
    />
  );
}
