/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", reject);
  });

export default (dataProvider: any, config: Record<string, any>) => (
  fetchType: string,
  resource: string,
  params: Record<string, any>
) => {
  if (config[resource] && config[resource].includes(fetchType)) {
    const { data, ...rest_params } = params;
    return convertFileToBase64(data.data).then((base64) => {
      return dataProvider(fetchType, resource, {
        ...rest_params,
        data: { ...data, base64 },
      });
    });
  }

  return dataProvider(fetchType, resource, params);
};
