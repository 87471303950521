import { DELETE } from "react-admin";
import { useCallback } from "react";
import { useMutation } from "../../../common/api/useMutation";
import {
  DeleteCentrepayDirectiveInput,
  DeleteCrnInput,
} from "../../../api/graphql/types.generated";
import {
  CentrepayCrn,
  CentrepayPaymentDirective,
} from "../../../api/ResourceName";

export function useDeleteCrnAndDirective(
  legalEntity: string,
  managingEntity: string,
  tenancy: string
): () => void {
  const [deleteCrnMutation] = useMutation<{ input: DeleteCrnInput }>({
    resource: CentrepayCrn,
    type: DELETE,
  });

  const [deleteCentrepayDirectiveMutation] = useMutation<{
    input: DeleteCentrepayDirectiveInput;
  }>({
    resource: CentrepayPaymentDirective,
    type: DELETE,
  });
  return useCallback(async () => {
    await deleteCrnMutation({
      payload: {
        input: {
          legalEntity,
          managingEntity,
        },
      },
    });
    await deleteCentrepayDirectiveMutation({
      payload: {
        input: {
          tenancy,
          legalEntity,
          managingEntity,
        },
      },
    });
  }, [
    deleteCrnMutation,
    deleteCentrepayDirectiveMutation,
    tenancy,
    legalEntity,
    managingEntity,
  ]);
}
