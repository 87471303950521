import React, { useState } from "react";
import { CreateOverdraftAllowanceDialog } from "./CreateOverdraftAllowanceDialog";
import { TransferButton } from "./TransferButton";

export function CreateOverdraftAllowanceButton({
  walletId,
}: {
  walletId: string;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <TransferButton onClick={onClick}>
        Create Overdraft Allowance
      </TransferButton>
      <CreateOverdraftAllowanceDialog
        open={open}
        onClose={onClose}
        walletId={walletId}
      />
    </div>
  );
}
