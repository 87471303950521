import { Button } from "@material-ui/core";
import React from "react";
import { Colors } from "../../colors";

/**
 * @deprecated Use <Button /> directly
 */
const CloseButton = ({ onClick }: any) => (
  <Button
    disableElevation
    style={{
      textTransform: "none",
      color: Colors.AILO_RED,
    }}
    onClick={onClick}
  >
    Close
  </Button>
);

export { CloseButton };
