import React from "react";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Create } from "../../common";

const requiredValidate = [required()];
const useStyles = makeStyles({
  first_name: {
    display: "inline-block",
  },
  last_name: {
    display: "inline-block",
    marginLeft: 32,
  },
  email: {
    width: 544,
  },
  address: {
    maxWidth: 544,
  },
  zipcode: {
    display: "inline-block",
  },
  city: {
    display: "inline-block",
    marginLeft: 32,
  },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: {
    display: "inline-block",
  },
  confirm_password: {
    display: "inline-block",
    marginLeft: 32,
  },
});

const PersonCreate = (props: Record<string, any>) => {
  const classes = useStyles();
  return (
    <Create redirect="list" {...props}>
      <SimpleForm>
        <TextInput
          autoFocus
          source="firstName"
          formClassName={classes.first_name}
          validate={requiredValidate}
        />
        <TextInput
          source="lastName"
          formClassName={classes.last_name}
          validate={requiredValidate}
        />
        <BooleanInput
          source="createUser"
          label="Create User"
          disabled={false}
          defaultValue
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.createUser) {
              return (
                <TextInput
                  type="email"
                  source="emailAddress"
                  validation={{
                    email: true,
                  }}
                  fullWidth
                  formClassName={classes.email}
                  validate={requiredValidate}
                />
              );
            }
          }}
        </FormDataConsumer>
        <TextInput source="phoneNo" fullWidth formClassName={classes.email} />
        <TextInput label="Job Title" source="jobTitle" fullWidth />
        <TextInput label="Tax File Number" source="taxFileNumber" fullWidth />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.createUser) {
              return (
                <div>
                  <TextInput
                    label="Invited By Admin"
                    source="metadata.invitedByAdmin"
                    defaultValue="true"
                    disabled
                  />
                  <SelectInput
                    label="Default Application"
                    validate={requiredValidate}
                    source="metadata.defaultApplication"
                    choices={[
                      {
                        id: "ailo",
                        name: "Ailo",
                      },
                      {
                        id: "ailo-consumer",
                        name: "GT Consumer",
                      },
                      {
                        id: "ailo-agency",
                        name: "GT Agency",
                      },
                      {
                        id: "lpma",
                        name: "LPMA",
                      },
                      {
                        id: "forum",
                        name: "Forum",
                      },
                    ]}
                  />
                </div>
              );
            }

            return <div />;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export { PersonCreate };
