import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { CompanyAvatar } from "../../common/ui/avatars";
import { CopyToClipboardButton } from "../../common";
import { LegalEntityField } from "../../common/fields";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 140,
  },
}));
export const CompanyDetailCard = ({
  company,
  additionalInfo,
  resource,
  status,
  preferOrganisationName,
}: any) => {
  const classes = useStyles();
  const name =
    (preferOrganisationName && company.organisation?.name) ||
    company.registeredEntityName;

  return (
    <Card
      elevation={0}
      classes={{
        root: classes.root,
      }}
    >
      <CardHeader
        style={{
          alignItems: "flex-start",
        }}
        avatar={
          <CompanyAvatar
            name={name}
            style={{
              width: 40,
              height: 40,
              fontSize: "1rem",
            }}
          />
        }
        title={
          <Box display="flex">
            <LegalEntityField
              record={{ company }}
              source="company"
              preferOrganisationName={preferOrganisationName}
            />
          </Box>
        }
        subheader={
          <>
            <Box mt={1}>
              {company.id}
              <CopyToClipboardButton value={company.id} />
            </Box>
            {additionalInfo && <Box mt={1}>{additionalInfo}</Box>}
          </>
        }
      />
    </Card>
  );
};
