import React, { useState } from "react";
import { CardContent } from "@material-ui/core";
import { GET_ONE, Loading, TextField, useNotify, useQuery } from "react-admin";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import { sum } from "lodash";
import { isPresent } from "ts-is-present";
import { DetailCard, MoneyField, VirtualAccountData } from "../../../../common";
import { BondAccount, TenancyBond } from "../../../../api";
import { GetTenancyBondDetailsQuery } from "../../../../api/resources/TenancyBond/tenancyBondsClient.generated";
import { BondStatusField } from "./BondStatusField";
import { BondClaimsList } from "./BondClaimsList";
import { DisburseBondClaimsDialogControl } from "./DisburseBondClaimsDialogControl";
import { BondAccountBalance } from "./BondAccountBalance";

type Props = {
  tenancyId: string;
  managingEntity?: string;
};

export const TenancyBondCard = ({
  tenancyId,
  managingEntity,
}: Props): React.ReactElement => {
  const notify = useNotify();
  const [disabled, setIsDisabled] = useState(true);

  const {
    data,
    loading,
    error,
  }: {
    data?: GetTenancyBondDetailsQuery["tenancy"];
    loading: boolean;
    error?: string;
  } = useQuery({
    type: GET_ONE,
    resource: TenancyBond,
    payload: {
      id: tenancyId,
    },
  });

  const {
    data: bondAccountData,
    loading: bondAccountLoading,
    error: bondAccountError,
  }: {
    data?: VirtualAccountData[];
    loading: boolean;
    error?: string;
  } = useQuery({
    type: GET_ONE,
    resource: BondAccount,
    payload: {
      entity: managingEntity,
    },
  });

  if (loading || bondAccountLoading) {
    return <Loading />;
  }

  if (error) {
    notify(error);
  }
  if (bondAccountError) {
    notify(bondAccountError);
  }

  if (!data?.bond) {
    return <></>;
  }

  const totalClaimed = calculateTotalClaimed(data);
  const walletOwners = getBondAccountWalletOwners(bondAccountData);

  return (
    <DetailCard
      title="Tenancy bond & claims"
      action={
        <DisburseBondClaimsDialogControl
          bondId={data.bond.id}
          disabled={disabled}
        />
      }
    >
      <CardContent>
        <BoxedShowLayout
          record={{
            ...data,
            totalClaimed: {
              cents: totalClaimed,
            },
          }}
        >
          <RaBox display="flex" style={{ marginBottom: 10 }}>
            <RaBox style={{ flex: 1 }}>
              <TextField label="Reference" source="bond.reference" />
            </RaBox>
            <RaBox style={{ flex: 1 }}>
              <MoneyField label="Amount" source="bond.amount" />
            </RaBox>
            <RaBox style={{ flex: 1 }}>
              <BondStatusField source="bond" />
            </RaBox>
            <RaBox style={{ flex: 1 }}>
              <MoneyField label="Total Claimed" source="totalClaimed" />
            </RaBox>
          </RaBox>

          <BondClaimsList source="bond.claims" />
          {walletOwners &&
            walletOwners.map((walletOwner) => {
              return (
                <BondAccountBalance
                  walletOwnerRef={walletOwner}
                  setIsDisabled={
                    walletOwners.length === 1 ? setIsDisabled : undefined
                  }
                  totalClaimed={totalClaimed}
                />
              );
            })}
        </BoxedShowLayout>
      </CardContent>
    </DetailCard>
  );
};

function calculateTotalClaimed(
  data: NonNullable<GetTenancyBondDetailsQuery["tenancy"]>
) {
  return sum(data.bond?.claims?.map((element) => element.amount.cents));
}

function getBondAccountWalletOwners(
  bondAccounts?: VirtualAccountData[]
): string[] {
  return (bondAccounts || [])
    .map(
      (bondAccount) =>
        bondAccount.paymentMethodCompanion?.paymentMethod.wallet.owner.reference
    )
    .filter(isPresent);
}
