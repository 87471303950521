import { formatAddress } from "@ailo/domain-helpers";
import { get } from "lodash";
import React from "react";
import { Property } from "../../api/ResourceName";
import { LinkField } from "./LinkField";
import {
  InjectedFieldProps,
  PublicFieldProps,
} from "./utils/react-admin-types";

export function PropertyField({
  record,
  source,
  managementId: managementIdProp,
  managementIdSource,
  tenancyId: tenancyIdProp,
  tenancyIdSource,
}: {
  source: string;
  managementId?: string;
  managementIdSource?: string;
  tenancyId?: string;
  tenancyIdSource?: string;
} & PublicFieldProps &
  InjectedFieldProps) {
  const property = get(record, source);
  const managementId =
    managementIdProp ??
    (managementIdSource ? get(record, managementIdSource) : undefined);
  const tenancyId =
    tenancyIdProp ??
    (tenancyIdSource ? get(record, tenancyIdSource) : undefined);

  if (!property) {
    return null;
  }

  const text = property.address ? formatAddress(property.address) : property.id;
  return (
    <LinkField
      record={record}
      source={`${source}.id`}
      sourceResource={Property}
      renderText={() => text}
      to={`/${Property}/${property.id}/show${
        managementId || tenancyId
          ? `?${new URLSearchParams({
              ...(managementId
                ? {
                    management: managementId,
                  }
                : undefined),
              ...(tenancyId
                ? {
                    tenancy: tenancyId,
                  }
                : undefined),
            }).toString()}`
          : undefined
      }`}
    />
  );
}
PropertyField.defaultProps = {
  addLabel: true,
  label: "Property",
};
