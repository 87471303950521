import Box from "@material-ui/core/Box";
import React from "react";
import { formatMoney } from "../../../../../common/ui/deprecated";

export const SubscriptionAmount = ({ price }: any) => {
  return (
    <Box {...({ justify: "flex-end" } as any)}>
      <Box textAlign="right" marginBottom="2px">
        {formatMoney(price)}
      </Box>
    </Box>
  );
};
