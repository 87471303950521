import { AiloRN, services } from "@ailo/ailorn";
import { DateRange, LocalDate } from "@ailo/date";
import { title } from "case";
import React, { ReactNode } from "react";
import { LinkField } from "../../common";
import { linkifyLedgerDescription } from "../../resourceViews/Liability/linkifyLedgerDescription";

// eslint-disable-next-line
export function formatLiabilityEntryDescription(entry: any): ReactNode {
  if (entry.__typename === "PlanBasedLiabilityEntry") {
    const dateRange = DateRange.from({
      start: LocalDate.from(entry.cycleStartDate),
      end: LocalDate.from(entry.cycleEndDate),
    });
    return `${title(entry.frequency ?? "")} payment for ${dateRange?.format()}`;
  }

  const formattedDescription = linkifyLedgerDescription(
    entry.description ?? entry.businessTransaction?.description
  );

  if (entry.__typename === "PaymentLiabilityEntry") {
    return (
      <>
        {formattedDescription}
        <br />
        Business Transaction:{" "}
        <LinkField
          record={{
            __typename: "BusinessTransaction",
            // TODO pass ID instead, once transaction view is fixed (so it accepts ID instead of ARN as url param)
            id: AiloRN.of(
              services.Ledger.businessTransaction,
              entry.businessTransaction.id
            ).toString(),
          }}
          includeApiTypeInLinkText={false}
          renderText={() => entry.businessTransaction.id}
        />
      </>
    );
  }

  return formattedDescription;
}
