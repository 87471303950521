import React, { useCallback, useEffect, useState } from "react";
import { ListItemView } from "./ListItemView";

interface Props {
  children: React.ReactNode | null;
  onToggled?: (isOpen: boolean) => void;
  isInitiallyOpen?: boolean;
  canToggle?: boolean;
}

function AccordionListItemView({
  children,
  onToggled,
  isInitiallyOpen = false,
  canToggle = true,
  ...rest
}: Record<string, unknown> & Props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  function renderAccordionChild() {
    return <div style={{ paddingBottom: 16 }}>{children}</div>;
  }

  const invokeOnToggled = useCallback(
    (isCurrentlyOpen: boolean) => {
      onToggled?.(isCurrentlyOpen);
    },
    [onToggled]
  );

  function toggleAndNotify() {
    setIsOpen((isCurrentlyOpen) => {
      const shouldOpen = !isCurrentlyOpen;
      invokeOnToggled(shouldOpen);
      return shouldOpen;
    });
  }

  useEffect(() => {
    invokeOnToggled(isOpen);
  }, [invokeOnToggled, isOpen]);

  return (
    <ListItemView
      {...rest}
      onTitleClick={canToggle ? toggleAndNotify : undefined}
    >
      {isOpen ? renderAccordionChild() : null}
    </ListItemView>
  );
}

function Loading() {
  return <ListItemView.Loading />;
}

AccordionListItemView.Loading = Loading;

export { AccordionListItemView };
