import Big from "big.js";
import gql from "graphql-tag";
import { CREATE } from "ra-core";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import { PaymentMethodDestinationInput } from "../../graphql/types.generated";

const CANCEL_AUTO_WITHDRAW_PLAN = "cancel_auto_withdraw_plan";

function autoWithdrawPlansClient({
  raFetchType,
  params,
}: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case CANCEL_AUTO_WITHDRAW_PLAN:
      return {
        query: gql`
          mutation cancelAutoWithdrawPlan($autoWithdrawPlanId: ID!) {
            cancelAutoWithdrawPlan(
              cancelAutoWithdrawInput: {
                autoWithdrawPlanId: $autoWithdrawPlanId
              }
            ) {
              id
              enabled
            }
          }
        `,
        variables: {
          autoWithdrawPlanId: params.autoWithdrawPlanId,
        },
        parseResponse: (response: {
          data: { cancelAutoWithdrawPlan: any };
        }) => ({
          data: response.data.cancelAutoWithdrawPlan,
        }),
      };

    case CREATE: {
      return {
        query: gql`
          mutation createAutoWithdrawPlanV2(
            $walletId: ID!
            $paymentMethodDestinations: [PaymentMethodDestinationInput!]!
            $frequency: QuartzPlanFrequency!
            $anniversary: Int
            $anniversaryDaysOfMonth: [Int!]
            $startDate: Date!
            $payerLegalEntityId: AiloRN!
            $lastDayOfTheMonth: Boolean
            $userFacingDescription: String
          ) {
            createAutoWithdrawPlanV2(
              createAutoWithdrawPlanInput: {
                walletId: $walletId
                paymentMethodDestinations: $paymentMethodDestinations
                frequency: $frequency
                anniversary: $anniversary
                anniversaryDaysOfMonth: $anniversaryDaysOfMonth
                startDate: $startDate
                payerLegalEntityId: $payerLegalEntityId
                lastDayOfTheMonth: $lastDayOfTheMonth
                userFacingDescription: $userFacingDescription
              }
            ) {
              id
              enabled
            }
          }
        `,
        variables: {
          walletId: params.walletId,
          paymentMethodDestinations: buildPaymentMethodDestinations(
            params.paymentMethodDestinations
          ),
          frequency: params.frequency,
          startDate: params.startDate,
          anniversary: params.anniversary,
          anniversaryDaysOfMonth: params.anniversaryDaysOfMonth,
          lastDayOfTheMonth: params.isLastDayOfTheMonth,
          payerLegalEntityId: params.payerLegalEntityId,
          userFacingDescription: params.userFacingDescription,
        },
        parseResponse: (response: {
          data: { createAutoWithdrawPlanV2: any };
        }) => ({
          data: response.data.createAutoWithdrawPlanV2,
        }),
      };
    }

    case "wallet_has_auto_withdraw_plan_enabled": {
      return {
        query: gql`
          query getWalletAutoWithdrawPlans($walletId: ID!) {
            wallet: walletById(walletId: $walletId) {
              id
              autoWithdrawPlans(enabled: true, disableOwnerFilter: true) {
                pageInfo {
                  total
                }
              }
            }
          }
        `,
        variables: {
          walletId: params.walletId,
        },

        parseResponse(response: { data: { wallet: any } }) {
          return {
            data: response.data.wallet.autoWithdrawPlans.pageInfo.total > 0,
          };
        },
      };
    }

    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

function buildPaymentMethodDestinations(
  source: { [id in string]?: number }
): PaymentMethodDestinationInput[] {
  return Object.keys(source)
    .filter((id) => source[id])
    .map((id) => ({
      paymentMethodId: id,
      share: Big(source[id]!).mul(100).toNumber(),
    }));
}

export { autoWithdrawPlansClient };
