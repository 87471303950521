import { List, Map, fromJS } from "immutable";
import type { LatestPagePayloadType } from "../actions";

export type ResourcePages = List<Map<string, any>>;
export type PaginationStateType = Map<string, ResourcePages>;

function excludeUndefinedValues(object: Record<string, any>) {
  return JSON.parse(JSON.stringify(object));
}

function updateLatest<T extends Map<unknown, unknown>>(
  list: List<T>,
  newItem: T
): List<T> {
  if (newItem.isEmpty()) return list;
  const itemIndex = list.indexOf(newItem);
  if (itemIndex === -1) return list.push(newItem);
  return list.filter((val, i) => i <= itemIndex);
}

export class PaginationState {
  static defaultState: PaginationStateType;

  state: PaginationStateType;

  constructor(state: PaginationStateType) {
    this.state = state;
  }

  updateLatestResourcePage(
    resource: string,
    latestPagePayload: LatestPagePayloadType
  ) {
    const filteredPayload = excludeUndefinedValues(latestPagePayload);
    const latestPayloadAsMap = fromJS(filteredPayload);
    const newState = this.state.update(resource, List(), (list) =>
      updateLatest(list, latestPayloadAsMap)
    );
    return new PaginationState(newState);
  }

  removeLastPageInResource(resource: string) {
    const newState = this.state.update(resource, List(), (list) =>
      list.pop().pop()
    );
    return new PaginationState(newState);
  }

  resetResource(resource: string) {
    const newState = this.state.update(resource, (list) => List());
    return new PaginationState(newState);
  }

  current(): PaginationStateType {
    return this.state;
  }
}
PaginationState.defaultState = Map({});
