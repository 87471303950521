import React, { ReactElement } from "react";
import { AiloRN } from "@ailo/ailorn";
import Alert from "@material-ui/lab/Alert";
import { Box, CardActions, CardContent } from "@material-ui/core";
import { SimpleShowLayout } from "react-admin";
import { DetailCard } from "../DetailCard/DetailCard";
import { CopyableTextField, LinkField, MoneyField } from "../../../fields";
import { TransferToTrustDialogControl } from "./TransferToTrustDialogControl";

export interface VirtualAccountData {
  id?: string;
  paymentMethodCompanion?: {
    id: string;
    paymentMethod: {
      wallet: {
        id: string;
        totalBalance: { cents: number };
        availableBalance: { cents: number };
        owner: { reference: string };
      };
      id: string;
      externalId?: string | null;
      accountNumber?: string | null;
      bsb?: string | null;
    };
  } | null;
}

function VirtualAccountCardItem({
  agency,
  walletOwnerRef,
  wallet,
  data,
  title,
  transferToTrustDescriptionGuide,
  additionalActions,
  footerActions,
}: {
  agency: AiloRN;
  walletOwnerRef: string;
  wallet: {
    id: string;
    availableBalance: { cents: number };
    outstandingBalance: { cents: number };
    idempotencyKey: string;
  };
  data: VirtualAccountData;
  title: string;
  transferToTrustDescriptionGuide: string;
  additionalActions?: React.ReactElement;
  footerActions?: React.ReactElement;
}): ReactElement | null {
  return (
    <DetailCard
      title={title}
      action={
        <Box display="flex">
          {additionalActions}
          <TransferToTrustDialogControl
            agency={agency}
            escrowAccount={AiloRN.fromString(walletOwnerRef)}
            idempotencyKey={wallet.idempotencyKey}
            availableBalance={wallet.availableBalance}
            disabled={false}
            alertComponent={
              <Alert style={{ width: "auto" }} severity="warning">
                {transferToTrustDescriptionGuide}
              </Alert>
            }
          />
        </Box>
      }
    >
      <CardContent style={{ marginTop: -30, marginLeft: -14, padding: 0 }}>
        <SimpleShowLayout
          record={{
            ...wallet,
            paymentMethod: data.paymentMethodCompanion?.paymentMethod,
          }}
        >
          <LinkField label="ID" sourceResource="Wallet" source="id" />
          <MoneyField label="Available Balance ($)" source="availableBalance" />
          <MoneyField label="Total Balance ($)" source="totalBalance" />
          <CopyableTextField
            label="Account Number"
            source="paymentMethod.accountNumber"
          />
          <CopyableTextField label="BSB" source="paymentMethod.bsb" />
        </SimpleShowLayout>
      </CardContent>
      {footerActions && <CardActions>{footerActions}</CardActions>}
    </DetailCard>
  );
}

export { VirtualAccountCardItem };
