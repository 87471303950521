import React from "react";
import {
  Datagrid,
  ExportButton,
  Filter,
  FunctionField,
  SelectInput,
  TextField,
  TopToolbar,
} from "react-admin";
import moment from "moment-timezone";

import { downloadCSV, useListContext } from "ra-core";
import jsonExport from "jsonexport";
import { AiloRN } from "@ailo/ailorn/build";
import { LinkField, List, MoneyField, DateFilter } from "../../../common";

const SearchFilter = (props: Record<string, any>): React.ReactElement => (
  <Filter {...props}>
    <DateFilter label="Banking Date" alwaysOn />
    <SelectInput
      label="Category"
      source="categoryId"
      choices={[
        {
          id: "1",
          name: "direct debit - payment",
        },
        {
          id: "2",
          name: "direct debit - manual adjustment",
        },
        {
          id: "3",
          name: "direct debit - early reversal",
        },
        {
          id: "4",
          name: "direct debit - late reversal",
        },
        {
          id: "5",
          name: "direct credit - payment",
        },
        {
          id: "6",
          name: "direct credit - refund",
        },
        {
          id: "7",
          name: "direct credit - manual adjustment",
        },
        {
          id: "8",
          name: "direct credit - early reversal",
        },
        {
          id: "9",
          name: "direct credit - late reversal",
        },
        {
          id: "10",
          name: "credit card - payment",
        },
        {
          id: "11",
          name: "credit card - refund",
        },
        {
          id: "12",
          name: "bpay payable - payment",
        },
        {
          id: "14",
          name: "virtual account debit - payment",
        },
        {
          id: "15",
          name: "write-off",
        },
      ]}
      alwaysOn
    />
  </Filter>
);

const PostListActions = () => {
  const { total } = useListContext();
  return (
    <TopToolbar>
      <ExportButton maxResults={total} />
    </TopToolbar>
  );
};
const exporter = (records: any[]) => {
  const recordsForExport = records.map(
    (record: {
      id: number;
      categoryId: number;
      paymentType: string;
      transactionType: string;
      total: {
        cents: number;
      };
      fees: {
        cents: number;
      };
      businessTransactionId: string;
      paymentTransactionId: string;
    }) => {
      return {
        id: record.id,
        categoryId: record.categoryId,
        paymentType: record.paymentType,
        transactionType: record.transactionType,
        total: record.total.cents,
        fees: record.fees.cents,
        businessTransactionId: AiloRN.fromString(record.businessTransactionId)
          .internalId,
        paymentTransactionId: record.paymentTransactionId
          ? AiloRN.fromString(record.paymentTransactionId).internalId
          : undefined,
      };
    }
  );
  jsonExport(recordsForExport, (err: any, csv: any) => {
    downloadCSV(csv, "transactions");
  });
};

const ReconciliationDetailsList = (props: any) => {
  const afterDateDefaultFilter = moment()
    .subtract(1, "days")
    .format(moment.HTML5_FMT.DATE);

  const beforeDateDefaultFilter = moment().format(moment.HTML5_FMT.DATE);

  return (
    <List
      {...props}
      perPage={50}
      title="Bank Level Reconciliation Details"
      bulkActionButtons={false}
      filters={<SearchFilter />}
      filterDefaultValues={{
        afterDate: afterDateDefaultFilter,
        beforeDate: beforeDateDefaultFilter,
      }}
      actions={<PostListActions />}
      exporter={exporter}
    >
      <Datagrid>
        <TextField label="Payment Type" source="paymentType" />
        <TextField label="Transaction Type" source="transactionType" />
        <MoneyField label="Total Amount ($)" source="total" showAbsolute />
        <MoneyField label="Fee Amount ($)" source="fees" showAbsolute />
        <FunctionField
          {...props}
          label="Transaction Id"
          render={(record: any) => {
            return (
              <LinkField
                label="Transaction Id"
                record={record}
                source="businessTransactionId"
                sourceResource="Transactions"
                to={`/Transactions/${record.businessTransactionId}/show`}
              />
            );
          }}
        />
        <LinkField
          label="Payment Id"
          source="paymentTransactionId"
          includeApiTypeInLinkText={false}
          linkable={false}
        />
      </Datagrid>
    </List>
  );
};

export { ReconciliationDetailsList };
