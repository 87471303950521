import React from "react";
import { EditProps, UPDATE } from "react-admin";
import { OperationData } from "../../api";
import { Fee } from "../../api/ResourceName";
import { Edit } from "../../common";
import { FeeForm } from "./FeeFormV2";

export const FeeEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <FeeForm
        redirect={(
          _path: string,
          _id: string,
          data: NonNullable<OperationData<typeof Fee, typeof UPDATE>>
        ) =>
          props.history ? props.history.goBack() : `/${Fee}/${data.id}/show`
        }
      />
    </Edit>
  );
};
