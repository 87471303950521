import {
  Channel,
  CommunicationType,
} from "../../../../../../api/graphql/types.generated";

export interface SupportedNotificationPreference {
  description: string;
  communicationType: CommunicationType;
  channels: Channel[];
}

export const supportedNotificationPreferences: SupportedNotificationPreference[] = [
  {
    description: "Default",
    communicationType: CommunicationType.Default,
    channels: [Channel.Email, Channel.Push],
  },
  {
    description: "Ownership Statement",
    communicationType: CommunicationType.InvestorStatement,
    channels: [Channel.Email],
  },
  {
    description: "EOFY Statement",
    communicationType: CommunicationType.TaxSummary,
    channels: [Channel.Email],
  },
  {
    description: "Transfer Summary",
    communicationType: CommunicationType.TransferSummary,
    channels: [Channel.Email],
  },
];
