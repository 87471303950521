import React from "react";
import { Name, NameAvatarGroup } from ".";

const NameAvatars = ({ names, style }: { names: Name[]; style?: any }) => {
  if (!names) return null;
  const sortedNames = getSortedNameAvatars(names);
  return <NameAvatarGroup names={sortedNames} style={style} />;
};

function getSortedNameAvatars(names: Name[]) {
  return names.sort((name1, name2): number => {
    if (!name1.lastName && !name2.lastName) return 0;
    if (!name1.lastName) return -1;
    if (!name2.lastName) return 1;
    if (name1.lastName > name2.lastName) return 1;
    if (name1.lastName < name2.lastName) return -1;
    return 0;
  });
}

export { NameAvatars };
