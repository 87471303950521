import React from "react";
import { Datagrid, DateInput, Filter } from "react-admin";
import moment from "moment-timezone";
import { List } from "../../../common";
import { MoneyField } from "../../../common";
import { LinkToRelatedWallets } from "./linkToRelatedWallets";

const SearchFilter = (props: Record<string, any>) => (
  <Filter {...props}>
    <DateInput label="Banking Date Before" source="beforeDate" alwaysOn />
  </Filter>
);

const LiabilityCategoriesList = (props: any) => {
  const beforeDateDefaultFilter = moment().format(moment.HTML5_FMT.DATE);

  return (
    <List
      {...props}
      title="Liabilities"
      bulkActionButtons={false}
      filters={<SearchFilter />}
      filterDefaultValues={{
        beforeDate: beforeDateDefaultFilter,
      }}
      pagination={false}
    >
      <Datagrid>
        <LinkToRelatedWallets label="Category" />
        <MoneyField
          label="Amount Liable ($)"
          textAlign="right"
          source="amountLiable"
        />
      </Datagrid>
    </List>
  );
};

export { LiabilityCategoriesList };
