/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { Link } from "@material-ui/core";
import {
  FormDataConsumer,
  Labeled,
  TextInput,
  required,
  useMutation,
  useNotify,
  useRefresh,
  UseMutationValue,
} from "react-admin";
import { Alert } from "@material-ui/lab";
import { MoneyInterface } from "@ailo/money";
import {
  ErrorDialog,
  minMoney,
  MoneyInput,
  SimpleFormDialog,
} from "../../common";
import { WalletTransfer } from "../../api";
import {
  descriptionHelperText,
  maxUserFacingDescLength,
  walletStyles,
} from "./helpers";

type FormData = {
  walletId: number;
  internalNotes: string;
  userFacingDescription: string;
  amount: MoneyInterface;
  approvedBy: string;
};

const useWriteOff = ({
  onSuccess,
}: {
  onSuccess?(): void;
}): UseMutationValue => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    {
      type: "write_off",
      resource: WalletTransfer,
    },
    {
      onSuccess: ({ data }) => {
        const error = data?.error;
        if (error) {
          notify(`Failed to write off wallet balance. Error: ${error}`);
        } else {
          refresh();
          notify(`Wallet balance has been written off`);
          onSuccess?.();
        }
      },
      onFailure: (error) => {
        notify(`Failed to write off wallet balance. Error: ${error}`);
      },
    }
  );
};

type Props = { open: boolean; onClose: () => void; walletId: string };

export const WriteOffDialog = ({
  open,
  onClose,
  walletId,
}: Props): React.ReactElement => {
  const [writeOffWalletBalance, { error }] = useWriteOff({
    onSuccess: onClose,
  });
  const [lastErrorWhenClosed, setLastErrorWhenClosed] = useState(error);
  const actualError = lastErrorWhenClosed !== error ? error : undefined;
  const classes = walletStyles();

  const submit = async (formData: FormData) => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `Are you sure you would like to move ${formData.amount.cents} cents from Ailo Write-Off Wallet to walletId ${walletId}? `
      )
    ) {
      return;
    }
    await writeOffWalletBalance({
      payload: {
        amountInCents: formData.amount.cents,
        walletId,
        description: `${formData.internalNotes}. Approved by ${formData.approvedBy}`,
        userFacingDescription: formData.userFacingDescription,
      },
    });
  };

  if (actualError) {
    return (
      <ErrorDialog
        open={open}
        title="Writing off wallet balance failed"
        error={actualError}
        onClose={() => {
          setLastErrorWhenClosed(error);
          onClose?.();
        }}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        walletId,
        userFacingDescription: "Credit from Ailo",
      }}
      title="Write Off Wallet Balance"
      open={open}
      validate={(values: FormData) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: any = {};
        if (!values.walletId) {
          errors.walletId = ["A wallet is required"];
        }
        return errors;
      }}
      submitLabel="Transfer"
      save={submit}
      onClose={onClose}
    >
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <>
              <Alert
                severity="warning"
                icon={false}
                style={{ marginBottom: 16 }}
              >
                Only do this if you know what you are doing! This is going to
                move money from the Ailo Write Off wallet to the current wallet.
                This should be used in a scenario when a negative balance needs
                to be written off, or Ailo is covering an expense. These actions
                must be authorised in accordance to the{" "}
                <Link
                  href="https://internal.help.ailo.io/hc/en-au/articles/4406587464079-Write-off-Policy"
                  target="_blank"
                  color="secondary"
                >
                  Ailo Write-Off Policy
                </Link>{" "}
                and added to the{" "}
                <Link
                  href="https://docs.google.com/spreadsheets/d/1A7Lz_hlLxdF4W_EoySQYYwlgQVZyHYXEN3-SI8pc0wU/edit#gid=0"
                  target="_blank"
                  color="secondary"
                >
                  CS Register of Discretionary Actions
                </Link>
                .
              </Alert>
              <Labeled label="How much? (in $)" fullWidth>
                <MoneyInput
                  source="amount"
                  validate={[required(), minMoney({ cents: 1 })].filter(
                    Boolean
                  )}
                />
              </Labeled>

              <Labeled label="Transfer details">
                <Alert severity="info" className={classes.transferDetails}>
                  The description will be visible by the investor on the
                  ownership statement.
                </Alert>
              </Labeled>

              <TextInput
                label="Description"
                source="userFacingDescription"
                validate={[required(), maxUserFacingDescLength()].filter(
                  Boolean
                )}
                helperText={descriptionHelperText(
                  formData.userFacingDescription
                )}
                fullWidth
              />
              <TextInput
                source="internalNotes"
                validate={[required()].filter(Boolean)}
                helperText="Include ticket number if possible eg: ZD-12345 or L3S-12345"
                fullWidth
              />
              <TextInput
                source="approvedBy"
                validate={[required()].filter(Boolean)}
                helperText="Name of a manager who approved this action"
                fullWidth
              />
            </>
          );
        }}
      </FormDataConsumer>
    </SimpleFormDialog>
  );
};
