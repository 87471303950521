import React, { ReactElement } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { Colors, opacify } from "../../common";

const useStyles = makeStyles({
  invitedChip: {
    color: Colors.STATUS.SUCCESS,
    backgroundColor: opacify(Colors.STATUS.SUCCESS, 0.1),
  },
  notInvitedChip: {
    color: Colors.STATUS.WARNING,
    backgroundColor: opacify(Colors.STATUS.WARNING, 0.1),
  },
});

interface Props {
  isInvited: boolean;
  label: string;
}

export function ConsumerChip({ isInvited, label }: Props): ReactElement {
  const classes = useStyles();

  return (
    <Chip
      className={isInvited ? classes.invitedChip : classes.notInvitedChip}
      label={label}
    />
  );
}
