import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { FunctionField, SimpleShowLayout, TextField } from "react-admin";
import Box from "@material-ui/core/Box";
import { OperationData, Person } from "../../../../../api";
import { CopyableTextField, IdField, LinkField } from "../../../../../common";
import { getAuth0UserUrl } from "../../../../../common/api/getAuth0UserUrl";
import { formatAddress } from "../../../../../common/utils";
import { formatDate } from "../../../../../common/utils/formatDate";
import {
  GTAgencyPasswordResetButton,
  GTConsumerPasswordResetButton,
} from "../../../../../features/User";
import { CreatePersonUserButton } from "./CreatePersonUserButton";
import { KycVerifiedChip } from "./kycVerifiedChip";
import { EditPersonEmailButton } from "./editPersonEmailButton";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    "&:first-child": {
      padding: 0,
    },
  },
}));

type PersonRecord = OperationData<typeof Person, "GET_ONE">;

export const PersonBasicInfo = ({
  record,
}: {
  record: PersonRecord;
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <SimpleShowLayout className={classes.root} record={record}>
      <IdField label="User ID" source="user.ailoRN" />

      {record.user && (
        <LinkField
          label="Auth0 User Id"
          source="user.auth0Id"
          sourceResource={false}
          to={
            record.user?.auth0Id
              ? getAuth0UserUrl(record.user.auth0Id)
              : undefined
          }
        />
      )}

      {!record.user && (
        <CreatePersonUserButton
          person={record}
          variant="outlined"
          style={{ marginBottom: 8 }}
        />
      )}

      <FunctionField<PersonRecord>
        label="KYC"
        render={(person) => <KycVerifiedChip status={person!.kycVerified} />}
      />
      <TextField label="Preferred name" source="preferredName" />
      <TextField label="Legal first name" source="legalFirstName" />
      <TextField label="Legal last name" source="lastName" />
      <FunctionField<PersonRecord>
        label="Email address"
        render={(person) => (
          <Box display="flex">
            <CopyableTextField
              label="Email address"
              source="emailAddress"
              record={person}
            />
            <EditPersonEmailButton person={record} />
          </Box>
        )}
      />
      <FunctionField<PersonRecord>
        label="Phone number"
        render={(person) =>
          person?.phoneNo
            ? `${person.phoneNo} (${
                person.phoneNoVerifiedAt
                  ? `Verified at ${person.phoneNoVerifiedAt}`
                  : "Not verified"
              })`
            : undefined
        }
      />
      <FunctionField<PersonRecord>
        label="Date of birth"
        render={(person) =>
          person?.birthDate ? formatDate(person.birthDate) : null
        }
      />
      <FunctionField<PersonRecord>
        label="Residential Address"
        render={(person) => (person ? formatAddress(person) : null)}
      />
      <LinkField source="organisation" includeApiTypeInLinkText={false} />

      <>
        {record.user && record.emailAddress && record.organisation && (
          <GTConsumerPasswordResetButton
            email={record.emailAddress}
            metadata={{
              organisationId: record.organisation.id,
              organisationName: record.organisation.name,
              userCreatedAt: record.user.createdAt,
              userId: record.user.id,
              plans: record.organisation.activeSubscriptions!.items.map(
                (s) => s.plan.id
              ),
            }}
          />
        )}
        {record.user && record.emailAddress && record.organisation && (
          <GTAgencyPasswordResetButton
            email={record.emailAddress}
            metadata={{
              organisationId: record.organisation.id,
              organisationName: record.organisation.name,
              userCreatedAt: record.user.createdAt,
              userId: record.user.id,
              plans: record.organisation.activeSubscriptions!.items.map(
                (s) => s.plan.id
              ),
            }}
          />
        )}
      </>
    </SimpleShowLayout>
  );
};
