import { Money, MoneyInterface } from "@ailo/money";
import { NumberInput } from "react-admin";
import React from "react";

export function MoneyInput({
  addLabel: _addLabel,
  defaultValue,
  ...props
}: any) {
  return (
    <NumberInput
      step={0.01}
      defaultValue={defaultValue}
      format={(value: Money | MoneyInterface) => {
        if (!value) {
          return null;
        }

        const money = Money.from(value);
        return money.dollars;
      }}
      parse={(input?: number | string) => {
        if (input === undefined || input === "") {
          return undefined;
        }

        const money = Money.fromDollars(input || 0);
        return {
          cents: money.cents,
        };
      }}
      {...props}
    />
  );
}
