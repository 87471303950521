import React from "react";
import { DetailCard } from "../../../common/ui/cards";
import { TenantsList } from "../components";

export function ManagementTenantsCard({
  tenancy,
  managingEntity,
}: {
  tenancy: React.ComponentProps<typeof TenantsList>["tenancy"];
  managingEntity?: string;
}): React.ReactElement {
  return (
    <DetailCard
      title="Tenants"
      contents={
        <TenantsList tenancy={tenancy} managingEntity={managingEntity} />
      }
    />
  );
}
