import React from "react";
import { ManagementFolio, OperationData } from "../../../api";
import { DetailCard } from "../../../common/ui/cards";
import { ManagementFolioPropertiesList } from "./ManagementFolioPropertiesList";

type Management = NonNullable<
  OperationData<typeof ManagementFolio, "GET_ONE">
>["managements"]["items"][number];

export function ManagementFolioPropertiesCard({
  managements,
}: {
  managements: Management[];
}): React.ReactElement {
  return (
    <DetailCard
      title="Current Properties"
      contents={<ManagementFolioPropertiesList managements={managements} />}
    />
  );
}
