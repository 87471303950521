import React, { ReactElement } from "react";
import {
  AutocompleteInput,
  FileField,
  FileInput,
  required,
  TextInput,
  useNotify,
} from "react-admin";
import { MigratingManagement } from "../../../api";
import { UPLOAD_MIGRATION_CSV } from "../../../api/resources/MigratingManagement";
import {
  formatCompanyOptionText,
  ReferenceInput,
  SimpleFormDialog,
  useMutation,
} from "../../../common";

const requiredValidate = [required()];

export function UploadMigrationCSVDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement {
  const notify = useNotify();
  const [uploadMigrationCSV] = useMutation(
    {
      type: UPLOAD_MIGRATION_CSV,
      resource: MigratingManagement,
    },
    {
      successMessage: "Migrating managements created from CSV",
      onSuccess: onClose,
      onFailure: (error) =>
        notify(`Unable to upload migration CSV: ${error.message}`, "error"),
    }
  );
  return (
    <SimpleFormDialog
      open={open}
      disableBackdropClick
      title="Upload CSV"
      submitLabel="Upload"
      save={async (formData) => {
        await uploadMigrationCSV({ payload: formData });
      }}
      onClose={onClose}
    >
      <TextInput label="Batch Reference" source="batchRef" />
      <ReferenceInput
        alwaysOn
        label="Managed By"
        source="legalEntityId"
        reference="Companies"
        fullWidth
        validate={requiredValidate}
        filterToQuery={(searchTerm: string) => ({
          searchTerm,
        })}
      >
        <AutocompleteInput optionText={formatCompanyOptionText} />
      </ReferenceInput>
      <FileInput
        source="data.data"
        label="CSV file"
        accept="text/csv"
        validate={requiredValidate}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleFormDialog>
  );
}
