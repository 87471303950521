import { Button, useNotify, useUnselectAll } from "react-admin";
import React from "react";
import ArchiveIcon from "@material-ui/icons/Archive";
import { Alert } from "@material-ui/lab";
import { FileAttachment, useZipAttachments } from "../../hooks";

const LABEL = "Download Zip";

export function ZipButton({
  attachments,
  resource,
  fileName,
}: {
  resource: string;
  attachments: FileAttachment[];
  fileName: string;
}): React.ReactElement {
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const { zipping, zip } = useZipAttachments();

  function onClick() {
    const zipOptions = {
      files: attachments,
      folderName: fileName,
      fileName,
    };

    zip(zipOptions)
      .then(() => {
        unselectAll();
        return notify(`Downloaded bill attachments`);
      })
      .catch((error) => notify(`${error}`));
  }

  return (
    <>
      <Button
        label={LABEL}
        disabled={!(attachments || []).length || zipping}
        onClick={onClick}
      >
        <ArchiveIcon />
      </Button>
      <Warning />
    </>
  );
}

function Warning(): React.ReactElement {
  return (
    <Alert style={{ marginTop: -8, marginLeft: 4 }} severity="warning">
      Download for each page separately
    </Alert>
  );
}

function Loading(): React.ReactElement {
  return (
    <>
      <Button label={LABEL} disabled>
        <ArchiveIcon />
      </Button>
      <Warning />
    </>
  );
}

ZipButton.Loading = Loading;
