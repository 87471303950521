import { GET_LIST, useQuery } from "react-admin";
import { AiloRN } from "@ailo/ailorn";
import { CentrepayPaymentDirective } from "../../../api";

export function useFetchCentrepayPaymentDirective(filter: {
  tenancy?: string;
  managingEntity?: string;
  legalEntity?: string;
}): { legalEntity: AiloRN; managingEntity: AiloRN; tenancy: AiloRN }[] {
  const { data: centrepayPaymentDirectives } = useQuery({
    type: GET_LIST,
    resource: CentrepayPaymentDirective,
    payload: {
      input: filter,
    },
  });
  return centrepayPaymentDirectives || [];
}
