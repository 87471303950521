import { LocalDate } from "@ailo/date";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import {
  DateInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import type { Tenancy } from "../../api/deprecatedTypes";
import { maxDate } from "../../common";
import { formatTerminationReason } from "../../common/utils/formatTerminationReason";

type Props = {
  entity: Tenancy;
  entityName: "Tenancy";
  terminationReasons: string[];
  save: (...args: Array<any>) => any;
  onFormChange: (...args: Array<any>) => any;
  error: string;
  resetEnabled?: boolean;
  notesWarning?: string;
  reasonSource: string;
  notesSource: string;
  /** @default `true` */
  allowPushingEndDateBack?: boolean;
};
export const TenancyTerminationForm = ({
  entity,
  entityName,
  terminationReasons,
  save,
  onFormChange,
  error,
  resetEnabled,
  notesWarning,
  reasonSource,
  notesSource,
  allowPushingEndDateBack = true,
}: Props) => {
  const validate = resetEnabled ? [] : [required()];
  const validateEndDate =
    !allowPushingEndDateBack && entity.endDate
      ? [
          ...validate,
          maxDate({
            max: LocalDate.from(entity.endDate),
            fieldName: "End Date",
            errorMessage:
              "Tenancy End Date can not be pushed further back. Instead, clear the end date, save the changes and then try to set the end date again.",
            allowEmpty: true,
          }),
        ]
      : validate;

  return (
    <SimpleForm
      toolbar={<PostEditToolbar />}
      record={entity}
      save={save}
      submitOnEnter={false}
    >
      {error ? (
        <Alert severity="error" {...({ fullWidth: true } as any)}>
          {error}
        </Alert>
      ) : null}
      <TextInput
        label={`${entityName} ID`}
        source="id"
        disabled
        validate={validate}
        fullWidth
      />
      <DateInput
        label="End Date"
        source="endDate"
        validate={validateEndDate}
        fullWidth
        onChange={onFormChange}
      />
      <SelectInput
        label="Internal Termination Reason"
        source={reasonSource}
        choices={terminationReasons.map((reason) => ({
          id: reason,
          name: formatTerminationReason(reason),
        }))}
        validate={validate}
        fullWidth
        onChange={onFormChange}
      />
      {notesWarning ? (
        <Alert severity="warning" {...({ fullWidth: true } as any)}>
          {notesWarning}
        </Alert>
      ) : null}
      <TextInput
        label="Internal End Note"
        multiline
        source={notesSource}
        fullWidth
        onChange={onFormChange}
      />
    </SimpleForm>
  );
};

const PostEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
