import Container from "@material-ui/core/Container";
import React from "react";

const EmptyTransactionsList = () => {
  return (
    <Container {...{ align: "center" }}>
      There have been no transactions made yet
    </Container>
  );
};

export { EmptyTransactionsList };
