import React, { useState } from "react";
import { useMutation, useNotify, useRefresh } from "react-admin";
import { ErrorDialogContent, SlideUpDialog } from "../../../common/ui/dialogs";
import { DeletePaymentMethodDialogScreen } from "./components";
import { PaymentMethod } from "../../../api";

const useDeletePaymentMethod = ({
  paymentMethodId,
  onSuccess,
  onFailure,
}: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    {
      type: "delete_payment_method",
      resource: PaymentMethod,
      payload: {
        paymentMethodId,
        disableAutoPlans: true,
      },
    },
    {
      onSuccess: () => {
        onSuccess();
        refresh();
        notify(`Payment method has been removed`);
      },
      onFailure: (error) => {
        onFailure(error.toString());
        notify(`Failed to remove payment method. Error: ${error}`);
      },
    }
  );
};

const DeletePaymentMethodDialog = ({
  isAutoPay,
  isAutoWithdraw,
  open,
  onClose,
  paymentMethodId,
}: any) => {
  const onDeleteFailure = (error: any) => {
    setError(error);
  };

  const [deletePaymentMethod] = useDeletePaymentMethod({
    paymentMethodId,
    onSuccess: onClose,
    onFailure: onDeleteFailure,
  });
  const [error, setError] = useState();
  return (
    <SlideUpDialog open={open} onClose={onClose}>
      {!error ? (
        <DeletePaymentMethodDialogScreen
          isAutoPay={isAutoPay}
          isAutoWithdraw={isAutoWithdraw}
          onConfirmClick={deletePaymentMethod}
          onCancelClick={onClose}
        />
      ) : (
        <ErrorDialogContent
          title="Remove payment method failed"
          error={error}
          onClose={onClose}
        />
      )}
    </SlideUpDialog>
  );
};

export { DeletePaymentMethodDialog };
