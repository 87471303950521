import React from "react";
import { Person as PersonResource } from "../../../../../api/ResourceName";
import { EditButton } from "../../../../../common";
import { EditPersonEmailDialog } from "./editPersonEmailDialog";
import { OperationData } from "../../../../../api";

type Person = OperationData<typeof PersonResource, "GET_ONE">;

export function EditPersonEmailButton({
  person,
}: {
  person: Person;
}): React.ReactElement {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <EditButton onClick={onClick} />
      <EditPersonEmailDialog open={open} onClose={onClose} person={person} />
    </>
  );
}
