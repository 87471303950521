import { DateTimeWithTimeZone } from "@ailo/date";
import React, { ReactElement } from "react";
import { OperationData, Person } from "../../api";
import { DATE_TIME_FORMAT, DescriptionList, DetailCard } from "../../common";

type PersonRecord = OperationData<typeof Person, "GET_ONE">;

export function PersonActivityHistoryCard({
  person,
}: {
  person: PersonRecord;
}): ReactElement | null {
  if (!person.user) {
    return null;
  }

  return (
    <DetailCard title="Activity History">
      {person.user.onboardingTasks.length === 0 &&
        "No onboarding tasks done yet."}
      {person.user.onboardingTasks.length > 0 && (
        <DescriptionList
          items={person.user.onboardingTasks
            .map((task) => ({
              ...task,
              completedAt: DateTimeWithTimeZone.from(task.completedAt),
            }))
            .sort(
              (a, b) =>
                b.completedAt.toDate().getTime() -
                a.completedAt.toDate().getTime()
            )
            .map((task) => ({
              key: task.onboardingTask.id,
              name: task.completedAt.format(DATE_TIME_FORMAT),
              description: task.onboardingTask.description,
            }))}
        />
      )}
    </DetailCard>
  );
}
