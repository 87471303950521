import React from "react";
import {
  ListContextProvider,
  useCheckMinimumRequiredProps,
  ListView,
  Title,
  ListToolbar,
} from "react-admin";
import { CursorPagination } from "./CursorPagination";
import { useListController } from "./useListController";

export function BaseList(props: any) {
  useCheckMinimumRequiredProps("List", ["children"], props);
  const controllerProps = useListController(props);
  // Simply passing `empty` to `ListView` doesn't work. This is a workaround.
  if (controllerProps.total === 0 && props.empty) {
    return (
      <>
        <Title title={props.title} defaultTitle={props.defaultTitle} />
        {(props.filters || props.actions) && (
          <ListToolbar
            filters={props.filters}
            {...controllerProps}
            actions={props.actions}
            exporter={props.exporter}
          />
        )}
        {props.empty}
      </>
    );
  }
  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} />
    </ListContextProvider>
  );
}
export function List(props: any) {
  return (
    <BaseList
      perPage={25}
      pagination={<CursorPagination />}
      filterDefaultValues={{
        ...props.filterDefaultValues,
        cursor: undefined,
        page: undefined,
      }}
      {...props}
    />
  );
}
