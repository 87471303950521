import React from "react";
import { regex, required, TextInput } from "react-admin";

export const bPayFormFields = (
  <TextInput
    {...({} as any)}
    autoFocus
    autoComplete="off"
    label="Biller Code"
    variant="outlined"
    name="billerCode"
    fullWidth
    validate={[
      required(),
      regex(/^\d{4,6}$/, "Biller code must contain 4-6 digits"),
    ]}
  />
);
