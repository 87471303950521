import { LocalDate } from "@ailo/date";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import {
  DateInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import type { Management } from "../../api/deprecatedTypes";
import {
  ManagementEndCause,
  ManagementEndReason,
} from "../../api/graphql/types.generated";
import { maxDate } from "../../common";

type Props = {
  entity: Management;
  entityName: "Management";
  endReasons: ManagementEndReason[];
  save: (...args: Array<any>) => any;
  onFormChange: (...args: Array<any>) => any;
  error: string;
  resetEnabled?: boolean;
  reasonSource: string;
  causeSource: string;
  notesSource: string;
  /** @default `true` */
  allowPushingEndDateBack?: boolean;
};
export const ManagementTerminationForm = ({
  entity,
  entityName,
  endReasons,
  save,
  onFormChange,
  error,
  resetEnabled,
  reasonSource,
  causeSource,
  notesSource,
  allowPushingEndDateBack = true,
}: Props) => {
  const [endCauses, setEndCauses] = useState<ManagementEndCause[]>([]);
  const validate = resetEnabled ? [] : [required()];
  const validateEndDate =
    !allowPushingEndDateBack && entity.endDate
      ? [
          ...validate,
          maxDate({
            max: LocalDate.from(entity.endDate),
            fieldName: "End Date",
            errorMessage:
              "Tenancy End Date can not be pushed further back. Instead, clear the end date, save the changes and then try to set the end date again.",
            allowEmpty: true,
          }),
        ]
      : validate;

  const handleOnEndReasonChange = (endReasonCode: string): void => {
    const endReason = endReasons.find((er) => er.code === endReasonCode);
    setEndCauses(endReason?.causes || []);
  };

  return (
    <SimpleForm
      toolbar={<PostEditToolbar />}
      record={entity}
      save={save}
      submitOnEnter={false}
    >
      {error ? (
        <Alert severity="error" {...({ fullWidth: true } as any)}>
          {error}
        </Alert>
      ) : null}
      <TextInput
        label={`${entityName} ID`}
        source="id"
        disabled
        validate={validate}
        fullWidth
      />
      <DateInput
        label="End Date"
        source="endDate"
        validate={validateEndDate}
        fullWidth
        onChange={onFormChange}
      />
      <SelectInput
        label="End Reason"
        source={reasonSource}
        choices={endReasons.map((reason) => ({
          id: reason.code,
          name: reason.label,
        }))}
        validate={validate}
        fullWidth
        onChange={(e) => {
          onFormChange(e);
          handleOnEndReasonChange(e.target.value);
        }}
        on
      />
      {!!endCauses.length && (
        <SelectArrayInput
          label="End Cause"
          source={causeSource}
          choices={endCauses.map((cause: ManagementEndReason["causes"][0]) => ({
            id: cause.code,
            name: cause.label,
          }))}
          validate={validate}
          fullWidth
          onChange={onFormChange}
        />
      )}
      <Alert severity="warning" {...({ fullWidth: true } as any)}>
        The end note below is visible to PMs, do not add any internal info (e.g.
        Zendesk tickets)
      </Alert>
      <TextInput
        label="End Note"
        multiline
        source={notesSource}
        fullWidth
        onChange={onFormChange}
        validate={validate}
      />
    </SimpleForm>
  );
};

const PostEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
