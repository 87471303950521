// eslint-disable-next-line no-restricted-imports
import { TablePagination, Toolbar, useMediaQuery } from "@material-ui/core";
import { List as ImmutableList, Map as ImmutableMap } from "immutable";
import React, { useCallback, useMemo } from "react";
import {
  sanitizeListRestProps,
  useListPaginationContext,
  useTranslate,
} from "react-admin";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const emptyArray:
  | (number | { value: number; label: string })[]
  | undefined = [];
export function CursorPagination(props: {
  [x: string]: any;
  resource?: any;
  rowsPerPageOptions?: any;
  actions?: any;
  limit?: any;
}) {
  const paginationResource = useSelector<any>((state) =>
    state.pagination.get(props.resource, ImmutableList())
  );
  const lastResource = (paginationResource as any).last() || ImmutableMap();
  const nextCursor = lastResource.get("nextCursor");
  const { rowsPerPageOptions, actions, limit, ...rest } = props;
  const {
    loading,
    page: cursor,
    perPage,
    total,
    setPage,
    setPerPage,
  } = useListPaginationContext(props);
  const page = cursor && String(cursor) !== "1" ? 2 : 1;
  const translate = useTranslate();
  const isSmall = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const { goBack } = history;
  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage) || 1;
  }, [perPage, total]);

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, nextPageProp) => {
      const nextPage = nextPageProp + 1;

      if (event) {
        event.stopPropagation();
      }

      if (nextPage < 1 || nextPage > totalPages) {
        throw new Error(
          translate("ra.navigation.page_out_of_boundaries", {
            page: nextPage,
          })
        );
      }

      if (nextPage === page - 1) {
        goBack();
        return;
      }

      if (nextPage === page + 1) {
        setPage(nextCursor);
        return;
      }

      throw new Error(
        `You can only navigate one page backward/forward. Going to \`n-th\` page is impossible with cursor pagination.`
      );
    },
    [totalPages, page, translate, goBack, setPage, nextCursor]
  );
  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );
  const labelDisplayedRows = useCallback(({ count }) => `${count} rows`, []);

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant="dense" /> : null;
  }

  if (isSmall) {
    return (
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        rowsPerPageOptions={emptyArray}
        component="span"
        labelDisplayedRows={labelDisplayedRows}
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      ActionsComponent={actions}
      component="span"
      labelRowsPerPage={translate("ra.navigation.page_rows_per_page")}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
}
