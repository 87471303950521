import React, { useState, useCallback } from "react";
import { useMutation, useNotify, useRefresh, FunctionField } from "react-admin";
import { Button, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import type { Tenancy } from "../../api/deprecatedTypes";
import { DraftField } from "./DraftField";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const TenancyStatusField = ({ tenancy }: { tenancy: Tenancy }) => {
  return (
    <Grid container spacing={3} direction="row">
      <Grid item direction="column">
        <Grid item>Status:</Grid>
        <Grid item>
          <FunctionField
            label="status"
            render={() => <DraftField record={tenancy} />}
          />
        </Grid>
      </Grid>
      {tenancy.isDraft ? (
        <>
          <Grid item>
            <DeleteDraftTenancyButton record={tenancy} />
          </Grid>
          <Grid item>
            <PublishTenancyButton record={tenancy} />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

const PublishTenancyButton = ({ record }: { record: Tenancy }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [publishTenancy, { loading }] = useMutation(
    {
      type: "publish",
      resource: "Tenancy",
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: ({ data }) => {
        notify("Tenancy published");
        refresh();
      },
      onFailure: (error) =>
        notify(`Unable to publish tenancy: ${error.message}`, "error"),
    }
  );
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={publishTenancy}
      disabled={loading}
    >
      Publish Tenancy
    </Button>
  );
};

const DeleteDraftTenancyButton = ({ record }: { record: Tenancy }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [deleteDraftTenancy, { loading }] = useMutation(
    {
      type: "delete_draft",
      resource: "Tenancy",
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: ({ data }) => {
        notify("Tenancy deleted");
        refresh();
      },
      onFailure: (error) =>
        notify(`Unable to delete tenancy: ${error.message}`, "error"),
    }
  );
  const openConfirmationDialog = useCallback(() => {
    setDialogOpened(true);
  }, []);
  const cancelDeleteDraftTenancy = useCallback(() => {
    setDialogOpened(false);
  }, []);
  const confirmDeleteDraftTenancy = useCallback(() => {
    setDialogOpened(false);
    deleteDraftTenancy();
  }, [deleteDraftTenancy]);
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={openConfirmationDialog}
        disabled={loading}
        startIcon={<DeleteIcon />}
      >
        Delete Draft
      </Button>
      <ConfirmationDialog
        open={dialogOpened}
        onClose={cancelDeleteDraftTenancy}
        onDisagree={cancelDeleteDraftTenancy}
        onAgree={confirmDeleteDraftTenancy}
        title="Are you sure?"
        description="Tenancy and all associated tenantship, tenancy bond, tenancy agreement, rent & rent schedule information will be deleted."
      />
    </>
  );
};
