import React from "react";
import { Box } from "@material-ui/core";
import { LastUsedPaymentMethodBadge } from "./lastUsedPaymentMethodBadge";
import { Badge, BadgeType } from "../../../../common";

const badgeStyle = {
  marginLeft: 1,
  marginBottom: "2px",
};
const StyledBadge = (props: {
  type?: BadgeType;
  text?: any;
  style?: any;
  children?: any;
}) => {
  return <Badge {...props} style={badgeStyle} />;
};

export const PaymentMethodBadges = ({
  isAutoPay,
  isAutoWithdraw,
  isDefaultIn,
  isDefaultOut,
  isDeleted,
  isHidden,
}: any) => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      {isDeleted ? (
        <StyledBadge>Deleted</StyledBadge>
      ) : (
        <>
          {isAutoPay && (
            <StyledBadge type={!isDeleted ? "success" : "default"}>
              Auto Payment
            </StyledBadge>
          )}
          {isAutoWithdraw && (
            <StyledBadge type={!isDeleted ? "success" : "default"}>
              Auto Transfer
            </StyledBadge>
          )}
          {isDefaultIn && (
            <LastUsedPaymentMethodBadge style={badgeStyle}>
              Last used to pay
            </LastUsedPaymentMethodBadge>
          )}
          {isDefaultOut && (
            <LastUsedPaymentMethodBadge style={badgeStyle}>
              Last used to transfer
            </LastUsedPaymentMethodBadge>
          )}
        </>
      )}
      {isHidden && <StyledBadge>Hidden</StyledBadge>}
    </Box>
  );
};
