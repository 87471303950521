import { RecurringDate } from "@ailo/date";
import ArchiveIcon from "@material-ui/icons/Archive";
import React from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
import { FeeBlueprint } from "../../api";
import {
  ActionInitiatorField,
  CurrentnessField,
  IdField,
  LinkField,
  ManagementField,
  MoneyField,
  MutationButton,
  OrganisationField,
  PropertyField,
} from "../../common";

const Actions = ({ basePath, data: fee, resource }: any) => {
  if (!fee) return null;
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={fee} />
      {!fee.cancelled && fee.nextOccurrence && (
        <MutationButton
          {...{
            resource,
            record: fee,
          }}
          type="cancel"
          icon={<ArchiveIcon />}
          confirmationAlert="Are you sure you want to cancel this fee? This fee will no longer be charged. (However, past occurrences will stay as they are.)"
        />
      )}
    </TopToolbar>
  );
};

export const RecurringFeeShow = (props: Record<string, any>) => {
  return (
    <Show actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <IdField label="ID" source="ailoRN" />
        <OrganisationField source="blueprint.organisation" />
        <PropertyField
          source="management.property"
          managementIdSource="management.id"
        />
        <ManagementField source="management" />
        <ReferenceField
          label="Blueprint"
          source="blueprintId"
          reference={FeeBlueprint}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <DateField source="startDate" />
        <DateField source="endDate" />
        <DateField source="nextOccurrence.date" label="Next charge date" />
        <CurrentnessField source="currentness" />
        <TextField label="Tax category" source="blueprint.taxCategory.name" />
        <TextField label="Description for payer" source="description" />
        <FunctionField
          label="Frequency"
          render={(record: any) => new RecurringDate(record.blueprint).format()}
        />
        <ActionInitiatorField
          label="Created"
          initiatedAtSource="createdAt"
          initiatedBySource="createdBy"
        />
        <ActionInitiatorField label="Modified" initiatedBySource="modifiedBy" />
        <BooleanField source="cancelled" />
        <BooleanField source="archived" />
        <FunctionField
          label="Schedules"
          render={(record: any) => {
            return (
              <ArrayField record={record} source="schedules">
                <Datagrid>
                  <DateField source="startDate" />
                  <MoneyField
                    label="Price"
                    source="taxInclusiveAmount"
                    frequency={record.blueprint.frequency}
                  />
                </Datagrid>
              </ArrayField>
            );
          }}
        />
        <MoneyField label="Overdue amount" source="liability.overdueAmount" />
        <LinkField source="liability" />
      </SimpleShowLayout>
    </Show>
  );
};
