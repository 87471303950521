import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { Button, Link } from "react-admin";
import { ManagementFee } from "../../../../api/ResourceName";
import { CopyToClipboardButton } from "../../../../common";
import { ListItem } from "../../../../common";

export const ManagementFeeCard = ({ managementFee, status }: any) => {
  return (
    <ListItem
      title={
        <Box display="flex" fontSize={14}>
          Management Fee
          <Box pl={2} ml="auto">
            {status}
          </Box>
        </Box>
      }
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        fontSize={14}
      >
        <Box>
          <Box fontSize={14}>On rent payment; Before auto transfer</Box>
          <Box mt={1} fontSize={14}>
            {managementFee.id}
            <CopyToClipboardButton value={managementFee.id} />
          </Box>
        </Box>
        <Box color="black">
          {`${(managementFee.percent * 100).toFixed(2)}%`}
        </Box>
      </Box>
      <Box mt={1}>
        <Button
          component={Link}
          to={{
            pathname: `/${ManagementFee}/${managementFee.id}`,
          }}
          label="Edit"
        >
          <EditIcon />
        </Button>
      </Box>
    </ListItem>
  );
};
