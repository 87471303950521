// Fork of https://raw.githubusercontent.com/marmelab/react-admin/c4185667dcb59cd0fc68fc0d89c514f86feba06b/packages/ra-core/src/controller/input/useReferenceInputController.ts

import {
  getStatusForInput,
  useFilterState,
  useGetList,
  usePaginationState,
  useResourceContext,
  useSelectionState,
  useSortState,
  useTranslate,
} from "ra-core";
import { useCallback } from "react";
import { useReferenceUsingUseGetOne } from "./useReference";

const defaultFilter = {};

/**
 * A hook for choosing a reference record. Useful for foreign keys.
 *
 * This hook fetches the possible values in the reference resource
 * (using `dataProvider.getList()`), it returns the possible choices
 * as the `choices` attribute.
 *
 * @example
 * const {
 *      choices, // the available reference resource
 * } = useReferenceInputController({
 *      input, // the input props
 *      resource: 'comments',
 *      reference: 'posts',
 *      source: 'post_id',
 * });
 *
 * The hook also allow to filter results. It returns a `setFilter`
 * function. It uses the value to create a filter
 * for the query - by default { q: [searchText] }. You can customize the mapping
 * searchText => searchQuery by setting a custom `filterToQuery` function option
 * You can also add a permanentFilter to further filter the result:
 *
 * @example
 * const {
 *      choices, // the available reference resource
 *      setFilter,
 * } = useReferenceInputController({
 *      input, // the input props
 *      resource: 'comments',
 *      reference: 'posts',
 *      source: 'post_id',
 *      permanentFilter: {
 *          author: 'john'
 *      },
 *      filterToQuery: searchText => ({ title: searchText })
 * });
 */
export const useReferenceInputController = (props: any) => {
  const {
    basePath,
    input,
    page: initialPage = 1,
    perPage: initialPerPage = 25,
    filter = defaultFilter,
    reference,
    filterToQuery,
    sort: sortOverride,
  } = props;
  const resource = useResourceContext(props);
  const translate = useTranslate();
  // pagination logic
  const {
    pagination,
    setPagination,
    page,
    setPage,
    perPage,
    setPerPage,
  } = usePaginationState({
    page: initialPage,
    perPage: initialPerPage,
  });
  // sort logic
  const { sort, setSort: setSortObject } = useSortState(sortOverride);
  const setSort = useCallback(
    (field, order = "ASC") => {
      setSortObject({
        field,
        order,
      });
      setPage(1);
    },
    [setPage, setSortObject]
  );
  // filter logic
  const { filter: filterValues, setFilter } = useFilterState({
    permanentFilter: filter,
    filterToQuery,
  });
  const displayedFilters: any[] = [];
  // plus showFilter and hideFilter defined outside of the hook because
  // they never change
  // selection logic
  const {
    selectedIds,
    onSelect,
    onToggleItem,
    onUnselectItems,
  } = useSelectionState();
  // fetch possible values
  const {
    ids: possibleValuesIds,
    data: possibleValuesData,
    total: possibleValuesTotal,
    loaded: possibleValuesLoaded,
    loading: possibleValuesLoading,
    error: possibleValuesError,
  } = useGetList(reference, pagination, sort, filterValues);
  // fetch current value
  const referenceResult = useReferenceUsingUseGetOne({
    id: input.value,
    reference,
  });
  const {
    referenceRecord,
    error: referenceError,
    loading: referenceLoading,
    loaded: referenceLoaded,
  } = referenceResult as any;
  // add current value to possible sources
  let finalIds: any;
  let finalData: any;
  let finalTotal: number | undefined;

  if (!referenceRecord || possibleValuesIds?.includes(input.value)) {
    finalIds = possibleValuesIds;
    finalData = possibleValuesData;
    finalTotal = possibleValuesTotal;
  } else {
    finalIds = [input.value, ...(possibleValuesIds ?? [])];
    finalData = {
      [input.value]: referenceRecord,
      ...possibleValuesData,
    };
    finalTotal = finalTotal! + 1;
  }

  // overall status
  const dataStatus = getStatusForInput({
    input,
    matchingReferences: Object.keys(finalData).map((id) => finalData[id]),
    referenceRecord,
    translate,
  });
  return {
    // should match the ListContext shape
    possibleValues: {
      basePath,
      data: finalData,
      ids: finalIds,
      total: finalTotal,
      error: possibleValuesError,
      loaded: possibleValuesLoaded,
      loading: possibleValuesLoading,
      hasCreate: false,
      page,
      setPage,
      perPage,
      setPerPage,
      currentSort: sort,
      setSort,
      filterValues,
      displayedFilters,
      setFilters: setFilter,
      showFilter,
      hideFilter,
      selectedIds,
      onSelect,
      onToggleItem,
      onUnselectItems,
      resource,
    },
    referenceRecord: {
      data: referenceRecord,
      loaded: referenceLoaded,
      loading: referenceLoading,
      error: referenceError,
    },
    dataStatus: {
      error: dataStatus.error,
      loading: dataStatus.waiting,
      warning: dataStatus.warning,
    },
    choices: finalIds.map((id: string | number) => finalData[id]),
    // kept for backwards compatibility
    // @deprecated to be removed in 4.0
    error: dataStatus.error,
    loading: possibleValuesLoading || referenceLoading,
    loaded: possibleValuesLoaded && referenceLoaded,
    filter: filterValues,
    setFilter,
    pagination,
    setPagination,
    sort,
    setSort: setSortObject,
    warning: dataStatus.warning,
  };
};

const hideFilter = () => {};

const showFilter = () => {};
