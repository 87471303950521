import gql from "graphql-tag";
import { createClientFromDefinition, GraphQLResponse } from "../../common";
import { PaginationParams } from "../../graphql/types.generated";
import { GeneralLedgerPaginatedEntriesFragment } from "../GeneralLedgerEntry";
import {
  AccountOwnerQuery,
  AccountOwnerQueryVariables,
  ListAccountOwnerEntriesQuery,
  ListAccountOwnerEntriesQueryVariables,
} from "./accountOwnerClient.generated";

export const AccountOwnerClientDefinition = {
  GET_ONE: (params: { id: string }) => ({
    query: gql`
      query accountOwner($reference: AiloRN!) {
        accountOwner(reference: $reference) {
          id: reference
          reference
          totalBalance {
            cents
          }
          availableBalance {
            cents
          }
        }
      }
    `,
    variables: <AccountOwnerQueryVariables>{
      reference: params.id,
    },
    parseResponse: (response: GraphQLResponse<AccountOwnerQuery>) => ({
      data: response.data.accountOwner!,
    }),
  }),
  LIST_ACCOUNT_OWNER_ENTRIES: (params: {
    cursor?: PaginationParams;
    accountOwnerReference: string;
  }) => ({
    query: gql`
      query listAccountOwnerEntries(
        $pagination: PaginationParams!
        $accountOwnerReference: AiloRN!
      ) {
        entries(
          filters: { accountOwnerReference: $accountOwnerReference }
          pagination: $pagination
          sorts: [
            { field: CREATED_AT, direction: Desc }
            { field: SNOWFLAKE, direction: Desc }
          ]
        ) {
          ...GeneralLedgerPaginatedEntries
        }
      }

      ${GeneralLedgerPaginatedEntriesFragment}
    `,
    variables: <ListAccountOwnerEntriesQueryVariables>{
      pagination: params.cursor,
      accountOwnerReference: params.accountOwnerReference,
    },
    parseResponse: (
      response: GraphQLResponse<ListAccountOwnerEntriesQuery>
    ) => ({ data: response.data.entries! }),
  }),
};

export const accountOwnerClient = createClientFromDefinition(
  AccountOwnerClientDefinition
);
