import { AiloRN } from "@ailo/ailorn";
import React, { ReactElement, useState } from "react";
import { Box } from "@material-ui/core";
import { useRefresh } from "react-admin";
import { ConfirmActionDialog } from "../../../../common/ui/dialogs/ConfirmActionDialog/ConfirmActionDialog";
import { useCreateOfflinePaymentAccount } from "./useCreateOfflinePaymentAccount";
import { EditIcon, DetailCard } from "../../../../common";

export function CreateOfflinePaymentAccountButton({
  tenancy,
}: {
  tenancy: AiloRN;
}): ReactElement {
  const createOfflinePaymentAccount = useCreateOfflinePaymentAccount(tenancy);
  const [
    showCreateOfflinePaymentAccountDialog,
    setShowCreateOfflinePaymentAccountDialog,
  ] = useState(false);
  const refresh = useRefresh();
  const confirmCreateOfflinePaymentAccountDialog = (
    <ConfirmActionDialog
      open={showCreateOfflinePaymentAccountDialog}
      onClose={() => setShowCreateOfflinePaymentAccountDialog(false)}
      confirmAction={async () => {
        setShowCreateOfflinePaymentAccountDialog(false);
        await createOfflinePaymentAccount();
        refresh();
      }}
      dialogText={{
        title: "Confirm create offline payment account?",
        content:
          "This will create an offline payment account for this tenancy. " +
          "If the account details don't show up immediately, try refreshing the page. " +
          "If this persists and error may have occurred.",
      }}
      failureNotification="Failed to create offline payment account"
    />
  );
  return (
    <DetailCard title="Offline Payment Account">
      <Box style={{ display: "flex" }}>
        Add offline payment account directive:
        <Box
          onClick={() => setShowCreateOfflinePaymentAccountDialog(true)}
          style={{ cursor: "pointer", marginLeft: 10 }}
        >
          <EditIcon style={{ height: 20 }} />
        </Box>
        {confirmCreateOfflinePaymentAccountDialog}
      </Box>
    </DetailCard>
  );
}
