import moment from "moment-timezone";
import React from "react";

/**
 * @deprecated Use DateField
 */
const FormattedDateField = ({ record, source, format, emptyText }: any) => {
  if (!record) {
    return (
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <span>{emptyText}</span>
      </div>
    );
  }

  const date = record[source];
  const timeZone = "Australia/Sydney";

  if (date) {
    const formattedDate = moment
      .tz(date, timeZone)
      .format(format || "YYYY-MM-DD");
    return (
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <span>{formattedDate}</span>
      </div>
    );
  }

  return (
    <div
      style={{
        marginBottom: 10,
      }}
    >
      <span>{emptyText}</span>
    </div>
  );
};

export { FormattedDateField };
