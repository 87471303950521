import { AiloRN } from "@ailo/ailorn";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { CentrepayWalletCards } from "../../Centrepay/CentrepayWalletCard";
import { BondFeaturesCard, TenancyBondWalletCards } from "../../TenancyBonds";
import {
  isAgency,
  isCompany,
  LegalEntityShowRecord,
} from "./LegalEntityShowRecord";

export function VirtualAccountsTab({
  record,
}: {
  record: LegalEntityShowRecord;
}): React.ReactElement {
  const isAgencyLegalEntity = isCompany(record) && isAgency(record);

  if (!isAgencyLegalEntity) {
    return (
      <Typography style={{ padding: 20, backgroundColor: "white" }}>
        Bond Details only relevant for an Agency
      </Typography>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TenancyBondWalletCards agency={AiloRN.from(record.ailoRN)} />
        <BondFeaturesCard
          featureToggles={record.organisation.availableFeatures}
          display={record?.__typename === "Company"}
        />
      </Grid>
      <Grid item xs={6}>
        <CentrepayWalletCards agency={AiloRN.from(record.ailoRN)} />
      </Grid>
    </Grid>
  );
}
