import React from "react";
import { FileAttachment } from "../../hooks";
import { ZipButton } from "./ZipButton";

type Props = {
  resource: string;
  attachments: FileAttachment[];
};

export function ZipButtonWithoutManagement({
  attachments,
  resource,
}: Props): React.ReactElement | null {
  return (
    <ZipButton
      attachments={attachments}
      resource={resource}
      fileName="All Properties_Bills"
    />
  );
}
