import { useMutation, useNotify, useRefresh, TextInput } from "react-admin";
import React from "react";
import { SimpleFormDialog } from "../../common/views/SimpleFormDialog";
import { ErrorDialog } from "../../common/ui/dialogs";

const useCancelBill = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  return useMutation(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; resource: string... Remove this comment to see the full error message
    {
      type: "archive",
      resource: "Bills",
    },
    {
      onSuccess: ({ data }) => {
        const error = data?.error;

        if (error) {
          notify(`Failed to cancel bill. Error: ${error}`);
        } else {
          refresh();
          notify(`Bill has been cancelled`);
        }
      },
      onFailure: (error) => {
        notify(`Failed to cancel bill. Error: ${error}`);
      },
    }
  );
};

export const CancelBillDialog = (props: {
  open: any;
  onClose: any;
  billId: any;
}) => {
  const { open, onClose, billId } = props;
  const [cancelBill, { error }] = useCancelBill();

  const submit = (formData: { reason: any }) => {
    cancelBill({
      payload: {
        data: {
          id: billId,
          reason: formData.reason,
        },
      },
    });
  };

  if (error) {
    return (
      <ErrorDialog
        open={open}
        title="Cancelling bill failed"
        error={error}
        onClose={onClose}
      />
    );
  }

  return (
    <SimpleFormDialog
      initialValues={{
        reason: "Offboarding",
      }}
      title="Cancel Bill"
      submitLabel="Cancel Bill"
      cancelLabel="Back"
      open={open}
      onClose={onClose}
      save={submit}
      allowSubmitWhenPristine
    >
      <TextInput
        variant="outlined"
        label="Reason for cancelling"
        source="reason"
      />
    </SimpleFormDialog>
  );
};
