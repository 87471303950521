import { formatAddress } from "@ailo/domain-helpers";
import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { isPresent } from "ts-is-present";
import { AccordionListItemView } from "../../../../common/ui/lists/AccordionListItemView";
import { TransactionDate } from "../transactionDate";
import { InlineTransactionDisplay } from "./components/InlineTransactionDisplay";
import { TransactionDetails } from "./transactionDetails";

const TransactionListItem = ({ transaction }: any) => {
  const header = transaction.description;
  const subHeader = getTransactionSubheader(transaction);
  const date = transaction.createdAt;
  return (
    <AccordionListItemView
      header={header}
      subHeader={subHeader}
      leftComponent={<TransactionDate date={date} />}
      rightComponent={<TransactionDetails transaction={transaction} />}
    >
      <InlineTransactionDisplay
        transactionId={transaction.businessTransaction}
      />
    </AccordionListItemView>
  );
};

const ConnectedTransactionListItem = connect(null, {
  push,
})(TransactionListItem);
const BankAccountTypeName = "BankAccount";
const CreditCardTypeName = "CreditCard";

function getTransactionSubheader(transaction: any): React.ReactElement {
  const paymentMethodDescription = getTransactionPaymentMethodDescription(
    transaction
  );
  const propertyAddress = getTransactionPropertyAddress(transaction);
  return (
    <>
      {[propertyAddress, paymentMethodDescription]
        .filter(isPresent)
        .map((line, index) => (
          <div key={index}>{line}</div>
        ))}
    </>
  );
}

function getTransactionPropertyAddress(transaction: any): string | undefined {
  const propertyAddress = transaction?.liability?.management?.property?.address;
  return propertyAddress
    ? formatAddress(propertyAddress, {
        format: "street, suburb",
      })
    : undefined;
}

function getTransactionPaymentMethodDescription(transaction: {
  amount?: any;
  paymentMethod?: any;
}): string | undefined {
  const { paymentMethod } = transaction;
  const paymentMethodType = paymentMethod?.__typename;
  if (transaction.amount.cents < 0) return undefined;

  if (paymentMethodType === CreditCardTypeName) {
    const card = paymentMethod;
    const cardNum = card.number;
    const cardType = card.type ? card.type : "Card";
    return `${
      cardType.charAt(0).toUpperCase() + cardType.slice(1)
    } ending in ${cardNum?.slice(cardNum?.length - 4)}`;
  }

  if (paymentMethodType === BankAccountTypeName) {
    const acctNo = paymentMethod.accountNumber;
    return `Bank account ending in ${acctNo?.slice(acctNo?.length - 4)}`;
  }

  return undefined;
}

export { ConnectedTransactionListItem as TransactionListItem };
