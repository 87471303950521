import { ListProps } from "@material-ui/core";
import React, { ReactElement } from "react";
import {
  Datagrid,
  ExportButton,
  Filter,
  FilterProps,
  FunctionField,
  TextField,
} from "react-admin";
import { OperationData } from "../../api";
import { Fee } from "../../api/ResourceName";
import {
  DateTimeField,
  LinkField,
  List,
  ManagementField,
  ManagementInput,
  MoneyField,
  PercentageField,
} from "../../common";
import { FeeStatusInput } from "../../common/inputs/FeeStatusInput.tsx";
import { FeeStatusBadge } from "../../features/Fee/FeeStatusBadge";

type FeeRecord = OperationData<typeof Fee, "GET_LIST">[number];

function Filters(props: Omit<FilterProps, "children">) {
  return (
    <Filter {...props}>
      <ManagementInput source="managementId" alwaysOn autoFocus />
      <FeeStatusInput label="Fee Status" source="status" alwaysOn />
    </Filter>
  );
}

export function FeeList(props: ListProps): ReactElement {
  return (
    <List
      {...props}
      filters={<Filters />}
      bulkActionButtons={false}
      actions={<ExportButton />}
    >
      <Datagrid rowClick="show">
        <TextField source="type" sortable={false} />
        <ManagementField source="management" sortable={false} />
        <LinkField source="appliedTo" sortable={false} />
        <MoneyField source="amount" sortable={false} />
        <PercentageField source="percentage" sortable={false} />
        <TextField
          label="Tax category"
          source="taxCategory.name"
          sortable={false}
        />
        <FunctionField<FeeRecord>
          label="Status"
          source="status"
          render={(record) =>
            record?.status ? <FeeStatusBadge status={record.status} /> : null
          }
        />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
}
