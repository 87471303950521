import { hasPresentKey } from "ts-is-present";

export function hasPresentKeys<K extends string | number | symbol>(
  ...keys: K[]
) {
  return function hasKeys<T>(
    a: T
  ): a is T & { [k in K]: k extends keyof T ? NonNullable<T[k]> : never } {
    return keys.every((k) => hasPresentKey(k)(a));
  };
}
