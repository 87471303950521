import { Box, IconButton, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { first, last } from "lodash";
import React, { useState } from "react";
import { DateField, FunctionField, TextField } from "react-admin";
import { isPresent } from "ts-is-present";
import type { Address } from "../../../api/deprecatedTypes";
import { Management, Tenancy } from "../../../api/ResourceName";
import { ManagementFragment } from "../../../api/resources/Management/managementsClient.generated";
import {
  DescriptionList,
  FormattedDateField,
  LinkField,
  StatusField,
} from "../../../common";
import { DetailCard } from "../../../common/ui/cards";
import { EditAddressForm } from "./EditAddressForm";
import {
  PropertyShowManagement,
  PropertyShowRecord,
  PropertyShowTenancy,
} from "./types";

const formatAddress = (address: Address) => {
  if (address) {
    return [
      address.unitStreetNumber,
      `${address.streetName},`,
      address.suburb,
      address.state,
      address.postcode,
      address.country,
    ].join(" ");
  }

  return null;
};

const AddressField = ({ record, source, onEditClick }: any) => (
  <Box flexDirection="row" fontWeight={600}>
    {formatAddress(record[source])}
    <EditAddressButton onClick={onEditClick} />
  </Box>
);

function EditAddressButton({ onClick }: { onClick: () => void }) {
  return (
    <Tooltip title="Edit Property Address">
      <IconButton color="primary" onClick={onClick}>
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

const useStyles = makeStyles({
  box: {
    color: "grey",
  },
});
export const FieldTitle = (props: any) => {
  const classes = useStyles();
  return <Box mb={2} className={classes.box} {...props} />;
};

export const PropertyTitleCard = ({
  management,
  tenancy,
  property,
}: {
  management: PropertyShowManagement;
  property: PropertyShowRecord;
  tenancy?: PropertyShowTenancy;
}) => {
  const [showEditAddressForm, setShowEditAddressForm] = useState(false);
  return (
    <>
      <DetailCard
        title={
          <AddressField
            record={property}
            source="address"
            onEditClick={(): void => setShowEditAddressForm(true)}
          />
        }
        contents={
          management && (
            <Grid container direction="row" justify="flex-start">
              <Grid
                item
                xs={6}
                container
                direction="row"
                alignItems="center"
                style={{ paddingLeft: 8 }}
              >
                <DescriptionList
                  items={[
                    {
                      name: "Management",
                      description: (
                        <LinkField
                          record={management}
                          source="id"
                          sourceResource={Management}
                        />
                      ),
                    },
                    {
                      name: "Management status",
                      description: (
                        <StatusField
                          record={{
                            status: management.isDraft
                              ? "draft"
                              : management.dateRange?.isBefore()
                              ? "former"
                              : management.dateRange?.isAfter()
                              ? "future"
                              : "active",
                          }}
                          source="status"
                        />
                      ),
                    },
                    {
                      name: "Management agreement expiry",
                      description: (
                        <FunctionField
                          record={management}
                          render={(record: any) => {
                            const expiry = first<any>(
                              record?.allManagementAgreements
                            )?.fixedTermEndDate;
                            return (
                              <FormattedDateField
                                record={{ expiry }}
                                source="expiry"
                                emptyText="N/A"
                              />
                            );
                          }}
                        />
                      ),
                    },
                    {
                      name: "Managed since",
                      description: (
                        <FunctionField
                          record={management}
                          label="Managed since"
                          render={(record: any) => {
                            const startDate = last<any>(
                              record?.allManagementAgreements
                            )?.startDate;
                            return (
                              <FormattedDateField
                                record={{ startDate }}
                                source="startDate"
                              />
                            );
                          }}
                        />
                      ),
                    },
                    {
                      name: "Management end date",
                      description: (
                        <FormattedDateField
                          record={management}
                          source="endDate"
                          emptyText="N/A"
                        />
                      ),
                    },
                    ...(management.endReason?.label
                      ? [
                          {
                            name: "Management end reason",
                            description: (
                              <TextField
                                record={management}
                                source="endReason.label"
                                emptyText="N/A"
                              />
                            ),
                          },
                        ]
                      : []),
                    ...(management.endReason?.causes?.length
                      ? [
                          {
                            name: "Management end cause",
                            description: (
                              <FunctionField
                                record={management}
                                render={(record: any) => {
                                  const {
                                    endReason,
                                  } = record as ManagementFragment;

                                  return endReason?.causes
                                    .map(({ label }) => label)
                                    .join(", ");
                                }}
                              />
                            ),
                          },
                        ]
                      : []),
                    ...(management.endNote
                      ? [
                          {
                            name: "Management end note",
                            description: (
                              <TextField
                                record={management}
                                source="endNote"
                                emptyText="N/A"
                              />
                            ),
                          },
                        ]
                      : []),
                    {
                      name: "Paid to date",
                      description: (
                        <FunctionField
                          record={tenancy ?? undefined}
                          render={(record: any) => {
                            return record && record.liability ? (
                              <DateField
                                record={record.liability}
                                source="paidToDate"
                                emptyText="N/A"
                              />
                            ) : (
                              "N/A"
                            );
                          }}
                        />
                      ),
                    },
                    {
                      name: "Effective Paid to date",
                      description: (
                        <FunctionField
                          record={tenancy ?? undefined}
                          render={(record: any) => {
                            return record && record.liability ? (
                              <DateField
                                record={record.liability}
                                source="effectivePaidToDate"
                                emptyText="N/A"
                              />
                            ) : (
                              "N/A"
                            );
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>

              <Grid
                container
                item
                xs={6}
                direction="row"
                justify="flex-end"
                style={{ paddingRight: 8 }}
              >
                <DescriptionList
                  items={[
                    {
                      name: "Tenancy",
                      description: (
                        <LinkField record={tenancy} sourceResource={Tenancy} />
                      ),
                    },
                    tenancy && {
                      name: "Tenancy start date",
                      description: (
                        <FormattedDateField
                          record={tenancy}
                          source="startDate"
                          emptyText="N/A"
                        />
                      ),
                    },
                    tenancy && {
                      name: "Tenancy end date",
                      description: (
                        <FormattedDateField
                          label="Tenancy end date"
                          record={tenancy}
                          source="endDate"
                          emptyText="N/A"
                        />
                      ),
                    },
                    tenancy && {
                      name: "Tenancy voided date",
                      description: (
                        <FormattedDateField
                          label="Tenancy voided date"
                          record={tenancy}
                          source="voidedAt"
                          emptyText="N/A"
                        />
                      ),
                    },
                  ].filter(isPresent)}
                />
              </Grid>
            </Grid>
          )
        }
      />
      {showEditAddressForm && (
        <EditAddressForm
          property={property}
          onDismiss={() => setShowEditAddressForm(false)}
        />
      )}
    </>
  );
};
