import { AiloRN } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";

export function getPrimaryOwnerAilorn(
  managementFolio: ManagementFolioShowRecord
): AiloRN | undefined {
  const ownerships = (managementFolio.owners ?? []).filter(isPresent);
  const primaryOwner = ownerships.find((ownership) => ownership.isPrimary);
  return primaryOwner?.owner?.id
    ? AiloRN.of("authz:legalentity", primaryOwner.owner.id)
    : undefined;
}
