import { Box } from "@material-ui/core";
import get from "lodash/get";
import React from "react";
import { TextField } from "react-admin";
import { CopyToClipboardButton } from "../ui";

export const CopyableTextField = ({ source, record, style = {} }: any) => {
  return (
    <Box {...style}>
      <TextField source={source} record={record} />
      <CopyToClipboardButton value={get(record, source)} />
    </Box>
  );
};
CopyableTextField.defaultProps = {
  addLabel: true,
};
