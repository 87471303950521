import React from "react";
import { Button, Link } from "react-admin";
import { CopyToClipboardButton } from "../../ui/buttons/CopyToClipboardButton";

/**
 * @deprecated Use IdField / LinkField
 */
export function DeprecatedIdField({
  id,
  displayName,
  resource,
  copyable,
  ...rest
}: any) {
  if (!id || !resource) {
    return null;
  }

  return (
    <>
      <Button
        {...rest}
        component={Link}
        color="primary"
        to={`/${resource}/${id}/show`}
        label={displayName || id}
      />
      {copyable ? <CopyToClipboardButton value={displayName || id} /> : null}
    </>
  );
}
