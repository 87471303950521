import { Maybe } from "../graphql/types.generated";

export type PaginationParams = {
  page?: string | number;
  perPage?: string | number;
};

export function buildPaginationVariables(
  params?: PaginationParams
): {
  cursor?: string;
  pageSize: number;
} {
  const cursor =
    String(params?.page) === "1" ||
    params?.page === undefined ||
    params?.page === null
      ? undefined
      : String(params.page);
  const pageSize = Number(params?.perPage ?? 50);
  return {
    cursor,
    pageSize,
  };
}

export function buildPaginatedResponseFromPaginatedList<TItem>(data: {
  pageInfo:
    | {
        total: number;
        nextCursor?: Maybe<string>;
        hasNext: boolean;
      }
    | {
        total: number;
        nextCursor?: Maybe<string>;
        hasMore: boolean;
      };
  items: TItem[];
}): {
  data: TItem[];
  total: number;
  nextCursor?: string;
  hasMore: boolean;
} {
  return {
    data: data.items,
    total: data.pageInfo.total,
    nextCursor: data.pageInfo.nextCursor ?? undefined,
    hasMore:
      "hasNext" in data.pageInfo
        ? data.pageInfo.hasNext
        : data.pageInfo.hasMore,
  };
}
