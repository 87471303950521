import React, { useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  useRedirect,
  useShowController,
} from "react-admin";
import { Person } from "../../api/ResourceName";
import { IdField } from "../../common";

export const UserShow = (props: Record<string, any>) => {
  const { record } = useShowController(props);
  const redirect = useRedirect();
  useEffect(() => {
    if (!record) {
      return;
    }

    redirect(`/${Person}/${record.person.id}/show`);
  }, [record, record?.person.id, redirect]);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <IdField source="id" />
        <IdField source="person.id" />
      </SimpleShowLayout>
    </Show>
  );
};
