import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core";
import { formatPersonName } from "../utils";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
  chip: {
    margin: 4,
  },
});

const LegalEntitiesField = ({ record }: { record: any }) => {
  const classes = useStyles();
  return record ? (
    <span className={classes.main}>
      {record.legalEntities &&
        record.legalEntities.map((le: any) => {
          return le.registeredEntityId ? (
            <Chip
              key={le.registeredEntityId}
              className={classes.chip}
              label={le.registeredEntityName}
            />
          ) : (
            <Chip
              key={le.id}
              className={classes.chip}
              label={formatPersonName(le)}
            />
          );
        })}
    </span>
  ) : null;
};

export { LegalEntitiesField };
