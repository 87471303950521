import gql from "graphql-tag";
import { CREATE, GET_LIST, GET_ONE } from "react-admin";
import { AiloRN, services } from "@ailo/ailorn";
import type { RaDataGraphqlClientArgs } from "../../deprecatedTypes";
import {
  AddTrustAccountMutation,
  DisburseFundsToTrustAccountMutation,
  TrustAccountQuery,
  TrustAccountsQuery,
} from "./trustAccountClient.generated";
import { GraphQLResponse } from "../../common";

const TRANSFER_TO_TRUST = "transfer_to_trust";

function trustAccountClient({ raFetchType, params }: RaDataGraphqlClientArgs) {
  switch (raFetchType) {
    case GET_ONE:
      return {
        query: gql`
          query trustAccount($id: ID!) {
            trustAccount(id: $id) {
              id
              ailorn
              ownerLegalEntity {
                registeredEntityName
              }
            }
          }
        `,
        variables: {
          id: AiloRN.of("trust-account:trust-account", params.id).internalId,
        },
        parseResponse: (response: GraphQLResponse<TrustAccountQuery>) => {
          return {
            data: response.data.trustAccount,
          };
        },
      };
    case GET_LIST:
      return {
        query: gql`
          query trustAccounts {
            trustAccounts(filter: {}) {
              edges {
                node {
                  id
                  ailorn
                  ownerLegalEntity {
                    registeredEntityName
                  }
                }
              }
            }
          }
        `,
        parseResponse: (response: GraphQLResponse<TrustAccountsQuery>) => {
          return {
            data: response.data.trustAccounts.edges.map((edge) => edge.node),
            total: response.data.trustAccounts.edges.length,
          };
        },
      };
    case CREATE:
      return {
        query: gql`
          mutation addTrustAccount(
            $agency: AiloRN!
            $bsb: String!
            $accountNumber: String!
            $accountName: String!
          ) {
            addTrustAccount(
              input: {
                agency: $agency
                bsb: $bsb
                accountNumber: $accountNumber
                accountName: $accountName
              }
            ) {
              agency
              paymentMethod {
                id
              }
            }
          }
        `,
        variables: {
          ...params.data,
          agency: AiloRN.of(
            services.AuthZ.legalEntity,
            params.data.ownerLegalEntity
          ),
        },
        parseResponse: (response: { data: AddTrustAccountMutation }) => ({
          data: {
            ...response.data.addTrustAccount,
            id: response?.data?.addTrustAccount?.paymentMethod.id,
          },
        }),
      };
    case TRANSFER_TO_TRUST:
      return {
        query: gql`
          mutation disburseFundsToTrustAccount(
            $amount: MoneyInput!
            $agency: AiloRN!
            $escrowAccount: AiloRN!
            $idempotencyKey: String!
            $description: String!
          ) {
            disburseFundsToTrustAccount(
              input: {
                amount: $amount
                agency: $agency
                escrowAccount: $escrowAccount
                idempotencyKey: $idempotencyKey
                description: $description
              }
            ) {
              success
            }
          }
        `,
        variables: {
          ...params,
        },
        parseResponse: (response: {
          data: DisburseFundsToTrustAccountMutation;
        }) => response,
      };
    default:
      throw new Error(`${raFetchType} is not yet implemented`);
  }
}

export { trustAccountClient, TRANSFER_TO_TRUST };
