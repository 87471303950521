import { AiloRN } from "@ailo/ailorn";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {
  ArrayField,
  Datagrid,
  DeleteButton,
  FunctionField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  ChipField,
} from "react-admin";
import { Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";

import Container from "@material-ui/core/Container";
import {
  BankAccountBlackList,
  Company,
  OperationData,
  Person,
} from "../../../../api";
import { CopyableTextField, DeprecatedIdField } from "../../../../common";
import { formatPersonName } from "../../../../common/utils";

type CompanyRecord = OperationData<typeof Company, "GET_ONE">;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    "&:first-child": {
      padding: 0,
    },
  },
}));

export const CompanyBasicInfo = ({ record }: { record: CompanyRecord }) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout className={classes.root} record={record}>
      <FunctionField<CompanyRecord>
        label="Organisation"
        render={(r) => (
          <DeprecatedIdField
            id={r!.organisation.id}
            displayName={r!.organisation.name}
            resource="Customers"
          />
        )}
      />
      <FunctionField<CompanyRecord>
        label="Owned by "
        render={(r) => (
          <DeprecatedIdField
            id={r!.owner.id}
            displayName={formatPersonName(r!.owner)}
            resource={Person}
          />
        )}
      />
      <CopyableTextField label="Organisation ID" source="organisation.id" />
      <TextField label="ABN" source="registeredEntityId" />
      <TextField label="Primary Contact Email" source="emailAddress" />
      {record.organisation.orgType === "Agency" && (
        <FunctionField
          label="Accounts Email"
          render={() => (
            <Container style={{ padding: 0, display: "flex" }}>
              <ArrayField
                record={{ emails: record.accountsEmail, id: record.id }}
                source="emails"
              >
                <SingleFieldList linkType={false}>
                  <FunctionField
                    render={(email: any) => {
                      return (
                        <ChipField
                          record={{ email, id: email }}
                          source="email"
                        />
                      );
                    }}
                  />
                </SingleFieldList>
              </ArrayField>
              {record.accountsEmail.length === 0 && (
                <span style={{ display: "flex", alignItems: "center" }}>
                  Unspecified - financial notifications will be sent to agency
                  owner
                </span>
              )}
              <span
                style={{
                  paddingLeft: 12,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Destination for transfer summaries and other financial notifications">
                  <Info />
                </Tooltip>
              </span>
            </Container>
          )}
        />
      )}
      <TextField label="Address 1" source="address1" />
      <TextField label="Address 2" source="address2" />
      <TextField label="Suburb" source="suburb" />
      <TextField label="State" source="state" />
      <TextField label="Postcode" source="postcode" />
      <TextField label="Country" source="country" />
      <TextField label="Time Zone" source="timezone" />
      <ArrayField
        label="Bank Accounts (blacklisted)"
        source="bankAccountBlackLists"
        resource={BankAccountBlackList}
      >
        <Datagrid>
          <CopyableTextField label="id" source="id" />
          <CopyableTextField label="Bsb" source="bsb" />
          <CopyableTextField label="Account Number" source="accountNumber" />
          <TextField label="Description" source="description" />
          <BankAccountBlackListsDeleteButton />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function BankAccountBlackListsDeleteButton(props: any) {
  return (
    <DeleteButton
      undoable={false}
      {...props}
      resource={BankAccountBlackList}
      basePath={`/${Company}/${
        AiloRN.from(props.record.ownerLegalEntity)?.internalId
      }/show`}
    />
  );
}
