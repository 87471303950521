import { Button } from "react-admin";
import React from "react";
import {
  EmptyTransactionsList,
  EntriesByMonthList,
  ErrorTransactionsList,
} from "..";
import { TransactionListItem } from "./transactionListItem";
import { LoadingList } from "../../../../common";

const TransactionsList = ({ items, hasMore, loadMore, style }: any) => {
  return (
    <>
      <EntriesByMonthList
        entries={items}
        groupBy="createdAt"
        listItemComponent={(item: any) => (
          <TransactionListItem key={item.id} transaction={item} />
        )}
        insetFooter={hasMore && <Button label="Load more" onClick={loadMore} />}
        style={style}
      />
    </>
  );
};

TransactionsList.Loading = LoadingList;
TransactionsList.Empty = EmptyTransactionsList;
TransactionsList.Error = ErrorTransactionsList;
export { TransactionsList };
