import React from "react";
import { LedgerEvent } from "../../../api";
import { useInfinitelyPaginatedQuery } from "../../../common";
import { GeneralLedgerEntryList } from "../../../features/GeneralLedgerEntry";

export function LedgerEventEntriesList({
  ledgerEventId,
}: {
  ledgerEventId: string;
}): React.ReactElement {
  const {
    loaded,
    error,
    data,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    type: "LIST_EVENT_ENTRIES",
    resource: LedgerEvent,
    payload: {
      ledgerEventId,
    },
    pageSize: 20,
  });

  if (!loaded) return <GeneralLedgerEntryList.Loading />;
  if (error) return <GeneralLedgerEntryList.Error />;
  if (!data?.length) return <GeneralLedgerEntryList.Empty />;

  return (
    <GeneralLedgerEntryList
      hasMore={hasMore}
      loadMore={loadMore}
      items={data}
    />
  );
}
