import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { Container } from "@material-ui/core";
import React, { useMemo, useEffect, useCallback } from "react";
import {
  AutocompleteInput,
  FormDataConsumer,
  required,
  TextInput,
  TextInputProps,
  useInput,
} from "react-admin";
import { CreateBillFormData } from "../..";
import { PaymentMethod, Supplier } from "../../..";
import { FIND_OR_CREATE_PAYMENT_REFERENCE } from "../../../../api/resources/PaymentMethod/paymentMethodsClient";
import {
  FindOrCreatePaymentReferenceMutation,
  FindOrCreatePaymentReferenceMutationVariables,
} from "../../../../api/resources/PaymentMethod/paymentMethodsClient.generated";
import { FIND_OR_CREATE_INTERNAL_SUPPLIER } from "../../../../api/resources/Supplier/supplierClient";
import {
  FindOrCreateInternalSupplierMutation,
  FindOrCreateInternalSupplierMutationVariables,
} from "../../../../api/resources/Supplier/supplierClient.generated";
import { useMutation } from "../../../../common";
import {
  PaymentMethodOption,
  useGetPaymentMethodOptions,
} from "../useGetPaymentMethodOptions";
import { InvoiceNumberInput } from "./InvoiceNumberInput";

export const PAYMENT_DESCRIPTION_MAX_LIMIT = 9;

type PaymentMethodInputProps = Pick<TextInputProps, "style"> & {
  payee?: CreateBillFormData["payee"];
  organisationReference?: CreateBillFormData["organisationAilorn"];
  crn?: string;
  invoiceNumber?: CreateBillFormData["invoiceNumber"];
  propertyAddress?: CreateBillFormData["property"]["address"];
  internal?: boolean;
};

export function PaymentDestinationInput(
  props: PaymentMethodInputProps
): React.ReactElement {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <PaymentMethodInputInner
          payee={formData.payee}
          organisationReference={formData.organisationAilorn}
          crn={formData.crn}
          invoiceNumber={formData.invoiceNumber}
          propertyAddress={formData.property?.address}
          internal={formData.internal}
          {...props}
        />
      )}
    </FormDataConsumer>
  );
}

export function PaymentMethodInputInner({
  payee,
  organisationReference,
  invoiceNumber,
  crn,
  propertyAddress,
  internal,
  style,
  ...rest
}: PaymentMethodInputProps): React.ReactElement {
  const options = useGetPaymentMethodOptions(payee, internal);
  const {
    input: { value: paymentMethod, onChange },
  }: {
    input: {
      value?: PaymentMethodOption;
      onChange: (value?: PaymentMethodOption) => void;
    };
  } = useInput({ source: "paymentMethod" });
  const {
    input: { onChange: onChangePaymentReferenceId },
  } = useInput({ source: "paymentReferenceId" });
  const {
    input: { onChange: onChangeSupplierId },
  } = useInput({ source: "supplierId" });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(options[0]), [options]);

  const [
    findOrCreateInternalSupplierReferenceMutation,
    {
      data: findOrCreateInternalSupplierData,
      loaded: findOrCreateInternalSupplierLoaded,
    },
  ] = useMutation<
    FindOrCreateInternalSupplierMutationVariables["supplierDetails"],
    FindOrCreateInternalSupplierMutation
  >(
    {
      type: FIND_OR_CREATE_INTERNAL_SUPPLIER,
      resource: Supplier,
    },
    { refreshOnSuccess: false, muteNotifications: true }
  );

  const supplierAilorn: string | undefined = useMemo(
    () =>
      payee?.type === "supplier"
        ? AiloRN.fromString(payee.ailorn).toString()
        : findOrCreateInternalSupplierData?.findOrCreateInternalSupplier
            ?.ailoRN,
    [
      findOrCreateInternalSupplierData?.findOrCreateInternalSupplier?.ailoRN,
      payee?.ailorn,
      payee?.type,
    ]
  );
  useEffect(() => {
    if (!payee || !organisationReference) return;
    if (payee.type === "supplier") return;
    const internalReference =
      paymentMethod?.type === "internal"
        ? paymentMethod.supplierAilorn
        : payee.ailorn;
    if (!internalReference) return;

    void findOrCreateInternalSupplierReferenceMutation({
      payload: {
        organisationReference: organisationReference.toString(),
        internalReference,
        name: payee.name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod?.id]);

  const [
    findOrCreatePaymentReferenceMutation,
    { data: findOrCreatePaymentReferenceData },
  ] = useMutation<
    FindOrCreatePaymentReferenceMutationVariables["paymentReferenceDetails"],
    FindOrCreatePaymentReferenceMutation
  >(
    {
      type: FIND_OR_CREATE_PAYMENT_REFERENCE,
      resource: PaymentMethod,
    },
    { refreshOnSuccess: false, muteNotifications: true }
  );

  useEffect(() => {
    onChangeSupplierId(
      supplierAilorn ? AiloRN.fromString(supplierAilorn).internalId : undefined
    );
  }, [onChangeSupplierId, supplierAilorn]);

  const findOrCreatePaymentReference = useCallback(() => {
    if (supplierAilorn && paymentMethod) {
      void findOrCreatePaymentReferenceMutation({
        payload: {
          supplierId: AiloRN.fromString(supplierAilorn).internalId,
          supplierPaymentMethodReference:
            paymentMethod?.type !== "internal"
              ? paymentMethod.paymentMethodId
              : undefined,
          crn: paymentMethod?.type === "BPay" ? crn : undefined,
          paymentDescription:
            paymentMethod?.type === "BankAccount"
              ? getSupplierPaymentDescription(
                  invoiceNumber,
                  formatAddress(propertyAddress ?? {}, {
                    format: "street, suburb",
                  })
                )
              : undefined,
        },
      });
    }
  }, [
    crn,
    findOrCreatePaymentReferenceMutation,
    invoiceNumber,
    paymentMethod,
    propertyAddress,
    supplierAilorn,
  ]);

  useEffect(() => {
    if (
      supplierAilorn &&
      findOrCreateInternalSupplierLoaded &&
      paymentMethod?.type === "internal"
    ) {
      findOrCreatePaymentReference();
    }
  }, [
    findOrCreateInternalSupplierLoaded,
    findOrCreatePaymentReference,
    paymentMethod?.type,
    supplierAilorn,
  ]);

  useEffect(() => {
    onChangePaymentReferenceId(
      findOrCreatePaymentReferenceData?.findOrCreatePaymentReference?.id
    );
  }, [findOrCreatePaymentReferenceData, onChangePaymentReferenceId]);

  return (
    <Container style={{ width: "100%", padding: 0 }}>
      <AutocompleteInput
        choices={options}
        disabled={options.length <= 1}
        helperText={
          payee?.type === "supplier" && options.length === 0
            ? "No payment method found for this supplier. Please add one from the supplier view"
            : undefined
        }
        onSelect={(value: PaymentMethodOption) => onChange(value)}
        defaultValue={options.length === 1 && options[0].id}
        validate={[required()]}
        label="Payment Destination"
        source="paymentMethod.id"
        options={{
          style,
          InputProps: {
            type: "search", // needed to stop 1password from trying to auto fill this field
          },
        }}
        resettable
        {...rest}
      />
      {paymentMethod?.type === "BPay" && (
        <TextInput
          label="CRN"
          source="crn"
          style={{ width: "100%" }}
          validate={[required()]}
          onBlur={() => crn && findOrCreatePaymentReference()}
        />
      )}
      <InvoiceNumberInput
        style={{ width: "100%" }}
        onBlur={() => invoiceNumber && findOrCreatePaymentReference()}
      />
    </Container>
  );
}

function getSupplierPaymentDescription(
  invoiceNumber: string | undefined,
  propertyAddress: string | undefined = ""
): string {
  if (invoiceNumber?.trim()) {
    const desc = invoiceNumber.trim();
    return desc.length <= PAYMENT_DESCRIPTION_MAX_LIMIT
      ? desc
          .padEnd(Math.min(desc.length + 1, PAYMENT_DESCRIPTION_MAX_LIMIT), " ")
          .padEnd(PAYMENT_DESCRIPTION_MAX_LIMIT, "‐")
      : "*".concat(desc.slice(desc.length - PAYMENT_DESCRIPTION_MAX_LIMIT + 1));
  }
  const desc = propertyAddress.trim();
  return desc.length <= PAYMENT_DESCRIPTION_MAX_LIMIT
    ? desc
        .padEnd(Math.min(desc.length + 1, PAYMENT_DESCRIPTION_MAX_LIMIT), " ")
        .padEnd(PAYMENT_DESCRIPTION_MAX_LIMIT, "‐")
    : desc.slice(0, PAYMENT_DESCRIPTION_MAX_LIMIT - 1).concat("*");
}
