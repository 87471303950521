import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Colors } from "../colors";

export interface SectionListStyle {
  maxHeight?: number;
}

const useStyles = makeStyles((theme) => ({
  root: (props?: SectionListStyle) => ({
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: props?.maxHeight,
  }),
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  subHeader: {
    backgroundColor: Colors.CLOUD,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: 12,
  },
}));

export const SectionList = ({
  sections,
  renderItem,
  insetFooter,
  style,
}: any & { style?: SectionListStyle }) => {
  const classes = useStyles(style);
  return (
    <>
      <List
        className={classes.root}
        style={{ maxHeight: "auto" }}
        subheader={<li />}
      >
        {sections.map((section: { title: string; data: any[] }) => (
          <li key={section.title} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader className={classes.subHeader}>
                {section.title}
              </ListSubheader>
              {section.data.map((item: any, index: any) =>
                renderItem({
                  item,
                  section,
                  index,
                })
              )}
            </ul>
          </li>
        ))}
        {insetFooter}
      </List>
    </>
  );
};
