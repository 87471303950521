import React from "react";
import { Show, SimpleShowLayout } from "react-admin";
import { Liability } from "../../api";
import { CopyableTextField, LinkField, PropertyField } from "../../common";

export const TenancyShow = (props: Record<string, any>) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <CopyableTextField label="ID" source="id" />
        <PropertyField
          source="management.property"
          managementIdSource="management.id"
          tenancyIdSource="id"
        />
        <LinkField source="management" includeApiTypeInLinkText={false} />
        <LinkField
          source="liability"
          sourceResource={Liability}
          includeApiTypeInLinkText={false}
        />
      </SimpleShowLayout>
    </Show>
  );
};
