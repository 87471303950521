import React, { useCallback, useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { RemoveTenancyDialog } from "./RemoveTenancyDialog";
import { useMutation } from "../../../common";
import { REMOVE_TENANCY } from "../../../api/resources/MigratingManagement";
import { MigratingManagement } from "../../../api";

interface Props {
  id: string;
}

export function RemoveTenancyButton({ id }: Props): React.ReactElement {
  const refresh = useRefresh();
  const notify = useNotify();
  const [dialogOpened, setDialogOpened] = useState(false);

  const [removeTenancy, { loading: removeTenancyLoading }] = useMutation(
    {
      type: REMOVE_TENANCY,
      resource: MigratingManagement,
    },
    {
      successMessage: "Tenancy has been removed",
      onSuccess: () => {
        refresh();
      },
      onFailure: (error) =>
        notify(`Unable to remove tenancy: ${error.message}`, "error"),
    }
  );

  const openConfirmationDialog = useCallback(() => {
    setDialogOpened(true);
  }, []);

  const cancelRemoveTenancy = useCallback(() => {
    setDialogOpened(false);
  }, []);

  const confirmRemoveTenancy = useCallback(async () => {
    setDialogOpened(false);
    await removeTenancy({ payload: { id } });
  }, [removeTenancy, id]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={openConfirmationDialog}
        disabled={removeTenancyLoading}
        startIcon={<DeleteIcon />}
      >
        Remove Tenancy
      </Button>
      <RemoveTenancyDialog
        open={dialogOpened}
        onClose={cancelRemoveTenancy}
        onAgree={confirmRemoveTenancy}
      />
    </>
  );
}
