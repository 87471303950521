import React from "react";
import Box from "@material-ui/core/Box";

/**
 * @deprecated Use Badge instead.
 */
export function BaseBadge({ color, style, children }: any) {
  return (
    <Box
      px="8px"
      borderRadius={4}
      justifyContent="center"
      alignItems="center"
      width="fit-content"
      {...style}
    >
      <Box color={color} textAlign="center" fontSize={12}>
        {children}
      </Box>
    </Box>
  );
}
