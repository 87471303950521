import gql from "graphql-tag";
import { transformLiabilityPlanChangeRequest } from "../../common";

type Params = {
  raFetchType: string;
  params: Record<string, any>;
  resourceString: string;
};

function liabilityPlanChangePreview({
  raFetchType,
  params,
  resourceString,
}: Params) {
  return {
    query: gql`
      query liabilityPlanChangePreview(
        $liabilityId: ID!
        $proposedPlans: [ProposedPlan!]!
      ) {
        liabilityPlanChangePreview(
          planToPreview: {
            liabilityId: $liabilityId
            proposedPlans: $proposedPlans
          }
        ) {
          earliestStartDateTime
          requiredAdjustment {
            cents
          }
          paidToDate
          paidToDateWithProposedPlan
          mergedPlans {
            amount {
              cents
            }
            frequency
            startDate
            isExisting
          }
        }
      }
    `,
    variables: transformLiabilityPlanChangeRequest(params.data),
    parseResponse: (response: {
      data: { liabilityPlanChangePreview: any };
    }) => {
      return {
        data: response.data.liabilityPlanChangePreview,
      };
    },
  };
}

export { liabilityPlanChangePreview };
