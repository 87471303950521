import React, { useState } from "react";
import { AiloRN } from "@ailo/ailorn";
import { EmailOutlined } from "@material-ui/icons";
import { FormDataConsumer, RadioButtonGroupInput } from "react-admin";
import {
  Colors,
  DialogControlButton,
  SimpleFormDialog,
  useMutation,
} from "../../../common";
import { Notifications } from "../../../api";
import {
  SEND_CENTREPAY_ACCOUNT_SETUP_EMAIL,
  SEND_CENTREPAY_ONBOARDING_EMAIL,
} from "../../../api/resources/Notifications/notificationsClient";

export function SendCentrepayOnboardingNotificationDialogControl({
  agency,
}: {
  agency: AiloRN;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [sendCentrepayOnboardingEmail] = useMutation<{
    agencyAilorn: AiloRN;
  }>(
    {
      type: SEND_CENTREPAY_ONBOARDING_EMAIL,
      resource: Notifications,
    },
    {
      successMessage: `Sent Centrepay onboarding email`,
    }
  );

  const [sendCentrepayAccountSetupEmail] = useMutation<{
    agencyAilorn: AiloRN;
  }>(
    {
      type: SEND_CENTREPAY_ACCOUNT_SETUP_EMAIL,
      resource: Notifications,
    },
    {
      successMessage: `Sent Centrepay account setup email`,
    }
  );

  const submit = async ({
    notificationType,
  }: {
    notificationType: string;
  }): Promise<void> => {
    if (notificationType === "onboardingNotification") {
      await sendCentrepayOnboardingEmail({ payload: { agencyAilorn: agency } });
    }
    if (notificationType === "accountSetupNotification") {
      await sendCentrepayAccountSetupEmail({
        payload: { agencyAilorn: agency },
      });
    }
  };

  return (
    <div>
      <DialogControlButton
        onClick={onClick}
        icon={
          <EmailOutlined
            style={{
              color: Colors.AILO_RED,
              marginRight: 5,
            }}
          />
        }
        disabled={false}
      >
        Send Centrepay emails
      </DialogControlButton>
      <SimpleFormDialog
        onClose={onClose}
        open={open}
        title="Send Centrepay emails"
        save={submit}
        style={{ width: 500 }}
      >
        <RadioButtonGroupInput
          source="notificationType"
          choices={[
            {
              id: "onboardingNotification",
              name: "Centrepay Onboarding Email",
            },
            {
              id: "accountSetupNotification",
              name: "Centrepay Account Setup Instructions",
            },
          ]}
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.notificationType === "onboardingNotification") {
              return (
                <div>
                  Send out an email containing details on setting up Centrepay
                  to all the agency's members?
                </div>
              );
            }
            if (formData.notificationType === "accountSetupNotification") {
              return (
                <div>
                  Send an email with Centrepay virtual account details to all
                  the agency's members?
                </div>
              );
            }
            return <></>;
          }}
        </FormDataConsumer>
      </SimpleFormDialog>
    </div>
  );
}
