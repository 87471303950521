import React, { useState, useCallback } from "react";
import { DELETE, useRefresh } from "react-admin";
import { Button, DialogContentText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useMutation } from "../../common";

interface Props {
  record: {
    startDate: string;
    endDate: string;
    tenancyId: Date;
    tenant: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
}

export function DeleteTenantshipButton({ record }: Props): React.ReactElement {
  const refresh = useRefresh();
  const [dialogOpened, setDialogOpened] = useState(false);

  const [deleteTenantship, { loading }] = useMutation(
    {
      type: DELETE,
      resource: "Tenantship",
    },
    {
      onSuccess: () => {
        refresh();
      },
    }
  );
  const openConfirmationDialog = useCallback(() => {
    setDialogOpened(true);
  }, []);
  const cancelDeleteTenantship = useCallback(() => {
    setDialogOpened(false);
  }, []);
  const confirmDeleteTenantship = useCallback(async () => {
    setDialogOpened(false);
    await deleteTenantship({
      payload: {
        input: { tenancyId: record.tenancyId, tenantId: record.tenant.id },
      },
    });
  }, [deleteTenantship, record]);
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={openConfirmationDialog}
        disabled={loading}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <ConfirmationDialog
        open={dialogOpened}
        onClose={cancelDeleteTenantship}
        onDisagree={cancelDeleteTenantship}
        onAgree={confirmDeleteTenantship}
        title="Are you sure?"
        description={
          <>
            <DialogContentText>
              {`Tenantship will be deleted. Any auto rent payments need to be
              manually cancelled.`}
            </DialogContentText>
          </>
        }
      />
    </>
  );
}
