import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Error } from "react-admin";
import { ManagementFolio } from "../../../api";
import { GET_MANAGEMENT_FOLIO_IDS_FOR_OWNERS } from "../../../api/resources/ManagementFolio";
import { useQuery } from "../../../common/api/useQuery";
import { ManagementFolioCard } from "../../../features/ManagementFolio";
import { LegalEntityShowRecord } from "./LegalEntityShowRecord";

export function PortfoliosTab({
  record,
}: {
  record: LegalEntityShowRecord;
}): React.ReactElement | null {
  const { data: managementFolioIds, loading, error } = useQuery({
    type: GET_MANAGEMENT_FOLIO_IDS_FOR_OWNERS,
    resource: ManagementFolio,
    payload: {
      ids: [record.id],
    },
  });
  if (loading || !managementFolioIds) return null;

  if (error) return <Error error={error} />;

  if (managementFolioIds.length === 0) {
    return (
      <Typography style={{ padding: 20, backgroundColor: "white" }}>
        This customer has no portfolios as an investor
      </Typography>
    );
  }
  return (
    <Box
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "start",
      }}
    >
      {managementFolioIds.map((id) => (
        <ManagementFolioCard managementFolioId={id} key={id} />
      ))}
    </Box>
  );
}
