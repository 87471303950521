import { AiloRN, nsEntities } from "@ailo/ailorn";
// eslint-disable-next-line no-restricted-imports
import { useNotify, useMutation } from "react-admin";
import { Management } from "../../../../api";
import { GENERATE_TENANCY_LEDGER_STATEMENT } from "../../../../api/resources/Management/managementsClient";

export const useGenerateTenancyLedgerStatement = ({
  onSuccess,
}: {
  onSuccess?(statementProgressesId: string): void;
}): {
  send: (payload: {
    tenancyId: string;
    startDate: string;
    endDate: string;
  }) => void;
} => {
  const notify = useNotify();

  const [mutate] = useMutation(
    {
      resource: Management,
      type: GENERATE_TENANCY_LEDGER_STATEMENT,
      payload: {},
    },
    {
      onSuccess: ({ data }) => {
        onSuccess?.(data.statementProgressesId);
      },
      onFailure: (error) => {
        notify(`Failure while requesting Tenancy Ledger: ${error}`);
      },
    }
  );

  return {
    send: ({
      tenancyId,
      startDate,
      endDate,
    }: {
      tenancyId: string;
      startDate: string;
      endDate: string;
    }) => {
      void mutate({
        payload: {
          data: {
            tenancyAiloRN: AiloRN.of(
              nsEntities.propertymanagement.tenancy,
              tenancyId
            ),
            startDate,
            endDate,
          },
        },
      });
    },
  };
};
