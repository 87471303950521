import React from "react";
import { TextInput, required, DateInput, CreateProps } from "react-admin";
import { Create, LegalEntityInput, SimpleForm } from "../../common";

const TenantshipCreate = (
  props: CreateProps & {
    record?: { id: string; startDate: string };
  }
): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm redirect={() => props.history?.goBack()}>
        <TextInput label="Tenancy ID" source="tenancyId" disabled />
        <LegalEntityInput
          label="Tenant"
          source="tenantId"
          validate={[required()]}
        />
        <DateInput source="startDate" validate={[required()]} />
        <DateInput source="endDate" />
      </SimpleForm>
    </Create>
  );
};

export { TenantshipCreate };
