import { AiloRN, services } from "@ailo/ailorn";
import { Container } from "@material-ui/core";
import React, { useCallback } from "react";
import { Button, Loading } from "react-admin";
import { StatementType } from "../../../../api/graphql/types.generated";
import { StatementProgress } from "../../../../api/ResourceName";
import { useInfinitelyPaginatedQuery, useMutation } from "../../../../common";
import { DetailCard } from "../../../../common/ui/cards";
import { StatementProgressesList } from "./StatementProgressesList";

export function StatementsCard({
  managementAiloRN,
  subheader,
  listStyle,
}: {
  managementAiloRN: string;
  subheader?: string;
  listStyle?: React.CSSProperties;
}): React.ReactElement {
  const {
    data,
    loaded,
    error,
    hasMore,
    loadMore,
  } = useInfinitelyPaginatedQuery({
    type: "GET_LIST_FOR_INFINITELY_PAGINATED_QUERY",
    resource: StatementProgress,
    payload: {
      filter: {
        sendEmail: true,
        statementSubject: AiloRN.from(managementAiloRN),
        types: [
          StatementType.Management,
          StatementType.TransferSummary,
          StatementType.TaxSummary,
        ],
      },
    },
    pageSize: 5,
  });

  const onRetryGeneratingStatement = useRetryGeneratingStatement();

  if (error) {
    return (
      <Container>There was an error loading statement progresses</Container>
    );
  }

  if (!loaded) {
    return <Loading />;
  }

  return (
    <DetailCard
      title="Statements"
      subheader={subheader}
      contents={
        <>
          <StatementProgressesList
            statementProgresses={data ?? []}
            onRetryGeneratingStatement={onRetryGeneratingStatement}
            style={listStyle}
          />
          {hasMore && (
            <Button
              label="Load more"
              onClick={loadMore}
              variant="contained"
              fullWidth
              style={{ marginTop: 25 }}
            />
          )}
        </>
      }
    />
  );
}

function useRetryGeneratingStatement() {
  const [retryGeneratingStatement] = useMutation({
    type: "retry_generating_statement",
    resource: StatementProgress,
  });

  return useCallback(
    (progressId) => {
      void retryGeneratingStatement({
        payload: {
          progressAiloRN: AiloRN.of(services.Statement.progress, progressId),
        },
      });
    },
    [retryGeneratingStatement]
  );
}
