import Big from "big.js";
import React, { useCallback } from "react";
import { required } from "react-admin";
import { useForm, useFormState } from "react-final-form"; //eslint-disable-line
import { maxMoney, minMoney, MoneyInput } from "../../common";

export function FeeAmountInput(): React.ReactElement {
  const { change } = useForm();
  const { values } = useFormState();

  const onChange = useCallback(
    (event) => {
      if (values.baseAmountType === "OneWeekRent" && values.baseAmount?.cents) {
        change(
          "percentage",
          new Big(+event.target.value || 0)
            .round(2)
            .mul(100)
            .div(values.baseAmount.cents)
            .round(4)
            .toNumber()
        );
      }
    },
    [change, values.baseAmount?.cents, values.baseAmountType]
  );

  return (
    <MoneyInput
      source="amount"
      label="Price (incl. tax) ($)"
      fullWidth
      onChange={onChange}
      validate={[
        required(),
        minMoney({ cents: 0 }),
        maxMoney({ cents: 1300000 }),
      ]}
    />
  );
}
