import { Alert } from "@material-ui/lab";
import React from "react";
import { FunctionField, Show, ShowProps, SimpleShowLayout } from "react-admin";
import { AccountOwner, OperationData } from "../../api";
import { DynamicAiloRNField, MoneyField } from "../../common";
import { AccountOwnerEntriesList } from "./AccountOwnerEntriesList";

type AccountOwnerRecord = OperationData<typeof AccountOwner, "GET_ONE">;

export const AccountOwnerShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props} title={`Account Owner #${props.id}`}>
      <SimpleShowLayout>
        <Alert severity="warning">
          This page is still under development, do not use it if you don't know
          what it is.
        </Alert>
        <DynamicAiloRNField label="Reference" addLabel source="reference" />
        <MoneyField label="Available Balance ($)" source="availableBalance" />
        <MoneyField label="Total Balance ($)" source="totalBalance" />

        <FunctionField<AccountOwnerRecord>
          label="Entries"
          render={(record) => {
            if (!record?.reference) return null;
            return (
              <AccountOwnerEntriesList
                accountOwnerReference={record.reference}
              />
            );
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};
