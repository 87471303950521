import React from "react";
import {
  Datagrid,
  Filter,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import {
  List,
  LegalEntityAvatarField,
  IdField,
  ConsumerTypesField,
} from "../../common";

const Filters = (props: Record<string, any>) => (
  <Filter {...props}>
    <TextInput label="Search" source="searchTerm" alwaysOn autoFocus />
    <SelectInput
      source="domainType"
      alwaysOn
      choices={["Consumer", "Agency"].map((option) => ({
        id: option,
        name: option,
      }))}
      emptyText="All"
    />
  </Filter>
);

const PersonList = (props: any) => {
  return (
    <List
      {...props}
      sort={{
        field: "firstName",
        order: "ASC",
      }}
      bulkActionButtons={false}
      filters={<Filters />}
    >
      <Datagrid rowClick="show">
        <LegalEntityAvatarField label="Name" sortBy="firstName" />
        <IdField label="User Id" source="user.ailoRN" sortable={false} />
        <ConsumerTypesField
          label="Type"
          source="organisation"
          sortable={false}
        />
        <TextField label="Email" source="emailAddress" />
        <TextField label="Phone" source="phoneNo" sortable={false} />
      </Datagrid>
    </List>
  );
};

export { PersonList };
