import { DELETE } from "react-admin";
import { useCallback } from "react";
import { useMutation } from "../../../common/api/useMutation";
import { DeleteCentrepayDirectiveInput } from "../../../api/graphql/types.generated";
import { CentrepayPaymentDirective } from "../../../api/ResourceName";

export function useDeletePaymentDirective(
  legalEntity: string,
  managingEntity: string,
  tenancy: string
): () => void {
  const [deleteCentrepayDirectiveMutation] = useMutation<{
    input: DeleteCentrepayDirectiveInput;
  }>({
    resource: CentrepayPaymentDirective,
    type: DELETE,
  });
  return useCallback(async () => {
    await deleteCentrepayDirectiveMutation({
      payload: {
        input: {
          tenancy,
          legalEntity,
          managingEntity,
        },
      },
    });
  }, [deleteCentrepayDirectiveMutation, tenancy, legalEntity, managingEntity]);
}
