import { formatRecurringDateFrequency } from "@ailo/date";
import { Money } from "@ailo/money";
import { capitalize } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ArchiveIcon from "@material-ui/icons/Archive";
import BuildIcon from "@material-ui/icons/Build";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";
import moment from "moment-timezone";
import React from "react";
import { Button, Error, Link, Loading, useGetOne } from "react-admin";
import {
  LiabilityAdjustment,
  RecurringFee,
} from "../../../../api/ResourceName";
import { ListItem, MutationButton } from "../../../../common";
import { Badge } from "../../../../common/ui/Badge";
import { capitaliseFirstLetter } from "../../../../common/ui/deprecated";

export function RecurringFeeCard({
  propertyId,
  recurringFeeId: feeId,
}: {
  propertyId: string;
  recurringFeeId: string;
}) {
  const { data: fee, loading, error } = useGetOne(RecurringFee, feeId);

  if (loading) {
    return <Loading />;
  }

  if (error || !fee) {
    return <Error error={error} />;
  }

  const amount = Money.from(fee.currentOrNextSchedule.taxInclusiveAmount);
  const overdueAmount = !fee.liability
    ? undefined
    : Money.from(fee.liability.overdueAmount);
  const formattedNextChargeDate =
    !fee.archived && fee.nextOccurrence
      ? `Next charge: ${moment(fee.nextOccurrence.date).format(
          "ddd, D MMM YYYY"
        )}`
      : "Next charge: –";
  const status = (
    <Badge
      type={
        fee.archived || fee.cancelled || fee.currentness === "past"
          ? "default"
          : fee.currentness === "current"
          ? "success"
          : "warning"
      }
    >
      {fee.archived || fee.cancelled
        ? "Cancelled"
        : capitalize(fee.currentness)}
    </Badge>
  );
  return (
    <ListItem
      title={
        <Box display="flex" fontSize={14}>
          <Link to={`/${RecurringFee}/${fee.id}/show`}>{fee.name}</Link>
          {status ? (
            <Box pl={2} ml="auto">
              {status}
            </Box>
          ) : null}
        </Box>
      }
    >
      <Box display="flex" flexDirection="row">
        <Box flex={1} fontSize={14}>
          {fee.endDate
            ? `${fee.startDate} – ${fee.endDate}`
            : `From ${fee.startDate}`}
        </Box>
        <Box fontSize={14} color="black">
          {`${amount.format()}${formatRecurringDateFrequency(fee.frequency)}`}
        </Box>
      </Box>
      <Box fontSize={14}>{capitaliseFirstLetter(fee.frequency)}</Box>
      <Box fontSize={14}>{formattedNextChargeDate}</Box>
      <Box fontSize={14}>
        Current overdue amount:{" "}
        {overdueAmount ? overdueAmount.format() : "Refresh to see it"}
      </Box>
      <Box mt={1}>
        {!fee.cancelled && fee.nextOccurrence && (
          <Button
            component={Link}
            to={{
              pathname: `/${RecurringFee}/${feeId}`,
              state: {
                record: {
                  propertyId,
                },
              },
            }}
            label="Edit"
          >
            <EditIcon />
          </Button>
        )}
        {!fee.cancelled && fee.nextOccurrence && (
          <MutationButton
            resource={RecurringFee}
            record={fee}
            type="cancel"
            confirmationAlert="Are you sure you want to cancel this fee? This fee will no longer be charged. (However, past occurrences will stay as they are.)"
            icon={<ArchiveIcon />}
          />
        )}
        {!fee.archived &&
          fee.liability?.id &&
          (fee.nextOccurrence || overdueAmount?.isPositive) && (
            <Button
              component={Link}
              to={{
                pathname: `/${LiabilityAdjustment}/create`,
                state: {
                  record: {
                    liabilityId: fee.liability?.id,
                    effectiveAt: new Date(),
                  },
                },
              }}
              label="Adjustment"
            >
              <BuildIcon />
            </Button>
          )}
      </Box>
      {fee.cancelled && overdueAmount?.isPositive && (
        <Alert severity="info">
          This fee has {overdueAmount.format()} outstanding. This will be paid
          from next rent received.
        </Alert>
      )}
    </ListItem>
  );
}
