import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FilterProps,
  FunctionField,
  ListProps,
} from "react-admin";
import { MigrationAgency, OperationData } from "../../api";
import { Badge, LegalEntityField, List, ReferenceInput } from "../../common";
import { MigrationTaskStatus } from "../../api/graphql/types.generated";

type MigrationAgencyRecord = NonNullable<
  OperationData<typeof MigrationAgency, "GET_LIST">
>[number];

const SearchFilter = (
  props: JSX.IntrinsicAttributes &
    Omit<FilterProps, "children"> & { children?: React.ReactNode }
) => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      source="organisationId"
      reference="Customers"
      filterToQuery={(searchTerm: string) => ({
        searchTerm,
      })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export function MigrationAgencyList(props: ListProps): React.ReactElement {
  return (
    <List
      {...props}
      perPage={50}
      title="Migration Agencies"
      filters={<SearchFilter />}
      sort={{ field: "finishedAt", order: "DESC" }}
    >
      <Datagrid isRowSelectable={() => false}>
        <LegalEntityField label="Agency" source="company" sortable={false} />
        <FunctionField<NonNullable<MigrationAgencyRecord>>
          label="Status"
          render={(record) => {
            if (!record) return null;
            if (record.disabled) {
              return <Badge type="critical" text="Disabled" />;
            }
            return <Badge type="success" text="Active" />;
          }}
        />
        <FunctionField<NonNullable<MigrationAgencyRecord>>
          label="Data Status"
          render={(record) => {
            if (!record) return null;
            if (!record.status) return null;
            if (record.status === MigrationTaskStatus.Failed) {
              return <Badge type="critical" text="Failed" />;
            }
            if (record.status === MigrationTaskStatus.Transforming) {
              return <Badge type="default" text="Transforming" />;
            }
            if (record.status === MigrationTaskStatus.Ingesting) {
              return <Badge type="default" text="Ingesting" />;
            }
            return <Badge type="success" text="Refreshed" />;
          }}
        />
        <DateField label="Refreshed At" source="finishedAt" showTime />
      </Datagrid>
    </List>
  );
}
