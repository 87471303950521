import { formatNames } from "@ailo/domain-helpers";
import { formatOrdinalInteger } from "./formatOrdinalInteger";

export function formatMonthlyFrequency(
  anniversaryDaysOfMonth: number[],
  includesLastDayOfMonth: boolean
): string {
  const dayStrings = [...anniversaryDaysOfMonth]
    .sort((a, b) => a - b)
    .map(formatOrdinalInteger);
  if (includesLastDayOfMonth) dayStrings.push("last day");
  const commaSeparatedDays = formatNames(dayStrings, { longForm: true });
  return `On the ${commaSeparatedDays} of every month`;
}
