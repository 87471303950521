import React, { useEffect, useState } from "react";
import { useQuery } from "react-admin";
import { GET_STATEMENT_URL } from "../../../../api/resources/Management/managementsClient";
import { Management } from "../../../../api";

export function DownloadStatementFileWithInterval({
  statementProgressId,
  interval,
  onSuccess,
}: {
  statementProgressId: string;
  interval: number;
  onSuccess: () => void;
}): React.ReactElement {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  // eslint-disable-next-line
  const timerKey = timer ? ((timer as any) as number) : 0;

  const onRetry = () => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setTimer(newTimer);
    }, interval);
  };

  return (
    <>
      <FetchAndDownloadStatementFile
        key={timerKey}
        statementProgressId={statementProgressId}
        onRetry={onRetry}
        onSuccess={onSuccess}
      />
    </>
  );
}

function FetchAndDownloadStatementFile({
  statementProgressId,
  onRetry,
  onSuccess,
}: {
  statementProgressId: string;
  onRetry: () => void;
  onSuccess: () => void;
}): React.ReactElement {
  const { data, loaded } = useQuery(
    {
      type: GET_STATEMENT_URL,
      resource: Management,
      payload: {
        statementProgressId,
      },
    },
    { enabled: !!statementProgressId }
  );

  useEffect(() => {
    if (loaded && data) {
      const url = data?.statement?.file?.url;

      if (url) {
        window.open(url, "_bank");
        onSuccess();
      } else {
        onRetry();
      }
    }
  }, [data, loaded, onRetry, onSuccess]);

  return <></>;
}
