import {
  DeleteButton,
  SaveButton, // eslint-disable-next-line no-restricted-imports
  SimpleForm as RASimpleForm,
  Toolbar,
  Button,
  Link,
  SimpleFormProps as RASimpleFormProps,
  Record,
  RedirectionSideEffect,
  DeleteButtonProps,
} from "react-admin";
import React from "react";
import { useHistory } from "react-router-dom";

export type SimpleFormProps<TRecord extends Record> = Omit<
  RASimpleFormProps,
  "toolbar"
> & {
  record?: TRecord;
  toolbarOptions?: {
    saveLabel?: string;
    /**
     * TODO automatically infer the value, depending on whether  delete is possible for such resource.
     * Do it once we update react-admin to >= 3.10 , by using `useResourceDefinition` .
     */
    deleteButton?: boolean;
  };
  deleteButtonProps?: DeleteButtonProps;
  /**
   * A function, a path, or `false` if don't want the cancel button.
   */
  onCancel?: (() => void) | string | false;
  children?: React.ReactNode;
};

export function SimpleForm<TRecord extends Record = Record>({
  toolbarOptions: { saveLabel = undefined, deleteButton = true } = {},
  onCancel: onCancelProp,
  children,
  deleteButtonProps,
  ...props
}: SimpleFormProps<TRecord>) {
  const create = !props.record!.id;
  const history = useHistory();
  const canGoBack = history.length > 0;
  const onCancel =
    onCancelProp !== undefined
      ? onCancelProp
      : canGoBack
      ? history.goBack
      : undefined;
  const onDelete =
    deleteButtonProps?.redirect !== undefined
      ? deleteButtonProps.redirect
      : canGoBack
      ? history.goBack
      : undefined;
  return (
    <RASimpleForm
      // eslint-disable-next-line react/no-children-prop
      children={children}
      actions={null}
      toolbar={
        <Toolbar mutationMode={props.mutationMode}>
          <SaveButton label={saveLabel || (create ? "Create" : "Save")} />

          {!create && deleteButton && (
            <DeleteButton
              style={{
                marginLeft: 8,
              }}
              mutationMode={props.mutationMode}
              {...{
                ...deleteButtonProps,
                redirect: onDelete as RedirectionSideEffect,
              }}
            />
          )}

          {onCancel && (
            <Button
              {...(onCancel && typeof onCancel === "function"
                ? {
                    onClick: onCancel,
                  }
                : {
                    component: Link,
                    to: onCancel,
                  })}
              label="Cancel"
              style={{
                marginLeft: 8,
              }}
            />
          )}
        </Toolbar>
      }
      {...props}
    />
  );
}
