import dataUriToBuffer, { MimeBuffer } from "data-uri-to-buffer";

export function convertFileToBuffer(file: {
  rawFile: File;
  src: string;
  title: string;
}): Promise<MimeBuffer> {
  // eslint-disable-next-line no-undef
  return new Promise<MimeBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.addEventListener("load", () =>
      resolve(dataUriToBuffer(reader.result as string))
    );
    reader.addEventListener("error", reject);
  });
}
