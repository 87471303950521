/* eslint-disable */
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { AiloRN, services } from "@ailo/ailorn/build";
import csv from "csvtojson";
import { envVars } from "../../../../envVars";

const isValidAccountName = (accountName: string): boolean => {
  return /^[\s\w!#$&'()*+,./:;=?@[\]^-]*$/.test(accountName);
};

const AUSTRALIAN_STATES = ["NSW", "VIC", "SA", "QLD", "ACT", "WA", "NT", "TAS"];

enum PaymentMethod {
  BPay = "BPay",
  BankAccount = "EFT",
}

enum CSVHeader {
  ABN = "ABN",
  RegisteredEntityName = "Contact Reference",
  Name = "Display Name",
  PaymentMethod = "Payment Method",
  PaymentDetails = "Account Number / Biller Code",
  Email = "Email Address",
  StreetName = "Street Name",
  UnitStreetNumber = "Unit / Street Number",
  Suburb = "Suburb",
  Postcode = "Postcode",
  State = "State",
  Country = "Country",
}

type CSVSupplier = {
  [CSVHeader.ABN]?: string;
  [CSVHeader.RegisteredEntityName]: string;
  [CSVHeader.Name]: string;
  [CSVHeader.PaymentMethod]: PaymentMethod.BPay | PaymentMethod.BankAccount;
  [CSVHeader.PaymentDetails]: string;
  [CSVHeader.Email]?: string;
  [CSVHeader.StreetName]: string;
  [CSVHeader.UnitStreetNumber]: string;
  [CSVHeader.Suburb]: string;
  [CSVHeader.Postcode]: string;
  [CSVHeader.State]: string;
  [CSVHeader.Country]: string;
};

type Supplier = {
  abn?: string;
  registeredEntityName: string;
  name: string;
  paymentMethod: BPayInput | BankAccountInput;
  email?: string;
  streetName: string;
  unitStreetNumber?: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
};

type BPayInput = { type: string; billerCode: string };

type BankAccountInput = {
  type: string;
  accountName?: string;
  accountNumber: string;
  bsb: string;
};

type PaymentMethodInput = BPayInput | BankAccountInput;

export const addSupplierWithPaymentMethods = async (
  csvString: string,
  organisationId: string
) => {
  let isValid = true;

  await csv()
    .fromString(csvString)
    .subscribe(
      async (supplier: CSVSupplier) => {
        try {
          await validateCSVSupplier(supplier);
        } catch (error) {
          console.error(error);
          isValid = false;
        }
      },
      (err) => console.error(err)
    );

  if (isValid) {
    console.log(`The CSV is valid, processing the suppliers.`);
    return processSuppliers(csvString, organisationId);
  } else {
    throw new Error(
      `The CSV file has invalid data, please fix the errors above.`
    );
  }
};

const validateCSVSupplier = async (supplier: CSVSupplier) => {
  const SUPPLIER_REGISTERED_ENTITY_NAME =
    supplier[CSVHeader.RegisteredEntityName];
  const SUPPLIER_NAME = supplier[CSVHeader.Name];

  if (!SUPPLIER_REGISTERED_ENTITY_NAME) {
    throw new Error(
      `Registered Entity Name missing for "${JSON.stringify(supplier)}"`
    );
  }

  if (!SUPPLIER_NAME) {
    throw new Error(`Display name missing for "${JSON.stringify(supplier)}"`);
  }

  if (!isValidAccountName(SUPPLIER_NAME)) {
    throw new Error(
      `"${SUPPLIER_NAME}" must only contain letters, numbers, spaces and the following characters: +@!^$&'()*-:;=?.#_,[]/"`
    );
  }

  if (!isValidAccountName(SUPPLIER_REGISTERED_ENTITY_NAME)) {
    throw new Error(
      `"${SUPPLIER_REGISTERED_ENTITY_NAME}" must only contain letters, numbers, spaces and the following characters: +@!^$&'()*-:;=?.#_,[]/"`
    );
  }

  if (!supplier[CSVHeader.Postcode].match(/^\d{4}$/g)) {
    throw new Error(
      `Postcode is invalid for "${SUPPLIER_NAME}, it must be 4 digits long"`
    );
  }

  if (!AUSTRALIAN_STATES.includes(supplier[CSVHeader.State])) {
    throw new Error(
      `State is invalid for "${SUPPLIER_NAME}, it must be one of: ${AUSTRALIAN_STATES}"`
    );
  }

  if (!supplier[CSVHeader.PaymentMethod]) {
    throw new Error(`Payment method missing for "${SUPPLIER_NAME}"`);
  }

  if (!supplier[CSVHeader.PaymentDetails]) {
    throw new Error(`Payment details missing for "${SUPPLIER_NAME}"`);
  }

  if (supplier[CSVHeader.PaymentMethod] === PaymentMethod.BPay) {
    const paymentDetailsDigits = supplier[CSVHeader.PaymentDetails].match(
      /^\d{2,19}$/g
    );

    if (!paymentDetailsDigits) {
      throw new Error(
        `Payment details are incorrect for "${SUPPLIER_NAME}", biller code should only consist of 2-19 digits: "${
          supplier[CSVHeader.PaymentDetails]
        }"`
      );
    }

    // Customer numbers must be 2-20 numerical digits long and not contain any special characters
    const CRN = paymentDetailsDigits.join("");
    if (CRN.length < 2 || CRN.length > 20) {
      throw new Error(
        `CRN is invalid for "${SUPPLIER_NAME}", it must be 2-20 numerical digits long.`
      );
    }
  }

  if (supplier[CSVHeader.PaymentMethod] === PaymentMethod.BankAccount) {
    if (supplier.ABN) {
      const ABN = supplier.ABN?.match(/^\d{11}$/g);
      if (!ABN) {
        throw new Error(
          `ABN is invalid for "${SUPPLIER_NAME}", it does not have 11 digits`
        );
      }
    }

    const paymentDetailsDigits = supplier[CSVHeader.PaymentDetails].match(
      /^\d{6}-\d{6,9}$/g
    );

    if (!paymentDetailsDigits) {
      throw new Error(
        `Payment details are incorrect for "${SUPPLIER_NAME}". It should be BSB-AccountNumber where BSB is 6 digits and AccountNumber is 6-9 digits`
      );
    }

    const normalizedPaymentDetails = paymentDetailsDigits.join("");

    if (
      normalizedPaymentDetails.length < 12 ||
      normalizedPaymentDetails.length > 16
    ) {
      throw new Error(
        `Bank payment details (i.e. ${
          supplier[CSVHeader.PaymentDetails]
        }) are incorrect for "${SUPPLIER_NAME}",
          we require 6 digits for the BSB and 6 to 9 digits for the account number`
      );
    }
  }
};

const processSuppliers = async (csvString: string, organisationId: string) => {
  const client = buildClient();

  const suppliers = await csv().fromString(csvString);
  for (let index = 0; index < suppliers.length; index++) {
    const csvSupplier = suppliers[index];
    const supplier: Supplier = {
      name: csvSupplier[CSVHeader.Name],
      registeredEntityName: csvSupplier[CSVHeader.RegisteredEntityName],
      abn: csvSupplier.ABN,
      paymentMethod: getPaymentData(
        csvSupplier[CSVHeader.Name],
        csvSupplier[CSVHeader.PaymentMethod],
        csvSupplier[CSVHeader.PaymentDetails]
      ),
      email: csvSupplier[CSVHeader.Email],
      streetName: csvSupplier[CSVHeader.StreetName],
      unitStreetNumber: csvSupplier[CSVHeader.UnitStreetNumber],
      suburb: csvSupplier[CSVHeader.Suburb],
      postcode: csvSupplier[CSVHeader.Postcode],
      state: csvSupplier[CSVHeader.State],
      country: csvSupplier[CSVHeader.Country],
    };

    await createSupplierWithPaymentMethod({
      organisationReference: AiloRN.of(
        services.AuthZ.organisation,
        organisationId
      ).toString(),
      supplier,
      client,
    }).then(() => console.log(`${supplier.name} processed`));
  }
};

const getPaymentData = (
  contactReference: string,
  paymentMethod: PaymentMethod.BPay | PaymentMethod.BankAccount,
  paymentDetails: string
): BPayInput | BankAccountInput => {
  if (paymentMethod === PaymentMethod.BPay) {
    return {
      type: PaymentMethod.BPay,
      billerCode: paymentDetails.match(/\d+/g)!.join(""),
    };
  }

  if (paymentMethod === PaymentMethod.BankAccount) {
    // This assumes that BSB comes before the account number
    const normalizedPaymentDetails = /^(\d{6})-(\d{6,9})$/.exec(paymentDetails);
    if (!normalizedPaymentDetails || normalizedPaymentDetails.length < 3) {
      throw new Error(
        `Could not parse BSB and Account Number from ${paymentDetails}`
      );
    }
    return {
      type: PaymentMethod.BankAccount,
      accountName: contactReference,
      bsb: normalizedPaymentDetails[1],
      accountNumber: normalizedPaymentDetails[2],
    };
  }

  throw new Error(
    `Payment method type "${paymentMethod}" for "${contactReference}" not implemented!`
  );
};

async function createSupplierWithPaymentMethod({
  organisationReference,
  supplier,
  client,
}: any) {
  const supplierResult = await createSupplier(
    {
      organisationReference,
      ...supplier,
    },
    client
  );
  const supplierReference = AiloRN.of(
    services.Bill.supplier,
    supplierResult?.data?.createSupplier.id
  );

  await createPaymentMethod(
    supplier.paymentMethod,
    supplierReference,
    organisationReference,
    client
  );
}

function buildClient(): ApolloClient<any> {
  const cache = new InMemoryCache();
  const token = localStorage.getItem("access_token");

  const link = new HttpLink({
    uri: `${envVars.adminGateway.domain}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return new ApolloClient({
    cache,
    link,
  });
}

async function createSupplier(
  {
    organisationReference,
    name,
    registeredEntityName,
    abn,
    email,
    streetName,
    unitStreetNumber,
    suburb,
    state,
    postcode,
    country,
  }: any,
  client: ApolloClient<any>
) {
  const mutation = gql`
    mutation createSupplierUsingBulkScript($input: CreateSupplierInput!) {
      createSupplier(input: $input) {
        id
      }
    }
  `;
  const result = await client.mutate({
    mutation,
    variables: {
      input: {
        organisationAiloRN: organisationReference,
        name,
        registeredEntityName,
        abn,
        emailAddress: email,
        address: {
          streetName,
          unitStreetNumber,
          suburb,
          state,
          postcode,
          country,
        },
      },
    },
  });
  return result;
}

async function createPaymentMethod(
  paymentMethod: PaymentMethodInput,
  supplierReference: AiloRN,
  organisationReference: AiloRN,
  client: ApolloClient<any>
) {
  switch (paymentMethod.type) {
    case PaymentMethod.BPay:
      return addBPay(
        { ...paymentMethod, supplierReference, organisationReference },
        client
      );
    case PaymentMethod.BankAccount:
      return addBankAccount(
        { ...paymentMethod, supplierReference, organisationReference },
        client
      );
  }
}

async function addBPay(
  { billerCode, supplierReference, organisationReference }: any,
  client: ApolloClient<any>
) {
  const mutation = gql`
    mutation createBpayPaymentMethod($input: CreatePaymentMethodInput!) {
      createPaymentMethod(input: $input) {
        id
      }
    }
  `;
  const result = await client.mutate({
    mutation,
    variables: {
      input: {
        bpayInput: {
          billerCode,
        },
        walletOwnerAiloRN: supplierReference,
        createWalletOwner: true,
        deleteExistingPaymentMethods: true,
        managingOrganisationAiloRN: organisationReference.toString(),
      },
    },
  });
  return result;
}

async function addBankAccount(
  {
    accountName,
    accountNumber,
    bsb,
    supplierReference,
    organisationReference,
  }: any,
  client: ApolloClient<any>
) {
  const mutation = gql`
    mutation createBankAccountPaymentMethod($input: CreatePaymentMethodInput!) {
      createPaymentMethod(input: $input) {
        id
      }
    }
  `;
  const result = await client.mutate({
    mutation,
    variables: {
      input: {
        bankAccountInput: {
          accountName,
          accountNumber,
          bsb,
        },
        walletOwnerAiloRN: supplierReference,
        createWalletOwner: true,
        deleteExistingPaymentMethods: true,
        managingOrganisationAiloRN: organisationReference.toString(),
      },
    },
  });
  return result;
}
