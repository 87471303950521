// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";
import { detailedBlueprintFragment } from "../../../../../api/resources/ManagementFeeBlueprint";
import {
  FindManagementFeeBlueprintsQuery,
  FindManagementFeeBlueprintsQueryVariables,
} from "./find.generated";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function find(
  managementId: string,
  feeBlueprintId: string,
  client: ApolloClient<unknown>
) {
  const query = gql`
    query findManagementFeeBlueprints(
      $conditions: ManagementFeeBlueprintsQueryConditions
      $cursor: PageCursorWithoutSort
    ) {
      blueprints: managementFeeBlueprints(
        conditions: $conditions
        cursor: $cursor
      ) {
        items {
          ...DetailedManagementFeeBlueprint
        }
        pageInfo {
          total
          nextCursor
          hasMore
        }
      }
    }
    ${detailedBlueprintFragment}
  `;

  const result = await client.query<
    FindManagementFeeBlueprintsQuery,
    FindManagementFeeBlueprintsQueryVariables
  >({
    query,
    variables: {
      conditions: { managementId, feeBlueprintId, archived: false },
    },
  });

  const { data, errors } = result;

  const managementFeeBlueprint = data.blueprints?.items.pop();

  if (errors) {
    throw new Error(
      `Failed to find management fee blueprint for management: ${managementId}`
    );
  }

  if ((data.blueprints?.items.length || 0) > 1) {
    throw new Error(
      `Expected to find 0 or 1 management fee blueprints for management: ${managementId} but found ${data.blueprints?.items.length} blueprints`
    );
  }

  return managementFeeBlueprint;
}
