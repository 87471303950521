import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { Property } from "csstype";
import { Colors, opacify } from "../colors";

const borderContainerRoot = {
  borderColor: opacify(Colors.SPACE_BLACK, 0.1),
  borderBottomWidth: 1,
  borderBottomStyle: "solid" as Property.BorderBottomStyle,
};

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  header: {
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  subHeader: {
    color: Colors.TEXT.DARK.SECONDARY,
    fontSize: "0.75rem",
  },
  borderContainerRoot,
}));

function ListItemView({
  header,
  subHeader,
  leftComponent,
  rightComponent,
  onClick,
  onTitleClick,
  style,
  children,
}: any) {
  const classes = useStyles();
  return (
    <ListItem
      style={style}
      onClick={onClick}
      {...({
        hoveredstyle: {
          cursor: onClick ? "pointer" : "default",
        },
      } as any)}
    >
      <Grid container alignItems="stretch" wrap="nowrap">
        <Box py="16px">{leftComponent}</Box>
        <Grid container direction="column" item style={borderContainerRoot}>
          <Box
            display="flex"
            flexGrow={1}
            alignItems="flex-start"
            py="16px"
            onClick={onTitleClick}
            style={{ cursor: onClick || onTitleClick ? "pointer" : undefined }}
          >
            <Box flexGrow={1}>
              <ListItemText
                classes={{
                  root: classes.root,
                }}
                primary={header}
                primaryTypographyProps={{
                  component: "div",
                  className: classes.header,
                }}
                secondary={subHeader}
                secondaryTypographyProps={{
                  component: "div",
                  className: classes.subHeader,
                }}
              />
            </Box>
            {rightComponent}
          </Box>
          {children}
        </Grid>
      </Grid>
    </ListItem>
  );
}

function Loading() {
  return (
    <ListItemView
      header={<Skeleton variant="text" width={160} />}
      subHeader={<Skeleton variant="text" width={80} />}
      leftComponent={
        <Skeleton
          variant="rect"
          height={40}
          width={40}
          style={{
            marginRight: 16,
            borderRadius: 8,
          }}
        />
      }
      rightComponent={
        <Skeleton
          variant="rect"
          height={40}
          width={40}
          style={{
            borderRadius: 8,
          }}
        />
      }
    />
  );
}

ListItemView.Loading = Loading;
export { ListItemView };
