import React, { useState } from "react";
import { AiloBankCreditDialog } from "./AiloBankCreditDialog";
import { TransferButton } from "./TransferButton";

export function AiloBankCreditButton({
  walletId,
}: {
  walletId: string;
}): React.ReactElement {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <TransferButton onClick={onClick}>
        Credit from Ailo Bank Wallet
      </TransferButton>
      <AiloBankCreditDialog open={open} onClose={onClose} walletId={walletId} />
    </div>
  );
}
