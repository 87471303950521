import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { Box, Grid, MenuItem, Select } from "@material-ui/core";
import { parse, stringify } from "query-string";
import React, { useCallback, useMemo } from "react";
import { Labeled } from "react-admin";
import { useHistory, useLocation } from "react-router-dom";
import { ManagementFolioShowRecord } from "../../../features/ManagementFolio";
import { WalletOwnerTransactionList } from "../../../features/WalletEntry";

export function TransactionsTab({
  managementFolio,
}: {
  managementFolio: ManagementFolioShowRecord;
}): React.ReactElement {
  const history = useHistory();
  const location = useLocation();

  const managements = managementFolio.managements.items;

  const { tab, management: rawManagementIdSearchParam } = useMemo(
    () => parse(location.search),
    [location.search]
  );

  const managementIdSearchParam =
    typeof rawManagementIdSearchParam === "string"
      ? rawManagementIdSearchParam
      : undefined;

  const managementId =
    managements.find((management) => management.id === managementIdSearchParam)
      ?.id ?? undefined;

  const handleManagementIdChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const newManagementId =
        typeof event.target.value === "string" ? event.target.value : undefined;
      history.push({
        search: `?${stringify({
          tab,
          ...(newManagementId && {
            management: newManagementId,
          }),
        })}`,
      });
    },
    [history, tab]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {managements.length > 1 && (
          <Labeled label="Management">
            <Select
              fullWidth
              value={managementId}
              onChange={handleManagementIdChange}
              renderValue={(value) => {
                if (!value) return undefined;
                const selectedManagement = managements.find(
                  (management) => management.id === value
                );
                return selectedManagement
                  ? formatAddress(selectedManagement.property.address)
                  : undefined;
              }}
              style={{ height: 40 }}
            >
              <MenuItem key="default" value={undefined}>
                <Box style={{ height: 24 }} />
              </MenuItem>
              {managements.map((management) => (
                <MenuItem key={management.id} value={management.id}>
                  {formatAddress(management.property.address)}
                </MenuItem>
              ))}
            </Select>
          </Labeled>
        )}

        <WalletOwnerTransactionList
          ownerAiloRN={managementFolio.ailorn}
          relatingToManagementAilorn={
            managementId
              ? AiloRN.of(
                  "propertymanagement:management",
                  managementId
                ).toString()
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
}
