import React from "react";
import { TenancyDepositStatus } from "../../../../api/graphql/types.generated";
import { Badge } from "../../../../common";

export const DepositStatusBadge = ({
  status,
}: {
  status: TenancyDepositStatus;
}): React.ReactElement => {
  const displayStatus = mapDepositStatus(status);

  return ({
    Transferred: <Badge.Success>{displayStatus}</Badge.Success>,
    Cleared: <Badge.Success>{displayStatus}</Badge.Success>,
    Unpaid: <Badge.Warning>{displayStatus}</Badge.Warning>,
    Pending: <Badge.Warning>{displayStatus}</Badge.Warning>,
    Failed: <Badge.Critical>{displayStatus}</Badge.Critical>,
    Cancelled: <Badge.Default>{displayStatus}</Badge.Default>,
  } as never)[displayStatus];
};

enum DepositStatus {
  Unpaid = "Unpaid",
  Pending = "Pending",
  Failed = "Failed",
  Cleared = "Cleared",
  Transferred = "Transferred",
  Cancelled = "Cancelled",
}

function mapDepositStatus(status: string): DepositStatus {
  switch (status) {
    case TenancyDepositStatus.Due:
    case TenancyDepositStatus.New:
      return DepositStatus.Unpaid;
    case TenancyDepositStatus.Pending:
      return DepositStatus.Pending;
    case TenancyDepositStatus.Failed:
      return DepositStatus.Failed;
    case TenancyDepositStatus.Paid:
      return DepositStatus.Cleared;
    case TenancyDepositStatus.Released:
      return DepositStatus.Transferred;
    case TenancyDepositStatus.Void:
      return DepositStatus.Cancelled;
    default:
      return DepositStatus.Unpaid;
  }
}
