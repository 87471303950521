import {
  ClientOperationData,
  ClientOperationParams,
  ClientOperationType,
} from "./common";
import {
  AccountOwner,
  Bill,
  BillTaxCategory,
  Company,
  Fee,
  FeeBlueprint,
  FeeEvent,
  LedgerEvent,
  LegalEntity,
  Liability,
  Management,
  ManagementAgreement,
  ManagementFeeBlueprint,
  ManagementFolio,
  MigratingManagement,
  MigrationAgency,
  PaymentMethod,
  Person,
  Property,
  RecurringFee,
  RecurringOwing,
  StatementProgress,
  Team,
  Tenancy,
  TenancyAgreement,
  TrustTransaction,
  Wallet,
  WalletEntry,
} from "./ResourceName";
import { BillClientDefinition } from "./resources/Bill/billClient";
import { billTaxCategoryDefinition } from "./resources/Bill/billTaxCategoryClient";
import { CompanyClientDefinition } from "./resources/Company/companiesClient";
import { FeeClientDefinition } from "./resources/Fee/FeeClient";
import { FeeBlueprintClientDefinition } from "./resources/FeeBlueprint";
import { AccountOwnerClientDefinition } from "./resources/AccountOwner";
import { LedgerEventClientDefinition } from "./resources/LedgerEvent";
import { LegalEntitiesClientDefinition } from "./resources/LegalEntity/legalEntitiesClient";
import { LiabilityClientDefinition } from "./resources/Liability/liabilitiesClient";
import { ManagementsClientDefinition } from "./resources/Management/managementsClient";
import { ManagementFeeBlueprintClientDefinition } from "./resources/ManagementFeeBlueprint";
import { MigratingManagementClientDefinition } from "./resources/MigratingManagement";
import { MigrationAgencyClientDefinition } from "./resources/MigrationAgency";
import { paymentMethodsDefinition } from "./resources/PaymentMethod/paymentMethodsClient";
import { PersonClientDefinition } from "./resources/Person/PersonClient";
import { PropertyClientDefinition } from "./resources/Property/propertiesClient";
import { RecurringFeeClientDefinition } from "./resources/RecurringFee";
import { RecurringOwingClientDefinition } from "./resources/RecurringOwing";
import { StatementProgressClientDefinition } from "./resources/StatementProgress/statementProgressClient";
import { TeamClientDefinition } from "./resources/Team/teamsClient";
import { TenancyAgreementClientDefinition } from "./resources/TenancyAgreement/tenancyAgreementClient";
import { WalletEntryClientDefinition } from "./resources/WalletEntry/walletEntryClient";
import { ManagementFolioClientDefinition } from "./resources/ManagementFolio/managementFolioClient";
import { WalletClientDefinition } from "./resources/Wallet";
import { TenanciesClientDefinition } from "./resources/Tenancy/tenanciesClient";
import { TrustTransactionsClientDefinition } from "./resources/TrustTransaction/transactionsClient";
import { FeeEventClientDefinition } from "./resources/FeeBlueprint/feeEventClient";
import { ManagementAgreementClientDefinition } from "./resources/ManagementAgreement/managementAgreementClient";

export const DataProviderDefinition = {
  [Bill]: BillClientDefinition,
  [BillTaxCategory]: billTaxCategoryDefinition,
  [Management]: ManagementsClientDefinition,
  [Company]: CompanyClientDefinition,
  [Fee]: FeeClientDefinition,
  [FeeBlueprint]: FeeBlueprintClientDefinition,
  [FeeEvent]: FeeEventClientDefinition,
  [AccountOwner]: AccountOwnerClientDefinition,
  [ManagementAgreement]: ManagementAgreementClientDefinition,
  [ManagementFeeBlueprint]: ManagementFeeBlueprintClientDefinition,
  [ManagementFolio]: ManagementFolioClientDefinition,
  [LedgerEvent]: LedgerEventClientDefinition,
  [LegalEntity]: LegalEntitiesClientDefinition,
  [Liability]: LiabilityClientDefinition,
  [MigratingManagement]: MigratingManagementClientDefinition,
  [MigrationAgency]: MigrationAgencyClientDefinition,
  [PaymentMethod]: paymentMethodsDefinition,
  [Person]: PersonClientDefinition,
  [Property]: PropertyClientDefinition,
  [RecurringFee]: RecurringFeeClientDefinition,
  [RecurringOwing]: RecurringOwingClientDefinition,
  [Team]: TeamClientDefinition,
  [Tenancy]: TenanciesClientDefinition,
  [WalletEntry]: WalletEntryClientDefinition,
  [StatementProgress]: StatementProgressClientDefinition,
  [TenancyAgreement]: TenancyAgreementClientDefinition,
  [Wallet]: WalletClientDefinition,
  [TrustTransaction]: TrustTransactionsClientDefinition,
};

export type TypedResourceName = keyof typeof DataProviderDefinition;

type ClientDefinition<
  TResourceName extends TypedResourceName
> = typeof DataProviderDefinition[TResourceName];

export type OperationType<
  TResourceName extends TypedResourceName
> = ClientOperationType<ClientDefinition<TResourceName>>;

export type OperationParams<
  TResourceName extends TypedResourceName,
  TOperationType extends OperationType<TResourceName>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequiredParams = any
> = ClientOperationParams<
  ClientDefinition<TResourceName>,
  TOperationType,
  TRequiredParams
>;

export type OperationData<
  TResourceName extends TypedResourceName,
  TOperationType extends OperationType<TResourceName>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequiredData = any
> = ClientOperationData<
  ClientDefinition<TResourceName>,
  TOperationType,
  TRequiredData
>;
