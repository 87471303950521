import React from "react";
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  TextInput,
} from "react-admin";
import { List } from "../../common";
import { Liability } from "../../api/ResourceName";
import { IdField, LinkField, MoneyField } from "../../common";

const Filters = (props: Record<string, any>) => (
  <Filter {...props}>
    <TextInput source="payerLegalEntityId" alwaysOn />
    <BooleanInput source="enabled" alwaysOn />
  </Filter>
);

export function LiabilityPaymentPlanList(props: any) {
  return (
    <List
      {...props}
      filters={<Filters />}
      filterDefaultValues={{
        enabled: true,
      }}
      bulkActionButtons={false}
      pagination={false}
    >
      <Datagrid rowClick="show">
        <IdField />
        <LinkField
          label="Liability"
          source="liabilityId"
          sourceResource={Liability}
        />
        <LinkField label="Owner" source="ownerAiloRN" />
        <DateField label="Created At" source="createdAt" showTime />
        <MoneyField
          label="Amount"
          source="details.amount"
          frequencySource="details.frequency"
        />
        <LinkField source="createdBy" />
      </Datagrid>
    </List>
  );
}
