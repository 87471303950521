import React from "react";
import { WalletCard } from "../../../../../features/Wallet/WalletCard";
import { TransferFundsButton } from "../../../../../features/Wallet/TransferFundsButton";

interface LegalEntityWalletCardsProps {
  legalEntityAiloRN: string;
  wallets: any;
  entityType?: "Person" | "Company";
  isAgency: boolean;
}

export const LegalEntityWalletCards = ({
  legalEntityAiloRN,
  wallets,
  entityType,
  isAgency,
}: LegalEntityWalletCardsProps) => {
  if (!wallets || wallets.length === 0) {
    return null;
  }

  const action = (
    <TransferFundsButton legalEntityId={legalEntityAiloRN} wallets={wallets} />
  );
  return (
    <>
      {wallets.map((wallet: any) => (
        <WalletCard
          key={wallet.id}
          title={
            entityType === "Company"
              ? "Company Wallet"
              : entityType === "Person"
              ? "Personal Wallet"
              : "Wallet"
          }
          wallet={wallet}
          walletOwnerAiloRN={legalEntityAiloRN}
          action={action}
          showTransactions={false}
          isAgency={isAgency}
        />
      ))}
    </>
  );
};
