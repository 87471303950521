import React, { ReactElement, useState } from "react";
import { Button, ButtonProps, TextInput } from "react-admin";
import { Maybe, Person } from "../../../../../api";
import { SimpleFormDialog, useMutation } from "../../../../../common";

export function CreatePersonUserButton({
  person,
  ...props
}: Omit<ButtonProps, "onClick"> & {
  person: {
    id: string;
    emailAddress?: Maybe<string>;
  };
}): ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mutate] = useMutation({
    resource: Person,
    type: "create_user",
  });

  return (
    <>
      <Button
        label="Create User"
        onClick={() => setDialogOpen(true)}
        {...props}
      />
      {dialogOpen && (
        <SimpleFormDialog<{ id: string; email?: string }>
          title="Create User"
          initialValues={{
            id: person.id,
            email: person.emailAddress,
          }}
          save={(data) => mutate({ payload: data })}
          onClose={() => setDialogOpen(false)}
        >
          <TextInput source="id" disabled label="Person ID" />
          <TextInput type="email" source="email" />
        </SimpleFormDialog>
      )}
    </>
  );
}
