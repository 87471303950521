import React from "react";
import { OperationData, Person } from "../../../../../api";
import { EditButton } from "../../../../../common";
import { EditPersonDialog } from "./editPersonDialog";

type PersonRecord = OperationData<typeof Person, "GET_ONE">;

export function EditConsumerButton({
  person,
}: {
  person: PersonRecord;
}): React.ReactElement {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <EditButton onClick={onClick} />
      <EditPersonDialog open={open} onClose={onClose} person={person} />
    </div>
  );
}
