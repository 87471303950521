const validCrnRegex = /^\d{9}[A-Za-z]$/;
const validCrnUppercaseRegex = /^\d{9}[A-Z]$/;

export function validateCrn(value?: string | null): undefined | string {
  if (!value) {
    return "Please enter a CRN";
  }
  return validCrnRegex.test(value) ? undefined : "CRN format is invalid";
}

export function validateCrnUppercase(
  value?: string | null
): undefined | string {
  if (!value) {
    return "Please enter a CRN";
  }
  return validCrnUppercaseRegex.test(value)
    ? undefined
    : "CRN letter must be uppercase";
}
